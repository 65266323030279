
import axios from "axios";

let walletsBaseUrl = process.env.VUE_APP_WALLETS_BASE_URL;

export default {
	getAllTransactions({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/transactions`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getTransactionsByRef({ apiKey, params, reference }) {
		return axios.get(`${walletsBaseUrl}/transactions/${reference}`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPosTransactions({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/pos/transactions`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	// createCustomer({ apiKey, request }) {
	// 	return axios.post(`${walletsBaseUrl}/customers`, request, {
	// 		headers: {
	// 			"x-api-key": apiKey,
	// 		},
	// 	});
	// },
};
