<template>
	<div class="col-sm-6 text-center mb-3">
		<div class="card h-100 px-3 pt-3">
			<ContentWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="getCustomers"
			>
				<template slot="content">
					<div class="flex__between border-bottom">
						<p>Total Customer - {{ customers.total || 0 }}</p>

						<p
							class="pointer text-primary"
							@click="$router.push('/wallets/customers')"
						>
							See All Customers
						</p>
					</div>

					<div class="flex__center h-100">
						<div class="w-100 border-right">
							<h4 class="call-count">{{ wallets.total || 0 }}</h4>
							<p class="call-name py-4">
								Wallet Customers ({{ currency }})
							</p>
							<button
								type="button"
								class="btn btn-sm btn--primary px-sm-4 px-2"
								@click="$router.push('/wallets')"
							>
								View Wallet
							</button>
						</div>

						<div class="w-100">
							<h4 class="call-count">{{ resAcct.total || 0 }}</h4>
							<p class="call-name py-4">Reserved Account</p>
							<button
								type="button"
								class="btn btn-sm btn--primary px-sm-4 px-2"
								@click="
									$router.push('/wallet/reserved-accounts')
								"
							>
								View Reserved Account
							</button>
						</div>
					</div>
				</template>
			</ContentWrapper>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins";

export default {
	mixins: [componentData],
	name: "TotalCustomers",
	props: ["currency"],
	data() {
		return {
			customers: {},
			resAcct: {},
			wallets: {},
		};
	},
	methods: {
		async getCustomers() {
			try {
				const response = await this.$store.dispatch(
					"customers/getAllCustomers",
					{
						apiKey: this.getWalletApikey(this.$store),
						params: this.filters,
					}
				);
				const { status, data } = response || {};
				if (status === 200) this.customers = data.data;
			} catch (error) {}
		},
		async getReservedAccount() {
			try {
				const response = await this.$store.dispatch(
					"reservedAccounts/getReservedAccounts",
					{
						apiKey: this.getWalletApikey(this.$store),
						params: { ...this.filters, currency: this.currency },
						// params: this.filters,
					}
				);
				const { status, data } = response || {};
				if (status === 200) this.resAcct = data.data;
			} catch (error) {}
		},
		async getWallets() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getWallets",
					{
						apiKey: this.getWalletApikey(this.$store),
						params: { ...this.filters, currency: this.currency },
					}
				);
				const { status, data } = response || {};
				if (status === 200) {
					let tol = data.data.total;
					const total = tol > 0 ? tol - 1 : tol;
					this.wallets = { ...data.data, total };
					this.loading = false;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = this.getError(error);
			}
		},
	},
	watch: {
		currency() {
			this.getWallets();
			this.getReservedAccount();
		},
	},
	async mounted() {
		this.getCustomers();
		this.getReservedAccount();
		this.getWallets();
	},
};
</script>
