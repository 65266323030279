<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<div v-else class="mb-4 d-flex justify-content-between">
			<div class="SectionItem no-b-b justify-content-start">
				<img
					src="/assets/img/icons/arrow-left.svg"
					width="30"
					alt=""
					class="pointer"
					@click="$router.push('/wallet/split-payments')"
				/>
				<p class="title ml-3 mb-0">
					{{ splitPayment.name || "Split Payment Details" }}
				</p>
			</div>
			<UpdateSplitPaymentButton
				v-if="Object.keys(splitPayment).length > 0"
				:data="splitPayment"
			/>
		</div>

		<div v-if="loading" class="flex__center mt-5 pt-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="() => getSplitPayment(reference)"
		/>

		<template v-else>
			<div class="content__slider">
				<Tabs
					:tabNames="['Split_Details', 'Sub_Account_Information']"
					:tabId="'splitTab'"
				/>
			</div>

			<div class="tab-content" id="splitContent">
				<SplitPaymentDetailsInfo
					:reference="reference"
					:splitPayment="splitPayment"
				/>
				<SplitPaymentSubAccountInfo
					:subAccounts="splitPayment.config"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import SplitPaymentDetailsInfo from "./SplitPaymentDetailsInfo";
import SplitPaymentSubAccountInfo from "./SplitPaymentSubAccountInfo";
import UpdateSplitPaymentButton from "../buttons/UpdateSplitPaymentButton/UpdateSplitPaymentButton";
import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
import Spinner from "@/utils/Spinner/Spinner";
import FailedState from "@/utils/ResponseState/FailedState";
import { getWalletApikey, timeDateFormat, toCurrency } from "@/utils/helpers";
import Tabs from "@/utils/Tabs/Tabs";

export default {
	name: "splitPayment",
	data() {
		return {
			reference: "",
			loading: false,
			errorMsg: "",
			splitPayment: {},
		};
	},
	components: {
		SplitPaymentDetailsInfo,
		UpdateSplitPaymentButton,
		SplitPaymentSubAccountInfo,
		Spinner,
		FailedState,
		EmptyWallet,
		Tabs,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		timeDateFormat,
		toCurrency,
		async getSplitPayment(reference) {
			try {
				this.loading = true;
				this.errorMsg = "";
				const response = await this.$store.dispatch(
					"payment/getMerchantSplitPayment",
					{
						apiKey: getWalletApikey(this.$store),
						reference,
					}
				);
				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.splitPayment = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
	},

	async mounted() {
		const reference = this.$route.params.reference;
		this.reference = reference;
		this.getSplitPayment(reference);
	},
};
</script>
