var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"id":_vm.id,"title":_vm.taxData ? 'Update Tax' : 'Create Tax',"toggle":_vm.handleCloseModal}},[(_vm.successMsg)?_c('SuccessState',{attrs:{"message":_vm.taxData
				? 'Tax Updated Successfully'
				: 'Tax Created Successfully',"onSuccess":_vm.handleRefetch}}):_c('form',{staticClass:"row h-100",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"col-12"},[_c('CustomInput',{attrs:{"name":'name',"label":'Name',"placeholder":'Enter a name',"required":true,"error":_vm.formErrors.name ||
					(_vm.$v.request.name.$error &&
						_vm.formVuelidator(_vm.$v.request.name)),"handleError":_vm.handleError},on:{"blur":function($event){return _vm.$v.request.name.$touch()}},model:{value:(_vm.request.name),callback:function ($$v) {_vm.$set(_vm.request, "name", $$v)},expression:"request.name"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('CustomSelect',{attrs:{"name":"tax_type","label":"Tax Type","optionLabel":'name',"options":[{ name: 'VAT', value: 'vat' }],"required":true,"error":_vm.formErrors.tax_type ||
					(_vm.$v.request.tax_type.$error &&
						_vm.formVuelidator(_vm.$v.request.tax_type)),"handleError":_vm.handleError},on:{"close":function($event){return _vm.$v.request.tax_type.$touch()}},model:{value:(_vm.request.tax_type),callback:function ($$v) {_vm.$set(_vm.request, "tax_type", $$v)},expression:"request.tax_type"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('CustomSelect',{attrs:{"name":"country","label":"Country","optionLabel":'name',"placeholder":"Select Country","options":[{ name: 'Nigeria', value: 'nigeria' }],"required":true,"error":_vm.formErrors.country ||
					(_vm.$v.request.country.$error &&
						_vm.formVuelidator(_vm.$v.request.country)),"handleError":_vm.handleError},on:{"close":function($event){return _vm.$v.request.country.$touch()}},model:{value:(_vm.request.country),callback:function ($$v) {_vm.$set(_vm.request, "country", $$v)},expression:"request.country"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('CustomInput',{attrs:{"name":'rate',"type":"number","label":'Enter Rate (%)',"placeholder":'Enter Rate (%)',"required":true,"max":100,"error":_vm.formErrors.rate ||
					(_vm.$v.request.rate.$error &&
						_vm.formVuelidator(_vm.$v.request.rate)),"handleError":_vm.handleError},on:{"blur":function($event){return _vm.$v.request.rate.$touch()}},model:{value:(_vm.request.rate),callback:function ($$v) {_vm.$set(_vm.request, "rate", $$v)},expression:"request.rate"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('CustomSelect',{attrs:{"name":"type","optionLabel":'name',"label":"Calculation Type","placeholder":"Select Tax Variant","options":_vm.taxVariant,"required":true,"error":_vm.formErrors.type ||
					(_vm.$v.request.type.$error &&
						_vm.formVuelidator(_vm.$v.request.type)),"handleError":_vm.handleError},on:{"close":function($event){return _vm.$v.request.type.$touch()}},model:{value:(_vm.request.type),callback:function ($$v) {_vm.$set(_vm.request, "type", $$v)},expression:"request.type"}})],1),_c('div',{staticClass:"col-12"},[_c('FormButtons',{attrs:{"loading":_vm.loading,"handleCloseModal":_vm.handleCloseModal}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }