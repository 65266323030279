<template>
  <div class="">
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <ClientLogFilters
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('logRef')"
            :download="downloadLogs"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="refetch"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <template slot="content">
        <v-server-table
          class="v-table"
          ref="logRef"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
        >
          <template slot="price_per_call" slot-scope="{ row }">
            <span>
              {{ toCurrency(row.price_per_call) }}
            </span>
          </template>
          <template slot="identity_number" slot-scope="{ row }">
            <span>
              {{ row.identity_number || 'Null' }}
            </span>
          </template>
          <template slot="request_time" slot-scope="{ row }">
            <span>
              {{
                timeDateFormat(row.request_time).date +
                " " +
                timeDateFormat(row.request_time).time
              }}
            </span>
          </template>
          <template slot="status" slot-scope="props">
            <span
              v-if="props.row.status == 1"
              class="badge badge-success badge-app"
              >Successful</span
            >
            <span v-else class="badge badge-danger badge-app">Failed</span>
          </template>
        </v-server-table>
      </template>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins";
import ClientLogFilters from "./Filters/ClientLogFilters";

const tableHeadings = {
  request_reference: "Request Reference",
  identity_number: "Identity Number",
  request_time: "Request Time",
  "service.service_label": "Service",
  price_per_call: "Amount Debited",
  status: "Status",
};
export default {
  name: "ClientLogs",
  mixins: [componentData],
  data() {
    return {
      loading: false,
      rLoading: false,
      errorMsg: "",
      size: 25,
      filters: {},
      ...this.tableOptions(tableHeadings, 20),
      apps: [],
      //   logs: [],
      isMainUser: "",
      url: "logs/getAllLogs",
      emptyTitle: "No Log Found",
    };
  },
  components: {
    ClientLogFilters,
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
  methods: {
    setError(error) {
      this.errorMsg = error.message;
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    refetch(filters) {
      this.handleRefetch(filters, this.getLogs, "logRef");
    },
    async getLogs() {
      let request = {};
      request.clientID = this.user.api_customer.client_id;
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, size: 25 };

        try {
          const response = await this.$store.dispatch("logs/getAllLogs", {
            params,
            request,
          });
          if (!response?.result) {
			return {
            count: 0,
            total: 0,
            totalPages: 0,
            data: [],
          };
          }
          return {
            count: response.total,
            total: response.total,
            totalPages: response.lastPage,
            data: response.result,
          };
        } catch (error) {
          this.loading = false;
          this.setError(error);
        }
      };
    },
    async downloadLogs() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.result);
      };
      const headers = { clientID: this.user.api_customer.client_id };
      this.downloadReportWithHeaders({
        url: "logs/getAllLogs",
        manageData: download,
        headers,
      });
    },
  },
  mounted() {
    const userR = this.$store.state.account.profile.roles[0].role_label;
    this.isMainUser = userR !== "Secondary Customer";
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
  created() {
    this.getLogs();
  },
};
</script>
