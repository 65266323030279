<template>
	<div class="form-group">
		<div v-if="label">
			<label class="input-item-label">{{ label }}</label>
			<span
				v-if="required"
				class="text-danger pl-1"
				title="This field is required"
				>*</span
			>
		</div>

		<div class="custom_select_2" :tabindex="0" @blur="open = false">
			<div class="selected" :class="{ open: open }" @click="handleClick">
				<input
					type="text"
					:value="value"
					:placeholder="placeholder"
					class="w-100 border-0"
					@input="handleChange"
				/>

				<span v-if="loading" class="pr-2 pt-1">
					<Spinner
						:variant="'clip'"
						:size="'15px'"
						:color="'#c4c4c4'"
				/></span>
				<div class="d-flex" v-else>
					<img
						v-if="selected"
						src="/assets/img/icons/close-invert.svg"
						alt
						width="10px"
						class="mr-1"
						@click="handleClear"
					/>
					<img
						src="/assets/img/icons/arrow-down.svg"
						alt
						width="15px"
						class="mr-3 caret"
					/>
				</div>
			</div>
			<div class="items" :class="`${open ? '' : 'd-none'}`">
				<div class="options">
					<template v-if="options.length > 0">
						<div
							v-for="(option, i) of options"
							:key="i"
							class="option__list"
							@click="() => optionSelect(option)"
						>
							{{ getLabel ? getLabel(option) : option }}
						</div>
					</template>
					<div v-else class="text-center mt-3 py-4">
						No Record Found
					</div>
				</div>
				<div
					v-if="createFooter"
					class="custom_select_2_footer"
					@click="createFooter"
				>
					<span
						class="font-weight-bold text--primary m-0 pointer"
						style="font-size: 14px"
					>
						+ Add New {{ btnName }}
					</span>
				</div>
			</div>
		</div>

		<span v-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";

export default {
	name: "CustomBuiltSelect",
	data() {
		return {
			open: false,
		};
	},
	components: {
		Spinner,
	},
	props: [
		"loading",
		"required",
		"options",
		"createFooter",
		"btnName",
		"placeholder",
		"handleChange",
		"value",
		"error",
		"selected",
		"label",
		"getLabel",
		"handleSelect",
	],
	methods: {
		handleClick() {
			this.open = !this.open;
		},
		optionSelect(option) {
			this.handleSelect(option);
			this.open = false;
		},
		handleClear(e) {
			e.stopPropagation();
			e.preventDefault();
			this.handleSelect(undefined);
			this.value = null;
		},
	},
	mounted() {
		this.$emit("input", this.selected);
	},
};
</script>
