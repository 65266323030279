<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="SectionItem no-b-b mb-3">
					<div class="row">
						<div class="col-12">
							<div class="SectionHeader">
								<span class="title">Team</span>
								<div class="description">
									Add/Edit/Remove Team members
								</div>
							</div>
						</div>
						<div class="col-12 mt-4">
							<a
								href="#"
								class="btn btn-md btn-primary"
								data-toggle="modal"
								data-effect="effect-scale"
								data-backdrop="static"
								data-target="#addTeamMemberModal"
							>
								<i class="la la-plus"></i> Add Team Member
							</a>
						</div>
						<div class="col-12 mt-3">
							<div class="user-details--wrapper">
								<v-client-table
									v-if="team.length > 0"
									ref="serverTableRef"
									name="question-table"
									class="v-table"
									:data="team"
									:columns="columns"
									:options="options"
								>
								</v-client-table>
							</div>
							<DefaultContent
								v-if="team.length == 0"
								:image="'empty-resources.png'"
								:title="'Team empty'"
								:subtitle="'You don\'t have a team yet.'"
							></DefaultContent>
						</div>
						<AddTeamMemberModal></AddTeamMemberModal>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AddTeamMemberModal from "@/components/modals/AddTeamMemberModal";
	export default {
		components: { AddTeamMemberModal },
		props: ["apiUser"],
		data() {
			return {
				columns: ["id", "email", "name", "role"],
				options: {
					sortable: [],
					perPage: 25,
					pagination: {
						chunk: 5,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search..",
						noResults: "No matching records",
					},
					headings: {
						id: "S/N",
						amount_credited: "Amount Credited",
						"loadedBy.firstname": "Credited By",
						payment_date: "Credit Date",
					},
				},
				team: [],
				roles: [
					"Owner",
					"Admin",
					"Operations",
					"Developer",
					"Customer Service",
				],
			};
		},
		mounted() {
			this.team = this.apiUser.team.map((member, index) => {
				return {
					id: index + 1,
					role: this.roles[member.organization_role - 1],
					name: `${member.user.firstname} ${member.user.lastname}`,
					email: member.user.email,
				};
			});
		},
	};
</script>

<style></style>
