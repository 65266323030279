var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccessControl',{attrs:{"page":true}},[_c('ContentWrapper',{attrs:{"loading":_vm.isLoading,"errorMsg":_vm.errorMsg,"refetch":_vm.getKYCData}},[_c('template',{slot:"content"},[(_vm.compliance.reason)?_c('div',{class:_vm.compliance.status && _vm.compliance.status === 'unapproved'
						? ' my-5 bg-danger-light rounded text-danger p-2'
						: ' my-5 bg-success-light rounded text-success p-2'},[(
						_vm.compliance.status &&
						_vm.compliance.status === 'unapproved'
					)?_c('span',[_c('img',{staticClass:"mr-2",attrs:{"src":"/assets/img/icons/error.svg","alt":"image","width":"13","height":"13"}})]):_c('span',[_c('img',{staticClass:"mr-2",attrs:{"src":"/assets/img/icons/CaretDown.svg","alt":"image"}})]),(_vm.compliance.reason)?_c('span',[_vm._v(" "+_vm._s(_vm.compliance.reason)+" ")]):_c('span',[_vm._v("Invalid Information")])]):_vm._e(),_c('CompanyInfo',{attrs:{"company":{
					rc_number: _vm.compliance.rc_number,
					tin: _vm.compliance.tin,
				}},on:{"refresh":function($event){return _vm.getKYCData()}}}),_c('DirectorsTable',{attrs:{"directors":_vm.compliance.directors},on:{"refresh":function($event){return _vm.getKYCData()}}}),_c('Documents',{attrs:{"data":_vm.compliance},on:{"refresh":function($event){return _vm.getKYCData()}}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }