<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="SectionItem no-b-b mb-4 justify-content-start">
				<img
					src="/assets/img/icons/arrow-left.svg"
					width="30"
					alt=""
					class="pointer"
					@click="$router.push('/wallet/subscriptions/trails')"
				/>
				<p class="title ml-3 mb-0">
					Subscription Details
				</p>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getTrail(id)"
			/>

			<template v-else>
				<div class="content__slider">
					<Tabs
						:tabNames="[
							'Subscription_Details',
							'Subscriber_Information',
						]"
						:tabId="'trailTab'"
					/>
				</div>

				<div class="tab-content" id="subContent">
					<SubscriptionDetailsMain
						:subscription="trail.metadata"
						:desc="trail.description"
						:title="trail.title"
					/>
					<SubscriberInformation
						:wallet="trail.metadata.metadata.wallet"
					/>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
	import Tabs from "../../../../../utils/Tabs/Tabs";
	import EmptyWallet from "../../../common/EmptyWallet/EmptyWallet";
	import SubscriptionDetailsMain from "../../SubscriptionOverview/SubscriptionDetails/SubscriptionDetailsMain/SubscriptionDetailsMain";
	import SubscriberInformation from "../../SubscriptionOverview/SubscriptionDetails/SubscriberInformation/SubscriberInformation";
	import Spinner from "../../../../../utils/Spinner/Spinner";
	import FailedState from "../../../../../utils/ResponseState/FailedState";
	import { getWalletApikey } from "../../../../../utils/helpers";

	export default {
		name: "SubscriptionTrailDetails",
		data() {
			return {
				id: "",
				loading: false,
				errorMsg: "",
				trail: {},
			};
		},
		components: {
			Spinner,
			FailedState,
			EmptyWallet,
			Tabs,
			SubscriberInformation,
			SubscriptionDetailsMain,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			async getTrail(id) {
				try {
					this.loading = true;
					this.errorMsg = "";
					const response = await this.$store.dispatch(
						"trails/getTrail",
						{
							apiKey: getWalletApikey(this.$store),
							id,
						}
					);
					if (response.status === 200) {
						this.loading = false;
						this.trail = response.data.data;
					}
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
		},

		async mounted() {
			const id = this.$route.params.id;
			this.id = id;
			this.getTrail(id);
		},
	};
</script>
