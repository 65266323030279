<template>
	<div class="row justify-content-center py-5">
		<div class="col-lg-5 col-md-6 col-11">
			<h5 class="font-weight-bold">
				You have not set any spending limit on this card yet
			</h5>
			<p class="py-2">
				Spending limits enables you to manage your spending habits
			</p>
			<SetSpendingLimitButton />
		</div>
	</div>
</template>

<script>
	import SetSpendingLimitButton from "../../buttons/SetSpendingLimitButton/SetSpendingLimitButton";

	export default {
		name: "EmptySpendingLimit",
		components: {
			SetSpendingLimitButton,
		},
	};
</script>
