<template>
	<div>
		<h2
			v-if="useText"
			class="font-weight-bold text--primary m-0 pointer"
			@click="openCreateItem"
		>
			+
		</h2>
		<button
			v-else
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreateItem"
		>
			<img src="/assets/img/icons/task-square.svg" class="mr-2" alt="" />
			Create Product
		</button>
		<CreateItemsModal
			:id="'createItem'"
			:closeModal="closeModal"
			:itemsRef="itemsRef"
		/>
	</div>
</template>

<script>
import CreateItemsModal from "../../modals/CreateItemsModal/CreateItemsModal";

export default {
	name: "CreateItemButton",
	props: ["itemsRef", "useText", "refetch"],
	methods: {
		openCreateItem() {
			$("#createItem").modal("show");
		},
		closeModal() {
			$("#createItem").modal("hide");
		},
	},
	components: {
		CreateItemsModal,
	},
};
</script>
