<template>
	<Modal
		:id="id"
		:title="`Issue Invoice ${invoice.invoice_reference}`"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Invoice Issued Successfully'"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="handleIssueInvoice">
			<h4 class="text-center my-4">
				Are you sure you want to issue invoice
				<b>{{ invoice.invoice_reference }}</b
				>?
			</h4>

			<FormButtons
				:btnName="'Issue'"
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../public/assets/mixins/filters";

const defaultValue = () => ({
	loading: false,
	successMsg: false,
});

export default {
	mixins: [filters, notifications],
	name: "IssueInvoiceModal",
	data() {
		return { ...defaultValue() };
	},
	props: ["id", "invoice", "draftRef", "closeModal"],

	methods: {
		handleCloseModal() {
			Object.assign(this.$data, { ...defaultValue() });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			this.draftRef.refresh();
		},
		setError(msg) {
			this.loading = false;
			this.showErrorLoginNotification(msg);
		},
		async handleIssueInvoice() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"invoices/issueInvoice",
					{
						apiKey: this.getWalletApikey(this.$store),
						reference: this.invoice.invoice_reference,
						request: { notifications: ["email"] },
					}
				);
				const { status, data } = response || {};

				if (status === 200 && (data.status || data.success)) {
					this.loading = false;
					this.successMsg = true;
				} else this.setError(data.message);
			} catch (error) {
				this.setError(this.getError(error));
			}
		},
	},
};
</script>
