<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div class="py-4" v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<DateFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('refundRef')"
							:download="downloadRefund"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="`Error Fetching Refund Record!`"
			>
				<template slot="content">
					<v-server-table
						ref="refundRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@row-click="showData"
						@loading="setLoading(true)"
						@loaded="setLoading(false)"
						@error="setError"
					>
						<template slot="amount" slot-scope="{ row }">
							{{ row.currency }}{{ toCurrency(row.amount, " ") }}
						</template>
						<template slot="charges" slot-scope="{ row }">
							{{ row.currency }}{{ toCurrency(row.charges, " ") }}
						</template>
						<template slot="status" slot-scope="{ row }">
							<span :class="statusColor(row.status)">
								{{ titleCase(row.status) }}
							</span>
						</template>
						<template slot="createdAt" slot-scope="props">
							<span>
								{{
									timeDateFormat(props.row.createdAt).date +
									" " +
									timeDateFormat(props.row.createdAt).time
								}}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</div>
		<PaymentRefundModal
			v-if="showModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../public/assets/mixins/componentData";
import DateFilters from "../../common/DateFilters/DateFilters";
import AccessControl from "@/utils/AccessControl/AccessControl";
import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
import TransactionsTableFilters from "../../common/TransactionsTable/TransactionsTableFilters/TransactionsTableFilters";
import PaymentRefundModal from "./PaymentRefundModal";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import { refundTableHeadings } from "../enums";
import { tableOptions } from "@/utils/helpers";

export default {
	mixins: [notifications, componentData],
	name: "refunds",
	data() {
		return {
			showModal: false,
			modalData: null,
			...tableOptions(refundTableHeadings, 20),
		};
	},
	components: {
		EmptyWallet,
		FilterWrapper,
		TransactionsTableFilters,
		FailedState,
		Spinner,
		AccessControl,
		PaymentRefundModal,
		DateFilters,
	},
	methods: {
		showData({ row }) {
			this.modalData = row;
			this.showModal = true;
		},
		closeModal() {
			this.showModal = false;
			this.modalData = null;
		},
		refetch(filters) {
			this.handleRefetch(filters, this.handleRefund, "refundRef");
		},
		async handleRefund() {
			this.handleAPICall("payment/getRefunds");
		},
		async downloadRefund() {
			const download = (filterData) =>
				this.downloadReport(refundTableHeadings, filterData.data.data);
			this.downloadTableReport("payment/getRefunds", download);
		},
	},
	created() {
		this.handleRefund();
	},
	mounted() {
		this.setColums(refundTableHeadings);
	},
};
</script>
