import Vue from "vue";
import Router from "vue-router";

// layout
import TheLayout from "./components/modules/TheLayout.vue";

// UnAuthenticated Routes
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import RegisterInvite from "./views/RegisterInvite.vue";
import AuditTrails from "./views/AuditTrails/AuditTrails.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import PasswordReset from "./views/PasswordReset.vue";
import ActivateAccount from "./views/ActivateAccount.vue";

// Authenticated Routes
import Account from "./components/Accounts/Accounts";
import AccountProfile from "./components/Accounts/Profile/Profile";
import AccountWallet from "./components/Accounts/Wallet/Wallet";
import AccountServices from "./components/Accounts/Services/Services";
import RequestLogs from "./components/Accounts/RequestLogs/RequestLogs";
import Teams from "./components/Accounts/Teams/Teams";
import AccountKYC from "./components/account/KYC.vue";
import AltAccount from "./views/AltAccount.vue";
import Applications from "./views/Applications.vue";
import DirectDebit from "./views/DirectDebit.vue";
import SingleFTTransaction from "./views/SingleFTTransaction.vue";
import TransactionsLog from "./components/TransactionsLog/TransactionsLog";
import DebitHistory from "./components/TransactionsLog/DebitHistory/DebitHistory";
import CreditHistory from "./components/TransactionsLog/CreditHistory/CreditHistory";
import DebitHistoryDetails from "./components/TransactionsLog/DebitHistory/DebitHistoryDetails/DebitHistoryDetails";

import Dashboard from "./views/Dashboard.vue";
import Services from "./views/Services.vue";
import FacialServices from "./views/FacialServices.vue";
import CustomerInfoServices from "./views/ServicesCustomerInfo.vue";
import BusinessInfoServices from "./views/ServicesBusinessInfo.vue";
import ServiceReports from "./views/ServiceReports/ServiceReports";
import ServiceReportDetails from "./views/ServiceReports/ServiceReportDetails/ServiceReportDetails";
import Ownership from "./views/ServiceReports/ServiceReportDetails/Ownership/Ownership";
import Personnel from "./views/ServiceReports/ServiceReportDetails/Personnel/Personnel";
import ContactInformation from "./views/ServiceReports/ServiceReportDetails/ContactInformation/ContactInformation";
import MainInformation from "./views/ServiceReports/ServiceReportDetails/MainInformation/MainInformation";
import OtherInformation from "./views/ServiceReports/ServiceReportDetails/OtherInformation/OtherInformation";
import APIDocuments from "./views/APIDocuments/APIDocuments";
import APIKeys from "./views/APIDocuments/APIKeys/APIKeys";

import AmlServices from "./views/AntiMoneyLaunderings.vue";
import BillPayments from "./views/BillPayment.vue";

// Funds Transfer Routes
import RequestFundsTransfer from "./views/RequestFundsTransfer.vue";
import FundsTransfer from "./views/FundsTransfer.vue";
import FTTransactions from "./components/fundsTransfer/Transactions.vue";
import Preferences from "./components/fundsTransfer/Preferences.vue";
import FundsTransferProfile from "./components/fundsTransfer/FundsTransferProfile.vue";

//Chargeback
import ChargeBackContent from "./components/wallet/Payment/ChargeBack/contents/ChargeBackContent.vue";
import ChargeBackDetailContent from "./components/wallet/Payment/ChargeBack/contents/ChargeBackDetailContent.vue";

// WhiteLabelling Features
// import SubCustomerDetails from "./views/SubCustomerDetails.vue";
// import WhiteLabelSettings from "./views/WhiteLabelSettings.vue";

// import WhiteLabelDashboard from "./views/WhitelabelDashboard.vue";
// import WhiteLabelTransactions from "./views/WhiteLabelTransactions.vue";
// import SingleSecondaryCustomerRequestLog from "./views/SingleSecondaryCustomerRequestLog.vue";
// import SingleWhiteLabelTransactions from "./views/SingleWhiteLabelTransactions.vue";
// import WhiteLabelServicePricing from "./views/WhiteLabelServicePricing.vue";
// import WhiteLabelCustomer from "./views/WhiteLabelCustomers.vue";
// import WhiteLabelCustomerAPILogs from "./views/WhiteLabelCustomersAPILogs.vue";

import CreateCustomer from "./views/CreateCustomer.vue";
// import RequestWhitelabelling from "./views/RequestWhitelabelling.vue";
import ExternalWLRegistration from "./views/ExternalWLRegistration.vue";

// Messaging Routes
import Messaging from "./components/services/Messaging/Messaging";

// Direct Debit Routes
import DirectDebitPreferences from "./components/directDebit/DirectDebitPreferences.vue";
import Mandate from "./components/directDebit/Mandate.vue";
import DirectDebitTransactions from "./components/directDebit/DirectDebitTransactions.vue";

// Wallet Routes
import WalletOverview from "./components/wallet/WalletOverview/WalletOverview";
import WalletDetails from "./components/wallet/Wallets/WalletDetails/WalletDetails";
import Card from "./components/wallet/Wallets/Card/Card";
import Wallets from "./components/wallet/Wallets/Wallets.vue";
import Customers from "./components/wallet/Customers/Customers";
import CustomerDetails from "./components/wallet/Customers/CustomerDetails/CustomerDetails";
import CollectionHome from "./components/wallet/CollectionHome.vue";

//Payments
import Payment from "./components/wallet/Payment/Payment";
import SplitGroup from "./components/wallet/SplitGroup";
import PaymentLinks from "./components/wallet/PaymentLinks/PaymentLinks";
import PaymentLinkDetails from "./components/wallet/PaymentLinks/PaymentLinkDetails/PaymentLinkDetails";

import PaymentPayouts from "./components/wallet/Payment/PaymentPayouts/PaymentPayouts";
import PaymentPayoutDetails from "./components/wallet/Payment/PaymentPayouts/PaymentPayoutDetails/PaymentPayoutDetails";
import SubAccounts from "./components/wallet/SubAccounts/SubAccounts";
import SplitPayments from "./components/wallet/SplitPayments/SplitPayments";
import SplitPaymentDetails from "./components/wallet/SplitPayments/SplitPaymentDetails/SplitPaymentDetails";
import SubAccountDetails from "./components/wallet/SubAccounts/SubAccountDetails/SubAccountDetails";
import PaymentDisputes from "./components/wallet/Payment/PaymentDisputes/PaymentDisputes";
import PaymentDisputeDetails from "./components/wallet/Payment/PaymentDisputes/PaymentDisputeDetails/PaymentDisputeDetails";
import PaymentRefunds from "./components/wallet/Payment/PaymentRefund/PaymentRefund.vue";
import PaymentCollection from "./components/wallet/Payment/PaymentCollection/PaymentCollection";
import PaymentCollectionDetails from "./components/wallet/Payment/PaymentCollection/PaymentCollectionDetails/PaymentCollectionDetails";
import PaymentPageInformation from "./components/wallet/Payment/PaymentCollection/PaymentCollectionDetails/PaymentInformation/PaymentInformation";
import PaymentPageTransactions from "./components/wallet/Payment/PaymentCollection/PaymentCollectionDetails/PaymentPageTransactions/PaymentPageTransactions";
import WalletPreference from "./components/wallet/Preferences/Preferences";
import Transactions from "./components/wallet/Transactions/Transactions";
import BankTransferTransactions from "./components/wallet/Transactions/BankTransferTransactions/BankTransferTransactions";
import CollectionTransactions from "./components/wallet/Transactions/CollectionTransactions/CollectionTransactions";
import ReservedAccountTransactions from "./components/wallet/Transactions/ReservedAccountTransactions/ReservedAccountTransactions";
import WalletTransactions from "./components/wallet/Transactions/WalletTransactions/WalletTransactions";
import RemittanceTransactions from "./components/wallet/Transactions/RemittanceTransactions/RemittanceTransactions";
import SubscriptionLogs from "./components/wallet/Transactions/SubscriptionsLogs/SubscriptionsLogs";
import InvoiceTransactions from "./components/wallet/Transactions/InvoiceTransactions/InvoiceTransactions";
import ReservedAccounts from "./components/wallet/ReservedAccounts/ReservedAccounts";
import ReservedAccountDetails from "./components/wallet/ReservedAccounts/ReservedAccountDetails/ReservedAccountDetails";

//Subscription
import Subscriptions from "./components/wallet/Subscriptions/Subscriptions";
import SubscriptionOverview from "./components/wallet/Subscriptions/SubscriptionOverview/SubscriptionOverview";
import SubscriptionDetials from "./components/wallet/Subscriptions/SubscriptionOverview/SubscriptionDetails/SubscriptionDetails";
import Plans from "./components/wallet/Subscriptions/Plans/Plans";
import PlanDetails from "./components/wallet/Subscriptions/Plans/PlanDetails/PlanDetails";
import Coupons from "./components/wallet/Subscriptions/Coupons/Coupons";
import SubscriptionTrails from "./components/wallet/Subscriptions/SubscriptionTrails/SubscriptionTrails";
import SubscriptionTrailDetails from "./components/wallet/Subscriptions/SubscriptionTrails/SubscriptionTrailDetails/SubscriptionTrailDetails";
import Tax from "./components/wallet/Subscriptions/Tax/Tax";

//Invoice
import Invoice from "./components/wallet/Invoices/Invoices";
import Items from "./components/wallet/Invoices/Items/Items";
import Drafts from "./components/wallet/Invoices/Drafts/Drafts";
import InvoiceList from "./components/wallet/Invoices/InvoiceList/InvoiceList";
import CreateInvoice from "./components/wallet/Invoices/CreateInvoice/CreateInvoice";

import CustomerInvite from "./views/CustomerInvitePage";
import CustomerEmailVerification from "./views/CustomerEmailVerification.vue";

// Remittance
import Remittance from "./components/wallet/Remittance/Remittance";
import SendMoney from "./components/wallet/Remittance/SendMoney/SendMoney";
import TransactionHistory from "./components/wallet/Remittance/TransactionHistory/TransactionHistory";
import Beneficiaries from "./components/wallet/Remittance/Beneficiaries/Beneficiaries";
import Sender from "./components/wallet/Remittance/Sender/Sender";
import ReversedTransactions from "./components/wallet/Remittance/ReversedTransactions/ReversedTransactions";
import SavedTransactions from "./components/wallet/Remittance/SavedTransactions/SavedTransactions";
import SenderDetails from "./components/wallet/Remittance/Sender/SenderDetails/SenderDetails";
import BeneficiaryDetails from "./components/wallet/Remittance/Beneficiaries/BeneficiaryDetails/BeneficiaryDetails";

// Terminal
import Terminal from "./components/wallet/Terminal/Terminal";
import TerminalOverview from "./components/wallet/Terminal/Overview/Overview";
import TerminalTransactions from "./components/wallet/Terminal/Transactions/Transactions";
import VirtualTerminal from "./components/wallet/Terminal/VirtualTerminal/VirtualTerminal";
import PosTerminal from "./components/wallet/Terminal/PosTerminal/PosTerminal";
import TerminalAgent from "./components/wallet/Terminal/Agents/Agents";
import VirtualTerminalDetails from "./components/wallet/Terminal/VirtualTerminal/VirtualTerminalDetails/VirtualTerminalDetails";
import PosTerminalDetails from "./components/wallet/Terminal/PosTerminal/PosTerminalDetails/PosTerminalDetails";
import TerminalAgentDetails from "./components/wallet/Terminal/Agents/AgentDetails/AgentDetails";

Vue.use(Router);

export const routes = [
	// Login Route
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "/customer-invite",
		props: true,
		name: "CustomerInvite",
		component: CustomerInvite,
		meta: {
			title: "Blusalt - Invite Customer",
		},
	},
	{
		path: "/customer-email-verification",
		props: true,
		name: "CustomerEmailVerification",
		component: CustomerEmailVerification,
		meta: {
			title: "Blusalt - Customer Email Verification",
		},
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			title: "Blusalt - Login to blusalt ",
		},
	},
	{
		path: "/external-whitelabel-request",
		name: "ExternalWLRegistration",
		component: ExternalWLRegistration,
		meta: {
			title: "Blusalt - Register to start using blusalt whitelabelling",
		},
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
		meta: {
			title: "Blusalt - Register to start using blusalt",
		},
	},
	{
		path: "/register/invite/:token",
		name: "RegisterInvite",
		component: RegisterInvite,
		meta: {
			title: "Blusalt - Register to start using blusalt",
		},
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
		meta: {
			title: "Blusalt  - Lets help you recover your password",
		},
	},
	{
		path: "/activate-account/:token",
		props: true,
		name: "ActivateAccount",
		component: ActivateAccount,
		meta: {
			title: "Blusalt - Activate your account to continue using blusalt",
		},
	},
	{
		path: "/password-reset/:token",
		props: true,
		name: "PasswordReset",
		component: PasswordReset,
		meta: {
			title: "Blusalt - Lets help you recover your password",
		},
	},
	// Dashboard & Children Components Routes
	{
		path: "/dashboard",
		component: TheLayout,
		redirect: "/home",
		children: [
			{
				path: "/home",
				name: "home",
				component: Home,
			},

			{
				path: "/account",
				name: "Account",
				component: Account,
				meta: {
					title: "Blusalt - View your account",
				},
				children: [
					{
						path: "profile",
						name: " Account Profile",
						component: AccountProfile,
						meta: {
							title: "Blusalt - Account Profile",
						},
					},
					{
						path: "/",
						name: "Account Wallet",
						component: AccountWallet,
						meta: {
							title: "Blusalt - Account Wallet",
						},
					},
					{
						path: "services",
						name: "Account Services",
						component: AccountServices,
						meta: {
							title: "Blusalt - Account Services",
						},
					},
					{
						path: "request-logs",
						name: "Request Logs",
						component: RequestLogs,
						meta: {
							title: "Blusalt - Request Logs",
						},
					},
					{
						path: "team",
						name: "Teams",
						component: Teams,
						meta: {
							title: "Blusalt - Account Teams",
						},
					},
					{
						path: "kyc",
						name: "AccountKYC",
						component: AccountKYC,
						meta: {
							title: "Blusalt - Account KYC",
						},
					},
				],
			},
			{
				path: "/applications",
				name: "Applications",
				component: Applications,
				meta: {
					title: "Blusalt - Start creating applications",
				},
			},
			{
				path: "/direct-debit",
				name: "DirectDebit",
				component: DirectDebit,
				meta: {
					title: "Blusalt - Manage your direct debit process",
				},
			},
			{
				path: "/wallets",
				name: "WalletList",
				component: Wallets,
				meta: {
					title: "Blusalt - View all your wallets",
				},
			},

			{
				path: "/wallet/card",
				name: "Wallet Card",
				component: Card,
				meta: {
					title: "Blusalt - Wallet Card",
				},
			},
			{
				path: "/wallets/customers",
				name: "WalletCustomers",
				component: Customers,
				meta: {
					title: "Blusalt - View all your wallet customers",
				},
			},
			{
				path: "/wallet/customer/:reference/:id",
				name: "Customer Detail",
				component: CustomerDetails,
				meta: {
					title: "Blusalt - View customer",
				},
			},
			{
				path: "/messaging",
				name: "Messaging",
				component: Messaging,
				meta: {
					title: "Blusalt - Messaging",
				},
			},
			{
				path: "/wallet/payment",
				name: "Payment",
				component: Payment,
				meta: {
					title: "Blusalt - Payment",
				},
				children: [
					{
						path: "payouts",
						name: "Payouts",
						component: PaymentPayouts,
						meta: {
							title: "Blusalt - View all your wallet payment links",
						},
					},
					{
						path: "disputes",
						name: "Payment Disputes",
						component: PaymentDisputes,
						meta: {
							title: "Blusalt - View all your payment disputes",
						},
					},
					{
						path: "chargebacks",
						name: "chargebacks",
						component: ChargeBackContent,
						meta: {
							title: "Blusalt - View all your payment chargebacks",
						},
					},
					{
						path: "refunds",
						name: "Refunds",
						component: PaymentRefunds,
						meta: {
							title: "Blusalt - View all your payment refunds",
						},
					},
					{
						path: "/",
						name: "Payment Collection",
						component: PaymentCollection,
						meta: {
							title: "Blusalt - View all your payment collection",
						},
					},
				],
			},
			{
				path: "/wallet/payment/chargebacks/:id",
				name: "chargeback",
				component: ChargeBackDetailContent,
				meta: {
					title: "Blusalt - View all your payment chargebacks",
				},
			},
			{
				path: "/wallet/payment-links",
				name: "PaymentLinks",
				component: PaymentLinks,
				meta: {
					title: "Blusalt - View all your wallet payment links",
				},
			},

			{
				path: "/wallet/payment-links/:reference",
				name: "PaymentLink Details",
				component: PaymentLinkDetails,
				meta: {
					title: "Blusalt - View payment link details",
				},
			},
			{
				path: "/wallet/payment/disputes/:id",
				name: "Payment Dispute Details",
				component: PaymentDisputeDetails,
				meta: {
					title: "Blusalt - View payment dispute details",
				},
			},

			{
				path: "/wallet/subscriptions",
				name: "Subscriptions",
				component: Subscriptions,
				meta: {
					title: "Blusalt - Subscription",
				},
				children: [
					{
						path: "plans",
						name: "Plans",
						component: Plans,
						meta: {
							title: "Blusalt - View all your subscription plans",
						},
					},
					{
						path: "coupons",
						name: "Coupons",
						component: Coupons,
						meta: {
							title: "Blusalt - View all your coupons",
						},
					},
					{
						path: "trails",
						name: "Subscription Trails",
						component: SubscriptionTrails,
						meta: {
							title: "Blusalt - View all your subscription trails",
						},
					},
					{
						path: "tax",
						name: "Tax",
						component: Tax,
						meta: {
							title: "Blusalt - View tax",
						},
					},
					{
						path: "/",
						name: "Subscription",
						component: SubscriptionOverview,
						meta: {
							title: "Blusalt - View plan details",
						},
					},
				],
			},
			{
				path: "/wallet/remittance",
				name: "Remittance",
				component: Remittance,
				meta: {
					title: "Blusalt - Remittance",
				},
				children: [
					{
						path: "senders",
						name: "Senders",
						component: Sender,
						meta: {
							title: "Blusalt - View all your subscription plans",
						},
					},

					{
						path: "beneficiaries",
						name: "Beneficiaries",
						component: Beneficiaries,
						meta: {
							title: "Blusalt - View all your subscription plans",
						},
					},
					{
						path: "reversed-transactions",
						name: "Reversed Transactions",
						component: ReversedTransactions,
						meta: {
							title: "Blusalt - View all your coupons",
						},
					},
					{
						path: "saved-transactions",
						name: "Saved Transactions",
						component: SavedTransactions,
						meta: {
							title: "Blusalt - View all your coupons",
						},
					},
					{
						path: "sender/:id",
						name: "Remittance Sender",
						component: SenderDetails,
						meta: {
							title: "Blusalt - View remittance Sender details",
						},
					},
					{
						path: "beneficiary/:id",
						name: "Remittance Sender Details",
						component: BeneficiaryDetails,
						meta: {
							title: "Blusalt - View remittance Sender details",
						},
					},
					{
						path: "/",
						name: "Remittance Transaction History",
						component: TransactionHistory,
						meta: {
							title: "Blusalt - View all remittance",
						},
					},
				],
			},
			{
				path: "/wallet/remittance/send-money",
				name: "Send Money",
				component: SendMoney,
				meta: {
					title: "Blusalt - View all your subscription plans",
				},
			},
			{
				path: "/wallet/terminal",
				name: "Terminal",
				component: Terminal,
				meta: {
					title: "Blusalt - Remittance",
				},
				children: [
					{
						path: "transactions",
						name: "Terminal Transactions",
						component: TerminalTransactions,
						meta: {
							title: "Blusalt - View all your Transactions",
						},
					},
					{
						path: "virtual",
						name: "Virtual Terminal",
						component: VirtualTerminal,
						meta: {
							title: "Blusalt - View all your subscription plans",
						},
					},
					{
						path: "virtual/:id",
						name: "Virtual Termina Details",
						component: VirtualTerminalDetails,
						meta: {
							title: "Blusalt - View all your Virtual Terminal",
						},
					},
					{
						path: "pos",
						name: "POS Terminal",
						component: PosTerminal,
						meta: {
							title: "Blusalt - View all your POS Terminal",
						},
					},
					{
						path: "pos/:id",
						name: "POS Terminal Details",
						component: PosTerminalDetails,
						meta: {
							title: "Blusalt - View all your Virtual Terminal",
						},
					},
					{
						path: "agents",
						name: "Agent",
						component: TerminalAgent,
						meta: {
							title: "Blusalt - View all your Agent",
						},
					},
					{
						path: "agents/:id",
						name: "Agent Details",
						component: TerminalAgentDetails,
						meta: {
							title: "Blusalt - View all your Agent",
						},
					},
					{
						path: "/",
						name: "Overview",
						component: TerminalOverview,
						meta: {
							title: "Blusalt - Terminal Overview",
						},
					},
				],
			},
			{
				path: "/wallet/subscriptions/plans/:reference",
				name: "Plan Details",
				component: PlanDetails,
				meta: {
					title: "Blusalt - View plan details",
				},
			},
			{
				path: "/wallet/subscriptions/trails/:id",
				name: "Subscription Trail Details",
				component: SubscriptionTrailDetails,
				meta: {
					title: "Blusalt - View subscription trail details",
				},
			},
			{
				path: "/wallet/subscription/:id",
				name: "Subscription Details",
				component: SubscriptionDetials,
				meta: {
					title: "Blusalt - View subscription details",
				},
			},

			{
				path: "/wallet/reserved-accounts",
				name: "Reserved Accounts",
				component: ReservedAccounts,
				meta: {
					title: "Blusalt - View all reserved accounts",
				},
			},
			{
				path: "/wallet/reserved-account/:reference",
				name: "Reserved Account",
				component: ReservedAccountDetails,
				meta: {
					title: "Blusalt - View reserved account",
				},
			},
			{
				path: "/wallet/split-payments",
				name: "Split Payments",
				component: SplitGroup,
				meta: {
					title: "Blusalt - View all your split payments",
				},
				children: [
					{
						path: "sub-accounts",
						name: "Sub Accounts",
						component: SubAccounts,
						meta: {
							title: "Blusalt - View all your sub accounts",
						},
					},
					{
						path: "/",
						name: "Split Payments",
						component: SplitPayments,
						meta: {
							title: "Blusalt - View all your split payments",
						},
					},
				],
			},
			{
				path: "/wallet/sub-account/:reference",
				name: "Sub Accounts Details",
				component: SubAccountDetails,
				meta: {
					title: "Blusalt - View sub account detials",
				},
			},
			{
				path: "/wallet/split-payments/:reference",
				name: "Split Payments Details",
				component: SplitPaymentDetails,
				meta: {
					title: "Blusalt - View split payment detials",
				},
			},
			{
				path: "/wallet/payment/payout/details",
				name: "Payout Details",
				component: PaymentPayoutDetails,
				meta: {
					title: "Blusalt - View payout detials",
				},
			},
			{
				path: "/wallet/payment/collection/:reference",
				name: "Payout Collection",
				component: PaymentCollectionDetails,
				meta: {
					title: "Blusalt - View payout collection details",
				},
				children: [
					{
						path: "information",
						name: "Payment Page Information",
						component: PaymentPageInformation,
						meta: {
							title: "Blusalt - Payment Page Information",
						},
					},
					{
						path: "transactions",
						name: "Payment Page Transactions",
						component: PaymentPageTransactions,
						meta: {
							title: "Blusalt - Payment Page Transactions",
						},
					},
				],
			},
			{
				path: "/wallet-:reference",
				name: "Wallet Detail",
				component: WalletDetails,
				meta: {
					title: "Blusalt - View wallet",
				},
			},
			{
				path: "/invoice",
				name: "Invoice",
				component: Invoice,
				meta: {
					title: "Blusalt - Invoice",
				},
				children: [
					{
						path: "products",
						name: "Items",
						component: Items,
						meta: {
							title: "Blusalt - Items",
						},
					},
					{
						path: "drafts",
						name: "Drafts",
						component: Drafts,
						meta: {
							title: "Blusalt - Drafts",
						},
					},
					{
						path: "/",
						name: "Invoice",
						component: InvoiceList,
						meta: {
							title: "Blusalt - Invoice List",
						},
					},
				],
			},
			{
				path: "/invoice/new",
				name: "NewInvoice",
				component: CreateInvoice,
				meta: {
					title: "Blusalt - New Invoice",
				},
				// props: (route) => ({ ...route.params }),
			},
			{
				path: "/transactions",
				name: "Transactions",
				component: TransactionsLog,
				meta: {
					title: "Blusalt - Transactions",
				},
				children: [
					{
						path: "debit",
						name: "Debit History",
						component: DebitHistory,
						meta: {
							title: "Blusalt - Debit Transactions",
						},
					},
					{
						path: "/",
						name: "Credit History",
						component: CreditHistory,
						meta: {
							title: "Blusalt - Credit Transactions",
						},
					},
				],
			},
			{
				path: "/transactions/debit/:reference",
				name: "Debit History Detail",
				component: DebitHistoryDetails,
				meta: {
					title: "Blusalt - View your debit history log details",
				},
			},

			{
				path: "/wallets/transactions",
				name: "Wallet ransactions",
				component: Transactions,
				meta: {
					title: "Blusalt - View all wallet transactions",
				},
				children: [
					{
						path: "collections",
						name: "Wallet Transactions",
						component: CollectionTransactions,
						meta: {
							title: "Blusalt - Collection Transactions",
						},
					},
					{
						path: "transfers",
						name: "Bank Transfer Transactions",
						component: BankTransferTransactions,
						meta: {
							title: "Blusalt - Bank Transafers",
						},
					},
					{
						path: "reserved-account",
						name: "Reserved Account",
						component: ReservedAccountTransactions,
						meta: {
							title: "Blusalt - reserved",
						},
					},
					{
						path: "subscriptions",
						name: "Wallet Subscriptions",
						component: SubscriptionLogs,
						meta: {
							title: "Blusalt - Wallet Subscriptions",
						},
					},
					{
						path: "invoices",
						name: "Invoices",
						component: InvoiceTransactions,
						meta: {
							title: "Blusalt - invoices transactions",
						},
					},
					{
						path: "remittance",
						name: "Remittance",
						component: RemittanceTransactions,
						meta: {
							title: "Blusalt - Remittance transactions",
						},
					},
					{
						path: "/",
						name: "Wallet Transactions",
						component: WalletTransactions,
						meta: {
							title: "Blusalt - Wallets Transactions",
						},
					},
				],
			},
			{
				path: "/wallets-overview",
				name: "WalletOverview",
				component: WalletOverview,
				meta: {
					title: "Blusalt - An overview of all your wallets",
				},
			},
			{
				path: "/wallets/preferences",
				name: "Wallet Preferences",
				component: WalletPreference,
				meta: {
					title: "Blusalt - View wallet preferences",
				},
			},
			{
				path: "/wallets/payment-collection",
				name: "CollectionHome",
				component: CollectionHome,
				meta: {
					title: "Blusalt - View wallet payment collection",
				},
			},
			{
				path: "/request-funds-transfer",
				name: "Request Funds Transfer",
				component: RequestFundsTransfer,
				meta: {
					title: "Blusalt - Request Funds Transfer",
				},
			},
			{
				path: "/funds-transfer",
				name: " Funds Transfer",
				component: FundsTransfer,
				meta: {
					title: "Blusalt -  Funds Transfers",
				},
			},
			{
				path: "/funds-transfer-transactions",
				name: "FTTransactions",
				component: FTTransactions,
				meta: {
					title: "Blusalt - Funds Transfer Transactions",
				},
			},

			{
				path: "/preferences",
				name: "Preferences",
				component: Preferences,
				meta: {
					title: "Blusalt - Preferences",
				},
			},

			{
				path: "/direct-debit/preferences",
				name: "Direct Debit Preferences",
				component: DirectDebitPreferences,
				meta: {
					title: "Blusalt - Direct Debit Preferences",
				},
			},
			{
				path: "/direct-debit/mandates",
				name: "Mandate",
				component: Mandate,
				meta: {
					title: "Blusalt - Direct Debit Preferences",
				},
			},
			{
				path: "/direct-debit/transactions",
				name: "Direct Debit Transactions",
				component: DirectDebitTransactions,
				meta: {
					title: "Blusalt - Direct Debit Transactions",
				},
			},

			{
				path: "/dashboard",
				name: "Dashboard",
				component: Dashboard,
				meta: {
					title: "Blusalt - Welcome to your dashboard",
				},
			},
			{
				path: "/audit",
				name: "AuditTrails",
				component: AuditTrails,
				meta: {
					title: "Blusalt - view audit trail",
				},
			},

			{
				path: "/create-customer",
				name: "CreateCustomer",
				component: CreateCustomer,
				meta: {
					title: "Blusalt - Create a customer",
					whitelabel: true,
				},
			},
			{
				path: "/services",
				name: "Services",
				component: Services,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/facial",
				name: "Services",
				component: FacialServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/customer-info",
				name: "Services",
				component: CustomerInfoServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/business-info",
				name: "Services",
				component: BusinessInfoServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/aml",
				name: "AntiMoneyLaundering",
				component: AmlServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/bills",
				name: "BillPayments",
				component: BillPayments,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/create-customer",
				name: "CreateCustomer",
				component: CreateCustomer,
				meta: {
					title: "Blusalt - Create a customer",
					whitelabel: true,
				},
			},
			{
				path: "/services",
				name: "Services",
				component: Services,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},

			{
				path: "/services/customer-info",
				name: "Services",
				component: CustomerInfoServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/business-info",
				name: "Services",
				component: BusinessInfoServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/aml",
				name: "AntiMoneyLaundering",
				component: AmlServices,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/bills",
				name: "BillPayments",
				component: BillPayments,
				meta: {
					title: "Blusalt - Start Testing your services",
				},
			},
			{
				path: "/services/reports",
				name: "Service Reports",
				component: ServiceReports,
				meta: {
					title: "Blusalt - Transactions",
				},
			},
			{
				path: "/services/reports/:id",
				name: "Service Report Details",
				component: ServiceReportDetails,
				meta: {
					title: "Blusalt - Debit Transactions",
				},
				children: [
					{
						path: "ownership",
						name: "Ownership",
						component: Ownership,
						meta: {
							title: "Blusalt - Ownership",
						},
					},
					{
						path: "personnel",
						name: "Personnel",
						component: Personnel,
						meta: {
							title: "Blusalt - Personnel",
						},
					},
					{
						path: "company-information",
						name: "ContactInformation",
						component: ContactInformation,
						meta: {
							title: "Blusalt - Contact Information",
						},
					},
					{
						path: "main_information",
						name: "MainInformation",
						component: MainInformation,
						meta: {
							title: "Blusalt - Main formation",
						},
					},
					{
						path: "other_information",
						name: "OtherInformation",
						component: OtherInformation,
						meta: {
							title: "Blusalt - Other formation",
						},
					},
				],
			},
			{
				path: "/services/api-docs/",
				name: "API Documents",
				component: APIDocuments,
				meta: {
					title: "Blusalt - API Documents",
				},
				children: [
					{
						path: "keys-and-webhook",
						name: "API Keys",
						component: APIKeys,
						meta: {
							title: "Blusalt - API Keys",
						},
					},
				],
			},
			// {
			//   path: "/request-whitelabelling",
			//   name: "RequestWhitelabelling",
			//   component: RequestWhitelabelling,
			//   meta: {
			//     title: "Blusalt - Request to be a whitelabelled customer",
			//   },
			// },
			// {
			//   path: "/whitelabel-settings",
			//   name: "WhiteLabelSettings",
			//   component: WhiteLabelSettings,
			//   meta: {
			//     title: "Blusalt - Configure white label settings",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-customers/:clientid",
			//   name: "WhiteLabelCustomer",
			//   props: true,
			//   component: SubCustomerDetails,
			//   meta: {
			//     title: "Blusalt - View Details of Sub Customer",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-api-logs/:clientid",
			//   name: "SingleSecondaryCustomerRequestLog",
			//   props: true,
			//   component: SingleSecondaryCustomerRequestLog,
			//   meta: {
			//     title: "Blusalt - View Details of Sub Customer APi logs",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-logs/:clientid",
			//   name: "WhitelabelAPILogs",
			//   props: true,
			//   component: SubCustomerDetails,
			//   meta: {
			//     title: "Blusalt - View Details of Sub Customer",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-customers",
			//   name: "WhiteLabelCustomer",
			//   component: WhiteLabelCustomer,
			//   meta: {
			//     title: "Blusalt - List all your whitelabelled customers",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-analytics",
			//   name: "WhiteLabelCustomer",
			//   component: WhiteLabelDashboard,
			//   meta: {
			//     title: "Blusalt - List all your whitelabelled analytics",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-api-logs",
			//   name: "WhiteLabelCustomerAPILogs",
			//   component: WhiteLabelCustomerAPILogs,
			//   meta: {
			//     title: "Blusalt - See all the logs of your secondary customer",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-transactions",
			//   name: "WhiteLabelTransactions",
			//   component: WhiteLabelTransactions,
			//   meta: {
			//     title: "Blusalt - See all your whitelabel transactions",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-transactions/:clientid",
			//   name: "SingleWhiteLabelTransactions",
			//   component: SingleWhiteLabelTransactions,
			//   props: true,
			//   meta: {
			//     title: "Blusalt - See all a subcustomer transactions",
			//     whitelabel: true,
			//   },
			// },
			// {
			//   path: "/whitelabel-pricing",
			//   name: "WhiteLabelServicePricing",
			//   component: WhiteLabelServicePricing,
			//   meta: {
			//     title: "Blusalt - See all your whitelabel service pricings",
			//     whitelabel: true,
			//   },
			// },
			{
				path: "/alt-account",
				name: "AltAccount",
				component: AltAccount,
				meta: {
					title: "Alt Account Sandbox",
					whitelabel: true,
				},
			},
		],
		beforeEnter(to, from, next) {
			if (!localStorage.getItem("salt-token")) {
				next("/");
			} else {
				next();
			}
		},
	},
];
