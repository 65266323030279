<template>
	<div class="p-md-4 p-3">
		<div class="SectionItem no-b-b mb-3">
			<p class="title">Transactions</p>
		</div>
		<TransactionsTable :source="'blusalt-core'" isFundTransfer />
	</div>
</template>

<script>
import TransactionsTable from "@/components/wallet/common/TransactionsTable/TransactionsTable.vue";

export default {
	name: "FTTransactions",
	components: {
		TransactionsTable,
	},
};
</script>
