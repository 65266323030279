<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreatePaymentDisputeButton
								:disputeRef="$refs.disputeRef"
						/></AccessControl>
					</template>
					<template slot="filterForm">
						<PaymentDisputeFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('disputeRef')"
							:download="downloadDisputes"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="refetch" :isEmpty="isEmpty" :emptyTitle="emptyTitle" >
				<template slot="empty">
					<AccessControl>
						<CreatePaymentDisputeButton
							:disputeRef="$refs.disputeRef"
					/></AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="disputeRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
								{{ timeDateFormat(row.createdAt).time }}
							</span>
						</template>
						<template slot="amount" slot-scope="{ row }">
								{{ toCurrency(row.amount) }}
						</template>
						<template slot="status" slot-scope="{ row }">
							<span :class="statusColor(row.status)">
								{{ titleCase(row.status) }}
							</span>
						</template>

						<template slot="proof_url" slot-scope="{ row }">
							<img
								v-show="row.proof_url"
								src="/assets/img/icons/download.png"
								alt="Download"
								class="pointer"
								width="30px"
								@click="download(row.proof_url)"
							/>
							<button
								class="btn btn--xm py-0 btn--primary ml-3"
								@click="handleClick(row.id)"
							>
								View
							</button>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
	import { componentData } from "../../../../../public/assets/mixins/componentData";
	import CreatePaymentDisputeButton from "../buttons/CreatePaymentDisputeButton/CreatePaymentDisputeButton";
	import PaymentDisputeFilters from "./PaymentDisputeFilters/PaymentDisputeFilters";
	import {disputeTableHeadings, disputeStatus} from "../enums"

	export default {
		mixins: [componentData],
		name: "PaymentDisputes",
		data() {
			return {
				emptyTitle: "No Dispute Found",
				...this.tableOptions(disputeTableHeadings, this.size),
				filters: { status: disputeStatus[1] },
			};
		},
		components: {
			CreatePaymentDisputeButton,
			PaymentDisputeFilters,
		},
		methods: {
			download(link) {
				window.open(link);
			},
			handleClick(id) {
				this.$router.push(`/wallet/payment/disputes/${id}`);
			},
			refetch(filters) {
				let newFilters = { ...this.filters, ...filters }
				if (newFilters.status) newFilters.status = newFilters.status.value
				this.handleRefetch(newFilters, this.getDisputes, 'disputeRef' )
			},
			async getDisputes() {
				this.handleAPICall("payment/getDisputes")
			},
			async downloadDisputes() {
				const download = (filterData) => this.downloadReport(disputeTableHeadings, filterData.data.data);
				this.downloadTableReport("payment/getDisputes", download)
			},
		},
		created() {
			this.getDisputes()
		},
		mounted() {
			this.setColums(disputeTableHeadings)
		},
	};
</script>

<style scoped>
	.status_filter {
		width: 170px;
	}
	.status_filter .form-group {
		margin: 0 !important;
	}
</style>
