<template>
	<div class="mr-3" @click="stopPopa" @focusout="setFalse" tabindex="0">
		<div class="dropdown az-profile-menu">
			<img
				type="button dropdown-toggle"
				data-toggle="dropdown"
				src="/assets/img/icons/ellipsis.svg"
				alt
				width="30px"
			/>

			<div class="dropdown-menu p-0 pt-2" :class="{ show }">
				<span class="dropdown-item" @click="handleClick"> Copy </span>
			</div>
		</div>
	</div>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../../public/assets/mixins";

export default {
	mixins: [notifications, componentData],
	name: "PaymentCollectionAction",
	props: ["pageData"],
	data() {
		return {
			show: false,
		};
	},
	components: {},
	methods: {
		handleClick(e) {
			e.stopPropagation();
			const baseURL = process.env.VUE_APP_CHECKOUT_URL;
			const copied = this.copyToClipboard(
				`${baseURL}/page/${this.pageData.reference}`
			);
			copied && this.showSuccessNotification("Link Copied!");
			this.show = false;
		},
		stopPopa(e) {
			e.stopPropagation();
			this.show = !this.show;
		},
		setFalse() {
			this.show = false;
		},
	},
};
</script>
