<template>
	<AccessControl :page="true">
		<div class="p-md-4">
			<div class="row justify-content-between create-invoice">
				<div class="col-12">
					<div
						class="SectionItem no-b-b mb-4 d-flex justify-content-start"
					>
						<img
							src="/assets/img/icons/arrow-left.svg"
							width="23"
							alt=""
							class="pointer"
							@click="goBack"
						/>
						<h4 class="mb-0 pl-3 font-weight-bold" v-if="duplicate">
							Duplicate Invoice
						</h4>
						<h4 class="mb-0 pl-3 font-weight-bold" v-else>
							{{
								`${
									status.createStatus
										? "Create New Invoice"
										: "Update Invoice " +
										  invoice.invoice_reference
								}`
							}}
						</h4>
					</div>
				</div>
				<div class="create_invoice_wrapper">
					<div
						v-if="status.previewStatus"
						class="create-invoice-form"
					>
						<div class="row">
							<div class="col-12">
								<MerchantLogoForm :showForm="true" />
							</div>

							<div class="col-md-6">
								<CustomerSelect
									:customer="request.customer"
									:required="true"
									:error="formErrors.customer"
									:action="openCustomerInfoModal"
									:setRequest="setRequest"
								/>
							</div>

							<div class="col-md-6">
								<CustomSelect
									name="invoice_type"
									v-model="request.invoice_type"
									:label="'Invoice Type'"
									:optionLabel="'name'"
									:error="formErrors.invoice_type"
									:handleError="handleError"
									:options="invoiceType"
								/>
							</div>
							<div
								class="col-md-6"
								v-if="
									request.invoice_type.value === 'recurring'
								"
							>
								<CustomSelect
									name="interval"
									v-model="request.interval"
									:label="'Invoice Interval'"
									:optionLabel="'name'"
									:error="formErrors.interval"
									:handleError="handleError"
									:options="invoiceIntervalOptions"
								/>
							</div>

							<div class="col-md-6">
								<CustomInput
									:name="'shippingAddress'"
									v-model="request.shippingAddress"
									:label="'Shipping Address'"
									:placeholder="'Enter shipping address'"
									:required="true"
									:disabled="true"
									:error="formErrors.shippingAddress"
									:handleError="handleError"
								/>
							</div>

							<div class="col-md-6">
								<CustomInput
									:name="'billingAddress'"
									v-model="request.billingAddress"
									:label="'Billing Address'"
									:placeholder="'Enter billing address'"
									:disabled="true"
									:error="formErrors.billingAddress"
									:handleError="handleError"
								>
									<UpdateBillingAddressButton
										v-if="request.customer.first_name"
										:customer="request.customer"
										:updateAddress="updateAddress"
									/>
								</CustomInput>
							</div>

							<div class="col-md-6">
								<CurrencySelect
									:name="'currency'"
									:handleError="handleError"
									:error="formErrors.currency"
									v-model="request.currency"
									:ngnOnly="true"
								/>
							</div>

							<div
								class="col-md-6"
								v-if="!request.create_and_issue"
							>
								<DateTimePicker
									:name="'issued_date'"
									v-model="request.issued_date"
									:label="'Issued Date'"
									:disabledDates="yesterDay"
									:placeholder="'Issued Date'"
									:format="'YYYY-MM-DD HH:mm:ss'"
								/>
							</div>
							<div class="col-md-6">
								<DateTimePicker
									:name="'due_date'"
									v-model="request.due_date"
									:label="'Payment Due'"
									:disabledDates="yesterDay"
									:placeholder="'Expiry Date'"
									:format="'YYYY-MM-DD HH:mm:ss'"
								/>
							</div>
							<div
								class="col-md-6"
								v-if="request.invoice_type.value === 'one-time'"
							>
								<li class="list-group-item mb-3">
									<h5>
										Allow customer make partial payment for
										this invoice
									</h5>
									<toggle-button
										color="#006848"
										v-model="request.isFlexible"
										:labels="{
											checked: 'Yes',
											unchecked: 'No',
										}"
										:value="true"
										:sync="true"
									/>
								</li>
							</div>

							<div class="col-md-6" v-if="request.isFlexible">
								<CustomSelect
									name="interval"
									v-model="request.interval"
									:label="'Payment Interval'"
									:optionLabel="'name'"
									:error="formErrors.interval"
									:handleError="handleError"
									:options="invoiceIntervalOptions"
								/>
							</div>

							<div
								:class="`${
									request.isFlexible ? 'col-md-6' : 'col-12'
								}`"
							>
								<CustomInput
									:name="'description'"
									v-model="request.description"
									:label="'Invoice Summary'"
									:placeholder="'Enter a brief description'"
									:error="formErrors.description"
									:handleError="handleError"
								/>
							</div>

							<div class="col-12 my-5">
								<ItemsTable
									:updateRequest="setRequest"
									:updateItem="updateItem"
									:payload="request"
								/>
							</div>

							<div class="col-md-6">
								<CustomTextArea
									:name="'notes'"
									v-model="request.notes"
									:label="'Customer Notes'"
									:placeholder="'Enter customer notes'"
								/>
							</div>

							<div class="col-md-6">
								<CustomTextArea
									:name="'terms_and_condition'"
									v-model="request.terms_and_condition"
									:label="'Terms And Condition'"
									:placeholder="'Enter terms and condition'"
								/>
							</div>
						</div>
					</div>

					<PreviewInvoice
						v-else
						:invoice="request"
						:changeStatus="changeStatus"
					/>

					<div class="create-invoice-button">
						<InvoiceCreationActionButton
							:loading="loading"
							:sLoading="sLoading"
							:status="status"
							:changeStatus="changeStatus"
							:handleSubmit="handleSubmit"
							:duplicate="duplicate"
						/>

						<div
							v-if="!this.$route.params.invoice || this.duplicate"
							class="form-group col-12 mt-4"
						>
							<label class="d-flex align-items-center">
								<input
									:name="'create_and_issue'"
									type="checkbox"
									:value="false"
									v-model="request.create_and_issue"
								/>
								<span class="ml-2 fs-15" style="margin-top: 2px"
									>Issue Invoice Immediately</span
								>
							</label>
						</div>
					</div>
				</div>
			</div>

			<InvoiceActionModal
				:status="status.createStatus"
				:customer="request.customer"
				:handleCreateInvoice="handleCreateInvoice"
				:backToInvoice="backToInvoice"
				:closeModal="closeModal"
				:duplicate="duplicate"
			/></div
	></AccessControl>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../public/assets/mixins/filters";
import ItemsTable from "./ItemsTable/ItemsTable";
import PreviewInvoice from "../PreviewInvoice/PreviewInvoice";
import InvoiceActionModal from "../modals/InvoiceActionModal/InvoiceActionModal";
import InvoiceCreationActionButton from "../buttons/InvoiceCreationActionButton/InvoiceCreationActionButton";
import UpdateBillingAddressButton from "../buttons/UpdateBillingAddressButton/UpdateBillingAddressButton";
import MerchantLogoForm from "../common/MerchantLogoForm/MerchantLogoForm";
import { invoiceType, invoiceIntervalOptions } from "../enums";
import "./CreateInvoice.css";

const initialState = () => ({
	request: {
		currency: "NGN",
		line_items: [],
		issued_date: "",
		due_date: "",
		description: "",
		customer: {},
		invoice_type: invoiceType[0],
		interval: invoiceIntervalOptions[0],
		shippingAddress: "",
		billingAddress: "",
		create_and_issue: false,
		tax: "",
		isFlexible: false,
		couponCode: "",
		notes: "",
		terms_and_condition: "",
	},
	loading: false,
	sLoading: false,
	showCustomerInfo: false,
	formErrors: {},
	status: {
		previewStatus: true,
		createStatus: true,
	},
	invoice: {},
	duplicate: null,
});

export default {
	mixins: [filters, notifications],
	name: "CreateInvoice",
	data() {
		return { invoiceType, invoiceIntervalOptions, ...initialState() };
	},
	components: {
		ItemsTable,
		PreviewInvoice,
		InvoiceActionModal,
		MerchantLogoForm,
		UpdateBillingAddressButton,
		InvoiceCreationActionButton,
	},
	methods: {
		changeStatus(status) {
			this.status = { ...this.status, [status]: !this.status[status] };
		},
		goBack() {
			this.$router.go(-1);
		},
		backToInvoice() {
			this.closeModal();
			this.goBack();
		},
		updateAddress(address) {
			this.request.billingAddress = address;
		},
		openCustomerInfoModal(customer) {
			if (customer) this.request.customer = customer;
			$("#customerInfo").modal("show");
		},
		setRequest(name, value) {
			this.request[name] = value;
			this.handleError(name);
		},
		openWarningModal() {
			$("#addressWarning").modal("show");
		},
		closeModal() {
			$("#addressWarning, #invoiceCreationSuccess, #customerInfo").modal(
				"hide"
			);
		},
		getShippingAddress() {
			const { state, city } = this.$store.state.account.profile;
			const { country, api_customer } = this.$store.state.account.profile;
			const cityState =
				city == state.state_label
					? city
					: `${city}, ${state.state_label}`;

			this.request.shippingAddress = `${api_customer.address} ${cityState}, ${country.name}`;
		},
		setAddress(add) {
			const { country, state, address } = add || this.request.customer;
			this.request.billingAddress = address
				? `${address || ""} ${state || ""} ${country || ""}`
				: "";
		},
		updateItem(items) {
			this.request.line_items = items;
		},
		handleSubmit(e, option) {
			e.preventDefault();
			const { customer, currency, billingAddress } = this.request;
			const { formErrors, isValid } = this.formValidator({
				customer,
				currency,
			});

			if (isValid) {
				if (option === "preview") this.changeStatus("previewStatus");
				else if (billingAddress) {
					if (option === "create") this.handleCreateInvoice();
					else if (option === "save") this.handleCreateInvoice(true);
				} else this.openWarningModal();
			} else this.formErrors = formErrors;
		},
		getRequestData(isSave) {
			const req = { ...this.request };

			let line_items = req.line_items.reduce((acc, arr) => {
				const index = acc.findIndex(
					(item) => item.reference === arr.name.reference
				);

				if (index >= 0) {
					const newAcc = [...acc];
					const { reference, quantity } = newAcc[index];
					newAcc[index] = {
						reference: reference,
						quantity: parseInt(quantity) + parseInt(arr.quantity),
					};

					return newAcc;
				} else {
					if (arr.name.reference)
						return [
							...acc,
							{
								reference: arr.name.reference,
								quantity: parseInt(arr.quantity),
							},
						];
					else return acc;
				}
			}, []);

			const data = {
				create_and_issue: isSave ? false : req.create_and_issue,
				line_items,
				customer: req.customer.reference,
				currency: req.currency.code,
				isFlexible: req.isFlexible,
			};

			req.couponCode && (data.couponCode = req.couponCode.couponCode);
			req.due_date && (data.due_date = req.due_date);
			req.issued_date && (data.issued_date = req.issued_date);
			req.tax && (data.tax = req.tax.id);
			req.description && (data.description = req.description);
			req.invoice_type && (data.invoice_type = req.invoice_type.value);
			req.interval &&
				(data.interval = {
					period: req.interval.value,
				});
			req.terms_and_condition &&
				(data.terms_and_condition = req.terms_and_condition);
			req.notes && (data.notes = req.notes);

			if (data.create_and_issue) {
				delete data.issued_date;
			}
			if (data.invoice_type === "one-time") {
				!req.isFlexible && delete data.interval;
			} else delete data.isFlexible;

			return data;
		},
		async handleCreateInvoice(isSave) {
			try {
				if (isSave) this.sLoading = true;
				else this.loading = true;

				const URL =
					this.status.createStatus || this.duplicate
						? "invoices/createInvoice"
						: "invoices/updateInvoice";

				let payload = {
					apiKey: this.getWalletApikey(this.$store),
					request: this.getRequestData(),
				};
				if (isSave) payload.request = this.getRequestData(true);

				if (!this.status.createStatus && !this.duplicate) {
					payload.reference = this.invoice.invoice_reference;
					delete payload.request.currency;
					delete payload.request.create_and_issue;
				}
				const response = await this.$store.dispatch(URL, payload);
				const { status, data } = response || {};

				if (
					(status === 201 || status === 200) &&
					(data.status || data.success)
				) {
					if (isSave) this.sLoading = false;
					else this.loading = false;
					$("#invoiceCreationSuccess").modal("show");
				} else this.setError(data.message, isSave);
			} catch (error) {
				this.setError(this.getError(error), isSave);
			}
		},
		setError(msg, isSave) {
			if (isSave) this.sLoading = false;
			else this.loading = false;
			this.showErrorLoginNotification(msg);
		},
		prefillField(invoice) {
			const { coupon } = invoice.other_info || {};
			const invoice_type = invoiceType.find(
				({ value }) => value === invoice.invoice_type
			);
			const interval = invoiceIntervalOptions.find(
				({ value }) => value === invoice.interval.period
			);
			const line_items = invoice.other_info.calculated_items.map(
				(v, i) => ({
					...v,
					name: {
						name: v.name,
						reference: JSON.parse(invoice.line_items[i]).reference,
						amount: v.amount,
						quantity: v.quantity,
					},
				})
			);

			const dueDate = this.timeDateFormat(
				invoice.due_date,
				"YYYY-MM-DD HH:mm:ss"
			);
			const issuedDate = this.timeDateFormat(
				invoice.issued_date,
				"YYYY-MM-DD HH:mm:ss"
			);

			const data = {
				currency: invoice.currency,
				create_and_issue: false,
				line_items,
				due_date: invoice.due_date ? dueDate.otherDate : "",
				issued_date: invoice.issued_date ? issuedDate.otherDate : "",
				tax: invoice.other_info.tax ? invoice.other_info.tax : "",
				description: invoice.description ? invoice.description : "",
				isFlexible: invoice.partial.is_partial || false,
				invoice_type,
				interval,
				customer: invoice.other_info.customer,
				couponCode: coupon ? coupon.coupon : "",
				notes: invoice.notes ? invoice.notes : "",
				terms_and_condition: invoice.terms_and_condition
					? invoice.terms_and_condition
					: "",
			};

			this.invoice = invoice;
			this.changeStatus("createStatus");
			this.request = { ...this.request, ...data };
			this.setAddress();
		},
	},
	watch: {
		"request.customer": function (v) {
			const { address_one, id } = v || {};
			this.request.billingAddress = address_one;
			id && this.openCustomerInfoModal();
		},
	},
	mounted() {
		this.getShippingAddress();
		const update = this.$route.params.invoice;
		this.duplicate = this.$route.params.duplicate;
		update && this.prefillField(update);
	},
};
</script>
