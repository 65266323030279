<template>
	<div
		class="tab-pane fade card"
		id="settlement_accounts"
		role="tabpanel"
		aria-labelledby="settlement_accounts-tab"
	>
		<div v-if="loading" class="flex__center mt-5 pt-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="getWalletSettings"
		/>

		<template v-else>
			<div v-if="banks.length > 1" class="flex__center py-5">
				<div class="text-center pb-5">
					<i class="la la-xing la-4x" />
					<h4 class="font-weight-bold my-4">
						Settlement Accounts has not been added yet
					</h4>
					<AddBankButton />
				</div>
			</div>

			<div v-else>
				<div class="d-flex justify-content-end p-3">
					<AddBankButton :refetch="refetch" />
				</div>

				<v-client-table
					:data="bankAccounts"
					:columns="columns"
					:options="options"
				>
					<template slot="createdAt" slot-scope="props">
						<span>
							{{
								timeDateFormat(props.row.createdAt).date +
								" " +
								timeDateFormat(props.row.createdAt).time
							}}
						</span>
					</template>
				</v-client-table>
			</div>
		</template>
	</div>
</template>

<script>
import AddBankButton from "../../WalletOverview/buttons/AddBankButton/AddBankButton";
import Spinner from "../../../../utils/Spinner/Spinner";
import FailedState from "../../../../utils/ResponseState/FailedState";
import { tableOptions, timeDateFormat } from "../../../../utils/helpers";

const tableHeadings = {
	bank: "Bank Name",
	account_number: "Account Number",
	account_name: "Account Name",
	createdAt: "Date Added",
};

export default {
	name: "BankSettings",
	props: ["bankAccounts", "refetch"],
	data() {
		return {
			loading: false,
			errorMsg: "",
			banks: [],
			...tableOptions(tableHeadings),
		};
	},
	components: {
		AddBankButton,
		FailedState,
		Spinner,
	},
	methods: {
		timeDateFormat,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
