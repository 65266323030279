<template>
  <div class="pt-3">
    <template>
      <div class="content__slider">
        <Tabs
          :tabNames="['Online_Transactions', 'Cash_Registers']"
          :tabId="'subTab'"
        />
      </div>

      <div class="tab-content" id="subTabContent">
        <OnlineTransactions />
        <CashRegister />
      </div>
    </template>
  </div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Tabs from "../../../../utils/Tabs/Tabs.vue";
import OnlineTransactions from "./OnlineTranactions/OnlineTranactions.vue";
import CashRegister from "./CashRegisters/CashRegisters.vue";

export default {
  mixins: [notifications],
  name: "Transactions",
  data() {
    return {};
  },
  components: {
    Tabs,
    OnlineTransactions,
    CashRegister,
  },
  methods: {},
  created() {},
};
</script>
