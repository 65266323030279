<template>
	<div class="d_content">
		<div v-show="showSide" class="content__sideview active">
			<div class="flex__between mb-3">
				<div
					v-if="prevRoute"
					class="d-flex pointer align-items-center"
					@click="goBack()"
				>
					<img
						src="/assets/img/icons/arrow-left.svg"
						width="30"
						alt=""
						
					/>
					<h5 class="m-0 ml-2">Back</h5>
				</div>

				<div class="togg-wrap">
					<img
						src="/assets/img/icons/d-caret-right.svg"
						alt=""
						class="togg"
						@click="switchContent"
					/>
				</div>
			</div>
			<slot name="sideview" />
		</div>

		<div class="main__content">
			<div class="togg-wrap">
				<img
					src="/assets/img/icons/d-caret-right.svg"
					alt=""
					class="togg"
					@click="switchContent"
				/>
			</div>
			<slot name="content" />
		</div>
	</div>
</template>

<script>
	import "./SplitContent.css";

	export default {
		name: "SplitContent",
		props: {
			prevRoute: {
				type: String,
			},
			showSide: {
				type: Boolean,
				default: true,
			},
		},
		methods: {
			switchContent() {
				JQuery(".main__content").toggleClass("active");
				JQuery(".content__sideview").toggleClass("active");
			},
			goBack() {
				if (this.prevRoute) this.$router.push(this.prevRoute);
			},
		},
	};
</script>
