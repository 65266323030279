<template>
	<div>
		<button
			class="btn btn-sm btn--primary px-3"
			:disabled="disable"
			@click="openMakeTransferModal"
		>
			Make Transfer
		</button>

		<MakeTransferModal
      :settings="settings"
			:id="'maketransfer'"
			:payload="payload"
			:closeModal="closeModal"
			:resetTransfer="resetTransfer"
		/>
	</div>
</template>

<script>
	import MakeTransferModal from "../../modals/MakeTransferModal/MakeTransferModal";

	export default {
		name: "MakeTransferButton",
		props: ["payload", "disable", "resetTransfer", "settings"],
		components: {
			MakeTransferModal,
		},
		methods: {
			openMakeTransferModal() {
				$("#maketransfer").modal("show");
			},
			closeModal() {
				$("#maketransfer").modal("hide");
			},
		},
	};
</script>
