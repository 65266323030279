<template>
  <div class="card-body">
    <apexchart height="600" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    series: {
      type: Array
    }
  },
  data() {
    return {
      chartOptions: {
        fontFamily: "AllianzNeo, sans-serif",
        style: {
          fontSize: "20px",
          color: "#263238",
          width: "100%"
        },
        legend: {
          show: false
        },
        chart: {
          type: 'bar',
          height: 450,
          stacked: true,
        },
        plotOptions: {
          bar: {
            vertical: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Identity Verification Service Chart',
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "20px",
            color: "#263238",
            width: "100%"
          }
        },
        xaxis: {
          //  this.dashboardData.totalBvnCalls,
          //     this.dashboardData.totalNinCalls,
          //     this.dashboardData.totaliBVNCalls,
          //     this.dashboardData.totalPvcCalls,
          //     this.dashboardData.totalNipCalls,
          //     this.dashboardData.totalLicenceCalls,
          //     this.dashboardData.totalAddressCalls,
          //     this.dashboardData.totalPivCalls,
          //     this.dashboardData.totalLivenessCheckCalls,
          //     this.dashboardData.totalFacialComparisonCalls,
          //     this.dashboardData.totalNum2BankCalls,
          //     this.dashboardData.totalBankAccountCalls,//
          categories: ["BVN", 'NIN', 'iBVN', 'PVC', 'NIP', 'DriverLicence', 'AddressVerification', 'OCR', "LivenessCheck", "FaceComparison", "Num2Bank", "BankAccountVerification"],
          labels: {
            formatter: function (val) {
              return val
            },
            title: 'Total Calls'
          }
        },
        yaxis: {
          labels: {
            text: 'Identity Verification Service'
          }

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Calls"
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    }

  }
}
</script>