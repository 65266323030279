<template>
	<!-- <div class="az-footer" :style="{backgroundColor : appSettings.footer_color}"> -->
	<div class="az-footer">
		<div class="container-fluid">
			<span>{{
				appSettings.copy_right_info || `© ${year} BluSalt`
			}}</span>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			appSettings: {
				type: Object,
			},
		},
		computed: {
			year() {
				return new Date().getFullYear();
			},
		},
	};
</script>
