<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <!-- Aml Service -->
                <h2 class="user-profile-name">Anti Money Laundering Form</h2>
                <h5 class="user-profile-email sub">
                  Get to know who your potential partners really are
                </h5>
              </div>
            </div>
            <div class="content">
              <span class="badge badge-warning" style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'">(Each API call costs &#8358;{{ `${servicePrice}`
                }})</span>
              <span v-if="!hasService" class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500">You don't have access to this service</span>
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="amlLookup">
              <div class="row mt-4">
                <!-- Application details -->
                <div class="col-md-12">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required">*</span>
                  </label>
                  <v-select class="v-select" placeholder="Select an application" v-model="app"
                    @keydown.enter.native.prevent :required="!app" :disabled="!application" :options="application"
                    label="app_name"></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row mt-4">
                <!-- Service Type -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Service Type</label>
                    <span class="text-danger" title="This field is required">*</span>
                    <v-select class="v-select" placeholder="Select a service" v-model="serviceType"
                      @keydown.enter.native.prevent :required="!serviceType" :disabled="!app" :options="serviceTypes">
                    </v-select>
                  </div>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select class="v-select" placeholder="Select an Currency Type" v-model="currencyType"
                    @keydown.enter.native.prevent :disabled="!customerWallets" :options="customerWallets"
                    label="currency"></v-select>
                </div>

                <!-- Search Entity -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="input-item-label">Name</label>
                    <span class="text-danger" title="This field is required">*</span>
                    <input type="text" v-model="searchEntity" class="form-control input-bordered"
                      placeholder="Enter name of person or organization" />
                  </div>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input type="text" v-model="call_id" class="form-control input-bordered"
                          placeholder="Enter your bundle call ID" />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button @click.prevent="initializeApp(app)" class="btn btn-block btn-lg btn-info mt-1">
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button class="btn btn-lg btn-primary mg-b-20" type="submit"
                    :disabled="isSubmitting == true">
                    <span v-if="isSubmitting">...lookup in progress</span>
                    <span v-else>Search</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- End of transition -->
      </transition>

      <div class="user-settings-section" v-if="hasNewResponse">
        <div class="user-settings-section-header">
          <div class="user-settings-section-header-text">
            <h1>Anti Money Laundering Response</h1>
            <span class="text-span">Preview of the information gotten from Anti Money Laundering request made</span>
          </div>
          <a href="#" class="btn btn-outline-danger" @click="hasNewResponse = false">
            <i class="la la-close"></i>Close Panel
          </a>
        </div>
        <div style="margin-bottom: 20px" :key="index" v-for="(result, index) in verificationResult.result"
          class="preview-content">
          <p class="invoice-info-row preview-row">
          <h3>Name</h3>
          <span>{{ result.name}}</span>
          </p>
          <p class="invoice-info-row preview-row">
            <span>Is complete match</span>
            <span>{{ result.isCompleteMatch}}</span>
          </p>
          <p class="invoice-info-row preview-row">
            <span>Remarks</span>
            <span>{{ result.remarks}}</span>
          </p>
          <p class="invoice-info-row preview-row">
            <span>Source List</span>
            <span>{{ result.source_list_url || "Source list not available"}}</span>
          </p>
          <p class="invoice-info-row preview-row">
            <span>Source Information</span>
            <span>{{ result.source_information_url || "Source information not available"}}</span>
          </p>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre class="code code-html"><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}Aml/Sanctions`
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "name": "{{ searchEntity || "searchEntity"}}"
        "search_type": "{{ serviceType ? serviceType.value.toLowerCase() : "serviceType"}}"
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
                    {
              "status_code": 200,
              "message": "Results fetched successfully",
              "matches_found": "0",
              "search": "Sanction",
              results: [ ]
            }`}}
      </code></pre>
        </div>
      </div>
    </div>

    <ApplicationInitializationModal :app="app" :initializedAppResult="initializedAppResult">
    </ApplicationInitializationModal>

    <!-- Modal -->
    <div class="modal fade" id="sandboxModal" tabindex="-1" role="dialog" aria-labelledby="sandboxModalitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              <i class="la la-exclamation-circle"></i> Notice
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Hey! You are on our <strong>Sandbox Environment</strong>. Data
              shown below as well as the email that will be recieved from this
              request are demo data and do not necessarily show actual results
              of your queries.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import {
  required,
} from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
console.log("ABout to run Script....");
export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "v2",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      billersData: [],
      serviceType: null,
      biller: "",
      amount: "",
      customerId: "",
      searchEntity: "",
      customerInfo: null,
      selectedProduct: null,
      countrycode: "",
      local_format: "",
      mno: "",
      hasNewResponse: false,
      country_prefix: "",
      logData: [],
      apiResponse: "",
      // services
      hasService: false,
      servicePrice: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      isSubmitting: false,
      verificationResult: [],
    };
  },
  validations: {
    apiVersion: { required },
    serviceType: { required },
    app: { required },
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      console.log("New-Response >>>>", newValue);
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
    serviceTypes() {
      return [
        { label: "Global PEP", value: "PEP" },
        { label: "Global SANCTION", value: "SANCTION" }
      ];
    },
  },
  methods: {
    computeService() {
      console.log("COMPUTE-SERVICE >>>>");
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Aml")) {
            console.log("YEAH 'MOBILE' FOUND >>>>", service.service_label);
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },

    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          name: this.searchEntity,
          search_type: this.serviceType.value.toLowerCase(),
        },
        query: this.call_id,
      };

      return verificationData;
    },
    async amlLookup() {
      console.log("LookUp-Aml ----->");
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        console.log("Aml-Payload ----->", verificationData);
        let apiResponse = await this.$store.dispatch(
          "services/amlLookup",
          verificationData
        );
        console.log("[api Response from Aml] ->", apiResponse);
        this.apiResponse = apiResponse;
        this.showSuccessNotification(apiResponse.message);
        this.verificationResult = apiResponse;
        this.hasNewResponse = true;
        this.isSubmitting = false;
        if (this.apiResponse.environment === "sandbox") {
          $("#sandboxModal").modal();
        }
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
