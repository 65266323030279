<template>
  <div
    class="tab-pane fade show"
    id="Terminal_Transactions"
    role="tabpanel"
    aria-labelledby="Terminal_Transactions-tab"
  >
    <div class="row">
      <div class="col-12 mt-3">
        <div class="px-3">
          <FilterWrapper>
            <template slot="pageSelect"> </template>
            <template slot="filterForm">
              <TransactionsTableFilters
                :loading="loading"
                :rLoading="rLoading"
                :submitFilter="refetch"
                :clearFilter="clearFilter"
                :download="downloadCoupon"
              />
            </template>
          </FilterWrapper>
        </div>
      </div>
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div v-else-if="errorMsg" class="col-12">
        <FailedState :errorMsg="errorMsg" :refetch="refetch" />
      </div>

      <div class="col-12" :class="tbClass">
        <v-server-table
          ref="couponRef"
          class="v-table"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
          @row-click="showData"
        >
          <template slot="amount" slot-scope="{ row }">
            <span>
              {{ toCurrency(row.amount, row.currency) }}
            </span>
          </template>
          <template slot="type" slot-scope="{ row }">
            <span>
              {{ titleCase(row.type) }}
            </span>
          </template>
          <template slot="createdAt" slot-scope="{ row }">
            {{ timeDateFormat(row.createdAt).date }}
          </template>
          <template slot="status" slot-scope="{ row }">
            <span :class="statusColor(row.status)">{{
              titleCase(row.status)
            }}</span>
          </template>
        </v-server-table>
      </div>
    </div>
    <TransactionModal
      v-if="showModal"
      :data="modalData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { componentData } from "../../../../../../../public/assets/mixins/componentData";
import TransactionModal from "../../../../../wallet/common/TransactionsTable/TransactionsTableModal.vue";
import TransactionsTableFilters from "../../../Transactions/TransactionsTableFilters/TransactionsTableFilters.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  getWalletApikey,
  toCurrency,
  statusColor,
} from "@/utils/helpers";

const tableHeadings = {
  reference: "Reference",
  type: "Type",
  amount: "Amount",
  createdAt: "Created At",
  status: "Status",
};

export default {
  mixins: [componentData],
  name: "Transactions",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 20,
      filters: {},
      ...tableOptions(tableHeadings, 20),
    };
  },
  components: {
    TransactionModal,
    TransactionsTableFilters,
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    toCurrency,
    statusColor,
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getVirtualTerminalsTransactions();
      this.$refs.couponRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.couponRef.refresh();
    },
    async getVirtualTerminalsTransactions() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
            id: this.$route.params.id,
          },
        };
        return await this.$store
          .dispatch("pos/getVirtualTerminalsTransactions", payload)
          .then((response) => {
            const resp = response.data.data;
            return { ...resp, count: resp.total };
          });
      };
    },
    async downloadCoupon() {
      const download = (filterData) => {
        this.downloadReport(tableHeadings, filterData.data);
      };
      this.downloadTableReport("pos/getVirtualTerminalsTransactions", download);
    },
  },

  created() {
    this.getVirtualTerminalsTransactions();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
