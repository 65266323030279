<template>
	<Modal
		:id="id"
		:title="'Resolve Account Details'"
		:toggle="closeModal"
		class="text-left"
	>
		<ResolveAccountForm
			:loading="loading"
			:closeModal="closeModal"
			:formSubmit="next"
			:account_number="acctNumber"
		/>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../utils/Modal/Modal";
	import ResolveAccountForm from "../../../wallet/common/ResolveAccountForm/ResolveAccountForm";
	import { getCSVFileObject } from "../../../../utils/helpers";

	const initialState = () => ({
		loading: false,
		request: {},
	});

	export default {
		mixins: [notifications],
		name: "UpdateFTAccountModal",
		props: [
			"id",
			"closeModal",
			"account",
			"accountFile",
			"updateAccountList",
		],
		data() {
			return { ...initialState() };
		},
		components: {
			Modal,
			ResolveAccountForm,
		},
		computed: {
			acctNumber() {
				const { accountNumber } = this.account.row || {};
				return accountNumber ? parseInt(accountNumber) : "";
			},
		},
		methods: {
			handleUpdateAccount(account) {
				let newAccountFile = [...this.accountFile];
				newAccountFile[this.account.index - 1] = account;
				this.updateAccountList(newAccountFile);
				this.closeModal();
			},
			next(data) {
				const { index, row } = this.account || {};
				const newAccount = [...this.accountFile].splice(index, 1);
				const exist = newAccount.find(
					({ accountNumber, amount }) =>
						accountNumber === data.account_number &&
						amount === row.amount
				);
				if (exist)
					this.showErrorLoginNotification("Account Already Exist!!!");
				else this.verifiyAccounts(data);
			},
			async verifiyAccounts(account) {
				try {
					this.loading = true;
					const file = getCSVFileObject([
						{
							accountNumber: account.account_number,
							accountName: account.account_name,
							bankCode: account.bank.code,
							narration: this.account.row.narration,
							amount: this.account.row.amount,
						},
					]);
					const formData = new FormData();
					formData.append("file", file);

					const response = await this.$store.dispatch(
						"fundstransfer/verifyAccountsBulk",
						{ formData }
					);
					const { status, data } = response;

					if (status === 200 && data.status) {
						this.loading = false;
						this.handleUpdateAccount(data.data[0]);
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(
						"Error Updating Account. Try Again !!!"
					);
				}
			},
		},
	};
</script>
