<template>
	<div class="flex__between mb-5">
		<h4 class="font-weight-bold text--primary mb-0 mr-4">{{ name }}</h4>

		<div class="merchant_logo">
			<img v-if="logo" :src="logo" alt="" />

			<template v-else>
				<AddLogoButton
					v-if="showForm"
					:inv="true"
					:logo="logo"
					:setLogo="setLogo"
				/>
			</template>
		</div>
	</div>
</template>

<script>
import AddLogoButton from "../../../Preferences/buttons/AddLogoButton/AddLogoButton";

export default {
	name: "MerchantLogoForm",
	props: ["showForm"],
	data() {
		return {
			merchantLogo: "",
		};
	},
	components: {
		AddLogoButton,
	},
	methods: {
		setLogo(logo) {
			this.merchantLogo = logo;
		},
	},
	computed: {
		logo() {
			return (
				this.merchantLogo || this.$store.state.account.customerKeys.logo
			);
		},
		name() {
			return this.$store.state.account.profile.api_customer
				.organization_name;
		},
	},
};
</script>

<style>
.merchant_logo {
	display: flex;
	align-items: center;
	max-width: 370px;
	margin-left: auto;
	cursor: pointer;
}

.merchant_logo img {
	max-height: 50px;
	height: auto;
}

.merchant_logo_form {
	padding: 15px;
	border: 2px dashed #bdbdbd;
	display: flex;
	align-items: center;
}

.merchant_logo_form span {
	color: #bdbdbd;
}
</style>
