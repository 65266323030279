<template>
  <div id="apikeyCreateNew" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-createApplication"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <h5 class="modal-title mg-b-5">Create New Application</h5>
          <p
            class="fs-14"
          >Let's get you all setup so you can begin using our app. (Services selected here are services that would be avaialble to application)</p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="createApplication">
            <!-- CLient ID -->
            <div class="form-group">
              <label class="input-item-label">Client ID</label>
              <input
                type="text"
                disabled
                class="form-control input-bordered"
                :placeholder="clientID"
              />
            </div>
            <!-- Application Name -->
            <div class="form-group">
              <label class="input-item-label">
                Application Name
                <span class="text-danger" title="This field is required">*</span>
              </label>
              <input
                type="text"
                class="form-control input-bordered"
                placeholder="Enter application name"
                v-model="applicationName"
              />
              <ul class="parsley-errors-list filled" v-show="$v.applicationName.$error">
                <li class="parsley-required">Application name is required.</li>
              </ul>
            </div>

            <!-- Application Services -->
            <div class="form-group">
              <label class="input-item-label">
                Select Services
                <span class="text-danger" title="This field is required">*</span>
              </label>
              <a href @click.prevent="selectAllServices" class="right">Select all services</a>
              <v-select
                class="v-select h-auto"
                placeholder="Select a service"
                v-model="services"
                @keydown.enter.native.prevent
                :required="!services"
                :options="userServices"
                label="service_label"
                multiple
              ></v-select>
              <ul class="parsley-errors-list filled" v-show="$v.services.$error">
                <li class="parsley-required">Services are required.</li>
              </ul>
            </div>

            <!-- Application Credit limit -->
            <div class="form-group mt-4">
              <label class="ckbox">
                <input type="checkbox" v-model="unlimited" />
                <span style="font-size: 15px">
                  Cap credit available for app
                  <span
                    class="text-danger"
                    title="This field is required"
                  >*</span>
                </span>
              </label>
              <span
                class="input-note"
              >Note: Setting a cap to your API credit limit would limit the credit limit on the app.</span>
            </div>

            <div class="form-group" v-if="unlimited == 1">
              <label class="input-item-label">App Credit</label>
              <input
                type="text"
                class="form-control input-bordered"
                v-model="creditLimit"
                placeholder
              />
            </div>

            <div class="form-group mt-4">
              <label class="input-item-label">
                Billing Type
                <span class="text-danger" title="This field is required">*</span>
              </label>
              <label class="rdiobox">
                <input name="billing" type="radio" value="1" v-model="billingType" />
                <span class="fs-15">Core Billing ( You will be charged per call for any service )</span>
              </label>
              <div class="gaps-1x"></div>
              <label class="rdiobox">
                <input name="billing" type="radio" value="2" v-model="billingType" />
                <span class="fs-15" v-if="billingType == '2'">
                  Bundled Billing ( Services will be billed as a bundle, total bundle cost is
                  <strong>{{ getBundledPrice }}</strong>)
                </span>
                <span class="fs-15" v-else>Bundled Billing (Services will be billed as a bundle)</span>
              </label>
              <ul class="parsley-errors-list filled" v-show="$v.billingType.$error">
                <li class="parsley-required">Billing Type are required.</li>
              </ul>
            </div>

            <div class="gaps-2x"></div>
            <button type="submit" class="btn btn-lg btn-primary" :disabled="isProcessing == true">
              <span v-if="isProcessing">Creating application</span>
              <span v-else>Create</span>
            </button>
            <button type="button" class="btn btn-lg btn-light ml-2" data-dismiss="modal">Close</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from 'nprogress'
import { required } from 'vuelidate/lib/validators'
import { notifications } from '../../../public/assets/mixins/notifications'

export default {
  mixins: [notifications],
  data() {
    return {
      isProcessing: false,
      services: '',
      creditLimit: 0,
      applicationName: '',
      billingType: null,
      unlimited: null,
      isProcessing: false
    }
  },
  validations: {
    services: { required },
    // creditLimit: { required },
    applicationName: { required },
    billingType: { required }
  },
  computed: {
    userServices() {
      return this.$store.state.account.profile.api_customer.customerServices
    },
    metaData() {
      return this.$store.state.metaData
    },
    clientID() {
      return this.$store.state.account.clientID
    },
    getBundledPrice() {
      let total = 0
      if (this.services) {
        for (let service of this.services) {
          total += service.pivot.price_per_call
        }
      }
      return total
    }
  },
  methods: {
    clearInputFields() {
      this.services = ''
      this.creditLimit = ''
      this.applicationName = ''
      this.billingType = ''
    },
    selectAllServices() {
      this.services = this.userServices
    },
    formatCreateApplicationData() {
      let requestData = new FormData()

      requestData.append('app_name', this.applicationName)
      requestData.append('billing_type_id', this.billingType)

      if (this.unlimited == null || this.unlimited == 0) {
        requestData.append('is_app_credit_limited', 0)
        requestData.append('api_credit_limit', 0)
      } else {
        requestData.append('is_app_credit_limited', 1)
        requestData.append('api_credit_limit', this.creditLimit)
      }

      if (this.services) {
        for (let index = 0; index < this.services.length; index++) {
          requestData.append('services', this.services[index].id)
        }
      }

      return requestData
    },
    closeModalSuccess() {
      let closeButton = document.getElementById('close-createApplication')
      closeButton.click()

      this.$emit('refetchApplication')
    },
    async createApplication() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isProcessing = true
      try {
        let requestPayload = this.formatCreateApplicationData()

        let application = await this.$store.dispatch('application/createApplication', requestPayload)
        console.log('[] ->', application.message)
        this.showSuccessNotification(application.message)
        let self = this
        setTimeout(() => {
          self.closeModalSuccess()
          self.isProcessing = false
          self.clearInputFields()
        }, 2500)
        Nprogress.done()
      } catch (error) {
        Nprogress.done()
        console.log('[error] ->', error)
        this.isProcessing = false
        this.showErrorLoginNotification(error.message)
      }
    }
  }
}
</script>
