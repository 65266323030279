<template>
	<div>
		<MakeTransfer
			:settings="settings"
			:verifiedAccount="verifiedAccount"
			:goBack="goBack"
			:resetTransfer="resetTransfer"
		/>

		<div class="card transfer__list">
			<v-client-table
				ref="bulkRef"
				class="v-table row-pointer"
				:data="verifiedAccount"
				:columns="columns"
				:options="options"
			>
				<template slot="accountName" slot-scope="{ row }">
					{{ row.accountName || "--------------------" }}
				</template>
				<template slot="resolved" slot-scope="{ row }">
					<span
						:class="
							statusColor(
								row.resolved ? 'Resolved' : 'Unresolved'
							)
						"
					>
						{{ row.resolved ? "Resolved" : "Unresolved" }}
					</span>
				</template>
			</v-client-table>
		</div>
	</div>
</template>

<script>
import MakeTransfer from "./MakeTransfer/MakeTransfer";
import {
	statusColor,
	titleCase,
	tableOptions,
} from "../../../../utils/helpers";

const tableHeadings = {
	accountName: "Account Name",
	accountNumber: "Account Number",
	bankCode: "Bank Name",
	amount: "Amount (Naira)",
	narration: "Narration",
	resolved: "Status",
};

export default {
	name: "VerifiedAccountList",
	data() {
		return {
			filters: {},
			...tableOptions(tableHeadings, 100),
		};
	},
	props: [
		"settings",
		"verifiedAccount",
		"resetTransfer",
		"updateAccountList",
		"handleUpload",
		"goBack",
	],
	components: { MakeTransfer },
	methods: {
		titleCase,
		statusColor,
	},
	async mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
