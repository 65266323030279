<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="dashboard-brief py-3">
          <p class="call-name">Transaction Count</p>
        </div>
      </div>
      <div class="col-sm-6">
        <CustomSelect
          placeholder="Filter By"
          :optionLabel="'name'"
          :options="chartSelectOptions"
          v-model="range"
        />
      </div>
    </div>
    <div>
      <Spinner v-if="loading" />
      <apexchart
        width="400"
        type="pie"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins";
import pos from "@/services/wallets/pos";
import {
  chartSelectOptions,
  getDateRangeFromValue,
} from "@/utils/helpers";

const defaultFilter = getDateRangeFromValue(chartSelectOptions[0]);

export default {
  name: "TransactionOverviewChart",
  mixins: [componentData],
  data() {
    return {
      loading: false,
      chartSelectOptions,
      filters: defaultFilter,
      range: "Today",
      chartData: [],
      series: [0, 0],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Online Transactions", "Cash Registers"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    async fetchOverviewTrans() {
      this.loading = true;
      try {
        const transOverview = await pos.getPosOverviewTransactions({
          apiKey: this.getWalletApikey(this.$store),
          params: this.filters,
        });
        const parsedData = transOverview.data.data;
        this.series = [parsedData.onlineTransaction, parsedData.cashRegister];
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
  watch: {
    range: function (newRange) {
      this.filters = getDateRangeFromValue(newRange);
      this.fetchOverviewTrans();
    },
  },
  mounted() {
    this.fetchOverviewTrans();
  },
};
</script>

<style scoped></style>
