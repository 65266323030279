<template>
	<div class="user-settings-section border-0">
		<div class="invoice-info mt-4">
			<p class="invoice-info-row preview-row">
				<span>Validity</span>
				<span :class="result.valid ? 'text-success' : 'text-danger'">{{
					result.valid
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Phone Number Carrier</span>
				<span>{{ result.carrier || "Information not available" }}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Phone Number</span>
				<span>{{ result.number || "Information not available" }}</span>
			</p>

			<p class="invoice-info-row preview-row">
				<span>Local Format</span>
				<span>{{
					result.local_format || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>International Format</span>
				<span>{{
					result.international_format || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Country</span>
				<span
					>{{ result.country_name || "Information not available" }}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "PhoneNumberVerificationStandardResult",
	props: ["result"],
};
</script>
