<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="SectionItem no-b-b mb-4 justify-content-start">
				<img
					src="/assets/img/icons/arrow-left.svg"
					width="30"
					alt=""
					class="pointer"
					@click="$router.push('/wallet/split-payments')"
				/>
				<p class="title ml-3 mb-0">
					{{ subAccount.account_name || "Sub Account Details" }}
				</p>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getSubAccount(reference)"
			/>

			<template v-else>
				<div class="card overflow-hidden mt-3">
					<div class="row py-3">
						<div class="col-sm-4 text-center border-right ">
							<h4 class="call-count">
								{{ totals.count }}
							</h4>
							<p class="call-name pt-2">Total Transactions</p>
						</div>

						<div class="col-sm-3 text-center border-right">
							<h4 class="call-count">
								{{ toCurrency(totals.total) }}
							</h4>
							<p class="call-name pt-2">Total Amount</p>
						</div>

						<div class="col-sm-5 text-center">
							<h4 class="call-count">
								{{ subAccount.account_number || "None" }}
							</h4>
							<p class="call-name pt-2">Payout Account</p>
						</div>
					</div>
				</div>

				<TransactionsTable :params="params" :getTotals="getTotals" />
			</template>
		</template>
	</div>
</template>

<script>
	import TransactionsTable from "../../common/TransactionsTable/TransactionsTable";
	import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
	import Spinner from "../../../../utils/Spinner/Spinner";
	import FailedState from "../../../../utils/ResponseState/FailedState";
	import { getWalletApikey, toCurrency } from "../../../../utils/helpers";

	export default {
		name: "SubAccountDetails",
		data() {
			return {
				reference: "",
				loading: false,
				errorMsg: "",
				subAccount: {},
				totals: {},
				params: { wallet: this.$route.params.reference },
			};
		},
		components: {
			TransactionsTable,
			Spinner,
			FailedState,
			EmptyWallet,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			toCurrency,
			async getSubAccount(reference) {
				try {
					this.loading = true;
					this.errorMsg = "";
					const response = await this.$store.dispatch(
						"payment/getMerchantSubAccount",
						{
							apiKey: getWalletApikey(this.$store),
							reference,
						}
					);
					if (response.status === 200) {
						this.loading = false;
						this.subAccount = response.data.data;
					}
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
			getTotals(totals) {
				this.totals = totals;
			},
		},

		async mounted() {
			const reference = this.$route.params.reference;
			this.reference = reference;
			this.getSubAccount(reference);
		},
	};
</script>
