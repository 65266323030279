import utility from "@/services/utility";


export default {
  namespaced: true,
  actions: {
    async uploadImage({ commit }, fileData) {
      try {
        let { data } = await utility.uploadImage(fileData);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
  },
  getters: {},
};
