import { subscriptionAPI } from "@/services/api";

export default {
	getAllTaxes({ apiKey, params }) {
		return subscriptionAPI.get(`/tax`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getTax({ apiKey, id }) {
		return subscriptionAPI.get(`/tax/${id}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createTax({ apiKey, request }) {
		return subscriptionAPI.post(`/tax`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateTax({ apiKey, id, request }) {
		return subscriptionAPI.put(`/tax/${id}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	deleteTax({ apiKey, id }) {
		return subscriptionAPI.delete(`/tax/${id}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
