<template>
	<TransactionsTable :params="params" :getTotals="getTotals" />
</template>

<script>
import TransactionsTable from "../../../../../wallet/common/TransactionsTable/TransactionsTable";

export default {
	name: "PaymentPageTransactions",
	data() {
		return {
			loading: false,
			errorMsg: "",
			size: 25,
			totals: {},
			params: { payment_link_ref: this.$route.params.reference },
		};
	},
	components: {
		TransactionsTable,
	},
	methods: {
		getTotals(totals) {
			this.totals = totals;
		},
	},
};
</script>
