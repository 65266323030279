export const defaultTableHeadings = {
	reference: "Transaction Reference",
	currency: "Currency",
	amount: "Amount",
	charges: "Charges",
	source: "Payment Method",
	action: "Type",
	status: "Status",
	narration: "Narration",
	createdAt: "Date",
};

export const rsaTableHeadings = {
	reference: "Transaction Reference",
	currency: "Currency",
	amount: "Amount",
	charges: "Charges",
	"metadata.account_number": "Receiver Account Number",
	"metadata.source_account.account_name": "Sender Account Name",
	"metadata.source_account.account_number": "Sender Account Number",
	"metadata.source_account.bank_name": "Sender Bank Name",
	source: "Payment Method",
	action: "Type",
	status: "Status",
	narration: "Narration",
	createdAt: "Date",
};

export const collectionTableHeadings = {
	reference: "Transaction Reference",
	currency: "Currency",
	amount: "Amount",
	charges: "Charges",
	"metadata.account_number": "Receiver Account Number",
	"metadata.source_account.account_name": "Sender Account Name",
	"metadata.source_account.account_number": "Sender Account Number",
	"metadata.source_account.bank_name": "Sender Bank Name",
	source: "Payment Method",
	type: "Payment Source",
	action: "Type",
	status: "Status",
	narration: "Narration",
	createdAt: "Date",
};

export const transferTableHeadings = {
	reference: "Transaction Reference",
	currency: "Currency",
	amount: "Amount",
	charges: "Charges",
	"metadata.session_id": "Session ID",
	"metadata.ip": "Ip Address",
	"metadata.destination.recipient": "Receiver Account Number",
	"metadata.destination.bank_name": "Receiver Bank Name",
	"metadata.destination.type": "Access Medium",
	source: "Payment Method",
	action: "Type",
	status: "Status",
	narration: "Narration",
	createdAt: "Date",
};
