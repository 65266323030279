<template>
	<div class="w-100 mb-3">
		<div class="bg-light rounded p-4">
			<div class="flex__between">
				<div>
					<h5>
						{{ accountFile.length || 0 }} transfers uploaded.
						<span class="text-success">
							{{ statusCount.verifCount }} Successfully
							verified.</span
						>
					</h5>
					<h5
						v-show="statusCount.unverifCount > 0"
						class="text-danger"
					>
						{{ statusCount.unverifCount }} of those transfers could
						not be verified because they contained some errors.
					</h5>
				</div>

				<button class="btn btn-sm btn-danger" @click="resetTransfer">
					Cancel Transfer
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TransferListTableHeader",
		props: ["accountFile", "resetTransfer"],
		computed: {
			statusCount() {
				const verifCount = this.accountFile.filter(
					({ resolved }) => resolved === true
				).length;
				return {
					verifCount,
					unverifCount: this.accountFile.length - verifCount,
				};
			},
		},
	};
</script>
