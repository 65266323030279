<template>
	<pulse-loader
		v-if="variant == 'pulse'"
		:loading="true"
		:color="color"
		:size="size"
	/>
	<clip-loader
		v-else-if="variant == 'clip'"
		:loading="true"
		:color="color"
		:size="size"
	/>
	<fade-loader
		v-else-if="variant == 'fade'"
		:loading="true"
		:color="color"
		:height="height"
		:width="width"
	/>
</template>

<script>
	import PulseLoader from "vue-spinner/src/PulseLoader";
	import ClipLoader from "vue-spinner/src/ClipLoader";
	import FadeLoader from "vue-spinner/src/FadeLoader";

	export default {
		name: "Spinner",
		props: {
			color: { default: "#17568b" },
			size: { default: "10px" },
			variant: { default: "pulse" },
			height: { default: "100px" },
			width: { default: "100px" },
		},
		components: {
			PulseLoader,
			ClipLoader,
			FadeLoader,
		},
	};
</script>
