<template>
  <div class="card pt-3 pb-4 px-3">
    <div class="row">
      <div class="col-12 mt-3"></div>
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-lg-6 col-xl-4">
          <TransactionOverviewChart />
          <div class="dashboard-brief py-3 mt-4 border mx-4">
            <h2 class="call-count">{{ agentOverviewStart.numOfAgents }}</h2>
            <p class="call-name">Number of Agents</p>
          </div>
        </div>
        <div class="col-lg-6 col-xl-8">
          <OverviewChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import OverviewChart from "./OverviewChart/OverviewChart.vue";
import TransactionOverviewChart from "./OverviewChart/TransactionOverviewChart.vue";
import Spinner from "@/utils/Spinner/Spinner";

import pos from "@/services/wallets/pos";

export default {
  mixins: [componentData],
  name: "Overview",
  data() {
    return {
      agentOverviewStart: null,
    };
  },
  components: {
    Spinner,
    OverviewChart,
    TransactionOverviewChart,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    async fetchOverviewAgent() {
      this.loading = true;
      try {
        let agentOverview = await pos.getPosOverviewAgent({
          apiKey: this.getWalletApikey(this.$store),
        });
        this.agentOverviewStart = agentOverview.data.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log({ err });
      }
    },
  },
  created() {
    this.fetchOverviewAgent();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
  watch: {
		loading(value) {
			console.log({value});
		},
	},
};
</script>
