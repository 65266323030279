<template>
	<div class="form-group" :class="otherCls">
		<div class="flex__between">
			<div>
				<label v-show="label" class="input-item-label">{{
					label
				}}</label>
				<span
					v-if="required"
					class="text-danger pl-1"
					title="This field is required"
					>*
    {{number}}
          </span
				>
			</div>
			<template>
				<slot />
			</template>
		</div>
		<div
			class="input-wrapper"
			:class="`${disabled ? 'disabled' : ''}  ${error ? 'error' : ''}`"
		>
			<vue-tel-input
				:defaultCountry="defaultCountry ? defaultCountry : 'NG'"
				:onlyCountries="onlyCountries"
				:inputOptions="{
					...inputOptions,
				}"
				:dropdownOptions="{
					showDialCodeInList: true,
					showFlags: true,
					showSearchBox: true,
					tabindex: 0,
					width: '300px',
					...dropdownOptions,
				}"
        :value='number'
        :modelValue='number'
				class="form-control w-100 input-bordered p-0"
				step="any"
				:disabled="disabled"
				@input="handleInput"
				@validate="$emit('validate', $event)"
				@blur="$emit('blur', $event)"
				:validCharactersOnly="true"
        :autoFormat="false"
			/>
			<div v-show="icon" class="img__wrap">
				<img
					:src="icon"
					alt=""
					class="pointer"
					@click="iconClick ? iconClick() : ''"
				/>
			</div>
		</div>
		<div v-if="typeof error === 'string'">
			<span v-show="error" class="text-danger">{{ error }}</span>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
export default {
	name: "CustomPhoneInput",
	props: [
		"id",
		"value",
		"name",
		"type",
		"min",
		"max",
		"placeholder",
		"defaultCountry",
		"required",
		"label",
		"error",
		"disabled",
		"handleError",
		"icon",
		"iconClick",
		"avoidPaste",
		"btn",
		"otherCls",
		"onlyCountries",
		"dropdownOptions",
	],
  data() {
		return {
			number: this.value.normal,
		};
	},
	computed: {
		inputOptions() {
			return _.omitBy(
				{
					id: this.name,
					name: this.name,
					type: this.type,
					placeholder: this.placeholder,
				},
				_.isNil
			);
		},
	},
	methods: {
		handleInput(number, object) {
			const payload = {
				...object,
				normal: number.replace(/[^0-9]/g, ""),
			};
			this.$emit("input", payload);
		},
	},
	watch: {
		value: {
     handler(newValue) {
			  if (this.handleError) this.handleError(this.name);

        this.number = newValue.normal.replace(/[^0-9]/g, "");
     },
     deep: true
		},
	},
};
</script>
