<template>
  <div class="az-content-body">
    <div class="row">
      <!-- Create Customer section -->
      <div class="col-lg-12 col-md-12">
        <div>
          <div class="row">
            <div class="col-10">
              <div class="row" v-if="active_section == 'customerCreation'">
                <!-- First name field -->
                <div class="col-md-12">
                  <div class="SectionItem">
                    <div class="SectionHeader">
                      <span class="title">Fill in basic customer details</span>
                      <div
                        class="description"
                      >The first step to creating a secondary customer is inputing their basic details</div>
                    </div>
                    <div class="content steps">1/3</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Firstname
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      class="form-control input-bordered"
                      v-model="customerDetails.firstname"
                      placeholder="Enter your first name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.firstname.$error"
                    >
                      <li class="parsley-required">Firstname is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- last name field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Lastname
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      class="form-control input-bordered"
                      v-model="customerDetails.lastname"
                      placeholder="Enter your last name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.lastname.$error"
                    >
                      <li class="parsley-required">Lastname is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- Organisation field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Organisation Name
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="text"
                      name="organisation"
                      class="form-control input-bordered"
                      v-model="customerDetails.organisation"
                      placeholder="Enter your organisation name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.organisation.$error"
                    >
                      <li class="parsley-required">Organisation name is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- Email field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Email
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      class="form-control input-bordered"
                      v-model="customerDetails.email"
                      placeholder="Enter your email"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.email.$error"
                    >
                      <li class="parsley-required">Email is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- Phonr number field -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="input-item-label">Phone Number</label>
                    <input
                      type="tel"
                      name="phonenmber"
                      class="form-control input-bordered"
                      v-model="customerDetails.phone_number"
                      placeholder="Enter your phoneNumber"
                    />
                  </div>
                </div>

                <!-- Address field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Address
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      class="form-control input-bordered"
                      v-model="customerDetails.address"
                      placeholder="Enter your address"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.address.$error"
                    >
                      <li class="parsley-required">Address is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- City field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      City
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control input-bordered"
                      v-model="customerDetails.city"
                      placeholder="Enter your city"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.city.$error"
                    >
                      <li class="parsley-required">City is required.</li>
                    </ul>
                  </div>
                </div>

                <!--Country field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Country
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <v-select
                      class="v-select"
                      placeholder="Select a country"
                      v-model="customerDetails.country"
                      @keydown.enter.native.prevent
                      :required="!customerDetails.country"
                      :options="metaData.countries"
                      @input="changeState"
                      label="name"
                    ></v-select>
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.country.$error"
                    >
                      <li class="parsley-required">Country is required.</li>
                    </ul>
                  </div>
                </div>

                <!-- State field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      State
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <v-select
                      class="v-select"
                      placeholder="Select a state"
                      v-model="customerDetails.state"
                      @keydown.enter.native.prevent
                      :disabled="!customerDetails.country"
                      :required="!customerDetails.state"
                      :options="states"
                      label="state_label"
                    ></v-select>
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.state.$error"
                    >
                      <li class="parsley-required">State is required.</li>
                    </ul>
                  </div>
                </div>

                <!--Password field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Password
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="password"
                      autocomplete
                      class="form-control input-bordered"
                      v-model="customerDetails.password"
                      placeholder="Enter a password"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      id="parsley-id-26"
                      v-show="$v.customerDetails.password.$error"
                    >
                      <li class="parsley-required">Password must be a minimum of 6 charachers.</li>
                    </ul>
                  </div>
                </div>

                <!-- Confirm password field -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Confirm Password
                      <span class="text-danger" title="This field is required">*</span>
                    </label>
                    <input
                      type="password"
                      autocomplete
                      class="form-control input-bordered"
                      v-model.lazy="customerDetails.confirmpassword"
                      placeholder="Confrim the password"
                    />
                    <ul class="parsley-errors-list filled" id="parsley-id-26">
                      <li
                        class="parsley-required"
                        v-if="$v.customerDetails.confirmpassword.$error"
                      >Field is required and must be the same as the password.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <transition mode="out-in" name="slide">
                <div class="row" v-if="active_section == 'creditCreation'">
                  <div class="col-md-12">
                    <div class="SectionItem" style="margin-bottom: 25px;">
                      <div class="SectionHeader">
                        <span class="title">Add Credit to a customer account</span>
                        <div
                          class="description"
                        >Add credits to customer account to enable apps to start making API calls</div>
                      </div>
                      <div class="content steps">2/3</div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">
                        Customer's Credit
                        <span class="text-danger" title="This field is required">*</span>
                      </label>
                      <input
                        type="number"
                        :max="getProfileAmount"
                        name="credit"
                        class="form-control input-bordered"
                        v-model.number="creditCreation.credit"
                        placeholder="Enter your customer's credit"
                      />
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.creditCreation.credit.$error && $v.creditCreation.credit.between"
                      >
                        <li class="parsley-required">customer's credit is required.</li>
                      </ul>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="!$v.creditCreation.credit.between"
                      >
                        <li
                          class="parsley-required"
                        >Customer's credit cannot be greater than available credit for applications and sub-customers which is {{getProfileAmount}}.</li>
                      </ul>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.getProfileAmount.$error"
                      >
                        <li
                          class="parsley-required"
                        >Your total balance is less than 1000, Please top up your account.</li>
                      </ul>
                    </div>
                    <div class="gaps-6x"></div>
                  </div>

                  <!-- First name field -->
                  <div class="col-md-12">
                    <div class="SectionItem">
                      <div class="SectionHeader">
                        <span class="title">Create application for customer</span>
                        <div
                          class="description"
                        >The next step is to instantly create an application the customer would start using</div>
                      </div>
                      <div class="content steps">3/3</div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">
                        App Name
                        <span class="text-danger" title="This field is required">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstname"
                        class="form-control input-bordered"
                        v-model="appDetails.app_name"
                        placeholder="Enter your app name"
                      />
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.appDetails.app_name.$error"
                      >
                        <li class="parsley-required">App Name is required.</li>
                      </ul>
                    </div>
                  </div>

                  <!-- App credit field -->
                  <div class="col-md-6">
                    <div class="form-group mt-4">
                      <label class="ckbox">
                        <input type="checkbox" v-model="unlimited" />
                        <span style="font-size: 15px">Cap credit available for app</span>
                      </label>
                      <span
                        class="input-note"
                      >Note: Setting a cap to your API credit limit would limit the the credit limit on the app.</span>
                    </div>
                  </div>

                  <div class="col-md-12" v-if="unlimited == 1">
                    <div class="form-group">
                      <label class="input-item-label">API Credit Limit</label>
                      <input
                        type="number"
                        :max="creditCreation.credit"
                        name="lastname"
                        class="form-control input-bordered"
                        v-model.number="appDetails.api_credit_limit"
                        placeholder="Enter an amount for the API credit limit"
                      />
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.appDetails.api_credit_limit.$error && $v.appDetails.api_credit_limit.between"
                      >
                        <li class="parsley-required">API credit limit is required.</li>
                      </ul>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="!$v.appDetails.api_credit_limit.between"
                      >
                        <li
                          class="parsley-required"
                        >App credit must not be greated than {{creditCreation.credit}}.</li>
                      </ul>
                    </div>
                  </div>

                  <!--Services field -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="input-item-label">
                        Services
                        <span class="text-danger" title="This field is required">*</span>
                      </label>
                      <a
                        href
                        @click.prevent="selectAllServices"
                        class="right ml-1"
                      >Select all services</a>
                      <a
                        href="#"
                        class="right"
                        v-if="false"
                      >Fail over services provides fallbacks and makes results more efficent</a>
                      <v-select
                        class="v-select multiple"
                        placeholder="Select a service"
                        @keydown.enter.native.prevent
                        v-model="appDetails.services"
                        :required="!appDetails.services"
                        :options="whiteLabelledCustomerServices"
                        label="service_label"
                        multiple
                      ></v-select>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.appDetails.services.$error"
                      >
                        <li class="parsley-required">Services are required.</li>
                      </ul>
                    </div>
                  </div>

                  <!-- BIlling field -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="input-item-label">
                        Billing Type
                        <span class="text-danger" title="This field is required">*</span>
                      </label>
                      <a
                        href="#"
                        class="right"
                        v-if="false"
                      >Fail over services provides fallbacks and makes results more efficent</a>
                      <v-select
                        class="v-select multiple"
                        placeholder="Select a service"
                        @keydown.enter.native.prevent
                        v-model="appDetails.billing_type_id"
                        :required="!appDetails.billing_type_id"
                        :options="metaData.billingType"
                        label="billing_type_label"
                      ></v-select>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.appDetails.billing_type_id.$error"
                      >
                        <li class="parsley-required">Billing Type is required.</li>
                      </ul>
                    </div>
                  </div>

                  <!-- Fail over servies -->
                  <div
                    v-if="false"
                    :class="[customerDetails.hasFailOvers == 'Yes' ? 'col-md-4' : 'col-md-12']"
                  >
                    <div class="form-group">
                      <label class="input-item-label">Fail-over services</label>
                      <v-select
                        class="v-select"
                        @keydown.enter.native.prevent
                        name="address"
                        :disabled="!customerDetails.services"
                        v-model="customerDetails.hasFailOvers"
                        :options="failOvers"
                        placeholder="Would you like to select fail-over services"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </transition>

              <div class="gaps-2x"></div>

              <!-- Buttons  -->
              <div class="row">
                <div class="col-12">
                  <template v-if="active_section == 'customerCreation'">
                    <button
                      class="btn btn-lg btn-dark mg-b-20 mr-3"
                      @click="$router.push({name: 'Dashboard'})"
                    >Cancel</button>
                    <button
                      class="btn btn-lg btn-primary mg-b-20"
                      @click="nextPage"
                      :disabled="submitting == true"
                    >
                      <span>Next</span>
                    </button>
                  </template>
                  <template v-if="active_section == 'creditCreation'">
                    <button class="btn btn-lg btn-dark mg-b-20 mr-3" @click="toCustomer">Back</button>
                    <button
                      class="btn btn-lg btn-primary mg-b-20"
                      type="submit"
                      @click="performRegistration"
                      :disabled="submitting == true"
                    >
                      <span v-if="submitting == true">...Creating account</span>
                      <span v-else>Submit</span>
                    </button>
                  </template>
                  <!-- <template v-if="active_section == 'applicationCreation'">
                    <button class="btn btn-lg btn-dark mg-b-20 mr-3" @click="prevPage">Back</button>
                    <button
                      class="btn btn-lg btn-primary mg-b-20"
                      type="submit"
                      @click="performRegistration"
                      :disabled="submitting == true"
                    >
                      <span v-if="submitting == true">...Creating account</span>
                      <span v-else>Submit</span>
                    </button>
                  </template>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from 'nprogress'
import { required, minLength, alphaNum, sameAs, email, between, minValue } from 'vuelidate/lib/validators'
import AuthWrapper from '@/components/auth/AuthWrapper'
import { notifications } from '../../public/assets/mixins/notifications'

export default {
  name: 'Register',
  mixins: [notifications],
  components: {
    AuthWrapper
  },
  data() {
    return {
      isLoading: true,
      states: [],
      unlimited: null,
      active_section: 'customerCreation',
      getProfileAmount: this.$store.state.account.profile.api_customer.total_credit_remaining,
      metaData: {},
      submitting: false,
      failOvers: ['Yes', 'No'],
      creditCreation: {
        credit: ''
      },
      customerDetails: {
        firstname: '',
        lastname: '',
        organisation: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        password: '',
        confirmpassword: '',
        hasFailOvers: '',
        failOverOptions: '',
        phone_number: '',
      },
      appDetails: {
        app_name: '',
        api_credit_limit: '',
        billing_type_id: '',
        services: '',
      }
    }
  },
  watch: {
    'customerDetails.services'(newVal, old) {
      let newServiceArray, failOverServices, missingIndex
      if (newVal) newServiceArray = newVal.map(service => service.id)

      if (this.customerDetails.failOverOptions) {
        failOverServices = this.customerDetails.failOverOptions.map(service => service.id)
      }

      if (newServiceArray && failOverServices) {
        for (let index = 0; index < failOverServices.length; index++) {
          if (!newServiceArray.includes(failOverServices[index])) {
            missingIndex = index
            break
          }
        }
        this.customerDetails.failOverOptions.splice(missingIndex, 1)
      }
    }
  },
  validations() {
    return {
      getProfileAmount: {
        minValue: minValue(1000),
      },
      customerDetails: {
        firstname: { required },
        lastname: { required },
        organisation: { required },
        email: { required, email },
        address: { required },
        city: { required },
        country: { required },
        state: { required },
        password: {
          required,
          minLength: minLength(6)
        },
        confirmpassword: {
          required,
          sameAsPassword: sameAs('password')
        }
      },
      appDetails: {
        app_name: { required },
        api_credit_limit: {
          between: between(0, Number(this.creditCreation.credit))
        },
        billing_type_id: { required },
        services: { required },
      },
      creditCreation: {
        credit: {
          required,

          between: between(0, this.getProfileAmount)
        },
      }
    }
  },
  computed: {
    changeState() {
      this.customerDetails.state = ''
      if (!this.customerDetails.country) {
        this.states = []
      } else {
        this.states = this.customerDetails.country.states || []
      }
    },
    failOverOptions() {
      if (this.customerDetails.services) {
        return this.customerDetails.services
      }
    },
    whiteLabelledCustomerServices() {
      return this.$store.state.account.services
    }
  },
  methods: {
    selectAllServices() {
      this.appDetails.services = this.whiteLabelledCustomerServices
    },
    formatRequestData() {
      let requestData = new FormData()

      if (this.appDetails.services) {
        this.appDetails.services.map(service => {
          requestData.append('services', service.id)
        })
      }
      // if (this.customerDetails.hasFailOvers == 'Yes') {
      //   this.customerDetails.failOverOptions.map((service) => { requestData.append('fail_over_services', service.id) })
      // }
      if (this.unlimited == null || this.unlimited == 0) {
        requestData.append('is_app_credit_limited', 0)
        requestData.append('api_credit_limit', 0)
      } else {
        requestData.append('is_app_credit_limited', 1)
        requestData.append('api_credit_limit', this.appDetails.api_credit_limit)
      }
      requestData.append('firstname', this.customerDetails.firstname)
      requestData.append('lastname', this.customerDetails.lastname)
      requestData.append('organization_name', this.customerDetails.organisation)
      requestData.append('email', this.customerDetails.email)
      requestData.append('address', this.customerDetails.address)
      requestData.append('city', this.customerDetails.city)
      requestData.append('credit', this.creditCreation.credit)
      requestData.append('phone_number', this.customerDetails.phone_number)

      // requestData.append('fail_over_services', this.customerDetails.password)
      requestData.append('state_id', this.customerDetails.state ? this.customerDetails.state.id : '')
      requestData.append('country_id', this.customerDetails.country ? this.customerDetails.country.id : '')
      requestData.append('app_name', this.appDetails.app_name)
      requestData.append('billing_type_id', this.appDetails.billing_type_id.id)
      requestData.append('password', this.customerDetails.password)

      return requestData
    },
    async performRegistration() {
      this.$v.creditCreation.$touch()
      this.$v.appDetails.$touch()
      this.$v.getProfileAmount.$touch()
      if (this.$v.creditCreation.$invalid) {
        return
      }
      if (this.$v.appDetails.$invalid) {
        return
      }
      if (this.$v.getProfileAmount.$invalid) {
        return
      }
      Nprogress.start()
      this.submitting = true
      let formattedResponse = this.formatRequestData()
      console.log('[formatted response ] ->', formattedResponse)
      try {
        let { data } = await this.$store.dispatch('auth/whitelabelCustomerRegistration', formattedResponse)
        this.$swal({
          title: `Customer successfully registered`,
          text: 'You have successfully created a customer',
          icon: 'success',
          button: 'Ok'
        }).then(value => {
          this.$router.push('/whitelabel-customers')
        })
        Nprogress.done()
      } catch (error) {
        Nprogress.done()
        this.submitting = false
        console.log('error :', error)
        this.showErrorLoginNotification(error.message)
      }
    },
    nextPage() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.$v.customerDetails.$touch()
      if (this.$v.customerDetails.$invalid) {
        return
      }
      this.active_section = 'creditCreation'
    },
    // toApplication() {
    //   this.$v.creditCreation.$touch()
    //   if (this.$v.creditCreation.$invalid) {
    //     return
    //   }
    //   this.active_section = 'applicationCreation'
    // },
    prevPage() {
      this.active_section = 'creditCreation'
    },
    toCustomer() {
      this.active_section = 'customerCreation'
    },

  },
  async mounted() {
    try {
      let apiResponse = await this.$store.dispatch('getMetaData')
      this.metaData = apiResponse.results
      this.isLoading = false
    } catch (error) {
      console.log('[Error] ->', error)
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.content.steps {
  font-size: 26px;
  font-weight: 900;
}
.card-login.register {
  min-width: 330px;
  max-width: 950px;
}
.header-title {
  font-weight: 600;
  margin-bottom: 14px;
}
</style>