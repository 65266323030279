<template>
	<form @submit.prevent="handleSubmit">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
				<CustomInput
					:name="'identity_number'"
					v-model="filters.identity_number"
					:placeholder="'ID Number'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
				<CustomInput
					:name="'request_reference'"
					v-model="filters.request_reference"
					:placeholder="'Reference'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-4">
				<CustomSelect
					name="service_id"
					:optionLabel="'service_label'"
					placeholder="Select service"
					:options="allServices"
					v-model="filters.service_id"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<DatePicker
					:name="'from'"
					v-model="dateFilters.from"
					:placeholder="'Start Date'"
					:disabledDates="{ from: new Date() }"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<DatePicker
					:name="'to'"
					v-model="dateFilters.to"
					:disabledDates="{ from: new Date(), to: dateFilters.from }"
					:placeholder="'End Date'"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>

				<div class="d-inline-block">
					<Spinner v-if="qLoading" />
					<img
						v-else
						src="/assets/img/icons/download.png"
						alt="Download as csv"
						class="ml-3 pointer"
						width="30px"
						@click="exportHistory"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import DatePicker from "../../../../utils/DatePicker/DatePicker";
	import CustomInput from "../../../../utils/CustomInput/CustomInput";
	import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
	import Spinner from "../../../../utils/Spinner/Spinner";
	import { timeRangeFormat } from "../../../../utils/helpers";

	const initialState = () => ({
		filters: {
			service_id: "",
			request_reference: "",
			identity_number: "",
		},
		dateFilters: {
			from: null,
			to: null,
		},
		isFilter: false,
		qLoading: false,
	});

	export default {
		mixins: [notifications],
		name: "DebitHistoryFilters",
		data() {
			return { ...initialState() };
		},
		props: ["loading", "clearFilter", "submitFilter", "requestData"],
		components: {
			DatePicker,
			CustomInput,
			CustomSelect,
			Spinner,
		},
		computed: {
			disabled() {
				return !Object.values({
					...this.filters,
					...this.dateFilters,
				}).some((val) => val);
			},
			allServices() {
				return this.$store.state.metaData.services;
			},
		},
		methods: {
			resetFilters() {
				Object.assign(this.$data, { ...initialState() });
				this.clearFilter();
				this.isFilter = false;
			},
			handleSubmit() {
				const { service_id } = this.filters || {};
				this.isFilter = true;
				if (this.dateFilters.from)
					this.dateFilters.from = timeRangeFormat(
						this.dateFilters.from,
						true
					);
				if (this.dateFilters.to)
					this.dateFilters.to = timeRangeFormat(this.dateFilters.to);
				this.submitFilter({
					dataParams: { ...this.dateFilters },
					query: {
						...this.filters,
						service_id: service_id ? service_id.id : undefined,
					},
				});
			},
			async exportHistory() {
				try {
					this.qLoading = true;
					const response = await this.$store.dispatch(
						"logs/exportDebitHistory",
						this.requestData
					);

					const { status, data } = response || {};

					if (status === 200) {
						this.qLoading = false;
						let blob = new Blob([data], {
								type: "text/csv",
							}),
							url = window.URL.createObjectURL(blob);

						await window.open(url, "_blank");
					}
				} catch (error) {
					this.qLoading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
		watch: {
			"filters.from": function() {
				this.filters.to = null;
			},
		},
	};
</script>
