<template>
  <div class="px-5">
    <div id="print-statement" class="overall-content">
      <div ref="printRef">
        <template
          v-for="(page, index) in getPaginatedData(
            data.data || data.invoices,
            8
          )"
        >
          <div :key="index">
            <div class="bg--primary all-content my-4">
              <div class="statement-content box-shadow bg-white">
                <TransactionContentHead
                  v-if="index == 0 && !type"
                  :data="data.data"
                  :dates="dates"
                />
                <WalletContentHeader
                  v-else-if="index == 0 && type == 'wallet'"
                  :data="data.data"
                  :dates="dates"
                />
                <InvoiceContentHeader
                  v-else-if="index == 0 && type == 'invoices'"
                  :data="data.invoices"
                  :extra="data"
                  :dates="dates"
                />

                <StatementTable :transactions="page" :type="type" />
                <ContentFooter
                  v-if="
                    getPaginatedData(data.data || data.invoices, 8).length -
                      1 ===
                    index
                  "
                />
                <div className="page-no text-center my-4 black">
                  Page {{ index + 1 }} of
                  {{ getPaginatedData(data.data || data.invoices, 8).length }}
                </div>
              </div>
            </div>
            <div className="page-break"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionContentHead from "./TransactionContentHead.vue";
import WalletContentHeader from "./WalletContentHeader.vue";
import InvoiceContentHeader from "./InvoiceContentHeader.vue";
import ContentFooter from "./ContentFooter.vue";
import StatementTable from "./StatementTable.vue";

export default {
  props: ["dates", "data", "type"],
  components: {
    InvoiceContentHeader,
    WalletContentHeader,
    TransactionContentHead,
    ContentFooter,
    StatementTable,
  },
  methods: {
    getPaginatedData(data = [], pageSize = 10) {
      return data.reduce((resultArray, item, index) => {
        const pageIndex = Math.floor(index / pageSize);
        if (!resultArray[pageIndex]) {
          resultArray[pageIndex] = [];
        }
        resultArray[pageIndex].push(item);
        return resultArray;
      }, []);
    },
  },

  mounted() {
    this.$emit("ref", this.$refs.printRef);
  },
};
</script>
