<template>
	<div>
		<img
			@click="openDeleteTax"
			class="pointer"
			src="/assets/img/icons/trash.svg"
			alt="Delete Tax"
		/>

		<DeleteTaxModal
			:id="`deleteTax-${taxID}`"
			:closeModal="closeModal"
			:taxRef="taxRef"
			:taxID="taxID"
		/>
	</div>
</template>

<script>
	import DeleteTaxModal from "../../modals/DeleteTaxModal/DeleteTaxModal";

	export default {
		name: "DeleteTaxButton",
		props: ["taxRef", "taxID"],
		methods: {
			openDeleteTax() {
				$(`#deleteTax-${this.taxID}`).modal("show");
			},
			closeModal() {
				$(`#deleteTax-${this.taxID}`).modal("hide");
			},
		},
		components: {
			DeleteTaxModal,
		},
	};
</script>
