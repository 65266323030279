<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 "
			@click="openSpendingLimitModal"
		>
			Set Spending Limit
		</button>
		<SetSpendingLimitModal :id="'setSpendingLimit'" :closeModal="closeModal" />
	</div>
</template>

<script>
	import SetSpendingLimitModal from "../../modals/SetSpendingLimitModal/SetSpendingLimitModal";

	export default {
		name: "SetSpendingLimitButton",
		methods: {
			openSpendingLimitModal() {
				$("#setSpendingLimit").modal("show");
			},
			closeModal() {
				$("#setSpendingLimit").modal("hide");
			},
		},
		components: {
			SetSpendingLimitModal,
		},
	};
</script>
