<template>
	<div class="card pt-3">
		<div class="px-3">
			<FilterWrapper>
				<template slot="pageSelect">
					<PerPageSelect :handleChange="refetch" :limit="limit" />
				</template>
				<template slot="filterForm">
					<DebitHistoryFilters
						:loading="loading"
						:submitFilter="refetch"
						:clearFilter="clearFilter"
						:requestData="requestData"
					/>
				</template>
			</FilterWrapper>
		</div>

		<div v-if="loading" class="flex__center my-5 py-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="refetch"
		/>

		<div :class="tbClass">
			<v-server-table
				ref="dRef"
				class="v-table row-pointer"
				:columns="columns"
				:options="options"
				@loading="setLoading(true)"
				@loaded="setLoading(false)"
				@error="setError"
				@row-click="handleClick"
			>
				<template slot="identity_number" slot-scope="{ row }">
					<span>{{ row.identity_number || "----------------" }}</span>
				</template>
				<template slot="service_id" slot-scope="{ row }">
					<span>{{ getService(row.service_id) }}</span>
				</template>
				<template slot="status" slot-scope="{ row }">
					<span :class="status(row.status).style">{{
						status(row.status).val
					}}</span>
				</template>
				<template slot="request_time" slot-scope="{ row }">
					<span>
						{{
							timeDateFormat(row.request_time).date +
							" " +
							timeDateFormat(row.request_time).time
						}}
					</span>
				</template>
			</v-server-table>
		</div>
	</div>
</template>

<script>
import Spinner from "../../../utils/Spinner/Spinner";
import FailedState from "../../../utils/ResponseState/FailedState";
import DebitHistoryFilters from "./DebitHistoryFilters/DebitHistoryFilters";
import FilterWrapper from "../../../utils/FilterWrapper/FilterWrapper";
import PerPageSelect from "../../../utils/Selects/PerPageSelect/PerPageSelect";
import {
	timeDateFormat,
	titleCase,
	tableOptions,
} from "../../../utils/helpers";

const tableHeadings = {
	request_reference: "Request Reference",
	identity_number: "ID Number",
	service_id: "Service",
	"app.app_name": "Application Used",
	price_per_call: "Amount Debited",
	status: "Status",
	request_time: "Request Date",
};

export default {
	name: "DebitHistory",
	data() {
		return {
			loading: false,
			errorMsg: "",
			query: {},
			dataParams: {},
			limit: 25,
			requestData: {},
			...tableOptions(tableHeadings, 25),
		};
	},
	components: {
		FailedState,
		Spinner,
		DebitHistoryFilters,
		FilterWrapper,
		PerPageSelect,
	},
	computed: {
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
		allServices() {
			return this.$store.state.metaData.services;
		},
	},
	methods: {
		titleCase,
		timeDateFormat,
		getService(id) {
			const service = this.allServices.find((val) => val.id === id);
			return service.service_label;
		},
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		handleClick({ row }) {
			this.$router.push(`/transactions/debit/${row.request_reference}`);
		},
		refetch({ query, dataParams, limit }) {
			if (query) {
				const newQuery = Object.fromEntries(
					Object.entries(query).filter(([_, v]) => v != "")
				);
				this.query = newQuery;
			}

			if (dataParams) this.dataParams = { ...dataParams };
			if (limit) {
				this.$refs.dRef.setLimit(limit);
				this.limit = limit;
			}
			this.$refs.dRef.refresh();
		},
		clearFilter() {
			this.query = {};
			this.dataParams = {};
			this.$refs.dRef.refresh();
		},
		async getDebitHistory() {
			this.options.requestFunction = async (data) => {
				const clientid =
					this.$store.state.account.clientID ||
					localStorage.getItem("local-ID");

				const requestData = {
					clientid,
					params: {
						...data,
						...this.dataParams,
						limit: this.limit,
						query: { ...this.query },
					},
				};
				this.requestData = requestData;

				return await this.$store
					.dispatch("logs/CustomerDebitHistory", requestData)
					.then((response) => ({
						...response.result.debitLog,
						count: response.result.debitLog.total,
					}));
			};
		},
		status(val) {
			return val === 1
				? { val: "Successful", style: "text-success" }
				: { val: "Failed", style: "text-danger" };
		},
	},

	created() {
		this.getDebitHistory();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
