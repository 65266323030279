<template>
  <div class="az-content-body">
    <div class="col-lg-12">
      <div class="SectionItem">
        <div class="SectionHeader">
          <span class="title">Direct Debit</span>
          <div class="description">
            Manage direct debit instructions, view history and configure
            webhooks for direct debit mandates.
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end" v-if="user.directDebit">
      <button
        type="button"
        class="btn themed-button mr-4"
        @click="withdrawFunds"
      >
        <span v-if="submitting">...requesting</span>
        <span v-else>Request Withdrawal</span>
      </button>
    </div>
    <div class="row justify-content-center mt-5" v-if="user.directDebit">
      <div class="col-12">
        <div class="row px-3">
          <div class="col-sm-4">
            <div class="card shadow-none rounded">
              <div class="row px-4 py-3">
                <div class="col-12">
                  <h5 class="themed-text">DIRECT DEBIT WALLET BAL.</h5>
                </div>
                <div class="col-12 py-4">
                  <p>NGN</p>
                  <h5><strong>0.00</strong></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="card shadow-none rounded">
              <div class="row px-4 py-3">
                <div class="col-12">
                  <h5 class="themed-text">WALLET DETAILS</h5>
                </div>
                <div class="col-12 py-4">
                  <div class="row">
                    <div class="col-md-3">
                      <p>Account Name</p>
                      <h5><strong>Guy Thorne</strong></h5>
                    </div>
                    <div class="col-md-3">
                      <p>Account Number</p>
                      <h5><strong>9145754355</strong></h5>
                    </div>
                    <div class="col-md-6">
                      <p>Bank Name</p>
                      <h5><strong>National Bank of Inspection</strong></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row mx-3">
          <div class="user-details--wrapper">
            <h5 class="card-title">DIRECT DEBIT CUSTOMER</h5>
            <br />
            <p>History of transactions through your direct debit</p>
            <br />
            <v-client-table
              :data="tableData"
              :columns="columns"
              :options="options"
            >
              <template slot="createdAt" slot-scope="props">
                <div>
                  {{ props.row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
                </div>
              </template>
            </v-client-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5 pt-5" v-else>
      <div class="col-12 text-center">
        <i class="la la-xing la-5x"></i>
      </div>
      <div class="col-12 mt-5">
        <h3 class="text-center">
          <strong>Direct Debit has not been activated yet</strong>
        </h3>
      </div>
      <div class="col-12 mb-5">
        <h3 class="text-center">
          To activate direct debit, click the button below.
        </h3>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-lg themed-button py-3 px-5" @click="popModal">
          <span class="text-white">Activate</span>
        </button>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="activateDirectDebit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <h4 class="text-center">
                  <strong>Activate Direct Debit</strong>
                </h4>
              </div>
              <div class="col-12">
                <form @submit.prevent="activateDirectDebit" class="px-5">
                  <div v-if="activateStep == 1">
                    <div class="row mt-5">
                      <!-- Charge Type -->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label class="input-item-label"
                            >Select Settlement Type</label
                          >
                          <span
                            class="text-danger"
                            title="This field is required"
                            >*</span
                          >
                          <v-select
                            class="v-select"
                            placeholder="Select settlement type"
                            v-model="settlementType"
                            @keydown.enter.native.prevent
                            :options="settlementTypes"
                            label="currency"
                          ></v-select>
                        </div>
                      </div>
                      <!-- Charge Type -->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label class="input-item-label"
                            >Select Charge Type</label
                          >
                          <span
                            class="text-danger"
                            title="This field is required"
                            >*</span
                          >
                          <v-select
                            class="v-select"
                            placeholder="Select charge type"
                            v-model="chargeType"
                            @keydown.enter.native.prevent
                            :options="chargeOptions"
                            label="currency"
                          ></v-select>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <p>
                          <strong>NB : </strong> Charge type is the person that
                          will be charged for these transactions. You can always
                          change this in the setting
                        </p>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="row justify-content-between px-3 py-5">
                          <button class="btn btn-outline" data-dismiss="modal">
                            <span>Cancel</span>
                          </button>
                          <p class="btn themed-button" @click="nextStep">
                            <span class="text-white">Next</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="activateStep == 2">
                    <div class="row mt-5">
                      <div class="col-sm-12">
                        <h5>
                          <strong>
                            Enter account details for direct debit settlement
                          </strong>
                        </h5>
                      </div>
                      <!-- Select Bank -->
                      <div class="col-sm-12 mt-2">
                        <div class="form-group">
                          <label class="input-item-label">Select Bank</label>
                          <span
                            class="text-danger"
                            title="This field is required"
                            >*</span
                          >
                          <v-select
                            class="v-select"
                            placeholder="Select bank for setlement"
                            v-model="selectedBank"
                            @keydown.enter.native.prevent
                            :options="bankList"
                            label="name"
                          ></v-select>
                        </div>
                      </div>
                      <!-- Account Number-->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label class="input-item-label">Account Number</label>
                          <span
                            class="text-danger"
                            title="This field is required"
                            >*</span
                          >
                          <input
                            type="text"
                            v-model="accountNumber"
                            required
                            class="form-control input-bordered"
                            placeholder="Enter Account Number"
                            maxlength="10"
                          />
                        </div>
                      </div>
                      <!-- Account Name-->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label class="input-item-label">Account Name</label>
                          <input
                            type="text"
                            :value="accountName"
                            readonly
                            required
                            class="form-control input-bordered"
                            placeholder="Select bank and enter account number to verify"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="row justify-content-between px-3 py-5">
                          <button class="btn btn-outline" data-dismiss="modal">
                            <span>Cancel</span>
                          </button>

                          <p class="btn themed-button" @click="processActivate">
                            <span class="text-white" v-if="verifiedAccountName"
                              >Activate</span
                            >
                            <span class="text-white" v-else
                              >Verify Account Number</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nprogress from "nprogress";
import FilterBar from "../components/UI/FilterBar";
import { notifications } from "../../public/assets/mixins/notifications";
export default {
  mixins: [notifications],
  components: {
    FilterBar,
  },
  data() {
    return {
      chargeOptions: ["customer", "merchant"],
      settlementTypes: ["wallet", "bank"],
      bankOptions: [
        "Access Bank",
        "First Bank of Nigeria",
        "Union bank",
        "Zenith Bank",
      ],
      chargeType: "customer",
      settlementType: "bank",
      activeDirectDebit: false,
      verifiedAccountName: false,
      loading: true,
      submitting: false,
      bankList: [],
      activateStep: 1,
      selectedBank: "",
      accountNumber: "",
      accountName: "",
      withdrawAmount: "",
      tableData: [],
      columns: ["email", "phone", "createdAt"],
      options: {
        sortable: [],
        perPage: 10,
        pagination: {
          chunk: 5,
        },

        headings: {
          email: "E mail",
          phone: "Phone",
          createdAt: "Created At",
        },
        perPageValues: [],
        filterByColumn: true,
      },
    };
  },
  methods: {
    nextStep() {
      this.activateStep++;
    },
    popModal() {
      $("#activateDirectDebit").modal("show");
    },
    async processActivate() {
      if (this.verifiedAccountName === true) await this.activateDirectDebit();
      else await this.verifyAccountNumber();
    },
    getRequestData() {
      let requestData = {
        clientID: this.user.api_customer.client_id,
        payload: {
          chargeType: this.chargeType,
          accountNumber: this.accountNumber,
          accountName: this.accountName,
          bank: this.selectedBank.name,
          bankCode: this.selectedBank.code,
          settlementType: this.settlementType,
        },
      };
      return requestData;
    },
    async activateDirectDebit() {
      Nprogress.start();
      this.submitting = true;
      let activationData = this.getRequestData();
      $("#activateDirectDebit").modal("hide");
      try {
        const activateResponse = await this.$store.dispatch(
          "directdebit/activateDirectDebit",
          activationData
        );
        this.showSuccessNotification(activateResponse.message);
        this.isLoading = false;
        this.submitting = false;
        Nprogress.done();
      } catch (error) {
        this.submitting = false;
      }
    },
    async getBankList() {
      try {
        const bankList = await this.$store.dispatch(
          "fundstransfer/getBankList"
        );
        this.bankList = bankList.data.result;
        this.isLoading = false;
      } catch (error) {}
    },
    async verifyAccountNumber() {
      Nprogress.start();
      this.submitting = true;
      const data = {
        account_number: this.accountNumber,
        bank_code: this.selectedBank.code,
      };
      try {
        const verifiedAccount = await this.$store.dispatch(
          "fundstransfer/verifyAccountNumber",
          data
        );
        this.showSuccessNotification(verifiedAccount.data.message);
        this.accountName = verifiedAccount.data.results[0].account_name.toUpperCase();
        this.isLoading = false;
        this.submitting = false;
        this.verifiedAccountName = true;
        Nprogress.done();
      } catch (error) {
        this.submitting = false;
        this.accountNumber = false;
        Nprogress.done();
      }
    },
    async getDirectDebitCustomers() {
      try {
        let merchantData = {
          clientID: this.user.api_customer.client_id,
        };
        const ddCustomers = await this.$store.dispatch(
          "directdebit/getDirectDebitCustomers",
          merchantData
        );
        this.tableData = ddCustomers.results.customers;
      } catch (error) {}
    },
    async withdrawFunds() {
      try {
        this.isLoading = true;
        this.submitting = true;
        let merchantData = {
          clientID: this.user.api_customer.client_id,
          payload: {
            amount: this.withdrawAmount,
          },
        };
        const withdrawResponse = await this.$store.dispatch(
          "directdebit/withdrawFunds",
          merchantData
        );
        this.showSuccessNotification(withdrawResponse.data.message);
        this.isLoading = false;
        this.submitting = false;
      } catch (error) {}
    },
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
  async mounted() {
    this.loading = true;
    const historyResponse = await this.$store.dispatch(
      "wallets/getWalletHistory"
    );
    this.getBankList();
    this.getDirectDebitCustomers();
    this.walletHistories = historyResponse.results;
    this.loading = false;
  },
};
</script>