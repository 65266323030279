<template>
	<div>
		<CustomBuiltSelect
			:label="'Tax'"
			:btnName="'Tax'"
			:placeholder="'Select Tax'"
			:value="value"
			:error="error"
			:loading="loading"
			:selected="selected"
			:getLabel="getLabel"
			:required="required"
			:options="filteredTax"
			:handleChange="handleChange"
			:handleSelect="handleSelect"
			:createFooter="openAddTaxModal"
		/>

		<CreateTaxModal
			:id="'addTax'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";
import CreateTaxModal from "@/components/wallet/Subscriptions/modals/CreateTaxModal/CreateTaxModal";
import CustomBuiltSelect from "../CustomBuiltSelect/CustomBuiltSelect";

export default {
	name: "TaxSelect",
	data() {
		return {
			filteredTax: [],
			taxes: [],
			selected: null,
			value: "",
			loading: false,
		};
	},
	components: {
		CustomBuiltSelect,
		CreateTaxModal,
	},
	props: ["required", "error", "action", "tax", "setRequest"],
	methods: {
		async refetch(tax) {
			this.action(tax);
			this.getTax();
		},
		handleChange({ target: { value } }) {
			if (value) {
				this.value = value;
				let data = [...this.taxes].filter((tax) =>
					tax.name.toLowerCase().includes(value.toLowerCase())
				);
				this.filteredTax = data;
			} else {
				this.value = "";
				this.filteredTax = this.taxes;
			}
		},
		getLabel(tax) {
			const { name, type } = tax || {};
			return name
				? `${name} ( ${
						type == "included" ? "Inclusive" : "Exclusive"
				  } )`
				: "";
		},
		handleSelect(option) {
			this.selected = option;
			this.value = this.getLabel(option);
			this.setRequest(option);
		},
		openAddTaxModal() {
			$("#addTax").modal("show");
		},
		closeModal() {
			$("#addTax").modal("hide");
		},

		async getTax() {
			this.loading = true;
			try {
				const response = await this.$store.dispatch("tax/getAllTaxes", {
					apiKey: getWalletApikey(this.$store),
					params: { limit: 100000000000000 },
				});
				this.taxes = response.data.data.data;
				this.filteredTax = response.data.data.data;
				this.loading = false;
				if (typeof this.tax === "number") {
					let val = response.data.data.data.find(
						(obj) => obj.id === this.tax
					);
					this.selected = val;
					this.value = this.getLabel(val);
					setTax(val);
				}
			} catch (error) {
				this.loading = false;
				this.taxes = [];
			}
		},
		setTax(v) {
			this.selected = v;
			this.value = this.getLabel(v);
		},
	},
	mounted() {
		this.getTax();
		this.setTax(this.tax);
	},
	watch: {
		tax(option) {
			this.setTax(option);
		},
	},
};
</script>
