<template>
  <Modal :id="id" :title="'Add Beneficiary'" :toggle="closeModal">
    <form @submit.prevent="handleSubmit">
      <div>
        <div class="row">
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'firstName'"
              :label="'First Name'"
              :placeholder="'First Name'"
              :required="true"
              v-model="beneficiaryForm.firstName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.firstName.$error"
            >
              <li class="parsley-required">First Name is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'middleName'"
              :label="'Middle Name'"
              :placeholder="'Middle Name'"
              :required="true"
              v-model="beneficiaryForm.middleName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.middleName.$error"
            >
              <li class="parsley-required">Middle Name is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'lastName'"
              :label="'Last Name'"
              :placeholder="'Last Name'"
              :required="true"
              v-model="beneficiaryForm.lastName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.lastName.$error"
            >
              <li class="parsley-required">Last Name is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'email'"
              :label="'Email'"
              :placeholder="'Email'"
              v-model="beneficiaryForm.email"
            />
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'phoneNumber'"
              :maxlength="11"
              :placeholder="'Phone Number'"
              :label="'Enter Phone Number'"
              :required="true"
              :isNum="true"
              v-model="beneficiaryForm.phoneNumber"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.phoneNumber.$error"
            >
              <li class="parsley-required">Phone Number is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'address'"
              :placeholder="'Address'"
              :label="'Address'"
              :required="true"
              v-model="beneficiaryForm.address"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.address.$error"
            >
              <li class="parsley-required">Address is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <div class="form-group">
              <label class="input-item-label">Country</label>
              <v-select
                class="v-select"
                placeholder="Select a country"
                :options="metaData.countries"
                label="name"
                @input="changeState"
                @keydown.enter.native.prevent
                name="country"
                v-model="beneficiaryForm.country"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.country.$error"
            >
              <li class="parsley-required">Country is required.</li>
            </ul>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="input-item-label">State</label>
              <v-select
                class="v-select"
                placeholder="Select a state"
                v-model="beneficiaryForm.state"
                @keydown.enter.native.prevent
                :disabled="!beneficiaryForm.country"
                :options="states"
                label="state_label"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.state.$error"
            >
              <li class="parsley-required">State is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'city'"
              :maxlength="11"
              :placeholder="'City'"
              :label="'City'"
              :required="true"
              v-model="beneficiaryForm.city"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.city.$error"
            >
              <li class="parsley-required">City is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'postCode'"
              :maxlength="11"
              :placeholder="'Postcode'"
              :label="'Postcode'"
              :required="true"
              :isNum="true"
              v-model="beneficiaryForm.postCode"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.postCode.$error"
            >
              <li class="parsley-required">Postcode is required.</li>
            </ul>
          </div>

          <div class="col-md-6 mb-2">
            <label class="">
              Gender
              <span class="text-danger" title="This field is required">*</span>
            </label>
            <div class="d-flex">
              <label class="rdiobox mr-4">
                <input
                  name="gender"
                  type="radio"
                  checked
                  value="Male"
                  v-model="beneficiaryForm.gender"
                />
                <span class="fs-15">Male</span>
              </label>
              <label class="rdiobox">
                <input
                  name="gender"
                  type="radio"
                  value="Female"
                  v-model="beneficiaryForm.gender"
                />
                <span class="fs-15"> Female </span>
              </label>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.beneficiaryForm.gender.$error"
            >
              <li class="parsley-required">Gender is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <div class="form-group">
              <label class="input-item-label">Date of Birth</label>
              <DatePicker
                :name="'birthDate'"
                v-model="beneficiaryForm.birthDate"
                :disabledDates="{ from: new Date() }"
                :placeholder="'Date of Birth'"
              />
              <ul
                class="parsley-errors-list filled"
                v-show="$v.beneficiaryForm.birthDate.$error"
              >
                <li class="parsley-required">Date of Birth is required.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="flex__between">
        <button
          type="button"
          class="btn btn-outline w-100 mr-3"
          @click="handleClose"
        >
          <span>Cancel</span>
        </button>
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100 ml-3"
          :disabled="loading"
        >
          <Spinner v-if="loading" />
          <span v-else>Create</span>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Nprogress from "nprogress";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import { required } from "vuelidate/lib/validators";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
import Spinner from "../../../../../utils/Spinner/Spinner.vue";
import { newCountryFormat } from "@/assets/js/countryDailcodes";
import DatePicker from "@/utils/DatePicker/DatePicker";
import { getWalletApikey, getError } from "@/utils/helpers";

export default {
  mixins: [notifications],
  name: "AddBeneficiaryModal",
  data() {
    return {
      beneficiaryForm: {},
      loading: false,
      newCountryFormat,
      states: [],
    };
  },
  validations: {
    states: [],
    beneficiaryForm: {
      firstName: { required },
      middleName: { required },
      lastName: { required },
      phoneNumber: { required },
      address: { required },
      country: { required },
      state: { required },
      city: { required },
      gender: { required },
      birthDate: { required },
      postCode: { required },
    },
  },
  props: ["id", "closeModal", "refetch"],
  components: { Modal, CustomInput, CustomSelect, DatePicker, Spinner },

  methods: {
    clearInputFields() {
      this.beneficiaryForm = {};
      this.loading = false;
    },
    changeState() {
      // this.beneficiaryForm.state = [];
      this.states = this.beneficiaryForm.country.states;
    },
    handleClose() {
      this.clearInputFields();
      this.closeModal();
    },
    async handleSubmit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      try {
        let response = await this.$store.dispatch("remittance/addBeneficiary", {
          payload: {
            ...this.beneficiaryForm,
            country: this.beneficiaryForm.country.name,
            state: this.beneficiaryForm.state.state_label,
          },
          apiKey: getWalletApikey(this.$store),
        });

        console.log({ ressss: response.data });
        this.showSuccessNotification(response.data.message);

        setTimeout(() => {
          this.handleClose();
          this.loading = false;
          this.clearInputFields();
          this.refetch({});
        }, 1000);

        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        console.log("[error] ->", error.response);
        this.loading = false;
        this.showErrorLoginNotification(getError(error.response));
      }
    },
  },
  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
  },
  mounted() {
    this.states = this.metaData.countries[0].states;
  },
};
</script>
