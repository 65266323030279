<template>
	<div class="form-group">
		<label class="input-item-label">Select Plan</label>
		<span class="text-danger pl-1" title="This field is required">*</span>
		<v-select
			class="v-select"
			:class="cls"
			placeholder="Select Plan"
			@keydown.enter.native.prevent
			:label="'name'"
			:options="plans"
			:value="value"
			:multiple="multiple"
			v-on:input="$emit('input', $event)"
			:clearable="true"
      @close="$emit('close', $event)"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
import { getWalletApikey } from "../../helpers";

export default {
	name: "PlanSelect",
	data() {
		return {
			loading: false,
			plans: [],
		};
	},
	props: [
		"name",
		"cls",
		"value",
		"error",
		"handleError",
		"multiple",
		"setPlans",
	],
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async getPlans() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"plans/getAllPlans",
					{
						apiKey: getWalletApikey(this.$store),
					}
				);
				const { status, data } = response || {};
				if (status === 200) {
					this.loading = false;
					const allplans = data.data.data;
					this.plans = [
						{ name: "All Plans", reference: "allPlans" },
						...allplans,
					];
					this.setPlans && this.setPlans(allplans);
				}
			} catch (error) {
				this.plans = [];
				this.loading = false;
			}
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getPlans();
	},
};
</script>
