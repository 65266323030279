<template>
	<div class="p-4">
		<div class="SectionItem no-b-b mb-4 justify-content-start">
			<img
				src="/assets/img/icons/arrow-left.svg"
				width="30"
				alt=""
				class="pointer"
				@click="$router.push('/wallet/payment/payouts')"
			/>
			<p class="title ml-3 mb-0">Back to Payouts</p>
		</div>

		<div class="card overflow-hidden mt-3">
			<div class="row py-3">
				<div class="col-lg-3 col-md-6 col-12 text-center border-right">
					<h4>
						{{ toCurrency(payout.amount || 0) }}
					</h4>
					<p class="call-name pt-2">Amount</p>
				</div>

				<div class="col-lg-3 col-md-6 col-12 text-center border-right">
					<h4>
						{{ toCurrency(payout.amount + payout.charges) }}
					</h4>
					<p class="call-name pt-2">Total Amount</p>
				</div>

				<div class="col-lg-4 col-md-7 col-12 text-center border-right">
					<h5>
						{{ recipient(payout.BankAccountId) }}
					</h5>
					<p class="call-name pt-2">Payout Account</p>
				</div>

				<div class="col-lg-2 col-md-5 col-12 text-center border-right">
					<h4
						:class="
							payout.status === 'successful' ? 'text-success' : ''
						"
					>
						{{ titleCase(payout.status) }}
					</h4>
					<p class="call-name pt-2">Status</p>
				</div>
			</div>
		</div>

		<TransactionsTable :title="'Transactions'" :params="tableParam" />
	</div>
</template>

<script>
import EmptyWallet from "../../../common/EmptyWallet/EmptyWallet";
import TransactionsTable from "../../../common/TransactionsTable/TransactionsTable";
import Spinner from "../../../../../utils/Spinner/Spinner";
import FailedState from "../../../../../utils/ResponseState/FailedState";
import {
	toCurrency,
	titleCase,
	getWalletApikey,
} from "../../../../../utils/helpers";

export default {
	name: "PaymentPayoutDetails",
	data() {
		return {
			reference: "",
			loading: false,
			errorMsg: "",
			payout: {},
			merchantBanks: [],
			tableParam: {
				payout: this.$route.query.ref,
			},
		};
	},
	components: {
		TransactionsTable,
		Spinner,
		FailedState,
		EmptyWallet,
	},
	methods: {
		toCurrency,
		titleCase,
		recipient(bankID) {
			const bank = this.merchantBanks.find(({ id }) => id === bankID);
			return bank
				? `${bank.account_number} (${bank.bank})`
				: "-------------";
		},
		async getPayoutDetails(reference) {
			try {
				this.loading = true;
				this.errorMsg = "";
				const response = await this.$store.dispatch(
					"payment/getPayoutDetails",
					{
						apiKey: getWalletApikey(this.$store),
						reference: encodeURIComponent(reference),
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.payout = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
		async getMerchantBanks() {
			try {
				const response = await this.$store.dispatch(
					"wallets/getMerchantBanks",
					{ apiKey: getWalletApikey(this.$store) }
				);
				this.merchantBanks = response.data.data;
			} catch (error) {
				this.merchantBanks = [];
			}
		},
	},

	async mounted() {
		const reference = this.$route.query.ref;
		this.reference = reference;
		this.getMerchantBanks();
		this.getPayoutDetails(reference);
	},
};
</script>
