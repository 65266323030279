import { walletAPI } from "./api";

export default {
  uploadLogo({ params, request }) {
    return walletAPI.post("/merchant/update-logo", request, {
      headers: {
        'x-api-key': params.apiKey
      },
    });
  },
};
