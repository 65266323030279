<template>
  <div @click="handleClick" class="table-modal-wrapper transaction">
    <div ref="modal" class="table-modal">
      <div style="text-align: right" class="p-3">
        <i
          style="cursor: pointer"
          @click="closeModal"
          class="far fa-times-circle"
        ></i>
      </div>
      <p class="badge custom-badge" @click="copy">
        Transaction Reference: <b>{{ data.reference }}</b>
      </p>

      <div
        class="p-4 border-top"
        style="
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <h4 class="font-weight-bold">Transaction Information</h4>
        <div class="content">
          <div class="information">
            <p>Amount</p>
            <span>{{ data.currency }} {{ toCurrency(data.amount, " ") }}</span>
          </div>
          <div class="information">
            <p>Charges</p>
            <span>{{ data.currency }} {{ toCurrency(data.charges, " ") }}</span>
          </div>
          <div class="information">
            <p>Transaction Narration</p>
            <span>{{ data.narration || "Not Available" }}</span>
          </div>
          <div class="information">
            <p>Source Country Currency</p>
            <span>{{ data.senderCountryCurrency || "Not Available" }}</span>
          </div>
          <div class="information">
            <p>Destination Country Currency</p>
            <span>{{ data.receiverCountryCurrency || "Not Available" }}</span>
          </div>
          <div class="information">
            <p>Status</p>
            <span
              :class="
                data.status === 'SUCCESSFUL' ? 'text-success' : 'text-primary'
              "
              >{{ data.status }}</span
            >
          </div>
          <div class="information">
            <p>Transaction Date</p>
            <span>
              {{
                timeDateFormat(data.created_at).date +
                " " +
                timeDateFormat(data.created_at).time
              }}
            </span>
          </div>
        </div>
        <br />
        <h4 class="font-weight-bold">Sender Information</h4>
        <div class="content">
          <div class="information">
            <p>First Name</p>
            <span>{{ data.senderFirstName || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Last Name</p>
            <span>{{ data.senderLastName || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Email</p>
            <span>{{ data.senderEmail || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Phone Number</p>
            <span>{{ data.senderPhoneNumber || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Address</p>
            <span>{{ data.senderAddressOne || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>State</p>
            <span>{{ data.senderState || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>City</p>
            <span>{{ data.senderCity || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Country</p>
            <span>{{ data.senderCountry || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Post Code</p>
            <span>{{ data.senderPostCode || "Not Available" }} </span>
          </div>
        </div>
        <br />
        <h4 class="font-weight-bold">Reciever Information</h4>
        <div class="content">
          <div class="information">
            <p>First Name</p>
            <span>{{ data.receiverFirstName || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Last Name</p>
            <span>{{ data.receiverLastName || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Email</p>
            <span>{{ data.receiverEmail || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Phone Number</p>
            <span>{{ data.receiverPhoneNumber || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Address</p>
            <span>{{ data.receiverAddress || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>State</p>
            <span>{{ data.receiverState || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>City</p>
            <span>{{ data.receiverCity || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Country</p>
            <span>{{ data.receiverCountry || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Post Code</p>
            <span>{{ data.receiverPostCode || "Not Available" }} </span>
          </div>
          <div class="information">
            <p>Reciever Bank Code</p>
            <span>{{ titleCase(data.receiverBankCode) }}</span>
          </div>
          <div class="information">
            <p>Reciever Bank Number</p>
            <span>*** ****{{ data.receiverAccountNumber.slice(6, data.receiverAccountNumber.length) }}</span>
          </div>
          <div class="information">
            <p>Reciever Bank Name</p>
            <span>{{ titleCase(data.receiverBankName) }}</span>
          </div>
        </div>
      </div>
      <div class="flex__start my-4">
        <button
          type="button"
          class="btn btn-outline btn-sm ml-4"
          @click="closeModal"
        >
          <span>Close</span>
        </button>
        <button @click="sendMoney" class="btn btn-sm btn-primary ml-3">
          <Spinner v-if="loading" />
          <span v-else>Send Money</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import {
  titleCase,
  timeDateFormat,
  copyToClipboard,
  toCurrency,
  getWalletApikey,
  getError,
} from "@/utils/helpers";
import Nprogress from "nprogress";
import Spinner from "@/utils/Spinner/Spinner";

export default {
  name: "RemittanceModal",
  mixins: [notifications],
  data() {
    return { loading: false };
  },
  props: {
    data: {
      type: Object,
    },
  },
  components: { Spinner },
  methods: {
    titleCase,
    timeDateFormat,
    toCurrency,
    handleClick(e) {
      if (!e.target.closest(".table-modal")) {
        return this.$emit("close-modal");
      }
    },
    closeModal(e) {
      this.$emit("close-modal");
    },
    async sendMoney() {
      Nprogress.start();
      this.loading = true;
      let payload = { ...this.data, saveProcess: false };
      delete payload.receiverBirthDate;
      delete payload.senderBirthDate;
      delete payload.senderAddressTwo;
      delete payload.receiverMiddleName;
      delete payload.senderMiddleName;
      delete payload.receiverPostCode;
      delete payload.WalletId;
      delete payload.secretAnswer;
      delete payload.secretQuestion;
      delete payload.transactionSourceCode;
      delete payload.id;
      delete payload.MerchantId;
      delete payload.created_at;
      delete payload.updated_at;
      delete payload.status;
      delete payload.statustransactionType;
      delete payload.receiverBankName;
      delete payload.receiverBankCode;
      delete payload.AggregatorId;
      delete payload.receiverPhoneCode;
      delete payload.senderPhoneCode;
      try {
        let response = await this.$store.dispatch("remittance/createPayment", {
          payload,
          apiKey: getWalletApikey(this.$store),
        });
        this.showSuccessNotification(response.data.message);
        this.loading = false;
        Nprogress.done();
        setTimeout(() => {
          this.$router.push(`/wallet/remittance`);
        }, 1000);
      } catch (error) {
        Nprogress.done();
        console.log("[error] ->", error);
        this.loading = false;
        console.log(error.response.data);
        this.showErrorLoginNotification(getError(error.response));
      }
    },
    copy() {
      const copied = copyToClipboard(this.data.reference);
      copied && this.showSuccessNotification("Transaction Reference Copied!");
    },
  },
};
</script>
