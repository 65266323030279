<template>
	<div>
		<div class="py-4">
			<EmptyWallet v-if="!merchant" />
			<div v-else class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreateSplitPaymentButton
								:splitPaymentRef="$refs.splitPaymentRef"
						/></AccessControl>
					</template>
					<template slot="filterForm">
						<SplitPaymentFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('splitPaymentRef')"
							:download="downloadSplitPayment"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<CreateSplitPaymentButton
							:splitPaymentRef="$refs.splitPaymentRef"
						/>
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="splitPaymentRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@row-click="handleClick"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="props">
							<span>
								{{ timeDateFormat(props.row.createdAt).date }}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import CreateSplitPaymentButton from "./buttons/CreateSplitPaymentButton/CreateSplitPaymentButton.vue";
import SplitPaymentFilters from "./filters/SplitPaymentFilters/SplitPaymentFilters";
import { tableHeadings, splitPaymentCsvRows } from "./enums";

export default {
	mixins: [componentData],
	name: "SplitPayments",
	data() {
		return {
			emptyTitle: "No Split Payment Found",
			...this.tableOptions(tableHeadings, this.size),
		};
	},
	components: { SplitPaymentFilters, CreateSplitPaymentButton },
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(`/wallet/split-payments/${row.reference}`);
		},
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.bank_code)
				newFilters.bank_code = newFilters.bank_code.code;
			this.handleRefetch(
				newFilters,
				this.getSplitPayments,
				"splitPaymentRef"
			);
		},
		async getSplitPayments() {
			this.handleAPICall("payment/getMerchantSplitPayments");
		},
		async downloadSplitPayment() {
			const download = (filterData) =>
				this.downloadReport(splitPaymentCsvRows, filterData.data.data);
			this.downloadTableReport(
				"payment/getMerchantSplitPayments",
				download
			);
		},
	},
	created() {
		this.getSplitPayments();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
