<template>
	<div class="p-md-4 p-3">
		<div class="SectionItem no-b-b mb-3 flex__between">
			<p class="title">Messaging</p>
		</div>

		<DefaultContent
			v-if="!profile.is_active"
			:image="'empty-resources.png'"
			:title="`Seems you've not verified your account.`"
			:subtitle="'Verify your account to start testing out the different services. Contact the Admin to verify your account'"
		/>

		<DefaultContent
			v-if="profile.is_active && applications.length == 0"
			:image="'empty-resources.png'"
			:title="'No Application Created'"
			:subtitle="'You currently do not have any active application, Please create one start testing services'"
		>
			<router-link class="btn btn-md btn-primary" to="/applications">
				<i class="la la-plus"></i> Create Application
			</router-link>
		</DefaultContent>

		<template v-else>
			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => fetchApps()"
			/>

			<div class="content__slider">
				<TabsV2 :tabNames="tabNames" :tabId="'messageTab'" />
			</div>

			<template v-if="hasService && userRole !== 'Secondary Customer'">
				<div class="tab-content" id="messageTabContent">
					<SendSingleSms
						:servicePrice="servicePrice"
						:applications="applications"
					/>
					<OTPService
						:servicePrice="servicePrice"
						:applications="applications"
					/>
					<SmsLogs
						:servicePrice="servicePrice"
						:applications="applications"
					/>
				</div>
			</template>

			<div v-else class="flex__center my-5 py-5">
				<span
					class="badge badge-danger"
					style="font-size: 14px; margin-top: 5px; font-weight: 500"
					>You don't have access to this service</span
				>
			</div>
		</template>
	</div>
</template>

<script>
import TabsV2 from "../../../utils/Tabs/TabsV2";
import Spinner from "../../../utils/Spinner/Spinner";
import FailedState from "../../../utils/ResponseState/FailedState";
import SendSingleSms from "./SendSingleSms/SendSingleSms";
import OTPService from "./OTPService/OTPService";
import SmsLogs from "./SmsLogs/SmsLogs";

const tabNames = [
	{
		tabName: "Send Single SMS",
		value: "send_single_sms",
	},
	{
		tabName: "OTP Service",
		value: "otp_service",
	},
	{
		tabName: "SMS Logs",
		value: "sms_logs",
	},
];

export default {
	name: "Messaging",
	data() {
		return {
			tabNames,
			loading: false,
			errorMsg: "",
			apps: {},
			applications: [],
			servicePrice: 0,
			hasService: false,
		};
	},

	components: {
		TabsV2,
		FailedState,
		Spinner,
		SendSingleSms,
		OTPService,
		SmsLogs,
	},
	computed: {
		profile() {
			return this.$store.state.account.profile.api_customer;
		},
		userRole() {
			return this.$store.state.account.profile.roles[0].role_label;
		},
	},
	methods: {
		async fetchApps() {
			try {
				this.loading = true;
				await this.$store
					.dispatch("application/getApplications")
					.then((response) => {
						this.loading = false;
						this.apps = response;
						this.applications =
							response.applications.filter(
								({ is_active }) => is_active == 1
							) || [];
						this.computeService(response.customerServices);
					});
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
		computeService(services = []) {
			if (services) {
				services.filter(({ service_label, pivot }) => {
					if (service_label.includes("SMS")) {
						this.hasService = true;
						this.servicePrice = pivot.price_per_call;
					}
				});
			}
		},
	},
	mounted() {
		this.fetchApps();
	},
};
</script>
