<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreatePaymentCollectionButton
								:collectionRef="$refs.collectionRef"
							/>
						</AccessControl>
					</template>
					<template slot="filterForm">
						<PaymentCollectionFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('collectionRef')"
							:download="downloadCollection"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<CreatePaymentCollectionButton
							:collectionRef="$refs.collectionRef"
						/>
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="collectionRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
						@row-click="handleClick"
					>
						<template slot="title" slot-scope="{ row }">
							<b>{{ titleCase(row.title) }}</b>
						</template>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
								{{ timeDateFormat(row.createdAt).time }}
							</span>
						</template>
						<template slot="amount" slot-scope="{ row }">
							{{ toCurrency(row.amount, row.Currency.code) }}
						</template>
						<template slot="page_type" slot-scope="{ row }">
							<span>
								{{ titleCase(row.page_type) }}
							</span>
						</template>
						<template slot="status" slot-scope="{ row }">
							<span :class="statusColor(row.status)">
								{{ titleCase(row.status) }}
							</span>
						</template>
						<template slot="action" slot-scope="{ row }">
							<PaymentCollectionAction :pageData="row" />
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../public/assets/mixins/componentData";
import CreatePaymentCollectionButton from "../buttons/CreatePaymentCollectionButton/CreatePaymentCollectionButton";
import PaymentCollectionAction from "../buttons/PaymentCollectionAction/PaymentCollectionAction";
import PaymentCollectionFilters from "./PaymentCollectionFilters/PaymentCollectionFilters";
import { tableHeadings, paymentCollectionCsvRows } from "../enums";
import ViewMore from "@/utils/ViewMore/ViewMore";
import { toCurrency } from "@/utils/helpers/index";

export default {
	mixins: [notifications, componentData],
	name: "PaymentCollection",
	data() {
		return {
			emptyTitle: "No Collection Found",
			...this.tableOptions(tableHeadings, 20),
		};
	},
	components: {
		CreatePaymentCollectionButton,
		PaymentCollectionFilters,
		ViewMore,
		PaymentCollectionAction,
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(
				`/wallet/payment/collection/${row.reference}/information`
			);
		},
		toCurrency,
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.bank_name)
				newFilters.bank_name = newFilters.bank_name.name;
			if (newFilters.status) newFilters.status = newFilters.status.value;
			this.handleRefetch(newFilters, this.getCollection, "collectionRef");
		},
		async getCollection() {
			this.handleAPICall("payment/getPaymentPages");
		},
		async downloadCollection() {
			const download = (filterData) =>
				this.downloadReport(
					paymentCollectionCsvRows,
					filterData.data.data
				);
			this.downloadTableReport("payment/getPaymentPages", download);
		},
	},
	created() {
		this.getCollection();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
