export const splitPaymentCsvRows = {
	reference: "Reference",
	name: "Split Name",
	currency: "Currency",
	createdAt: "Date Created",
};

export const tableHeadings = {
	reference: "Reference",
	name: "Split Name",
	currency: "Currency",
	createdAt: "Date Created",
};

export const splitSubAccountHeading = {
	sub_account: "Sub Account Reference",
	type: "Sub Account Type",
	value: "Amount",
};
