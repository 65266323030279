import transactions from "@/services/wallets/transactions";

export default {
	namespaced: true,
	actions: {
		async getAllTransactions({}, payload) {
			return await transactions.getAllTransactions(payload);
		},
		async getTransactionsByRef({}, payload) {
			let { data } = await transactions.getTransactionsByRef(payload);
			return data;
		},
		async getAllPosTransactions({}, payload) {
			return await transactions.getPosTransactions(payload);
		},
		// async createCustomer({}, payload) {
		// 	try {
		// 		let { data } = await customers.createCustomer(payload);
		// 		return data;
		// 	} catch (error) {
		// 		throw Error(error);
		// 	}
		// },
	},
};
