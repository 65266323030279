import subscriptions from "@/services/wallets/subscriptions/subscriptions";

export default {
	namespaced: true,
	actions: {
		async getAllSubscriptions({}, payload) {
			return await subscriptions.getAllSubscriptions(payload);
		},
		async getSubscription({}, payload) {
			return await subscriptions.getSubscription(payload);
		},
		async createSubscription({}, payload) {
			return await subscriptions.createSubscription(payload);
		},
		async cancelSubscription({}, payload) {
			return await subscriptions.cancelSubscription(payload);
		},
	},
};
