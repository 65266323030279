<template>
	<div class="form-group amount_select">
		<label class="input-item-label">{{ label }}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>

		<div class="value__field">
			<v-select
				class="v-select"
				placeholder="NGN"
				@keydown.enter.native.prevent
				:options="options"
				:value="currency"
				:clearable="false"
				@input="handleSelect"
			/>
			<input
				:name="'amount'"
				:type="'number'"
				:value="amount"
				v-on:input="$emit('input', $event.target.value)"
				:placeholder="'Enter amount'"
				:disabled="disabled"
				:handleError="handleError"
				class="amount__input"
        @blur="$emit('blur', $event)"
				@keypress="isNumber"
			/>
		</div>
		<span v-show="error" class="text-danger">{{ error }}</span>
	</div>
</template>

<script>
	import "./CustomInput.css";

	export default {
		name: "CustomAmountInput",
		data() {
			return {
				options: ["NGN"],
			};
		},
		props: [
			"amount",
			"currency",
			"required",
			"label",
			"error",
			"isNum",
			"disabled",
			"handleError",
			"handleSelect",
		],

		methods: {
			isNumber(val) {
				if (this.isNum) {
					if (isNaN(Number(val.key))) return val.preventDefault();
				}
			},
		},
		watch: {
			amount() {
				this.handleError("amount");
			},
		},
	};
</script>
