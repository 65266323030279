import Nprogress from 'nprogress'

export const whitelabelMixin = {
  data() {
    return {
      isFetchingSettings: true,
    }
  },
  computed: {
    WLsettings() {
      return this.$store.state.whitelabelling.whitelabelledSettings
    },
  },
  async created() {
    console.log('[mounted] ->')
    let hostURL = localStorage.getItem('salt-host')
    let hostProtocol = localStorage.getItem('salt-protocol')

    let requestData = {
      host: hostURL,
      protocol: hostProtocol,
    }

    try {
      let response = await this.$store.dispatch('whitelabelling/getAppSettingByDomain', requestData)
      console.log('[response from whitelabelled settings fetch] ->', response)

      this.isFetchingSettings = false
      Nprogress.done()
    } catch (error) {
      this.isFetchingSettings = false
      Nprogress.done()
      console.log('[error] ->', error)
    }
  },
}
