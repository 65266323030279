<template>
	<div class="create-invoice-form">
		<div class="row">
			<div class="col-12">
				<MerchantLogoForm />
			</div>
			<div class="col-12 pb-4">
				<p class="text--primary font-weight-bold">Description:</p>
				<p>{{ invoice.description || "None" }}</p>
			</div>

			<div class="col-sm-6">
				<p class="text--primary font-weight-bold">Billing To:</p>
				<p class="m-0">
					{{ invoice.customer.first_name || "" }}
					{{ invoice.customer.last_name || "" }}
				</p>
				<span>
					{{ invoice.customer.email || "" }},
					{{ invoice.customer.phone || "" }}
				</span>
				<p>
					{{ invoice.billingAddress || "" }}
				</p>
			</div>

			<div class="col-sm-6 text-right">
				<p class="text--primary font-weight-bold">Due Date:</p>
				<p>
					{{ invoice.due_date || "None" }}
				</p>
			</div>

			<div class="col-12 pt-4">
				<p class="text--primary font-weight-bold">
					Shipping Address To:
				</p>
				<p>
					{{ invoice.shippingAddress || "None" }}
				</p>
			</div>

			<div class="col-12 mt-5">
				<PreviewItemsTable :invoice="invoice" />
			</div>

			<div v-if="invoice.notes" class="col-12 py-4">
				<p class="text--primary font-weight-bold">Customer Notes:</p>
				<p>{{ invoice.notes }}</p>
			</div>

			<div v-if="invoice.terms_and_condition" class="col-12 pb-4">
				<p class="text--primary font-weight-bold">
					Terms and Conditions:
				</p>
				<p>{{ invoice.terms_and_condition }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../public/assets/mixins/filters";
import PreviewItemsTable from "./PreviewItemsTable/PreviewItemsTable";
import MerchantLogoForm from "../common/MerchantLogoForm/MerchantLogoForm";

export default {
	mixins: [filters, notifications],
	name: "PreviewInvoice",
	props: ["invoice", "changeStatus"],
	components: {
		PreviewItemsTable,
		MerchantLogoForm,
	},
};
</script>
