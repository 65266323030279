<template>
  <div class="row mt-5">
    <div class="col-12">
      <div class="user-details-info">
        <div class="user-details--top-info">
          <h2 class="user-profile-name">API Request Format</h2>
          <h5 class="user-profile-email sub">
            Representation of how the API call can be made with the appropriate
            data neccesary
          </h5>
        </div>
      </div>
    </div>

    <div class="col-12">
      <pre class="code code-html">
        <label style="top:35%;">End-Point</label>
        <code> <slot name="endpoint" /> </code>
      </pre>
    </div>

    <div class="col-12">
      <pre class="code code-html">
        <label style="top:42%;">Headers</label>
        <code> <slot name="header" /> </code>
      </pre>
    </div>

    <div class="col-12">
      <pre class="code code-html">
        <label style="top:42%;">Body</label>
        <code> <slot name="body" /> </code>
      </pre>
    </div>

    <div class="col-12">
      <pre class="code code-html">
        <label>Sample Response</label>
        <code> <slot name="sample" /> </code>
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsePreview",
};
</script>
