import { walletAPI } from "../api";

export default {
	createWallet({ apiKey, body }) {
		return walletAPI.post(`/wallets`, body, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createPaymentCollection({ apiKey, request }) {
		return walletAPI.post(`/wallets/master/collections`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createPaymentPage({ apiKey, request }) {
		return walletAPI.post(`/payment-page`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updatePaymentPage({ apiKey, request, reference }) {
		return walletAPI.put(`/payment-page/${reference}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPaymentCollections({ apiKey, params }) {
		return walletAPI.get(`/wallets/master/collections`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPaymentPages({ apiKey, params }) {
		delete params.limit;
		delete params.query;
		delete params.ascending;
		delete params.byColumn;
		return walletAPI.get(`/payment-page`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getOnePaymentPage({ apiKey, reference }) {
		return walletAPI.get(`/payment-page/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPaymentCollection({ apiKey, reference }) {
		return walletAPI.get(`/wallets/master/collections/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createPaymentLink({ apiKey, request }) {
		return walletAPI.post(`/paymentlink`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPaymentLinks({ apiKey, params }) {
		return walletAPI.get(`/paymentlink`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	getPaymentLink({ apiKey, reference }) {
		return walletAPI.get(`/paymentlink/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updatePaymentLink({ apiKey, reference, request }) {
		return walletAPI.put(`/paymentlink/${reference}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	sendPaymentLink({ apiKey, request }) {
		const { reference, payload } = request || {};
		return walletAPI.post(`/paymentlink/${reference}/send`, payload, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	deletePaymentLink({ apiKey, reference }) {
		return walletAPI.delete(`/paymentlink/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	createSubMerchant(merchantDetails) {
		return walletAPI.post(`/wallets`, merchantDetails.payload, {
			headers: {
				"x-api-key": merchantDetails.apiKey,
			},
		});
	},

	getMerchantPayout({ apiKey, params }) {
		return walletAPI.get(`/merchant/payouts`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPayoutDetails({ apiKey, reference }) {
		return walletAPI.get(`/merchant/payouts/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantSubAccounts({ apiKey, params }) {
		return walletAPI.get(`/subaccounts`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantSplitPayments({ apiKey, params }) {
		return walletAPI.get(`/split-payments`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantSubAccount({ apiKey, reference }) {
		return walletAPI.get(`/subaccounts/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantSplitPayment({ apiKey, reference }) {
		return walletAPI.get(`/split-payments/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getSplitPaymentTransaction({ apiKey, params }) {
		console.log(params);
		return walletAPI.get(`/transactions`, {
			params: {
				...params,
			},
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	createMerchantSubAccounts({ apiKey, request }) {
		return walletAPI.post(`/subaccounts`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createMerchantSplitPayment({ apiKey, request }) {
		return walletAPI.post(`/split-payments`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateMerchantSplitPayment({ apiKey, request, reference }) {
		return walletAPI.put(`/split-payments/${reference}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getDisputes({ apiKey, params }) {
		return walletAPI.get(`/disputes`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	getDispute({ apiKey, id }) {
		return walletAPI.get(`/disputes/${id}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createDispute({ apiKey, request }) {
		return walletAPI.post(`/disputes`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getRefunds({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/refunds`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getRefunds({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/refunds`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateDisputeStatus({ apiKey, reference, params }) {
		return walletAPI.put(
			`/disputes/${reference}`,
			{},
			{
				params,
				headers: {
					"x-api-key": apiKey,
				},
			}
		);
	},
	getRefunds({ apiKey, params }) {
		return walletAPI.get(`/refunds`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	chargeCard({ apiKey, request }) {
		return walletAPI.post(`/charge/card`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
