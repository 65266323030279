<template>
  <AuthWrapper v-if="isLoading == false">
    <div class="card-login register">
      <a href="index.php" class="logo">
        <img src="/assets/img/blusalt-logo-sb-inverse.svg" alt />
      </a>
      <h2>Building the future of financial services</h2>
      <h4>Fill the form to request for whitelabelling services</h4>

      <form @submit.prevent="performRegistration">
        <div class="row">
          <!-- First name field -->
          <div class="col-md-6">
            <div class="form-group">
              <label class="input-item-label">Firstname</label>
              <input
                type="text"
                name="firstname"
                class="form-control input-bordered"
                v-model="customerDetails.firstname"
                placeholder="Enter your first name"
              />
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.customerDetails.firstname.$error"
              >
                <li class="parsley-required">Firstname is required.</li>
              </ul>
            </div>
          </div>

          <!-- last name field -->
          <div class="col-md-6">
            <div class="form-group">
              <label class="input-item-label">Lastname</label>
              <input
                type="text"
                name="lastname"
                class="form-control input-bordered"
                v-model="customerDetails.lastname"
                placeholder="Enter your last name"
              />
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.customerDetails.lastname.$error"
              >
                <li class="parsley-required">Lastname is required.</li>
              </ul>
            </div>
          </div>

          <!-- Email field -->
          <div class="col-md-6">
            <div class="form-group">
              <label class="input-item-label">Email</label>
              <input
                type="email"
                name="email"
                class="form-control input-bordered"
                v-model="customerDetails.email"
                placeholder="Enter your email"
              />
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.customerDetails.email.$error"
              >
                <li class="parsley-required">Email is required.</li>
              </ul>
            </div>
          </div>

          <!-- phone number field -->
          <div class="col-md-6">
            <div class="form-group">
              <label class="input-item-label">Phone Number</label>
              <input
                type="phonenumber"
                name="phonenumber"
                class="form-control input-bordered"
                v-model="customerDetails.phoneNumber"
                placeholder="Enter your phone number"
              />
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.customerDetails.phoneNumber.$error"
              >
                <li class="parsley-required">Phone number is required.</li>
              </ul>
            </div>
          </div>

          <!--Services field -->
          <div class="col-md-12">
            <div class="form-group">
              <label class="input-item-label">Services</label>
              <a
                href="#"
                class="right"
                v-if="false"
              >Fail over services provides fallbacks and makes results more efficent</a>
              <v-select
                class="v-select multiple"
                placeholder="Select a service"
                @keydown.enter.native.prevent
                v-model="customerDetails.services"
                :required="!customerDetails.services"
                :options="metaData.services"
                label="service_label"
                multiple
              ></v-select>
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.customerDetails.services.$error"
              >
                <li class="parsley-required">Services are required.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="gaps-2x"></div>
        <button
          class="btn btn-lg btn-primary btn-block mg-b-20"
          type="submit"
          :disabled="submitting == true"
        >
          <span v-if="submitting == true">Creating account</span>
          <span v-else>Submit</span>
        </button>
        <p>
          Already have an account?
          <router-link to="/">Login</router-link>
        </p>
      </form>
    </div>
  </AuthWrapper>
</template>

<script>
import Nprogress from 'nprogress'
import { required, minLength, alphaNum, sameAs } from 'vuelidate/lib/validators'
import AuthWrapper from '@/components/auth/AuthWrapper'
import { notifications } from '../../public/assets/mixins/notifications'

export default {
  name: 'Register',
  mixins: [notifications],
  components: {
    AuthWrapper
  },
  data() {
    return {
      isLoading: true,
      states: [],
      metaData: {},
      submitting: false,

      customerDetails: {
        firstname: '',
        lastname: '',
        email: '',
        services: '',
        phoneNumber: '',

      }
    }
  },
  watch: {
    'customerDetails.services'(newVal, old) {
      let newServiceArray, failOverServices, missingIndex
      if (newVal) newServiceArray = newVal.map(service => service.id)

      if (this.customerDetails.failOverOptions) {
        failOverServices = this.customerDetails.failOverOptions.map(service => service.id)
      }

      if (newServiceArray && failOverServices) {
        for (let index = 0; index < failOverServices.length; index++) {
          if (!newServiceArray.includes(failOverServices[index])) {
            missingIndex = index
            break
          }
        }
        this.customerDetails.failOverOptions.splice(missingIndex, 1)
      }
    }
  },
  validations: {
    customerDetails: {
      firstname: { required },
      lastname: { required },
      email: { required },
      phoneNumber: { required },
      services: { required },
    }
  },

  methods: {
    formatRequestData() {
      let requestData = new FormData()

      if (this.customerDetails.services) {
        this.customerDetails.services.map(service => {
          requestData.append('services', service.id)
        })
      }
      requestData.append('firstname', this.customerDetails.firstname)
      requestData.append('lastname', this.customerDetails.lastname)

      requestData.append('email', this.customerDetails.email)
      requestData.append('phone_number', this.customerDetails.phoneNumber)


      return requestData
    },
    async performRegistration() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('[invalid fields] ->')
        return
      }

      Nprogress.start()
      this.submitting = true
      let formattedResponse = this.formatRequestData()
      console.log('[formatted response ] ->', formattedResponse)
      try {
        let { data } = await this.$store.dispatch('auth/registerExternalUser', formattedResponse)
        this.showSuccessNotification(data.message)
        this.$swal({
          title: `Customer successfully registered`,
          text: 'Your Account Information was received. Please check your email for the instructions on how next to proceed for whitelabelling',
          icon: 'success',
          button: 'Ok'
        }).then(value => {
          this.$router.push('/')
        })
        console.log('[data from registration] ->', data)
        Nprogress.done()
      } catch (error) {
        Nprogress.done()
        this.submitting = false
        console.log('error :', error)
        this.showErrorLoginNotification(error.message)
      }
    }
  },
  async mounted() {
    try {
      let apiResponse = await this.$store.dispatch('getMetaData')
      this.metaData = apiResponse.results
      this.isLoading = false
    } catch (error) {
      console.log('[Error] ->', error)
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.card-login.register {
  min-width: 330px;
  max-width: 950px;
}
</style>
