<template>
	<Modal :id="id" :title="'Send Payment Link'" :toggle="handleCloseModal">
		<SuccessState
			v-if="successMsg"
			:message="'Payment Link Sent Successfully'"
			:onSuccess="handleCloseModal"
		>
			<template>
				<div
					v-if="pData.processed_email.length > 0"
					class="flex__center flex-wrap mb-4"
				>
					<div class="d-block font-weight-bold w-100">
						Payment Link Sent to the following Email(s):
					</div>
					<template v-for="(mail, i) in pData.processed_email">
						<span :key="i"
							>{{ mail }}
							<span
								v-if="i < pData.processed_email.length - 1"
								class="px-2"
								>|</span
							>
						</span>
					</template>
				</div>

				<div
					v-if="pData.processed_sms.length > 0"
					class="flex__center flex-wrap mb-4"
				>
					<div class="d-block font-weight-bold w-100">
						Payment Link Sent to the following Phone Number(s):
					</div>
					<template v-for="(sms, i) in pData.processed_sms">
						<span :key="i"
							>{{ sms }}
							<span
								v-if="i < pData.processed_sms.length - 1"
								class="px-2"
								>|</span
							>
						</span>
					</template>
				</div>
			</template>
		</SuccessState>

		<form v-else-if="!showOtp" @submit.prevent="handleSubmit">
			<div v-for="(formData, i) in request" :key="i" class="row">
				<div class="col-12 d-flex align-items-center">
					<div class="row w-100">
						<div class="col-6">
							<CustomPhoneInput
								:name="`phoneNumber${i}`"
								v-model="request[i][`phoneNumber${i}`]"
								:placeholder="'Input Phone Number'"
								:error="formErrors[`phoneNumber${i}`]"
								:handleError="handleError"
							/>
						</div>
						<div class="col-6">
							<CustomInput
								:type="'email'"
								:name="`email${i}`"
								v-model="request[i][`email${i}`]"
								:placeholder="'Input Email'"
								:error="formErrors[`email${i}`]"
								:handleError="handleError"
							/>
						</div>
					</div>
					<div class="ml-3 mb-3 flex__center" style="width: 30px">
						<span
							v-if="count(i) && request.length < 10"
							class="font-weight-bold text--primary"
							style="font-size: 40px"
							@click="addMore"
							>+</span
						>
						<img
							v-else
							class="pointer"
							src="/assets/img/icons/trash.svg"
							width="20px"
							height="20px"
							@click="() => remove(i)"
						/>
					</div>
				</div>
			</div>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
		<ContentWrapper
			v-else-if="showOtp"
			:loading="otpLoading"
			:errorMsg="errorMsg"
			:refetch="requestOtp"
		>
			<template slot="content">
				<form
					@submit.prevent="sendLink({ contacts: getContact(), otp })"
				>
					<div class="d-flex justify-content-center">
						<v-otp-input
							ref="otpInput"
							input-classes="otp-input"
							separator="-"
							:num-inputs="6"
							:should-auto-focus="true"
							:is-input-num="true"
							@on-change="handleOnChange"
						/>
					</div>
					<div class="flex__between pt-4">
						<button
							type="button"
							class="btn btn-outline w-100 mr-3"
							@click="showOtp = false"
						>
							<span>Back</span>
						</button>
						<button
							type="submit"
							class="btn btn-lg btn-primary w-100 ml-3"
							:disabled="otp.length < 6 || loading"
						>
							<span v-if="loading">...Submitting</span>
							<span v-else>Submit</span>
						</button>
					</div>
				</form>
			</template>
		</ContentWrapper>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput.vue";
import FormButtons from "@/utils/FormButtons/FormButtons";
import SuccessState from "@/utils/ResponseState/SuccessState";
import Modal from "@/utils/Modal/Modal";
import Spinner from "@/utils/Spinner/Spinner";
import { getError, getWalletApikey, getPhoneNumber } from "@/utils/helpers";
import ContentWrapper from "@/utils/TableWrapper/ContentWrapper";

const initialState = {
	request: [{ phoneNumber0: "", email0: "" }],
	errorMsg: "",
	loading: false,
	formErrors: {},
	successMsg: "",
	pData: {},
	showOtp: false,
	otpLoading: false,
	otp: "",
};

export default {
	mixins: [notifications],
	name: "SendPaymentLinkModal",
	data() {
		return { showOtp: false, ...initialState, settings: "" };
	},
	props: ["id", "closeModal", "reference"],
	components: {
		CustomInput,
		FormButtons,
		SuccessState,
		Modal,
		Spinner,
		CustomPhoneInput,
		ContentWrapper,
	},
	methods: {
		getPhoneNumber,
		count(len) {
			return this.request.length - 1 === len ? true : false;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleOnChange(value) {
			this.otp = value;
		},
		handleSubmit(e) {
			e.preventDefault();
			const contacts = this.getContact();
			const last = contacts[contacts.length - 1];
			if (last.customer_phone && last.customer_phone.length < 10)
				this.showErrorLoginNotification("Invalid Phone Number!!!");
			else if (
				last.customer_email &&
				!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					last.customer_email
				)
			)
				this.showErrorLoginNotification("Invalid Email!!!");
			else if (last.customer_email || last.customer_phone)
				if (this.settings.transfer_otp) {
					this.showOtp = true;
					this.requestOtp();
				} else {
					this.sendLink({ contacts });
				}
			else
				this.showErrorLoginNotification(
					"One of the fields must not be empty"
				);
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState });
			this.closeModal();
		},
		addMore() {
			const lastLen = this.request.length;
			const last = this.request[lastLen - 1];
			if (
				last[`phoneNumber${lastLen - 1}`] &&
				!last[`phoneNumber${lastLen - 1}`].valid
			)
				this.showErrorLoginNotification("Invalid Phone Number!!!");
			else if (
				last[`email${lastLen - 1}`] &&
				!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					last[`email${lastLen - 1}`]
				)
			)
				this.showErrorLoginNotification("Invalid Email!!!");
			else if (
				last[`phoneNumber${lastLen - 1}`] ||
				last[`email${lastLen - 1}`]
			)
				this.request = [
					...this.request,
					{
						[`phoneNumber${lastLen}`]: "",
						[`email${lastLen}`]: "",
					},
				];
			else
				this.showErrorLoginNotification(
					"One of the fields must not be empty"
				);
		},
		remove(i) {
			let newRequest = [...this.request];
			newRequest = newRequest.filter((_, index) => i !== index);
			this.request = newRequest;
		},
		getContact() {
			const invalidPhoneNumber = this.request.some(
				(arr, i) => !arr[`phoneNumber${i}`].valid
			);
			if (invalidPhoneNumber) {
				return this.showErrorLoginNotification(
					"Invalid Phone Number!!!"
				);
			}
			const contacts = this.request.reduce((acc, arr, i) => {
				let customer_phone = arr[`phoneNumber${i}`].nationalNumber
					? getPhoneNumber(arr[`phoneNumber${i}`])
					: "";
				let customer_email = arr[`email${i}`];
				let send_sms = this.findContact(acc, customer_phone);
				let send_email = this.findContact(acc, customer_email);
				acc.push({
					send_email,
					send_sms,
					customer_phone,
					customer_email,
				});
				return acc;
			}, []);
			return contacts;
		},
		findContact(arr, val) {
			const exist = arr.find(
				(v) => v.customer_email === val || v.customer_phone === val
			);
			return exist || !val ? false : true;
		},
		async sendLink(payload) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"payment/sendPaymentLink",
					{
						apiKey: getWalletApikey(this.$store),
						request: { reference: this.reference, payload },
					}
				);
				const { status, data } = response || {};
				if (
					(status === 200 || status === 201) &&
					(data.status || data.success)
				) {
					this.successMsg = true;
					this.pData = data.data;
				} else this.showErrorLoginNotification(data.message);
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(getError(error));
			}
		},
		async getWalletSettings() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getMerchantWalletSettings",
					{ apiKey: getWalletApikey(this.$store) }
				);

				if (response.status === 200) {
					this.loading = false;
					this.settings = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
		async requestOtp() {
			try {
				this.otpLoading = true;
				const response = await this.$store.dispatch(
					"wallets/requestOtp",
					{
						apiKey: getWalletApikey(this.$store),
					}
				);

				if (response.data.status === true) {
					this.otpLoading = false;
					this.showSuccessNotification("An OTP has been sent to you");
				}
			} catch (error) {
				this.otpLoading = false;
				this.errorMsg = error.message;
			}
		},
	},
	mounted() {
		this.getWalletSettings();
	},
};
</script>
