<template>
	<div>
		<span
			v-if="useText"
			class="font-weight-bold text--primary m-0 pointer"
			style="font-size: 14px"
			@click="openAddNewCustomerModal"
		>
			+ Add New Customer
		</span>
		<button
			v-else
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openAddNewCustomerModal"
		>
			Add New Customer
		</button>
		<AddCustomerModal
			:id="'addCustomer'"
			:closeModal="closeModal"
			:customerRef="customerRef"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import AddCustomerModal from "../../modals/AddCustomerModal/AddCustomerModal";

export default {
	name: "AddCustomerButton",
	props: ["customerRef", "useText", "refetch"],
	methods: {
		openAddNewCustomerModal() {
			$("#addCustomer").modal("show");
		},
		closeModal() {
			$("#addCustomer").modal("hide");
		},
	},
	components: {
		AddCustomerModal,
	},
};
</script>
