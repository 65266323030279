<template>
	<Modal :id="id" :title="'Send Money'" :toggle="closeModal">
		<div class="text-center my-4 pb-4">
			<h4>Confirmation</h4>
			<p>
				You're about to send USD
				{{ formatNumber(finalPayload.amount) }} to
				{{ finalPayload.receiverFirstName }}
				{{ finalPayload.receiverLastName }} <br />Do you wish to
				continue?
			</p>
		</div>
		<div class="flex__between">
			<button
				type="button"
				class="btn btn-outline w-100 mr-3"
				@click="handleClose"
			>
				<span>Cancel</span>
			</button>
			<button
				type="submit"
				class="btn btn-lg btn-primary w-100 ml-3"
				:disabled="loading"
				@click="handleSubmit(true)"
			>
				<span>Save Process</span>
			</button>
			<button
				type="submit"
				class="btn btn-lg btn-primary w-100 ml-3"
				:disabled="loading"
				@click="handleSubmit(false)"
			>
				<Spinner v-if="loading" />
				<span v-else>Continue</span>
			</button>
		</div>
	</Modal>
</template>

<script>
import Nprogress from "nprogress";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import Spinner from "../../../../../utils/Spinner/Spinner.vue";
import { getWalletApikey, getError, formatNumber } from "@/utils/helpers";

export default {
	mixins: [notifications],
	name: "ConfirmPaymentModal",
	data() {
		return { loading: false, finalPayload: null };
	},
	props: ["id", "closeModal"],
	components: { Modal, Spinner },

	methods: {
		handleClose() {
			this.closeModal();
		},
		formatNumber,
		async handleSubmit(isSaved) {
			console.log({ isSaved });
			this.loading = true;
			const payload = {
				...this.finalPayload,
				saveProcess: isSaved ? true : false,
				currency: "USD",
				purposeCode: "223",
				amount: parseInt(this.finalPayload.amount),
				senderCountry: this.finalPayload.senderCountry.name
					? this.finalPayload.senderCountry.name
					: this.finalPayload.senderCountry,
				senderCountryCode: this.finalPayload.senderCountryCode.code3,
				senderCountryCurrency:
					this.finalPayload.senderCountryCurrency.code,
				senderState: this.finalPayload.senderState.state_label
					? this.finalPayload.senderState.state_label
					: this.finalPayload.senderState,
				receiverCountry: this.finalPayload.receiverCountry.name
					? this.finalPayload.receiverCountry.name
					: this.finalPayload.receiverCountry,
				receiverCountryCode:
					this.finalPayload.receiverCountryCode.code3,
				receiverCountryCurrency:
					this.finalPayload.receiverCountryCurrency.code,
				receiverState: this.finalPayload.receiverState.state_label
					? this.finalPayload.receiverState.state_label
					: this.finalPayload.receiverState,
				// receiverBankCode: this.finalPayload.receiverBankCode.code,
				providerReference:
					this.finalPayload.receiverBankCode.providerReference,
				senderMaidenName: this.finalPayload.senderMaidenName
					? this.finalPayload.senderMaidenName
					: " ",
				senderGender: this.finalPayload.senderGender
					? this.finalPayload.senderGender
					: " ",
				senderIdExpiryDate: this.finalPayload.senderIdExpiryDate
					? this.finalPayload.senderIdExpiryDate
					: " ",
				senderIdIssueDate: this.finalPayload.senderIdIssueDate
					? this.finalPayload.senderIdIssueDate
					: " ",
				senderIdNumber: this.finalPayload.senderIdNumber
					? this.finalPayload.senderIdNumber
					: " ",
				senderIdType: this.finalPayload.senderIdType
					? this.finalPayload.senderIdType
					: " ",
				senderImage: this.finalPayload.senderImage
					? this.finalPayload.senderImage
					: " ",
				senderOccupation: this.finalPayload.senderOccupation
					? this.finalPayload.senderOccupation
					: " ",
				senderMiddleName: this.finalPayload.senderMiddleName
					? this.finalPayload.senderMiddleName
					: " ",
				senderNationality: this.finalPayload.senderNationality
					? this.finalPayload.senderNationality
					: " ",
				senderPhoneCode: this.finalPayload.senderPhoneCode
					? this.finalPayload.senderPhoneCode
					: " ",
				receiverPhoneCode: this.finalPayload.receiverPhoneCode
					? this.finalPayload.receiverPhoneCode
					: " ",
				receiverBankCode: this.finalPayload.receiverBankCode
					? this.finalPayload.receiverBankCode
					: " ",
				receiverBirthDate: this.finalPayload.receiverBirthDate
					? this.finalPayload.receiverBirthDate
					: " ",
				receiverMiddleName: this.finalPayload.receiverMiddleName
					? this.finalPayload.receiverMiddleName
					: " ",
				receiverPostCode: this.finalPayload.receiverPostCode
					? this.finalPayload.receiverPostCode
					: " ",
				secretAnswer: this.finalPayload.secretAnswer
					? this.finalPayload.secretAnswer
					: " ",
				secretQuestion: this.finalPayload.secretQuestion
					? this.finalPayload.secretQuestion
					: " ",
			};
			delete payload.receiverBankCode;
			!isSaved && delete payload.saveProcess;

			try {
				let response = await this.$store.dispatch(
					"remittance/createPayment",
					{
						payload,
						apiKey: getWalletApikey(this.$store),
					}
				);
				this.showSuccessNotification(response.data.message);
				this.handleClose();
				this.loading = false;
				Nprogress.done();
				setTimeout(() => {
					this.$router.push(`/wallet/remittance`);
				}, 1000);
			} catch (error) {
				Nprogress.done();
				this.loading = false;
				this.showErrorLoginNotification(getError(error.response));
			}
		},
	},
	computed: {
		metaData() {
			return this.$store.state.metaData;
		},
	},
	mounted() {
		this.states = this.metaData.countries[0].states;
	},
	created() {
		this.finalPayload = JSON.parse(
			window.localStorage.getItem("__send_money")
		);
	},
};
</script>
