var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade pt-4",attrs:{"id":"notification_settings","role":"tabpanel","aria-labelledby":"notification_settings-tab"}},[_c('ul',{staticClass:"p-0"},[_c('li',{staticClass:"flex__between"},[_c('h5',[_vm._v("Would you like to use OTP on wallet creation?")]),_c('toggle-button',{attrs:{"color":"#006848","value":_vm.request.wallet_creation_otp,"sync":true,"labels":{
						checked: 'On',
						unchecked: 'Off',
					}},model:{value:(_vm.request.wallet_creation_otp),callback:function ($$v) {_vm.$set(_vm.request, "wallet_creation_otp", $$v)},expression:"request.wallet_creation_otp"}})],1),_c('li',{staticClass:"flex__between"},[_c('h5',[_vm._v("Would you like to use OTP for Transfers?")]),_c('toggle-button',{attrs:{"color":"#006848","value":_vm.request.transfer_otp,"labels":{
						checked: 'On',
						unchecked: 'Off',
					},"sync":true},model:{value:(_vm.request.transfer_otp),callback:function ($$v) {_vm.$set(_vm.request, "transfer_otp", $$v)},expression:"request.transfer_otp"}})],1),_c('li',{staticClass:"flex__between"},[_c('h5',[_vm._v(" Would you like to turn on/off the option to receive email notifications for Transactions? ")]),_c('toggle-button',{attrs:{"color":"#006848","value":_vm.request.email_integration,"sync":true,"labels":{
						checked: 'On',
						unchecked: 'Off',
					}},model:{value:(_vm.request.email_integration),callback:function ($$v) {_vm.$set(_vm.request, "email_integration", $$v)},expression:"request.email_integration"}})],1),_c('li',{staticClass:"flex__between"},[_c('h5',[_vm._v(" Would you like to receive receipts? ")]),_c('toggle-button',{attrs:{"color":"#006848","value":_vm.request.send_receipt_to_merchant,"sync":true,"labels":{
						checked: 'On',
						unchecked: 'Off',
					}},model:{value:(_vm.request.send_receipt_to_merchant),callback:function ($$v) {_vm.$set(_vm.request, "send_receipt_to_merchant", $$v)},expression:"request.send_receipt_to_merchant"}})],1),_c('li',{staticClass:"flex__between"},[_c('h5',[_vm._v(" Would you like for customers to receive receipts? ")]),_c('toggle-button',{attrs:{"color":"#006848","value":_vm.request.send_receipt_to_customer,"sync":true,"labels":{
						checked: 'On',
						unchecked: 'Off',
					}},model:{value:(_vm.request.send_receipt_to_customer),callback:function ($$v) {_vm.$set(_vm.request, "send_receipt_to_customer", $$v)},expression:"request.send_receipt_to_customer"}})],1),_c('button',{staticClass:"btn btn-lg btn-primary mt-4 float-right px-4",attrs:{"disabled":_vm.loading},on:{"click":_vm.handleSubmit}},[(_vm.loading)?_c('Spinner'):_c('span',[_vm._v("Save Changes")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }