<template>
  <div class="row">
    <div class="col-12">
      <!-- Filter bar -->
      <form @submit.prevent="sendFilter">
        <div class="row justify-content-start py-4">
          <h5 class="px-2 py-3"><strong>Filter :</strong></h5>
          <div class="col-2">
            <v-select
              class="v-select px-2"
              placeholder="Select Date"
              @keydown.enter.native.prevent
              :components="{ Deselect }"
              :options="dateOptions"
              v-model="dateRange"
              label="Select Date"
            ></v-select>
          </div>
          <div class="col-2" v-if="dateRange === 'Custom'">
            <date-picker
              :date="startTime"
              :option="option"
              :limit="limit"
            ></date-picker>
          </div>
          <div class="col-2" v-if="dateRange === 'Custom'">
            <date-picker
              :date="endTime"
              :option="option2"
              :limit="limit"
            ></date-picker>
          </div>

          <div class="col-3">
            <div class="form-group">
              <v-select
                class="v-select px-2"
                placeholder="Activity Type"
                :components="{ Deselect }"
                @keydown.enter.native.prevent
                :options="activityTypes"
                v-model="activityType"
                label="Activity Type"
              ></v-select>
            </div>
          </div>
          <div class="col-2">
            <button
              class="btn btn-lg btn-block themed-button mg-b-20 pt-2 pb-3"
              type="submit"
            >
              <span>Search</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import myDatepicker from "vue-datepicker";
export default {
  props: ["activityTypes"],
  components: {
    "date-picker": myDatepicker,
  },
  data() {
    return {
      Deselect: {
        render: (createElement) => createElement("span"),
      },
      dateOptions: ["none", "Today", "This week", "This month", "Custom"],
      activityType: "",
      dateRange: "",
      startTime: {
        time: "",
      },
      endTime: {
        time: "",
      },
      option: {
        type: "day",
        week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        format: "YYYY-MM-DD",
        placeholder: "Start Date",
        inputStyle: {
          display: "inline-block",
          padding: "6px",
          "line-height": "32px",
          "font-size": "16px",
          border: "2px solid #fff",
          "box-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
          "border-radius": "2px",
          color: "#5F5F5F",
        },
        color: {
          header: "#ccc",
          headerText: "#f00",
        },
        buttons: {
          ok: "Ok",
          cancel: "Cancel",
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true, // as true as default
      },
      option2: {
        type: "day",
        week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        format: "YYYY-MM-DD",
        placeholder: "End Date",
        inputStyle: {
          display: "inline-block",
          padding: "6px",
          "line-height": "32px",
          "font-size": "16px",
          border: "2px solid #fff",
          "box-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
          "border-radius": "2px",
          color: "#5F5F5F",
        },
        color: {
          header: "#ccc",
          headerText: "#f00",
        },
        buttons: {
          ok: "Ok",
          cancel: "Cancel",
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true, // as true as default
      },
      limit: [
        {
          type: "weekday",
          available: [1, 2, 3, 4, 5],
        },
      ],
    };
  },
  methods: {
    sendFilter() {
      this.$emit("clicked", {
        dateRange: this.dateRange,
        activityType: this.activityType,
        startTime: this.startTime.time,
        endTime: this.endTime.time,
      });
    },
  },
};
</script>