import plans from "@/services/wallets/subscriptions/plans";

export default {
	namespaced: true,
	actions: {
		async getAllPlans({}, payload) {
			return await plans.getAllPlans(payload);
		},
		async getPlan({}, payload) {
			return await plans.getPlan(payload);
		},
		async createPlan({}, payload) {
			return await plans.createPlan(payload);
		},
		async updatePlan({}, payload) {
			return await plans.updatePlan(payload);
		},
		async deletePlan({}, payload) {
			return await plans.deletePlan(payload);
		},
	},
};
