<template>
  <div
    class="w-bg"
    :class="{'page-ath': !whitelabel}"
    :style="[WLsettings.background_image_url ? `'backgroundImage: url(/assets/img/${WLsettings.background_image_url})'` : `'backgroundImage: url(/assets/img/${this.imageUrl})'` ]"
  >
    <div class="row justify-content-center align-items-center" style="height: 100vh; width:100%;">
      <transition mode="out-in" name="slide">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";

export default {
  props: ['whitelabel'],
  data() {
    return {
      imageUrl: 'app-background.svg',
      isLoading: true,
    }
  },
  computed: {
    WLsettings() {
      return this.$store.state.whitelabelling.whitelabelledSettings
    }
  },

}
</script>

<style>
</style>
