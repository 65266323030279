<template>
    <Modal
        :id="id"
        :title="'Update Customer Address'"
        :toggle="handleCloseModal"
        :loading="loading"
    >
        <SuccessState
            v-if="successMsg"
            :message="'Customer Address Updated Successfully'"
            :onSuccess="handleRefetch"
        />

        <form v-else @submit.prevent="handleSubmit" class="row">
            <div class="col-12">
                <CustomInput
                    :name="'address_one'"
                    v-model="request.address_one"
                    :label="'Address'"
                    :placeholder="'Enter address'"
                />
            </div>

            <div class="col-sm-6">
                <CustomSelect
                    name="country"
                    :optionLabel="'name'"
                    label="Country"
                    placeholder="Select Country"
                    :options="countries"
                    v-model="request.country"
                />
            </div>

            <div class="col-sm-6">
                <CustomSelect
                    name="state"
                    :optionLabel="'state_label'"
                    label="State"
                    placeholder="Select State"
                    :options="states"
                    v-model="request.state"
                />
            </div>
            <div class="col-12">
                <FormButtons
                    :loading="loading"
                    :handleCloseModal="handleCloseModal"
                />
            </div>
        </form>
    </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../public/assets/mixins/filters";

const defaultValue = () => ({
    request: {
        address_one: "",
        country: "",
        state: "",
    },
    states: [],
    formErrors: {},
    successMsg: false,
});

export default {
    mixins: [filters, notifications],
    name: "AddCustomerModal",
    data() {
        return {
            ...defaultValue(),
            loading: false,
        };
    },
    props: ["id", "customer", "closeModal", "updateAddress"],

    computed: {
        countries() {
            return this.$store.state.metaData.countries;
        },
        address() {
            const { country, state, address_one } = this.request;
            return `${address_one || ""} ${
                this.check(state) ? state : state.state_label || ""
            } ${this.check(country) ? country : country.name || ""}`;
        },
    },
    methods: {
        check(val) {
            return typeof val == "string";
        },
        handleCloseModal() {
            Object.assign(this.$data, { ...defaultValue() });
            this.closeModal();
        },
        handleRefetch() {
            this.updateAddress && this.updateAddress(this.address);
            this.handleCloseModal();
        },
        handleSubmit() {
            const { formErrors, isValid } = this.formValidator(this.request);
            if (isValid) this.updateCustomer();
            else this.formErrors = formErrors;
        },

        async updateCustomer() {
            try {
                this.loading = true;
                const newRequest = {
                    ...this.request,
                    state: this.check(this.request.state)
                        ? this.request.state
                        : this.request.state.state_label,
                    country: this.check(this.request.country)
                        ? this.request.country
                        : this.request.country.name,
                };
                const response = await this.$store.dispatch(
                    "customers/updateCustomer",
                    {
                        apiKey: this.getWalletApikey(this.$store),
                        request: newRequest,
                        reference: this.customer.reference,
                    }
                );
                const { status, data } = response || {};

                if (status === 200 && data.status) {
                    this.loading = false;
                    this.successMsg = true;
                } else this.showError(data.message);
            } catch (error) {
                this.showError(error.message);
            }
        },
        showError(message) {
            this.loading = false;
            this.showErrorLoginNotification(message);
        },
    },
    mounted() {
        const { address_one, state, country } = this.customer || {};
        this.request = { address_one, state, country };
    },
    watch: {
        "request.country": function(newVal) {
            this.states = newVal.states;
        },
    },
};
</script>
