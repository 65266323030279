<template>
	<div>
		<section>
			<p>Email</p>
			<span>{{ customer.email || "None" }} </span>
		</section>

		<section>
			<p>Customer ID</p>
			<span>{{ customer.reference || "None" }} </span>
		</section>

		<section>
			<p>Phone Number</p>
			<span>{{ getPhone(customer.phone) || "None" }} </span>
		</section>

		<section>
			<p>Customer Type</p>
			<span>{{ customer.business ? "Business" : "Individual" }} </span>
		</section>
	</div>
</template>

<script>
	import { getPhone } from "../../../../../../utils/helpers";

	export default {
		name: "CustomerPersonalInfo",
		props: ["customer"],
		methods: {
			getPhone,
		},
	};
</script>
