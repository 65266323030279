<template>
	<div>
		<CustomBuiltSelect
			:label="'Coupon'"
			:btnName="'Coupon'"
			:placeholder="'Select Coupon'"
			:value="value"
			:error="error"
			:loading="loading"
			:selected="selected"
			:getLabel="getLabel"
			:required="required"
			:options="filteredCoupon"
			:handleChange="handleChange"
			:handleSelect="handleSelect"
			:createFooter="openAddCouponModal"
		/>

		<CreateCouponModal
			:id="'addCoupon'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";
import CreateCouponModal from "@/components/wallet/Subscriptions/modals/CreateCouponModal/CreateCouponModal";
import CustomBuiltSelect from "../CustomBuiltSelect/CustomBuiltSelect";
import { titleCase } from "@/utils/helpers";

export default {
	name: "CustomerSelect",
	data() {
		return {
			filteredCoupon: [],
			coupons: [],
			selected: null,
			value: "",
			loading: false,
		};
	},
	components: {
		CustomBuiltSelect,
		CreateCouponModal,
	},
	props: ["required", "error", "action", "coupon", "setRequest"],
	methods: {
		async refetch(coupon) {
			this.action(coupon);
			this.getCoupons();
		},
		handleChange({ target: { value } }) {
			if (value) {
				this.value = value;
				let data = [...this.coupons].filter((coupon) =>
					coupon.couponCode
						.toLowerCase()
						.includes(value.toLowerCase())
				);
				this.filteredCoupon = data;
			} else {
				this.value = "";
				this.filteredCoupon = this.coupons;
			}
		},
		getLabel(coupon) {
			const { couponCode, discountType } = coupon || {};
			return couponCode
				? `${couponCode} ( ${titleCase(discountType)} )`
				: "";
		},
		handleSelect(option) {
			this.selected = option;
			this.value = this.getLabel(option);
			this.setRequest(option);
		},
		openAddCouponModal() {
			$("#addCoupon").modal("show");
		},
		closeModal() {
			$("#addCoupon").modal("hide");
		},

		async getCoupons() {
			this.loading = true;
			try {
				const response = await this.$store.dispatch(
					"coupons/getAllCoupons",
					{
						apiKey: getWalletApikey(this.$store),
						params: { limit: 100000000000000 },
					}
				);
				this.coupons = response.data.data.data;
				this.filteredCoupon = response.data.data.data;
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.coupons = [];
			}
		},
		setCoupon(v) {
			this.selected = v;
			this.value = this.getLabel(v);
		},
	},
	mounted() {
		this.setCoupon(this.coupon);
		this.getCoupons();
	},
	watch: {
		coupon(option) {
			this.setCoupon(option);
		},
	},
};
</script>
