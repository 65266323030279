<template>
	<div v-if="isDev" :class="cls">
		<slot />
	</div>
</template>

<script>
export default {
	name: "DevOnly",
	props: ["cls"],
	computed: {
		isDev() {
			if (process.env.NODE_ENV === "development") return true;
			else return false;
		},
	},
};
</script>
