import coupons from "@/services/wallets/subscriptions/coupons";

export default {
	namespaced: true,
	actions: {
		async getAllCoupons({}, payload) {
			return await coupons.getAllCoupons(payload);
		},
		async getCoupon({}, payload) {
			return await coupons.getCoupon(payload);
		},
		async createCoupon({}, payload) {
			return await coupons.createCoupon(payload);
		},
		async updateCoupon({}, payload) {
			return await coupons.updateCoupon(payload);
		},
		async deleteCoupon({}, payload) {
			return await coupons.deleteCoupon(payload);
		},
	},
};
