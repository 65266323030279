<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">
                  Kenya International Passport Request form
                </h2>
                <h5 class="user-profile-email sub">
                  Verify International Passport of your users.
                </h5>
              </div>
            </div>
            <div class="content">
              <PricesAndAccess
                :serviceLabel="'Kenya Passport ID'"
                :getServiceStatus="getServiceStatus"
              />
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyKIP">
              <div class="row mt-4">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="input-item-label"
                      >International Passport
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="identity_number"
                      @blur="$v.identity_number.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter international passport number"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.identity_number.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.identity_number) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <CustomPhoneInput
                    defaultCountry="KE"
                    :onlyCountries="['KE']"
                    label="Phone Number"
                    v-model="phonenumber"
                    @blur="$v.phonenumber.$touch()"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phonenumber.$error && formVuelidator($v.phonenumber)
                    "
                  />
                </div>

                <div class="col-12 col-md-6">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <div class="col-12 col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Verifying</span>
                    <span v-else>Verify</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Kenya International Passport Response</h1>
              <span class="text-span"
                >Preview of the information gotten from Kenya International
                Passport request made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content">
            <div class="invoice-info-row preview-row">
              <div class="passport-image">
                <img
                  width="200px"
                  height="200px"
                  :src="verificationResult.data.image"
                  :alt="`image for ${verificationResult.data.fullName}`"
                />
              </div>
            </div>
            <p class="invoice-info-row preview-row">
              <span>Full Name</span>
              <span>{{
                verificationResult.data.fullName || "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Dob</span>
              <span>{{
                verificationResult.data.dateOfBirth || "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>International Passport Number</span>
              <span>{{
                verificationResult.data.idNumber || "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Gender</span>
              <span>{{
                !verificationResult.data.gender
                  ? "Not Available"
                  : verificationResult.data.gender == "m"
                  ? "Male"
                  : "Female"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Nationality</span>
              <span>{{
                verificationResult.data.nationality || "Not Available"
              }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>

        <!-- End of transition -->
      </transition>
      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}/IdentityVerification/KenyaPassportID`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "identity_number": "{{identity_number ? identity_number : "identity_number"}}",
        "phone_number": "{{phonenumber.formatted ? phonenumber.formatted.replace(/\s/g, "") : "phonenumber"}}",
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
    "status_code": 200,
    "error": false,
    "message": "User Info Retrieved",
    "data": {
    "id": "648ca6fc3618b1e0151a2233",
    "parentId": null,
    "status": "found",
    "reason": null,
    "dataValidation": false,
    "selfieValidation": false,
    "firstName": "JOHN",
    "middleName": "MUTHUKUI",
    "lastName": "DOE",
    "fullName": "JOHN MUTHUKUI DOE",
    "image": "base64_image"
    "nationality": "KENYAN",
    "dateOfBirth": "1975-12-31",
    "isConsent": true,
    "idNumber": "A1111500",
    "businessId": "6222a5ea3e7a41c29c030fb0",
    "type": "kePassport",
    "allValidationPassed": true,
    "gender": "m",
    "country": "KE",
    "lastModifiedAt": "2023-06-16T18:16:29.210Z",
    "metadata": {
        "requestId": 1686939388720
    }
}
}`}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import PricesAndAccess from "../../utils/PricesAndAccess/PricesAndAccess";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    phonenumber: {
      isPhoneValid: isPhoneValid("phonenumber"),
    },
    identity_number: { required },
    app: { required },
  },
  data() {
    return {
      initializedAppResult: {},
      app: "",
      clientID: "",
      apikey: "",
      appname: "",
      hasNewResponse: false,
      country_prefix: "",
      logData: [],
      apiResponse: "",
      includepic: false,
      identity_number: "",
      phonenumber: "",
      // services
      hasService: false,
      servicePrice: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      isSubmitting: false,
      verificationResult: [],
    };
  },
  components: {
    PricesAndAccess,
    CustomPhoneInput,
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  methods: {
    formVuelidator,
    getServiceStatus(hasService) {
      this.hasService = hasService;
    },

    getRequestData() {
      let verificationData = {
        headers: {
          clientID: this.clientID,
          appname: this.app.app_name,
          apikey: this.app.api_key,
        },
        payload: {
          identity_number: this.identity_number,
          phone_number: this.phonenumber.formatted.replace(/\s/g, ""),
          currency_type: this.currencyType.currency,
        },
      };

      return verificationData;
    },
    async verifyKIP() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        let apiResponse = await this.$store.dispatch(
          "services/verifyKenyaInternationalPassport",
          verificationData
        );
        this.apiResponse = apiResponse;
        this.verificationResult = apiResponse;

        this.isSubmitting = false;

        if (apiResponse.data.status_code == 400) {
          this.showErrorLoginNotification("Error validating passport");
          return;
        } else {
          this.hasNewResponse = true;
          this.showSuccessNotification(apiResponse.message);
        }
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
