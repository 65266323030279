
import axios from "axios";

let walletsBaseUrl = process.env.VUE_APP_WALLETS_BASE_URL;

export default {
  sendAccStatement({ apiKey, payload }) {
    return axios.post(`${walletsBaseUrl}/customers/account/statement`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getAllCustomers({ apiKey, params }) {
    return axios.get(`${walletsBaseUrl}/customers`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getCustomer({ apiKey, reference }) {
    return axios.get(`${walletsBaseUrl}/customers/${reference}`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  createCustomer({ apiKey, request }) {
    return axios.post(`${walletsBaseUrl}/customers`, request, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  updateCustomer({ apiKey, request, reference }) {
    return axios.put(`${walletsBaseUrl}/customers/${reference}`, request, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getCustomerCard({ apiKey, reference }) {
    return axios.get(`${walletsBaseUrl}/tokenized-card`, {
      params: {
        customerRef: reference,
      },
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
};
