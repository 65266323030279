<template>
	<AccessControl :page="true">
		<EmptyWallet v-if="!merchant" />

		<div v-else class="p-4">
			<div class="SectionItem no-b-b mb-3 flex__between">
				<p class="title">Preferences</p>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="getWalletSettings"
			/>

			<template v-else>
				<div class="content__slider">
					<TabsV2 :tabNames="tabNames" :tabId="'preferenceTab'" />
				</div>

				<div class="tab-content" id="myTabContent">
					<APIKeySettings :keyValues="keyValues" />
					<NotificationSettings :settings="settings" />
					<WalletSettings
						:settings="settings"
						:merchantBanks="merchantBanks"
						:settlementWallets="settlementWallets"
						:logo="keyValues.logo"
					/>
					<BankSettings
						:bankAccounts="merchantBanks"
						:refetch="getMerchantBanks"
					/>
					<PriceSettings />
					<Payments />
				</div>
			</template>
		</div>
	</AccessControl>
</template>

<script>
import TabsV2 from "@/utils/Tabs/TabsV2";
import AccessControl from "@/utils/AccessControl/AccessControl";
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import APIKeySettings from "./APIKeySettings/APIKeySettings";
import WalletSettings from "./WalletSettings/WalletSettings";
import Payments from "./Payments/Payments";
import BankSettings from "./BankSettings/BankSettings";
import PriceSettings from "./PriceSettings/PriceSettings";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import Spinner from "@/utils/Spinner/Spinner";
import FailedState from "@/utils/ResponseState/FailedState";
import { getWalletApikey } from "@/utils/helpers";

const tabNames = [
	{
		tabName: "API Keys and Webhooks",
		value: "api_keys_and_webhooks",
	},
	{
		tabName: "Notification Settings",
		value: "notification_settings",
	},
	{
		tabName: "Settlement Accounts",
		value: "settlement_accounts",
	},
	{
		tabName: "Payment Methods",
		value: "payment_methods",
	},
	{
		tabName: "Settings",
		value: "settings",
	},
	{
		tabName: "Pricing",
		value: "pricing",
	},
];

export default {
	name: "Preferences",
	data() {
		return {
			tabNames,
			loading: false,
			errorMsg: "",
			settings: {},
			merchantBanks: [],
			settlementWallets: [],
		};
	},
	components: {
		TabsV2,
		APIKeySettings,
		WalletSettings,
		BankSettings,
		NotificationSettings,
		PriceSettings,
		FailedState,
		EmptyWallet,
		Spinner,
		AccessControl,
		Payments,
	},
	methods: {
		async getWalletSettings() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getMerchantWalletSettings",
					{ apiKey: getWalletApikey(this.$store) }
				);

				if (response.status === 200) {
					this.loading = false;
					this.settings = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
		async getMerchantBanks() {
			try {
				const response = await this.$store.dispatch(
					"wallets/getMerchantBanks",
					{ apiKey: getWalletApikey(this.$store) }
				);

				const { status, data } = response || {};
				if (status === 201 || status === 200) {
					this.merchantBanks = data.data;
				}
			} catch (error) {
				this.merchantBanks = [];
			}
		},
		async getMasterWallet() {
			try {
				const response = (
					await this.$store.dispatch("wallets/getMasterWallet", {
						apiKey: getWalletApikey(this.$store),
						params: { currency: "USD" },
					})
				).data;
				this.settlementWallets = response;
			} catch (error) {
				this.settlementWallets = [];
			}
		},
	},
	computed: {
		keyValues() {
			return this.$store.state.account.customerKeys;
		},
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	mounted() {
		this.getMasterWallet();
		this.getWalletSettings();
		this.getMerchantBanks();
	},
};
</script>
