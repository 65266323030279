import { apiClient, baseAPI } from "./api";

export default {
	customerRegistration(customerDetails) {
		return apiClient.post(`Authorize/RegisterCustomer`, customerDetails);
	},
	inviteUserRegistration(customerDetails) {
		return apiClient.post(`Authorize/RegisterInviteUser`, customerDetails);
	},
	getInviteEmail(token) {
		return apiClient.get(`Authorize/GetInviteEmail/${token}`);
	},
	externalRegistration(customerDetails) {
		return apiClient.post(
			`Authorize/ExternalRegistration`,
			customerDetails
		);
	},
	authenticateUser(userDetail) {
		return apiClient.post(`Authorize/Login`, userDetail);
	},
	authenticateUserOtp(userDetail) {
		return apiClient.post(`Authorize/LoginOtp`, userDetail);
	},
	whitelabelCustomerRegistration(customerDetails) {
		return apiClient.post(`Authorize/SecondaryCustomer`, customerDetails);
	},
	SendPasswordResetLink(email) {
		return apiClient.post(`Authorize/SendPasswordResetLink`, email);
	},
	ActivateAccount(token) {
		return apiClient.get(`Authorize/ActivateAccount/${token}`);
	},
	ForgotPassword(userDetails) {
		return apiClient.post(`Authorize/ForgotPassword`, userDetails);
	},
	AdminRegisterCustomer(userDetails) {
		return baseAPI.post(`api/Normal/Customer/registerInvites`, userDetails);
	},
	customerEmailVerification(payload) {
		return apiClient.post(
			`Authorize/Onboarding/MerchantApp/verify-email`,
			payload,
			{
				headers: {
					requestsource: "merchant_app",
					requestkey: process.env.VUE_APP_REQUEST_KEY,
				},
			}
		);
	},
	getCustomerDetails(userDetails) {
		return baseAPI.get(`api/Normal/Customer/getCustomerInfo`, {
			params: {
				...userDetails,
			},
		});
	},
};
