<template>
  <form>
    <div><h3 class="title mb-3">Reciever Information</h3></div>
    <div>
      <div class="row">
        <div class="col-sm-4 mb-2">
            <label> Select Receiver </label>
            <ReceiverSelect @populateForm="populateForm" />
          </div>
        <div class="col-sm-4 mb-2">
          <CustomInput
            :otherCls="'mb-0'"
            :name="'receiverFirstName'"
            :label="'Reciever`s First Name'"
            :placeholder="'First Name'"
            :required="true"
            v-model="senderForm.receiverFirstName"
          />
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverFirstName.$error"
          >
            <li class="parsley-required">First Name is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <CustomInput
            :otherCls="'mb-0'"
            :name="'receiverLastName'"
            :label="'Reciever`s Last Name'"
            :placeholder="'Last Name'"
            :required="true"
            v-model="senderForm.receiverLastName"
          />
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverLastName.$error"
          >
            <li class="parsley-required">Last Name is required.</li>
          </ul>
        </div>

        <div class="col-sm-4 mb-2">
          <CustomInput
            :otherCls="'mb-0'"
            :name="'receiverEmail'"
            :label="'Reciever`s Email'"
            :placeholder="'Email'"
            v-model="senderForm.receiverEmail"
          />
        </div>
        <div class="col-sm-4 mb-2">
          <label class="">
            Gender
            <span class="text-danger" title="This field is required">*</span>
          </label>
          <div class="d-flex">
            <label class="rdiobox mr-4">
              <input
                name="receiverGender"
                type="radio"
                :checked="true"
                value="Male"
                v-model="senderForm.receiverGender"
              />
              <span class="fs-15">Male</span>
            </label>
            <label class="rdiobox">
              <input
                name="receiverGender"
                type="radio"
                value="Female"
                v-model="senderForm.receiverGender"
              />
              <span class="fs-15"> Female </span>
            </label>
          </div>
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverGender.$error"
          >
            <li class="parsley-required">Gender is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <CustomInput
            :otherCls="'mb-0'"
            :name="'receiverPhoneNumber'"
            :maxlength="11"
            :placeholder="'Phone Number'"
            :label="'Enter Phone Number'"
            :required="true"
            :isNum="true"
            v-model="senderForm.receiverPhoneNumber"
          />
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverPhoneNumber.$error"
          >
            <li class="parsley-required">Phone Number is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <CustomInput
            :otherCls="'mb-0'"
            :name="'receiverAddress'"
            :placeholder="'Address'"
            :label="'Address'"
            :required="true"
            v-model="senderForm.receiverAddress"
          />
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverAddress.$error"
          >
            <li class="parsley-required">Address is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <div class="form-group">
            <label class="input-item-label">Country Currency</label>
            <v-select
              class="v-select"
              placeholder="Select a country"
              :options="countryCurrency"
              label="code"
              @keydown.enter.native.prevent
              name="receiverCountryCurrency"
              v-model="senderForm.receiverCountryCurrency"
            ></v-select>
          </div>
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverCountryCurrency.$error"
          >
            <li class="parsley-required">Country Currency is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <div class="form-group">
            <label class="input-item-label">Country Short Code</label>
            <v-select
              class="v-select"
              placeholder="Select a country"
              :options="countrySortCode"
              label="code3"
              @keydown.enter.native.prevent
              name="receiverCountryCode"
              v-model="senderForm.receiverCountryCode"
            ></v-select>
          </div>
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverCountryCode.$error"
          >
            <li class="parsley-required">Country short Code is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <div class="form-group">
            <label class="input-item-label">Country</label>
            <v-select
              class="v-select"
              placeholder="Select a country"
              :options="metaData.countries"
              label="name"
              @input="changeState"
              @keydown.enter.native.prevent
              name="receiverCountry"
              v-model="senderForm.receiverCountry"
            ></v-select>
          </div>
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverCountry.$error"
          >
            <li class="parsley-required">Country is required.</li>
          </ul>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label class="input-item-label">State</label>
            <v-select
              class="v-select"
              placeholder="Select a state"
              v-model="senderForm.receiverState"
              @keydown.enter.native.prevent
              :disabled="!senderForm.receiverCountry"
              :options="states"
              label="state_label"
            ></v-select>
          </div>
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverState.$error"
          >
            <li class="parsley-required">State is required.</li>
          </ul>
        </div>
        <div class="col-sm-4 mb-2">
          <CustomInput
            :otherCls="'mb-0'"
            :name="'receiverCity'"
            :maxlength="11"
            :placeholder="'City'"
            :label="'City'"
            :required="true"
            v-model="senderForm.receiverCity"
          />
          <ul
            class="parsley-errors-list filled"
            v-show="$v.senderForm.receiverCity.$error"
          >
            <li class="parsley-required">City is required.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex__between col-12 col-sm-2 mt-4">
      <input
        type="button"
        value="Prev"
        class="btn btn-lg btn-primary mr-4"
        :disabled="loading"
        @click="$emit('handlePrev')"
      />
      <input
        type="button"
        value="Next"
        class="btn btn-lg btn-primary"
        :disabled="loading"
        @click="handleSubmit"
      />
    </div>
  </form>
</template>

<script>
import Spinner from "../../../../utils/Spinner/Spinner.vue";
import FailedState from "../../../../utils/ResponseState/FailedState";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { required } from "vuelidate/lib/validators";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import ReceiverSelect from "@/utils/Selects/ReceiverSelect/ReceiverSelect";
import {
  newCountryFormat,
  countryCurrency,
  countrySortCode,
} from "@/assets/js/countryDailcodes";

export default {
  mixins: [notifications],
  name: "RecieverForm",
  data() {
    return {
      senderForm: {},
      loading: false,
      newCountryFormat,
      countryCurrency,
      countrySortCode,
      states: [],
    };
  },
  validations: {
    states: [],
    senderForm: {
      receiverFirstName: { required },
      receiverLastName: { required },
      receiverPhoneNumber: { required },
      receiverCountryCurrency: { required },
      receiverCountryCode: { required },
      receiverAddress: { required },
      receiverCountry: { required },
      receiverState: { required },
      receiverCity: { required },
      receiverGender: { required },
    },
  },
  components: {
    Spinner,
    FailedState,
    CustomInput,
    CustomSelect,
    DatePicker,
    ReceiverSelect
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
  },
  methods: {
    changeState() {
      this.states = this.senderForm.receiverCountry.states;
    },
    populateForm(data) {
      this.senderForm = {
        ...this.senderForm,
        receiverFirstName: data.firstName,
        receiverLastName: data.lastName,
        receiverEmail: data.email,
        receiverPhoneNumber: data.phoneNumber,
        receiverAddress: data.address,
        receiverCountry: data.country,
        receiverState: data.state,
        receiverCity: data.city,
        receiverGender: data.gender,
        beneficiaryReference: data.beneficiaryReference,
      };
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const senderPayload = JSON.parse(
        window.localStorage.getItem("__send_money")
      );
      window.localStorage.setItem(
        "__send_money",
        JSON.stringify({ ...senderPayload, ...this.senderForm })
      );
      this.$emit("handleNext");
    },
  },
  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
  },
  mounted() {
    this.states = this.metaData.countries[0].states;
  },
};
</script>
