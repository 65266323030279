<template>
  <div
    class="tab-pane active fade show"
    id="Agent_Information"
    role="tabpanel"
    aria-labelledby="Agent_Information-tab"
  >
    <div class="row mt-4" v-if="virtualTerminalData">
      <div class="col-md-12 flex__end mb-4">
        <BlockTerminalButton @updateAgent="getOneAgent" />
      </div>
      <div class="col-md-6 mb-4">
        <ul class="sub_card h-100">
          <h5>Basic Information</h5>
          <li>
            <p>Status</p>
            <p>{{ titleCase(virtualTerminalData.agent.status) }}</p>
          </li>
          <li>
            <p>Agent Reference</p>
            <p>{{ virtualTerminalData.agent.reference }}</p>
          </li>
          <li>
            <p>Agent's Name</p>
            <p>
              {{
                `${virtualTerminalData.agent.firstName} ${virtualTerminalData.agent.lastName}`
              }}
            </p>
          </li>
          <li>
            <p>Agent's Email</p>
            <p>{{ virtualTerminalData.agent.email }}</p>
          </li>
          <li>
            <p>Agent's Phone Number</p>
            <p>{{ virtualTerminalData.agent.phoneNumber }}</p>
          </li>
          <li>
            <p>Agent's Location</p>
            <p>
              <span v-if="virtualTerminalData.agent.address_1"
                >{{ virtualTerminalData.agent.address_1 }},
              </span>
              <span v-if="virtualTerminalData.agent.address_2"
                >{{ virtualTerminalData.agent.address_2 }},
              </span>
              <span v-if="virtualTerminalData.agent.city"
                >{{ virtualTerminalData.agent.city }},
              </span>
              <span v-if="virtualTerminalData.agent.state"
                >{{ virtualTerminalData.agent.state }},
              </span>
              <span v-if="virtualTerminalData.agent.country"
                >{{ virtualTerminalData.agent.country }}.</span
              >
            </p>
          </li>
          <h5 class="mt-4 pt-4">Account Information</h5>
          <li>
            <p>Bank Name</p>
            <p>{{ virtualTerminalData.agent.bank_name }}</p>
          </li>
          <li>
            <p>Account Number</p>
            <p>{{ virtualTerminalData.agent.accountNumber }}</p>
          </li>
          <li>
            <p>Account Name</p>
            <p>{{ virtualTerminalData.agent.accountName }}</p>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">
            {{ toCurrency(virtualTerminalData.statistics.total_value) }}
          </h2>
          <p class="call-name">Total Amount Collected</p>
        </div>
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">
            {{ virtualTerminalData.statistics.total_number || 0 }}
          </h2>
          <p class="call-name">Number of Transactions</p>
        </div>
      </div>
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import {
  titleCase,
  toCurrency,
  statusColor,
  timeDateFormat,
  getWalletApikey,
} from "../../../../../../utils/helpers";
import Spinner from "../../../../../../utils/Spinner/Spinner";
import BlockTerminalButton from "../../../buttons/BlockTerminalButton/BlockTerminalButton.vue";

export default {
  name: "TerminalInformation",
  data() {
    return {
      virtualTerminalData: null,
    };
  },
  components: {
    BlockTerminalButton,
    Spinner,
  },
  methods: {
    titleCase,
    toCurrency,
    statusColor,
    timeDateFormat,
    async getOneAgent() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          id: this.$route.params.id,
        },
      };

      try {
        const resp = await this.$store.dispatch("pos/getOneAgent", payload);

        this.virtualTerminalData = resp.data.data;
      } catch (error) {
        console.log("transctionError", error);
      }
    },
  },
  created() {
    this.getOneAgent();
  },
};
</script>
