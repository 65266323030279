<template>
  <div class="row">
    <div v-if="stats !== null" class="card-body row m-4">
      <div class="col-md-3">
        <p class="call-name">NUmber of Terminals</p>
        <h2 class="call-count">
          {{ stats.terminalCount }}
        </h2>
      </div>
      <div
        class="col-md-3"
        v-for="(terminalInfo, i) in stats.groupedTerminals"
        :key="i"
      >
        <p class="call-name">
          {{ terminalInfo.terminalName }}
        </p>
        <h2 class="call-count">
          {{ terminalInfo.count }}
        </h2>
      </div>
      <!-- <div class="dashboard-brief">
				<p class="call-name" v-if="stats.groupedTerminals.length > 1">
					{{ stats.groupedTerminals[1].terminalName }}
				</p>
				<h2 class="call-count" v-if="stats.groupedTerminals.length > 1">
					{{ stats.groupedTerminals[1].count }}
				</h2>
			</div> -->
    </div>
    <div class="col-12 mt-3">
      <div class="px-3">
        <FilterWrapper>
          <template slot="pageSelect"> </template>
          <template slot="filterForm">
            <POSTableFilters
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="clearFilter"
              :download="downloadPosTransactions"
            />
          </template>
        </FilterWrapper>
      </div>
    </div>
    <div v-if="loading" class="col-12">
      <div class="flex__center my-5 py-5">
        <Spinner :size="'30px'" />
      </div>
    </div>

    <div v-else-if="errorMsg" class="col-12">
      <FailedState :errorMsg="errorMsg" :refetch="refetch" />
    </div>

    <div class="col-12" :class="tbClass">
      <v-server-table
        ref="couponRef"
        class="v-table"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @error="setError"
        @row-click="handlClick"
      >
        <template slot="terminalName" slot-scope="{ row }">
          {{ titleCase(row.terminalName) }}
        </template>
        <template slot="serialNumber" slot-scope="{ row }">
          {{ row.serialNumber.slice(3, row.serialNumber.length) }}
        </template>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import POSTableFilters from "./POSTableFilters";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  formatNumber,
  getWalletApikey,
} from "@/utils/helpers";

const tableHeadings = {
  serialNumber: "Reference",
  deviceOs: "Device OS",
  terminalName: "Terminal Name",
  device: "Device",
};

export default {
  mixins: [componentData],
  name: "PosTermnal",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 20,
      filters: {},
      ...tableOptions(tableHeadings, 20),
      stats: null,
    };
  },
  components: {
    POSTableFilters,
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    formatNumber,
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    handlClick({ row }) {
      this.$router.push(
        `/wallet/terminal/pos/${row.serialNumber.slice(
          3,
          row.serialNumber.length
        )}`
      );
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getPosTransactions();
      this.$refs.couponRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.couponRef.refresh();
    },
    async getPosTransactions() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch("wallets/getPosTerminals", payload)
          ).data;
          const { groupedTerminals, allTerminals, terminalCount } = resp;
          this.stats = { groupedTerminals, terminalCount };
          return {
            ...allTerminals,
            groupedTerminals,
            terminalCount,
          };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
    async getPosStats() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          // ...data,
        },
      };
      try {
        const resp = (
          await this.$store.dispatch("wallets/getPosTerminals", payload)
        ).data;
        const { groupedTerminals, terminalCount } = resp;
        this.stats = { groupedTerminals, terminalCount };
      } catch (error) {
        console.log("transctionError", error);
      }
    },
    async downloadPosTransactions() {
      const download = (filterData) => {
        this.downloadReport(tableHeadings, filterData.allTerminals.data);
      };
      this.downloadTableReport("wallets/getPosTerminals", download);
    },
  },

  created() {
    this.getPosTransactions();
    // this.getPosStats();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>

<style scoped>
.col-md-3 {
  border: 1px solid #d1d3dc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5px;
}
.col-md-3 .call-name {
  font-size: 15px;
}
</style>
