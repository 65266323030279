<template>
	<div>
		<div class="py-4">
			<EmptyWallet v-if="!merchant" />

			<div v-else class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreateSubAccountButton
								:subAcctRef="$refs.subAcctRef"
						/></AccessControl>
					</template>
					<template slot="filterForm">
						<SubAccountFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('subAcctRef')"
							:download="downloadSubAccount"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<CreatePaymentCollectionButton
							:collectionRef="$refs.collectionRef"
						/>
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="subAcctRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@row-click="handleClick"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="props">
							<span>
								{{ timeDateFormat(props.row.createdAt).date }}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import CreateSubAccountButton from "./buttons/CreateSubAccountButton/CreateSubAccountButton.vue";
import SubAccountFilters from "./SubAccountFilters/SubAccountFilters";
import { tableHeadings, subAccountCsvRows } from "./enums";

export default {
	mixins: [componentData],
	name: "SubAccounts",
	data() {
		return {
			emptyTitle: "No Account Found",
			...this.tableOptions(tableHeadings, this.size),
		};
	},
	components: { CreateSubAccountButton, SubAccountFilters },
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(`/wallet/sub-account/${row.reference}`);
		},
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.bank_code)
				newFilters.bank_code = newFilters.bank_code.code;
			this.handleRefetch(newFilters, this.getSubAccounts, "subAcctRef");
		},
		async getSubAccounts() {
			this.handleAPICall("payment/getMerchantSubAccounts");
		},
		async downloadSubAccount() {
			const download = (filterData) =>
				this.downloadReport(subAccountCsvRows, filterData.data.data);
			this.downloadTableReport(
				"payment/getMerchantSubAccounts",
				download
			);
		},
	},
	created() {
		this.getSubAccounts();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
