<template>
	<Modal :id="id" :title="'Create Subscription'" :toggle="handleCloseModal">
		<SuccessState
			v-if="successMsg"
			:message="'Subscription Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="h-100">
			<div class="row">
				<div class="col-12">
					<PlanSelect
						v-show="!plan"
						:name="'planRef'"
						:error="formErrors.planRef"
						:handleError="handleError"
						v-model="request.planRef"
					/>
				</div>

				<div class="form-group col-12">
					<label class="input-item-label">Account Type</label>
					<div class="d-flex">
						<label class="rdiobox mr-4">
							<input
								type="radio"
								value="wallet"
								v-model="request.type"
							/>
							<span class="fs-15">Wallet</span>
						</label>

						<label class="rdiobox">
							<input
								type="radio"
								value="card"
								v-model="request.type"
							/>
							<span class="fs-15">Card</span>
						</label>
					</div>
				</div>

				<div class="col-12">
					<WalletSelect
						v-if="request.type == 'wallet'"
						:name="'walletRef'"
						:error="formErrors.walletRef"
						:handleError="handleError"
						v-model="request.walletRef"
						:required="true"
					/>
				</div>

				<template v-if="request.type == 'card'">
					<div class="col-12">
						<CustomerSelect
							:customer="request.customer"
							:required="true"
							:error="formErrors.customer"
							:setRequest="setRequest"
						/>
					</div>

					<div
						class="col-12 mb-4"
						v-if="request.customer && request.customer.reference"
					>
						<div class="form-group">
							<div v-if="!cardLoading" class="card-container">
								<CustomerCard
									v-for="data in customerCards"
									:key="data.id"
									:data="data"
									:checked="
										request.tokenized_card.id == data.id
									"
									@handleSelect="
										($event) =>
											(request.tokenized_card = $event)
									"
								/>
								<CreateCardForm
									:card_image="card_image"
									:form="request"
									:formErrors="formErrors"
									:handleError="handleError"
									:cvvLabel="cvvLabel"
									:cvvLength="cvvLength"
									@handleSelect="
										($event) =>
											(request.tokenized_card = $event)
									"
								/>
							</div>
							<Spinner v-else />
						</div>
					</div>
				</template>

				<div class="col-12">
					<CouponSelect
						:label="'Coupon Code ( Optional )'"
						:placeholder="'Enter coupon code if any'"
						:coupon="request.couponCode"
						:setRequest="
							(coupon) => setRequest('couponCode', coupon)
						"
						:error="formErrors.couponCode"
					/>
				</div>
			</div>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import PlanSelect from "../../../../../utils/Selects/PlanSelect/PlanSelect";
import WalletSelect from "../../../../../utils/Selects/WalletSelect/WalletSelect";
import DatePicker from "../../../../../utils/DatePicker/DatePicker";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import Modal from "../../../../../utils/Modal/Modal";
import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
import Spinner from "../../../../../utils/Spinner/Spinner";
import FormButtons from "../../../../../utils/FormButtons/FormButtons";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import { getWalletApikey } from "../../../../../utils/helpers";
import CreditCard from "@/assets/images/card/credit-card.png";
import {
	formatCardNumber,
	formatExpirationDate,
	CARD_IMAGES,
} from "@/utils/helpers";
import CustomerSelect from "@/utils/Selects/CustomerSelect/CustomerSelect.vue";
import CouponSelect from "@/utils/Selects/CouponSelect/CouponSelect";
import CreateCardForm from "./CreateCardForm.vue";
import { filters } from "../../../../../../public/assets/mixins/filters";
import CustomerCard from "./CustomerCard.vue";
import Axios from "axios";

const initialState = () => ({
	request: {
		planRef: "",
		walletRef: "",
		couponCode: "",
		cardNumber: "",
		cardName: "",
		expiryDate: "",
		cvv: "",
		type: "wallet",
		customer: "",
		tokenized_card: {},
	},
	formValid: {},
	cardInfo: "",
	loading: false,
	successMsg: false,
	formErrors: {},
	customerCards: [],
	payment: {},
	cardLoading: false,
});

export default {
	name: "CreateSubscriptionModal",
	mixins: [notifications, filters],
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "subRef", "plan", "refetch"],
	components: {
		CustomInput,
		CouponSelect,
		Modal,
		Spinner,
		FormButtons,
		SuccessState,
		PlanSelect,
		WalletSelect,
		DatePicker,
		CustomerSelect,
		CreateCardForm,
		CustomerCard,
	},
	computed: {
		cvvLabel() {
			if (
				this.cardInfo &&
				this.cardInfo.code &&
				this.cardInfo.code.name
			) {
				return this.cardInfo.code.name;
			}
			return "CVV";
		},
		cvvLength() {
			if (
				this.cardInfo &&
				this.cardInfo.code &&
				this.cardInfo.code.size
			) {
				return this.cardInfo.code.size;
			}
			return 4;
		},
		card_image() {
			if (this.cardInfo && this.cardInfo.type) {
				return CARD_IMAGES[this.cardInfo.type];
			}
			return CreditCard;
		},
		card_length() {
			if (
				this.cardInfo &&
				this.cardInfo.lengths &&
				this.cardInfo.lengths.length
			) {
				return this.cardInfo.lengths[0] + this.cardInfo.gaps.length;
			}

			return 20;
		},

		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		getExpiry(val) {
			const expiry = val.split("/");
			return `${expiry[0]}/${expiry[1].toString().substr(-2)}`;
		},
		handleSubmit(e) {
			e.preventDefault();
			console.log("this.request", this.plan);
			const payload = { ...this.request };

			if (this.request.type == "wallet") {
				delete payload.cvv;
				delete payload.cardName;
				delete payload.customer;
				delete payload.expiryDate;
				delete payload.cardNumber;
				delete payload.tokenized_card;
			} else {
				if (payload.tokenized_card.id == "NEW_CARD") {
					const { cardNumber, expiryDate, cvv } =
						this.formValid || {};
					if (cardNumber || expiryDate || cvv) {
						return (this.formErrors = {
							...this.formErrors,
							...this.formValid,
						});
					}
				} else {
					delete payload.cvv;
					delete payload.cardName;
					delete payload.cardNumber;
					delete payload.expiryDate;
				}
				delete payload.walletRef;
			}

			this.plan && delete payload.planRef;
			!payload.couponCode && delete payload.couponCode;

			const { formErrors, isValid } = formValidator(payload);

			if (isValid) {
				payload.planRef &&
					(payload.planRef = payload.planRef.reference);
				payload.couponCode &&
					(payload.couponCode = payload.couponCode.couponCode);

				if (this.request.type == "wallet") {
					const walletRef = payload.walletRef.reference;
					payload.option = { walletRef };
				} else {
					if (payload.tokenized_card.id !== "NEW_CARD") {
						const option = {
							authToken: payload.tokenized_card.authToken,
						};

						payload.option = option;
					}
					payload.customerRef = payload.customer.reference;
				}

				delete payload.cvv;
				delete payload.type;
				delete payload.cardName;
				delete payload.customer;
				delete payload.walletRef;
				delete payload.cardNumber;
				delete payload.expiryDate;
				delete payload.save_card;

				delete payload.paymentMethod;
				delete payload.paymentReference;
				delete payload.tokenized_card;

				console.log("this.request.planRef", this.request.planRef);

				if (this.request.tokenized_card.id !== "NEW_CARD") {
					this.subscribe(payload);
				} else {
					this.initializeCharge({
						card: {
							pan: this.request.cardNumber.replace(/\s/g, ""),
							cvv: this.request.cvv,
							expiry: this.getExpiry(this.request.expiryDate),
							card_holder: this.request.cardName,
						},
						user_information: {
							email_address: this.request.customer.email,
							phone_number: this.request.customer.phone,
						},
						save_payment_usage: true,
						save_card: this.request.save_card,
						narration: `Payment for subscription to plan ${this.request.planRef.name}`,
						amount: Number(this.request.planRef.amount),
						currency: "NGN",
					});
				}
			} else this.formErrors = formErrors;
		},
		handleRefetch() {
			this.handleCloseModal();
			this.plan ? this.refetch() : location.reload();
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		setRequest(name, value) {
			this.request[name] = value;
			this.handleError(name);
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		getPlanRefs() {
			return this.request.forPlans.map((plan) => plan.reference || plan);
		},
		async subscribe(payload) {
			try {
				this.loading = true;

				const request = {
					...payload,
					planRef: this.plan ? this.plan.reference : payload.planRef,
				};

				const response = await this.$store.dispatch(
					"subscriptions/createSubscription",
					{
						apiKey: getWalletApikey(this.$store),
						request,
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
		async handleNewCard() {
			const payload = {
				customerRef: this.request.customer.reference,
				planRef: this.request.planRef.reference,
				option: {
					paymentReference: this.payment.reference,
				},
			};

			if (this.request.couponCode && this.request.couponCode.couponCode) {
				payload.couponCode = this.request.couponCode.couponCode;
			}

			this.subscribe(payload);
		},
		async initializeCharge(payload) {
			try {
				this.loading = true;

				const ip_address = await Axios.get(
					"https://api.ipify.org?format=json"
				)
					.then(({ data }) => data)
					.then(({ ip }) => ip);

				payload.user_information = {
					...payload.user_information,
					ip_address,
				};

				const response = await this.$store.dispatch(
					"payment/chargeCard",
					{
						apiKey: getWalletApikey(this.$store),
						request: payload,
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.payment = response.data.data;
					this.$store.state.checkoutData = {
						url: response.data.data.redirect_url,
						refetch: this.handleNewCard,
						addFrame: true,
					};
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
	},
	async mounted() {
		if (this.plan) {
			this.request = { ...this.request, planRef: this.plan };
		}
	},
	watch: {
		async "request.customer"() {
			this.cardLoading = true;
			try {
				const response = await this.$store.dispatch(
					"customers/getCustomerCard",
					{
						apiKey: this.getWalletApikey(this.$store),
						reference: this.request.customer.reference,
					}
				);

				this.customerCards = response.data.data.data;

				this.cardLoading = false;
			} catch (error) {
				this.cardLoading = false;
				this.showErrorLoginNotification(message);
			}
		},
		"request.cardNumber"(newValue) {
			const cardData = formatCardNumber(newValue);
			this.cardInfo = cardData.card;
			this.formValid = { ...this.formValid, cardNumber: !cardData.next };
			this.request.cardNumber = cardData.newValue;
		},

		"request.cvv"(newValue) {
			const value = newValue.replace(/[^0-9]/g, "");
			this.formValid = {
				...this.formValid,
				cvv: !(newValue.length === this.cvvLength),
			};

			this.request.cvv = value;
		},

		"request.expiryDate"(newValue) {
			const cardData = formatExpirationDate(newValue);
			this.formValid = { ...this.formValid, cardNumber: !cardData.next };
			this.request.expiryDate = cardData.newValue;
		},
	},
};
</script>
