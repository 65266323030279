]
<template>
	<div>
		<div class="col-12">
			<transition mode="out-in" name="fade">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									NIN By phone Number (pNIN Basic) Request
									form
								</h2>
								<h5 class="user-profile-email sub">
									Verify NIN By Phone Numbers of various users
								</h5>
							</div>
						</div>
						<div class="content">
							<span
								class="badge badge-warning"
								style="font-size: 14px; font-weight: 500"
								v-if="
									hasService &&
									userRole !== 'Secondary Customer'
								"
								>(Each API call costs &#8358;{{
									`${servicePrice}`
								}})</span
							>
							<span
								v-if="!hasService"
								class="badge badge-danger"
								style="
									font-size: 14px;
									margin-top: 5px;
									font-weight: 500;
								"
								>You don't have access to this service</span
							>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="verifyNINByMsisdn">
							<div class="row mt-4">
								<div class="col-12">
									<CustomPhoneInput
										:onlyCountries="['NG']"
										label="Phone Number"
										v-model="phonenumber"
										@blur="$v.phonenumber.$touch()"
										placeholder="Enter your phone number"
										:error="
											$v.phonenumber.$error &&
											formVuelidator($v.phonenumber)
										"
									/>
								</div>

								<div class="col-md-12">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@close="$v.app.$touch()"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<!-- API Version details -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select an API Version
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an API version"
										v-model="apiVersion"
										@close="$v.apiVersion.$touch()"
										@keydown.enter.native.prevent
										:required="!apiVersion"
										:disabled="!versions"
										:options="versions"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.apiVersion.$error"
									>
										<li class="parsley-required">
											API version field is required.
										</li>
									</ul>
								</div>

								<!-- Currency Type  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select Currency Type
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Currency Type"
										v-model="currencyType"
										@keydown.enter.native.prevent
										:disabled="!customerWallets"
										:options="customerWallets"
										label="currency"
									></v-select>
								</div>

								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
										:disabled="
											isSubmitting == true || !hasService
										"
									>
										<span v-if="isSubmitting"
											>...Verifying nin</span
										>
										<span v-else>Verify NIN</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="user-settings-section" v-if="hasNewResponse">
					<div class="user-settings-section-header">
						<div class="user-settings-section-header-text">
							<h1>NIN Request Response</h1>
							<span class="text-span"
								>Preview of the information gotten from NIN
								request made</span
							>
						</div>
						<a
							href="#"
							class="btn btn-outline-danger"
							@click="hasNewResponse = false"
						>
							<i class="la la-close"></i>Close Panel
						</a>
					</div>
					<div class="preview-content" v-if="apiVersion == 'v1'">
						<p class="invoice-info-row preview-row">
							<span>Firstname</span>
							<span>{{ verificationResult.first_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Lastname</span>
							<span>{{ verificationResult.last_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Middle Name</span>
							<span>{{ verificationResult.middle_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Gender</span>
							<span>{{
								verificationResult.gender == "f"
									? "Female"
									: "Male"
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>NIN</span>
							<span>{{ verificationResult.nin }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Date of Birth</span>
							<span>{{
								verificationResult.formatted_date_of_birth
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Phone number</span>
							<span>{{ verificationResult.phone_number }}</span>
						</p>

						<div class="gaps-1-5x"></div>
					</div>

					<div class="preview-content" v-else>
						<p class="invoice-info-row preview-row">
							<span>Firstname</span>
							<span>{{ verificationResult.first_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Lastname</span>
							<span>{{ verificationResult.last_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Middle Name</span>
							<span>{{ verificationResult.middle_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Gender</span>
							<span>{{
								verificationResult.gender == "f"
									? "Female"
									: "Male"
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>NIN</span>
							<span>{{ verificationResult.nin }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Date of Birth</span>
							<span>{{
								verificationResult.formatted_date_of_birth
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Phone number</span>
							<span>{{ verificationResult.phone_number }}</span>
						</p>

						<div class="gaps-1-5x"></div>
					</div>
				</div>
			</transition>

			<hr />
		</div>

		<div class="row">
			<div class="col-12">
				<div class="user-details-info">
					<div class="user-details--top-info">
						<h2 class="user-profile-name">API Request Format</h2>
						<h5 class="user-profile-email sub">
							Representation of how the API call can be made with
							the appropriate data neccesary
						</h5>
					</div>
				</div>
			</div>

			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:35%;">End-Point</label><code>
            'POST': '{{API}}IdentityVerification/pNIN-Basic',
      </code></pre>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:42%;">Header</label><code>
            {
              'clientid': '{{clientID}}',
              'appname': '{{ app ? app.app_name : 'app_name'  }}',
              'apikey': '{{ app ? app.api_key : 'app_key'  }}',
            }
      </code></pre>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:42%;">Body</label><code>
            {
              "phone_number": "{{phonenumber.formatted ? getPhoneNumber(phonenumber)  : "phonenumber"}}"
            }
     </code></pre>
			</div>
			<div class="col-12">
				<pre class="code code-html"><label>Sample Response</label><code>
        {{apiResponse ? apiResponse : `{
    "message": "Verification Completed Successfully",
    "code": 200,
    "status": "Success",
    "result": {
      "request_reference": "xxxxxxxxx",
      "first_name": "JOHN",
      "last_name": "DOE",
      "middle_name": "JAMES",
      "other_name": "JACOB",
      "date_of_birth": "01-02-1904",
      "nin": "123457890",
      "tracking_id": "ADFA88ADF0ADFASD",
      "central_id": "41238479",
      "gender": "m",
      "maiden_name": "",
      "title": "mr",
      "phone_number": "08000000000",
      "formatted_date_of_birth": "February 1, 1904"
    }
}`}}
      </code></pre>
			</div>
		</div>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		></ApplicationInitializationModal>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { getPhoneNumber } from "@/utils/helpers";

export default {
	props: {
		application: {
			type: Array,
		},
		services: {
			type: Array,
		},
	},
	mixins: [notifications, modalMixin],
	validations: {
		app: { required },
		phonenumber: {
			required,
			isPhoneValid: isPhoneValid("phonenumber"),
		},
		apiVersion: { required },
	},
	components: {
		CustomPhoneInput,
	},
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
	},
	computed: {
		API() {
			// if (this.apiVersion == "v2" || this.apiVersion == "") {
			return this.$store.state.appUrlV2;
		},
	},
	data() {
		return {
			initializedAppResult: {},
			call_id: "",
			clientID: "",
			versions: ["v1", "v2"],
			apiVersion: "",
			app: "",
			apikey: "",
			appname: "",
			phonenumber: "",
			hasNewResponse: false,
			apiResponse: "",
			customerWallets: this.$store.state.account.customerWallets,
			currencyType: "",
			// services
			hasService: false,
			servicePrice: "",

			isSubmitting: false,
			verificationResult: [],
		};
	},
	methods: {
		getPhoneNumber,
		formVuelidator,
		computeService() {
			if (this.services) {
				this.services.filter((service) => {
					if (
						service.service_label.includes(
							"(pNIN-Basic) NIN Verification with Msisdn"
						)
					) {
						this.hasService = true;
						this.servicePrice = service.pivot.price_per_call;
					}
				});
			}
		},
		getRequestData() {
			if (!this.hasBundleBilling) {
				this.call_id = "";
			}

			let verificationData = {
				clientID: this.clientID,
				appname: this.app.app_name,
				apikey: this.app.api_key,
				payload: {
					phone_number: getPhoneNumber(this.phonenumber),
					apiVersion: this.apiVersion,
					currency_type: this.currencyType.currency,
				},
				query: this.call_id,
			};

			return verificationData;
		},
		async verifyNINByMsisdn() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			let verificationData = this.getRequestData();
			this.isSubmitting = true;

			try {
				let apiResponse = await this.$store.dispatch(
					"services/verifyNINByMsisdnBasic",
					verificationData
				);
				if (apiResponse.message == "NIN not found") {
					Nprogress.done();
					this.isSubmitting = false;
					this.showErrorLoginNotification(apiResponse.message);
					return;
				}
				this.apiResponse = apiResponse;
				this.showSuccessNotification(apiResponse.message);
				this.verificationResult = apiResponse.results;
				this.hasNewResponse = true;
				this.isSubmitting = false;
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSubmitting = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.computeService();

		this.clientID = this.$store.state.account.clientID;
	},
};
</script>

<style></style>
