<template>
	<div>
		<img
			@click="openEditCoupon"
			class="pointer mr-4"
			src="/assets/img/icons/edit.png"
			alt="update Coupon"
			width="25px"
		/>
		<CreateCouponModal
			:id="coupon.couponCode"
			:closeModal="closeModal"
			:couponRef="couponRef"
			:coupon="coupon"
		/>
	</div>
</template>

<script>
	import CreateCouponModal from "../../modals/CreateCouponModal/CreateCouponModal";

	export default {
		name: "UpdateCouponButton",
		props: ["couponRef", "coupon"],
		methods: {
			openEditCoupon() {
				$(`#${this.coupon.couponCode}`).modal("show");
			},
			closeModal() {
				$(`#${this.coupon.couponCode}`).modal("hide");
			},
		},
		components: {
			CreateCouponModal,
		},
	};
</script>
