<template>
	<Modal
		:id="id"
		:title="'Create Split Payment'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Split Payment Created  Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="h-100">
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="input-item-label">
							Split Group Name
							<span
								class="text-danger"
								title="This field is required"
								>*</span
							>
						</label>
						<input
							type="text"
							v-model="name"
							class="form-control input-bordered"
							placeholder="Give your split group a name"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.name.$error"
						>
							<li class="parsley-required">
								Split group name is required.
							</li>
						</ul>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<CurrencySelect
							:name="'currency_type'"
							v-model="currency"
							:ngnOnly="true"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.currency.$error"
						>
							<li class="parsley-required">
								Transaction currency is required.
							</li>
						</ul>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label class="input-item-label">
							Select Sub Account
						</label>
						<v-select
							:name="'subAccount'"
							label="name"
							optionLabel="name"
							v-model="selected"
							placeholder="Select Sub Account"
							:options="subAccounts"
							class="v-select"
							@keydown.enter.native.prevent
							@input="addToSplit"
						>
							<template
								slot="selected-option"
								slot-scope="option"
							>
								{{
									`${startTitleCase(option.name)} (${
										option.account_number
									})`
								}}
							</template>
							<template slot="option" slot-scope="option">
								{{
									`${startTitleCase(option.name)} (${
										option.account_number
									})`
								}}
							</template>
						</v-select>
						<ul
							class="parsley-errors-list filled"
							v-show="!$v.splits.required && $v.splits.$error"
						>
							<li class="parsley-required">
								Please configure at least one sub account.
							</li>
						</ul>
					</div>
				</div>
				<div class="col-12 mb-3" v-if="splits.length > 0">
					<div
						v-for="(account, index) in splits"
						:key="account.reference"
						class="row ml-0 mt-3 d-flex align-items-center"
					>
						<div class="d-flex flex-grow-1 align-items-center">
							<img
								class="mr-2 pointer"
								@click="removeAccount(index)"
								src="/assets/img/close.svg"
								alt="close.svg"
							/>
							{{ truncateString(startTitleCase(account.name)) }}
						</div>
						<div class="col-4">
							<v-select
								class="v-select"
								placeholder="Type"
								v-model="splits[index].type"
								@keydown.enter.native.prevent
								:options="[
									{ label: 'Percent', value: 'percent' },
									{ label: 'Flat', value: 'flat' },
								]"
								label="label"
							>
							</v-select>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.splits.$each[index].type.$error"
							>
								<li class="parsley-required">
									Type is required.
								</li>
							</ul>
						</div>
						<div class="col-4">
							<input
								type="text"
								v-model="splits[index].value"
								class="form-control input-bordered"
								placeholder="Value"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.splits.$each[index].value.$error"
							>
								<li class="parsley-required">
									Value is required.
								</li>
							</ul>
						</div>
					</div>
					<ul
						class="parsley-errors-list filled mb-2"
						v-show="$v.percentSum.$error"
					>
						<li class="parsley-required">
							Total percentage must be less than or equal to 100
						</li>
					</ul>
				</div>
				<div class="col-12">
					<button
						type="submit"
						class="btn btn-lg btn-primary w-25"
						:disabled="loading"
					>
						<Spinner v-if="loading" />
						<span v-else>Create Group</span>
					</button>
				</div>
			</div>
		</form>
	</Modal>
</template>

<script>
import Modal from "../../../../../utils/Modal/Modal";
import Spinner from "../../../../../utils/Spinner/Spinner";
import {
	getWalletApikey,
	startTitleCase,
	truncateString,
} from "@/utils/helpers";
import { required, maxValue, minLength } from "vuelidate/lib/validators";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import { notifications } from "../../../../../../public/assets/mixins/notifications";

const initialState = () => ({
	name: "",
	currency: "",
	selected: "",
	splits: [],
	successMsg: false,
	loading: false,
});

export default {
	mixins: [notifications],
	name: "CreateSplitPaymentModal",
	validations: {
		name: { required },
		currency: { required },
		percentSum: { maxValue: maxValue(100) },
		splits: {
			required,
			minLength: minLength(1),
			$each: {
				type: {
					required,
				},
				value: {
					required,
				},
			},
		},
	},
	data() {
		return {
			...initialState(),
			loading: false,
			subAccounts: [],
		};
	},
	props: ["id", "closeModal", "splitPaymentRef"],
	components: {
		Modal,
		SuccessState,
		Spinner,
		CurrencySelect,
	},
	methods: {
		startTitleCase,
		truncateString,
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.$v.$reset();
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			location.reload();
		},
		removeAccount(index) {
			this.splits.splice(index, 1);
		},
		addToSplit(sub) {
			if (
				this.splits.find(
					(account) => account.reference === sub.reference
				)
			) {
				return;
			}
			this.splits.push({
				...sub,
				type: "",
				value: "",
				sub_account: sub.reference,
			});
		},
		async handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			// this.loading = true;

			const config = this.splits.map((config) => ({
				type: config.type.value,
				sub_account: config.sub_account || config.reference,
				value: parseInt(config.value),
			}));

			const payload = {
				name: this.name,
				currency: this.currency.code,
				config: [...config],
			};

			this.createMerchantSplitPayment(payload);
		},
		async createMerchantSplitPayment(data) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"payment/createMerchantSplitPayment",
					{
						apiKey: getWalletApikey(this.$store),
						request: {
							...data,
						},
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async getMerchantSubAccounts() {
			try {
				const subAccounts = await this.$store.dispatch(
					"payment/getMerchantSubAccounts",
					{ apiKey: getWalletApikey(this.$store) }
				);
				this.subAccounts = subAccounts.data.data.data;
			} catch (error) {
				this.subAccounts = [];
			}
		},
	},
	computed: {
		percentSum() {
			return this.splits.reduce((sum, config) => {
				if (config.type.value == "percent") {
					return sum + parseInt(config.value);
				}
				return sum;
			}, 0);
		},
		user() {
			return this.$store.state.account.profile;
		},
	},
	mounted() {
		this.getMerchantSubAccounts();
	},
};
</script>

<style scoped>
.form-control {
	height: 100% !important;
}
</style>
