<template>
  <div>
    <CustomBuiltSelect
      :label="'Product'"
      :btnName="'Product'"
      :placeholder="'Select Product'"
      :value="value"
      :error="error"
      :loading="loading"
      :selected="selected"
      :getLabel="getLabel"
      :required="required"
      :options="filteredProducts"
      :handleChange="handleChange"
      :handleSelect="handleSelect"
      :createFooter="openAddNewProductModal"
    />

    <transition mode="out-in" name="fade">
      <Teleport v-if="show" to="body">
        <div :style="{ position: 'relative', zIndex: 1050 }">
          <CreateItemsModal
            modalCls="show d-block"
            :id="'addProduct'"
            :closeModal="closeModal"
            :refetch="refetch"
            :noReload="true"
          />
          <div class="modal-backdrop fade show"></div>
        </div>
      </Teleport>
    </transition>
  </div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";
import Teleport from "@/utils/Teleport/Teleport.vue";
import CreateItemsModal from "@/components/wallet/Invoices/modals/CreateItemsModal/CreateItemsModal.vue";
import CustomBuiltSelect from "../CustomBuiltSelect/CustomBuiltSelect";

export default {
  name: "ProductSelect",
  data() {
    return {
      filteredProducts: [],
      produts: [],
      selected: this.product || null,
      value: "",
      loading: false,
      show: false,
    };
  },
  components: {
    CustomBuiltSelect,
    CreateItemsModal,
    Teleport,
  },
  props: ["required", "error", "action", "product", "setRequest"],
  methods: {
    async refetch(customer) {
      // this.action(customer);
      this.getProducts();
    },
    handleChange({ target: { value } }) {
      if (value) {
        this.value = value;
        let data = [...this.produts].filter(
          (product) =>
            `${product.name}`.toLowerCase().includes(value.toLowerCase()) ||
            customer.email.includes(value.toLowerCase())
        );
        this.filteredProducts = data;
      } else {
        this.value = "";
        this.filteredProducts = this.produts;
      }
    },
    getLabel(product) {
      const { name } = product || {};
      return name ? `${name}` : "";
    },
    handleSelect(option) {
      this.selected = option;
      this.value = this.getLabel(option);
      this.setRequest("productRef", option);
    },
    openAddNewProductModal() {
      // $("#addProduct").modal("show");
      this.show = true;
    },
    closeModal() {
      // $("#addProduct").modal("hide");
      this.show = false;
    },

    async getProducts() {
      this.loading = true;

      try {
        const response = await this.$store.dispatch("products/getProducts", {
          apiKey: getWalletApikey(this.$store),
          limit: 100000000000000,
        });

        this.produts = response.data.data.data;
        this.filteredProducts = response.data.data.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.produts = [];
      }
    },
    setProduct(v) {
      this.selected = v;
      this.value = this.getLabel(v);
    },
  },
  mounted() {
    this.setProduct(this.product);
    this.getProducts();
  },
  watch: {
    product(option) {
      this.setProduct(option);
    },
  },
};
</script>
