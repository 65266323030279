<template>
  <Modal
    :cls="modalCls"
    :id="id"
    :title="item ? `Update ${titleCase(item.name)}` : `Add Product`"
    :toggle="handleCloseModal"
  >
    <SuccessState
      v-if="successMsg"
      :message="
        item ? 'Product Updated Successfully' : 'Product Added Successfully'
      "
      :onSuccess="handleRefetch"
    />

    <form v-else @submit.prevent="handleSubmit" class="h-100">
      <CustomInput
        :name="'name'"
        v-model="request.name"
        :label="'Product Name'"
        :placeholder="'Enter name'"
        :required="true"
        :error="formErrors.name"
        :handleError="handleError"
      />

      <CustomAmountInput
        :required="true"
        :name="'amount'"
        :amount="request.amount"
        :currency="request.currency"
        v-model="request.amount"
        :label="'Enter Amount'"
        :placeholder="'Enter amount to fund'"
        :error="formErrors.amount"
        :handleError="handleError"
        :handleSelect="handleSelect"
        :isNum="true"
      />

      <CustomTextArea
        :name="'description'"
        v-model="request.description"
        :label="'Description'"
        :placeholder="'Describe product'"
        :error="formErrors.description"
        :handleError="handleError"
        :required="true"
      />

      <TaxSelect
        v-if="item"
        :name="'TaxId'"
        :error="formErrors.TaxId"
        :handleError="handleError"
        v-model="request.TaxId"
        :tax="item.TaxId"
        :action="(tax) => setRequest('TaxId', tax)"
        :setRequest="(tax) => setRequest('TaxId', tax)"
      />
      <TaxSelect
        v-else
        :name="'TaxId'"
        :error="formErrors.TaxId"
        :handleError="handleError"
        v-model="request.TaxId"
        :action="(tax) => setRequest('TaxId', tax)"
        :setRequest="(tax) => setRequest('TaxId', tax)"
      />

      <div class="mb-2" v-if="!item">
        <label class="">
          Category
          <span class="text-danger" title="This field is required">*</span>
        </label>
        <div class="d-flex">
          <label class="rdiobox mr-4">
            <input
              name="type"
              type="radio"
              :checked="true"
              value="goods"
              v-model="request.type"
            />
            <span class="fs-15">Goods</span>
          </label>
          <label class="rdiobox">
            <input
              name="type"
              type="radio"
              value="services"
              v-model="request.type"
            />
            <span class="fs-15"> Services </span>
          </label>
        </div>
        <div v-if="typeof formErrors.type === 'string'">
          <span v-show="formErrors.type" class="text-danger">{{
            formErrors.type
          }}</span>
        </div>
      </div>

      <div class="row" v-if="request.type == 'services' && !item">
        <div
          :class="[
            request.subscription_type === 'recurring' ? 'col-6' : 'col-12',
          ]"
        >
          <CustomSelect
            name="subscription_type"
            label="Subscription Type"
            placeholder="Select Subscription Type"
            :options="['one-time', 'recurring']"
            v-model="request.subscription_type"
            :required="true"
            :error="formErrors.subscription_type"
            :handleError="handleError"
          />
        </div>

        <template v-if="request.subscription_type === 'recurring'">
          <div class="col-6">
            <CustomSelect
              name="interval"
              label="Interval"
              placeholder="Select Interval"
              :options="intervals"
              v-model="request.interval"
              :required="true"
              :error="formErrors.interval"
              :handleError="handleError"
            />
          </div>
        </template>
      </div>

      <div class="form-group mt-4">
        <CustomUpload
          :name="'product'"
          :label="'Product Image'"
          :required="true"
          :validFileTypes="['.jpg', '.png', '.jpeg']"
          :handleChange="handleUpload"
          :fileName="file && file.name"
        />
        <div class="mt-4 d-flex" v-if="image_urls[0]">
          <img
            v-for="img in image_urls"
            :key="img"
            :src="img"
            alt="img"
            width="70"
            height="70"
            class="mx-2"
          />
        </div>
      </div>
      <FormButtons :loading="loading" :handleCloseModal="handleCloseModal" />
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import TaxSelect from "@/utils/Selects/TaxSelect/TaxSelect.vue";
import { formActions } from "../../../../../../public/assets/mixins/formActions";
import { intervals } from "@/components/wallet/Subscriptions/enums.js";

const initialState = () => ({
  request: {
    name: "",
    amount: "",
    description: "",
    TaxId: "",
    currency: "NGN",
    type: "goods",
    subscription_type: "",
    interval: "",
  },
  image_urls: [],
  file: null,
  loading: false,
  successMsg: "",
  intervals,
});

export default {
  mixins: [notifications, formActions],
  name: "CreateItemsModal",
  data() {
    return { ...initialState(), newItem: {} };
  },
  components: {
    TaxSelect,
  },
  props: [
    "id",
    "closeModal",
    "item",
    "itemsRef",
    "refetch",
    "noReload",
    "modalCls",
  ],

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const { name, amount, description, subscription_type, type, interval } =
        this.request;

      const { formErrors, isValid } = this.formValidator({
        name,
        amount,
        description,
        ...(!this.item && {
          ...(type == "services" && { subscription_type }),
          ...(subscription_type == "recurring" && { interval }),
        }),
      });

      if (isValid) {
        if (amount <= 0)
          this.formErrors = {
            ...this.formErrors,
            amount: "Invalid Amount",
          };
        else this.handleItem();
      } else this.formErrors = formErrors;
    },
    handleRefetch(e) {
      e.preventDefault();
      e.stopPropagation();
      //   this.itemsRef && this.itemsRef.refresh();
      //   this.refetch && this.refetch(this.newItem);
      // Vue seems to be crazy the codes above, it couldn't refresh the table
      // So, I'll reload the page.    -Joshua O.

      if (this.refetch && this.noReload) {
        this.refetch();
      } else window.location.reload();
      this.handleCloseModal();
      this.setItemData();
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleCloseModal() {
      Object.assign(this.$data, { ...initialState() });
      this.closeModal();
      this.setItemData();
    },
    handleSelect(selected) {
      this.request.currency = selected;
    },
    setError(msg) {
      this.loading = false;
      this.showErrorLoginNotification(msg);
    },
    async handleUpload({ target: { files } }) {
      this.file = null;

      const file = files[0];
      if (file) {
        const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.file = file;
          this.uploadError = false;
        } else {
          this.uploadError = true;
        }
      }
    },
    setRequest(name, value) {
      this.request[name] = value;
    },
    async handleItem() {
      try {
        this.loading = true;

        const url = this.item
          ? "products/updateProduct"
          : "products/createProduct";

        let newRequest = { ...this.request };
        if (!newRequest.amount) {
          delete newRequest.amount;
          delete newRequest.currency;
        }
        !newRequest.description && delete newRequest.description;

        let payload = {
          apiKey: this.getWalletApikey(this.$store),
          request: newRequest,
        };
        payload = {
          apiKey: this.getWalletApikey(this.$store),
          request: {
            ...payload.request,
          },
        };
        if (this.item) {
          payload = {
            apiKey: this.getWalletApikey(this.$store),
            reference: this.item.reference,
            request: {
              name: this.request.name,
              amount: this.request.amount,
              description: this.request.description,
              currency: this.request.currency,
            },
          };
        }

        if (this.request.TaxId) {
          payload.request.TaxId = this.request.TaxId.id || this.request.TaxId;
        } else {
          delete payload.request.TaxId;
        }

        if (this.request.type == "services" && !this.item) {
          payload.request.subscription_type = this.request.subscription_type;

          if (payload.request.subscription_type == "recurring") {
            payload.request.interval = this.request.interval;
          } else {
            delete payload.request.interval;
          }
        } else {
          delete payload.request.interval;
          delete payload.request.subscription_type;
        }

        if (this.file) {
          const dataSize = 1024 * 1024;
          if (this.file.size / dataSize > 5) {
            this.setError("File too large");
          } else {
            let newPayload = new FormData();
            newPayload.append("name", this.request.name);
            newPayload.append("amount", this.request.amount);
            newPayload.append("description", this.request.description);
            newPayload.append("currency", this.request.currency);
            !this.item &&
              newPayload.append("type", this.request.type.toLowerCase());
            this.request.TaxId &&
              newPayload.append(
                "TaxId",
                this.request.TaxId.id || this.request.TaxId
              );
            newPayload.append("images", this.file);

            if (this.request.type == "services" && !this.item) {
              newPayload.append(
                "subscription_type",
                this.request.subscription_type
              );

              if (this.request.subscription_type == "recurring") {
                newPayload.append("interval", this.request.interval);
              }
            }

            payload = { ...payload, request: newPayload };
          }
        }

        const response = await this.$store.dispatch(url, {
          ...payload,
        });
        const { status, data } = response || {};
        if (status >= 200 && status <= 250 && (data.status || data.success)) {
          this.loading = false;
          this.successMsg = true;
          this.newItem = data.data;
        } else this.setError(data.message);
      } catch (error) {
        console.log("first", error, this.getError(error));
        this.setError(this.getError(error));
        this.loading = false;
      }
    },
    setItemData() {
      if (this.item) {
        const { name, amount, description, currency, type, image_urls, TaxId } =
          this.item || {};
        this.request = {
          name,
          amount,
          description,
          currency,
          type,
          TaxId,
        };
        this.image_urls = image_urls;
      }
    },
  },
  mounted() {
    this.setItemData();
  },
};
</script>
