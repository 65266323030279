<template>
	<EmptyWallet v-if="!merchant" />

	<div v-else class="p-md-4 p-3">
		<div class="SectionItem no-b-b mb-3">
			<p class="title">Invoice</p>
		</div>

		<div class="content__slider">
			<TabsV2
				:tabNames="tabNames"
				:tabId="'invoiceTab'"
				:tabLink="true"
			/>
		</div>

		<transition mode="out-in" name="slide">
			<router-view />
		</transition>
	</div>
</template>

<script>
import SplitContent from "../../../utils/SplitContent/SplitContent";
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import TabsV2 from "../../../utils/Tabs/TabsV2";

const tabNames = [
	{ tabName: "Invoice", value: "invoice", link: "/invoice" },
	{ tabName: "Products", value: "products", link: "/invoice/products" },
	{ tabName: "Drafts", value: "drafts", link: "/invoice/drafts" },
];

export default {
	name: "Invoices",
	components: {
		TabsV2,
		SplitContent,
		EmptyWallet,
	},
	data() {
		return {
			tabNames,
		};
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
