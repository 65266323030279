<template>
	<div class="card p-4">
		<div v-if="loading" class="flex__center my-5 py-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="getProfile"
		/>

		<div v-else>
			<div class="border-bottom pb-3 mb-4">
				<h3>
					{{ user.api_customer.organization_name }}
				</h3>
				<div class="flex__between">
					<span>
						ID: {{ user.api_customer.client_id || "None" }}
					</span>
					<AccessControl>
						<EditProfileButton :user="user" :refetch="getProfile" />
					</AccessControl>
				</div>
			</div>

			<div class="row profile border-bottom mb-4">
				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Phone Number</p>
					<h5>{{ getPhone(user.phone_number) || "None" }}</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Last Update</p>
					<h5>
						{{ timeDateFormat(user.api_customer.created_at).date }}
						{{ timeDateFormat(user.api_customer.created_at).time }}
					</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Status</p>
					<div class="d-flex">
						<div
							class="status"
							:class="
								user.api_customer.is_verified
									? 'success'
									: 'pending'
							"
						>
							{{
								user.api_customer.is_verified
									? "Verified"
									: "Unverified"
							}}
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Email</p>
					<h5>{{ user.email || "None" }}</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Company/Organization</p>
					<h5>
						{{ user.api_customer.organization_name || "None" }}
					</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Address</p>
					<h5 class="contact-details--truncated">
						{{ user.api_customer.address || "None" }}
					</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>Country</p>
					<h5 class="contact-details--truncated">
						{{ user.country.name || "None" }}
					</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>State</p>
					<h5 class="contact-details--truncated">
						{{ user.state.state_label || "None" }}
					</h5>
				</div>

				<div class="col-lg-3 col-md-4 col-sm-6 col-6">
					<p>City</p>
					<h5 class="contact-details--truncated">
						{{ user.city || "None" }}
					</h5>
				</div>
			</div>
			<AccessControl>
				<ProfileFooter :user="user" :refresh="getProfile"
			/></AccessControl>
		</div>
	</div>
</template>

<script>
import AccessControl from "@/utils/AccessControl/AccessControl";
import Spinner from "@/utils/Spinner/Spinner";
import FailedState from "@/utils/ResponseState/FailedState";
import EditProfileButton from "../buttons/EditProfileButton/EditProfileButton";
import ProfileFooter from "./ProfileFooter/ProfileFooter";
import { timeDateFormat, getPhone } from "@/utils/helpers";

export default {
	name: "Profile",
	data() {
		return {
			loading: false,
			errorMsg: "",
			user: {},
		};
	},
	components: {
		Spinner,
		FailedState,
		EditProfileButton,
		ProfileFooter,
		AccessControl,
	},

	methods: {
		getPhone,
		timeDateFormat,
		async getProfile() {
			try {
				this.loading = true;
				await this.$store
					.dispatch("account/getProfile")
					.then((response) => {
						this.loading = false;
						this.user = response;
					});
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
	},

	async mounted() {
		this.getProfile();
	},
};
</script>
