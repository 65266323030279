<template>
	<TransactionsTable
		:source="'reserved_account'"
		:downloadHeadings="headings"
	/>
</template>

<script>
import TransactionsTable from "../../common/TransactionsTable/TransactionsTable";
import { rsaTableHeadings } from "../extra";

export default {
	name: "ReservedAccountTransactions",
	data() {
		return {
			headings: rsaTableHeadings,
		};
	},
	components: {
		TransactionsTable,
	},
};
</script>
