import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "./router";
import store from "@/store/index";
import { apiClient } from "@/services/api";

// 3rd Party Packages
// import VueMoment from 'vue-moment'
import moment from "moment";
import vSelect from "vue-select";
import Vuelidate from "vuelidate";
import "vue-select/dist/vue-select.css";
import { ClientTable, ServerTable } from "vue-tables-2";
import Nprogress from "nprogress";
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
import "vue-select/dist/vue-select.css";
import "nprogress/nprogress.css";
import VueSwal from "vue-swal";
import VTooltip from "v-tooltip";
import VueApexCharts from "vue-apexcharts";
import VueGraph from "vue-graph";
import vue2Dropzone from "vue2-dropzone";
import Swatches from "vue-swatches";
import VCalendar from "v-calendar";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.min.css";
import ToggleButton from "vue-js-toggle-button";
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import vueAwesomeCountdown from "vue-awesome-countdown";
import VueShepherd from "vue-shepherd";
import Clipboard from "v-clipboard";
import UUID from "vue-uuid";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Print from 'vue-print-nb'

// Sentry
import * as Sentry from "@sentry/browser"; 
import { Vue as VueIntegration } from "@sentry/integrations";

import JQuery from "jquery";
window.JQuery = JQuery;

import OtpInput from "@bachdgvn/vue-otp-input";

Vue.use(Print);
Vue.use(VueSwal);
Vue.use(VCalendar);
Vue.use(VueLoaders);
Vue.use(vueAwesomeCountdown, "vac");

Vue.use(ToggleButton);
Vue.use(VTooltip);
//Vue.use(VueMoment)
Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.use(VueIziToast);
Vue.use(Vuelidate);
Vue.use(VueGraph);
Vue.component("v-select", vSelect);
Vue.component("apexchart", VueApexCharts);
Vue.component("vue-dropzone", vue2Dropzone);
Vue.component("swatches", Swatches);
Vue.use(VueShepherd);
Vue.use(Clipboard);
Vue.use(UUID);

Vue.component("v-otp-input", OtpInput);

import Loader from "@/components/UI/Loader.vue";
import DefaultContent from "@/components/UI/DefaultContent";
import comparisonbar from "vue-graph/src/components/comparisonbar";

// Sentry
Sentry.init({
	dsn:
		"https://282ddce6a04e40b49fade8169e79ac57@o448328.ingest.sentry.io/5500405",
	integrations: [new VueIntegration({ Vue, attachProps: true })],
	logErrors: true,
});

Vue.use(VueTelInput)

Vue.filter("moment", (value, format) => {
	return moment(moment.utc(value))
		.local()
		.format(format);
});

// Register filter for number
Vue.filter("numberSeperator", function(value) {
	if (!value) return "";
	value = Number.parseInt(value);
	return Number(value).toLocaleString();
});

Vue.use(VueTelInput);

// Registering Local Components
Vue.component("Loader", Loader);
Vue.component("DefaultContent", DefaultContent);

Vue.config.productionTip = false;

if (localStorage.getItem("salt-token")) {
	let token = localStorage.getItem("salt-token");
	apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

let protocol = window.location.protocol.split(":")[0];
let host;

let enviroment = process.env.NODE_ENV;

if (enviroment == "development") {
	host = window.location.host.split(":")[0];
} else {
	host = window.location.host;
}
localStorage.setItem("salt-host", host);
localStorage.setItem("salt-protocol", protocol);

// route guard
const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});
// Navigation guards
router.beforeEach((to, from, next) => {
	Nprogress.start();

	// route guards
	let token = localStorage.getItem("salt-token");
	let userRole = localStorage.getItem("saltState");

	// White Label Customer
	if (to.matched.some((record) => record.meta.whitelabel)) {
		if (token !== null && userRole === "Whitelabelled Customer") {
			next();
		} else {
			next("/dashboard");
			console.log(userRole, "Access not granted");
		}
	}

	// update title and meta tag
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);
	// const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll("[data-vue-router-controlled]")
	).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));
	next();
});

apiClient.interceptors.response.use(
	(response) => {
		return response;
	},
	(err) => {
		Nprogress.done();
		// if (err.response.status == 401 || err.response.status == 403) {
		// 	// localStorage.clear();
		// 	// setTimeout(() => {
		// 	// 	location.replace("/");
		// 	// }, 2500);
		// }
		throw err;
	}
);

router.afterEach((routeTo, routeFrom, next) => {
	Nprogress.done();
});

Vue.mixin({
  methods: {
    onlyNumbers(e) {
      const reg=/\d/;
      if (!reg.test(e.key)) {
        e.preventDefault()
      }
    }
  }
})


new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
