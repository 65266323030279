<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div v-else class="p-md-4 p-3">
			<div class="SectionItem no-b-b mb-3">
				<p class="title">Subscriptions</p>
			</div>

			<div class="content__slider">
				<Tabs
					:tabNames="tabNames"
					:tabId="'subscriptionTab'"
					:tabLink="true"
				/>
			</div>

			<transition mode="out-in" name="slide">
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import SplitContent from "../../../utils/SplitContent/SplitContent";
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import Tabs from "../../../utils/Tabs/Tabs";

const tabNames = [
	{ tabName: "Subscriptions", link: "/wallet/subscriptions" },
	{ tabName: "Plans", link: "/wallet/subscriptions/plans" },
	{ tabName: "Coupons", link: "/wallet/subscriptions/coupons" },
	{ tabName: "Tax", link: "/wallet/subscriptions/tax" },
	{ tabName: "Trails", link: "/wallet/subscriptions/trails" },
];

export default {
	name: "Subscriptions",
	components: {
		Tabs,
		SplitContent,
		EmptyWallet,
	},
	data() {
		return {
			tabNames,
		};
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
