<template>
	<div class="w-100">
		<template v-if="showOnlyBtn">
			<span
				v-if="useText"
				class="pointer"
				@click="$refs.uploadRef.click()"
			>
				{{ btnText || "Upload File" }}
			</span>
			<button
				v-else
				class="btn"
				:class="btnClass"
				@click="$refs.uploadRef.click()"
				:disabled="disabled"
			>
				{{ btnName || "Upload File" }}
			</button>

			<input
				type="file"
				ref="uploadRef"
				:name="name"
				:multiple="multiple"
				class="form-group__drag-drop__input"
				:accept="validFileTypes.join(', ')"
				@change="handleChange"
			/>
		</template>

		<div v-else class="form-group ">
			<label class="input-item-label">{{ label }}</label>
			<span
				v-show="required"
				class="text-danger pl-1"
				title="This field is required"
				>*</span
			>
			<div
				class="form-group__drag-drop-wrapper"
				:data-error="Boolean(error)"
			>
				{{fileName ? truncateString(fileName, 16) : uploadText || 'Click to upload file'}}

				<label
					@click="$refs.uploadRef.click()"
					class="btn btn-sm btn--primary px-4 m-0"
				>
					Browse
				</label>

				<input
					type="file"
				  :ref="'uploadRef'"
					:name="name"
					:multiple="multiple"
					class="form-group__drag-drop__input"
					:accept="validFileTypes ? validFileTypes.join(', ') : null"
					@change="handleChange"
				/>
			</div>
			<p v-show="error" class="text-danger">{{ error }}</p>
		</div>
	</div>
</template>

<script>
	import "./CustomUpload.css";
import {  truncateString } from "@/utils/helpers";

	export default {
		name: "CustomUpload",
		props: [
			"name",
			"value",
			"multiple",
			"validFileTypes",
			"required",
			"label",
			"error",
			"handleError",
			"onChange",
			"handleChange",
			"disabled",
			"showOnlyBtn",
			"cls",
			"btnName",
			"btnText",
			"useText",
      "fileName",
      "uploadText"
		],
		computed: {
			btnClass() {
				return this.cls ? this.cls : "btn-lg w-100 btn-primary";
			},
		},
    methods: {
      truncateString
    },
		watch: {
			value() {
				this.handleError(this.name);
			},
		},
	};
</script>
