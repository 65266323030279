<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 "
			@click="popAddBankModal"
		>
			Add Settlement Bank
		</button>
		<AddBankModal
			:id="'addBank'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
	import AddBankModal from "../../modals/AddBankModal/AddBankModal";
	export default {
		name: "AddBankButton",
		props: ["refetch"],
		methods: {
			popAddBankModal() {
				$("#addBank").modal("show");
			},
			closeModal() {
				$("#addBank").modal("hide");
			},
		},
		components: {
			AddBankModal,
		},
	};
</script>
