<template>
  <Modal
    :id="id"
    :title="`${user.mfa_active ? 'Disable' : 'Activate'} MFA`"
    :toggle="handleClose"
  >
    <SuccessState
      v-if="successMsg"
      :message="`MFA ${
        user.mfa_active ? 'Disabled' : 'Activated'
      } Successfully`"
      :onSuccess="handleRefetch"
    />
    <div v-else-if="loading" class="my-5">
      <Spinner :size="'20px'" />
    </div>

    <form v-else-if="!loading && !showOtp" @submit.prevent="requestMfa">
      <h5 class="text-center my-4">
        Are you sure you wanted to
        {{ user.mfa_active ? "disable" : "enable" }} Multi Factor Authentication
        ?
      </h5>

      <FormButtons
        :loading="loading"
        :btnName="`Yes, ${user.mfa_active ? 'Disable' : 'Enable'}`"
        :handleCloseModal="handleClose"
      />
    </form>

    <form v-else-if="!loading && showOtp" @submit.prevent="submitOTP">
      <div class="d-flex justify-content-center">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
        />
      </div>
      <div class="flex__between pt-4">
        <button
          type="button"
          class="btn btn-outline w-100 mr-3"
          :disabled="loading"
          @click="handleClose"
        >
          <span>Close</span>
        </button>
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100 ml-3"
          :disabled="loading || otp.length < 6"
        >
          Submit
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "../../../../utils/Modal/Modal";
import SuccessState from "../../../../utils/ResponseState/SuccessState";
import Spinner from "../../../../utils/Spinner/Spinner";
import FormButtons from "../../../../utils/FormButtons/FormButtons";

const initialState = {
  loading: false,
  successMsg: false,
  showOtp: false,
  otp: "",
};

export default {
  mixins: [notifications],
  name: "ToggleMultiFactorAuthModal",
  data() {
    return { ...initialState };
  },
  props: ["id", "user", "closeModal", "refresh"],
  components: { Modal, SuccessState, Spinner, FormButtons },
  methods: {
    handleClose() {
      this.closeModal();
      Object.assign(this.$data, { ...initialState });
    },
    handleRefetch() {
      this.closeModal();
      Object.assign(this.$data, { ...initialState });
      this.refresh();
    },
    handleOnChange(value) {
      this.otp = value;
    },
    async submitOTP() {
      if (this.otp.length < 6) {
        return;
      }

      this.loading = true;

      try {
        const response = await this.$store.dispatch("account/updateMfa", {
          mfa_status: !this.user.mfa_active,
          code: this.otp,
        });

        if (response.status_code == 200 && response.status == "Success") {
          this.successMsg = true;
        } else {
          this.showErrorRegistrationNotification(response.message);
        }

        this.loading = false;
        this.otp = "";
      } catch (error) {
        this.otp = "";
        this.loading = false;
        this.showErrorRegistrationNotification(error.message);
      }
    },

    async requestMfa() {
      this.loading = true;

      try {
        const response = await this.$store.dispatch("account/generateMfaOtp");

        if (response.status_code == 200 && response.status == "Success") {
          this.showOtp = true;
          this.showSuccessNotification("An OTP has been sent to you");
        } else {
          this.showErrorRegistrationNotification(response.message);
        }

        this.otp = "";
        this.loading = false;
      } catch (error) {
        this.otp = "";
        this.loading = false;
        this.showErrorRegistrationNotification(error.message);
      }
    },
  },
};
</script>
