<template>
	<Modal :id="id" :title="'Customer Information'" :toggle="closeModal">
		<ul class="customer__info">
			<li v-if="customer.id">
				<p>
					ID: <span>{{ customer.id }}</span>
				</p>
				<p>
					Reference: <span>{{ customer.reference }}</span>
				</p>
			</li>
			<li>
				<p>
					First Name: <span>{{ customer.first_name }}</span>
				</p>
				<p>
					Last Name: <span>{{ customer.last_name }}</span>
				</p>
			</li>
			<li>
				<p>
					Email: <span>{{ customer.email }}</span>
				</p>
				<p>
					Phone Number: <span>{{ customer.phone }}</span>
				</p>
			</li>
			<li>
				<p>
					Address: <span>{{ address }}</span>
				</p>
			</li>

			<h4
				class="border-bottom font-weight-bold text--primary mt-4 pb-2 mb-3"
			>
				Business Information
			</h4>

			<div v-if="customer.business_details">
				<p>
					Business Name:
					<span>{{ customer.business_details.name }}</span>
				</p>
				<p>
					Business Address:
					<span>{{ customer.business_details.address }}</span>
				</p>
			</div>

			<div v-else class="text-center py-4">
				<span class="text-danger"
					>Business Information not available</span
				>
			</div>
			<div class="flex__between">
				<button
					class="btn btn--sm btn--primary px-5 mt-3 mx-auto"
					@click="closeModal"
				>
					Ok
				</button>
			</div>
		</ul>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "@/utils/Modal/Modal";

export default {
	mixins: [notifications],
	name: "CustomerInfoModal",
	data() {
		return {};
	},
	props: ["id", "closeModal", "customer"],
	components: { Modal },
	computed: {
		address() {
			const { country, state, address_one } = this.customer;
			return `${address_one || ""} ${state || ""} ${country || ""}`;
		},
	},
};
</script>

<style scoped>
ul {
	list-style: none;
	padding: 0 !important;
	font-size: 1.2em;
}
ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
ul span {
	font-weight: 600;
	margin-left: 5px;
}
</style>
