<template>
  <Modal :id="id" :title="'Delete Director'" :toggle="handleCloseModal">
    <SuccessState
      v-if="successMsg"
      :message="'Director Deleted Successfully'"
      :onSuccess="handleSuccessCloseModal"
    />
    <form v-else @submit.prevent="handleSubmit" class="row h-100">
      <div class="col-12">
        <h4 class="text-center my-4">
          Are you sure you want to delete director
          <b>{{ `${selected.first_name} ${selected.last_name}` }}</b
          >?
        </h4>
      </div>
      <div class="col-12">
        <div class="flex__between pt-4">
          <button
            type="button"
            class="btn btn-outline w-100 mr-3"
            :disabled="loading"
            @click="handleCloseModal"
          >
            <span>Close</span>
          </button>
          <button
            type="submit"
            class="btn btn-lg w-100 ml-3 btn-danger"
            :disabled="loading"
          >
            <Spinner v-if="loading" :color="'#fff'" />
            <span v-else>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/utils/Modal/Modal.vue";
import Spinner from "@/utils/Spinner/Spinner.vue";
import SuccessState from "@/utils/ResponseState/SuccessState.vue";
import { notifications } from "../../../../../../public/assets/mixins/notifications";

const initialState = {
  successMsg: false,
  loading: false,
};

export default {
  props: ["id", "selected", "closeModal"],
  mixins: [notifications],
  data() {
    return { ...initialState };
  },
  methods: {
    handleSuccessCloseModal() {
      this.$emit("refresh");
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    handleCloseModal() {
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    async handleSubmit() {
      try {
        this.loading = true;

        const response = await this.$store.dispatch(
          "account/deleteDirector",
          this.selected.id
        );

        if (response.status) {
          this.loading = false;
          this.successMsg = true;
        }
      } catch (error) {
        this.loading = false;
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  components: {
    Modal,
    Spinner,
    SuccessState,
  },
};
</script>
