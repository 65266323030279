<template>
	<div class="az-content-body py-5">
		<div class="row">
			<div class="SectionItem d-flex justify-content-between">
				<div class="SectionHeader">
					<span class="title">Funds Transfer Information</span>
					<!-- <div class="description">Send funds by filling the details below.</div> -->
				</div>
			</div>
			<div class>
				<!-- nav tabs -->
				<div class="row">
					<div class="col-12">
						<div
							class="dashboard rounded tab-pane fade show active"
							id="nav-all-profile"
							role="tabpanel"
							aria-labelledby="nav-all-profile-tab"
						>
							<!-- nav tabs -->
							<div class="row">
								<div class="col-12">
									<nav>
										<div
											class="nav-tab__user-account nav nav-tabs"
											style="top: -1px"
											id="nav-tab"
											role="tablist"
										>
											<a
												data-toggle="tab"
												id="nav-all-users-tab"
												href="#nav-all-users"
												role="tab"
												aria-controls="nav-all-users"
												aria-selected="false"
												class="nav-link active"
											>
												<span
													class="badge badge-pill badge-primary"
													style="
														font-size: 14px;
														font-weight: 500;
													"
													>1</span
												>
												Business Information
											</a>
											<a
												data-toggle="tab"
												id="nav-settings-tab"
												href="#nav-transfer"
												role="tab"
												aria-controls="nav-transfer"
												aria-selected="false"
												class="nav-link"
											>
												<span
													class="badge badge-pill badge-primary"
													style="
														font-size: 14px;
														font-weight: 500;
													"
													>2</span
												>
												Business Funding Source
											</a>
											<a
												data-toggle="tab"
												id="nav-settings-tab"
												href="#nav-billing"
												role="tab"
												aria-controls="nav-billing"
												aria-selected="false"
												class="nav-link"
											>
												<span
													class="badge badge-pill badge-primary mr-1"
													style="
														font-size: 14px;
														font-weight: 500;
													"
													>3</span
												>Preferred Billing
											</a>
											<a
												data-toggle="tab"
												id="nav-settings-tab"
												href="#nav-access-config"
												role="tab"
												aria-controls="nav-access-config"
												aria-selected="false"
												class="nav-link"
											>
												<span
													class="badge badge-pill badge-primary"
													style="
														font-size: 14px;
														font-weight: 500;
													"
													>4</span
												>
												Access Configuration
											</a>
										</div>
									</nav>
								</div>

								<div
									class="col-12 mt-4 tab-content"
									id="nav-tabContent"
									style="min-height: 450px"
								>
									<div
										class="dashboard rounded p-4 tab-pane fade show active"
										id="nav-all-users"
										role="tabpanel"
										aria-labelledby="nav-all-users-tab"
										style="width: 80%"
									>
										<h2>Business Details</h2>
										<form>
											<div class="row mt-4">
												<!-- Company/Merchant Name -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Company/Merchant
															Name</label
														>
														<input
															type="text"
															v-model="
																business_info.company_name
															"
															class="form-control input-bordered"
															placeholder="Enter Company/Merchant Name"
															disabled
														/>
													</div>
												</div>

												<!-- RC No. -->
												<!-- <div class="col-md-6">
                          <div class="form-group">
                            <label class="input-item-label">RC Number</label>
                            <input
                              type="text"
                              v-model="business_info.rc_no"
                              class="form-control input-bordered text-uppercase"
                              placeholder="Enter RC Number"
                              disabled
                            />
                          </div>
                        </div>-->

												<!-- CAC Reg No. -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>CAC Registration
															Number</label
														>
														<input
															type="text"
															v-model="
																business_info.cac_reg_no
															"
															class="form-control input-bordered text-uppercase"
															placeholder="Enter CAC Registration Number"
															disabled
														/>
													</div>
												</div>

												<!-- Business Phone Number -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Business Phone
															Number</label
														>
														<input
															type="text"
															v-model="
																business_info.business_phone_no
															"
															class="form-control input-bordered"
															placeholder="Enter Business Phone Number"
															disabled
														/>
													</div>
												</div>

												<!-- Annual Revenue -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Annual
															Revenue</label
														>
														<input
															type="text"
															v-model="
																business_info
																	.annual_revenue
																	.annual_revenue_label
															"
															class="form-control input-bordered"
															placeholder="Select Annual Revenue"
															disabled
														/>
													</div>
												</div>

												<!-- Business Type -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Business
															Type</label
														>
														<input
															type="text"
															v-model="
																business_info
																	.business_type
																	.business_type_label
															"
															class="form-control input-bordered"
															placeholder="Select Business Type"
															disabled
														/>
													</div>
												</div>
											</div>
											<hr />
											<h2 class="mt-3">
												Business Emails
											</h2>
											<div class="row mt-4">
												<!-- Business Email -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Business
															Email</label
														>
														<input
															type="text"
															v-model="
																business_info.business_email
															"
															class="form-control input-bordered"
															placeholder="Enter Business Email"
															disabled
														/>
													</div>
												</div>

												<!-- Support Email -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Support
															Email</label
														>
														<input
															type="text"
															v-model="
																business_info.support_email
															"
															class="form-control input-bordered"
															placeholder="Enter Support Email"
															disabled
														/>
													</div>
												</div>

												<!-- Chargeback Email -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Chargeback
															Email</label
														>
														<input
															type="text"
															v-model="
																business_info.chargeback_email
															"
															class="form-control input-bordered"
															placeholder="Enter Chargeback Email"
															disabled
														/>
													</div>
												</div>
											</div>
											<hr />

											<h2 class="mt-3">
												Business Address
											</h2>
											<div class="row mt-4">
												<!-- Business Website -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Business
															Website</label
														>
														<input
															type="text"
															v-model="
																business_info.business_website
															"
															class="form-control input-bordered"
															placeholder="Enter Business Website"
															disabled
														/>
													</div>
												</div>

												<!-- Postal Code -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Postal Code</label
														>
														<input
															type="text"
															v-model="
																business_info.postal_code
															"
															class="form-control input-bordered"
															placeholder="Enter Postal Code"
															disabled
														/>
													</div>
												</div>

												<!-- Building Number -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Building
															Number</label
														>
														<input
															type="text"
															v-model="
																business_info.building_no
															"
															class="form-control input-bordered"
															placeholder="Enter Building Number"
															disabled
														/>
													</div>
												</div>

												<!-- Business Address -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Business
															Address</label
														>
														<input
															type="text"
															v-model="
																business_info.building_address
															"
															class="form-control input-bordered"
															placeholder="Enter Business Address"
															disabled
														/>
													</div>
												</div>

												<!-- City -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>City</label
														>
														<input
															type="text"
															v-model="
																business_info.city
															"
															class="form-control input-bordered"
															placeholder="Enter City"
															disabled
														/>
													</div>
												</div>

												<!-- State -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>State</label
														>
														<input
															type="text"
															v-model="
																business_info
																	.state
																	.state_label
															"
															class="form-control input-bordered"
															placeholder="Select State"
															disabled
														/>
													</div>
												</div>
											</div>
											<hr />
											<h2 class="mt-3">
												Business Contacts
											</h2>
											<div class="row mt-4">
												<!-- Primary Contact First Name -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Primary Contact
															First Name</label
														>
														<input
															type="text"
															v-model="
																business_info.primary_contact_first_name
															"
															class="form-control input-bordered"
															placeholder="Enter Primary Contact First Name"
															disabled
														/>
													</div>
												</div>
												<!-- Primary Contact Last Name -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Primary Contact
															Last Name</label
														>
														<input
															type="text"
															v-model="
																business_info.primary_contact_last_name
															"
															class="form-control input-bordered"
															placeholder="Enter Primary Contact Last Name"
															disabled
														/>
													</div>
												</div>
												<!-- Primary Contact Email -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Primary Contact
															Email</label
														>
														<input
															type="text"
															v-model="
																business_info.primary_contact_email
															"
															class="form-control input-bordered"
															placeholder="Enter Primary Contact Email"
															disabled
														/>
													</div>
												</div>
												<!-- Primary Contact Phone No. -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Primary Contact
															Phone No.</label
														>
														<input
															type="text"
															v-model="
																business_info.primary_contact_phone_no
															"
															class="form-control input-bordered"
															placeholder="Enter Primary Contact Phone No."
															disabled
														/>
													</div>
												</div>
												<!-- Secondary Contact First Name -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Secondary Contact
															First Name</label
														>
														<input
															type="text"
															v-model="
																business_info.secondary_contact_first_name
															"
															class="form-control input-bordered"
															placeholder="Enter Secondary Contact First Name"
															disabled
														/>
													</div>
												</div>
												<!-- Secondary Contact Last Name -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Secondary Contact
															Last Name</label
														>
														<input
															type="text"
															v-model="
																business_info.secondary_contact_last_name
															"
															class="form-control input-bordered"
															placeholder="Enter Secondary Contact Last Name"
															disabled
														/>
													</div>
												</div>
												<!-- Secondary Contact Mobile Telephone -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Secondary Contact
															Mobile
															Telephone</label
														>
														<input
															type="text"
															v-model="
																business_info.secondary_contact_mobile_telephone
															"
															class="form-control input-bordered"
															placeholder="Enter Secondary Contact Mobile Telephone"
															disabled
														/>
													</div>
												</div>
												<!-- Secondary Contact Office Telephone -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Secondary Contact
															Office
															Telephone</label
														>
														<input
															type="text"
															v-model="
																business_info.secondary_contact_office_telephone
															"
															class="form-control input-bordered"
															placeholder="Enter Secondary Contact Office Telephone"
															disabled
														/>
													</div>
												</div>
												<!-- Secondary Contact Email -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Secondary Contact
															Email</label
														>
														<input
															type="text"
															v-model="
																business_info.secondary_contact_email
															"
															class="form-control input-bordered"
															placeholder="Enter Secondary Contact Email"
															disabled
														/>
													</div>
												</div>
												<!-- Secondary Contact Designation -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Secondary Contact
															Designation</label
														>
														<input
															type="text"
															v-model="
																business_info.secondary_contact_designation
															"
															class="form-control input-bordered"
															placeholder="Enter Secondary Contact Designation"
															disabled
														/>
													</div>
												</div>
											</div>
											<hr />
											<h2>Social Media Links</h2>
											<div class="row mt-4">
												<!-- LinkedIn -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>LinkedIn Profile
															URL</label
														>
														<input
															type="text"
															v-model="
																socials.linkedin_url
															"
															class="form-control input-bordered"
															placeholder="Enter LinkedIn Profile URL"
															disabled
														/>
													</div>
												</div>

												<!-- Facebook -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Facebook Profile
															URL</label
														>
														<input
															type="text"
															v-model="
																socials.facebook_url
															"
															class="form-control input-bordered"
															placeholder="Enter Facebook Profile URL"
															disabled
														/>
													</div>
												</div>

												<!-- Instagram -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Instagram Profile
															URL</label
														>
														<input
															type="text"
															v-model="
																socials.instagram_url
															"
															class="form-control input-bordered"
															placeholder="Enter Instagram Profile URL"
															disabled
														/>
													</div>
												</div>

												<!-- Twitter -->
												<div class="col-md-6">
													<div class="form-group">
														<label
															class="input-item-label"
															>Twitter Profile
															URL</label
														>
														<input
															type="text"
															v-model="
																socials.twitter_url
															"
															class="form-control input-bordered"
															placeholder="Enter Twitter Profile URL"
															disabled
														/>
													</div>
												</div>
											</div>
										</form>
									</div>
									<div
										class="tab-pane fade dashboard rounded p-4"
										id="nav-transfer"
										role="tabpanel"
										aria-labelledby="nav-settings-tab"
									>
										<h2>Business Funding Source</h2>
										<form>
											<div class="row col-md-10 mt-4">
												<!-- Funding Source Options -->
												<div
													:class="{
														'col-md-12':
															showVirtualCard ==
															false,
														'col-md-6':
															showVirtualCard ==
															true,
													}"
												>
													<div class="form-group">
														<label
															class="input-item-label"
															>Funding Source
															Option</label
														>
														<v-select
															:options="[
																{
																	id: 1,
																	funding_source_label:
																		'Bank',
																},
															]"
															label="funding_source_label"
															@input="
																checkFundingOption
															"
															v-model="
																business_funding_source.funding_source_options
															"
															class="v-select"
															placeholder="Select Funding Source Option"
															:disabled="
																showVirtualCard ==
																true
															"
														></v-select>
														<!-- <input
                              type="text"
                              v-model="business_funding_source.funding_source_options"
                              class="form-control input-bordered"
                              placeholder="Select Funding Source Option"
                              disabled
                            />-->
													</div>
												</div>

												<div
													class="col-md-6"
													v-if="
														showVirtualCard === true
													"
												>
													<div
														class="card text-white mt-4"
														style="
															max-width: 28rem;
															height: 16rem;
															border-radius: 1.5em;
															background-image: linear-gradient(
																109.6deg,
																rgba(
																		9,
																		9,
																		121,
																		1
																	)
																	11.2%,
																rgba(
																		144,
																		6,
																		161,
																		1
																	)
																	53.7%,
																rgba(
																		0,
																		212,
																		255,
																		1
																	)
																	100.2%
															);
														"
													>
														<div
															class="card-header d-flex justify-content-end"
														>
															<img
																src="/assets/img/blusalt-logo-light.svg"
																height="30"
																alt
																style
															/>
														</div>
														<div class="card-body">
															<img
																src="/assets/img/chip.svg"
																alt
																height="50"
															/>
															<div>
																<p
																	style="
																		font-size: 2em;
																	"
																>
																	{{
																		business_funding_source.virtual_card_no
																	}}
																</p>
																<h5
																	class="card-title"
																>
																	Virtual Card
																	Number
																</h5>
																<!-- class="shadow" -->
															</div>
															<div
																class="d-flex justify-content-end"
															>
																<img
																	src="/assets/img/mastercard.svg"
																	alt
																	height="34"
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
									<div
										class="tab-pane fade dashboard rounded p-4"
										id="nav-billing"
										role="tabpanel"
										aria-labelledby="nav-settings-tab"
									>
										<h2>Billing</h2>
										<form @submit.prevent="editBillingType">
											<div class="row mt-4">
												<!-- Funds Transfer Billing -->
												<div class="col-md-8">
													<div class="form-group">
														<label
															class="input-item-label"
															>Funds Transfer
															Billing</label
														>
														<v-select
															:options="
																billingType
															"
															label="transfer_pricing_label"
															v-model="ft_billing"
															@input="
																changeBillingTab
															"
															class="v-select"
															placeholder="Select Funds Transfer Billing"
															disabled
														></v-select>
													</div>
												</div>
											</div>
											<div
												class="col-md-8 p-0"
												v-if="
													billingTab ===
													'amtThreshold'
												"
											>
												<v-client-table
													name="threshold-list"
													:api-mode="false"
													class="v-table hidden-xs"
													:data="thresholdBilling"
													:columns="thresholdColumns"
													:options="thresholdOptions"
												>
													<div
														slot="id"
														slot-scope="props"
														:href="props.id"
														class
													>
														<span
															v-tooltip.top-center="
																'View Transaction'
															"
															class="pointer"
															@click="
																viewSingleTransaction(
																	props.row,
																	props.index
																)
															"
														>
															<i
																class="typcn typcn-eye-outline mr-1"
															></i>
														</span>
													</div>
												</v-client-table>
											</div>
											<div
												class="col-md-4 p-0"
												v-if="
													billingTab ===
													'volumeBilling'
												"
											>
												<v-client-table
													name="billing-list"
													:api-mode="false"
													class="v-table hidden-xs"
													:data="volumeBilling"
													:columns="volumeColumns"
													:options="volumeOptions"
												></v-client-table>
											</div>
										</form>
									</div>
									<div
										class="tab-pane fade dashboard rounded p-4"
										id="nav-access-config"
										role="tabpanel"
										aria-labelledby="nav-settings-tab"
									>
										<h2>Access Configuration</h2>
										<form>
											<div class="mt-4">
												<!-- IP Address -->
												<div class="col-md-6">
													<div
														class="input-group mb-2 mr-sm-4"
													>
														<label
															class="key__label mr-4"
															>IP Address</label
														>
														<!-- <small>(IP Address to be whitelisted for access to the APIs)</small> -->
														<input
															ref="ip_address"
															type="text"
															class="form-control api__keys"
															v-model="
																access_config.ip_address
															"
															id="inlineFormInputGroupMinimumPrice1"
															placeholder="IP Address to be whitelisted for access to the APIs"
														/>
														<div
															class="input-group-prepend"
															@click="
																copyToClipboard(
																	$refs.ip_address
																)
															"
														>
															<div
																class="input-group-text"
															>
																Copy
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-6 mt-4">
													<div
														class="input-group mb-2 mr-sm-4"
													>
														<label
															class="key__label mr-4"
															>Public Key</label
														>
														<input
															ref="public_key"
															type="password"
															class="form-control api__keys"
															v-model="public_key"
															id="inlineFormInputGroupMinimumPrice1"
															placeholder="Public API Key"
														/>
														<div
															class="input-group-prepend"
															@click="
																copyToClipboard(
																	$refs.public_key
																)
															"
														>
															<div
																class="input-group-text"
															>
																Copy
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-6 mt-4">
													<div
														class="input-group mb-2 mr-sm-4 mt-2"
													>
														<label
															class="key__label mr-4"
															>Secret Key</label
														>
														<input
															ref="secret_key"
															type="text"
															class="form-control api__keys"
															v-model="secret_key"
															id="inlineFormInputGroupMinimumPrice1"
															placeholder="Secret API Key"
														/>
														<div
															class="input-group-prepend"
															@click="
																copyToClipboard(
																	$refs.secret_key
																)
															"
														>
															<div
																class="input-group-text"
															>
																Copy
															</div>
														</div>
													</div>
												</div>
												<div class="col-md-6 mt-4">
													<div
														class="input-group mb-2 mr-sm-4 mt-2"
													>
														<label
															class="key__label mr-4"
															>Terminal ID</label
														>
														<input
															ref="etz_terminal_id"
															type="text"
															class="form-control api__keys"
															v-model="
																access_config.etz_terminal_id
															"
															id="inlineFormInputGroupMinimumPrice1"
															placeholder="ETZ Terminal ID"
														/>
														<div
															class="input-group-prepend"
															@click="
																copyToClipboard(
																	$refs.etz_terminal_id
																)
															"
														>
															<div
																class="input-group-text"
															>
																Copy
															</div>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
export default {
	name: "FundsTransferProfile",
	mixins: [notifications, modalMixin],
	data() {
		return {
			bizInfo: {},
			activeTab: "",
			fundingTab: "",
			billingTab: "amtThreshold",
			accountVerification: false,
			newForm: false,
			FTServiceInfo: true,
			validated: false,
			showButton: false,
			requestFTService: false,
			initializedAppResult: {},
			versions: ["v1", "v2"],
			app: "",
			apiVersion: "",
			call_id: "",
			clientID: "",
			hasNewResponse: false,
			logData: [],
			bankList: [],
			verifyAccount: [],
			account_name: "",
			apiResponse: "",
			mandate_doc: "",
			auth_document: "",
			business_info: {
				cac_reg_no: "",
				rc_no: "",
				company_name: "",
				business_type: "",
				business_email: "",
				primary_contact_first_name: "",
				primary_contact_last_name: "",
				primary_contact_email: "",
				primary_contact_phone_no: "",
				secondary_contact_first_name: "",
				secondary_contact_last_name: "",
				secondary_contact_email: "",
				secondary_contact_designation: "",
				secondary_contact_office_telephone: "",
				secondary_contact_office_telephone: "",
				support_email: "",
				chargeback_email: "",
				business_phone_no: "",
				business_website: "",
				building_no: "",
				building_address: "",
				city: "",
				state: "",
				postal_code: "",
				annual_revenue: "",
			},
			business_funding_source: {
				funding_source_bank_code: "",
				funding_source_bank_branch: "",
				funding_source_options: "",
				funding_source_acc_no: "",
				virtual_card_no: "",
			},
			socials: {
				linkedin_url: "",
				facebook_url: "",
				twitter_url: "",
				instagram_url: "",
			},
			ft_billing: "",
			billing_charge: "",
			billing_rate: "",
			billing_price: [
				{
					ft_billing_min: "",
					ft_billing_max: "",
					ft_billing_rate: "",
				},
			],
			settings: {},
			access_config: {
				ip_address: "",
				etz_terminal_id: "",
			},
			public_key: "",
			secret_key: "",

			// services
			servicePrice: "",

			isSubmitting: false,
			showVirtualCard: false,
			verificationResult: [],
			volumeColumns: ["transfer_rate"],
			thresholdColumns: ["min_range", "max_range", "transfer_rate"],
			thresholdOptions: {
				sortable: [],
				filterable: false,
				perPage: 10,
				pagination: {
					chunk: 5,
				},
				texts: {
					filter: "",
					filterPlaceholder: "Search...",
					noResults: "No matching billings",
					count: "Showing {from} to {to} of {count} billings|{count} billings|One billing",
				},
				headings: {
					min_range: "Minimum Range (NGN)",
					max_range: "Maximum Range (NGN)",
					transfer_rate: "Transfer Rate (NGN)",
				},
			},
			volumeOptions: {
				sortable: [],
				filterable: false,
				perPage: 10,
				pagination: {
					chunk: 5,
				},
				texts: {
					filter: "",
					filterPlaceholder: "Search...",
					noResults: "No matching billings",
					count: "Showing {from} to {to} of {count} billings|{count} billings|One billing",
				},
				headings: {
					transfer_rate: "Transfer Rate(NGN)",
				},
			},
		};
	},
	computed: {
		states() {
			return this.$store.state.metaData.countries[131].states;
		},
		annualRevenue() {
			return this.$store.state.metaData.settingsFtAnnualRevenue;
		},
		businessType() {
			return this.$store.state.metaData.settingsFtBusinessType;
		},
		fundingSource() {
			return this.$store.state.metaData.settingsFundingSource;
		},
		billingType() {
			return this.$store.state.metaData.settingsPricingDefinition;
		},
		thresholdBilling() {
			return this.$store.state.metaData
				.settingsDefaultThresholdBillingRate;
		},
		volumeBilling() {
			return this.$store.state.metaData.settingsDefaultVolumeBillingRate;
		},
		ftRequestMade() {
			return this.$store.state.account.profile.is_ft_request_made;
		},
	},
	methods: {
		checkFundingOption() {
			if (
				this.business_funding_source.funding_source_options
					.funding_source_label === "Bank"
			) {
				this.fundingTab = "Bank";
			} else {
				this.fundingTab = "";
				this.showButton = true;
			}
		},
		formatNumber(number) {
			let digit = "";
			number = number.toString();
			for (let i = 0; i < number.length; i++) {
				digit += number[i];
				if (i == 3 || i == 7 || i == 11) {
					digit += " - ";
				}
			}
			return digit;
		},
		changeBillingTab() {
			if (this.ft_billing.transfer_pricing_label === "Amount Threshold") {
				this.billingTab = "amtThreshold";
			} else {
				this.billingTab = "volumeBilling";
			}
		},
		copyToClipboard(text) {
			text.select();
			text.setSelectionRange(0, 99999);
			document.execCommand("copy");
			this.copied = true;
			this.showSuccessNotification("Copied!");
			setTimeout(() => {
				this.copied = false;
			}, 1000);
		},
		async fetchKeys() {
			Nprogress.start();
			try {
				await this.$store
					.dispatch("fundstransfer/fetchKeys")
					.then((response) => {
						console.log("fetchKeys:", response);
						this.public_key = response.data.results.ft_public_key;
						this.secret_key = response.data.results.ft_secret_key;
					});
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				console.log("error :", error);
				this.showErrorNotification(error.message);
			}
		},
		async getBusinessInfo() {
			try {
				await this.$store
					.dispatch("fundstransfer/getBusinessInfo")
					.then((response) => {
						console.log("getInfo:", response);

						let apiResponse = response.data.results[0];
						apiResponse.requestApprovalSteps
							.agreed_to_terms_conditions === 1
							? (this.agreed_to_terms_conditions = true)
							: (this.agreed_to_terms_conditions = false);
						apiResponse.business_website === null
							? (this.business_info.business_website = "http://")
							: (this.business_info.business_website =
									apiResponse.business_website);
						apiResponse.ftBusinessSocialMediaInformation.pivot ===
						undefined
							? (this.socials.linkedin_url = "http://")
							: (this.socials.linkedin_url =
									apiResponse.ftBusinessSocialMediaInformation[3].pivot.social_media_link);
						apiResponse.ftBusinessSocialMediaInformation.pivot ===
						undefined
							? (this.socials.facebook_url = "http://")
							: (this.socials.facebook_url =
									apiResponse.ftBusinessSocialMediaInformation[0].pivot.social_media_link);
						apiResponse.ftBusinessSocialMediaInformation.pivot ===
						undefined
							? (this.socials.twitter_url = "http://")
							: (this.socials.twitter_url =
									apiResponse.ftBusinessSocialMediaInformation[1].pivot.social_media_link);
						apiResponse.ftBusinessSocialMediaInformation.pivot ===
						undefined
							? (this.socials.instagram_url = "http://")
							: (this.socials.instagram_url =
									apiResponse.ftBusinessSocialMediaInformation[2].pivot.social_media_link);
						console.log("linkedin:", this.socials.linkedin_url);
						apiResponse.requestApprovalSteps
							.submitted_business_info === 1
							? (this.submitted_business_info = true)
							: (this.submitted_business_info = false);
						apiResponse.requestApprovalSteps
							.submitted_authorization_document === 1
							? (this.submitted_authorization_document = true)
							: (this.submitted_authorization_document = false);
						apiResponse.requestApprovalSteps
							.submitted_mandate_document === 1
							? (this.submitted_mandate_document = true)
							: (this.submitted_mandate_document = false);
						apiResponse.requestApprovalSteps
							.submitted_funding_source_info === 1
							? (this.submitted_funding_source_info = true)
							: (this.submitted_funding_source_info = false);
						apiResponse.requestApprovalSteps
							.submitted_secondary_contact === 1
							? (this.submitted_secondary_contact = true)
							: (this.submitted_secondary_contact = false);
						apiResponse.requestApprovalSteps
							.whitelist_ip_address === 1
							? (this.whitelist_ip_address = true)
							: (this.whitelist_ip_address = false);
						console.log(
							"this.whitelist_ip_address:",
							this.whitelist_ip_address
						);

						this.business_info.company_name =
							apiResponse.apiCustomer.organization_name;
						this.business_info.rc_no = apiResponse.rc_number;
						this.business_info.cac_reg_no =
							apiResponse.cac_registration_number;
						this.business_info.business_type =
							apiResponse.businessType;
						this.business_info.annual_revenue = apiResponse.revenue;
						this.business_info.business_phone_no =
							apiResponse.business_phone_number;
						this.business_info.business_email =
							apiResponse.business_email;
						this.business_info.support_email =
							apiResponse.support_email;
						this.business_info.chargeback_email =
							apiResponse.chargeback_email;
						this.business_info.building_no =
							apiResponse.building_no;
						this.business_info.building_address =
							apiResponse.building_address;
						this.business_info.city = apiResponse.city;
						this.business_info.state = apiResponse.state;
						this.business_info.postal_code =
							apiResponse.postal_code;
						this.business_info.primary_contact_first_name =
							apiResponse.apiCustomer.primaryContact.firstname;
						this.business_info.primary_contact_last_name =
							apiResponse.apiCustomer.primaryContact.lastname;
						this.business_info.primary_contact_email =
							apiResponse.apiCustomer.primaryContact.email;
						this.business_info.primary_contact_phone_no =
							apiResponse.apiCustomer.primaryContact.phone_number;
						this.business_info.secondary_contact_first_name =
							apiResponse.secondaryContact.secondary_contact_firstname;
						this.business_info.secondary_contact_last_name =
							apiResponse.secondaryContact.secondary_contact_lastname;
						this.business_info.secondary_contact_designation =
							apiResponse.secondaryContact.secondary_contact_designation;
						this.business_info.secondary_contact_email =
							apiResponse.secondaryContact.secondary_contact_email;
						this.business_info.secondary_contact_mobile_telephone =
							apiResponse.secondaryContact.secondary_contact_mobile_telephone;
						this.business_info.secondary_contact_office_telephone =
							apiResponse.secondaryContact.secondary_contact_office_telephone;
						this.socials.linkedin_url =
							apiResponse.ftBusinessSocialMediaInformation[3].pivot.social_media_link;
						this.socials.facebook_url =
							apiResponse.ftBusinessSocialMediaInformation[0].pivot.social_media_link;
						this.socials.twitter_url =
							apiResponse.ftBusinessSocialMediaInformation[1].pivot.social_media_link;
						this.socials.instagram_url =
							apiResponse.ftBusinessSocialMediaInformation[2].pivot.social_media_link;
						this.agreed_to_terms_conditions =
							apiResponse.requestApprovalSteps.agreed_to_terms_conditions;
						this.submitted_business_info =
							apiResponse.requestApprovalSteps.submitted_business_info;
						this.submitted_authorization_document =
							apiResponse.requestApprovalSteps.submitted_authorization_document;
						this.submitted_mandate_document =
							apiResponse.requestApprovalSteps.submitted_mandate_document;
						this.submitted_funding_source_info =
							apiResponse.requestApprovalSteps.submitted_funding_source_info;
						this.submitted_secondary_contact =
							apiResponse.requestApprovalSteps.submitted_secondary_contact;
						this.whitelist_ip_address =
							apiResponse.requestApprovalSteps.whitelist_ip_address;
						this.access_config.ip_address =
							apiResponse.whitelisted_ip;
						this.access_config.etz_terminal_id =
							apiResponse.etz_terminal_id;
					});
				this.isLoading = false;
			} catch (error) {
				console.log(error);
			}
		},
		async getFundingSource() {
			try {
				await this.$store
					.dispatch("fundstransfer/getFundingSource")
					.then((response) => {
						console.log("getFunding:", response);
						// if (response.data.results > 0) {
						let apiResponse = response.data.results[0];
						this.business_funding_source.funding_source_options =
							apiResponse.businessInfo.fundingSource.funding_source_label;
						this.business_funding_source.funding_source_acc_no =
							apiResponse.funding_source_account_no;
						this.business_funding_source.funding_source_bank_branch =
							apiResponse.funding_source_bank_branch;
						this.business_funding_source.funding_source_bank_code =
							apiResponse.funding_source_bank_id;
						// this.business_funding_source.virtual_card_no = apiResponse.virtual_card_no;
						this.business_funding_source.virtual_card_no =
							this.formatNumber(apiResponse.virtual_card_no);
						this.showVirtualCard = true;
						// } else {
						//   return response
						// }
						this.checkFundingOption();
					});
				// this.$refs.uploadInput.display = "none";
				this.isLoading = false;
			} catch (error) {
				console.log(error);
			}
		},
		async getBillingType() {
			try {
				await this.$store
					.dispatch("fundstransfer/getBillingType")
					.then((response) => {
						console.log("getBillingType:", response);
						let apiResponse = response.data.results;
						if (apiResponse.length > 0) {
							this.ft_billing = apiResponse[0].billingOption;
							this.changeBillingTab();
						}
					});
				this.requestFTService = true;
				this.isLoading = false;
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		await this.getBusinessInfo();
		await this.fetchKeys();
		await this.getFundingSource();
		await this.getBillingType();
	},
};
</script>

<style scoped>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
	display: none !important;
}
.v-select.vs__dropdown-toggle {
	padding: 2px 15px !important;
}
.v-table.hidden-xs.VueTables.VueTables--client .VuePagination nav {
	display: none !important;
}
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
	background-color: transparent !important;
}
.form-control:disabled {
	background-color: transparent !important;
}
.input-bordered {
	border: none;
	/* border-bottom: 1px solid #d2dde9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
	/* padding-left: 0; */
}
.key__label {
	line-height: 30px;
	font-size: 14px;
	font-weight: 700;
	margin-top: 4px;
}
</style>
