<template>
	<Modal
		:id="id"
		:title="taxData ? 'Update Tax' : 'Create Tax'"
		:toggle="handleCloseModal"
	>
		<SuccessState
			v-if="successMsg"
			:message="
				taxData
					? 'Tax Updated Successfully'
					: 'Tax Created Successfully'
			"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row h-100">
			<div class="col-12">
				<CustomInput
					:name="'name'"
					v-model="request.name"
					@blur="$v.request.name.$touch()"
					:label="'Name'"
					:placeholder="'Enter a name'"
					:required="true"
					:error="
						formErrors.name ||
						($v.request.name.$error &&
							formVuelidator($v.request.name))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomSelect
					name="tax_type"
					label="Tax Type"
					:optionLabel="'name'"
					:options="[{ name: 'VAT', value: 'vat' }]"
					v-model="request.tax_type"
					@close="$v.request.tax_type.$touch()"
					:required="true"
					:error="
						formErrors.tax_type ||
						($v.request.tax_type.$error &&
							formVuelidator($v.request.tax_type))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomSelect
					name="country"
					label="Country"
					:optionLabel="'name'"
					placeholder="Select Country"
					:options="[{ name: 'Nigeria', value: 'nigeria' }]"
					v-model="request.country"
					@close="$v.request.country.$touch()"
					:required="true"
					:error="
						formErrors.country ||
						($v.request.country.$error &&
							formVuelidator($v.request.country))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'rate'"
					type="number"
					v-model="request.rate"
					@blur="$v.request.rate.$touch()"
					:label="'Enter Rate (%)'"
					:placeholder="'Enter Rate (%)'"
					:required="true"
					:max="100"
					:error="
						formErrors.rate ||
						($v.request.rate.$error &&
							formVuelidator($v.request.rate))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomSelect
					name="type"
					:optionLabel="'name'"
					label="Calculation Type"
					placeholder="Select Tax Variant"
					:options="taxVariant"
					v-model="request.type"
					@close="$v.request.type.$touch()"
					:required="true"
					:error="
						formErrors.type ||
						($v.request.type.$error &&
							formVuelidator($v.request.type))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import {
	notifications,
	formActions,
	componentData,
} from "../../../../../../public/assets/mixins";
import { required, maxValue } from "vuelidate/lib/validators";

const taxVariant = [
	{ name: "Included", value: "included" },
	{ name: "Excluded", value: "excluded" },
];

const initialState = () => ({
	request: {
		tax_type: { name: "VAT", value: "vat" },
		name: "",
		country: { name: "Nigeria", value: "nigeria" },
		rate: "",
		type: "",
	},
	loading: false,
	successMsg: false,
	formErrors: {},
	taxVariant,
});

export default {
	name: "CreateTaxModal",
	mixins: [notifications, componentData, formActions],
	data() {
		return { ...initialState(), tax: {} };
	},
	props: ["id", "closeModal", "taxRef", "taxData", "refetch"],
	validations: {
		request: {
			tax_type: {
				required,
			},
			name: {
				required,
			},
			country: {
				required,
			},
			rate: {
				required,
				maxValue: maxValue(100),
			},
			type: {
				required,
			},
		},
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault();
			this.$v.$touch();
			const { formErrors, isValid } = this.formValidator(this.request);
			if (isValid && !this.$v.$invalid)
				this.taxData ? this.updateTax() : this.createTax();
			else this.formErrors = formErrors;
		},
		handleRefetch() {
			this.handleCloseModal();
			this.taxRef && this.taxRef.refresh();
			this.refetch && this.refetch(this.tax);
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
			this.setTaxData();
		},
		async createTax() {
			try {
				this.loading = true;
				const { country, tax_type, type } = this.request || {};

				const response = await this.$store.dispatch("tax/createTax", {
					apiKey: this.getWalletApikey(this.$store),
					request: {
						...this.request,
						country: country.value,
						tax_type: tax_type.value,
						type: type.value,
					},
				});

				const { status, data } = response || {};

				if (status === 200 && data.status) {
					this.loading = false;
					this.successMsg = true;
					this.tax = data.data;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
		async updateTax() {
			try {
				this.loading = true;
				const { country, tax_type, type } = this.request || {};

				const response = await this.$store.dispatch("tax/updateTax", {
					apiKey: this.getWalletApikey(this.$store),
					id: this.taxData.id,
					request: {
						...this.request,
						country: country.value,
						tax_type: tax_type.value,
						type: type.value,
					},
				});

				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
		setTaxData() {
			if (this.taxData) {
				this.request = {
					...this.request,
					name: this.taxData.name,
					rate: this.taxData.rate,
					type: taxVariant.find(
						(val) => val.value === this.taxData.type
					),
				};
			}
		},
	},

	mounted() {
		this.setTaxData();
	},
};
</script>
