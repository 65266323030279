<template>
	<div>
		<div v-if="loading" class="flex__center mt-5 pt-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="() => getChargeBack(routeParam.id)"
		/>

		<div class="p-md-4 p-3" v-else>
			<div class="my-4">
				<router-link
					:to="{ name: 'chargebacks' }"
					class="mr-2 text-secondary"
				>
					Chargeback
				</router-link>
				&lt;
				<router-link to="" class="ml-2 text-primary">
					Chargeback Information
				</router-link>
			</div>
			<div class="">
				<div
					class="SectionItem no-b-b mb-3 d-flex flex-wrap justify-content-between"
				>
					<span class="title d-flex mb-3">
						<router-link
							:to="{ name: 'chargebacks' }"
							class="nav-sub-link"
						>
							<img
								src="/assets/img/icons/arrow-back.svg"
								alt="image"
								height="30"
							/>
						</router-link>
						<span class="ml-2"> Chargeback Information </span>
					</span>

					<div
						class="d-flex"
						style="gap: 10px"
						v-if="chargeback && chargeback.status !== 'ACCEPTED'"
					>
						<ChallengeChargeBtn
							v-if="
								!(
									chargeback &&
									chargeback.status === 'CHALLENGED'
								)
							"
							:chargeback="chargeback"
							:refetch="() => getChargeBack(routeParam.id)"
						/>

						<AcceptChargebackBtn
							:chargeback="chargeback"
							:refetch="() => getChargeBack(routeParam.id)"
						/>
					</div>
				</div>
			</div>
			<div
				v-if="
					(countdown || countdown !== '----') &&
					chargeback.status !== 'ACCEPTED'
				"
				class="my-3 banner bg-warning-light rounded text-warning p-2"
			>
				<span>
					<img
						src="/assets/img/icons/warning.svg"
						alt="image"
						width="13"
						height="13"
						class="mr-2"
					/>
				</span>

				<span>
					Payment will be reversed automatically if not treated within
					the time limit

					<span class="value text-danger ml-1">
						{{ countdown }}
					</span>
				</span>
			</div>
			<div class="row shadow-container">
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">CHARGEBACK REFERENCE</span>
					<span class="value">{{ chargeback.reference }}</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">AMOUNT</span>
					<span class="value">
						{{
							chargeback.Transaction &&
							chargeback.Transaction.amount
								? toCurrency(
										chargeback.Transaction.amount,
										chargeback.Transaction.currency
								  )
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">STATUS</span>
					<div class="value">
						<StatusPill :status="chargeback.status" />
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">TRANSACTION REFERENCE</span>
					<span class="value">{{
						chargeback.transaction_reference
					}}</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">TRANSACTION DATA</span>
					<span class="value">
						{{
							chargeback.data &&
							chargeback.data.data &&
							chargeback.data.data.pan
								? chargeback.data.data.pan
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">RRN</span>
					<span class="value">
						{{
							chargeback.data &&
							chargeback.data.data &&
							chargeback.data.data.rrn
								? chargeback.data.data.rrn
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">CLIENT REFERENCE</span>
					<span class="value">
						{{
							chargeback.Transaction &&
							chargeback.Transaction.client_reference
								? chargeback.Transaction.client_reference
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">CREATED AT</span>
					<span class="value">
						{{
							chargeback.createdAt
								? `${
										timeDateFormat(chargeback.createdAt)
											.date
								  } ${
										timeDateFormat(chargeback.createdAt)
											.time
								  }`
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">DUE AT</span>
					<span class="value">
						{{
							chargeback.due_at
								? `${timeDateFormat(chargeback.due_at).date} ${
										timeDateFormat(chargeback.due_at).time
								  }`
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">UPDATED AT</span>
					<span class="value">
						{{
							chargeback.updatedAt
								? `${
										timeDateFormat(chargeback.updatedAt)
											.date
								  } ${
										timeDateFormat(chargeback.updatedAt)
											.time
								  }`
								: "Not Available"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">TIME REMAINING</span>
					<span class="value text-danger">
						{{
							chargeback.status !== "ACCEPTED"
								? countdown
								: "----"
						}}
					</span>
				</div>
				<div class="col-12 col-md-6 col-lg-4 d-flex flex-column mb-4">
					<span class="title-key">REASON</span>
					<span class="value">
						{{
							chargeback.reason_code
								? `${chargeback.reason_code} - ${
										reasons[chargeback.reason_code]
								  }`
								: "Not Available"
						}}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reasons } from "../helpers/enums";
import Spinner from "@/utils/Spinner/Spinner";
import FailedState from "@/utils/ResponseState/FailedState";
import { toCurrency, timeDateFormat } from "@/utils/helpers";
import { getWalletApikey } from "@/utils/helpers";
import StatusPill from "@/utils/StatusPill/StatusPill.vue";
import ChallengeChargeBtn from "../buttons/ChallengeChargeBtn/ChallengeChargeBtn.vue";
import AcceptChargebackBtn from "../buttons/AcceptChargebackBtn/AcceptChargebackBtn.vue";

export default {
	name: "ChargeBackDetailContent",
	data() {
		return {
			reasons,
			routeParam: {},
			loading: false,
			errorMsg: "",
			countdown: null,
			chargeback: {},
		};
	},
	components: {
		Spinner,
		FailedState,
		StatusPill,
		ChallengeChargeBtn,
		AcceptChargebackBtn,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		toCurrency,
		timeDateFormat,
		startCountdown() {
			var interval = setInterval(() => {
				var dueDate = new Date(this.chargeback.due_at).getTime();
				var now = new Date().getTime();
				var timeDiff = dueDate - now;

				if (timeDiff <= 0) {
					clearInterval(interval);
					this.countdown = "----";
				} else {
					var days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
					var hours = Math.floor(
						(timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
					);
					var minutes = Math.floor(
						(timeDiff % (1000 * 60 * 60)) / (1000 * 60)
					);
					var seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

					this.countdown = `${days
						.toString()
						.padStart(2, "0")}:${hours
						.toString()
						.padStart(2, "0")}:${minutes
						.toString()
						.padStart(2, "0")}:${seconds
						.toString()
						.padStart(2, "0")}`;
				}
			}, 1000);
		},
		async getChargeBack(reference) {
			try {
				this.loading = true;
				this.errorMsg = "";
				const response = await this.$store.dispatch(
					"wallets/getChargeBack",
					{
						apiKey: getWalletApikey(this.$store),
						reference,
					}
				);
				if (
					(response.status === 200 || response.status === 201) &&
					response.data.status === true
				) {
					this.chargeback = response.data.data;
				} else this.errorMsg = response.data.message;

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
	},

	async mounted() {
		const { id } = this.$route.params;
		this.routeParam = { id };
		this.getChargeBack(id);
		this.startCountdown();
	},
};
</script>

<style scoped>
.title-key {
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	color: #828282;
	margin-bottom: 8px;
}

.value {
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: left;
	color: #071b2b;
}

.banner {
	color: #e49b0c;
}

.shadow-container {
	margin: 0;
	padding: 15px 20px;
	box-shadow: 0px 4px 25px 0px #0000000a;
}
</style>
