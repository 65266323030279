
import axios from "axios";
import API, { apiClient } from "./api";
let coreBaseUrl = process.env.VUE_APP_BASE_URL_CORE;

export default {
  AmlLookUp(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(`Aml/Sanctions`, applicationDetails.payload, {
      headers,
    });
  },
  getBillersCategory() {
    return apiClient.get("Authorize/list-billers-categories");
  },
  getBettingProviders() {
    return apiClient.get("Authorize/list-betting-providers");
  },
  validateBettingProvider(payload) {
    return apiClient.post("Authorize/validate-betting-provider", payload);
  },
  topUpBettingAccount(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      "BillPayment/BettingTopup",
      applicationDetails.payload,
      { headers }
    );
  },
  getBillerServices(payload) {
    return apiClient.post("Authorize/list-billers-category-services", payload);
  },
  validateCableTV({ payload }) {
    return apiClient.post("Authorize/Validate-cabletv", payload);
  },
  getCableTVPlans({ payload }) {
    return apiClient.post("Authorize/cabletv-plans", payload);
  },
  getInternetPlans({ payload }) {
    return apiClient.post("Authorize/list-internet-plans", payload);
  },
  validateElectricity({ payload }) {
    return apiClient.post("Authorize/Validate-electricity", payload);
  },
  GetBillers(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Billers`, { headers });
  },
  ValidateBiller(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(`Billers/validate`, applicationDetails.payload, {
      headers,
    });
  },
  payBill({ payload, headers }) {
    return apiClient.post(`Billers/pay`, payload, {
      headers,
    });
  },
  BVNVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/BVN${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  CACVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `BusinessInformation/CAC/Advance${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  BasicCACVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `BusinessInformation/CAC/Basic${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  verifyTIN(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `BusinessInformation/TIN${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  MobileCarrierVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `CustomerInformation/verifyMobileCarrier${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  AdvancedMobileCarrierVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `CustomerInformation/verifyMobileCarrierAdvanced${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  VerifyMobileNumber(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `Onboarding/Kyc/VerifyMobileNumber${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  MobileCarrierBulkVerification(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `CustomerInformationBulk/verifyMobileCarrierBulk`,
      applicationDetails.payload,
      { headers }
    );
  },
  VerifyGhanaVoterId(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/Ghana-VID${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  VerifyGhanaInternationalPassport(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/Ghana-PID${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  VerifyGhanaDriversLicence(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/Ghana-COC${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  VerifyGhanaSsnit(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/Ghana-FSSNO${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  SingleSmsSender({ headers, payload, params }) {
    return apiClient.post("Messaging/Sms", payload, {
      params,
      headers,
    });
  },
  BulkBVNVerification(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/BVN-Upload${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.bvnData,
      { headers }
    );
  },
  BulkBVNWithImageVerification(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/BulkImageBVN${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.bvnData,
      { headers }
    );
  },
  BVNWithImageVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/iBVN${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  PVCVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/PVC${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  NINVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/NIN${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  NINByMsisdnVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/pNIN${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  NINByMsisdnVerificationBasic(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/pNIN-Basic${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  DLVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/DriverLicence${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  NIPVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/NIP${query ? "?bundle_call_id=" + query : query}`,
      applicationDetails.payload,
      { headers }
    );
  },
  addressVerification(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/Address${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  numberToBankListing(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/NumberToBankListing${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  bankAccountVerify(applicationDetails) {
    let query = applicationDetails.query;
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/BankAccountVerify${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.payload,
      { headers }
    );
  },
  comparePhoto(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/FacialComparison${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.imagesData,
      { headers }
    );
  },
  OCRValidation(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/OCRValidation${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.imagesData,
      { headers }
    );
  },
  verifyVNIN(verificationData) {
    let query = verificationData.query;
    let headers = {
      appname: verificationData.appname,
      apikey: verificationData.apikey,
      clientid: verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/vNIN${query ? "?bundle_call_id=" + query : query}`,
      verificationData.payload,
      { headers }
    );
  },
  OCRVerification(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/OCR`,
      applicationDetails.formData,
      { headers }
    );
  },
  DocumentCapture(applicationDetails) {
    let query = applicationDetails.verificationData.query;
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };

    return apiClient.post(
      `IdentityVerification/DocumentCapture${
        query ? "?bundle_call_id=" + query : query
      }`,
      applicationDetails.imagesData,
      { headers }
    );
  },
  // Generate OTP
  SendOTP({ headers, payload }) {
    return apiClient.post("Otp/send", payload, { headers });
  },
  // Verify OTP
  verifyOTP({ headers, payload }) {
    return apiClient.post("Otp/verify", payload, { headers });
  },
  // Bulk NIN
  BulkNINVerification(applicationDetails) {
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/NIN-Bulk`,
      applicationDetails.ninData,
      { headers }
    );
  },
  verifypNINBasicBulk(applicationDetails) {
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/pNIN-Basic-Bulk`,
      applicationDetails.ninData,
      { headers }
    );
  },
  // Bulk NIN Mobile
  BulkNINVerificationMobile(applicationDetails) {
    let headers = {
      appname: applicationDetails.verificationData.appname,
      apikey: applicationDetails.verificationData.apikey,
      clientid: applicationDetails.verificationData.clientID,
    };
    return apiClient.post(
      `IdentityVerification/BulkNinMsisdn`,
      applicationDetails.ninData,
      { headers }
    );
  },
  // Vend Airtime
  airtimeVend(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `BillPayment/AirtimeTopup`,
      applicationDetails.payload,
      { headers }
    );
  },
  // Get Data Plans
  getDataPlans(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
      Authorization: `Bearer ${applicationDetails.token}`,
    };
    return apiClient.post(
      `Authorize/ListDataBundles`,
      applicationDetails.payload,
      { headers }
    );
  },
  // Top Up Data
  topData(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(`BillPayment/DataTopup`, applicationDetails.payload, {
      headers,
    });
  },
  // Get Config Data
  setMessagingConfig(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Messaging/fetchConfig`, { headers });
  },
  // Get Config Data for Sender ID
  getMessagingConfig(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID,
      appname: applicationDetails.appname,
    };
    return apiClient.get(`Authorize/Messaging/fetchConfig`, { headers });
  },
  fetchSMSConfig(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Messaging/Sms/config`, { headers });
  },

  fetchBanks() {
    const token = localStorage.getItem("salt-token");
    return apiClient.get(`Authorize/Kyc/get-bank`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  nubanOnboardingVerification({ headers, payload }) {
    return apiClient.post(`Onboarding/Kyc/NubanVerify`, payload, {
      headers,
    });
  },

  binVerification({ headers, payload }) {
    return apiClient.post(`Onboarding/Kyc/Bin-lookUp`, payload, {
      headers,
    });
  },

  bvnMatching({ headers, payload }) {
    return apiClient.post(`Onboarding/Kyc/verifySingleBVN`, payload, {
      headers,
    });
  },
  bodyOutline({ headers, payload }) {
    return apiClient.post("IdentityVerification/BodyOutline", payload, {
      headers,
    });
  },
  liveness({ headers, payload }) {
    return apiClient.post("IdentityVerification/LivenessCheck", payload, {
      headers,
    });
  },
  livenessAdvanced({ headers, payload }) {
    return apiClient.post(
      "IdentityVerification/LivenessCheckAdvanced",
      payload,
      {
        headers,
      }
    );
  },
  phoneNumberVerifStandard({ headers, payload }) {
    return apiClient.post(
      `customerinformation/verifyMobileCarrierStandard`,
      payload,
      {
        headers,
      }
    );
  },
  verifyKenyaInternationalPassport({ headers, payload }) {
    return apiClient.post(
      `IdentityVerification/KenyaPassportID`,
      payload,
      {
        headers,
      }
    );
  },
  verifyKenyaNationalID({ headers, payload }) {
    return apiClient.post(
      `IdentityVerification/KenyaNationalID`,
      payload,
      {
        headers,
      }
    );
  },
  getReport({ params }) {
    return apiClient.get("Authorize/VerificationReport", { params });
  },
  getReportDetails({ params }) {
    return apiClient.get(`Authorize/VerificationReportDetails/${params.id}`, {
      params,
    });
  },
  downloadReportDetails({ params }) {
    return apiClient.get(`Authorize/DownloadCACReport/${params.id}`, {
      params,
    });
  },
  deleteReport({ params }) {
    return apiClient.delete(`Authorize/DeleteVerificationReport/${params.id}`, {
      params,
    });
  },
  getReportAPIDocs() {
    return apiClient.get("Authorize/ApiDocument/DocumentationLink");
  },
  getReportAPIWebhooks() {
    return apiClient.get("Authorize/ApiDocument/GetVerificationWebhook");
  },
  updateReportAPIWebhooks(payload) {
    return apiClient.put(
      "Authorize/ApiDocument/UpdateVerificationWebhook",
      payload
    );
  },
};
