<template>
	<Modal
		:id="id"
		:title="'Add Bank Account'"
		:toggle="handleCloseModal"
		class="text-left"
	>
		<ResolveAccountForm
			v-if="currentState === 'IDLE'"
			:loading="loading"
			:closeModal="handleCloseModal"
			:formSubmit="requestOtp"
		/>

		<div v-if="currentState === 'OTP'">
			<WalletOTP
				:goBack="goBack"
				:loading="loading"
				:value="otp"
				:handleChange="handleOtp"
				:handleSubmit="handleSubmit"
			/>
		</div>

		<SuccessState
			v-if="currentState === 'SUCCESS'"
			:message="'Bank Added Successfully'"
			:onSuccess="handleRefetch"
		/>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../utils/Modal/Modal";
	import WalletOTP from "../WalletOTP/WalletOTP";
	import ResolveAccountForm from "../../../common/ResolveAccountForm/ResolveAccountForm";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import { getWalletApikey } from "../../../../../utils/helpers";

	const initialState = () => ({
		loading: false,
		otp: "",
		currentState: "IDLE",
		request: {},
	});

	export default {
		mixins: [notifications],
		name: "AddBankModal",
		data() {
			return { ...initialState() };
		},
		props: ["id", "closeModal", "refetch"],
		components: {
			Modal,
			WalletOTP,
			ResolveAccountForm,
			SuccessState,
		},
		computed: {
			user() {
				return this.$store.state.account.profile;
			},
		},
		methods: {
			handleOtp(otp) {
				this.otp = otp;
			},
			goBack() {
				this.otp = "";
				this.currentState = "IDLE";
			},
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleRefetch() {
				this.handleCloseModal();
				this.refetch();
			},
			async requestOtp(data) {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"wallets/requestOtp",
						{
							apiKey: getWalletApikey(this.$store),
						}
					);
					if (response.status === 200) {
						this.loading = false;
						this.request = { ...data };
						this.currentState = "OTP";
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},

			async handleSubmit() {
				try {
					this.loading = true;
					const { bank, account_number } = this.request || {};
					const response = await this.$store.dispatch(
						"wallets/addBank",
						{
							apiKey: getWalletApikey(this.$store),
							payload: {
								account_number,
								bank: bank.name,
								bank_code: bank.code,
								otp: this.otp,
							},
						}
					);

					if (response.status === 201) {
						this.loading = false;
						this.currentState = "SUCCESS";
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
