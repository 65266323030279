<template>
	<div
		class="tab-pane fade pt-4"
		id="notification_settings"
		role="tabpanel"
		aria-labelledby="notification_settings-tab"
	>
		<ul class="p-0">
			<li class="flex__between">
				<h5>Would you like to use OTP on wallet creation?</h5>
				<toggle-button
					v-model="request.wallet_creation_otp"
					color="#006848"
					:value="request.wallet_creation_otp"
					:sync="true"
					:labels="{
						checked: 'On',
						unchecked: 'Off',
					}"
				/>
			</li>
			<li class="flex__between">
				<h5>Would you like to use OTP for Transfers?</h5>
				<toggle-button
					v-model="request.transfer_otp"
					color="#006848"
					:value="request.transfer_otp"
					:labels="{
						checked: 'On',
						unchecked: 'Off',
					}"
					:sync="true"
				/>
			</li>
			<li class="flex__between">
				<h5>
					Would you like to turn on/off the option to receive email
					notifications for Transactions?
				</h5>
				<toggle-button
					v-model="request.email_integration"
					color="#006848"
					:value="request.email_integration"
					:sync="true"
					:labels="{
						checked: 'On',
						unchecked: 'Off',
					}"
				/>
			</li>
      <li class="flex__between">
				<h5>
					Would you like to receive receipts?
				</h5>
				<toggle-button
					v-model="request.send_receipt_to_merchant"
					color="#006848"
					:value="request.send_receipt_to_merchant"
					:sync="true"
					:labels="{
						checked: 'On',
						unchecked: 'Off',
					}"
				/>
			</li>
      <li class="flex__between">
				<h5>
					Would you like for customers to receive receipts?
				</h5>
				<toggle-button
					v-model="request.send_receipt_to_customer"
					color="#006848"
					:value="request.send_receipt_to_customer"
					:sync="true"
					:labels="{
						checked: 'On',
						unchecked: 'Off',
					}"
				/>
			</li>

			<button
				class="btn btn-lg btn-primary mt-4 float-right px-4"
				:disabled="loading"
				@click="handleSubmit"
			>
				<Spinner v-if="loading" />
				<span v-else>Save Changes</span>
			</button>
		</ul>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Spinner from "../../../../utils/Spinner/Spinner";
import { getWalletApikey } from "../../../../utils/helpers";

export default {
	name: "NotificationSettings",
	mixins: [notifications],
	data() {
		return {
			loading: false,
			request: {
				wallet_creation_otp: this.settings.wallet_creation_otp,
				transfer_otp: this.settings.transfer_otp,
				email_integration: this.settings.email_integration,
				send_receipt_to_customer: this.settings.send_receipt_to_customer,
				send_receipt_to_merchant: this.settings.send_receipt_to_merchant,
			},
		};
	},
	props: ["settings"],
	components: {
		Spinner,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async handleSubmit() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/merchantToggleSettings",
					{
						apiKey: getWalletApikey(this.$store),
						request: this.request,
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.showSuccessNotification(response.data.message);
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		console.log(this.settings);
	},
};
</script>

<style scoped>
li {
	border-bottom: 1px solid #efefef;
	padding: 15px 0;
}
li h5 {
	margin: 0;
	padding-right: 15px;
}
</style>
