<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div v-else class="p-md-4 p-3">
			<div class="flex__between">
				<div class="SectionItem no-b-b mb-3">
					<p class="title">Remittance</p>
				</div>
			</div>
			<div class="flex__end">
				<buttonn
					@click="$router.push('/wallet/remittance/send-money')"
					class="btn btn-sm btn--primary px-4"
					>Send Money</buttonn
				>
			</div>
			<div class="content__slider">
				<TabsV2
					:tabNames="tabNames"
					:tabId="'subscriptionTab'"
					:tabLink="true"
				/>
			</div>

			<transition mode="out-in" name="slide">
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import SplitContent from "../../../utils/SplitContent/SplitContent";
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import TabsV2 from "../../../utils/Tabs/TabsV2";

const tabNames = [
	{
		tabName: "Transaction History",
		value: "transaction_history",
		link: "/wallet/remittance",
	},
	{
		tabName: "Senders",
		value: "senders",
		link: "/wallet/remittance/senders",
	},
	{
		tabName: "Beneficiaries",
		value: "beneficiaries",
		link: "/wallet/remittance/beneficiaries",
	},
	{
		tabName: "Saved Transactions",
		value: "saved_transactions",
		link: "/wallet/remittance/saved-transactions",
	},
	// {
	//   tabName: "Reversed Transactions",
	//   link: "/wallet/remittance/reversed-transactions",
	// },
];

export default {
	name: "Remittance",
	components: {
		TabsV2,
		SplitContent,
		EmptyWallet,
	},
	data() {
		return {
			tabNames,
		};
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
