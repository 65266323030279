
import { subscriptionAPI } from "@/services/api";

export default {
	getAllTrails({ apiKey, params }) {
		return subscriptionAPI.get(`/trail`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getTrail({ apiKey, id }) {
		return subscriptionAPI.get(`/trail/${id}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
