<template>
	<Modal :id="id" :title="title" :toggle="closeModal" :loading="loading">
		<SuccessState
			v-if="successMsg"
			:message="'Key Changed Successfully'"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="handleSubmit">
			<h4 class="text-center my-4">
				Are you sure you wanted to change this key ?
			</h4>

			<FormButtons
				:loading="loading"
				:btnName="'Yes, Continue'"
				:handleCloseModal="closeModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import FormButtons from "../../../../../utils/FormButtons/FormButtons";
import { getWalletApikey } from "../../../../../utils/helpers";
import Modal from "../../../../../utils/Modal/Modal";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import Spinner from "../../../../../utils/Spinner/Spinner";

const initialState = () => ({
	loading: false,
	successMsg: false,
});

export default {
	mixins: [notifications],
	name: "ChangeKeyModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "title", "keyType", "closeModal", "data"],
	components: { Modal, SuccessState, Spinner, FormButtons },
	methods: {
		handleSubmit() {
			const { environment, webhook } = this.data || {};
			if (this.keyType === "webhook")
				this.updateWebhookFxn(webhook, environment);
			else this.changeKey(this.keyType);
		},
		handleRefetch() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		async updateWebhookFxn(webhook, environment) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/updateWebHook",
					{
						apiKey: getWalletApikey(this.$store),
						params: {
							environment: environment ? "live" : "test",
						},
						webhook,
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async changeKey(key) {
			try {
				const { environment } = this.data || {};
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/changeKey",
					{
						params: {
							[key]: true,
							environment: environment ? "live" : "test",
						},
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
