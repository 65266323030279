<template>
	<div class="az-content-box">
		<div class="az-content-left">
			<div class="az-services-menu">
				<!-- Customer Information -->
				<div
					id="accordion"
					class="accordion"
					role="tablist"
					aria-multiselectable="true"
				>
					<div
						class="card-header panel-heading"
						role="tab"
						id="headingFour"
					>
						<a
							data-toggle="collapse"
							href="#collapseFour"
							aria-expanded="true"
							aria-controls="collapseFour"
						>
							<label
								class="az-content-label az-content-label-sm"
								style="cursor: pointer"
								>Customer Information</label
							>
						</a>
					</div>

					<TabSwitch
						:tab="tab"
						:title="'Phone Number Verification'"
						:tabNumber="12"
						:tabswitch="tabswitch"
					/>

					<TabSwitch
						:tab="tab"
						:title="'Phone Number Verification (Standard)'"
						:tabNumber="29"
						:tabswitch="tabswitch"
					/>

					<TabSwitch
						:tab="tab"
						:title="'Phone Number Verification (Bulk)'"
						:tabNumber="13"
						:tabswitch="tabswitch"
					/>

					<TabSwitch
						:tab="tab"
						:title="'Bank Account Verification'"
						:tabNumber="10"
						:tabswitch="tabswitch"
					/>

					<TabSwitch
						:tab="tab"
						:title="'Know Your Customer (Basic)'"
						:tabNumber="14"
						:tabswitch="tabswitch"
					/>

					<TabSwitch
						:tab="tab"
						:title="'Know Your Customer (Advance)'"
						:tabNumber="7"
						:tabswitch="tabswitch"
					/>

					<TabSwitch
						:tab="tab"
						:title="'BIN Verification'"
						:tabNumber="24"
						:tabswitch="tabswitch"
					/>
				</div>
				<!-- End Customer-Info ---->
			</div>
		</div>

		<loader v-if="isProcessing == true"></loader>

		<div class="az-content-body tab-content" v-else>
			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<MobileCarrierVerificationService
					:application="application"
					:services="apps.customerServices"
					v-if="tab == 12"
				>
				</MobileCarrierVerificationService>
			</transition>

			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<PhoneNumberVerificationStandard
					:application="application"
					:services="apps.customerServices"
					v-if="tab == 29"
				/>
			</transition>
			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<MobileCarrierVerificationServiceBulk
					:application="application"
					:services="apps.customerServices"
					v-if="tab == 13"
				></MobileCarrierVerificationServiceBulk>
			</transition>
			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<AdvancedMobileCarrierVerificationService
					:application="application"
					:services="apps.customerServices"
					v-if="tab == 14"
				>
				</AdvancedMobileCarrierVerificationService>
			</transition>
			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<BankAccountService
					:application="application"
					:services="apps.customerServices"
					v-if="tab == 10"
				>
				</BankAccountService>
			</transition>

			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<AdvancedBankAccountVerification v-if="tab == 7" />
			</transition>
			<transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<BINVerification v-if="tab == 24"></BINVerification>
			</transition>

			<!-- <transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			>
				<NumberToBankService
					:application="application"
					:services="apps.customerServices"
					v-if="tab == 7"
				></NumberToBankService>
			</transition> -->

			<DefaultContent
				v-if="!profile.is_active"
				:image="'empty-resources.png'"
				:title="`Seems you've not verified your account.`"
				:subtitle="'Verify your account to start testing out the different services. Contact the Admin to verify your account'"
			></DefaultContent>
			<DefaultContent
				v-if="profile.is_active && application.length == 0"
				:image="'empty-resources.png'"
				:title="'No Application Created'"
				:subtitle="'You currently do not have any active application, Please create one start testing services'"
			>
				<router-link class="btn btn-md btn-primary" to="/applications">
					<i class="la la-plus"></i> Create Application
				</router-link>
			</DefaultContent>
		</div>
	</div>
</template>

<script>
import MobileCarrierVerificationService from "@/components/services/MobileCarrierService.vue";
import AdvancedMobileCarrierVerificationService from "@/components/services/AdvancedMobileCarrierService";
import PhoneNumberVerificationStandard from "@/components/services/CustomerInformation/PhoneNumberVerificationStandard/PhoneNumberVerificationStandard";
import MobileCarrierVerificationServiceBulk from "@/components/services/MobileCarrierServiceBulk";
import BankAccountService from "@/components/services/BankAccountService";
import AdvancedBankAccountVerification from "@/components/services/CustomerInformation/AdvancedBankAccountVerification/AdvancedBankAccountVerification";
import BINVerification from "@/components/services/CustomerInformation/BINVerification/BINVerification";
import TabSwitch from "../components/services/common/TabSwitch/TabSwitch";

export default {
	components: {
		BankAccountService,
		AdvancedBankAccountVerification,
		MobileCarrierVerificationService,
		PhoneNumberVerificationStandard,
		AdvancedMobileCarrierVerificationService,
		MobileCarrierVerificationServiceBulk,
		BINVerification,
		TabSwitch,
	},
	computed: {
		profile() {
			return this.$store.state.account.profile.api_customer;
		},
		userRole() {
			return this.$store.state.account.profile.roles[0].role_label;
		},
	},
	data() {
		return {
			tab: 12,
			apps: {},
			application: [],
			isProcessing: true,
		};
	},
	methods: {
		tabswitch(tab) {
			window.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			if (tab) {
				this.tab = tab;
				this.$router.replace({ query: { tab: this.tab } }).catch(() => {});
			} else {
				this.tab = 1;
			}
		},

		async fetchApps() {
			try {
				const app = await this.$store.dispatch(
					"application/getApplications"
				);
				this.apps = app;
				let applications = app.applications;
				let filteredApplication = [];
				if (applications.length > 0) {
					filteredApplication = applications.filter(
						(app) => app.is_active == 1
					);
				}
				// console.log('[filtered] ->', filteredApplication);
				this.application = filteredApplication;

				this.isProcessing = false;
			} catch (error) {
				console.log("[errors getting applications] ->", error);
				this.isProcessing = false;
			}
		},
	},
	async mounted() {
		if (this.$route.query.tab) {
			let tab = this.$route.query.tab;
			this.tabswitch(tab);
		}
		this.fetchApps();
	},
};
</script>
<style scoped>
.accordion .card-header a {
	padding: 14px 0px !important;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
	color: #212229;
	background-color: transparent !important;
}

.accordion .card-header a.collapsed {
	background-color: transparent !important;
}

.panel-heading a:before {
	content: "\002B";
	float: right;
	transition: all 0.5s;
}

.panel-heading a.collapsed:before {
	content: "\2212" !important;
	float: right;
	transition: all 0.5s;
}
</style>
