<template>
	<Modal :id="id" :title="'Remove Account'" :toggle="closeModal">
		<form @submit.prevent="handleSubmit">
			<h4 class="text-center my-4">
				Remove Account From Transfer List
			</h4>

			<FormButtons
				:btnName="'Remove'"
				:primary="false"
				:handleCloseModal="closeModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import Modal from "../../../../utils/Modal/Modal";
	import FormButtons from "../../../../utils/FormButtons/FormButtons";

	export default {
		name: "DeleteFTAccountModal",
		props: ["id", "account", "closeModal", "removeAccount"],
		components: { Modal, FormButtons },
		methods: {
			handleSubmit() {
				this.removeAccount(this.account.row);
				this.closeModal();
			},
		},
	};
</script>
