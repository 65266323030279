import reservedAccounts from "@/services/wallets/reservedAccounts";

export default {
	namespaced: true,
	actions: {
		async getReservedAccounts({}, payload) {
			return await reservedAccounts.getReservedAccounts(payload);
		},
		async getReservedAccount({}, payload) {
			return await reservedAccounts.getReservedAccount(payload);
		},
		async getReservedAccountTransactions({}, payload) {
			return await reservedAccounts.getReservedAccountTransactions(
				payload
			);
		},
		async createReservedAccount({}, payload) {
			return await reservedAccounts.createReservedAccount(payload);
		},
	},
};
