<template>
  <AuthWrapper>
    <div class="card-login">
      <h2>Seems like you forgot something, lets help you get it back.</h2>
      <h4></h4>

      <form @submit.prevent="resetPassord">
        <!-- Email -->
        <div class="form-group">
          <label class="input-item-label">Email</label>
          <input
            type="email"
            name="email"
            class="form-control input-bordered"
            placeholder="Enter your email address"
            v-model.trim="email"
          />
          <ul class="parsley-errors-list filled" v-show="$v.email.$error">
            <li class="parsley-required">Email is required.</li>
          </ul>
        </div>

        <!-- New Password -->
        <div class="form-group">
          <label class="input-item-label">New Password</label>
          <input
            type="password"
            name="password"
            class="form-control input-bordered"
            placeholder="Enter your new password"
            v-model.trim="password"
          />
          <ul class="parsley-errors-list filled" v-show="$v.password.$error">
            <li class="parsley-required">password is required.</li>
          </ul>
        </div>

        <div class="gaps-2x"></div>
        <button
          type="submit"
          class="btn btn-lg btn-primary btn-block mg-b-20"
          :disabled="isProcessing == true"
        >
          <span v-if="isProcessing">Attempting to reset password</span>
          <span v-else>Reset Password</span>
        </button>
        <p>
          Already have an account?
          <router-link to="/">Login</router-link>
        </p>
      </form>
    </div>
  </AuthWrapper>
</template>

<script>
import Nprogress from "nprogress"
import { required } from 'vuelidate/lib/validators';
import AuthWrapper from '@/components/auth/AuthWrapper';
import { notifications } from '../../public/assets/mixins/notifications';

export default {
  name: "Password-Reset",
  mixins: [notifications],
  components: {
    AuthWrapper
  },
  props: {
    token: {
      type: String
    }
  },
  data() {
    return {
      email: '',
      password: '',
      isProcessing: false
    }
  },
  validations: {
    email: {
      required
    },
    password: {
      required
    }
  },
  methods: {
    async resetPassord() {
      if (this.$v.$invalid) return
      console.log('[clicked] ->');
      Nprogress.start();
      this.isProcessing = true;

      let credential = {
        "email": this.email,
        "password": this.password,
        'token': this.token
      }
      try {
        let apiResponse = await this.$store.dispatch('auth/passwordReset', credential)
        console.log('[apiResponse of login] ->', apiResponse);
        Nprogress.done();
        this.showSuccessNotification(apiResponse.message)
        // this.email = ''
        let self = this;
        setTimeout(() => {
          this.isProcessing = false;
          this.$router.push('/')
        }, 2500);
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false;
        console.log('[] ->', error.response);
        this.showErrorLoginNotification(error.message)
      }
    }
  }
};
</script>
