<template>
	<EmptyWallet v-if="!merchant" />

	<div v-else class="az-content-body py-5">
		<div class="SectionItem no-b-b mb-3 flex__between">
			<p class="title">Preferences</p>
		</div>
		<div class="w-100">
			<!-- nav tabs -->
			<div class="content__slider">
				<TabsV2 :tabNames="tabNames" :tabId="'preferenceTab'" />
			</div>
			<div
				class="mt-4 tab-content"
				id="nav-tabContent"
				style="min-height: 450px"
			>
				<div
					class="tab-pane fade api__key show active"
					id="api_keys_and_webhooks"
					role="tabpanel"
					aria-labelledby="api_keys_and_webhooks-tab"
				>
					<div
						class="d-flex align-items-center flex-md-nowrap flex-wrap"
					>
						<label class="text-nowrap mb-3">Public Key</label>
						<div class="flex__center w-100">
							<CustomInput
								name="publicKey"
								v-model="request.publicKey"
								type="password"
								:disabled="true"
								:icon="
									request.publicKey
										? '/assets/img/icons/copy.svg'
										: ''
								"
								:iconClick="() => copy(request.publicKey)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CustomInput from "@/utils/CustomInput/CustomInput";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import { copyToClipboard } from "@/utils/helpers";
import ChangeKeyButton from "@/components/wallet/Preferences/buttons/ChangeKeyButton/ChangeKeyButton.vue";
import EmptyWallet from "@/components/wallet/common/EmptyWallet/EmptyWallet.vue";
import TabsV2 from "@/utils/Tabs/TabsV2";

const tabNames = [
	{
		tabName: "API Keys",
		value: "api_keys_and_webhooks",
	},
];

export default {
	mixins: [notifications, modalMixin],
	data() {
		return {
			tabNames,
			apiResponse: "",
			loading: false,
			verificationResult: [],
			copied: false,
			environment: process.env.NODE_ENV !== "development",
			request: {
				transfer_otp: false,
				...this.setKey(process.env.NODE_ENV !== "development"),
			},
		};
	},
	components: {
		EmptyWallet,
		CustomInput,
		TabsV2,
		ChangeKeyButton,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		setKey(newVal) {
			const keyValues = this.$store.state.account.customerKeys;

			const { publicKey } = keyValues || {};
			const { testPublicKey, apiKey, testApiKey } = keyValues || {};

			const keys = {
				publicKey: newVal ? publicKey : testPublicKey,
				apiKey: newVal ? apiKey : testApiKey,
			};
			this.request = { ...this.request, ...keys };
			return keys;
		},
		copy(value) {
			const copied = copyToClipboard(value);
			copied && this.showSuccessNotification("API Key Copied!");
		},
	},
	watch: {
		environment(newVal) {
			this.setKey(newVal);
		},
	},
	mounted() {
		this.setKey(this.environment);
	},
};
</script>
<style scoped>
.input-group-text {
	cursor: pointer;
}
.api__keys {
	overflow: hidden;
	text-overflow: ellipsis;
}
.key__label {
	line-height: 30px;
	font-size: 14px;
	font-weight: 700;
}
</style>
