<template>
	<Modal
		:id="id"
		:title="'Add Account'"
		:toggle="handleCloseModal"
		class="text-left"
	>
		<ResolveAccountForm
			v-if="step === 1"
			:closeModal="handleCloseModal"
			:formSubmit="next"
		/>

		<form
			v-if="step === 2"
			@submit.prevent="handleFormSubmit"
			class="h-100"
		>
			<CustomInput
				:name="'amount'"
				v-model="request.amount"
				:label="'Enter Amount'"
				:placeholder="'Enter amount to fund'"
				:required="true"
				:isNum="true"
				:error="formErrors.amount"
				:handleError="handleError"
			/>

			<CustomInput
				:name="'narration'"
				v-model="request.narration"
				:label="'Narration'"
				:placeholder="'Narration'"
				:required="true"
				:error="formErrors.narration"
				:handleError="handleError"
			/>

			<FormButtons
				:loading="loading"
				:btnName="'Add Transfer'"
				:closeBtnName="'Back'"
				:handleCloseModal="goBack"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../utils/Modal/Modal";
	import ResolveAccountForm from "../../../wallet/common/ResolveAccountForm/ResolveAccountForm";
	import CustomInput from "../../../../utils/CustomInput/CustomInput";
	import FormButtons from "../../../../utils/FormButtons/FormButtons";
	import { formValidator } from "../../../../utils/FormValidator/FormValidator";
	import { getCSVFileObject, getWalletPublickey } from "../../../../utils/helpers";

	const initialState = () => ({
		loading: false,
		step: 1,
		request: {
			amount: "",
			narration: "",
		},
		formErrors: {},
	});

	export default {
		mixins: [notifications],
		name: "AddMoreAccountModal",
		data() {
			return { ...initialState() };
		},
		props: ["id", "closeModal", "accountFile", "updateAccountList"],
		components: {
			Modal,
			ResolveAccountForm,
			CustomInput,
			FormButtons,
		},
		methods: {
			next(data) {
				this.request = { ...this.request, ...data };
				this.step += 1;
			},
			goBack() {
				this.step -= 1;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleFormSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) this.validateData(this.request);
				else this.formErrors = formErrors;
			},
			validateData({ account_number, amount }) {
				const exist = this.accountFile.find(
					(v) =>
						v.accountNumber === account_number &&
						parseFloat(v.amount) === parseFloat(amount)
				);
				if (exist)
					this.showErrorLoginNotification("Account Already Exist!!!");
				else {
					this.verifiyAccounts();
				}
			},
			async verifiyAccounts() {
				try {
					this.loading = true;
					const file = getCSVFileObject([
						{
							accountNumber: this.request.account_number,
							accountName: this.request.account_name,
							bankCode: this.request.bank.code,
							narration: this.request.narration,
							amount: this.request.amount,
						},
					]);
					const formData = new FormData();
					formData.append("file", file);

					const response = await this.$store.dispatch(
          "fundstransfer/verifyAccountsBulk",
          {
            formData,
            headers: {
              clientid:
                this.$store.state.account.clientID ||
                localStorage.getItem("local-ID"),
              publickey: getWalletPublickey(this.$store),
            },
          }
        );
					const { status, data } = response;

					if (status === 200 && data.status) {
						this.loading = false;
						this.updateAccountList([
							...data.data,
							...this.accountFile,
						]);
						this.handleCloseModal();
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(
						"Error Adding Account. Try Again !!!"
					);
				}
			},
		},
	};
</script>
