<template>
	<div>
		<EmptyWallet v-if="!user.merchant" />

		<template v-else>
			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getWallet(routeParam.reference)"/>

			<SplitContent
				v-else
				:showSide="Boolean(wallet.Customer)"
				:prevRoute="'/wallets'"
			>
				<template slot="sideview">
					<WalletDetailSideView :wallet="wallet" />
				</template>

				<template slot="content">
					<WalletDetialsContent
						:wallet="wallet"
						:refetch="() => getWallet(routeParam.reference)"
					/>
				</template> </SplitContent
		></template>
	</div>
</template>

<script>
	import WalletDetialsContent from "./WalletDetialsContent/WalletDetialsContent";
	import WalletDetailSideView from "./WalletDetailSideView/WalletDetailSideView";
	import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
	import Spinner from "../../../../utils/Spinner/Spinner";
	import FailedState from "../../../../utils/ResponseState/FailedState";
	import SplitContent from "../../../../utils/SplitContent/SplitContent";
	import { getWalletApikey } from "../../../../utils/helpers";
	import "./WalletDetails.css";

	export default {
		name: "WalletDetails",
		data() {
			return {
				routeParam: {},
				loading: false,
				errorMsg: "",
				wallet: {},
			};
		},
		components: {
			WalletDetialsContent,
			WalletDetailSideView,
			SplitContent,
			EmptyWallet,
			Spinner,
			FailedState,
		},
		computed: {
			user() {
				return this.$store.state.account.profile;
			},
		},
		methods: {
			async getWallet(reference) {
				try {
					this.loading = true;
					this.errorMsg = "";
					const response = await this.$store.dispatch(
						"wallets/getWallet",
						{
							apiKey: getWalletApikey(this.$store),
							reference,
						}
					);
					if (response.status === 200) {
						this.loading = false;
						this.wallet = response.data.data[0];
					}
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
		},

		async mounted() {
			const { reference } = this.$route.params;
			this.routeParam = { reference };
			this.getWallet(reference);
		},
	};
</script>
