<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="SectionItem">
          <div class="SectionHeader">
            <span class="title">Payout</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01"
                    >Filter Payouts By:</label
                  >
                </div>
                <select class="custom-select" id="inputGroupSelect01">
                  <option value="1">None</option>
                  <option value="2">Status</option>
                  <option value="3">Pay Channel</option>
                  <option value="3">Date</option>
                  <option value="3">Period</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-3">
            <!-- <div class="form-group">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01"
                    >Status:</label
                  >
                </div>
                <select class="custom-select" id="inputGroupSelect01">
                  <option value="1">None</option>
                  <option value="1">Failed</option>
                  <option value="2">Pending</option>
                  <option value="3">Successful</option>
                </select>
              </div>
            </div> -->
            <!-- <div class="form-group">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01"
                    >Period:</label
                  >
                </div>
                <select class="custom-select" id="inputGroupSelect01">
                  <option value="1">None</option>
                  <option value="1">This week</option>
                  <option value="2">This month</option>
                  <option value="3">This year</option>
                </select>
              </div>
            </div> -->
          </div>
          <div class="col-3">
            <!-- <date-picker
              :date="startTime"
              :option="option"
              :limit="limit"
            ></date-picker> -->
          </div>
          <div class="col-2">
            <p class="text-right">
              <button type="button" class="btn themed-button'">Export</button>
            </p>
          </div>
        </div>
      </div>

      <div class="col-12">
        <v-client-table :data="tableData" :columns="columns" :options="options">
          <template slot="createdAt" slot-scope="props">
            <div>
              {{ props.row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
            </div>
          </template>
        </v-client-table>
      </div>
    </div>
  </div>
</template>
<script>
import { notifications } from "../../../public/assets/mixins/notifications";
import myDatepicker from "vue-datepicker";
export default {
  mixins: [notifications],
  components: {
    "date-picker": myDatepicker,
  },
  data() {
    return {
      activityTypes: ["none", "create-mandate", "request", "validate-otp"],
      withdrawStep: 1,
      loading: false,
      isSubmitting: false,
      verifiedAccountName: false,
      loading: false,
      columns: [
        "Reference",
        "Currency",
        "Amount",
        "Customer",
        "Channel",
        "Paid On",
      ],
      options: {
        sortable: [],
        perPage: 10,
        pagination: {
          chunk: 5,
        },
        headings: {
          reference: "Reference",
          currency: "Currency",
          amount: "Amount",
          customer: "Customer",
          transaction_type: "Transaction Type",
          createdAt: "Paid On",
        },
        perPageValues: [],
        filterByColumn: true,
      },
      tableData: [],
      startTime: {
        time: "",
      },
      endTime: {
        time: "",
      },
      option: {
        type: "day",
        week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        format: "YYYY-MM-DD",
        placeholder: "Start Date",
        inputStyle: {
          display: "inline-block",
          padding: "6px",
          "line-height": "32px",
          "font-size": "16px",
          border: "2px solid #fff",
          "box-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
          "border-radius": "2px",
          color: "#5F5F5F",
        },
        color: {
          header: "#ccc",
          headerText: "#f00",
        },
        buttons: {
          ok: "Ok",
          cancel: "Cancel",
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true, // as true as default
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
  methods: {
    async getPaymentCollections() {
      try {
        const collectionResponse = (
          await this.$store.dispatch("wallets/getPaymentCollections", {
            apiKey: this.user.merchant.api_key,
          })
        ).data;
        console.log("collectionResponse", collectionResponse);
      } catch (error) {}
    },
  },
  async mounted() {
    this.getPaymentCollections();
  },
};
</script>
<style scoped>
.charts {
  padding: 20px;
  /* background-color: #cccbcb;
  border: 1px solid black; */
}
.user-account-info {
  background-color: #ededed;
  height: 100%;
}
.charts svg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.graphs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard-flex {
  padding: 0;
  /* background: #081b2a; */
  border: 1px solid #cccbcb;
  border-radius: 5px;
  color: black;
}
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 120px;
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
}

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  color: #2e6091;
  padding-top: 8px;
  margin-bottom: 2px;
}
.call-name {
  font-size: 13px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>