<template>
  <Modal :id="id" :title="'Add Director'" :toggle="handleCloseModal">
    <SuccessState
      v-if="successMsg"
      :message="'Director Created Successfully'"
      :onSuccess="handleSuccessCloseModal"
    />

    <form v-else @submit.prevent="handleSubmit" class="row h-100">
      <div class="col-sm-6">
        <CustomInput
          :name="'first_name'"
          v-model="director.first_name"
          @blur="$v.director.first_name.$touch()"
          :label="'First Name'"
          :placeholder="'Enter First Name'"
          :error="
            $v.director.first_name.$error &&
            formVuelidator($v.director.first_name)
          "
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'last_name'"
          v-model="director.last_name"
          @blur="$v.director.last_name.$touch()"
          :label="'Last Name'"
          :placeholder="'Enter Last Name'"
          :error="
            $v.director.last_name.$error &&
            formVuelidator($v.director.last_name)
          "
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'middle_name'"
          v-model="director.middle_name"
          :label="'Middle Name'"
          :placeholder="'Enter Middle Name'"
        />
      </div>
      <div class="col-sm-6">
        <DatePicker
          :name="'date_of_birth'"
          :label="'Date of Birth'"
          v-model="director.date_of_birth"
          :placeholder="'Date of Birth'"
          :error="
            $v.director.date_of_birth.$error &&
            formVuelidator($v.director.date_of_birth)
          "
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'email'"
          v-model="director.email"
          @blur="$v.director.email.$touch()"
          :label="'Email'"
          :placeholder="'Enter Email'"
          :error="$v.director.email.$error && formVuelidator($v.director.email)"
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <CustomPhoneInput
          label="Phone Number"
          v-model="director.mobile"
          @blur="$v.mobile.$touch()"
          placeholder="Enter your phone number"
          :required="true"
          :error="
            $v.director.mobile.$error && formVuelidator($v.director.mobile)
          "
        />
      </div>
      <div class="col-sm-6">
        <CountrySelect
          label="Country"
          :optionLabel="'name'"
          v-model="director.country"
          @blur="$v.director.country.$touch()"
          placeholder="Select your country"
          :required="true"
          :error="
            $v.director.country.$error && formVuelidator($v.director.country)
          "
        />
      </div>
      <div class="col-sm-6">
        <CustomSelect
          name="state"
          label="State"
          :optionLabel="'state_label'"
          placeholder="Select State"
          :options="states"
          v-model="director.state"
          :required="true"
          :error="$v.director.state.$error && formVuelidator($v.director.state)"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'city'"
          v-model="director.city"
          @blur="$v.director.city.$touch()"
          :label="'City'"
          :placeholder="'Enter City'"
          :error="$v.director.city.$error && formVuelidator($v.director.city)"
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'zip'"
          v-model="director.zip"
          :label="'Zip Code'"
          :placeholder="'Enter Zip Code'"
        />
      </div>
      <div v-if="director.country.name === 'Nigeria'" class="col-sm-6">
        <CustomInput
          :name="'bvn'"
          v-model="director.bvn"
          @blur="$v.director.bvn.$touch()"
          :label="'BVN'"
          :placeholder="'Enter BVN'"
          :error="$v.director.bvn.$error && formVuelidator($v.director.bvn)"
          :required="true"
        />
      </div>
      <div class="col-12">
        <CustomInput
          :name="'address'"
          v-model="director.address"
          @blur="$v.director.address.$touch()"
          :label="'Address 1'"
          :placeholder="'Enter Address'"
          :error="
            $v.director.address.$error && formVuelidator($v.director.address)
          "
          :required="true"
        />
      </div>
      <div class="col-12">
        <CustomInput
          :name="'second_address'"
          v-model="director.second_address"
          :label="'Address 2'"
          :placeholder="'Enter Address'"
        />
      </div>

      <div class="col-sm-6">
        <CustomSelect
          name="id_type"
          label="ID Type"
          :optionLabel="'label'"
          placeholder="Select ID type"
          :options="[
            {
              label: 'National ID',
              value: 'national_id',
            },
            {
              label: 'Passport',
              value: 'passport',
            },
            { label: 'Driver License', value: 'driver_license' },
          ]"
          v-model="director.id_type"
          :required="true"
          :error="
            $v.director.id_type.$error && formVuelidator($v.director.id_type)
          "
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'id_number'"
          :placeholder="'ID Number'"
          :label="'ID Number'"
          v-model="director.id_number"
          :required="true"
          :error="
            $v.director.id_number.$error &&
            formVuelidator($v.director.id_number)
          "
        />
      </div>

      <div class="col-sm-6">
        <CustomUpload
          :name="'director_id'"
          :label="'ID Document'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="(e) => handleUpload('director_id', e)"
          :fileName="director.director_id && director.director_id.name"
          :error="
            ($v.director.director_id.$error &&
              formVuelidator($v.director.director_id)) ||
            (uploadError.director_id && 'File size must be less than 5MB')
          "
          :required="true"
        />
      </div>

      <div class="col-sm-6">
        <CustomUpload
          :name="'director_address_proof'"
          :label="'Proof of Address'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="(e) => handleUpload('director_address_proof', e)"
          :fileName="
            director.director_address_proof &&
            director.director_address_proof.name
          "
          :error="
            ($v.director.director_address_proof.$error &&
              formVuelidator($v.director.director_address_proof)) ||
            (uploadError.director_address_proof &&
              'File size must be less than 5MB')
          "
          :required="true"
        />
      </div>

      <template
        v-if="
          director.id_type.value && director.id_type.value !== 'national_id'
        "
      >
        <div class="col-md-6">
          <DatePicker
            :name="'issue_date'"
            :label="'Date Issued'"
            v-model="director.issue_date"
            :placeholder="'Date Issued'"
            :error="
              $v.director.issue_date.$error &&
              formVuelidator($v.director.issue_date)
            "
            :required="true"
          />
        </div>
        <div class="col-md-6">
          <DatePicker
            :label="'Expiry Date'"
            :name="'expiry_date'"
            v-model="director.expiry_date"
            :placeholder="'Expiry Date '"
            :error="
              $v.director.expiry_date.$error &&
              formVuelidator($v.director.expiry_date)
            "
            :required="true"
          />
        </div>
      </template>

      <div class="col-12">
        <div class="flex__between pt-4">
          <button
            type="button"
            class="btn btn-outline w-100 mr-3"
            :disabled="loading"
            @click="handleCloseModal"
          >
            <span>Close</span>
          </button>
          <button
            type="submit"
            class="btn btn-lg w-100 ml-3 btn--primary"
            :disabled="loading"
          >
            <Spinner v-if="loading" :color="'#fff'" />
            <span v-else>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import {
  alpha,
  email,
  numeric,
  required,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import Modal from "@/utils/Modal/Modal.vue";
import Spinner from "@/utils/Spinner/Spinner.vue";
import DatePicker from "@/utils/DatePicker/DatePicker";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";
import CustomInput from "@/utils/CustomInput/CustomInput.vue";
import SuccessState from "@/utils/ResponseState/SuccessState.vue";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomSelect from "@/utils/CustomSelect/CustomSelect.vue";
import CountrySelect from "@/utils/CountrySelect/CountrySelect.vue";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput.vue";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { timeDateFormat, getPhoneNumber } from "@/utils/helpers";

const initialState = {
  director: {
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    bvn: "",
    address: "",
    mobile: "",
    country: "",
    second_address: "",
    state: "",
    city: "",
    zip: "",
    id_number: "",
    id_type: "",
    date_of_birth: "",
    director_address_proof: "",
    director_id: "",
  },
  uploadError: {
    director_id: false,
    director_address_proof: false,
  },
  loading: false,
  successMsg: false,
};

export default {
  props: ["id", "closeModal"],
  mixins: [notifications],
  data() {
    return {
      states: [],
      ...initialState,
    };
  },
  validations: {
    director: {
      first_name: { required, alpha },
      last_name: { required, alpha },
      email: { required, email },
      mobile: { required, isPhoneValid: isPhoneValid("director.mobile") },
      bvn: {
        required: requiredIf(function () {
          return this.director.country.name === "Nigeria";
        }),
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      date_of_birth: { required },
      id_type: { required },
      id_number: { required },
      city: { required },
      country: { required },
      state: { required },
      address: { required },
      issue_date: {
        required: requiredIf(function () {
          return this.director.id_type.value !== "national_id";
        }),
      },
      expiry_date: {
        required: requiredIf(function () {
          return this.director.id_type.value !== "national_id";
        }),
      },
      director_address_proof: { required },
      director_id: { required },
    },
  },
  components: {
    Modal,
    Spinner,
    CustomInput,
    SuccessState,
    CountrySelect,
    CustomSelect,
    CustomPhoneInput,
    DatePicker,
    CustomUpload,
  },
  methods: {
    formVuelidator,
    handleUpload(name, { target: { files } }) {
      const file = files[0];
      const dataSize = 1024 * 1024;

      if (file.size / dataSize < 5) {
        this.director[name] = file;
        this.uploadError[name] = false;
      } else {
        this.uploadError[name] = true;
      }
    },
    handleSuccessCloseModal() {
      this.$emit("refresh");
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    handleCloseModal() {
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    async handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.loading = true;

        const director = { ...this.director };

        if (director.country.name !== "Nigeria") {
          delete director.bvn;
        }

        if (director.id_type.value === "national_id") {
          delete director.issue_date;
          delete director.expiry_date;
        } else {
          director.issue_date = timeDateFormat(
            director.issue_date,
            "YYYY-MM-DD"
          ).otherDate;
          director.expiry_date = timeDateFormat(
            director.expiry_date,
            "YYYY-MM-DD"
          ).otherDate;
        }

        if (!director.middle_name) {
          delete director.middle_name;
        }

        if (!director.second_address) {
          delete director.second_address;
        }

        if (!director.zip) {
          delete director.zip;
        }

        director.country = director.country.name;
        director.id_type = director.id_type.value;
        director.state = director.state.state_label;
        director.date_of_birth = timeDateFormat(
          director.date_of_birth,
          "YYYY-MM-DD"
        ).otherDate;
        director.mobile = getPhoneNumber(director.mobile);

        const payload = new FormData();

        payload.append("directors", JSON.stringify([{ ...director }]));
        payload.append("director_id_image", director.director_id);
        payload.append(
          "director_address_proof",
          director.director_address_proof
        );

        const response = await this.$store.dispatch(
          "account/addCompliance",
          payload
        );

        if (response.status) {
          this.loading = false;
          this.successMsg = true;
        }
      } catch (error) {
        this.loading = false;
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  watch: {
    "director.country": function (newVal) {
      this.states = newVal.states;
      this.director.state = "";
    },
  },
};
</script>
