<template>
	<div class="m-3">
		<div class="SectionItem no-b-b mb-4 justify-content-start">
			<img
				src="/assets/img/icons/arrow-left.svg"
				width="30"
				alt=""
				class="pointer"
				@click="$router.go(-1)"
			/>
			<p class="title ml-3 mb-0">Card</p>
		</div>

		<Tabs :tabNames="tabNames" :tabId="'theCardTab'" />

		<div class="tab-content" id="myCardContent">
			<CardDetails />
			<CardSpendingLimit />
		</div>
	</div>
</template>

<script>
	import Tabs from "../../../../utils/Tabs/Tabs";
	import CardDetails from "./CardDetails/CardDetails";
	import CardSpendingLimit from "./CardSpendingLimit/CardSpendingLimit"

	const tabNames = ["Card_detials", "Spending_limit", "Transaction_pin"];

	export default {
		name: "Card",
		data() {
			return {
				tabNames,
			};
		},
		components: {
			Tabs,
			CardDetails,
			CardSpendingLimit
		},
	};
</script>
