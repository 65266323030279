import auth from "@/services/auth";
import { apiClient } from "@/services/api";
import account from "@/services/account";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

async function loginHook({ commit, dispatch }, result) {
	const token = result.data.results.token;
	apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	localStorage.setItem("salt-token", token);
	commit("SET_TOKEN", token);

	let user = await dispatch("account/getProfile", null, { root: true });
	let role = user.roles[0].role_label;

	console.log("[user role] ->", role);
	localStorage.setItem("saltState", role);
	// Check if role is Admin, If yes, clear all data and reload
	if (role == "Blusalt Admin") {
		localStorage.clear();
		apiClient.defaults.headers.common["Authorization"] = `Bearer `;
		throw Error("Something went wrong, you're not authorized");
	}
	return result;
}
export default {
	namespaced: true,
	state: {
		accounts: [],
		token: "",
	},
	mutations: {
		ALL_ACCOUNTS(state) {},
		SET_TOKEN(state, token) {
			state.token = token;
		},
	},
	actions: {
		async registerUser({ commit }, userDetails) {
			try {
				let result = await auth.customerRegistration(userDetails);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async registerInviteUser({ commit }, userDetails) {
			try {
				return await auth.inviteUserRegistration(userDetails);
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async registerExternalUser({ commit }, userDetails) {
			try {
				let result = await auth.externalRegistration(userDetails);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async whitelabelCustomerRegistration({ commit }, userDetails) {
			try {
				let result = await auth.whitelabelCustomerRegistration(
					userDetails
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async loginUserOtp({ commit, dispatch }, userDetails) {
			try {
				let result = await auth.authenticateUserOtp(userDetails);
				return await loginHook({ commit, dispatch }, result);
			} catch (e) {
				if (!error.response) {
					throw Error(error);
				} else {
					throw Error(error.response.data.message);
				}
			}
		},
		async loginUser({ commit, dispatch }, userDetails) {
			try {
				let result = await auth.authenticateUser(userDetails);

				if (
					result.data["status_code"] == 200 &&
					result.data["action"] === "otp"
				) {
					return result;
				} else if (result.data["status_code"] == 200) {
					return await loginHook({ commit, dispatch }, result);
				}
			} catch (error) {
				console.log("[error] ->", error);
				if (!error.response) {
					throw Error(error);
				} else {
					throw Error(error.response.data.message);
				}
			}
		},
		async initiatePasswordReset({ commit }, userDetails) {
			try {
				let { data } = await auth.SendPasswordResetLink(userDetails);
				return data;
			} catch (error) {
				console.log("[error] ->", error);
				throw Error(error.response.data.message);
			}
		},
		async passwordReset({ commit }, userDetails) {
			try {
				let { data } = await auth.ForgotPassword(userDetails);
				return data;
			} catch (error) {
				console.log("[error] ->", error);
				throw Error(error.response.data.message);
			}
		},
		async activateAccount({ commit }, token) {
			try {
				let { data } = await auth.ActivateAccount(token);
				return data.results;
			} catch (error) {
				console.log("[error] ->", error);
				throw Error(error.response.data.message);
			}
		},

		async getInviteEmail({ commit, dispatch }, token) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await auth.getInviteEmail(token);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},

		async adminRegisterCustomer({ commit }, userDetails) {
			return await auth.AdminRegisterCustomer(userDetails);
		},

		async customerEmailVerification({ commit }, payload) {
			return await auth.customerEmailVerification(payload);
		},

		async getCustomerDetails({ commit }, userDetails) {
			try {
				let result = await auth.getCustomerDetails(userDetails);
				return result;
			} catch (error) {
				if (error.data) {
					throw error.data;
				}
				throw error.response.data.message;
			}
		},
	},

	getters: {},
};
