<template>
	<section>
		<div class="mb-3 border-bottom">
			<div class="d-flex align-items-center justify-content-start mb-2">
				<img
					v-if="successMsg"
					src="/assets/img/icons/arrow-left.svg"
					width="30"
					alt=""
					class="pointer mr-3"
					@click="goBack"
				/>
				<p class="user-profile-name m-0">Liveness Check</p>
			</div>

			<div class="flex__between pb-3">
				<h5 class="user-profile-email sub m-0">
					Verify customer liveness
				</h5>
				<PricesAndAccess
					serviceLabel="Liveness Check"
					:getServiceStatus="getServiceStatus"
				/>
			</div>
		</div>

		<form
			v-if="!successMsg"
			@submit.prevent="handleSubmit"
			class="row pt-3"
		>
			<div class="col-12">
				<CustomPhoneInput
					label="Phone Number"
					v-model="request.phoneNumber"
					@blur="$v.request.phoneNumber.$touch()"
					placeholder="Enter your phone number"
					:error="
						$v.request.phoneNumber.$error &&
						formVuelidator($v.request.phoneNumber)
					"
				/>
			</div>

			<div class="col-md-12">
				<div class="form-group">
					<label class="input-item-label">Application</label>
					<span
						class="text-danger pl-1"
						title="This field is required"
						>*</span
					>
					<v-select
						class="v-select"
						placeholder="Select Application"
						@keydown.enter.native.prevent
						label="app_name"
						:options="application"
						v-model="request.app"
						@close="$v.request.app.$touch()"
					/>
					<ul
						class="parsley-errors-list filled"
						v-if="$v.request.app.$error"
					>
						<li
							class="parsley-required"
							v-if="!$v.request.app.required"
						>
							Application is required.
						</li>
					</ul>
				</div>
			</div>

			<div class="col-md-6 mt-4">
				<label class="input-item-label">
					Select an API Version
					<span class="text-danger" title="This field is required"
						>*</span
					>
				</label>
				<v-select
					class="v-select"
					placeholder="Select an API version"
					v-model="apiVersion"
					@close="$v.apiVersion.$touch()"
					@keydown.enter.native.prevent
					:required="!apiVersion"
					:disabled="!versions"
					:options="versions"
				></v-select>
				<ul
					class="parsley-errors-list filled"
					v-show="$v.apiVersion.$error"
				>
					<li class="parsley-required">
						API version field is required.
					</li>
				</ul>
			</div>

			<!-- Currency Type  -->
			<div class="col-md-6 mt-4">
				<label class="input-item-label"> Select Currency Type </label>
				<v-select
					class="v-select"
					placeholder="Select an Currency Type"
					v-model="currencyType"
					@keydown.enter.native.prevent
					:disabled="!customerWallets"
					:options="customerWallets"
					label="currency"
				>
				</v-select>
			</div>

			<div v-if="hasService" class="col-sm-12 mt-4">
				<button
					type="submit"
					class="btn btn-lg btn-primary"
					:disabled="loading"
				>
					<Spinner v-if="loading" />
					<span v-else>Liveness check</span>
				</button>
			</div>
		</form>

		<!-- <div class="row justify-content-center" v-else>
      <div class="col-sm-9 text-center">
        <div class="text-center align-items-center card p-4 mb-0">
          <h5 class="mb-4">
            The request has been completed Successfully, See the response below
          </h5>
          <button class="btn btn-lg btn-primary w-50" @click.prevent="goBack">
            Done
          </button>
        </div>
      </div>
    </div> -->

		<transition mode="out-in" name="fade">
			<LivenessModal
				:key="NaN"
				@close="handleClose"
				@confirm="(video) => handleStream(video)"
				v-if="showModal"
			/>
		</transition>

		<div class="user-settings-section" v-if="hasNewResponse">
			<div class="user-settings-section-header">
				<div class="user-settings-section-header-text">
					<h1>Liveness verification response</h1>
					<span class="text-span"
						>Preview of the information gotten from the liveness
						check</span
					>
				</div>
				<a
					href="#"
					class="btn btn-outline-danger"
					@click="
						() => {
							(successMsg = false), (hasNewResponse = false);
						}
					"
				>
					<i class="la la-close"></i>Close Panel
				</a>
			</div>
			<LivenessReport :result="result.data" />
		</div>

		<ResponsePreview>
			<template slot="endpoint">
				POST : '{{ baseUrl }}/v2/IdentityVerification/LivenessCheck'
			</template>

			<template slot="header">
				{{
					`{
                "clientid": ${clientID},
                "appname": ${request.app ? request.app.app_name : "app_name"},
                "apikey": ${request.app ? request.app.api_key : "app_key"}
                }`
				}}
			</template>

			<template slot="body">
				{{
					`{
                "video": ${request.video ? request.video.name : "video"},
                "phone_number": ${
					request.phoneNumber.formatted
						? getPhoneNumber(request.phoneNumber)
						: "phonenumber"
				}"
              }`
				}}
			</template>

			<template slot="sample">
				{{
					apiResponse
						? apiResponse
						: JSON.stringify(sampleResponse, null, 2)
				}}
			</template>
		</ResponsePreview>
	</section>
</template>

<script>
import { notifications } from "../../../public/assets/mixins/notifications";
import CustomInput from "@/utils/CustomInput/CustomInput";
import Spinner from "@/utils/Spinner/Spinner";
import PricesAndAccess from "@/utils/PricesAndAccess/PricesAndAccess";
import LivenessReport from "./Liveness/LivenessReport.vue";
import { sampleResponse } from "./Liveness/LivenessServiceSample";
import ResponsePreview from "@/utils/ResponsePreview/ResponsePreview";
import { required } from "vuelidate/lib/validators";
import LivenessModal from "./Liveness/LivenessModal.vue";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { getPhoneNumber } from "@/utils/helpers";

const initialState = () => ({});

export default {
	mixins: [notifications],
	name: "Liveness",
	props: ["application"],
	validations: {
		apiVersion: { required },
		request: {
			phoneNumber: {
				required,
				isPhoneValid: isPhoneValid("request.phoneNumber"),
			},
			app: { required },
		},
	},
	data() {
		return {
			hasService: false,
			baseUrl: null,
			request: {
				phoneNumber: "",
				app: "",
				video: "",
			},
			hasNewResponse: false,
			showModal: false,
			loading: false,
			successMsg: false,
			formErrors: {},
			apiResponse: "",
			result: "",
			versions: ["v2"],
			apiVersion: null,
			currencyType: null,
			customerWallets: this.$store.state.account.customerWallets,
			sampleResponse,
		};
	},
	components: {
		CustomPhoneInput,
		CustomInput,
		Spinner,
		PricesAndAccess,
		LivenessReport,
		ResponsePreview,
		LivenessModal,
	},
	computed: {
		API() {
			return this.$store.state.appUrlV2;
		},
		clientID() {
			return this.$store.state.account.clientID;
		},
	},
	methods: {
		getPhoneNumber,
		formVuelidator,
		handleClose(e) {
			this.showModal = false;
			this.loading = false;
		},
		async handleStream(video) {
			this.request.video = video;
			this.showModal = false;
			this.loading = true;
			const payload = new FormData();

			const { phoneNumber } = this.request || {};

			payload.append("video", video, "liveness.mp4");
			payload.append(
				"phone_number",
				phoneNumber.formatted ? getPhoneNumber(phoneNumber) : ""
			);

			const headers = {
				clientid: this.$store.state.account.clientID,
				appname: this.request.app.app_name,
				apikey: this.request.app.api_key,
			};

			try {
				const response = await this.$store.dispatch(
					"services/liveness",
					{
						headers,
						payload,
					}
				);

				if (
					response.status === 200 &&
					response.data.data.validity !== "INVALID"
				) {
					this.result = JSON.parse(JSON.stringify(response.data));
					this.apiResponse = JSON.parse(
						JSON.stringify(response.data)
					);
					this.apiResponse.data.image_best = `${this.apiResponse.data.image_best.substring(
						0,
						200
					)}...`;
					this.loading = false;
					this.successMsg = true;
					this.hasNewResponse = true;
				} else {
					this.loading = false;
					this.showErrorLoginNotification(response.data.data.message);
				}

				this.loading = false;

				return;
			} catch (error) {
				this.loading = false;

				typeof error.message === "string"
					? this.showErrorLoginNotification(error.message)
					: this.showErrorLoginNotification(
							"Error Validating Liveness"
					  );
				return;
			}
		},
		goBack() {
			Object.assign(this.$data, { ...initialState() });
		},
		getServiceStatus(hasService) {
			this.hasService = hasService;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleSubmit(e) {
			e.preventDefault();

			this.$v.$touch();
			console.log(this.$v);
			if (this.$v.$invalid) {
				return;
			}
			this.handleStart();
		},
		async handleStart() {
			this.loading = true;
			this.showModal = true;
			return;
		},
	},
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
	},
	mounted() {
		this.baseUrl = process.env.VUE_APP_BASE_URL_CORE;
	},
};
</script>

<style scoped>
.user-settings-section {
	border-top: 0 !important;
}
</style>
