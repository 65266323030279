import { getWalletApikey } from "../../../utils/helpers";

export const intervals = [
	"hourly",
	"daily",
	"weekly",
	"monthly",
	"quarterly",
	"biannually",
	"yearly",
];
export const trnasactionHistoryCsvRows = {
  reference: "Reference",
  senderFirstName: "First Name",
  senderLastName: "Last Name",
  senderEmail: "Email",
  currency: "Currency",
  amount: "Amount",
  active: "Status",
  created_at: "Created At",
};
export const senderCsvRows = {
  customerReference: "Sender Id",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  country: "Country",
  phoneNumber: "Phone",
  created_at: "Created At",
};
export const beneficiaryCsvRows = {
  beneficiaryReference: "Sender Id",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  country: "Country",
  phoneNumber: "Phone",
  currency: "Currency",
  bankName: "Bank Name",
  account_number: "Account Number",
  count: "Transaction Count",
  created_at: "Created At",
};

export const getRequestData = (store, filters, data, size = 1000000) => {
	const requestData = {
		apiKey: getWalletApikey(store),
		params: {
			size,
			...data,
			...filters,
		},
	};
	return requestData;
};
