<template>
	<Modal :id="id" :title="'Pay Bills'" :toggle="!loading && handleCloseModal">
		<SuccessState
			v-if="successMsg"
			:message="sucessData"
			:onSuccess="handleReset"
		/>

		<form v-else @submit.prevent="handlePayBill">
			<h5 class="text-center my-4">
				This Service would cost
				<b class="text-danger">{{ toCurrency(total) }}</b> <br />and
				would be deducted from your wallet
			</h5>

			<FormButtons
				:btnName="`Pay ${toCurrency(total)}`"
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../utils/Modal/Modal";
	import FormButtons from "../../../../../utils/FormButtons/FormButtons";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import { getError, toCurrency } from "../../../../../utils/helpers";
	import { PayBillStatus } from "./PayBillStatus/PayBillStatus";

	const initialState = () => ({
		loading: false,
		successMsg: false,
	});

	export default {
		name: "PayBillModal",
		mixins: [notifications],
		data() {
			return { ...initialState() };
		},
		props: ["id", "closeModal", "bill", "total", "reset", "setApiResponse"],
		components: {
			Modal,
			FormButtons,
			SuccessState,
		},
		computed: {
			sucessData() {
				const { service_type } = this.bill.payload || {};
				const success = PayBillStatus(this.successMsg, service_type);
				return success;
			},
		},
		methods: {
			toCurrency,
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleReset() {
				this.handleCloseModal();
				this.reset();
			},
			handleError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},
			handleStatus(data) {
				this.loading = false;
				const { service_type } = this.bill.payload || {};
				if (service_type === "cable_tv") {
					if (data.message === "successful" || data.message === 'Cable TV Subscription successful') {
						this.successMsg = data;
					} else this.handleError(data.details);
				} else {
					this.successMsg = data;
				}
			},
			async handlePayBill() {
				try {
					this.loading = true;

					const payload = this.bill;

					const response = await this.$store.dispatch(
						"services/payBill",
						payload
					);

					const { status, data } = response || {};

					if (status === 200 && data.status_code === 200) {
						this.handleStatus(data.data);
            this.setApiResponse(data)
					} else this.handleError(data.message);
				} catch (error) {
					this.handleError(getError(error));
				}
			},
		},
	};
</script>
