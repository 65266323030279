<template>
	<div>
		<button
			type="submit"
			class="btn btn-lg btn-primary w-100"
			@click="openPayBills"
			:disabled="disabled"
		>
			Pay {{ total }}
		</button>
		<PayBillModal
			:total="total"
			:id="'paybill'"
			:closeModal="closeModal"
			:bill="bill"
			:reset="reset"
      :setApiResponse="setApiResponse"
		/>
	</div>
</template>

<script>
	import PayBillModal from "../../modals/PayBillModal/PayBillModal";

	export default {
		name: "PayBillsButton",
		props: ["bill", "disabled", "handleSubmit", "reset", "setApiResponse"],
		computed: {
			total() {
				return this.bill.payload.amount
					? parseFloat(this.bill.payload.amount)
					: "";
			},
		},
		methods: {
			openPayBills() {
				const isValid = this.handleSubmit();
				isValid && $("#paybill").modal("show");
			},
			closeModal() {
				$("#paybill").modal("hide");
			},
		},
		components: {
			PayBillModal,
		},
	};
</script>
