<template>
	<div class="form-group" :class="otherCls">
		<label v-show="label" class="input-item-label">{{ label }}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<v-select
			class="v-select"
			:class="cls"
			:placeholder="placeholder"
			@keydown.enter.native.prevent
			:get-option-label="getLabel"
			:label="optionLabel"
			:options="options"
			:value="value"
			v-on:input="$emit('input', $event)"
			:disabled="disabled"
			:multiple="multiple"
			:clearable="isClear"
			@close="$emit('close', $event)"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: "CustomSelect",

  props: [
    "name",
    "label",
    "optionLabel",
    "placeholder",
    "value",
    "options",
    "required",
    "disabled",
    "getLabel",
    "error",
    "handleError",
    "multiple",
    "cls",
    "clear",
    "otherCls",
    "loading",
  ],
  computed: {
    isClear() {
      return this.clear ? true : false;
    },
  },
  watch: {
    value() {
      this.handleError && this.handleError(this.name);
    },
  },
};
</script>
