import DatePicker from "@/utils/DatePicker/DatePicker";
import DateTimePicker from "@/utils/DatePicker/DateTimePicker";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomAmountInput from "@/utils/CustomInput/CustomAmountInput";
import CustomTextArea from "@/utils/CustomTextArea/CustomTextArea";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import CustomerSelect from "@/utils/Selects/CustomerSelect/CustomerSelect";
import BankSelect from "@/utils/Selects/BankSelect/BankSelect";
import Spinner from "@/utils/Spinner/Spinner";
import DownloadReport from "@/utils/DownloadReport/DownloadReport";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import TaxSelect from "@/utils/Selects/TaxSelect/TaxSelect";
import CouponSelect from "@/utils/Selects/CouponSelect/CouponSelect";
import ItemSelect from "@/utils/Selects/ItemSelect/ItemSelect";
import FormButtons from "@/utils/FormButtons/FormButtons";
import Modal from "@/utils/Modal/Modal";
import SuccessState from "@/utils/ResponseState/SuccessState";
import SuccessModal from "@/utils/Modal/SuccessModal/SuccessModal";
import AccessControl from "@/utils/AccessControl/AccessControl";
import {
	timeDateFormat,
	timeRangeFormat,
	toCurrency,
	getWalletApikey,
	tableOptions,
	titleCase,
	getError,
} from "@/utils/helpers";
import { formValidator } from "@/utils/FormValidator/FormValidator";

export const filters = {
	data() {
		return {
			formErrors: {},
		};
	},
	components: {
		Spinner,
		CustomInput,
		CustomTextArea,
		CustomSelect,
		CurrencySelect,
		DownloadReport,
		BankSelect,
		DatePicker,
		DateTimePicker,
		TaxSelect,
		CouponSelect,
		CustomerSelect,
		ItemSelect,
		FormButtons,
		SuccessState,
		Modal,
		SuccessModal,
		CustomAmountInput,
		AccessControl,
	},
	methods: {
		getWalletApikey,
		timeRangeFormat,
		timeDateFormat,
		formValidator,
		tableOptions,
		toCurrency,
		titleCase,
		getError,
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
	},
	computed: {
		yesterDay() {
			return {
				to: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
			};
		},
	},
};
