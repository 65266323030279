export const sampleResponse = {
	data: {
		status: false,
		message: "Status Message",
		data: {
			request_reference: "REFERENCE NUMBER",
			bvn_number: "BVN NUMBER",
			name_on_card: "NAME ON CARD",
			enrolment_branch: "ENROLMENT BRANCH",
			enrolment_bank: "ZENITH BANK",
			formatted_registration_date: "FORMATED REGISTRATION DATE",
			level_of_account: "LEVEL OF ACCOUNT",
			nin: "NIN",
			watchlisted: "TRUE/FALSE",
			verification_status: "VERIFIED",
			service_type: "SERVICE TYPE",
			personal_info: {
				first_name: "THE FIRST NAME",
				middle_name: "THE MIDDLE NAME",
				last_name: "THE LAST NAME",
				full_name: "THE FULL NAME",
				email: "EMAIL",
				gender: "GENDER",
				phone_number: "PHONE NUMBER",
				phone_number_2: "PHONE NUMBER 2",
				date_of_birth: "DATE OF BIRTH",
				formatted_date_of_birth: "FORMATTED DATE OF BIRTH",
				lga_of_origin: "LGA OF ORIGIN",
				state_of_origin: "STATE OF ORIGIN",
				nationality: "NATIONALITY",
				marital_status: "MARITAL STATUS",
				image_url: "IMAGE URL",
			},
			residential_info: {
				state_of_residence: "STATE OF RESIDENCE",
				lga_of_residence: "LGA OF RESIDENCE",
				residential_address: "RESIDENTIAL ADDRESS",
			},
		},
	},
};
