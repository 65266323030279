<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 "
			@click="popWithdrawModal"
		>
			Withdraw Funds
		</button>
		<WithdrawalFundModal
			:id="'withdrawFund'"
			:closeModal="closeModal"
			:refetch="refetch"
			:balance="balance"
		/>
	</div>
</template>

<script>
	import WithdrawalFundModal from "../../modals/WithdrawalFundModal/WithdrawalFundModal";
	export default {
		name: "WithdrawFundButton",
		props: ["refetch", "balance"],
		methods: {
			popWithdrawModal() {
				$("#withdrawFund").modal("show");
			},
			closeModal() {
				$("#withdrawFund").modal("hide");
			},
		},
		components: {
			WithdrawalFundModal,
		},
	};
</script>
