var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:_vm.otherCls},[_c('div',{staticClass:"flex__between"},[_c('div',[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],staticClass:"input-item-label"},[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{staticClass:"text-danger pl-1",attrs:{"title":"This field is required"}},[_vm._v("* "+_vm._s(_vm.number)+" ")]):_vm._e()]),[_vm._t("default")]],2),_c('div',{staticClass:"input-wrapper",class:`${_vm.disabled ? 'disabled' : ''}  ${_vm.error ? 'error' : ''}`},[_c('vue-tel-input',{staticClass:"form-control w-100 input-bordered p-0",attrs:{"defaultCountry":_vm.defaultCountry ? _vm.defaultCountry : 'NG',"onlyCountries":_vm.onlyCountries,"inputOptions":{
					..._vm.inputOptions,
				},"dropdownOptions":{
					showDialCodeInList: true,
					showFlags: true,
					showSearchBox: true,
					tabindex: 0,
					width: '300px',
					..._vm.dropdownOptions,
				},"value":_vm.number,"modelValue":_vm.number,"step":"any","disabled":_vm.disabled,"validCharactersOnly":true,"autoFormat":false},on:{"input":_vm.handleInput,"validate":function($event){return _vm.$emit('validate', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.icon),expression:"icon"}],staticClass:"img__wrap"},[_c('img',{staticClass:"pointer",attrs:{"src":_vm.icon,"alt":""},on:{"click":function($event){_vm.iconClick ? _vm.iconClick() : ''}}})])],1),(typeof _vm.error === 'string')?_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }