export const ACCOUNT_ROLES = Object.freeze({
	OWNER: 1,
	ADMIN: 2,
	OPERATIONS: 3,
	DEVELOPER: 4,
	CUSTOMER_SERVICE: 5,
});

export const rolesOwners = [
	{ name: "Admin", value: 2 },
	{ name: "Operations", value: 3 },
	{ name: "Developer", value: 4 },
	{ name: "Customer Service", value: 5 },
];

export const rolesAdmin = [
	{ name: "Operations", value: 3 },
	{ name: "Developer", value: 4 },
	{ name: "Customer Service", value: 5 },
];
