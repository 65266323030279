<template>
	<Modal :id="id" :title="'Set Spending Limit'" :toggle="handleCloseModal">
		<form @submit.prevent="">
			<div class="d-flex bg-light rounded mb-3 p-3">
				<img src="/assets/img/icons/info.svg" alt="" class="mr-3" />
				<span
					>Setting spending limit on your card will not allow you
					exceed the amount you have set.</span
				>
			</div>

			<div class="py-3">
				<CustomAmountInput
					:name="'amount'"
					:type="'number'"
					:amount="request.amount"
					:currency="request.currency"
					v-model="request.amount"
					:label="'Enter Amount'"
					:placeholder="'Enter amount to fund'"
					:required="true"
					:error="formErrors.amount"
					:handleError="handleError"
				/>
			</div>

			<CustomSelect
				:name="'frequency'"
				label="Frequency"
				:placeholder="'Select Frequency'"
				:options="frequencyOptions"
				v-model="request.frequency"
				:error="formErrors.frequency"
				:handleError="handleError"
			/>

			<div class="flex__between pt-3">
				<button
					type="button"
					class="btn btn-outline w-100 mr-3"
					@click="handleCloseModal"
				>
					<span>Cancel</span>
				</button>
				<button
					type="button"
					class="btn btn-lg btn-primary w-100 ml-3"
					:disabled="loading"
					@click="handleSubmit"
				>
					<Spinner v-if="loading" />
					<span v-else>Set Limit</span>
				</button>
			</div>
		</form>
	</Modal>
</template>

<script>
	import CustomAmountInput from "../../../../../../utils/CustomInput/CustomAmountInput";
	import CustomSelect from "../../../../../../utils/CustomSelect/CustomSelect";
	import Modal from "../../../../../../utils/Modal/Modal";
	import { formValidator } from "../../../../../../utils/FormValidator/FormValidator";
	import Spinner from "../../../../../../utils/Spinner/Spinner";

	const frequencyOptions = [
		"Daily",
		"Weekly",
		"Monthly",
		"Yearly",
		"Per Change",
	];

	const defaultValue = {
		amount: "",
		currency: "NGN",
		frequency: "",
	};

	export default {
		name: "SetSpendingLimitModal",
		data() {
			return {
				request: { ...defaultValue },
				formErrors: {},
				frequencyOptions,
			};
		},
		props: ["id", "closeModal"],
		components: { CustomAmountInput, CustomSelect, Modal, Spinner },
		methods: {
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) this.submitForm(this.wallet, this.amount);
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			handleCloseModal() {
				Object.assign(this.$data.request, defaultValue);
				this.closeModal();
			},
		},
	};
</script>
