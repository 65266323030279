<template>
	<div class="card p-4">
		<div v-if="loading" class="flex__center my-5 py-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="getProfile"
		/>

		<template v-else>
			<AccessControl>
				<div
					v-if="userRole !== 'Secondary Customer'"
					class="border-bottom d-flex justify-content-end pb-3"
				>
					<EditServiceButton :refetch="getProfile" />
				</div>
			</AccessControl>

			<div
				v-for="(service, index) in allServices"
				:key="service.service_label"
				class="pt-4"
			>
				<div
					class="flex__between"
					:class="
						allServices.length - 1 === index
							? ''
							: 'border-bottom pb-3 mb-2 '
					"
				>
					<h5 class="m-0 pr-4">{{ service.service_label }}</h5>

					<h5 class="text-nowrap">
						{{ toCurrency(service.pivot.price_per_call) }}
					</h5>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import EditServiceButton from "../buttons/EditServiceButton/EditServiceButton";
	import Spinner from "@/utils/Spinner/Spinner";
	import FailedState from "@/utils/ResponseState/FailedState";
	import AccessControl from "@/utils/AccessControl/AccessControl";
	import { toCurrency } from "@/utils/helpers";

	export default {
		name: "Services",
		data() {
			return {
				loading: false,
				errorMsg: "",
				user: {},
			};
		},
		components: {
			Spinner,
			FailedState,
			EditServiceButton,
			AccessControl,
		},
		computed: {
			userRole() {
				return this.$store.state.account.profile.roles[0].role_label;
			},
			allServices() {
				return this.user.api_customer.customerServices;
			},
		},
		methods: {
			toCurrency,
			async getProfile() {
				try {
					this.loading = true;
					await this.$store
						.dispatch("account/getProfile")
						.then((response) => {
							this.loading = false;
							this.user = response;
						});
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
		},
		async mounted() {
			this.getProfile();
		},
	};
</script>
