import pos from "@/services/wallets/pos";

export default {
  namespaced: true,
  actions: {
    async getAgents({}, payload) {
      try {
        return await pos.getAgents(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getOneAgent({}, payload) {
      try {
        return await pos.getOneAgent(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getPosTransactionsCashRegister({}, payload) {
      try {
        return await pos.getPosTransactionsCashRegister(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getAgentOnlineTransactions({}, payload) {
      try {
        return await pos.getAgentOnlineTransactions(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getAgentCashRegister({}, payload) {
      try {
        return await pos.getAgentCashRegister(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async updateAgent({}, payload) {
      try {
        return await pos.updateAgent(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async addAgent({}, payload) {
        return await pos.addAgent(payload);
    },
    async getVirtualTerminals({}, payload) {
      try {
        return await pos.getVirtualTerminals(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getVirtualTerminalsDetails({}, payload) {
      try {
        return await pos.getVirtualTerminalsDetails(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getVirtualTerminalsTransactions({}, payload) {
      try {
        return await pos.getVirtualTerminalsTransactions(payload);
      } catch (error) {
        throw Error(error);
      }
    },
  },
};
