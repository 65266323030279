<template>
  <Modal :id="id" :title="'Block Terminal'" :toggle="closeModal">
    <div>
      <p v-if="virtualTerminalData.agent.status === 'active'">
        Are you sure you want to block this Agent's Access? This agent won't be
        able to access this terminal anymore.
      </p>
      <p v-else>
        Are you sure you want to unblock this Agent's Access? This agent won't be
        able to access this terminal anymore.
      </p>
      <ul class="sub_card h-100">
        <h5>Basic Information</h5>
        <li>
          <p>Status</p>
          <p>{{ titleCase(virtualTerminalData.agent.status) }}</p>
        </li>
        <li>
          <p>Agent Reference</p>
          <p>{{ virtualTerminalData.agent.reference }}</p>
        </li>
        <li>
          <p>Agent's Name</p>
          <p>
            {{
              `${virtualTerminalData.agent.firstName} ${virtualTerminalData.agent.lastName}`
            }}
          </p>
        </li>
        <li>
          <p>Agent's Email</p>
          <p>{{ virtualTerminalData.agent.email }}</p>
        </li>
        <li>
          <p>Agent's Phone Number</p>
          <p>{{ virtualTerminalData.agent.phoneNumber }}</p>
        </li>
      </ul>
    </div>
    <div class="flex__between">
      <button
        type="button"
        class="btn btn-outline w-100 mr-3"
        @click="handleClose"
      >
        <span>Cancel</span>
      </button>
      <button
        type="button"
        class="btn btn-lg btn-primary w-100 ml-3"
        :disabled="loading"
        @click="handleSubmit"
      >
        <Spinner v-if="loading" />
        <span v-else
          ><span v-if="virtualTerminalData.agent.status === 'active'"
            >Block Access</span
          ><span v-else>Unblock Access</span></span
        >
      </button>
    </div>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Spinner from "../../../../../utils/Spinner/Spinner";
import Modal from "../../../../../utils/Modal/Modal";
import {
  getWalletApikey,
  getError,
  titleCase,
} from "../../../../../utils/helpers";

export default {
  mixins: [notifications],
  name: "BlockTerminal",
  data() {
    return {
      virtualTerminalData: {},
      loading: false,
    };
  },
  props: ["id", "closeModal"],
  components: { Modal, Spinner },
  methods: {
    titleCase,
    handleClose() {
      this.closeModal();
    },
    async handleSubmit(e) {
      this.loading = true;
      try {
        let response = await this.$store.dispatch("pos/updateAgent", {
          payload: {
            status:
              this.virtualTerminalData.status === "inactive"
                ? "active"
                : "inactive",
          },
          apiKey: getWalletApikey(this.$store),
          id: this.$route.params.id,
        });
        this.showSuccessNotification(response.data.message);
        this.handleClose();
        this.loading = false;
        (this.virtualTerminalData.status =
          this.virtualTerminalData.status === "inactive"
            ? "active"
            : "inactive"),
          this.$parent.$emit("updateAgent");
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        console.log("[error] ->", error);
        this.loading = false;
        this.showErrorLoginNotification(getError(error.response));
      }
    },
    async getOneAgent() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          id: this.$route.params.id,
        },
      };

      try {
        const resp = await this.$store.dispatch("pos/getOneAgent", payload);

        this.virtualTerminalData = resp.data.data;
      } catch (error) {
        console.log("transctionError", error);
      }
    },
  },
  created() {
    this.getOneAgent();
  },
};
</script>
