import { apiClient } from "./api";

export default {
	// White labelling routes
	getAppSettingByDomain(domain) {
		console.log("[domain] ->", domain);
		return apiClient.get(
			`/Authorize/Whitelabel/AppSettingByDomain/protocol/${domain.protocol}/domain/${domain.host}`
		);
	},
	getSingleSecondaryCustomers(credential) {
		let headers = { clientid: credential };
		return apiClient.get(`Authorize/SingleSecondaryCustomers`, { headers });
	},
	getAppSetting() {
		return apiClient.get(`Authorize/AppSetting`);
	},
	getSecondaryCustomers() {
		return apiClient.get(`Authorize/SecondaryCustomers`);
	},
	SecondaryCustomerCreditLogs() {
		return apiClient.get(`/Authorize/Primary/SecondaryCustomerCreditLogs`);
	},
	actualSecondaryCustomerLog(requestHeaders) {
		let headers = { clientid: requestHeaders.clientID };
		return apiClient.get(
			`Authorize/WlCustomer/ActualSecondaryCustomerLog`,
			{ headers }
		);
	},
	customerRequestWhiteLabelling(requestHeaders) {
		let headers = { clientid: requestHeaders.clientid };
		return apiClient.get(`Authorize/InternalWLRegistration`, { headers });
	},
	whitelabelledDashboard(requestHeaders) {
		console.log("[request header] ->", requestHeaders);
		let headers = { clientid: requestHeaders.clientID };
		return apiClient.get(`Authorize/WhiteLabel/Dashboard`, { headers });
	},
	SecondaryCustomerLoadCredit(requestData) {
		return apiClient.put(
			`Authorize/SecondaryCustomer/LoadCredit/${requestData.clientid}`,
			requestData.payload
		);
	},
	editSecondaryCustomerProfile(profileDetails) {
		return apiClient.put(
			`/Authorize/SecondaryCustomerEditProfile`,
			profileDetails
		);
	},
	primaryCustomerEditSecondaryCustomer(profileDetails) {
		let headers = { clientid: profileDetails.user_clientid };
		return apiClient.put(
			`/Authorize/PrimaryCustomerEditProfile/${profileDetails.clientid}`,
			profileDetails.payload,
			{ headers }
		);
	},
	massUpdateDefaultServicesPrice(requestData) {
		// let headers = { clientid: requestData.clientid }
		return apiClient.put(
			`Authorize/WlCustomer/MassUpdateDefaultServicesPrice`,
			requestData
		);
	},
	updateSecondaryCustomerPricing(requestData) {
		let headers = { clientid: requestData.clientid };
		return apiClient.patch(
			`Authorize/WlCustomer/DefaultServicesPrice/${requestData.serviceId}`,
			requestData.payload,
			{ headers }
		);
	},
	getAllWhiteLabelledServices() {
		return apiClient.get(`Authorize/WlCustomer/DefaultServicesPrices`);
	},
	resetServicesPrice() {
		return apiClient.put(`Authorize/WlCustomer/ResetServicesPrice`);
	},
	resetAppColors() {
		return apiClient.put(`Authorize/ResetAppColors`);
	},
	getSecondaryCustomerServicePricing(requestHeaders) {
		let headers = { clientid: requestHeaders.clientID };
		return apiClient.get(`Authorize/SecondaryCustomer/ServicesPrices`, {
			headers,
		});
	},
	updateSecondaryCustomerServicePricing(requestData) {
		let headers = { clientid: requestData.clientid };
		return apiClient.patch(
			`Authorize/SecondaryCustomer/ServicesPrice/${requestData.serviceId}`,
			requestData.payload,
			{ headers }
		);
	},
};
