<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openChnagePassword"
		>
			Change Password
		</button>
		<ChangePasswordModal :id="'changePassword'" :closeModal="closeModal" />
	</div>
</template>

<script>
	import ChangePasswordModal from "../../modals/ChangePasswordModal/ChangePasswordModal";

	export default {
		name: "ChangePasswordButton",
		methods: {
			openChnagePassword() {
				$("#changePassword").modal("show");
			},
			closeModal() {
				$("#changePassword").modal("hide");
			},
		},
		components: {
			ChangePasswordModal,
		},
	};
</script>
