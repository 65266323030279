export const invoiceCsvRows = {
	invoice_reference: "Invoice Reference",
	amount: "Amount",
	currency: "Currency",
	due_date: "Due Date",
	invoice_type: "Invoice Type",
	"other_info.customer.first_name": "First Name",
	"other_info.customer.last_name": "Last Name",
	"other_info.customer.email": "Email",
	"other_info.customer.phone": "Phone Number",
	status: "Status",
	"other_info.tax_value": "Tax",
	"other_info.coupon_value": "Coupon",
	createdAt: "Date Created",
	invoice_link: "Invoice Link",
};

export const itemsCsvRows = {
	id: "Product ID",
	reference: "Product Reference",
	name: "Product Name",
	description: "Product Description",
	currency: "Currency",
	amount: "Amount(#)",
	created_at: "Date Created",
};

const tableHeadings = {
	invoice_reference: "Invoice Reference",
	createdAt: "Date Created",
	due_date: "Due Date",
	amount: "Amount",
	"other_info.customer.first_name": "Customer",
	invoice_type: "Invoice Type",
};

export const invoiceTableHeadings = {
	...tableHeadings,
	status: "Status",
	action: "",
};

export const draftTableHeadings = { ...tableHeadings, action: "" };

export const itemsTableHeadings = {
	reference: "Product Reference",
	name: "Product Name",
	description: "Description",
	amount: "Amount",
	created_at: "Date Created",
	status: "",
};

export const invoiceStatus = [
	{ name: "Draft", value: "draft" },
	{ name: "Issued", value: "issued" },
	{ name: "Partially Paid", value: "partially_paid" },
	{ name: "Paid", value: "paid" },
	{ name: "Canceled", value: "canceled" },
	{ name: "Expired", value: "expired" },
];

export const invoiceType = [
	{ name: "One Time", value: "one-time" },
	{ name: "Recurring", value: "recurring" },
];

export const invoiceIntervalOptions = [
	{ name: "Daily", value: "daily" },
	{ name: "Weekly", value: "weekly" },
	{ name: "Biweekly", value: "bi-weekly" },
	{ name: "Monthly", value: "monthly" },
	{ name: "Bimonthly", value: "bimestrial" },
	{ name: "Quarterly", value: "quarterly" },
	{ name: "Biyearly", value: "bi-yearly" },
];

export const newInvoiceItemsHeadings = (currency = "NGN") => ({
	name: "Product Name",
	amount: `Unit Cost (${currency})`,
	quantity: "Quantity",
	total: `Total Cost (${currency})`,
	action: "",
});

export const form = {
	name: "",
	quantity: 1,
	amount: 0,
	total: 0,
};
