<template>
	<div>
		<div class="text-center my-4 w-100">
			<h4 class="themed-text font-weight-bold">Enter OTP</h4>
		</div>

		<div class="row justify-content-center my-2 py-5">
			<v-otp-input
				ref="otpInput"
				input-classes="otp-input"
				separator="-"
				:value="value"
				:num-inputs="6"
				:should-auto-focus="true"
				:is-input-num="true"
				@on-change="handleChange"
			/>
		</div>

		<div class="row flex__center pt-4">
			<div v-if="goBack" class="col-6">
				<button class="btn btn-outline w-100" @click="goBack">
					<span>Back</span>
				</button>
			</div>
			<div class="col-6">
				<button
					class="btn btn-lg btn-primary w-100"
					:disabled="loading || value.length < 6"
					@click="handleSubmit"
				>
					<Spinner v-if="loading" />
					<span v-else>Submit</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";

export default {
	name: "WalletOTP",
	props: ["value", "loading", "handleChange", "handleSubmit", "goBack"],
	components: {
		Spinner,
	},
};
</script>
