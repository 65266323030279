import whitelabelling from "@/services/whitelabelling";
import { apiClient } from "@/services/api";
apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

const year = new Date().getFullYear();

export default {
	namespaced: true,
	state: {
		whitelabelledSettings: {},
		whitelabelledSettings1: {
			copy_right_info: `${year} My Demo Company Ltd. All rights reserved.`,
			company_name: "Demo Company",
			company_website_url: "demourl.com",
			background_image_url: "",
			sidebar_color: "#1C86EE",
			domain: "",
			logo_url:
				"https://www.freelogodesign.org/Content/img/logo-samples/celtica.png",
		},
	},
	mutations: {
		SET_CLIENT_ID(state, clientID) {
			localStorage.setItem("local-ID", clientID);
			state.clientID = clientID;
		},

		SET_WHITELABELLED_SETTINGS(state, settings) {
			state.whitelabelledSettings = settings;
		},
	},
	actions: {
		async getAppSettingByDomain({ commit }, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.getAppSettingByDomain(
					requestData
				);
				commit("SET_WHITELABELLED_SETTINGS", data.results);

				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async whitelabelledDashboard(context, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.whitelabelledDashboard(
					requestData
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getSecondaryCustomers() {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.getSecondaryCustomers();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getSingleSecondaryCustomers(context, credentials) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.getSingleSecondaryCustomers(
					credentials
				);
				return data.results[0];
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getAppSetting(context) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.getAppSetting();
				return data.results;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async SecondaryCustomerLoadCredit({ commit, dispatch }, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.SecondaryCustomerLoadCredit(
					requestData
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},

		// WhiteLabelling functionalities
		async primaryCustomerEditSecondaryCustomer(
			{ commit, dispatch },
			requestData
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.primaryCustomerEditSecondaryCustomer(
						requestData
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async customerRequestWhiteLabelling({ commit, dispatch }, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.customerRequestWhiteLabelling(
						requestData
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async secondaryCustomerCreditLogs({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.SecondaryCustomerCreditLogs();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async actualSecondaryCustomerLog({ commit, dispatch }, headers) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.actualSecondaryCustomerLog(
					headers
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async massUpdateDefaultServicesPrice(
			{ commit, dispatch },
			requestData
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.massUpdateDefaultServicesPrice(
						requestData
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async updateSecondaryCustomerPricing(
			{ commit, dispatch },
			requestData
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.updateSecondaryCustomerPricing(
						requestData
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getAllWhiteLabelledServices({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.getAllWhiteLabelledServices();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getSecondaryCustomerServicePricing(
			{ commit, dispatch },
			appDetails
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.getSecondaryCustomerServicePricing(
						appDetails
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async updateSecondaryCustomerServicePricing(
			{ commit, dispatch },
			appDetails
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await whitelabelling.updateSecondaryCustomerServicePricing(
						appDetails
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async resetServicesPrice({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.resetServicesPrice();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async resetAppColors({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await whitelabelling.resetAppColors();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
	},
	getters: {
		getClientID(state) {
			return state.clientID;
		},
	},
};
