<template>
	<Modal :id="id" :title="'Update Profile Logo'" :toggle="handleCloseModal">
		<SuccessState
			v-if="successMsg"
			:message="'Logo Updated Successfully'"
			:onSuccess="handleCloseModal"
		/>

		<form v-else>
			<CustomUpload
				:name="'logo'"
				:label="'Logo'"
				:required="true"
				:error="formErrors.logo"
				:handleError="handleError"
				:validFileTypes="['jpg', 'jpeg', 'png']"
				:handleChange="handleUpload"
			/>
			<template v-if="preview">
				<div v-if="inv" class="">
					<img :src="preview" class="customer__logo" />
				</div>
				<template v-else>
					<div class="flex__between btn--primary p-2">
						<img :src="preview" class="customer__logo" />

						<div class="text-right">
							<h5 class="m-0">Pay N50,000.000</h5>
							<span>{{user.email}}</span>
						</div>
					</div>
					<span class="flex__center"
						>This is how the logo will appear on the checkout
						page</span
					></template
				></template
			>

			<div class="d-flex mt-4">
				<button
					type="button"
					class="btn btn-outline w-100 mr-3"
					@click="handleCloseModal"
				>
					<span>Cancel</span>
				</button>
				<button
					type="button"
					class="btn btn-lg btn-primary w-100 ml-3"
					:disabled="loading"
					@click="handleSubmit"
				>
					<Spinner v-if="loading" />
					<span v-else>Upload</span>
				</button>
			</div>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";
import Modal from "@/utils/Modal/Modal";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import Spinner from "@/utils/Spinner/Spinner";
import { getWalletApikey, getError } from "@/utils/helpers";
import merchant from "@/services/merchant";

const initialState = () => ({
	logo: "",
	preview: "",
	loading: false,
	successMsg: false,
	formErrors: {},
});

export default {
	name: "CreatePaymentDisputeModal",
	mixins: [notifications],
	data() {
		return { ...initialState() };
	},
	props: ["id", "inv", "setLogo", "closeModal"],
	components: {
		CustomUpload,
		Modal,
		Spinner,
		SuccessState,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault();
			const { formErrors, isValid } = formValidator({
				logo: this.logo,
			});
			if (isValid) this.uploadLogo();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleCloseModal() {
      if (this.successMsg) {
        this.setLogo && this.setLogo(this.preview);
        this.$store.state.account.customerKeys.logo = this.preview
        this.$store.dispatch("wallets/getAPIKeys");
      }
      Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		setEmptyImg() {
			this.logo = "";
			this.preview = "";
		},
		async handleUpload({ target: { files } }) {
			const file = files[0];
			if (file)
				if (["image/png", "image/jpeg"].includes(file.type)) {
					if (file.size > 1048576) {
						this.setEmptyImg();
						this.showErrorLoginNotification(
							"File too large. Max of 1MB"
						);
					} else {
						this.preview = URL.createObjectURL(file);
						this.logo = file;
					}
				} else {
					this.setEmptyImg();
					this.showErrorLoginNotification("Inavlid Image");
				}
			else this.setEmptyImg();
		},
		async uploadLogo() {
			try {
				const request = new FormData();
				request.append("logo", this.logo, this.logo.name);

				this.loading = true;

				const response = await merchant.uploadLogo({
					request,
					params: { apiKey: getWalletApikey(this.$store) },
				});

				const { status, data } = response || {};

				if (status === 200 && data.status) {
					this.loading = false;
					this.successMsg = true;
          this.preview = data.data
				} else this.setError(data.message);
			} catch (error) {
        console.log(error)
				this.setError(getError(error));
			}
		},
		setError(msg) {
			this.loading = false;
			this.showErrorLoginNotification(msg);
		},
	},
};
</script>
