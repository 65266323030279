<template>
	<div class="card pt-3">
		<div class="row">
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
						</template>
						<template slot="filterForm">
							<TransactionsTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadCoupon"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="couponRef"
					class="v-table"
					:columns="columns"
					:options="options"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
					@row-click="showData"
				>
					
					<template slot="createdAt" slot-scope="{ row }">
						{{ timeDateFormat(row.createdAt).date }}
					</template>
				</v-server-table>
			</div>
		</div>
		<RemittanceTransactionModal
			v-if="showModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications"
	import RemittanceTransactionModal from "./RemittanceTransactionModal";
	import TransactionsTableFilters from "./TransactionsTableFilters/TransactionsTableFilters";
	import AccessControl from "@/utils/AccessControl/AccessControl";
	import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
	import FailedState from "@/utils/ResponseState/FailedState";
	import Spinner from "@/utils/Spinner/Spinner";
	import { getRequestData } from "../enums";
	import { transactionsObj } from "../data";
	import {
		timeDateFormat,
		tableOptions,
		titleCase,
		downloadReport,
	} from "@/utils/helpers";

	const tableHeadings = {
		id: "Sender Id",
		sender: "Name",
		amount: "Reserved Amount",
		currency: "Currency",
		reciever: "Reciever",
		createdAt: "Created At",
	};

	export default {
		mixins: [notifications],
		name: "Transactions",
		data() {
			return {
				loading: false,
				rLoading: false,
				showModal: false,
				modalData: null,
				errorMsg: "",
				size: 25,
				filters: {},
				...tableOptions(tableHeadings, 20),
				transactionsObj
			};
		},
		components: {
			RemittanceTransactionModal,
			TransactionsTableFilters,
			AccessControl,
			FilterWrapper,
			FailedState,
			Spinner,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
			tbClass() {
				return this.loading || this.errorMsg ? "hide__table" : "";
			},
		},
		methods: {
			showData({ row }) {
				this.modalData = row;
				this.showModal = true;
			},
			closeModal(e) {
				this.showModal = false;
				this.modalData = null;
			},
			timeDateFormat,
			titleCase,
			setLoading(loading) {
				this.loading = loading;
				this.errorMsg = "";
			},
			setError(error) {
				this.errorMsg = error.message;
			},
			refetch(filters) {
				const newFilters = Object.fromEntries(
					Object.entries(filters).filter(
						([_, v]) => v != "" && v != null
					)
				);
				if (filters) this.filters = newFilters;
				this.getCoupons();
				this.$refs.couponRef.refresh();
			},
			clearFilter() {
				this.filters = {};
				this.$refs.couponRef.refresh();
			},
			async getCoupons() {
				this.options.requestFunction = async (data) => {
					const requestData = getRequestData(
						this.$store,
						this.filters,
						data,
						this.size
					);
					return transactionsObj
				};
			},
			async downloadCoupon() {
				this.rLoading = true;
				const request = getRequestData(this.$store, this.filters, {});

				const response = await this.$store.dispatch(
					"coupons/getAllCoupons",
					request
				);
				const { status, data } = response || {};
				if (status === 200 && data.status) {
					const newHeading = { ...tableHeadings };
					delete newHeading.action;
					downloadReport(newHeading, data.data.data);
					this.rLoading = false;
				} else {
					this.rLoading = false;
					this.showErrorLoginNotification("Error Downloading Report");
				}
			},
		},

		created() {
			this.getCoupons();
		},
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
