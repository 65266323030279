<template>
	<WalletAccordion
		:title="'Personal Information'"
		:id="'headingOne'"
		:target="'personal'"
	>
		<section>
			<p>Email</p>
			<span>{{ wallet.Customer.email || "None" }} </span>
		</section>

		<section>
			<p>Phone Number</p>
			<span>{{ getPhone(wallet.Customer.phone) || "None" }} </span>
		</section>

		<section>
			<p>Customer ID</p>
			<span>{{ wallet.CustomerId || "None" }} </span>
		</section>

		<section>
			<p>Customer Type</p>
			<span
				>{{ wallet.Customer.business ? "Business" : "Individual" }}
			</span>
		</section>

		<section>
			<p>Account Number</p>
			<span>{{ wallet.account_number || "None" }} </span>
		</section>
	</WalletAccordion>
</template>

<script>
	import WalletAccordion from "../WalletAccordion/WalletAccordion";
	import { notifications } from "../../../../../../../public/assets/mixins/notifications";
	import { getPhone } from "../../../../../../utils/helpers";

	export default {
		mixins: [notifications],
		name: "WalletPersonalInfo",
		props: ["wallet"],
		components: {
			WalletAccordion,
		},
		methods: {
			getPhone,
			isCopy(val) {
				return val === null ? " " : "copy";
			},
		},
	};
</script>
