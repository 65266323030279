<template>
  <div id="ApplicationInitializationModal" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-initializationModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Application Initialization Details</h5>
          <p>Let's get you all up to date with your initialized bundled application.</p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>

          <form @submit.prevent="saveBundleDetails">
            <div class="gaps-2x"></div>
            <div class="form-group" v-if="$attrs.app">
              <label class="fs-15">Application Name</label>
              <input
                type="text"
                class="input-bordered"
                readonly
                :value="$attrs.app.app_name"
                disabled
              />
            </div>

            <div class="form-group">
              <label class="fs-15">Bundle Call ID</label>
              <div class="copy-wrap mgb-1-5x mgt-1-5x">
                <span class="copy-feedback" style="display: none;">Copied to Clipboard</span>
                <em class="fas fa-link"></em>
                <input
                  type="text"
                  class="copy-address"
                  :value="initializedAppResult.bundle_call_id"
                  disabled
                />
                <button
                  class="copy-trigger copy-clipboard"
                  @click.prevent
                  :data-clipboard-text="initializedAppResult.bundle_call_id"
                >
                  <em class="la la-copy"></em>
                </button>
              </div>

              <div class="gaps-2x"></div>
              <div class="form-group">
                <label class="fs-15">Services</label>
                <div class="table-responsive">
                  <table class="table table-stripped mg-b-0">
                    <tbody>
                      <tr v-for="(service, i) in initializedAppResult.services" :key="i">
                        <td>{{ service.service_label }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="gaps-2x"></div>
            <div class="message-info fs-14">
              Please note your
              <strong>CALL ID</strong> as it is required to make calls to the blusalt infrastructure when using your app, which has a bundled service.
            </div>
            <button
              type="submit"
              v-if="false"
              class="btn btn-lg btn-primary"
              :disabled="isProcessing == true"
            >
              <span v-if="isProcessing">Creating application</span>
              <span v-else>Save Details</span>
            </button>
            <button type="button" class="btn btn-lg btn-light ml-2" data-dismiss="modal">Close</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from 'nprogress'
import { required } from 'vuelidate/lib/validators'
import { notifications } from '../../../public/assets/mixins/notifications'

export default {
  mixins: [notifications],
  props: {
    initializedAppResult: {
      type: Object
    }
  },
  data() {
    return {
      isProcessing: false,
      services: ''
    }
  },
  validations: {},
  computed: {
    metaData() {
      return this.$store.state.metaData
    },
    clientID() {
      return this.$store.state.account.clientID
    }
  },
  methods: {
    closeModalSuccess() {
      let closeButton = document.getElementById('close-initializationModal')
      closeButton.click()
    },

    async saveBundleDetails() {
      this.isProcessing = true
      this.$v.$touch()
      if (this.$v.$invalid) return

      try {
        let application = await this.$store.dispatch('application/createApplication', requestPayload)
        console.log('[] ->', application.message)
        this.showSuccessNotification(application.message)
        this.isProcessing = false
        let self = this
        setTimeout(() => {
          self.closeModalSuccess()
          self.clearInputFields()
        }, 2500)
        Nprogress.done()
      } catch (error) {
        Nprogress.done()
        console.log('[error] ->', error)
        this.isProcessing = false
        this.showErrorLoginNotification(error.message)
      }
    }
  }
}
</script>

<style scoped>
.copy-wrap {
  position: relative;
  width: 100%;
}
.copy-feedback {
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  line-height: 20px;
  padding: 13px 0;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  border: 1px solid rgba(211, 224, 243, 0.5);
  color: #2c80ff;
}
.copy-wrap > [class*="fa"] {
  position: absolute;
  top: 0;
  left: 18px;
  line-height: 20px;
  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  color: #495463;
}

.copy-address {
  border: none;
  color: #495463;
  line-height: 20px;
  padding: 12px 50px 12px 40px;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  background: rgb(244, 244, 244);
}

.copy-trigger {
  position: absolute;
  right: 4px;
  top: 4px;
  height: 38px;
  width: 38px;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
  color: #758698;
  background: #e9eff9;
  border-radius: 4px;
  border: none;
  transition: all 0.4s;
  cursor: pointer;
}
.app-key-list-item.accordion-heading {
  cursor: pointer;
}
.app-content {
  padding: 20px;
  border-top: 1px solid #e9eff9;
  background: #f9f9f9;
}

h5.sub-heading {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 600;
}
.sub-heading span {
  font-weight: 900;
}
</style>
