<template>
	<Modal :id="id" :title="'Withdraw From Wallet'" :toggle="handleCloseModal">
		<WalletForm
			v-if="step == 1"
			:loading="loading"
			:closeModal="handleCloseModal"
			:submitForm="handleSendOTP"
			:minAmount="100"
			:miniText="'Minimum of #100'"
			:setDefault="true"
			:amountPlaceholder="'Enter amount to withdraw'"
			:showSettlementAccount="true"
		/>

		<WalletOTP
			v-if="step == 2"
			:goBack="goBack"
			:loading="loading"
			:value="otp"
			:handleChange="handleOtp"
			:handleSubmit="withdrawFunds"
		/>

		<SuccessState
			v-if="step == 3"
			:message="`${toCurrency(
				amount
			)} has been withdrawn into you settlment account successfully`"
			:onSuccess="handleRefetch"
		/>
	</Modal>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../../public/assets/mixins";
import Modal from "../../../../../utils/Modal/Modal";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import WalletOTP from "../WalletOTP/WalletOTP";
import WalletForm from "../WalletForm/WalletForm";
import Spinner from "../../../../../utils/Spinner/Spinner";
import { toCurrency } from "../../../../../utils/helpers";

const initialState = () => ({
	loading: false,
	otp: "",
	step: 1,
	amount: "",
	formErrors: {},
});

export default {
	mixins: [notifications, componentData],
	name: "WithdrawalFundModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "refetch", "balance"],
	components: {
		Modal,
		WalletOTP,
		WalletForm,
		Spinner,
		SuccessState,
	},
	methods: {
		toCurrency,
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleOtp(otp) {
			this.otp = otp;
		},
		goBack() {
			this.step -= 1;
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			this.refetch();
		},
		handleSendOTP(_, amount) {
			amount > this.balance
				? this.showErrorLoginNotification("Insufficient Balance!!!")
				: this.sendOtp(amount);
		},
		async sendOtp(amount) {
			this.amount = amount;

			try {
				this.loading = true;
				await this.$store
					.dispatch("wallets/requestOtp", {
						apiKey: this.getWalletApikey(this.$store),
						url: "request-otp",
					})
					.then(() => {
						this.loading = false;
						this.step = 2;
					});
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		setError(message) {
			this.loading = false;
			this.showErrorLoginNotification(message);
		},

		async withdrawFunds() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/withdrawFunds",
					{
						apiKey: this.getWalletApikey(this.$store),
						payload: {
							amount: parseFloat(this.amount),
							otp: this.otp,
						},
					}
				);

				if (response.status === 200 || response.status === 201) {
					const { status, message } = response.data || {};
					if (status) {
						this.loading = false;
						this.step = 3;
					} else this.setError(message);
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
