<template>
  <div
    id="elBody"
    class="az-body az-body-sidebar az-body-dashboard-nine"
    v-show="isLoading == false"
  >
    <template v-if="checkoutUrl">
      <div v-if="addFrame" class="iframe-container">
        <div class="iframe-subscription">
          <div class="close-iframe" @click="$store.state.checkoutData = {}">
            <Close />
          </div>
          <iframe
            :src="checkoutUrl"
            id="blusaltPayFrame"
            name="frame"
            allowTransparency="true"
          />
        </div>
      </div>
      <iframe
        v-else
        :src="checkoutUrl"
        id="blusaltPayFrame"
        name="frame"
        allowTransparency="true"
      />
    </template>
    <TheSidebar :appSettings="appSettings" />
    <div class="az-content az-content-dashboard-nine">
      <TheTopBar :headerColor="appSettings.header_color" />
      <transition mode="out-in" name="slide" v-if="isLoading == false">
        <!-- <router-view
					:style="{ backgroundColor: appSettings.background_color }"
				></router-view> -->
        <router-view />
      </transition>
      <TheFooter :appSettings="appSettings" />
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheTopBar from "./TheTopBar";
import Close from "./Close";
import TheFooter from "./TheFooter";
import { notifications } from "../../../public/assets/mixins/notifications";
export default {
  mixins: [notifications],
  components: {
    TheSidebar,
    TheTopBar,
    TheFooter,
    Close
  },
  data() {
    return {
      isLoading: true,
      appSettings: {},
      IDLE_TIMEOUT: 600, //10 mintutes after an idle screen
      idlesecondsTimer: 0,
      idleSecondsCounter: 0,
    };
  },
  computed: {
    checkoutUrl() {
      if (
        this.$store &&
        this.$store.state &&
        this.$store.state.checkoutData &&
        this.$store.state.checkoutData.url
      ) {
        return this.$store.state.checkoutData.url;
      }

      return false;
    },

    addFrame() {
      if (
        this.$store &&
        this.$store.state &&
        this.$store.state.checkoutData &&
        this.$store.state.checkoutData.addFrame
      ) {
        return this.$store.state.checkoutData.addFrame;
      }

      return false;
    },
  },
  methods: {
    async receiveMessage(event) {
      const { refetch } = this.$store.state.checkoutData;

      const { data: response, origin } = event || {};

      if (origin === process.env.VUE_APP_BCP_URL) {
        if (response === "close") {
          if (refetch) refetch();
          this.$store.state.checkoutData = {};
        }
        return;
      }

      const { name } = response || {};

      if (name === "close") this.$store.state.checkoutData = {};
      else if (name === "done") {
        refetch && refetch();
        this.$store.state.checkoutData = {};
      }
    },
    async getAppSettingLayout() {
      try {
        let appSettings = await this.$store.dispatch("account/getAppSetting");
        if (appSettings.domain) {
          // white-labelled customer
          this.$store.dispatch("setAppUrlV2", appSettings.domain + "/v2/");
        }
        this.appSettings = appSettings;
      } catch (error) {}
    },
    CheckIdleTime() {
      this.idleSecondsCounter++;
      if (this.idleSecondsCounter >= this.IDLE_TIMEOUT) {
        this.showErrorLoginNotification("You've been logged out");
        this.$store.dispatch("account/logout");
        window.clearInterval(this.idleSecondsTimer);
      }
    },
  },
  async mounted() {
    // idle timer function
    document.onclick = () => {
      this.idleSecondsCounter = 0;
    };
    document.onmousemove = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeypress = () => {
      this.idleSecondsCounter = 0;
    };

    this.idlesecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
    try {
      let apiResponse = await this.$store.dispatch("account/getProfile");
      await this.$store.dispatch("getMetaData");
      this.user = apiResponse;

      if (apiResponse.api_customer.app_settings) {
        await this.getAppSettingLayout();
      }
      this.isLoading = false;
    } catch (error) {}
    window.addEventListener("message", this.receiveMessage, false);
  },
};
</script>

<style scoped>
iframe {
  z-index: 2147483645;
  background: rgba(0, 0, 0, 0.5);
  border: 0px none transparent;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease 0s;
  visibility: visible;
}
</style>
