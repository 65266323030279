<template>
  <div @click="handleClick" class="table-modal-wrapper transaction">
    <div ref="modal" class="table-modal">
      <div style="text-align: right" class="p-3">
        <i
          style="cursor: pointer"
          @click="closeModal"
          class="far fa-times-circle"
        ></i>
      </div>

      <p class="badge custom-badge" @click="copy">
        Transaction Reference: <b>{{ data.reference }}</b>
      </p>

      <div
        class="p-4 border-top"
        style="
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <h4 class="font-weight-bold">Payment Information</h4>
        <div class="content">
          <div class="information">
            <p>Amount</p>
            <span>{{ data.currency }} {{ toCurrency(data.amount, " ") }}</span>
          </div>
          <div class="information">
            <p>Charges</p>
            <span
              >{{ data.currency }}
              {{ toCurrency(data.charges || data.charge, " ") }}</span
            >
          </div>
          <div class="information">
            <p>Payment Method</p>
            <span>{{ titleCase(data.source) }}</span>
          </div>
          <div class="information">
            <p>Status</p>
            <span
              v-if="data.status == 'successful' || data.status == 'approved'"
              class="text-success"
            >
              {{ titleCase(data.status) }}
            </span>
            <span
              v-else-if="
                data.status == 'pending' || data.status == 'processing'
              "
              class="text-warning"
            >
              {{ titleCase(data.status) }}
            </span>
            <span v-else class="text-danger">
              {{ titleCase(data.status) }}
            </span>
          </div>

          <div v-if="data.client_reference" class="information">
            <p>Client Reference</p>
            <span>{{ data.client_reference || "Not Available" }}</span>
          </div>
          <div class="information">
            <p>Transaction Date</p>
            <span>
              {{
                timeDateFormat(data.createdAt).date +
                " " +
                timeDateFormat(data.createdAt).time
              }}
            </span>
          </div>
          <div class="information">
            <p>Transaction Narration</p>
            <span>{{ data.narration || "Not Available" }}</span>
          </div>
        </div>
      </div>

      <div
        v-if="isTerminal"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 20px;
        "
      >
        <h4 class="font-weight-bold">Other Information</h4>
        <div class="content" >
          <div v-if="data.terminalId" class="information">
            <p>Terminal Id</p>
            <span>{{ data.terminalId }}</span>
          </div>
          <div v-if="data.pan" class="information">
            <p>Pan</p>
            <span>{{ cardFormat(data.pan) }}</span>
          </div>
          <div v-if="data.cardCurrency" class="information">
            <p>Card Currency</p>
            <span>{{ titleCase(data.cardCurrency) }}</span>
          </div>
          <div v-if="data.cardScheme" class="information">
            <p>Card Scheme</p>
            <span>{{ titleCase(data.cardScheme) }}</span>
          </div>
          <div v-if="data.stan" class="information">
            <p>Stan</p>
            <span>{{ titleCase(data.stan) }}</span>
          </div>
          <div v-if="data.terminalName" class="information">
            <p>Terminal Name</p>
            <span>{{ titleCase(data.terminalName) }}</span>
          </div>
          <div v-if="data.device" class="information">
            <p>Device Name</p>
            <span>{{ titleCase(data.device) }}</span>
          </div>
          <div v-if="data.deviceOs" class="information">
            <p>Device OS</p>
            <span>{{ titleCase(data.deviceOs) }}</span>
          </div>
          <div v-if="data.serialNumber" class="information">
            <p>Serial Number</p>
            <span>{{ titleCase(data.serialNumber) }}</span>
          </div>
          <div v-if="data.requestIp" class="information">
            <p>Request Ip</p>
            <span>{{ titleCase(data.requestIp) }}</span>
          </div>
          <div v-if="data.message">
            <div v-if="data.status === 'declined'" class="information">
              <p>Reason</p>
              <span>{{ titleCase(data.message) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.metadata && Object.keys(data.metadata).length > 0"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px;
          margin-bottom: 30px;
        "
      >
        <h4 v-if="!(data.metadata && Object.keys(data.metadata).length > 0 && isTerminal)" class="font-weight-bold">Other Information</h4>
        <div class="content" v-if="data.metadata.terminalData">
          <div class="information">
            <p>Terminal Id</p>
            <span>{{ data.metadata.terminalData.terminalId }}</span>
          </div>
          <div class="information">
            <p>Pan</p>
            <span>{{ cardFormat(data.metadata.terminalData.pan) }}</span>
          </div>
          <div class="information">
            <p>Card Currency</p>
            <span>{{ data.metadata.terminalData.cardCurrency }}</span>
          </div>
          <div class="information">
            <p>Card Scheme</p>
            <span>{{ titleCase(data.metadata.terminalData.cardScheme) }}</span>
          </div>
          <div class="information">
            <p>Stan</p>
            <span>{{ titleCase(data.metadata.terminalData.stan) }}</span>
          </div>
          <div class="information">
            <p>Terminal Name</p>
            <span>{{
              titleCase(data.metadata.terminalData.terminalName)
            }}</span>
          </div>
        </div>
        <div class="content">
          <template v-if="data.metadata.response">
            <div v-if="data.metadata.response.message" class="information">
              <p>Response</p>
              <span>
                {{ titleCase(data.metadata.response.message) }}
              </span>
            </div>
          </template>
          <div v-if="data.metadata.session_id" class="information">
            <p>Session ID</p>
            <span>{{ data.metadata.session_id }}</span>
          </div>
          <div v-if="data.metadata.ip" class="information">
            <p>IP Address</p>
            <span>{{ data.metadata.ip }}</span>
          </div>
          <div v-if="data.metadata.invoice_reference" class="information">
            <p>Invoice Reference</p>
            <span>{{ data.metadata.invoice_reference }}</span>
          </div>
          <div v-if="data.metadata.source" class="information">
            <p>Source</p>
            <span>{{ data.metadata.source }}</span>
          </div>

          <template v-if="data.metadata.source_account">
            <h5 class="font-weight-bold mt-2">Sender Details</h5>

            <div
              v-if="data.metadata.source_account.account_number"
              class="information"
            >
              <p>Account Number</p>
              <span>{{
                cardFormat(data.metadata.source_account.account_number)
              }}</span>
            </div>
            <div
              v-if="data.metadata.source_account.account_name"
              class="information"
            >
              <p>Account Name</p>
              <span>{{ data.metadata.source_account.account_name }}</span>
            </div>
            <div
              v-if="data.metadata.source_account.bank_name"
              class="information"
            >
              <p>Bank Name</p>
              <span>{{ data.metadata.source_account.bank_name }}</span>
            </div>
          </template>

          <template v-if="data.metadata.destination">
            <h5 class="font-weight-bold mt-4">Recipients Details</h5>

            <div v-if="data.metadata.destination.recipient" class="information">
              <p>Account Number</p>
              <span>
                {{ cardFormat(data.metadata.destination.recipient) }}
              </span>
            </div>
            <div
              v-if="data.metadata.destination.recipient_name"
              class="information"
            >
              <p>Account Name</p>
              <span>
                {{ data.metadata.destination.recipient_name }}
              </span>
            </div>

            <div v-if="data.metadata.destination.bank_name" class="information">
              <p>Bank Name</p>
              <span>
                {{ data.metadata.destination.bank_name }}
              </span>
            </div>

            <div
              v-else-if="data.metadata.destination.bank_code"
              class="information"
            >
              <p>Bank Name</p>
              <span>
                {{ bankName }}
              </span>
            </div>
            <div v-if="data.metadata.destination.type" class="information">
              <p>Access Medium</p>
              <span>
                {{ titleCase(data.metadata.destination.type) }}
              </span>
            </div>
          </template>

          <template v-else-if="data.metadata.account_number">
            <h5 class="font-weight-bold mt-4">Recipients Details</h5>
            <div class="information">
              <p>Account Number</p>
              <span>{{ cardFormat(data.metadata.account_number) }}</span>
            </div></template
          >

          <template v-if="data.metadata.card">
            <h5 class="font-weight-bold mt-2">Sender Details</h5>
            <div v-if="data.metadata.card.scheme" class="information">
              <p>Access Medium</p>
              <span>Card</span>
            </div>

            <div v-if="data.metadata.card.last4" class="information">
              <p>Card Number</p>
              <span> {{ cardFormat(data.metadata.card.last4) }}</span>
            </div>

            <div v-if="data.metadata.card.scheme" class="information">
              <p>Card Type</p>
              <span>{{ titleCase(data.metadata.card.scheme) }}</span>
            </div>

            <div v-if="data.metadata.cardholder" class="information">
              <p>Account Name</p>
              <span>
                {{ data.metadata.card.cardholder || "Not Available" }}
              </span>
            </div>
            <div v-if="data.metadata.card.bankName" class="information">
              <p>Bank Name</p>
              <span>
                {{ data.metadata.card.bankName || "Not Available" }}
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { banks } from "../../../../dataset";
import {
  titleCase,
  timeDateFormat,
  copyToClipboard,
  toCurrency,
} from "@/utils/helpers";
import "./TransactionsTable.css";

export default {
  mixins: [notifications],
  props: {
    data: {
      type: Object,
    },
    isTerminal: {
      type: Boolean,
    },
  },
  computed: {
    bankName() {
      if (this.data.metadata.destination) {
        const { bank_code } = this.data.metadata.destination;
        const bank = banks.find((v) => v.code === bank_code);
        return titleCase(bank.name);
      }
      return "";
    },
  },
  methods: {
    titleCase,
    timeDateFormat,
    toCurrency,
    handleClick(e) {
      if (!e.target.closest(".table-modal")) {
        return this.$emit("close-modal");
      }
    },
    closeModal(e) {
      this.$emit("close-modal");
    },
    copy() {
      const copied = copyToClipboard(this.data.reference);
      copied && this.showSuccessNotification("Transaction Reference Copied!");
    },
    cardFormat(value) {
      let result = "";
      if (value.length >= 4) result = value.substr(-4);
      return value ? `**** **** **** ${result}` : "";
    },
  },
};
</script>
