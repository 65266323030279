<template>
	<Modal
		:id="id"
		:title="'Update Profile'"
		:toggle="closeModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Profile Updated Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row">
			<div class="col-sm-6">
				<CustomInput
					:name="'firstname'"
					v-model="request.firstname"
					:label="'First Name'"
					:placeholder="'Enter first name'"
					:required="true"
					:error="formErrors.firstname"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'lastname'"
					v-model="request.lastname"
					:label="'Last Name'"
					:placeholder="'Enter last name'"
					:required="true"
					:error="formErrors.lastname"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'organization_name'"
					v-model="request.organization_name"
					:label="'Organization Name'"
					:placeholder="'Organization Name'"
					:required="true"
					:error="formErrors.organization_name"
					:handleError="handleError"
				/>
			</div>

			<!-- <div class="col-sm-6">
				<CustomInput
					:name="'phone_number'"
					v-model="request.phone_number"
					:label="'Phone Number'"
					:placeholder="'Enter phone number'"
					:required="true"
					:isNum="true"
					:error="formErrors.phone_number"
					:handleError="handleError"
				/>
			</div> -->

			<div class="col-sm-6">
				<CustomInput
					:name="'address'"
					v-model="request.address"
					:label="'Enter Address'"
					:placeholder="'Enter Address'"
					:required="true"
					:error="formErrors.address"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomSelect
					name="country"
					label="Country"
					:optionLabel="'name'"
					placeholder="Select Country"
					:options="countries"
					v-model="request.country"
					:required="true"
					:error="formErrors.country"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomSelect
					name="state"
					label="State"
					:optionLabel="'state_label'"
					placeholder="Select State"
					:options="states"
					v-model="request.state"
					:required="true"
					:error="formErrors.state"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'city'"
					v-model="request.city"
					:label="'Enter City'"
					:placeholder="'Enter City'"
					:required="true"
					:error="formErrors.city"
					:handleError="handleError"
				/>
			</div>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:btnName="'Update'"
					:handleCloseModal="closeModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "../../../../utils/Modal/Modal";
import FormButtons from "../../../../utils/FormButtons/FormButtons";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import SuccessState from "../../../../utils/ResponseState/SuccessState";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import Spinner from "../../../../utils/Spinner/Spinner";

export default {
	mixins: [notifications],
	name: "EditProfileModal",
	data() {
		return {
			request: {},
			loading: false,
			successMsg: false,
			formErrors: {},
			states: [],
		};
	},
	props: ["id", "user", "closeModal", "refetch"],
	components: {
		Modal,
		CustomInput,
		CustomSelect,
		SuccessState,
		Spinner,
		FormButtons,
	},
	computed: {
		countries() {
			return this.$store.state.metaData.countries;
		},
	},
	methods: {
		handleRefetch() {
			this.closeModal();
			this.refetch();
		},
		handleSubmit(e) {
			e.preventDefault();
			const { formErrors, isValid } = formValidator(this.request);
			if (isValid) this.editProfile();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},

		async editProfile() {
			let requestData = new FormData();
			const r = { ...this.request };

			requestData.append("firstname", r.firstname);
			requestData.append("lastname", r.lastname);
			requestData.append("organization_name", r.organization_name);
			// requestData.append("phone_number", r.phone_number);
			requestData.append("address", r.address);
			requestData.append("city", r.city);
			requestData.append("state_id", r.state ? r.state.id : "");
			requestData.append("country_id", r.country ? r.country.id : "");

			try {
				this.loading = true;
				await this.$store
					.dispatch("account/editProfile", requestData)
					.then(() => {
						this.loading = false;
						this.successMsg = true;
					});
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.request = {
			firstname: this.user.firstname,
			lastname: this.user.lastname,
			organization_name: this.user.api_customer.organization_name,
			phone_number: this.user.phone_number,
			address: this.user.api_customer.address,
			city: this.user.city,
			state: this.user.state,
			country: this.user.country,
		};
		this.states = this.countries.find(
			(country) => country.id == this.user.country_id
		).states;
	},
	watch: {
		"request.country": function (newVal) {
			this.states = newVal.states;
		},
	},
};
</script>
