export const reservedAccountCsvRows = {
	account_name: "Account Name",
	account_number: "Account Number",
	"Customer.first_name": "First Name",
	"Customer.Last_name": "Last Name",
	"Customer.email": "Email",
	"Customer.phone": "Phone Number",
	total_transactions: "Transaction Count",
	total_transactions_value: "Total Transaction(#)",
	reference: "Account Reference",
	createdAt: "Date Created",
};

export const tableHeadings = {
	account_name: "Account Name",
	reference: "Reference",
	"Customer.business": "Customer Type",
	"Customer.email": "Email",
	"Customer.phone": "Phone Number",
	createdAt: "Date Created",
};
