<template>
	<div class="form-group">
		<template v-if="!hideLabel">
			<label class="input-item-label">Select Bank</label>
			<span class="text-danger pl-1" title="This field is required"
				>*</span
			>
		</template>
		<v-select
			class="v-select"
			placeholder="Select Bank"
			@keydown.enter.native.prevent
			:get-option-label="getLabel"
			label="name"
			:options="banks"
			:value="value"
			v-on:input="$emit('input', $event)"
      @close="$emit('close', $event)"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: "BankSelect",
	data() {
		return {
			loading: false,
			banks: [],
		};
	},
	props: [
		"name",
		"value",
		"error",
		"handleError",
		"showCode",
		"hideLabel",
		"bankWithId",
		"getAllBanks",
	],
	methods: {
		async getBankList() {
			this.loading = true;
			try {
				const URL = this.bankWithId
					? "services/fetchBanks"
					: "fundstransfer/getBankList";
				const response = await this.$store.dispatch(URL);
				const banks = response.data.result || response.data.message;
				this.banks = banks;
				this.getAllBanks && this.getAllBanks(banks);
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.banks = [];
			}
		},
		getLabel(bank) {
			return this.showCode ? `${bank.name} ( ${bank.code} )` : bank.name;
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getBankList();
	},
};
</script>
