<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div class="p-4" v-else>
			<div class="SectionItem no-b-b mb-3 flex__between">
				<p class="title">Payment Links</p>
			</div>

			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreatePaymentLinkButton
								:paymentLinkRef="$refs.paymentLinkRef"
							/>
						</AccessControl>
					</template>
					<template slot="filterForm">
						<PaymentLinkFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('paymentLinkRef')"
							:download="downloadLinks"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<CreatePaymentLinkButton
							:paymentLinkRef="$refs.paymentLinkRef"
						/>
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="paymentLinkRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="url" slot-scope="{ row }">
							<a target="_blank" :href="row.url">{{ row.url }}</a>
						</template>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
							</span>
						</template>
						<template slot="action" slot-scope="{ row }">
							<PaymentLinkActions
								:link="row"
								:copy="copy"
								:paymentLinkRef="$refs.paymentLink"
							/>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../public/assets/mixins/componentData";
import CreatePaymentLinkButton from "./buttons/CreatePaymentLinkButton/CreatePaymentLinkButton";
import DeletePaymentLinkButton from "./buttons/DeletePaymentLinkButton/DeletePaymentLinkButton";
import UpdatePaymentLinkButton from "./buttons/UpdatePaymentLinkButton/UpdatePaymentLinkButton";
import PaymentLinkActions from "./buttons/PaymentLinkActions/PaymentLinkActions";
import PaymentLinkFilters from "./PaymentLinkFilters/PaymentLinkFilters";

const tableHeadings = {
	name: "Name",
	url: "Url",
	createdAt: "Date Created",
	action: "",
};

export default {
	mixins: [notifications, componentData],
	components: {
		CreatePaymentLinkButton,
		DeletePaymentLinkButton,
		UpdatePaymentLinkButton,
		PaymentLinkActions,
		PaymentLinkFilters,
	},

	data() {
		return {
			emptyTitle: "No Payment Link Found",
			...this.tableOptions(tableHeadings, this.size),
		};
	},
	methods: {
		async copy(value) {
			const copied = this.copyToClipboard(value);
			copied && this.showSuccessNotification("Payment Link Copied!");
		},
		refetch(filters) {
			this.handleRefetch(filters, this.getLinks, "paymentLinkRef");
		},
		async getLinks() {
			this.handleAPICall("payment/getPaymentLinks");
		},
		async downloadLinks() {
			const download = (filterData) =>
				this.downloadReport(tableHeadings, filterData.data.data);
			this.downloadTableReport("payment/getPaymentLinks", download);
		},
	},
	created() {
		this.getLinks();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
