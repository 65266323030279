import { getWalletApikey } from "../../../utils/helpers";

export const getRequestData = (store, filters, data, size = 1000000) => {
  const requestData = {
    apiKey: getWalletApikey(store),
    params: {
      size,
      ...data,
      ...filters,
    },
  };
  return requestData;
};

export const agentCsvRows = {
  reference: "Receiver ID",
  email: "Email Address",
  firstName: "First Namee",
  lastName: "Last Namee",
  phoneNumber: "Phone Number",
  created_at: "Date Added",
  status: "Status",
};
