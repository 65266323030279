<template>
  <div class="row flex__center mb-5 py-5">
      <div class="col-sm-12 col-md-8 col-lg-5 text-center">
    
        <img src="/assets/img/icons/empty.svg" alt=""  />

        <h3 v-if="title">{{ title }}</h3>

        <p v-if="text" class="mb-4">{{ text }}</p>

        <slot /> 
    
    </div>
  </div>
</template>

<script>
export default {
    name: "EmptyData",
    props: ["title", "text"]
}
</script>

