import { subscriptionAPI } from "@/services/api";

export default {
	getAllCoupons({ apiKey, params }) {
		return subscriptionAPI.get(`/coupon`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getCoupon({ apiKey, couponCode }) {
		return subscriptionAPI.get(`/coupon/${couponCode}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createCoupon({ apiKey, request }) {
		return subscriptionAPI.post(`/coupon`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateCoupon({ apiKey, request }) {
		return subscriptionAPI.put(`/coupon/${request.couponCode}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	deleteCoupon({ apiKey, couponCode }) {
		return subscriptionAPI.delete(`/coupon/${couponCode}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
