
import { apiClient } from "../api";

import axios from "axios";

let walletsBaseUrl = process.env.VUE_APP_WALLETS_BASE_URL;
let apisBaseUrl = process.env.VUE_APP_BASE_URL_V2;

export default {
  createWallet({ apiKey, request }) {
    return axios.post(`${walletsBaseUrl}/wallets`, request, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },

  activateWallet(payload) {
    return apiClient.post(`Authorize/CreateWalletApi`, payload);
  },

	getWallets({ apiKey, params = {} }) {
		return axios.get(`${walletsBaseUrl}/wallets`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMasterWallet({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/wallets/master`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPaymentMethods({ apiKey }) {
		return apiClient.get(`${walletsBaseUrl}/payment-method`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updatePaymentMethods({ headers, payload }) {
		return apiClient.put(`${walletsBaseUrl}/payment-method`, payload, {
			headers,
		});
	},
	getWallet({ apiKey, reference }) {
		return axios.get(`${walletsBaseUrl}/wallets/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

  getBankProvidersFromCore() {
    return apiClient.get(`Authorize/GetWalletBanks`);
  },

  getWalletInfo(userDetails) {
    let headers = {
      clientid: userDetails.clientID,
    };
    return apiClient.get(`wallets/info/NGN`, headers);
  },
  getCustomers(payload) {
    let { apiKey, params } = payload || {};
    return axios.get(`${walletsBaseUrl}/customers`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getTransactions(apiKey, customer, pagination) {
    let { page, limit, query } = pagination;
    query = JSON.stringify(query);

    let url = `${walletsBaseUrl}/transactions?page=${page}&limit=${limit}&query=${query}`;

    if (customer) {
      url += `&customer=${customer}`;
    }
    return axios.get(url, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  createPaymentCollection(apiKey, body) {
    return axios.post(`${walletsBaseUrl}/wallets/master/collections`, body, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  createPaymentLink(apiKey, body) {
    return axios.post(`${walletsBaseUrl}/paymentlink`, body, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPaymentLinks(apiKey) {
    return axios.get(`${walletsBaseUrl}/paymentlink`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },

  createCustomer(apiKey, body) {
    return axios.post(`${walletsBaseUrl}/customers`, body, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },

  createSubMerchant(merchantDetails) {
    return axios.post(`${walletsBaseUrl}/wallets`, merchantDetails.payload, {
      headers: {
        "x-api-key": merchantDetails.apiKey,
      },
    });
  },

  getWalletHistory({ params }) {
    return apiClient.get(`/Authorize/wallet/transaction`, { params });
  },
  getAPIKeys() {
    return apiClient.get(`/Authorize/Settings/GetKeys`);
  },
  changeKey({ params }) {
    return apiClient.put("/Authorize/Settings/ChangeKeys", {}, { params });
  },
  getCurrencies() {
    return axios.get(`${walletsBaseUrl}/currency`);
  },
  resolveBankName({ apiKey, bank_code, account_number }) {
    return axios.get(`${walletsBaseUrl}/resolve-bank/${account_number}`, {
      params: { bank_code },
      headers: {
        "x-api-key": apiKey,
      },
    });
  },

	// withdrawFunds(withdrawDetails) {
	// 	try {
	// 		let headers = {
	// 			"x-api-key": withdrawDetails.apiKey,
	// 		};
	// 		return axios.put(
	// 			`${walletsBaseUrl}/wallets/${withdrawDetails.walletID}`,
	// 			withdrawDetails.payload,
	// 			{ headers }
	// 		);
	// 	} catch (error) {}
	// },
	withdrawFunds({ apiKey, payload }) {
		return axios.post(`${walletsBaseUrl}/merchant/withdrawal`, payload, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	initializeSDK(walletDetails) {
		try {
			let headers = {
				Authorization: walletDetails.apiKey,
			};
			return axios.post(
				`${walletsBaseUrl}/checkout/initialize`,
				walletDetails.payload,
				{ headers }
			);
		} catch (error) {}
	},
	updateWebHook({ apiKey, params, webhook }) {
		return apiClient.put(
			"/Authorize/Settings/SetWebhook",
			{ webhook },
			{
				params,
				headers: { "x-api-key": apiKey },
			}
		);
	},
	addBank({ apiKey, payload }) {
		return axios.post(`${walletsBaseUrl}/merchant/bank/add`, payload, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateMerchantSettings({ apiKey, request }) {
		return axios.put(`${walletsBaseUrl}/merchant/setting`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateMerchantAccount({ apiKey, request }) {
		return axios.put(`${walletsBaseUrl}/merchant/account`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantWalletSettings({ apiKey }) {
		return axios.get(`${walletsBaseUrl}/merchant/setting/detail`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	merchantToggleSettings({ apiKey, request }) {
		return axios.put(`${walletsBaseUrl}/merchant/setting/toggle`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	requestOtp({ apiKey }) {
		return axios.post(
			`${walletsBaseUrl}/merchant/request-otp`,
			{},
			{
				headers: {
					"x-api-key": apiKey,
				},
			}
		);
	},
  requestMerchantOtp({ headers }) {
		return axios.post(
			`${walletsBaseUrl}/merchant/request-otp`,
      {},
			{ headers }
		);
	},
	getMerchantBanks({ apiKey }) {
		return axios.get(`${walletsBaseUrl}/merchant/bank/detail`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantPayout(apiKey) {
		return axios.get(`${walletsBaseUrl}/merchant/payouts`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getMerchantSubAccounts(apiKey) {
		return axios.get(`${walletsBaseUrl}/subaccounts`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getBankProviders(apiKey) {
		return axios.get(`${walletsBaseUrl}/bank-providers`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createMerchantSubAccounts(subAccountData) {
		return axios.post(
			`${walletsBaseUrl}/subaccounts`,
			subAccountData.payload,
			{
				headers: {
					"x-api-key": subAccountData.apiKey,
				},
			}
		);
	},

  validateSenderMask({ clientid, params }) {
    return axios.get(
      `${apisBaseUrl}Authorize/Messaging/otpSenderMask/validate`,
      {
        params,
        headers: {
          clientid,
        },
      }
    );
  },

  getTransaction(txRef) {
    return axios.get(`${walletsBaseUrl}/checkout/${txRef}`);
  },

	getTransactionStatus(txRef) {
		return axios.get(`${walletsBaseUrl}/checkout/status/${txRef}`);
	},
	getPosTransactions({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/pos/transactions`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPosTerminals({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/pos/terminals`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getTerminalInfo({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/pos/terminals/${params.id}`, {
			// params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getTerminalHistory({ apiKey, params }) {
		return axios.get(
			`${walletsBaseUrl}/pos/terminals/${params.id}/transactions`,
			{
				params,
				headers: {
					"x-api-key": apiKey,
				},
			}
		);
	},

  getChargeBacks({ apiKey, params = {} }) {
		return axios.get(`${walletsBaseUrl}/chargebacks`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
  getChargeBack({ apiKey, reference }) {
		return axios.get(`${walletsBaseUrl}/chargebacks/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
  updateChargeBack({ apiKey, reference, payload }) {
    return axios.put(
      `${walletsBaseUrl}/chargebacks/${reference}`,
      payload,
      {
        headers: {
          "x-api-key": apiKey,
        },
      }
    );
  },

  // -- Untested
  getPaymentCollections(apiKey) {
    return axios.get(`${walletsBaseUrl}/wallets/collections`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPosTransactions({ apiKey, params }) {
    return axios.get(`${walletsBaseUrl}/pos/transactions`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPosTerminals({ apiKey, params }) {
    return axios.get(`${walletsBaseUrl}/pos/terminals`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getTerminalInfo({ apiKey, params }) {
    return axios.get(`${walletsBaseUrl}/pos/terminals/${params.id}`, {
      // params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getTerminalHistory({ apiKey, params }) {
    return axios.get(
      `${walletsBaseUrl}/pos/terminals/${params.id}/transactions`,
      {
        params,
        headers: {
          "x-api-key": apiKey,
        },
      }
    );
  },

  // -- Untested
  getPaymentCollections(apiKey) {
    return axios.get(`${walletsBaseUrl}/wallets/collections`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
};
