<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div class="p-4" v-else>
			<div class="SectionItem no-b-b mb-3">
				<div>
					<p class="title">Payment Overview</p>
					<span class="description"
						>An overview of your payments.
					</span>
				</div>
			</div>

			<div class="flex__end">
				<div class="mr-3 no-mb">
					<CurrencySelect
						:hideLabel="true"
						:name="'currency'"
						v-model="currency"
						:getFirstData="getFirstData"
					/>
				</div>
				<AccessControl>
					<div class="d-flex flex-wrap justify-content-end">
						<div class="mr-3">
							<DepositFundButton
								:wallet="wallet"
								:app="'wallet'"
								:setDefault="true"
								:refetch="reload"
								:email="customerEmail"
							/>
						</div>

						<WithdrawFundButton
							:balance="ngnWallet.balance"
							:refetch="reload"
						/>

						<RequestFundButton :setDefault="true" />
					</div>
				</AccessControl>
			</div>

			<ContentWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="getMasterWallet"
			>
				<template slot="content">
					<WalletInfo :wallet="selectedWallet" /> </template
			></ContentWrapper>

			<div class="row">
				<TotalCustomers :currency="currency.code || 'NGN'" />
				<TotalTransactions :currency="currency.code || 'NGN'" />
			</div>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins";
import WithdrawFundButton from "./buttons/WithdrawFundButton/WithdrawFundButton";
import RequestFundButton from "./buttons/RequestFundButton/RequestFundButton";
import DepositFundButton from "./buttons/DepositFundButton/DepositFundButton";
import TotalCustomers from "./TotalCustomers/TotalCustomers";
import TotalTransactions from "./TotalTransactions/TotalTransactions";
import WalletInfo from "../common/WalletInfo/WalletInfo";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect.vue";
import "./WalletOverview.css";

export default {
	mixins: [componentData],
	data() {
		return {
			currency: "",
			wallet: null,
		};
	},
	components: {
		WithdrawFundButton,
		RequestFundButton,
		DepositFundButton,
		TotalCustomers,
		TotalTransactions,
		WalletInfo,
		CurrencySelect,
	},
	computed: {
		selectedWallet() {
			const code = this.currency.code || "NGN";
			const wallet = this.wallet.find(
				(wallet) => wallet.currency == code
			);

			return wallet;
		},
		ngnWallet() {
			return this.wallet.find((wallet) => wallet.currency == "NGN");
		},
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		customerEmail() {
			return this.$store.state.account.profile.email;
		},
	},
	methods: {
		getFirstData(currency) {
			this.currency = currency;
		},
		async getMasterWallet() {
			try {
				this.loading = true;
				const response = (
					await this.$store.dispatch("wallets/getMasterWallet", {
						apiKey: this.getWalletApikey(this.$store),
					})
				).data;

				this.wallet = response;
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.errorMsg = this.getError(error);
			}
		},
		reload() {
			location.reload();
		},
	},
	async mounted() {
		this.getMasterWallet();
	},
};
</script>

<style scoped>
.no-mb >>> .v-select,
.no-mb >>> [id*="__listbox"] {
	min-width: 135px;
}

.no-mb >>> .form-group {
	margin-bottom: 0px;
}

.no-mb >>> .vs__dropdown-toggle {
	min-height: 37px;
}
</style>
