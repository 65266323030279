<template>
	<div @click="handleClick" class="table-modal-wrapper transaction">
		<div ref="modal" class="table-modal">
			<div style="text-align: right" class="p-3">
				<i
					style="cursor: pointer;"
					@click="closeModal"
					class="far fa-times-circle"
				></i>
			</div>
			<p class="badge custom-badge" @click="copy">
				Transaction Reference: <b>{{ data.id }}</b>
			</p>

			<div
				class="p-4 border-top"
				style="margin-bottom: 10px; display: flex; flex-direction:column; justify-content: center; "
			>
				<h4 class="font-weight-bold">Payment Information</h4>
				<div class="content">
					<div class="information">
						<p>Amount</p>
						<span
							>{{ data.currency }}
							{{ toCurrency(data.amount, " ") }}</span
						>
					</div>
					<div class="information">
						<p>Charges</p>
						<span
							>{{ data.currency }}
							{{ toCurrency(data.charges, " ") }}</span
						>
					</div>
					<div class="information">
						<p>Payment Method</p>
						<span>{{ titleCase(data.source) }}</span>
					</div>
					<div class="information">
						<p>Status</p>
						<span
							v-if="data.active"
							class="text-success"
						>
							{{ titleCase('Active') }}
						</span>
						<span
							v-else-if="
								data.status == 'pending' ||
									data.status == 'processing'
							"
							class="text-warning"
						>
							{{ titleCase(data.status) }}
						</span>
						<span v-else class="text-danger">
							{{ titleCase(data.status) }}
						</span>
					</div>
					<div class="information">
						<p>Bank Code</p>
						<span>{{ titleCase(data.bank_code) }}</span>
					</div>
					<div class="information">
						<p>Bank Number</p>
						<span>{{ titleCase(data.account_number) }}</span>
					</div>
					<div class="information">
						<p>Bank Name</p>
						<span>{{ titleCase(data.bankName) }}</span>
					</div>
					<div class="information">
						<p>Transaction Date</p>
						<span>
							{{
								timeDateFormat(data.createdAt).date +
									" " +
									timeDateFormat(data.createdAt).time
							}}
						</span>
					</div>
					<div class="information">
						<p>Transaction Narration</p>
						<span>{{ data.narration || "Not Available" }}</span>
					</div>
				</div>
				<br>
				<h4 class="font-weight-bold">Sender Information</h4>
				<div class="content">
					<div class="information">
						<p>First Name</p>
						<span
							>{{ data.firstName || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Last Name</p>
						<span
							>{{ data.lastName || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Email</p>
						<span
							>{{ data.email || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Phone Number</p>
						<span
							>{{ data.phone || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Address</p>
						<span
							>{{ data.address || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>State</p>
						<span
							>{{ data.state || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>City</p>
						<span
							>{{ data.city || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Country</p>
						<span
							>{{ data.country || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Post Code</p>
						<span
							>{{ data.postCode || 'Not Available' }}
							</span
						>
					</div>
				</div>
				<br>
				<h4 class="font-weight-bold">Reciever Information</h4>
				<div class="content">
					<div class="information">
						<p>First Name</p>
						<span
							>{{ data.firstName || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Last Name</p>
						<span
							>{{ data.lastName || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Email</p>
						<span
							>{{ data.email || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Phone Number</p>
						<span
							>{{ data.phone || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Address</p>
						<span
							>{{ data.address || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>State</p>
						<span
							>{{ data.state || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>City</p>
						<span
							>{{ data.city || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Country</p>
						<span
							>{{ data.country || 'Not Available' }}
							</span
						>
					</div>
					<div class="information">
						<p>Post Code</p>
						<span
							>{{ data.postCode || 'Not Available' }}
							</span
						>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import {
	titleCase,
	timeDateFormat,
	copyToClipboard,
	toCurrency,
} from "@/utils/helpers";

export default {
    name: "RemittanceTransactionModal",
	mixins: [notifications],
	props: {
		data: {
			type: Object,
		},
	},

	methods: {
		titleCase,
		timeDateFormat,
		toCurrency,
		handleClick(e) {
			if (!e.target.closest(".table-modal")) {
				return this.$emit("close-modal");
			}
		},
		closeModal(e) {
			this.$emit("close-modal");
		},
		copy() {
			const copied = copyToClipboard(this.data.reference);
			copied &&
				this.showSuccessNotification("Transaction Reference Copied!");
		},
	},
};
</script>
