<template>
  <div class="mt-3">
    <div class="SectionItem no-b-b mb-3 flex__between">
      <p class="title">Directors</p>
      <CreateDirectorsBtn @refresh="$emit('refresh')" />
    </div>

    <v-client-table :data="directors" :columns="columns" :options="options">
      <template slot="id_type" slot-scope="props">
        <div>
          {{
            props.row.id_type ? titleCase(props.row.id_type) : "Not Available"
          }}
        </div>
      </template>
      <template slot="action" slot-scope="props">
        <div>
          <i
            @click="handleRowClick(props.row)"
            class="pointer la la-pencil mr-2"
          ></i>
          <i
            @click="handleRowDelete(props.row)"
            class="pointer la la-trash"
          ></i>
        </div>
      </template>
      <template slot="id_image" slot-scope="props">
        <div>
          <a
            v-if="props.row.id_image"
            :href="props.row.id_image"
            :download="`${props.row.first_name} ID Document`"
            target="_blank"
            >ID Document</a
          >
          <template v-else>Not Available</template>
        </div>
      </template>
      <template slot="address_proof" slot-scope="props">
        <div>
          <a
            v-if="props.row.address_proof"
            :href="props.row.address_proof"
            :download="`${props.row.first_name} Proof of Address`"
            target="_blank"
            >Proof of Address</a
          >
          <template v-else>Not Available</template>
        </div>
      </template>
    </v-client-table>
    <EditDirectorModal
      :id="'editDirector'"
      :closeModal="closeModal"
      @refresh="handleRefresh"
      :selected="selected"
    />
    <DeleteDirectorModal
      :id="'deleteDirector'"
      :closeModal="closeDeleteModal"
      @refresh="handleRefresh"
      :selected="selected"
    />
  </div>
</template>

<script>
import { tableOptions, titleCase } from "@/utils/helpers";
import EditDirectorModal from "./modals/EditDirectorModal.vue";
import CreateDirectorsBtn from "./buttons/CreateDirectorsBtn.vue";
import DeleteDirectorModal from "./modals/DeleteDirectorModal.vue";

const tableHeadings = {
  first_name: "Firstname",
  last_name: "Lastname",
  email: "Email",
  bvn: "BVN",
  address: "Address",
  id_image: "ID Document",
  address_proof: "Proof of Address",
  action: "",
};

export default {
  props: ["directors"],
  data() {
    return {
      selected: {},
      ...tableOptions(tableHeadings, 20),
      columns: [
        "first_name",
        "last_name",
        "email",
        "id_type",
        "address",
        "id_image",
        "address_proof",
        "action",
      ],
    };
  },
  components: {
    CreateDirectorsBtn,
    EditDirectorModal,
    DeleteDirectorModal,
  },
  methods: {
    titleCase,
    handleRefresh() {
      this.$emit("refresh");
      this.selected = null;
    },
    openModal() {
      $("#editDirector").modal("show");
    },
    closeModal() {
      $("#editDirector").modal("hide");
    },
    openDeleteModal() {
      $("#deleteDirector").modal("show");
    },
    closeDeleteModal() {
      $("#deleteDirector").modal("hide");
    },
    handleRowClick(director) {
      this.openModal();
      this.selected = JSON.parse(JSON.stringify(director));
    },
    handleRowDelete(director) {
      this.openDeleteModal();
      this.selected = JSON.parse(JSON.stringify(director));
    },
  },
};
</script>
