<template>
	<div>
		<div class="card p-4">
			<div class="border-bottom pb-3 mb-4 flex__between flex-wrap">
				<div class="">
					<h4 class="call-count">
						{{ baseWallet.currency }}
						{{ Number(baseWallet.balance).toLocaleString() }}
					</h4>
					<p class="call-name pt-2">Current NGN Wallet Balance</p>
				</div>

				<div class="">
					<h4 class="call-count">
						{{ toCurrency(user.api_customer.total_credit_loaded) }}
					</h4>
					<p class="call-name pt-2">Total Credit Loaded</p>
				</div>

				<AccessControl>
					<DepositFundButton
						:miniText="'Minimum of NGN50,000.00'"
						:refetch="reload"
						:app="'account'"
						:minAmount="50000"
				/></AccessControl>
			</div>

			<h5 class="font-weight-bold ">Wallet Info</h5>

			<div class="row profile pt-3 ">
				<div class="col-lg-4 col-12">
					<p>Account Name</p>
					<h5>{{ baseWallet.bank.account_name || "None" }}</h5>
				</div>

				<div class="col-lg-4 col-12">
					<p>Account Number</p>
					<div class="d-flex">
						<h5 class="m-0">
							{{ baseWallet.bank.account_number || "None" }}
						</h5>
						<img
							v-if="baseWallet.bank.account_number"
							src="/assets/img/icons/copy.svg"
							class="pointer ml-3"
							alt=""
							@click="copy(baseWallet.bank.account_number)"
						/>
					</div>
				</div>

				<div class="col-lg-4 col-12">
					<p>Bank Name</p>
					<h5>{{ baseWallet.bank.bank_name || "None" }}</h5>
				</div>
			</div>
		</div>

		<WalletHistory />
	</div>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import DepositFundButton from "../../wallet/WalletOverview/buttons/DepositFundButton/DepositFundButton";
import WalletHistory from "./WalletHistory/WalletHistory";
import AccessControl from "@/utils/AccessControl/AccessControl";
import { toCurrency, copyToClipboard } from "@/utils/helpers";

export default {
	mixins: [notifications],
	name: "Wallet",
	data() {
		return {
			user: {},
			baseWallet: this.$store.state.account.baseWallet,
			user: this.$store.state.account.profile,
		};
	},
	components: {
		DepositFundButton,
		WalletHistory,
		AccessControl,
	},
	methods: {
		toCurrency,
		reload() {
			location.reload();
		},
		async copy(value) {
			const copied = copyToClipboard(value);
			copied && this.showSuccessNotification("Account Number Copied!");
		},
	},
};
</script>
