import { posAPI } from "../api";

const posBaseUrl = "/api/v1/agent";

export default {
  getAgents({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getOneAgent({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/${params.id}`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getAgentOnlineTransactions({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/${params.id}/onlineTransaction`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getAgentCashRegister({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/${params.id}/cashRegister`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  updateAgent({ apiKey, payload, id }) {
    return posAPI.put(`${posBaseUrl}/status/${id}`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  addAgent({ apiKey, payload }) {
    return posAPI.post(`${posBaseUrl}/create`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPosTransactionsCashRegister({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/overview/cash`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPosOverviewAgent({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/overview/agent`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPosOverviewTransactions({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/overview/transaction`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getPosMerchantOverview({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/overview/totalValueProcessed`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getVirtualTerminals({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/terminals/virtual`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getVirtualTerminalsDetails({ apiKey, params }) {
    return posAPI.get(`${posBaseUrl}/terminals/virtual/${params.id}`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getVirtualTerminalsTransactions({ apiKey, params }) {
    return posAPI.get(
      `${posBaseUrl}/terminals/virtual/transaction/${params.id}`,
      {
        params,
        headers: {
          "x-api-key": apiKey,
        },
      }
    );
  },
};
