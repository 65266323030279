<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary--border px-4"
      @click="openBeneficiaryModal"
    >
      Add Beneficiary
    </button>
    <AddBeneficiaryModal
      :refetch="refetch"
      :id="'addBeneficiary'"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
import AddBeneficiaryModal from "../../modal/AddBeneficiaryModal/AddBeneficiaryModal.vue";

export default {
  name: "AddBeneficiaryButton",
  props: ["refetch"],
  methods: {
    openBeneficiaryModal() {
      $("#addBeneficiary").modal("show");
    },
    closeModal() {
      $("#addBeneficiary").modal("hide");
    },
  },
  components: {
    AddBeneficiaryModal,
  },
};
</script>
