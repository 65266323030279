<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-3 ml-3"
			@click="openSubAcctModal"
		>
			Add Reserved Account
		</button>

		<CreateReservedAccountModal
			:id="'reservedAccount'"
			:closeModal="closeModal"
			:accountRef="accountRef"
		/>
	</div>
</template>

<script>
	import CreateReservedAccountModal from "../../modals/CreateReservedAccountModal/CreateReservedAccountModal";

	export default {
		name: "CreateReservedAccountButton",
		props: ["accountRef"],
		methods: {
			openSubAcctModal() {
				$("#reservedAccount").modal("show");
			},
			closeModal() {
				$("#reservedAccount").modal("hide");
			},
		},
		components: {
			CreateReservedAccountModal,
		},
	};
</script>
