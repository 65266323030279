// import axios from "axios";
// import Nprogress from "nprogress";

// export const apiClient = axios.create({
// 	baseURL: process.env.VUE_APP_BASE_URL_V2,
// 	headers: {
// 		Accept: "application/json",
// 	},
// });

// apiClient.interceptors.request.use((config) => {
// 	Nprogress.start();
// 	return config;
// });
// apiClient.interceptors.response.use((response) => {
// 	Nprogress.done();
// 	return response;
// });

import axios from "axios";
import Nprogress from "nprogress";

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_V2,
  headers: {
    Accept: "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  Nprogress.start();
  return config;
});

apiClient.interceptors.response.use((response) => {
  Nprogress.done();
  return response;
});

//New

const baseURLs = [
  process.env.VUE_APP_BASE_URL_V2,
  process.env.VUE_APP_SUBSCRIPTION_BASE_URL,
  process.env.VUE_APP_WALLETS_BASE_URL,
  process.env.VUE_APP_PRODUCT_BASE_URL,
  process.env.VUE_APP_BASE_URL_CORE,
  process.env.VUE_APP_POS_BASE_URL,
];

const APIs = [];

baseURLs.forEach((url) => {
  let API = axios.create({
    baseURL: url,
    headers: {
      Accept: "application/json",
    },
  });

  API.interceptors.request.use((config) => {
    Nprogress.start();
    return config;
  });

	API.interceptors.response.use(
		async (response) => {
		  Nprogress.done();
			return response;
		},
		async (error) => {
		  Nprogress.done();
			return Promise.reject(error.response);
		}
	);

  APIs.push(API);
});

export default APIs[0];
export const subscriptionAPI = APIs[1];
export const walletAPI = APIs[2];
export const productAPI = APIs[3];
export const baseAPI = APIs[4];
export const posAPI = APIs[5];
