
<template>
    <div class="az-content-body">
        <!-- Top Title bar -->
        <div class="row">
            <div class="col-lg-12">
                <div class="SectionItem">
                    <div class="SectionHeader">
                        <span class="title">Applications</span>
                        <div class="description">
                            Explore our API, start testing how to utilize the
                            various Identity Verification Services. Create an
                            Application to get your access credentials
                        </div>
                    </div>
                    <AccessControl>
                        <div class="content" v-if="profile.is_active">
                            <a
                                href="#"
                                class="btn btn-md btn-primary"
                                data-toggle="modal"
                                data-effect="effect-scale"
                                data-backdrop="static"
                                data-target="#apikeyCreateNew"
                            >
                                <i class="la la-plus"></i> Create Application
                            </a>
                        </div></AccessControl
                    >
                </div>
                <div class="message-info" v-if="env == 'development'">
                    The Sandbox environment is a complete replica of the BluSalt
                    production environment, supporting all of the same API
                    endpoints. All data and money within the Sandbox is test
                    data and completely separate from your production account.
                    Happy testing!
                </div>
            </div>
        </div>

        <!-- Application Content area -->
        <Loader v-if="isProcessing == true"></Loader>
        <div class="row" v-if="isProcessing == false && profile.is_active">
            <div class="col-md-12 col-lg-12 col-xl-12">
                <nav class="nav az-nav-line nav-line-user-account mg-b-40">
                    <a
                        href="#active"
                        data-toggle="tab"
                        class="nav-link active show"
                        >Active Applications</a
                    >
                    <a href="#in-active" data-toggle="tab" class="nav-link"
                        >Deactivated</a
                    >
                </nav>
            </div>
            <div class="col-12 tab-content">
                <div id="active" class="row tab-pane active show">
                    <div class="col-lg-12" v-if="activeApps.length > 0">
                        <div
                            class="app-key-wrap accordion accordion-s2"
                            id="applications"
                            v-for="(app, i) in activeApps"
                            :key="i"
                        >
                            <div class="app-key-item-wrapper accordion-item">
                                <div
                                    class="app-key-list-item accordion-heading"
                                    data-toggle="collapse"
                                    :data-target="`#app${i}`"
                                >
                                    <div class="app-key-list-wrap">
                                        <div
                                            class="app-item-icon justify-column"
                                        >
                                            <em
                                                class="app-icon la la-cube"
                                                :class="{
                                                    isBundled:
                                                        app.billing_type_id ==
                                                        2,
                                                }"
                                            ></em>
                                        </div>
                                        <div class="app-name justify-column">
                                            <div class="app-name-title">
                                                <div class="ellipsis-text">
                                                    {{ app.app_name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <AccessControl :cls="'flex__between w-100'">
                                        <div class="app-secret-key-wrap">
                                            <div
                                                class="copy-wrap mgb-1-5x mgt-1-5x"
                                            >
                                                <span
                                                    class="copy-feedback"
                                                    style="display: none;"
                                                    >Copied to Clipboard</span
                                                >
                                                <em class="fas fa-link">
                                                    <span
                                                        style="font-family: AllianzNeo, sans-serif; marling-left: 10px;"
                                                        >API KEY</span
                                                    >:
                                                </em>
                                                <input
                                                    type="text"
                                                    class="copy-address"
                                                    :value="app.api_key"
                                                    disabled
                                                    style="margin-left: 60px; font-family: inherit"
                                                />
                                                <!-- <button class="copy-trigger copy-clipboard" :data-clipboard-text="app.api_key">
                    <em class="la la-copy"></em>
                      </button>-->
                                            </div>
                                        </div>

                                        <div
                                            class="app-key-action-wrap ellipsis"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title
                                            data-original-title="more options"
                                        >
                                            <span
                                                role="button"
                                                data-toggle="dropdown"
                                                class="dropdown-title"
                                            ></span>
                                            <div class="dropdown-menu">
                                                <a
                                                    href
                                                    class="dropdown-item"
                                                    @click.prevent="
                                                        regenerateKey(
                                                            app.app_name
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="la la-refresh"
                                                    ></i>
                                                    Regenerate key
                                                </a>
                                                <a
                                                    href
                                                    class="dropdown-item"
                                                    v-if="
                                                        app.billing_type_id == 2
                                                    "
                                                    @click.prevent="
                                                        initializeApp(app)
                                                    "
                                                >
                                                    <i class="la la-cogs"></i>
                                                    Initialize App
                                                </a>
                                                <a
                                                    href
                                                    @click="populateModal(app)"
                                                    class="dropdown-item"
                                                    data-toggle="modal"
                                                    data-effect="effect-scale"
                                                    data-backdrop="static"
                                                    data-target="#editApplicationService"
                                                >
                                                    <i class="la la-trash"></i>
                                                    Edit Services
                                                </a>
                                                <a
                                                    href
                                                    v-if="
                                                        app.is_app_credit_limited ==
                                                            1
                                                    "
                                                    @click="populateModal(app)"
                                                    class="dropdown-item"
                                                    data-toggle="modal"
                                                    data-effect="effect-scale"
                                                    data-backdrop="static"
                                                    data-target="#loadApplicationCredit"
                                                >
                                                    <i class="la la-trash"></i>
                                                    Load Credit
                                                </a>

                                                <a
                                                    href
                                                    @click="populateModal(app)"
                                                    class="dropdown-item"
                                                    data-toggle="modal"
                                                    data-effect="effect-scale"
                                                    data-backdrop="static"
                                                    data-target="#editApplication"
                                                >
                                                    <i class="la la-edit"></i>
                                                    Edit Application
                                                </a>

                                                <a
                                                    href
                                                    @click="populateModal(app)"
                                                    class="dropdown-item"
                                                    data-toggle="modal"
                                                    data-effect="effect-scale"
                                                    data-backdrop="static"
                                                    data-target="#DeactivateApplicationModal"
                                                >
                                                    <i class="la la-trash"></i>
                                                    Deactivate App
                                                </a>
                                            </div>
                                        </div>
                                    </AccessControl>
                                </div>

                                <div
                                    :id="`app${i}`"
                                    class="collapse"
                                    data-parent="#applications"
                                    style
                                >
                                    <div class="app-content accordion-content">
                                        <div class="row">
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Client ID:
                                                    <span>{{
                                                        apps.client_id
                                                    }}</span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5
                                                    class="sub-heading"
                                                    v-tooltip.top="
                                                        'Unlimited (Limited only to total number of available Credits)'
                                                    "
                                                    title="Unlimited (Limited only to total number of available Credits)"
                                                >
                                                    Total Credit Loaded:
                                                    <span>
                                                        {{
                                                            app.is_app_credit_limited ==
                                                            0
                                                                ? `Unlimited`
                                                                : app.api_credit_limit
                                                        }}
                                                    </span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Application Name:
                                                    <span>{{
                                                        app.app_name
                                                    }}</span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Total API Calls:
                                                    <span>{{
                                                        app.total_api_calls
                                                    }}</span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Active Status:
                                                    <span
                                                        v-if="
                                                            app.is_active == 1
                                                        "
                                                        class="badge badge-success badge-app"
                                                        >Active</span
                                                    >
                                                    <span
                                                        v-else
                                                        class="badge badge-danger badge-app"
                                                        >Inactive</span
                                                    >
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Used Credit:
                                                    <span>{{
                                                        app.used_credit_on_app
                                                    }}</span>
                                                </h5>
                                                <p class="sub-content"></p>
                                            </div>

                                            <div
                                                class="col-lg-6 col-md-6 col-sm-12"
                                                v-if="app.billing_type"
                                            >
                                                <h5 class="sub-heading">
                                                    Billing Type:
                                                    <span>{{
                                                        app.billing_type
                                                            .billing_type_label
                                                    }}</span>
                                                </h5>
                                            </div>

                                            <div
                                                class="col-lg-6 col-md-6 col-sm-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Application Balance:
                                                    <span>{{
                                                        app.is_app_credit_limited ==
                                                        0
                                                            ? "Unlimited"
                                                            : app.app_balance
                                                    }}</span>
                                                </h5>
                                            </div>

                                            <div
                                                class="col-lg-12 col-md-6 col-sm-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Services Registered:
                                                    <span
                                                        v-for="(service,
                                                        i) in app.app_services"
                                                        :key="i"
                                                        class="badge badge-service badge-app ml-1 mb-1 mr-1"
                                                        >{{
                                                            service.service_label
                                                        }}</span
                                                    >
                                                </h5>
                                            </div>
                                            <AccessControl :cls="'w-100'">
                                                <div
                                                    class="col-lg-12 col-md-6 col-sm-12 flex__between"
                                                >
                                                    <h5
                                                        style="display: flex;  min-width: 80px; font-weight:600; text-align: center;  align-items: center;  font-size: 18px;"
                                                    >
                                                        API KEY:
                                                    </h5>
                                                    <div
                                                        class="copy-wrap mgb-1-5x mgt-1-5x"
                                                    >
                                                        <span
                                                            class="copy-feedback"
                                                            style="display: none;"
                                                            >Copied to
                                                            Clipboard</span
                                                        >
                                                        <em
                                                            class="fas fa-link"
                                                        ></em>
                                                        <input
                                                            type="text"
                                                            class="copy-address"
                                                            :value="app.api_key"
                                                            disabled
                                                        />
                                                        <button
                                                            class="copy-trigger copy-clipboard"
                                                            :data-clipboard-text="
                                                                app.api_key
                                                            "
                                                        >
                                                            <em
                                                                class="la la-copy"
                                                            ></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </AccessControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-md-12 col-lg-12"
                        v-if="activeApps.length == 0"
                    >
                        <DefaultContent
                            :image="'empty-resources.png'"
                            :title="'No application created yet'"
                            :subtitle="
                                'Start by creation applications and registering services.'
                            "
                        >
                            <a
                                href="#"
                                class="btn btn-lg btn-primary"
                                data-toggle="modal"
                                data-effect="effect-scale"
                                data-backdrop="static"
                                data-target="#apikeyCreateNew"
                                v-if="userRole !== 'Secondary Customer'"
                            >
                                <i class="la la-plus"></i> Create application
                            </a>
                        </DefaultContent>
                    </div>
                </div>
                <div id="in-active" class="row tab-pane">
                    <div class="col-lg-12" v-if="inactiveApps.length > 0">
                        <div
                            class="app-key-wrap accordion accordion-s2"
                            id="applications"
                            v-for="(app, i) in inactiveApps"
                            :key="i"
                        >
                            <div class="app-key-item-wrapper accordion-item">
                                <div
                                    class="app-key-list-item accordion-heading"
                                    data-toggle="collapse"
                                    :data-target="`#app${i}`"
                                >
                                    <div class="app-key-list-wrap">
                                        <div
                                            class="app-item-icon justify-column"
                                        >
                                            <em
                                                class="app-icon la la-cube"
                                                :class="{
                                                    isBundled:
                                                        app.billing_type_id ==
                                                        2,
                                                }"
                                            ></em>
                                        </div>
                                        <div class="app-name justify-column">
                                            <div class="app-name-title">
                                                <div class="ellipsis-text">
                                                    {{ app.app_name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="app-secret-key-wrap">
                                        <div
                                            class="copy-wrap mgb-1-5x mgt-1-5x"
                                        >
                                            <span
                                                class="copy-feedback"
                                                style="display: none;"
                                                >Copied to Clipboard</span
                                            >
                                            <em class="fas fa-link">
                                                <span
                                                    style="font-family: AllianzNeo, sans-serif; marling-left: 10px;"
                                                    >API KEY</span
                                                >:
                                            </em>
                                            <input
                                                type="text"
                                                class="copy-address"
                                                :value="app.api_key"
                                                disabled
                                                style="margin-left: 60px; font-family: inherit"
                                            />
                                            <!-- <button class="copy-trigger copy-clipboard" :data-clipboard-text="app.api_key">
                    <em class="la la-copy"></em>
                      </button>-->
                                        </div>
                                    </div>
                                    <div
                                        class="app-key-action-wrap ellipsis"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        data-original-title="more options"
                                    >
                                        <span
                                            role="button"
                                            data-toggle="dropdown"
                                            class="dropdown-title"
                                        ></span>
                                        <div class="dropdown-menu">
                                            <a
                                                href
                                                class="dropdown-item"
                                                style="border-top:none"
                                                @click.prevent="
                                                    toggleApplicationStatus(app)
                                                "
                                            >
                                                <i class="la la-refresh"></i>
                                                Activate Application
                                            </a>
                                            <a
                                                href
                                                class="dropdown-item"
                                                @click.prevent="
                                                    toggleApplicationStatus(app)
                                                "
                                                v-if="false"
                                            >
                                                <i class="la la-refresh"></i>
                                                Activate Application
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :id="`app${i}`"
                                    class="collapse"
                                    data-parent="#applications"
                                    style
                                >
                                    <div class="app-content accordion-content">
                                        <div class="row">
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Client ID:
                                                    <span>{{
                                                        apps.client_id
                                                    }}</span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5
                                                    class="sub-heading"
                                                    v-tooltip.top="
                                                        'Unlimited (Limited only to total number of available Credits)'
                                                    "
                                                    title="Unlimited (Limited only to total number of available Credits)"
                                                >
                                                    Total Credit Loaded:
                                                    <span>
                                                        {{
                                                            app.is_app_credit_limited ==
                                                            0
                                                                ? `Unlimited`
                                                                : app.api_credit_limit
                                                        }}
                                                    </span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Application Name:
                                                    <span>{{
                                                        app.app_name
                                                    }}</span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Total API Calls:
                                                    <span>{{
                                                        app.total_api_calls
                                                    }}</span>
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Active Status:
                                                    <span
                                                        v-if="
                                                            app.is_active == 1
                                                        "
                                                        class="badge badge-success badge-app"
                                                        >Active</span
                                                    >
                                                    <span
                                                        v-else
                                                        class="badge badge-danger badge-app"
                                                        >Inactive</span
                                                    >
                                                </h5>
                                            </div>
                                            <div
                                                class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Used Credit:
                                                    <span>{{
                                                        app.used_credit_on_app
                                                    }}</span>
                                                </h5>
                                                <p class="sub-content"></p>
                                            </div>
                                            <div
                                                class="col-lg-12 col-md-6 col-sm-12"
                                            >
                                                <h5 class="sub-heading">
                                                    Services Registered:
                                                    <span
                                                        v-for="(service,
                                                        i) in app.app_services"
                                                        :key="i"
                                                        class="badge badge-service badge-app ml-1 mb-1 mr-1"
                                                        >{{
                                                            service.service_label
                                                        }}</span
                                                    >
                                                </h5>
                                            </div>

                                            <div
                                                class="col-lg-12 col-md-6 col-sm-12 d-flex"
                                            >
                                                <h5
                                                    style="display: flex;  min-width: 80px; font-weight:600; text-align: center;  align-items: center;  font-size: 18px;"
                                                >
                                                    API KEY:
                                                </h5>
                                                <div
                                                    class="copy-wrap mgb-1-5x mgt-1-5x"
                                                >
                                                    <span
                                                        class="copy-feedback"
                                                        style="display: none;"
                                                        >Copied to
                                                        Clipboard</span
                                                    >
                                                    <em
                                                        class="fas fa-link"
                                                    ></em>
                                                    <input
                                                        type="text"
                                                        class="copy-address"
                                                        :value="app.api_key"
                                                        disabled
                                                    />
                                                    <button
                                                        class="copy-trigger copy-clipboard"
                                                        :data-clipboard-text="
                                                            app.api_key
                                                        "
                                                    >
                                                        <em
                                                            class="la la-copy"
                                                        ></em>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-md-12 col-lg-12"
                        v-if="inactiveApps.length == 0"
                    >
                        <DefaultContent
                            :image="'empty-resources.png'"
                            :title="'No Inactive Application'"
                            :subtitle="
                                'You currently do not have any inactive application'
                            "
                        ></DefaultContent>
                    </div>
                </div>
            </div>
        </div>
        <DefaultContent
            v-if="isProcessing == false && !profile.is_active"
            :image="'empty-resources.png'"
            :title="`Seems you have not verified your accout.`"
            :subtitle="
                'Verify your account to start testing out the different services. Contact the Admin to verify your account'
            "
        ></DefaultContent>

        <ApplicationModal @refetchApplication="refetchApps"></ApplicationModal>
        <ApplicationInitializationModal
            :initializedAppResult="initializedAppResult"
        ></ApplicationInitializationModal>
        <ApplicationLoadCreditModal
            v-if="modalClicked"
            :modalData="modalData"
            :clientid="apps.client_id"
            :allowableCredit="apps.credit_for_apps_and_sec_customers"
            @refetchApplication="refetchApps"
        ></ApplicationLoadCreditModal>
        <ApplicationEditModal
            v-if="modalClicked"
            :modalData="modalData"
            :allowableCredit="apps.credit_for_apps_and_sec_customers"
            @refetchApplication="refetchApps"
        ></ApplicationEditModal>
        <ApplicationServiceEditModal
            v-if="modalClicked"
            :modalData="modalData"
            @refetchApplication="refetchApps"
        ></ApplicationServiceEditModal>
        <DeactivateApplicationModal
            v-if="modalClicked"
            :modalData="modalData"
            :clientID="clientID"
            @refetchApplication="refetchApps"
        ></DeactivateApplicationModal>
    </div>
</template>

<script>
import ApplicationModal from "../components/modals/ApplicationModal";
import AccessControl from "@/utils/AccessControl/AccessControl";
import ApplicationInitializationModal from "../components/modals/ApplicationInitializationModal";
import ApplicationEditModal from "../components/modals/ApplicationEditModal";
import ApplicationServiceEditModal from "../components/modals/ApplicationServiceEditModal";
import ApplicationLoadCreditModal from "../components/modals/ApplicationLoadCreditModal";

import DeactivateApplicationModal from "../components/modals/DeactivateApplicationModal";

import { notifications } from "../../public/assets/mixins/notifications";

export default {
    mixins: [notifications],
    components: {
        ApplicationEditModal,
        ApplicationServiceEditModal,
        ApplicationModal,
        ApplicationInitializationModal,
        DeactivateApplicationModal,
        ApplicationLoadCreditModal,
        AccessControl,
    },
    data() {
        return {
            apps: [],
            activeApps: [],
            inactiveApps: [],
            isProcessing: true,
            modalData: {},
            modalClicked: false,
            initializedAppResult: {},
        };
    },
    computed: {
        profile() {
            return this.$store.state.account.profile.api_customer;
        },
        userRole() {
            return this.$store.state.account.profile.roles[0].role_label;
        },
        env() {
            return process.env.NODE_ENV;
        },
        clientID() {
            return this.$store.state.account.clientID;
        },
    },
    methods: {
        populateModal(data) {
            this.modalData = { ...data };
            this.modalClicked = true;
        },
        async regenerateKey(appname) {
            try {
                this.apps = await this.$store.dispatch(
                    "application/regenerateKey",
                    {
                      headers: {
                        clientID: this.clientID
                      },
                      payload: { 
                        app_name: appname
                      }
                    }
                );
                this.isProcessing = false;
                this.refetchApps();
                this.showSuccessNotification("API Key generated successfully");
            } catch (error) {
                this.showErrorNotification("Error regenerating API Key");
                this.isProcessing = false;
            }
        },
        async initializeApp(app) {
            let appDetails = { ...app, clientID: this.clientID };
            try {
                console.log("[app details] ->", appDetails);
                let initializedAppResult = await this.$store.dispatch(
                    "application/initializeWebApp",
                    appDetails
                );
                this.showSuccessNotification(initializedAppResult.message);

                // Show Modal for App initilaization
                this.initializedAppResult = initializedAppResult.result;
                $("#ApplicationInitializationModal").modal({
                    show: true,
                    keyboard: false,
                    backdrop: "static",
                });
                // ApplicationInitializationModal
                this.isProcessing = false;
            } catch (error) {
                console.log("[errors getting applications] ->", error);
                this.isProcessing = false;
            }
        },
        async toggleApplicationStatus(app) {
            this.isProcessing = true;
            let appDetails = {
                apikey: app.api_key,
                appname: app.app_name,
                clientid: this.clientID,
                payload: {
                    is_active: !app.is_active,
                },
            };
            try {
                console.log("[app details] ->", appDetails);
                let activationStatus = await this.$store.dispatch(
                    "application/toggleApplicationStatus",
                    appDetails
                );
                this.showSuccessNotification(activationStatus.message);
                this.refetchApps();
                this.isProcessing = false;
            } catch (error) {
                this.showErrorLoginNotification(error.message);
                console.log("[errors getting applications] ->", error);
                this.isProcessing = false;
            }
        },
        refetchApps() {
            this.fetchApps();
        },
        async fetchApps() {
            try {
                let applications = await this.$store.dispatch(
                    "application/getApplications"
                );
                this.apps = applications;

                // filter active Applications
                let activeApps = applications.applications.filter(
                    (app) => app.is_active == 1
                );
                let inactiveApps = applications.applications.filter(
                    (app) => app.is_active == 0
                );
                this.activeApps = activeApps;
                this.inactiveApps = inactiveApps;

                this.isProcessing = false;
            } catch (error) {
                console.log("[errors getting applications] ->", error);
                this.isProcessing = false;
            }
        },
    },
    async mounted() {
        this.fetchApps();
    },
};
</script>

<style scoped>
.copy-wrap {
    position: relative;
    width: 100%;
}
.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #2c80ff;
}
.copy-wrap > [class*="fa"] {
    position: absolute;
    top: 0;
    left: 18px;
    line-height: 20px;
    padding: 12px 0;
    font-size: 14px;
    text-align: center;
    color: #495463;
}

.copy-address {
    cursor: pointer;
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    width: 100%;
    background: #fff;
}

.isBundled {
    color: #ae13ff !important;
}

.copy-trigger {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 38px;
    width: 38px;
    line-height: 20px;
    padding: 10px 0;
    text-align: center;
    color: #758698;
    background: #e9eff9;
    border-radius: 4px;
    border: none;
    transition: all 0.4s;
    cursor: pointer;
}
.app-key-list-item.accordion-heading {
    cursor: pointer;
}
.app-content {
    padding: 20px;
    border-top: 1px solid #e9eff9;
    background: #f9f9f9;
}

h5.sub-heading {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
}
.sub-heading span {
    font-weight: 900;
}
</style>
