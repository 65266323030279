<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openSubModal"
		>
			New Subscription
		</button>
		<CreateSubscriptionModal
			:id="'createSubscription'"
			:closeModal="closeModal"
			:subRef="subRef"
		/>
	</div>
</template>

<script>
	import CreateSubscriptionModal from "../../modals/CreateSubscriptionModal/CreateSubscriptionModal";

	export default {
		name: "CreateSubscriptionButton",
		props: ["subRef"],
		methods: {
			openSubModal() {
				$("#createSubscription").modal("show");
			},
			closeModal() {
				$("#createSubscription").modal("hide");
			},
		},
		components: {
			CreateSubscriptionModal,
		},
	};
</script>
