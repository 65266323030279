<template>
  <div v-if="success" className="d-flex">
    <button class="black btn px-4 mr-3" @click="$emit('refresh')">
      Refresh
    </button>
    <button
      class="btn btn--sm btn-primary px-4"
      @click.prevent="openModal"
      :disabled="isDisabled"
    >
      <span> Send Statement</span>
    </button>
    <AccountStatementModal
      :id="'statementTable'"
      :dates="dates"
      :closeModal="closeModal"
      :params="params"
    />
  </div>
  <div v-else>
    <button
      class="btn btn--sm btn-primary px-4"
      @click="$emit('generate')"
      :disabled="loading"
    >
      <Spinner v-if="loading" /> <span v-else>Generate Statement</span>
    </button>
  </div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";
import AccountStatementModal from "./AccountStatementModal.vue";

export default {
  props: ["success", "loading", "transactions", "dates", "params"],
  components: { Spinner, AccountStatementModal },
  computed: {
    isDisabled() {
      if (
        (this.transactions &&
          (this.transactions.data && this.transactions.data.length) === 0) ||
        (this.transactions.invoices && this.transactions.invoices.length) === 0
      )
        return true;

      return false;
    },
  },
  methods: {
    openModal() {
      if (this.isDisabled) return;
      $("#statementTable").modal("show");
    },
    closeModal() {
      $("#statementTable").modal("hide");
    },
  },
};
</script>
