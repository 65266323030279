<template>
	<Modal :id="id" :title="'Update Payment Link'" :toggle="closeModal">
		<SuccessState
			v-if="successMsg"
			:message="'Payment Link Updated Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit">
			<CustomInput
				:name="'name'"
				v-model="request.name"
				:label="'Name'"
				:placeholder="'Enter a name'"
				:required="true"
				:error="formErrors.name"
				:handleError="handleError"
			/>

			<CustomInput
				:name="'description'"
				v-model="request.description"
				:label="'Description'"
				:placeholder="'Enter description'"
				:required="true"
				:error="formErrors.description"
				:handleError="handleError"
			/>

			<CustomSelect
				:name="'sub_account'"
				label="Sub Account"
				optionLabel="name"
				placeholder="Select Sub Account"
				:options="subAccounts"
				v-model="request.sub_account"
				:error="formErrors.sub_account"
				:handleError="handleError"
			/>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import SuccessState from "@/utils/ResponseState/SuccessState";
import FormButtons from "@/utils/FormButtons/FormButtons";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import Modal from "@/utils/Modal/Modal";
import { getWalletApikey } from "@/utils/helpers";

const initialState = () => ({
	request: {
		name: "",
		description: "",
		sub_account: {},
	},
	loading: false,
	formErrors: {},
	successMsg: false,
});

export default {
	name: "UpdatePaymentLinkModal",
	mixins: [notifications],
	data() {
		return {
			...initialState(),
			subAccounts: [],
		};
	},
	props: ["id", "closeModal", "paymentLinkRef", "payment", "refresh"],
	components: {
		Modal,
		SuccessState,
		CustomInput,
		FormButtons,
		CustomSelect,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		handleRefetch() {
			this.closeModal();
			this.paymentLinkRef && this.paymentLinkRef.refresh();
			this.refresh && this.refresh();
			location.reload();
		},
		resetPayment() {
			this.request = {
				name: this.payment.name,
				description: this.payment.description,
				sub_account: this.payment.SubAccount || {},
			};
		},
		handleCloseModal() {
			this.closeModal();
			this.resetPayment();
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleSubmit() {
			const payload = { ...this.request };
			if (payload.sub_account.reference)
				payload.sub_account = payload.sub_account.reference;
			else delete payload.sub_account;

			const { formErrors, isValid } = formValidator(payload);
			if (isValid) this.updatePaymentLink(payload);
			else this.formErrors = formErrors;
		},
		async updatePaymentLink(payload) {
			try {
				this.loading = true;

				const response = await this.$store.dispatch(
					"payment/updatePaymentLink",
					{
						apiKey: getWalletApikey(this.$store),
						reference:
							this.payment.reference ||
							this.$route.params.reference,
						request: payload,
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async getMerchantSubAccounts() {
			try {
				const subAccounts = await this.$store.dispatch(
					"payment/getMerchantSubAccounts",
					{ apiKey: getWalletApikey(this.$store) }
				);
				this.subAccounts = subAccounts.data.data.data;
			} catch (error) {
				this.subAccounts = [];
			}
		},
	},
	mounted() {
		this.getMerchantSubAccounts();
		this.resetPayment();
	},
};
</script>
