<template>
	<div class="col-sm-6 col-12 justify-content-center">
		<apexchart
			ref="chartRef"
			width="250"
			type="donut"
			:options="options"
			:series="series"
		/>
	</div>
</template>

<script>
export default {
	name: "TransactionsChart",
	props: ["transactions"],

	data() {
		return {
			options: {
				dataLabels: {
					enabled: false,
				},
				labels: ["Succesfull", "Pending", "Failed"],
				legend: {
					position: "bottom",
					fontFamily: "AllianzNeo, sans-serif",
					markers: {
						width: 8,
						height: 8,
					},
				},
				plotOptions: {
					pie: {
						donut: {
							labels: {
								show: true,
								value: {
									show: true,
									fontSize: "20px",
									fontFamily: "AllianzNeo, sans-serif",
									offsetY: 5,
									formatter: (val) => val + "%",
								},
								name: {
									show: false,
								},
							},
						},
					},
				},
			},
			series: [360, 0, 0],
		};
	},
	watch: {
		transactions() {
			this.transactions.data.length > 0 && this.updateChart();
		},
	},
	methods: {
		updateChart() {
			let total = this.transactions.total;
			let suc = 0,
				pen = 0,
				fal = 0;

			this.transactions.data.forEach(({ status }) => {
				if (status == "successful") suc = suc + 1;
				else if (status == "pending") pen = pen + 1;
				else fal = fal + 1;
			});

			suc = (suc * 360) / total;
			pen = (pen * 360) / total;
			fal = (fal * 360) / total;
			this.$refs.chartRef.updateSeries([suc, pen, fal]);
		},
	},
};
</script>
