import wallets from "@/services/wallets/wallets";
import { apiClient } from "@/services/api";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

const handleError = (error) => {
	if (!error.response) {
		throw Error("Network Error, Please check your network and retry");
	} else {
		throw error.response.data;
	}
};

export default {
  namespaced: true,
  actions: {
    async createWallet({}, payload) {
      try {
        let response = await wallets.createWallet(payload);
        return response;
      } catch (error) {
        handleError(error);
      }
    },

    async activateWallet({}, payload) {
      try {
        return await wallets.activateWallet(payload);
      } catch (error) {
        handleError(error);
      }
    },

    async getBankProvidersFromCore() {
      try {
        let response = await wallets.getBankProvidersFromCore();
        return response;
      } catch (error) {
        handleError(error);
      }
    },

    async getPaymentLinks({ commit }, { apiKey }) {
      try {
        let { data } = await wallets.getPaymentLinks(apiKey);
        data.data.data.forEach(
          (link) =>
            (link.url = `${process.env.VUE_APP_CHECKOUT_URL}/p/${link.reference}`)
        );
        return data;
      } catch (error) {
        handleError(error);
      }
    },
    async createPaymentLink({ commit }, { apiKey, body }) {
      try {
        let { data } = await wallets.createPaymentLink(apiKey, body);
        return data;
      } catch (error) {
        handleError(error);
      }
    },
    async getCustomers({ commit }, { apiKey, query }) {
      try {
        let { data } = await wallets.getCustomers(apiKey, query);
        return data;
      } catch (error) {
        handleError(error);
      }
    },
    async getTransactions({ commit }, { apiKey, customer, query }) {
      try {
        let { data } = await wallets.getTransactions(apiKey, customer, query);
        return data;
      } catch (error) {
        handleError(error);
      }
    },
    async createPaymentCollection({ commit }, { apiKey, body }) {
      try {
        let { data } = await wallets.createPaymentCollection(apiKey, body);
        return data;
      } catch (error) {
        handleError(error);
      }
    },
    async createCustomer({ commit }, { apiKey, body }) {
      try {
        let { data } = await wallets.createCustomer(apiKey, body);
        return data;
      } catch (error) {
        handleError(error);
      }
    },

		async getWallets({}, payload) {
			return await wallets.getWallets(payload);
		},

		async getMasterWallet({}, payload) {
			try {
				let { data } = await wallets.getMasterWallet(payload);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getPaymentMethods({}, requestData) {
			try {
				let { data } = await wallets.getPaymentMethods(requestData);
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async updatePaymentMethods({}, requestData) {
			try {
				let { data } = await wallets.updatePaymentMethods(requestData);
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getWallet({}, payload) {
			try {
				let response = await wallets.getWallet(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},

		async getWalletInfo({ commit }, { userData }) {
			try {
				let { data } = await wallets.getWalletInfo(userData);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getWalletHistory({}, payload) {
			try {
				let { data } = await wallets.getWalletHistory(payload);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getCurrencies() {
			try {
				let { data } = await wallets.getCurrencies();
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getAPIKeys() {
			try {
				let { data } = await wallets.getAPIKeys();
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async changeKey({}, key) {
			try {
				let response = await wallets.changeKey(key);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async resolveBankName({}, payload) {
			try {
				let response = await wallets.resolveBankName(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async withdrawFunds({}, walletDetails) {
			try {
				return await wallets.withdrawFunds(walletDetails);
			} catch (error) {
				handleError(error);
			}
		},
		async initializeSDK({ commit }, walletDetails) {
			try {
				let { data } = await wallets.initializeSDK(walletDetails);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getPaymentCollections({ commit }, { apiKey }) {
			try {
				let { data } = await wallets.getPaymentCollections(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async updateWebHook({}, webHookData) {
			try {
				let response = await wallets.updateWebHook(webHookData);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async addBank({}, bankData) {
			try {
				let response = await wallets.addBank(bankData);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async updateMerchantSettings({}, payload) {
			try {
				let response = await wallets.updateMerchantSettings(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async getMerchantPayout({ commit }, apiKey) {
			try {
				let { data } = await wallets.getMerchantPayout(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getMerchantBanks({}, payload) {
			try {
				let response = await wallets.getMerchantBanks(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async getMerchantWalletSettings({}, payload) {
			try {
				let response = await wallets.getMerchantWalletSettings(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async merchantToggleSettings({}, payload) {
			try {
				let response = await wallets.merchantToggleSettings(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async requestOtp({}, payload) {
			try {
				let response = await wallets.requestOtp(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
    async requestMerchantOtp({}, otpData) {
      try {
				let { data } = await wallets.requestMerchantOtp(otpData);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getMerchantSubAccounts({}, apiKey) {
			try {
				let { data } = await wallets.getMerchantSubAccounts(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getBankProviders({ commit }, apiKey) {
			try {
				let { data } = await wallets.getBankProviders(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async createMerchantSubAccounts({ commit }, submerchantData) {
			try {
				let { data } = await wallets.createMerchantSubAccounts(
					submerchantData
				);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async validateSenderMask({}, payload) {
			try {
				let response = await wallets.validateSenderMask(payload);
				return response;
			} catch (error) {
				handleError(error);
			}
		},
		async getTransaction({}, txRef) {
			try {
				return await wallets.getTransaction(txRef);
			} catch (error) {
				throw Error(error);
			}
		},
		async getTransactionStatus({}, txRef) {
			try {
				return await wallets.getTransactionStatus(txRef);
			} catch (error) {
				throw Error(error);
			}
		},
		async updateMerchantAccount(_, merchantData) {
			return await wallets.updateMerchantAccount(merchantData);
		},
		async getPosTransactions({}, apiKey) {
			try {
				let { data } = await wallets.getPosTransactions(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getPosTerminals({}, apiKey) {
			try {
				let { data } = await wallets.getPosTerminals(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getTerminalInfo({}, apiKey) {
			try {
				let { data } = await wallets.getTerminalInfo(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async getTerminalHistory({}, apiKey) {
			try {
				let { data } = await wallets.getTerminalHistory(apiKey);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
    async getChargeBacks({}, payload) {
			try {
				return await wallets.getChargeBacks(payload);
			} catch (error) {
				handleError(error);
			}
		},
    async getChargeBack({}, payload) {
      try {
        return await wallets.getChargeBack(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async updateChargeBack({}, payload) {
      try {
        return await wallets.updateChargeBack(payload);
      } catch (error) {
        handleError(error);
      }
    },
	},
	getters: {},
};
