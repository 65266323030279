<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">Body Outlining Request form</h2>
                <h5 class="user-profile-email sub">
                  Extract the body outline from an image.
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="handleSubmit">
              <div class="row mt-4">
                <div class="col-12 mb-3">
                  <label class="input-item-label">
                    Select Image Type
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select image type"
                    v-model="type"
                    @keydown.enter.native.prevent
                    :required="!type"
                    :disabled="!application"
                    :options="[
                      { label: 'File', value: 'file' },
                      { label: 'Base64', value: 'base64' },
                    ]"
                    label="label"
                  ></v-select>
                  <ul
                    class="parsley-errors-list filled"
                    v-show="$v.type.$error"
                  >
                    <li class="parsley-required">Image type is required.</li>
                  </ul>
                </div>
                <div v-if="type.value == 'file'" class="col-12">
                  <div class="form-group">
                    <CustomUpload
                      :name="'logo'"
                      :label="'Image'"
                      :required="true"
                      :validFileTypes="['.png', '.jpg', '.jpeg']"
                      :handleChange="handleUpload"
                      :fileName="request.image && request.image.name"
                      :error="uploadError && (!request.image ? 'This field is required' : uploadErrorSize ? 'File size must be less than 5MB' : null)"
                  />
                  </div>
                </div>

                <div v-if="type.value == 'base64'" class="col-12">
                  <div class="form-group">
                    <label class="input-item-label">
                      Image
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <textarea
                      v-model="request.image"
                      class="form-control input-bordered"
                      placeholder="Base64 Image"
                      rows="4"
                      cols="50"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-if="$v.request.image.$error"
                    >
                      <span class="text-danger">This field is required</span>
                    </ul>
                  </div>
                </div>

                <!-- Application details -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="loading == true || !hasService"
                  >
                    <Spinner v-if="loading" />
                    <span v-else>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Body Outline Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from body outline request
                made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content">
            <p class="invoice-info-row preview-row">
              <span>Preview of image</span>
            </p>
            <div class="invoice-info-row preview-row">
              <span>Image</span>

              <div class="compare-image">
                <img
                  width="200px"
                  height="200px"
                  style="background-size: cover; background-color: white"
                  :src="`data:image/png;base64, ${verificationResult.body_image}`"
                  :alt="`image for second comapred file`"
                />
              </div>
            </div>
            <p class="invoice-info-row preview-row">
              <span>Reference</span>
              <span>{{ verificationResult.reference }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}IdentityVerification/BodyOutline`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
          <!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
          <!-- "dob": "{{dob}}" -->
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
       {{
            `
              {
                ${type.value == "base64" ? "image_base64" : "image_file"}: ${request.image ? request.image.name ? request.image.name : this.request.image.substring(
          0,
          200
        ) + '...' : "image"},
              }
            `
      }}
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
            {
              "status_code": 200,
              "status": "success",
              "message": "successfully returned image comparison result",
              "data": {
                "time_used": "200",
                "reference": "reference",
                "body_image": "base64_image",
              }
            }`}}
      </code></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import Spinner from "@/utils/Spinner/Spinner";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    request: { image: { required } },
    app: { required },
    type: { required },
    // apiVersion: { required },
  },
  data() {
    return {
      app: "",
      versions: ["v1", "v2"],
      apiVersion: "",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      request: {
        image: "",
      },
      hasNewResponse: false,
      logData: [],
      type: "",
      mediaData: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      uploadError: "",
      uploadSecondError: "",
      apiResponse: "",

      // services
      hasService: false,
      servicePrice: "",

      loading: false,
      verificationResult: [],
    };
  },
  components: {
    Spinner,
    CustomUpload
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    async handleUpload({ target: { files } }) {
      this.request.image = null;

			const file = files[0];
			if (file){
				const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.request.image = file;
          this.uploadError = false;
          this.uploadErrorSize = false;
        } else {
          this.uploadErrorSize = true;
          this.uploadError = true;
        }
      } 
		},
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Body Outlining")) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      const payload = new FormData();

      if (this.type.value == "base64") {
        payload.append("image_base64", this.request.image);
      } else {
        payload.append("image_file", this.request.image);
      }

      const headers = {
        clientid: this.$store.state.account.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
      };

      try {
        const response = await this.$store.dispatch("services/bodyOutline", {
          headers,
          payload,
        });

        this.verificationResult = JSON.parse(
          JSON.stringify(response.data.data)
        );
        this.apiResponse = JSON.parse(JSON.stringify(response.data));
        this.apiResponse.data.body_image = `${this.apiResponse.data.body_image.substring(
          0,
          200
        )}...`;
        this.loading = false;
        this.successMsg = true;
        this.hasNewResponse = true;

        this.loading = false;

        return;
      } catch (error) {
        this.loading = false;

        typeof error.message === "string"
          ? this.showErrorLoginNotification(error.message)
          : this.showErrorLoginNotification("Error Validating Liveness");
        return;
      }
    },
  },
  watch: {
    type: function () {
      this.request.image = null;
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>

<style scoped>
.compare-image {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}
</style>
