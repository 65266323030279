<template>
	<div id="initiatePaymentCollectionModal" class="modal">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content modal-content-demo">
				<div class="modal-body">
					<button
						type="button"
						class="close pos-absolute t-20 r-30 tx-26"
						data-dismiss="modal"
						aria-label="Close"
						id="close-AddTeamMember"
					>
						<span aria-hidden="true">&times;</span>
					</button>

					<h5 class="modal-title mg-b-5">
						Initiate Payment Collection
					</h5>
					<p class="fs-14">Initiate a new payment collection</p>
					<div class="b-b"></div>
					<div class="gaps-2x"></div>

					<div v-if="collectionCreated" class="row">
						<div class="col-12 px-5">
							<div>
								<div class="col-sm-12 pb-4">
									<h3 class="text-center themed-text">
										<strong>Payment Details</strong>
									</h3>
								</div>
							</div>
							<div class="col-12">
								<label
									class="input-item-label text-center pb-2"
								>
									Kindly pay {{ currency }}
									{{ Number(amount).toLocaleString() }} to the
									account details shown below using your
									mobile/internet banking app
								</label>

								<table class="table table-bordered pt-3">
									<tbody>
										<tr>
											<td>Amount</td>
											<td>
												<strong>
													{{ currency }}
													{{
														Number(
															amount
														).toLocaleString()
													}}
												</strong>
											</td>
										</tr>
										<tr>
											<td>Account Number</td>
											<td>
												<strong
													>{{
														createdCollection.account_number
													}}
												</strong>
											</td>
										</tr>
										<tr>
											<td>Bank Name</td>
											<td>
												<strong
													>{{
														createdCollection.bank_name
													}}
												</strong>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<form v-else @submit.prevent="initializePaymentCollection">
						<div class="form-group">
							<label class="input-item-label">
								Amount
								<span
									class="text-danger"
									title="This field is required"
									>*</span
								>
							</label>
							<input
								type="number"
								class="form-control input-bordered"
								placeholder="Enter amount"
								v-model="amount"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.amount.$error"
							>
								<li class="parsley-required">
									Amount is required.
								</li>
							</ul>
						</div>

						<div v-if="!addSplitPayment" class="form-group">
							<v-client-table
								v-if="splitPayments.length > 0"
								:data="splitPayments"
								:columns="columns"
								:options="options"
							>
							</v-client-table>

							<a
								href
								@click.prevent="addSplitPayment = true"
								class="right"
								>Add split payment</a
							>
						</div>

						<div v-if="addSplitPayment" class="form-group">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="input-item-label"
											>Sub Account</label
										>
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
										<v-select
											class="v-select"
											placeholder="Sub Account"
											v-model="newSplitPayment.subAccount"
											@keydown.enter.native.prevent
											label="name"
											:reduce="(v) => v.reference"
											:options="subAccounts"
											required
										></v-select>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<div class="form-group">
										<label class="input-item-label"
											>Type</label
										>
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
										<v-select
											class="v-select"
											placeholder="Type"
											v-model="newSplitPayment.type"
											@keydown.enter.native.prevent
											:options="['flat', 'percent']"
											required
										></v-select>
									</div>
								</div>
								<div class="col-6">
									<div class="form-group">
										<label class="input-item-label">
											Value
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
										</label>
										<input
											type="number"
											class="form-control input-bordered"
											placeholder="Enter value"
											v-model="newSplitPayment.value"
										/>
									</div>
								</div>
							</div>
							<a
								href
								@click.prevent="addNewSplitPayment"
								class="right"
								>Add</a
							>
						</div>
						<div class="gaps-2x"></div>
						<button
							type="submit"
							class="btn btn-lg btn-primary"
							:disabled="isProcessing == true"
						>
							<span v-if="isProcessing"
								>Creating payment collection</span
							>
							<span v-else>Create</span>
						</button>
						<button
							type="button"
							class="btn btn-lg btn-light ml-2"
							data-dismiss="modal"
						>
							Close
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Nprogress from "nprogress";
	import { required } from "vuelidate/lib/validators";
	import { notifications } from "../../../public/assets/mixins/notifications";

	export default {
		name: "InitiatePaymentCollection",
		mixins: [notifications],
		data() {
			return {
				amount: null,
				collectionCreated: false,
				createdCollection: null,
				split: {},
				currency: "NGN",
				phone: "",
				email: "",
				role: "",
				splitPayments: [],
				columns: ["type", "subAccount", "value"],
				newSplitPayment: {},
				subAccounts: [],
				isProcessing: false,
				addSplitPayment: false,
			};
		},
		validations: {
			amount: { required },
		},
		computed: {
			apiKeys() {
				return this.$store.state.account.customerKeys;
			},
		},
		created() {
			this.getMerchantSubAccounts();
		},
		methods: {
			addNewSplitPayment() {
				this.splitPayments.push({ ...this.newSplitPayment });
				this.newSplitPayment = {};
				this.addSplitPayment = false;
			},
			async getMerchantSubAccounts() {
				try {
					const merchantSubAccounts = await this.$store.dispatch(
						"wallets/getMerchantSubAccounts",
						this.apiKeys.apiKey
					);
					this.subAccounts = merchantSubAccounts.data.data;
				} catch (error) {}
			},
			clearInputFields() {
				this.email = "";
				this.billingType = "";
			},
			closeModalSuccess() {
				let closeButton = document.getElementById(
					"close-AddTeamMember"
				);
				closeButton.click();

				this.$emit("refetchApplication");
			},
			async initializePaymentCollection() {
				this.$v.$touch();
				if (this.$v.$invalid) return;

				this.isProcessing = true;
				try {
					let split;
					if (this.splitPayments) {
						split = {};
						this.splitPayments.forEach((splitPayment) => {
							split[splitPayment.subAccount] = {
								type: splitPayment.type,
								value: splitPayment.value,
							};
						});
					}
					this.createdCollection = (
						await this.$store.dispatch(
							"wallets/createPaymentCollection",
							{
								apiKey: this.apiKeys.apiKey,
								body: {
									amount: this.amount,
									currency: "NGN",
									split,
									customer: {
										// TODO customer email
										email: "johndoe@gmail.com",
									},
								},
							}
						)
					).data;
					console.log(this.createdCollection);

					this.collectionCreated = true;

					Nprogress.done();
				} catch (error) {
					Nprogress.done();
					console.log("[error] ->", error);
					this.isProcessing = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
