<template>
	<div class="form-group">
		<div class="flex__between">
			<div>
				<label v-show="label" class="input-item-label">{{
					label
				}}</label>
				<span
					v-show="required"
					class="text-danger pl-1"
					title="This field is required"
					>*</span
				>
			</div>
			<span v-show="showCount">{{
				`${smsLength.msgCount}/${smsLength.remains}`
			}}</span>
		</div>

		<div class="input-wrapper" :class="disabled ? 'disabled' : ''">
			<textarea
				:id="id"
				:value="value"
				v-on:input="$emit('input', $event.target.value)"
				class="form-control input-bordered w-100"
				:placeholder="placeholder"
				:disabled="disabled"
				:rows="rows"
				:cols="cols"
			/>
		</div>
		<span v-show="error" class="text-danger">{{ error }}</span>
	</div>
</template>

<script>
	export default {
		name: "CustomTextArea",
		data() {
			return {
				msgLimit: 160,
			};
		},
		props: {
			id: String,
			value: String,
			name: String,
			placeholder: String,
			label: String,
			error: String,
			disabled: Boolean,
			required: Boolean,
			handleError: Function,
			rows: {
				type: Number,
				default: 4,
			},
			cols: {
				type: Number,
				default: 50,
			},
			showCount: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			smsLength() {
				const remains = this.msgLimit - (this.value.length % 160);
				let msgCount = parseInt(this.value.length / 160) + 1;
				return { remains, msgCount };
			},
		},
		watch: {
			value() {
				this.handleError(this.name);
			},
		},
	};
</script>
