<template>
  <div id="addTeamMemberModal" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-AddTeamMember"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <h5 class="modal-title mg-b-5">Invite team member</h5>
          <p
            class="fs-14"
          >You can invite a team member to your organization here. An email invite will be sent to activate the account. </p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="inviteMember">
            <div class="form-group">
              <label class="input-item-label">
                Email Address
                <span class="text-danger" title="This field is required">*</span>
              </label>
              <input
                type="email"
                class="form-control input-bordered"
                placeholder="Enter email address"
                v-model="email"
              />
              <ul class="parsley-errors-list filled" v-show="$v.email.$error">
                <li class="parsley-required">Email address is required.</li>
              </ul>
            </div>

            <!-- Application Services -->
            <div class="form-group">
              <label class="input-item-label">
                Select Role
                <span class="text-danger" title="This field is required">*</span>
              </label>
              <v-select
                  class="v-select"
                  placeholder="Role"
                  v-model="role"
                  @keydown.enter.native.prevent
                  :required="!role"
                  :options="roles"
                  label="role"
              ></v-select>
              <ul class="parsley-errors-list filled" v-show="$v.role.$error">
                <li class="parsley-required">Role are required.</li>
              </ul>
            </div>


            <div class="gaps-2x"></div>
            <button type="submit" class="btn btn-lg btn-primary" :disabled="isProcessing == true">
              <span v-if="isProcessing">Inviting Member</span>
              <span v-else>Invite</span>
            </button>
            <button type="button" class="btn btn-lg btn-light ml-2" data-dismiss="modal">Close</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from 'nprogress'
import { required } from 'vuelidate/lib/validators'
import { notifications } from '../../../public/assets/mixins/notifications'

export default {
  mixins: [notifications],
  data() {
    return {
      roles: ['Admin', 'Operations', 'Developer', 'Customer Service'],
      email: '',
      role: '',
      isProcessing: false
    }
  },
  validations: {
    email: { required },
    role: { required },
  },
  computed: {
    clientID() {
      return this.$store.state.account.clientID
    },
  },
  methods: {
    clearInputFields() {
      this.email = ''
      this.billingType = ''
    },
    closeModalSuccess() {
      let closeButton = document.getElementById('close-AddTeamMember')
      closeButton.click()

      this.$emit('refetchApplication')
    },
    async inviteMember() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.isProcessing = true
      try {

        let application = await this.$store.dispatch('account/addTeamMember', {
          clientID: this.clientID,
          payload: {
            email: this.email,
            role: this.roles.indexOf(this.role) + 2 //owner is not included
          }
        })
        console.log('[] ->', application.message)
        this.showSuccessNotification(application.message)
        let self = this
        setTimeout(() => {
          self.closeModalSuccess()
          self.isProcessing = false
          self.clearInputFields()
        }, 2500)
        Nprogress.done()
      } catch (error) {
        Nprogress.done()
        console.log('[error] ->', error)
        this.isProcessing = false
        this.showErrorLoginNotification(error.message)
      }
    }
  }
}
</script>
