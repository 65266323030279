<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openSubscribeModal"
		>
			Subscribe
		</button>
		<CreateSubscriptionModal
			:id="'subscribe'"
			:closeModal="closeModal"
			:subRef="subRef"
			:refetch="refetch"
			:plan="plan"
		/>
	</div>
</template>

<script>
import CreateSubscriptionModal from "../../modals/CreateSubscriptionModal/CreateSubscriptionModal";

export default {
	name: "SubscribeButton",
	props: ["subRef", "refetch", "plan"],
	methods: {
		openSubscribeModal() {
			$("#subscribe").modal("show");
		},
		closeModal() {
			$("#subscribe").modal("hide");
		},
	},
	components: {
		CreateSubscriptionModal,
	},
};
</script>
