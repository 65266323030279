<template>
	<form @submit.prevent="handleSubmit" class="filter">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
				<CustomInput
					:name="'accountNumber'"
					v-model="filters.accountNumber"
					:placeholder="'Account Number'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
				<CustomInput
					:name="'accountName'"
					v-model="filters.accountName"
					:placeholder="'Account Name'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<BankSelect
					:name="'bankName'"
					v-model="filters.bankName"
					:hideLabel="true"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<CustomSelect
					name="status"
					:options="['verified', 'unverified']"
					v-model="filters.status"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>
			</div>
		</div>
	</form>
</template>

<script>
	import DatePicker from "../../../../../utils/DatePicker/DatePicker";
	import CustomInput from "../../../../../utils/CustomInput/CustomInput";
	import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
	import Spinner from "../../../../../utils/Spinner/Spinner";
	import BankSelect from "../../../../../utils/Selects/BankSelect/BankSelect";

	const initialState = () => ({
		filters: {
			accountNumber: "",
			bankName: "",
			accountName: "",
			status: "",
		},
		isFilter: false,
	});

	export default {
		name: "TransaferListFilters",
		data() {
			return { ...initialState() };
		},
		props: ["loading", "clearFilter", "submitFilter"],
		components: {
			DatePicker,
			CustomInput,
			CustomSelect,
			BankSelect,
			Spinner,
		},
		computed: {
			disabled() {
				return !Object.values({
					...this.filters,
					...this.dateFilters,
				}).some((val) => val);
			},
		},
		methods: {
			resetFilters() {
				Object.assign(this.$data, { ...initialState() });
				this.clearFilter();
				this.isFilter = false;
			},
			handleSubmit() {
				const { accountNumber } = this.filters || {};
				this.isFilter = true;
				this.submitFilter({
					query: {
						...this.filters,
						accountNumber: service_id ? service_id.id : undefined,
					},
				});
			},
		},
	};
</script>
