<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreatePlan"
		>
			Create Plan
		</button>
		<CreatePlanModal
			:id="'createPlan'"
			:closeModal="closeModal"
			:planRef="planRef"
		/>
	</div>
</template>

<script>
import CreatePlanModal from "../../modals/CreatePlanModal/CreatePlanModal";

export default {
	name: "CreatePlanButton",
	props: ["planRef"],
	methods: {
		openCreatePlan() {
			$("#createPlan").modal("show");
		},
		closeModal() {
			$("#createPlan").modal("hide");
		},
	},
	components: {
		CreatePlanModal,
	},
};
</script>
