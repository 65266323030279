<template>
	<ul
		class="nav nav-tabs flex-nowrap"
		:class="tabClass"
		:id="tabId"
		role="tablist"
	>
		<template v-if="tabLink">
			<template v-for="{ tabName, link, devOnly } in tabNames">
				<li v-if="devOnly" class="nav-item" :key="tabName">
					<DevOnly>
						<span
							class="nav-link pointer"
							:class="link === $route.path ? 'active' : ''"
							@click="$router.push(link)"
							role="presentation"
							>{{ titleCase(tabName) }}</span
						>
					</DevOnly>
				</li>

				<li v-else class="nav-item" :key="tabName">
					<span
						class="nav-link pointer"
						:class="link === $route.path ? 'active' : ''"
						@click="$router.push(link)"
						role="presentation"
						>{{ titleCase(tabName) }}</span
					>
				</li>
			</template></template
		>

		<template v-else>
			<template v-for="tabName in tabNames">
				<li class="nav-item" :key="tabName">
					<a
						class="nav-link"
						:class="tabName === tabNames[0] ? 'active' : ''"
						:id="`${tabName}-tab`"
						data-toggle="tab"
						:href="`#${tabName}`"
						role="tab"
						:aria-controls="tabName"
						aria-selected="true"
						>{{ titleCase(tabName) }}</a
					>
				</li>
			</template></template
		>
	</ul>
</template>

<script>
import { titleCase } from "../helpers";
import DevOnly from "@/utils/AccessControl/DevOnly";

export default {
	name: "Tabs",
	data() {
		return {
			currentTab: "",
		};
	},
	props: {
		tabNames: Array,
		tabLink: {
			type: Boolean,
			default: false,
		},
		tabId: String,
		tabClass: {
			type: String,
			default: "flex-tab",
		},
	},
	components: {
		DevOnly,
	},
	methods: {
		titleCase,
		switchTab(tabName) {
			this.currentTab = tabName;
		},
	},
};
</script>
