<template>
	<form @submit.prevent="editProfile">
		<div class="row">
			<div class="col-lg-10 col-md-12">
				<div class="row">
					<!-- First name field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Firstname</label>
							<input
								type="text"
								name="firstname"
								class="form-control input-bordered"
								v-model="apiUser.firstname"
								placeholder="Enter your first name"
							/>
						</div>
					</div>

					<!-- last name field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Lastname</label>
							<input
								type="text"
								name="lastname"
								class="form-control input-bordered"
								v-model="apiUser.lastname"
								placeholder="Enter your last name"
							/>
						</div>
					</div>

					<!-- Organisation field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label"
								>Organisation Name</label
							>
							<input
								type="text"
								name="organisation"
								class="form-control input-bordered"
								v-model="apiUser.api_customer.organization_name"
								placeholder="Enter your organisation name"
							/>
						</div>
					</div>

					<!-- Email field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Phone Number</label>
							<input
								type="tel"
								name="phone number"
								class="form-control input-bordered"
								v-model="apiUser.phone_number"
								placeholder="Enter your phone number"
							/>
						</div>
					</div>
					<!-- Email field -->
					<div class="col-md-12">
						<div class="form-group">
							<label class="input-item-label">Email</label>
							<input
								type="email"
								name="email"
								disabled
								class="form-control input-bordered"
								v-model="apiUser.email"
								placeholder="Enter your email"
							/>
						</div>
					</div>

					<!--Services field -->
					<!-- <div class="col-md-12">
            <div class="form-group">
              <label class="input-item-label">Services</label>
              <v-select
                class="v-select multiple"
                placeholder="Select a service"
                @keydown.enter.native.prevent
                v-model="apiUser.api_customer.customerServicePrices"
                :required="!apiUser.api_customer.customerServicePrices"
                :options="metaData.services"
                label="service_label"
                multiple
              ></v-select>
            </div>
          </div>-->

					<!--Fail Overs field -->
					<div class="col-md-12" v-if="false">
						<div class="form-group">
							<label class="input-item-label"
								>Fail-Over Services</label
							>
							<v-select
								class="v-select multiple"
								placeholder="Select fail over services"
								@keydown.enter.native.prevent
								v-model="selectedServices"
								:required="
									!apiUser.api_customer.customerServicePrices
								"
								:options="failOverServices"
								label="service_label"
								multiple
							></v-select>
						</div>
					</div>

					<!-- Address field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Address</label>
							<input
								type="text"
								name="address"
								class="form-control input-bordered"
								v-model="apiUser.api_customer.address"
								placeholder="Enter your address"
							/>
						</div>
					</div>

					<!-- City field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">City</label>
							<input
								type="text"
								class="form-control input-bordered"
								v-model="apiUser.city"
								placeholder="Enter your city"
							/>
						</div>
					</div>

					<!--Country field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Country</label>
							<v-select
								class="v-select"
								placeholder="Select a country"
								v-model="apiUser.country"
								@keydown.enter.native.prevent
								:options="metaData.countries"
								@input="changeState"
								label="name"
							></v-select>
						</div>
					</div>

					<!-- State field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">State</label>
							<v-select
								class="v-select"
								placeholder="Select a state"
								v-model="apiUser.state"
								@keydown.enter.native.prevent
								:disabled="!apiUser.country"
								:options="states"
								label="state_label"
							></v-select>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="gaps-2x"></div>
		<button
			class="btn btn-lg btn-primary mg-b-20"
			type="submit"
			:disabled="isSbumitting"
		>
			<span v-if="isSbumitting">Save Changes</span>
			<span v-else>Update account</span>
		</button>
		<button
			class="btn btn-lg btn-outline-danger ml-2 mg-b-20"
			@click.prevent="closeEditPanel"
		>
			Cancel
		</button>
	</form>
</template>

<script>
import Nprogress from "nprogress";
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
	mixins: [notifications],
	props: {
		apiUser: {
			type: Object,
		},
	},
	data() {
		return {
			isSbumitting: false,
			states: [],
			serviceCount: null,
			selectService: [],
		};
	},
	methods: {
		closeEditPanel() {
			this.$emit("closeEdit");
		},
		changeState() {
			this.apiUser.state = [];
			this.states = this.apiUser.country.states;
		},
		formatRequestData() {
			let requestData = new FormData();

			// if (this.apiUser.api_customer.customerServicePrices) {
			//   this.apiUser.api_customer.customerServicePrices.map(service => {
			//     requestData.append("services", service.id);
			//   });
			// }
			// if (this.selectService) {
			//   this.selectService.map(service => {
			//     requestData.append("fail_over_services", service.id);
			//   });
			// }

			requestData.append("firstname", this.apiUser.firstname);
			requestData.append("lastname", this.apiUser.lastname);
			requestData.append(
				"organization_name",
				this.apiUser.api_customer.organization_name
			);
			// requestData.append("email", this.apiUser.email);
			requestData.append("phone_number", this.apiUser.phone_number);
			requestData.append("address", this.apiUser.api_customer.address);
			requestData.append("city", this.apiUser.city);
			requestData.append(
				"state_id",
				this.apiUser.state ? this.apiUser.state.id : ""
			);
			requestData.append(
				"country_id",
				this.apiUser.country ? this.apiUser.country.id : ""
			);

			return requestData;
		},
		async editProfile() {
			Nprogress.start();
			this.isSbumitting = true;
			let formattedResponse = this.formatRequestData();
			console.log("[formatted response ] ->", formattedResponse);
			try {
				let apiResponse = await this.$store.dispatch(
					"account/editProfile",
					formattedResponse
				);
				console.log("[data from edit Profile] ->", apiResponse);
				this.closeEditPanel();
				this.showSuccessNotification(apiResponse.message);
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSbumitting = false;
				console.log("error :", error);
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	watch: {
		"apiUser.api_customer.customerServicePrices"(newVal, old) {
			let newServiceArray, failOverServices, missingIndex;
			if (newVal) newServiceArray = newVal.map((service) => service.id);

			if (this.selectService) {
				failOverServices = this.selectService.map(
					(service) => service.id
				);
			}

			if (newServiceArray && failOverServices) {
				for (let index = 0; index < failOverServices.length; index++) {
					if (!newServiceArray.includes(failOverServices[index])) {
						missingIndex = index;
						break;
					}
				}
				this.selectService.splice(missingIndex, 1);
			}
		},
	},
	computed: {
		metaData() {
			return this.$store.state.metaData;
		},
		selectedServices: {
			get: function () {
				return this.selectService;
			},
			set: function (value) {
				console.log("[values] ->", value);
				this.selectService = value;
			},
		},
		failOverServices() {
			return this.apiUser.api_customer.customerServicePrices;
		},
	},
	mounted() {
		// this.selectService = this.apiUser.api_customer.customerServicePrices.filter(service => service.pivot.has_fail_over == 1);
		this.serviceCount = this.apiUser.api_customer.customerServicePrices
			? this.apiUser.api_customer.customerServicePrices.length
			: 0;
		this.states = this.metaData.countries.filter(
			(country) => country.id == this.apiUser.country_id
		)[0].states;
	},
};
</script>
