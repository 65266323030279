<template>
	<Modal
		:id="id"
		:title="'Delete Payment Link'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Payment Link Deleted Successfully'"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="deletePaymentLink">
			<h4 class="text-center my-4">
				Are you sure you wanted to delete this payment link ?
			</h4>

			<div class="flex__between pt-4">
				<button
					type="button"
					class="btn btn-outline w-100 mr-3"
					:disabled="loading"
					@click="handleCloseModal"
				>
					<span>Close</span>
				</button>
				<button type="submit" class="btn btn-lg btn-danger w-100 ml-3">
					Delete
				</button>
			</div>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../utils/Modal/Modal";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import Spinner from "../../../../../utils/Spinner/Spinner";

	const defaultValue = {
		loading: false,
		successMsg: false,
	};

	export default {
		mixins: [notifications],
		name: "DeletePaymentLinkModal",
		data() {
			return { ...defaultValue };
		},
		props: ["id", "reference", "closeModal", "paymentLinkRef"],
		components: { Modal, SuccessState, Spinner },
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...defaultValue });
				this.closeModal();
			},
			handleRefetch() {
				this.handleCloseModal();
				this.paymentLinkRef.refresh();
			},

			async deletePaymentLink() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"payment/deletePaymentLink",
						{
							apiKey: this.apiKeys.apiKey,
							reference: this.reference,
						}
					);

					if (response.status === 200) {
						this.loading = false;
						this.successMsg = true;
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
		computed: {
			apiKeys() {
				return this.$store.state.account.customerKeys;
			},
		},
	};
</script>
