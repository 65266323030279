<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="SectionItem no-b-b mb-3">
				<p class="title m-0">Reserved Accounts</p>
			</div>

			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreateReservedAccountButton
								:accountRef="$refs.accountRef"
						/></AccessControl>
					</template>
					<template slot="filterForm">
						<AccountFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('accountRef')"
							:download="downloadAccount"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<CreateReservedAccountButton
							:accountRef="$refs.accountRef"
					/></AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="accountRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@row-click="handleClick"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="Customer.business" slot-scope="{ row }">
							<span>
								{{
									row.Customer.business
										? "Business"
										: "Individual"
								}}
							</span>
						</template>
						<template slot="Customer.phone" slot-scope="{ row }">
							{{ getPhone(row.Customer.phone) }}
						</template>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { notifications, componentData } from "../../../../public/assets/mixins";
import CreateReservedAccountButton from "./buttons/CreateReservedAccountButton/CreateReservedAccountButton";
import AccountFilters from "../common/AccountFilters/AccountFilters";
import { reservedAccountCsvRows, tableHeadings } from "./enums";

export default {
	mixins: [notifications, componentData],
	name: "ReservedAccounts",
	data() {
		return {
			emptyTitle: "No Reserved Account Found",
			...this.tableOptions(tableHeadings, this.size),
		};
	},
	components: {
		AccountFilters,
		CreateReservedAccountButton,
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(`/wallet/reserved-account/${row.reference}`);
		},
		refetch(filters) {
			this.handleRefetch({...filters, currency: filters.currency.code}, this.getAccount, "accountRef");
		},
		async getAccount() {
			this.options.requestFunction = async (data) => {
				const params = { ...data, ...this.filters };
				try {
					return (
						await this.$store.dispatch(
							"reservedAccounts/getReservedAccounts",
							{
								apiKey: this.getWalletApikey(this.$store),
								params,
							}
						)
					).data.data;
				} catch (error) {
					this.loading = false;
					this.errorMsg = this.getError(error);
				}
			};
		},
		async downloadAccount() {
			const download = (filterData) =>
				this.downloadReport(
					reservedAccountCsvRows,
					filterData.data.data
				);
			this.downloadTableReport(
				"reservedAccounts/getReservedAccounts",
				download
			);
		},
	},
	created() {
		this.getAccount();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
