<template>
	<Modal
		:id="id"
		:title="'Delete Tax'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Tax Deleted Successfully'"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="deleteTax" class="h-100">
			<h4 class="text-center my-4">
				Are you sure you want to delete this Tax?
			</h4>

			<FormButtons
				:btnName="'Delete'"
				:primary="false"
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "@/utils/Modal/Modal";
import SuccessState from "@/utils/ResponseState/SuccessState";
import Spinner from "@/utils/Spinner/Spinner";
import FormButtons from "@/utils/FormButtons/FormButtons";
import { getWalletApikey } from "@/utils/helpers";

const defaultValue = {
	loading: false,
	successMsg: false,
};

export default {
	mixins: [notifications],
	name: "DeleteTaxModal",
	data() {
		return { ...defaultValue };
	},
	props: ["id", "taxID", "closeModal", "taxRef"],
	components: { Modal, SuccessState, Spinner, FormButtons },
	methods: {
		handleCloseModal() {
			Object.assign(this.$data, { ...defaultValue });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			location.reload();
		},

		async deleteTax() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch("tax/deleteTax", {
					apiKey: getWalletApikey(this.$store),
					id: this.taxID,
				});

				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
