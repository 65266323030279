<template>
	<Modal
		:id="id"
		:title="'Initiate Bulk Transfer'"
		:toggle="handleCloseModal"
	>
		<SuccessState
			v-if="successMsg"
			:message="successMsg"
			:onSuccess="handleRefetch"
		/>

		<form v-else-if="!showOtp" @submit.prevent="handleRequst">
			<div class="text-center my-4">
				<h4>Are you sure you want to initiate this transfer?</h4>
				<h5 class="text-danger">
					You can't revoke this action after submitting
				</h5>
			</div>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>

		<form v-else @submit.prevent="handleRequst">
			<div class="d-flex justify-content-center">
				<v-otp-input
					ref="otpInput"
					input-classes="otp-input"
					separator="-"
					:num-inputs="6"
					:should-auto-focus="true"
					:is-input-num="true"
					@on-change="handleOnChange"
				/>
			</div>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "../../../../utils/Modal/Modal";
import SuccessState from "../../../../utils/ResponseState/SuccessState";
import FormButtons from "../../../../utils/FormButtons/FormButtons";
import { getWalletApikey, getWalletPublickey } from "@/utils/helpers";
import { decrypt, encrypt } from "../../helpers";

export default {
	mixins: [notifications],
	name: "MakeTransferModal",
	data() {
		return { loading: false, successMsg: "", showOtp: false, otp: "" };
	},
	props: ["id", "closeModal", "payload", "resetTransfer", "settings"],
	components: {
		Modal,
		SuccessState,
		FormButtons,
	},
	methods: {
		handleCloseModal() {
			this.loading = false;
			this.successMsg = "";
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			this.resetTransfer();
		},
		handleOnChange(value) {
			this.otp = value;
		},
		getError(msg) {
			this.loading = false;
			this.showErrorLoginNotification(msg);
		},
		handleRequst() {
			if (!this.showOtp) {
				this.requestOtp();
			} else {
				this.handleSubmit();
			}
		},
		async requestOtp() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/requestMerchantOtp",
					{
						headers: {
							"x-api-key": getWalletApikey(this.$store),
						},
					}
				);
				if (response.status === true) {
					this.loading = false;
					this.showOtp = true;
					this.showSuccessNotification("An OTP has been sent to you");
				} else {
					this.showErrorRegistrationNotification(response.message);
				}
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorRegistrationNotification(error.message);
			}
		},

		async handleSubmit() {
			if (this.otp.length < 6) {
				return;
			}

			try {
				this.loading = true;

				const headers = {
					clientid:
						this.$store.state.account.clientID ||
						localStorage.getItem("local-ID"),
					publickey: getWalletPublickey(this.$store),
				};

				const payload = { ...this.payload, otp: this.otp };

				const response = await this.$store.dispatch(
					"fundstransfer/initiateBulkTransfer",
					{
						headers,
						payload: encrypt(
							getWalletApikey(this.$store),
							JSON.stringify(payload)
						),
					}
				);

				const { status, data } = response || {};

				if (status === 200 || status === 201) {
					this.$swal({
						title: `Transaction Processing`,
						text: "Transaction Queued Successfully",
						icon: "success",
						button: "Ok",
					}).then(() => this.handleRefetch());
				} else {
					this.showErrorLoginNotification(
						data.message || "Error Initiating Transaction"
					);
				}
				this.loading = false;
			} catch (error) {
				if (error.response.data.base64Iv) {
					const { base64Iv, data } = error.response.data || {};
					const errorMsg = decrypt(
						base64Iv,
						getWalletApikey(this.$store),
						data
					);
					this.showErrorLoginNotification(
						errorMsg.message || "Error Initiating Transaction"
					);
				} else this.showErrorLoginNotification(error.message);
				this.loading = false;
			}
		},
	},
};
</script>
