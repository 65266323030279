<template>
	<div>
		<img
			v-if="useIcon"
			@click="openEditplan"
			class="pointer mr-4"
			src="/assets/img/icons/edit.png"
			alt="update Plan"
			width="25px"
		/>
		<button
			v-else
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openEditPlan"
		>
			Update Plan
		</button>
		<CreatePlanModal
			:id="plan.reference"
			:closeModal="closeModal"
			:planRef="planRef"
			:plan="plan"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import CreatePlanModal from "../../modals/CreatePlanModal/CreatePlanModal";

export default {
	name: "UpdatePlanButton",
	props: ["planRef", "plan", "refetch", "useIcon"],
	methods: {
		openEditPlan() {
			$(`#${this.plan.reference}`).modal("show");
		},
		closeModal() {
			$(`#${this.plan.reference}`).modal("hide");
		},
	},
	components: {
		CreatePlanModal,
	},
};
</script>
