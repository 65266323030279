import DatePicker from "@/utils/DatePicker/DatePicker";
import DateTimePicker from "@/utils/DatePicker/DateTimePicker";
import CustomAmountInput from "@/utils/CustomInput/CustomAmountInput";
import CustomTextArea from "@/utils/CustomTextArea/CustomTextArea";
import CustomerSelect from "@/utils/Selects/CustomerSelect/CustomerSelect";
import BankSelect from "@/utils/Selects/BankSelect/BankSelect";
import DownloadReport from "@/utils/DownloadReport/DownloadReport";
import TaxSelect from "@/utils/Selects/TaxSelect/TaxSelect";
import CouponSelect from "@/utils/Selects/CouponSelect/CouponSelect";
import ItemSelect from "@/utils/Selects/ItemSelect/ItemSelect";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";
import Modal from "@/utils/Modal/Modal";
import SuccessState from "@/utils/ResponseState/SuccessState";
import SuccessModal from "@/utils/Modal/SuccessModal/SuccessModal";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import TableWrapper from "@/utils/TableWrapper/TableWrapper";
import ContentWrapper from "@/utils/TableWrapper/ContentWrapper";
import Spinner from "@/utils/Spinner/Spinner";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import EmptyWallet from "@/components/wallet/common/EmptyWallet/EmptyWallet";
import FormButtons from "@/utils/FormButtons/FormButtons";
import { notifications } from "./notifications";
import {
	timeDateFormat,
	tableOptions,
	getWalletApikey,
	getWalletPublickey,
	getRequestData,
	titleCase,
	toCurrency,
	truncate,
	statusColor,
	getFilters,
	copyToClipboard,
	downloadReport,
	getPhone,
	getError,
	getRequestDataWithHeaders,
	xEnv,
	timeRangeFormat,
	getResponseStatus,
	getPhoneNumber,
} from "@/utils/helpers";

export const componentData = {
	mixins: [notifications],
	data() {
		return {
			loading: false,
			rLoading: false,
			isEmpty: false,
			errorMsg: "",
			size: 20,
			filters: {},
			xEnv,
		};
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	components: {
		AccessControl,
		FilterWrapper,
		EmptyWallet,
		FailedState,
		Spinner,
		TableWrapper,
		CustomInput,
		CustomSelect,
		FormButtons,
		CustomTextArea,
		DownloadReport,
		BankSelect,
		DatePicker,
		DateTimePicker,
		TaxSelect,
		CouponSelect,
		CustomerSelect,
		ItemSelect,
		SuccessState,
		Modal,
		SuccessModal,
		CustomAmountInput,
		CustomUpload,
		ContentWrapper,
		CustomPhoneInput,
		CurrencySelect,
	},
	methods: {
		timeDateFormat,
		timeRangeFormat,
		tableOptions,
		getWalletApikey,
		getWalletPublickey,
		titleCase,
		statusColor,
		getFilters,
		copyToClipboard,
		downloadReport,
		getPhone,
		toCurrency,
		getError,
		truncate,
		formValidator,
		formVuelidator,
		getResponseStatus,
		getPhoneNumber,
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		onLoaded({ total }) {
			this.setLoading(false);
			this.isEmpty = total > 0 ? false : true;
		},
		setError(error) {
			this.loading = false;
			this.errorMsg = getError(error);
		},
		handleRefetch(filters, apiCall, refName) {
			const newFilters = getFilters(filters);
			if (filters) this.filters = newFilters;
			apiCall();
			// location.reload();
			this.$refs[refName] && this.$refs[refName].refresh();
		},
		clearFilter(refName) {
			this.filters = {};
			this.$refs[refName].refresh();
		},
		async handleAPICall(url) {
			this.options.requestFunction = async (data) => {
				// const params = { ...data, ...this.filters };
				const requestData = getRequestData(
					this.$store,
					this.filters,
					data,
					this.size
				);
				try {
					return (await this.$store.dispatch(url, requestData)).data
						.data;
				} catch (error) {
					this.setError(error);
				}
			};
		},
		async downloadTableReport(url, manageData) {
			this.rLoading = true;
			const request = getRequestData(this.$store, this.filters, {});

			const response = await this.$store.dispatch(url, request);

			const { status, data } = response || {};
			if (
				(status === 200 || status === "Success" || status === true) &&
				(data.status || data.success || data)
			) {
				manageData(data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
		async downloadReportWithHeaders({ url, manageData, headers = {} }) {
			this.rLoading = true;
			const request = getRequestDataWithHeaders({
				store: this.$store,
				filters: this.filters,
				headers,
			});

			const response = await this.$store.dispatch(url, request);

			const { status, data } = response || {};
			if (status === 200 && (data.status || data.success)) {
				manageData(data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
		setColums(tableHeadings) {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	},
};
