<template>
  <div class="az-content-box">
    <div class="az-content-left">
      <div class="az-services-menu">
        <!-- Account View -->
        <div
          id="accordion"
          class="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div
            id="collapseFour"
            data-parent="#accordion"
            class="collapse show profile-sidebar"
            role="tabpanel"
            aria-labelledby="headingFive"
          >
            <nav class="nav az-nav-column mg-b-20" role>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(16)"
                href
                :class="{ active: tab == 16 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-user la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> Profile </strong>
                      <br />
                      <small>Edit profile, change password, MFA</small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(17)"
                href
                :class="{ active: tab == 17 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-credit-card la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> Wallet </strong>
                      <br />
                      <small>Wallet info, funding and history</small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(23)"
                href
                :class="{ active: tab == 23 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-money la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong>Application Billings </strong>
                      <br />
                      <small>Overview of your billings on the app </small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(19)"
                href
                :class="{ active: tab == 19 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-sitemap la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> Services </strong>
                      <br />
                      <small>View all application service(s) usable.</small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(20)"
                href
                :class="{ active: tab == 20 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-clipboard la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> Request Log </strong>
                      <br />
                      <small>Historical data of your Requests</small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(18)"
                href
                :class="{ active: tab == 18 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-users la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> Manage Team </strong>
                      <br />
                      <small>Add/Edit/Remove team members</small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(21)"
                href
                :class="{ active: tab == 21 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-folder la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> Compliance </strong>
                      <br />
                      <small>Additional account documents </small>
                    </h5>
                  </div>
                </div>
              </a>

              <div class="hr-solid"></div>
              <br />

              <a
                data-toggle="tab"
                @click="tabswitch(22)"
                href
                :class="{ active: tab == 22 }"
                class="nav-link"
              >
                <div class="row">
                  <div class="col-2">
                    <i class="la la-key la-2x pt-2"></i>
                  </div>
                  <div class="col-10 account-nav-content">
                    <h5>
                      <strong> API Key Settings </strong>
                      <br />
                      <small>Manage your API keys for services </small>
                    </h5>
                  </div>
                </div>
              </a>
            </nav>
          </div>
        </div>
        <!-- Messaging Services -->
      </div>
    </div>

    <loader v-if="isProcessing == true"></loader>

    <div class="az-content-body tab-content profile-body" v-else>
      <transition
        mode="out-in"
        name="fade"
        v-if="profile.is_active && application.length > 0"
      >
        <Profile
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 16"
        ></Profile>
        <CustomerWallet
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 17"
        ></CustomerWallet>
        <Team :apiUser="apiUser" v-if="tab == 18"></Team>
        <AccountServices
          :apiUser="apiUser"
          @refetchServices="getProfile"
          v-if="tab == 19"
        ></AccountServices>
        <AccountActivity
          v-if="userRole !== 'Secondary Customer' && tab == 20"
          :apiUser="apiUser"
        ></AccountActivity>
        <SecondaryCustomerRequestLogs
          v-if="userRole == 'Secondary Customer' && tab == 20"
          :apiUser="apiUser"
        ></SecondaryCustomerRequestLogs>
        <KYC
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 21"
        ></KYC>
        <APIKeys
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 22"
        ></APIKeys>
        <AccountBilling :apiUser="apiUser" v-if="tab == 23"></AccountBilling>
      </transition>
      <DefaultContent
        v-if="!profile.is_active"
        :image="'empty-resources.png'"
        :title="`Seems you've not verified your account.`"
        :subtitle="'Verify your account to start testing out the different services. Contact the Admin to verify your account'"
      ></DefaultContent>
      <DefaultContent
        v-if="profile.is_active && application.length == 0"
        :image="'empty-resources.png'"
        :title="'No Application Created'"
        :subtitle="'You currently do not have any active application, Please create one start testing services'"
      >
        <router-link class="btn btn-md btn-primary" to="/applications">
          <i class="la la-plus"></i> Create Application
        </router-link>
      </DefaultContent>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/account/Profile";
import CustomerWallet from "@/components/account/CustomerWallet.vue";
import AccountServices from "@/components/account/AccountServices";
import Team from "@/components/account/Team";
import SecondaryCustomerRequestLogs from "@/components/account/SecondaryCustomerRequestLogs";
import AccountActivity from "@/components/account/AccountActivity";
import KYC from "@/components/account/KYC";
import APIKeys from "@/components/account/APIKeys";
import AccountBilling from "@/components/account/AccountBilling";
export default {
  components: {
    Profile,
    CustomerWallet,
    Team,
    AccountServices,
    SecondaryCustomerRequestLogs,
    AccountActivity,
    KYC,
    APIKeys,
    AccountBilling,
  },
  computed: {
    profile() {
      return this.$store.state.account.profile.api_customer;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  data() {
    return {
      apps: {},
      application: [],
      apiUser: {},
      isProcessing: true,
      tab: 16,
    };
  },
  methods: {
    tabswitch(tab) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (tab) {
        this.tab = tab;
        this.$router.replace({ query: { tab: this.tab } });
      } else {
        this.tab = 1;
      }
    },

    async fetchApps() {
      try {
        const app = await this.$store.dispatch("application/getApplications");
        this.apps = app;
        let applications = app.applications;
        let filteredApplication = [];
        if (applications.length > 0) {
          filteredApplication = applications.filter(
            (app) => app.is_active == 1
          );
        }
        this.application = filteredApplication;
        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
    async getProfile() {
      try {
        let apiResponse = await this.$store.dispatch("account/getProfile");
        this.apiUser = { ...apiResponse };
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    if (this.$route.query.tab) {
      let tab = this.$route.query.tab;
      this.tabswitch(tab);
    }
    this.fetchApps();
    this.getProfile();
  },
};
</script>
<style scoped>
.profile-sidebar {
  height: 100%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
}
.profile-body {
  background-color: #fff;
  margin: 0px 5px;
}
.account-nav-content {
  padding: 0 5px !important;
}
a .nav-link-alt {
  color: #70737c;
}
.nav-link-alt .active {
  color: #1d4486 !important;
}
.hr-solid {
  border-top: 1px solid #bbb;
}
.accordion .card-header a {
  padding: 14px 0px !important;
}
.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
  color: #212229;
  background-color: transparent !important;
}
.accordion .card-header a.collapsed {
  background-color: transparent !important;
}
.panel-heading a:before {
  content: "\002B";
  float: right;
  transition: all 0.5s;
}
.panel-heading a.collapsed:before {
  content: "\2212" !important;
  float: right;
  transition: all 0.5s;
}
</style>
