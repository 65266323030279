<template>
	<div
		class="tab-pane active fade show pt-3"
		id="Online_Transactions"
		role="tabpanel"
		aria-labelledby="Online_Transactions-tab"
	>
		<div class="row">
			<div class="col-12 mt-3"></div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>
			<div class="col-12" :class="tbClass">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect"> </template>
						<template slot="filterForm">
							<TransactionsTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadPosTransactions"
							/>
						</template>
					</FilterWrapper>
				</div>
				<v-server-table
					ref="onlineRef"
					class="v-table"
					:columns="columns"
					:options="options"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
					@row-click="showData"
				>
					<template slot="amount" slot-scope="{ row }">
						{{ toCurrency(row.amount, row.currency) }}
					</template>
					<template slot="source" slot-scope="{ row }">
						{{ titleCase(row.source) }}
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						{{
							timeDateFormat(row.createdAt).date +
							" " +
							timeDateFormat(row.createdAt).time
						}}
					</template>
					<template slot="status" slot-scope="{ row }">
						<span :class="statusColor(row.status)">{{
							titleCase(row.status)
						}}</span>
					</template>
				</v-server-table>
			</div>
		</div>
		<TransactionModal
			v-if="showSideModal"
			:isTerminal="true"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins//componentData";
import TransactionModal from "../../../../wallet/common/TransactionsTable/TransactionsTableModal.vue";
import Tabs from "../../../../../utils/Tabs/Tabs.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import TransactionsTableFilters from "../TransactionsTableFilters/TransactionsTableFilters.vue";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
	timeDateFormat,
	tableOptions,
	titleCase,
	toCurrency,
	statusColor,
	getWalletApikey,
} from "@/utils/helpers";
const tableHeadings = {
	reference: "Transaction Reference",
	source: "Payment Method",
	amount: "Amount",
	stan: "Stan",
	createdAt: "Created At",
	status: "Status",
};
export default {
	mixins: [componentData],
	name: "OnlineTransactions",
	data() {
		return {
			loading: false,
			rLoading: false,
			showSideModal: false,
			modalData: null,
			stats: null,
			errorMsg: "",
			size: 20,
			filters: {},
			...tableOptions(tableHeadings, 20),
		};
	},
	components: {
		TransactionsTableFilters,
		TransactionModal,
		FilterWrapper,
		Tabs,
		AccessControl,
		FailedState,
		Spinner,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		statusColor,
		showData({ row }) {
			this.modalData = row;
			this.showSideModal = true;
		},
		closeModal(e) {
			this.showSideModal = false;
			this.modalData = null;
		},
		timeDateFormat,
		titleCase,
		toCurrency,
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch(filters) {
			if (filters) this.filters = filters;
			this.fetchPosTransactions();
			this.$refs.onlineRef.refresh();
		},
		clearFilter() {
			this.filters = {};
			this.$refs.onlineRef.refresh();
		},
		async fetchPosTransactions() {
			this.options.requestFunction = async (data) => {
				const payload = {
					apiKey: getWalletApikey(this.$store),
					params: {
						...data,
						limit: this.size,
						...this.filters,
						id: this.$route.params.id,
					},
				};
				return await this.$store
					.dispatch("transactions/getAllPosTransactions", payload)
					.then((response) => {
						const allData = response.data.data;
						const { transactionCount, transactionSum } = allData;
						this.stats = { transactionCount, transactionSum };
						return { ...allData.terminalTransactions };
					});
			};
		},
		async downloadPosTransactions() {
			const download = (filterData) =>
				this.downloadReport(
					tableHeadings,
					filterData.data.terminalTransactions.data
				);
			this.downloadTableReport(
				"transactions/getAllPosTransactions",
				download
			);
		},
	},
	created() {
		this.fetchPosTransactions();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
