<template>
  <div class="p-4">
    <!-- <EmptyWallet v-if="!merchant" /> -->

    <template>
      <div class="flex__between">
        <div class="SectionItem no-b-b mb-4 justify-content-start">
          <img
            src="/assets/img/icons/arrow-left.svg"
            width="30"
            alt=""
            class="pointer"
            @click="$router.push('/wallet/remittance/')"
          />
          <p class="title ml-3 mb-0">Send Money</p>
        </div>
      </div>

      <div class="row">
        <div class="col-10 mx-auto">
          <div class="mt-4">
            <div :class="stepper === 1 ? 'd-block' : 'd-none'">
              <SenderForm @handleNext="handleNext" />
            </div>
            <div :class="stepper === 2 ? 'd-block' : 'd-none'">
              <RecieverForm @handleNext="handleNext" @handlePrev="handlePrev" />
            </div>
            <div :class="stepper === 3 ? 'd-block' : 'd-none'">
              <PaymentForm @handleNext="handleNext" @handlePrev="handlePrev" />
            </div>
            <div v-if="stepper === 4">
              <ConfirmationPage
                @handleNext="handleNext"
                @handlePrev="handlePrev"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Spinner from "../../../../utils/Spinner/Spinner.vue";
import SenderForm from "./SenderForm";
import RecieverForm from "./RecieverForm";
import PaymentForm from "./PaymentForm";
import ConfirmationPage from "./ConfirmationPage";

export default {
  name: "SendMoney",
  data() {
    return {
      stepper: 1,
    };
  },
  components: {
    Spinner,
    SenderForm,
    RecieverForm,
    PaymentForm,
    ConfirmationPage,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
  },
  methods: {
    handleNext() {
      this.stepper += 1;
    },
    handlePrev() {
      this.stepper -= 1;
    },
  },
  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
  },
  mounted() {
    window.localStorage.removeItem("__send_money");
    this.states = this.metaData.countries[0].states;
  },
};
</script>
