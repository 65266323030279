<template>
	<div class="service-content accordion-content">
		<template v-for="(detail, index) in pricing">
			<div
				v-if="detail.paymentTypeSlug !== 'international-fund-transfer'"
				class="row align-items-center"
				:key="`content${index}`"
			>
				<p class="col-md-3 service-name mb-0">
					{{ detail.paymentType || "" }}
				</p>

				<div class="col-md-4 d-flex">
					<label
						v-if="!!detail.flatCost"
						class="rdiobox mr-4"
						@change="handleSelect('flat', index)"
					>
						<input
							type="radio"
							:value="detail.chargingType"
							:checked="detail.chargingType === 'flat'"
							:disabled="
								!(detail.flatCost && detail.percentageCost)
							"
						/>
						<span class="fs-15">Flat</span>
					</label>

					<label
						v-if="!!detail.percentageCost"
						class="rdiobox"
						@change="handleSelect('percentage', index)"
					>
						<input
							type="radio"
							:value="detail.chargingType"
							:checked="detail.chargingType === 'percentage'"
							:disabled="
								!(detail.flatCost && detail.percentageCost)
							"
						/>
						<span class="fs-15">Percentage</span>
					</label>
				</div>
				<div class="col-md-5">
					<CustomInput
						:disabled="true"
						:value="getInputValue(detail)"
						cls="mb-0 w-100"
					/>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";

export default {
	name: "PriceSetting",
	mixins: [componentData],
	data() {
		return {
			pricing: this.details,
		};
	},
	props: ["details"],
	methods: {
		transformResult(details) {
			const services = Object.keys(details);
			return { services, details };
		},
		handleSelect(value, index) {
			const newPricing = [...this.pricing];
			let selected = newPricing[index];
			selected.chargingType = value;
			newPricing[index] = selected;
		},
		getInputValue(detail) {
			let text;
			const isCur = Boolean(detail.currency);
			if (detail.chargingType === "percentage") {
				let cost = `${detail.percentageCost || 0}% / Transaction `;

				let cap = detail.cap
					? `(${isCur ? detail.currency : ""} ${this.toCurrency(
							detail.cap,
							isCur ? " " : undefined
					  )} Cap) `
					: "";
				text = `${cost} ${cap}`;
			} else {
				text = `${isCur ? detail.currency : ""} ${this.toCurrency(
					detail.flatCost,
					isCur ? " " : undefined
				)} / Transaction`;
			}
			return text;
		},
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
};
</script>
