<template>
	<Modal
		:id="id"
		:title="'Update Settings'"
		:toggle="handleCloseModal"
		class="text-left"
	>
		<div v-if="currentState === 'IDLE'">
			<WalletOTP
				:loading="loading"
				:value="otp"
				:handleChange="handleOtp"
				:handleSubmit="handleSubmit"
			/>
		</div>

		<SuccessState
			v-if="currentState === 'SUCCESS'"
			:message="'Setting Updated Successfully'"
			:onSuccess="handleRefetch"
		/>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import WalletOTP from "../../../WalletOverview/modals/WalletOTP/WalletOTP";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import { getWalletApikey } from "../../../../../utils/helpers";

const initialState = () => ({
	loading: false,
	otp: 456789,
	currentState: "IDLE",
});

export default {
	mixins: [notifications],
	name: "UpdateWalletSettingsModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "payload", "disableSetting"],
	components: {
		Modal,
		WalletOTP,
		SuccessState,
	},
	methods: {
		handleOtp(otp) {
			this.otp = otp;
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			this.disableSetting();
		},
		async handleSubmit() {
			try {
				this.loading = true;
				const { bank_account_id } = this.payload || {};
				const request = { ...this.payload, otp: this.otp };

				if (bank_account_id)
					request.bank_account_id = bank_account_id.id;

				const response = await this.$store.dispatch(
					"wallets/updateMerchantSettings",
					{
						apiKey: getWalletApikey(this.$store),
						request,
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.currentState = "SUCCESS";
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
