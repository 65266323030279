export const auditCategories = [
	{
		name: "Settings",
		value: "Settings",
	},
	{
		name: "Account",
		value: "Account",
	},
];

export const tableHeadings = {
  id: "Audit Reference",
  event: "Event",
  name: "Name",
  category: "Category",
  createdAt: "Date Created",
};
