<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary--border px-4"
      @click="openSenderModal"
    >
      Add Sender
    </button>
    <AddSenderModal
      :refetch="refetch"
      :id="'addSender'"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
import AddSenderModal from "../../modal/AddSenderModal/AddSenderModal.vue";

export default {
  name: "AddSenderButton",
  props: ["refetch"],
  methods: {
    openSenderModal() {
      $("#addSender").modal("show");
    },
    closeModal() {
      $("#addSender").modal("hide");
    },
  },
  components: {
    AddSenderModal,
  },
};
</script>
