import { walletAPI } from "../../api";
const invoiceURL = "/invoices";

export default {
	getAllInvoices({ apiKey, params }) {
		return walletAPI.get(invoiceURL, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createInvoice({ apiKey, request }) {
		return walletAPI.post(invoiceURL, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updateInvoice({ apiKey, reference, request }) {
		return walletAPI.put(`${invoiceURL}/${reference}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	deleteInvoice({ apiKey, reference }) {
		return walletAPI.delete(`${invoiceURL}/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	issueInvoice({ apiKey, reference, request }) {
		return walletAPI.post(`${invoiceURL}/${reference}/issue`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
  getCustomerInvoiceStatement({ apiKey, params }) {
		return walletAPI.get(`${invoiceURL}/account/statement`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
