<template>
	<div class="card pt-3">
		<div class="row">
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<AccessControl>
								<CreatePlanButton :planRef="$refs.planRef" />
							</AccessControl>
						</template>
						<template slot="filterForm">
							<PlanTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadPlan"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="planRef"
					class="v-table"
					:columns="columns"
					:options="options"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template slot="name" slot-scope="{ row }">
						<span
							class="text-primary pointer"
							@click="
								$router.push(
									`/wallet/subscriptions/plans/${row.reference}`
								)
							"
							>{{ row.name }}
						</span>
					</template>
					<template slot="amount" slot-scope="{ row }">
						{{ row.currency
						}}{{ toCurrency(row.amount, " ") || "Not Available" }}
					</template>
					<template slot="interval" slot-scope="{ row }">
						{{ titleCase(row.interval) || "Not Available" }}
					</template>
					<template slot="subscription_type" slot-scope="{ row }">
						{{ titleCase(row.subscription_type) }}
					</template>
					<template slot="created_at" slot-scope="{ row }">
						<span>
							{{
								timeDateFormat(row.created_at).date +
								" " +
								timeDateFormat(row.created_at).time
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../public/assets/mixins/componentData";
import AccessControl from "@/utils/AccessControl/AccessControl";
import CreatePlanButton from "../buttons/CreatePlanButton/CreatePlanButton";
import DeletePlanButton from "../buttons/DeletePlanButton/DeletePlanButton";
import PlanTableFilters from "./PlanTableFilters/PlanTableFilters";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import { getRequestData } from "../enums";

const tableHeadings = {
	name: "Plan Name",
	amount: "Amount",
	interval: "Interval",
	subscription_type: "Subscription Type",
	reference: "Plan Reference",
	created_at: "Date Created",
};

export default {
	mixins: [notifications, componentData],
	name: "Plans",
	data() {
		return {
			loading: false,
			rLoading: false,
			errorMsg: "",
			size: 25,
			filters: {},
			...this.tableOptions(tableHeadings, 20),
		};
	},
	components: {
		CreatePlanButton,
		DeletePlanButton,
		PlanTableFilters,
		FilterWrapper,
		AccessControl,
		FailedState,
		Spinner,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch(filters) {
			const newFilters = Object.fromEntries(
				Object.entries(filters).filter(([_, v]) => v != "" && v != null)
			);
			if (filters) this.filters = newFilters;
			this.getPlans();
			this.$refs.planRef.refresh();
		},
		clearFilter() {
			this.filters = {};
			this.$refs.planRef.refresh();
		},
		async getPlans() {
			this.options.requestFunction = async (data) => {
				const requestData = getRequestData(
					this.$store,
					this.filters,
					data,
					this.size
				);
				try {
					return (
						await this.$store.dispatch(
							"plans/getAllPlans",
							requestData
						)
					).data.data;
				} catch (error) {
					this.loading = false;
					this.errorMsg = this.getError(error);
				}
			};
		},
		async downloadPlan() {
			this.rLoading = true;
			const request = getRequestData(this.$store, this.filters, {});

			const response = await this.$store.dispatch(
				"plans/getAllPlans",
				request
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				this.downloadReport(tableHeadings, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},

	created() {
		this.getPlans();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
