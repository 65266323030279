<template>
	<div class="w-100 mb-3">
		<div class="bg-light rounded p-4">
			<div class="flex__between">
				<div class="d-flex align-items-center" @click="goBack">
					<img
						src="/assets/img/icons/arrow-left.svg"
						width="30"
						alt=""
						class="pointer"
					/>
					<h4 class="m-0 ml-2">Back</h4>
				</div>

				<div class="d-flex">
					<MakeTransferButton
						:disable="false"
						:payload="payload"
						:resetTransfer="resetTransfer"
						:settings="settings"
					/>

					<button
						class="ml-3 btn btn-sm btn-danger"
						@click="resetTransfer"
					>
						Cancel Transfer
					</button>
				</div>
			</div>

			<div class="flex__between mt-4 no-margin">
				<!-- <div class="d-flex">
					<CustomInput
						:name="'reference_code'"
						v-model="request.reference_code"
						:placeholder="'Enter or generate ref'"
						:error="formErrors.reference_code"
						:handleError="handleError"
					/>

					<button
						class="btn btn-sm btn--primary ml-3"
						@click="generateRef"
					>
						Generate
					</button>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import MakeTransferButton from "../../../buttons/MakeTransferButton/MakeTransferButton";

const initialState = () => ({
	request: {
		pin: "kghxqwveJ3eSQJip/cmaMQ==",
	},
	loading: false,
	errorMsg: "",
	formErrors: {},
});

export default {
	name: "MakeTransfer",
	data() {
		return { ...initialState() };
	},
	props: ["verifiedAccount", "goBack", "resetTransfer", "settings"],
	components: {
		CustomInput,
		MakeTransferButton,
	},
	methods: {
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		generateRef() {
			this.request.reference_code = this.$uuid.v1().slice(0, 15);
		},
	},
	computed: {
		payload() {
			var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ";
			var string_length = 12;
			var randomstring = "";
			for (var i = 0; i < string_length; i++) {
				var randNum = Math.floor(Math.random() * chars.length);
				randomstring += chars.substring(randNum, randNum + 1);
			}
			const transfers = this.verifiedAccount.map((account) => {
				const newAccount = { ...account };
				delete newAccount.resolved;
				return newAccount;
			});
			const { pin } = this.request || {};
			const data = {
				reference_code: randomstring,
				pin,
				source: "wallet",
				transfers,
			};

			return data;
		},
	},
};
</script>
