<template>
	<div class="w-100">
		<v-client-table
			class="v-table row-pointer"
			:columns="columns"
			:data="splitList"
			:options="options"
		>
			<template slot="action" slot-scope="{ row }">
				<img
					@click="removeSplit(row.reference)"
					class="pointer"
					src="/assets/img/icons/trash.svg"
					alt="Remove Split"
				/>
			</template>
		</v-client-table>
	</div>
</template>

<script>
	const tableHeadings = {
		reference: "Sub Account ID",
		type: "Type",
		value: "Value",
		action: "",
	};

	export default {
		name: "SplitPaymentTable",
		data() {
			return {
				columns: [],
				options: {
					headings: tableHeadings,
					filterable: false,
					pagination: {
						show: false,
					},
				},
			};
		},
		props: ["splitList", "removeSplit"],
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
