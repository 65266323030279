<template>
    <form @submit.prevent="handleSubmit">
        <div class="row mb-2">
            <div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
                <CustomInput
                    :name="'reference'"
                    v-model="filters.reference"
                    :placeholder="'Reference'"
                />
            </div>
            <div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
                <CustomInput
                    :name="'first_name'"
                    v-model="filters.first_name"
                    :placeholder="'First Name'"
                />
            </div>
            <div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
                <CustomInput
                    :name="'last_name'"
                    v-model="filters.last_name"
                    :placeholder="'Last Name'"
                />
            </div>
            <div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
                <CustomInput
                    :name="'email'"
                    v-model="filters.email"
                    :placeholder="'Email'"
                />
            </div>
            <div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-2">
                <CustomInput
                    :name="'phone'"
                    v-model="filters.phone"
                    :placeholder="'Phone Number'"
                    :isNum="true"
                />
            </div>

            <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
                <DatePicker
                    :name="'from'"
                    v-model="filters.from"
                    :placeholder="'Start Date'"
                    :disabledDates="{ from: new Date() }"
                />
            </div>

            <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
                <DatePicker
                    :name="'to'"
                    v-model="filters.to"
                    :disabledDates="{ from: new Date(), to: filters.from }"
                    :placeholder="'End Date'"
                />
            </div>

            <div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
                <button
                    type="submit"
                    :disabled="disabled || loading"
                    class="btn btn-primary mx-3"
                >
                    Filter
                </button>

                <button
                    v-show="isFilter"
                    type="button"
                    class="btn btn-outline"
                    @click="resetFilters"
                >
                    Clear
                </button>

                <div class="d-inline-block">
                    <DownloadReport
                        :downloadReport="download"
                        :loading="rLoading"
                    />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { filters } from "../../../../../public/assets/mixins/filters";

const initialState = () => ({
    filters: {
        reference: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        from: null,
        to: null,
    },
    isFilter: false,
    qLoading: false,
});

export default {
    mixins: [filters],
    name: "CustomersFilters",
    data() {
        return { ...initialState() };
    },
    props: ["loading", "rLoading", "clearFilter", "submitFilter", "download"],
    computed: {
        disabled() {
            return !Object.values(this.filters).some((val) => val);
        },
    },
    methods: {
        resetFilters() {
            Object.assign(this.$data, { ...initialState() });
            this.clearFilter();
            this.isFilter = false;
        },
        handleSubmit() {
            this.isFilter = true;
            if (this.filters.from)
                this.filters.from = this.timeRangeFormat(
                    this.filters.from,
                    true
                );
            if (this.filters.to)
                this.filters.to = this.timeRangeFormat(this.filters.to);
            this.submitFilter(this.filters);
        },
    },
    watch: {
        "filters.from": function() {
            this.filters.to = null;
        },
    },
};
</script>
