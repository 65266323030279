<template>
	<div class="p-md-4 p-3">
		<div class="SectionItem no-b-b mb-3">
			<p class="title">Transactions</p>
		</div>

		<div class="content__slider">
			<TabsV2 :tabNames="tabNames" :tabId="'transTab'" :tabLink="true" />
		</div>

		<transition mode="out-in" name="slide">
			<router-view />
		</transition>
	</div>
</template>

<script>
import TabsV2 from "../../utils/Tabs/TabsV2";

const tabNames = [
	{
		tabName: "Credit History",
		value: "Credit_history",
		link: "/transactions",
	},
	{
		tabName: "Debit History",
		value: "Debit_history",
		link: "/transactions/debit",
	},
];

export default {
	name: "TransactionsLog",
	data() {
		return {
			tabNames,
		};
	},

	components: {
		TabsV2,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
};
</script>
