export const virtualTerminalObj = {
  total: 1,
  count: 1,
  data: [
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
  ],
  totalPages: 1,
  currentPage: 1,
};
export const posTerminalObj = {
  total: 1,
  count: 1,
  data: [
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "TER993232345",
      serialNo: 'SN-3446536',
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      bankName: "Zenith Bank",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
  ],
  totalPages: 1,
  currentPage: 1,
};
export const agentObj = {
  total: 1,
  count: 1,
  data: [
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
    {
      id: "AGT993232345",
      email: "amakaolisa@gmail.com",
      agent: "Amaka Olisa",
      phone: "0812 342 4354",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: 'Active'
    },
  ],
  totalPages: 1,
  currentPage: 1,
};

export const virtualTerminalData = {
  status: "Active",
  terminalId: "TER23445",
  agentId: "AGT0342843",
  agentName: "Amaka Olisa",
  email: "alao@mail.com",
  phone: "2348043434545",
  street: "5 Oke street",
  createdAt: "2021-12-20T07:19:05.526Z",
  couponAmount: 16,
  state: "Lagos",
  country: "Nigeria",
  bankName: "Zenith Bank",
  accNum: '034324532',
};

export const transactionsObj = {
  total: 1,
  count: 1,
  data: [
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
    {
      id: "RMO-429636",
      sender: "John Bello",
      reciever: "Chioma Joseph",
      currency: "USD",
      link: "bluLifrSdjjWer",
      narration: "School Fees",
      amount: "300",
      account_number: "323636242",
      bankName: "Access Bank",
      subscriptionType: "recurring",
      bank_code: "324",
      source: "Card",
      active: true,
      createdAt: "2021-12-20T07:19:05.526Z",
      updatedAt: "2021-12-20T07:19:05.526Z",
      firstName: "Alao",
      lastName: "John",
      email: "alao@mail.com",
      phone: "2348043434545",
      country: "Nigeria",
      state: "Lagos",
      city: "Bariga",
      street: "5 Oke street",
      postCode: "23454",
    },
  ],
  totalPages: 1,
  currentPage: 1,
};

export const virtualTerminalHistory = {
  total: 1,
  count: 1,
  data: [
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
    {
      reference: "POS-WITHDRAWAL-30F-JAOE90",
      type: "cashout",
      amount: "N80,546",
      charges: "N1000",
      stan: "92368",
      createdAt: "2021-12-20T07:19:05.526Z",
      status: "Successful",
    },
  ],
};