<template>
	<Modal :id="id" :title="'Create Payment Link'" :toggle="closeModal">
		<SuccessState
			v-if="successMsg"
			:message="'Payment Link Created Successfully'"
			:onSuccess="handleRefetch"
		/>

		<PaymentLinkForm
			v-else
			:onSuccess="onSuccess"
			:closeModal="closeModal"
		/>
	</Modal>
</template>

<script>
import PaymentLinkForm from "../../../common/PaymentLinkForm/PaymentLinkForm";
import SuccessState from "@/utils/ResponseState/SuccessState";
import Modal from "@/utils/Modal/Modal";

export default {
	name: "CreatePaymentLinkModal",
	data() {
		return {
			successMsg: false,
		};
	},
	props: ["id", "closeModal", "paymentLinkRef"],
	components: {
		Modal,
		SuccessState,
		PaymentLinkForm,
	},
	methods: {
		handleRefetch() {
			this.successMsg = false;
			this.closeModal();
			// this.paymentLinkRef.refresh();
			location.reload();
		},
		onSuccess() {
			this.successMsg = true;
		},
	},
};
</script>
