<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="dashboard-brief py-3">
          <h2 class="call-count">{{ toCurrency(totalValueProcessed) }}</h2>
          <p class="call-name">Total Value Processed</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="dashboard-brief py-3">
          <h2 class="call-count">{{ toCurrency(cashValue) }}</h2>
          <p class="call-name">Cash Registers</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="dashboard-brief py-3">
          <h2 class="call-count">{{ toCurrency(transactionProcessed) }}</h2>
          <p class="call-name">Online Transactions</p>
        </div>
      </div>
      <div class="offset-md-8 col-sm-4">
        <CustomSelect
          placeholder="Filter By"
          :optionLabel="'name'"
          :options="chartSelectOptions"
          v-model="range"
        />
      </div>
    </div>
    <div>
      <Spinner v-if="loading" />
      <apexchart
        type="bar"
        height="400"
        width="850"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
  chartSelectOptions,
  getDateRangeFromValue,
  getWalletApikey,
} from "@/utils/helpers";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import Spinner from "@/utils/Spinner/Spinner";
import pos from "@/services/wallets/pos";
import moment from "moment";

const defaultFilter = getDateRangeFromValue(chartSelectOptions[0]);

export default {
  name: "FTStatistics",
  data() {
    return {
      loading: true,
      totalValueProcessed: 0,
      transactionProcessed: 0,
      cashValue: 0,
      chartData: [],
      chartSelectOptions,
      filters: defaultFilter,
      range: "Today",
      series: [
        {
          name: "Online Transactions",
          data: ["Day"],
        },
        {
          name: "Cash Registers",
          data: [0],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        xaxis: {
          categories: [],
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };
  },
  components: { CustomSelect, Spinner },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchOverviewTrans() {
      this.loading = true;
      try {
        const transOverview = await pos.getPosMerchantOverview({
          apiKey: getWalletApikey(this.$store),
          params: this.filters,
        });
        let parsedData = transOverview.data.data;
        this.cashValue = parsedData.cashValue;
        this.transactionProcessed = parsedData.transactionProcessed[0].successful_sum;
        this.totalValueProcessed =
          parsedData.cashValue + parsedData.transactionProcessed[0].successful_sum;
        // this.series = [parsedData.onlineTransaction, parsedData.cashRegister];

        this.loading = false;
        const series2 = [
          {
            name: "Online Transactions",
            data: parsedData.chartData.map((d) => d.onlineValue),
          },
          {
            name: "Cash Registers",
            data: parsedData.chartData.map((d) => d.cashValue),
          },
        ];

        this.series = series2;

        const payload = {
          from: this.filters.from, // Startd Date
          to: this.filters.to, // End date
        };
        const min = new Date(payload.from).getDate();
        const max = new Date(payload.to).getDate();
        if (this.filters.frequency === "week") {
          let xAxis = parsedData.chartData.map((d) => `Week ${d.week}`);
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              categories: xAxis,
            },
          };

          if (parsedData.chartData.length === 0) {
            let tempArr = [];
            for (let index = min; index < max + 1; index++) {
              tempArr.push(index);
            }
            this.chartOptions = {
              ...this.chartOptions,
              xaxis: {
                categories: tempArr,
              },
            };
            
          }
        } else {
          const isNextMonth =
            moment(payload.to).month() > moment(payload.from).month();

          if (!isNextMonth) {
            let tempArr = [];
            for (let index = min; index < max + 1; index++) {
              tempArr.push(`Day ${index}`);
            }
            this.chartOptions = {
              ...this.chartOptions,
              xaxis: {
                categories: tempArr,
              },
            };

            let ddd = [];
            for (let i = 0; i < tempArr.length; i++) {
              for (let j = 0; j < parsedData.chartData.length; j++) {
                ddd.push(
                  tempArr[i] === `Day ${parsedData.chartData[j].day}` 
                    ? parsedData.chartData[j]
                    : { day: tempArr[i], cashValue: 0, onlineValue: 0 }
                );
              }
            }

            const series2 = [
              {
                name: "Online Transactions",
                data: ddd.map((d) => d.onlineValue),
              },
              {
                name: "Cash Registers",
                data: ddd.map((d) => d.cashValue),
              },
            ];

            this.series = series2;
            // [01, ..... 30]
          } else {
            const endOfPrevMonth = new Date(
              moment(payload.from).endOf("month").format("YYYY-MM-DD")
            ).getDate();

            let tempArr1 = [];
            let tempArr2 = [];
            for (let index = min; index < endOfPrevMonth + 1; index++) {
              tempArr1.push(index);
            }
            for (let index = 1; index < max + 1; index++) {
              tempArr2.push(index);
            }
            this.chartOptions = {
              ...this.chartOptions,
              xaxis: {
                categories: [...tempArr1, ...tempArr2],
              },
            };
            const totalArr = [...tempArr1, ...tempArr2];
            let ddd = [];
            for (let i = 0; i < totalArr.length; i++) {
              for (let j = 0; j < parsedData.chartData.length; j++) {
                ddd.push(
                  totalArr[i] === `Day ${parsedData.chartData[j].day}` 
                    ? parsedData.chartData[j]
                    : { day: totalArr[i], cashValue: 0, onlineValue: 0 }
                );
              }
            }

            const series2 = [
              {
                name: "Online Transactions",
                data: ddd.map((d) => d.onlineValue),
              },
              {
                name: "Cash Registers",
                data: ddd.map((d) => d.cashValue),
              },
            ];

            this.series = series2;
          }
        }
      } catch (err) {
        this.loading = false;
      }
    },
  },
  watch: {
    range: function (newRange) {
      this.filters = getDateRangeFromValue(newRange);
      this.fetchOverviewTrans();
    },
  },
  mounted() {
    this.fetchOverviewTrans();
  },
};
</script>

<style scoped></style>
