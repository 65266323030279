<template>
	<div class="row">
		<div class="col-12">
			<div class="bg-light rounded p-4">
				<h4>Requirements</h4>
				<ul>
					<li>Files must be CSV.</li>
					<li>
						CSV file should contain the following columns -
						<b
							>Account number, Account Name, Bank code, Amount
							(Naira) and Narration,
						</b>
					</li>
					<li>
						The order of the columns should be same as the order in
						which they are listed above with the first row as
						headers.
					</li>
				</ul>
				<div
					class="text-primary pointer border-bottom mb-3 pb-3 d-flex"
				>
					<img
						src="/assets/img/icons/download.png"
						alt="Download sample file"
						class="mr-2"
						width="20px"
					/>

					<a href="../../assets/bulk_transfer_sample.csv">
						<h5 class="mb-0">Download sample Bulk Transfer file</h5>
					</a>
				</div>
				<h5 class="mb-0 w-100 text-center">
					If you need help with bank codes,
					<span class="pointer text-primary" @click="toggleBankCode">
						click here to find bank code</span
					>
					or
					<span
						class="pointer text-primary"
						@click="downloadBankList"
					>
						Download the whole bank list</span
					>
				</h5>

				<div v-show="showBankCode" class="col-md-6 col-10 mx-auto mt-3">
					<BankSelect
						:name="'bank'"
						v-model="bank"
						:showCode="true"
						:hideLabel="true"
						:getAllBanks="getAllBanks"
					/>
				</div>
			</div>
		</div>

		<div class="col-6 mt-4 mx-auto">
			<CustomUpload
				:name="'accountList'"
				:validFileTypes="['text/csv']"
				:showOnlyBtn="true"
				:handleChange="handleUpload"
			/>
		</div>
	</div>
</template>

<script>
import CustomUpload from "../../../../utils/CustomUpload/CustomUpload";
import BankSelect from "../../../../utils/Selects/BankSelect/BankSelect.vue";
import { downloadReport } from "../../../../utils/helpers";

export default {
	name: "InitiateBulkTransfer",
	data() {
		return {
			bank: "",
			allBanks: [],
			formErrors: {},
			showBankCode: false,
		};
	},
	props: ["handleNext", "handleUpload"],
	components: {
		CustomUpload,
		BankSelect,
	},
	methods: {
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		toggleBankCode() {
			this.showBankCode = !this.showBankCode;
		},
		getAllBanks(banks) {
			this.allBanks = banks;
		},
		downloadBankList() {
			const headings = {
				name: "Bank Name",
				code: "Bank Code",
			};
			downloadReport(headings, this.allBanks);
		},
	},
};
</script>
