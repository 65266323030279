<template>
	<div class="az-content-body">
		<div class="row">
			<div class="col-12 mt-3">
				<div class="SectionItem mb-0">
					<div class="SectionHeader">
						<span class="title">API Documentation</span>
						<div class="description">
							API calls and responses for services
						</div>
					</div>
				</div>

				<div class="content__slider">
					<TabsV2
						:tabNames="tabNames"
						:tabId="'reportTab'"
						:tabLink="true"
					/>
				</div>

				<transition mode="out-in" name="slide">
					<router-view />
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import TabsV2 from "@/utils/Tabs/TabsV2";

const tabNames = [
	{
		tabName: "API Keys and Webhook",
		value: "api_keys_and_webhook",
		link: `/services/api-docs/keys-and-webhook`,
	},
];

export default {
	mixins: [componentData],
	name: "APIDocuments",
	data() {
		return {
			tabNames,
			details: null,
			loading: false,
		};
	},
	computed: {
		profile() {
			return this.$store.state.account.profile.api_customer;
		},
	},
	components: { TabsV2 },
	methods: {},
	created() {},
};
</script>
