<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <!-- Mobile Carrier Service -->
                <h2 class="user-profile-name">Airtime Service</h2>
                <h5 class="user-profile-email sub">
                  Recharge airtime across different networks
                </h5>
              </div>
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="buyAirtime">
              <div class="row mt-4">
                <!-- Application details -->
                <div class="col-md-12">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>
                <!-- Currency Type  -->
              </div>
              <div class="row mt-4">
                <!-- phone number -->
                <div class="col-12">
                  <CustomPhoneInput
                    :name="'phone'"
                    v-model="phoneNumber"
                    @blur="$v.phoneNumber.$touch()"
                    :label="'Phone Number'"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phoneNumber.$error && formVuelidator($v.phoneNumber)
                    "
                  />
                </div>

                <!-- Amount -->
                <div class="col-md-6" v-if="!allowedAmount">
                  <CustomInput
                    :name="'amount'"
                    placeholder="Enter amount to vend"
                    v-model="amount"
                    @blur="$v.amount.$touch()"
                    :label="'Amount'"
                    :required="true"
                    :isNum="true"
                    type='number'
                    :error="$v.amount.$error && formVuelidator($v.amount)"
                  >
                    <template slot="input_left">
                      <div class="ml-3">NGN</div>
                    </template>
                  </CustomInput>
                  <!-- <div class="form-group">
                    <label class="input-item-label">Amount</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <input
                      type="number"
                      v-model="amount"
                      min="0"
                      @blur="$v.amount.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter amount to vend"
                      @keypress="blockInvalidChar"
                      @wheel.prevent="$event.currentTarget.blur()"
                      step="any"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.amount.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.amount) }}
                      </li>
                    </ul>
                  </div> -->
                </div>
                <!-- Allowed Amounts-->
                <div class="col-md-6" v-if="allowedAmount">
                  <div class="form-group">
                    <label class="input-item-label">Allowed Amounts</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <v-select
                      class="v-select"
                      placeholder="Select amount"
                      v-model="amount"
                      @close="$v.amount.$touch()"
                      @keydown.enter.native.prevent
                      :required="!amount"
                      :disabled="!allowedAmounts"
                      :options="allowedAmounts"
                    ></v-select>
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.amount.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.amount) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Currency -->
                <div class="col-md-6" v-if="selectedCurrency == 'NGN'">
                  <div class="form-group">
                    <CurrencySelect :name="'currency'" v-model="currencyType" />

                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.currencyType.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.currencyType) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting"
                  >
                    <span v-if="isSubmitting">...Loading</span>
                    <span v-else>Recharge Airtime</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Airtime Recharge Response</h1>
              <span class="text-span"
                >Preview of the information gotten from airtime request made<br
              /></span>
              <br />
              <div class="row mt-4">
                <!-- Message -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Message</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.message }}
                    </h5>
                  </div>
                </div>
                <!-- Recipient -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Recipient</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.recipient }}
                    </h5>
                  </div>
                </div>
                <!-- Operator -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Operator</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.operator_name }}
                    </h5>
                  </div>
                </div>
                <!-- Amount -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Amount</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.destination_currency_code }}
                      {{ this.apiResponse.delivered_top_up_amount }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <!-- <div v-for="value in computedObj">{{value}}</div> -->
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}/BillPayment/AirtimeTopup`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
       {
        'country_code': '{{phoneNumber.formatted ? phoneNumber.countryCallingCode : 'country_code'}}',
        'phone_number': '{{ phoneNumber.formatted ? phoneNumber.nationalNumber.replace(/\s/g, "") : 'phone_number'  }}',
        'amount': '{{ amount ? amount : 'amount'  }}',<br v-if="selectedCurrency == 'NGN'" />{{selectedCurrency == "NGN" ? `'currency_type': ${currencyType ? currencyType.code : 'currency_type'},` : null }}
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
  "status_code": 200,
  "message": "Airtime top up successful",
  "data": {
    "status": "success",
    "message": "Transaction Successfull",
    "reference": "jQWYJbFosY3QVB59wv"
  }
}`}}
      </code></pre>
        </div>
      </div>
    </div>

    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>

    <!-- Modal -->
    <div
      class="modal fade"
      id="allowedAmount"
      tabindex="-1"
      role="dialog"
      aria-labelledby="allowedAmounttitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              <i class="la la-exclamation-circle"></i> Notice
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              {{ modalMessage }}
            </p>
            <h4>
              {{ modalDetails }}
            </h4>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, decimal, requiredIf } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect.vue";
import CustomInput from "@/utils/CustomInput/CustomInput.vue";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "v2",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      hasNewResponse: false,
      logData: [],
      apiResponse: "",
      // services
      hasService: false,
      servicePrice: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      isSubmitting: false,
      verificationResult: [],

      //airtime service
      phoneNumber: "",
      currencies: [],
      currency: "",
      amount: "",
      modalMessage: "",
      modalDetails: "",
      allowedAmount: false,
      allowedAmounts: [],
    };
  },
  validations: {
    apiVersion: { required },
    app: { required },
    amount: { required, decimal },
    currencyType: {
      required: requiredIf(function () {
        return this.selectedCurrency === "NGN";
      }),
    },
    phoneNumber: {
      required,
      isPhoneValid: isPhoneValid("phoneNumber"),
    },
  },
  components: { CustomPhoneInput, CurrencySelect, CustomInput },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
    app() {
      this.getServices();
    },
  },
  computed: {
    selectedCurrency() {
      return this.phoneNumber.country &&
        this.phoneNumber.country.iso2 &&
        this.phoneNumber.country.iso2 === "NG"
        ? "NGN"
        : "USD";
    },
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    formVuelidator,
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Mobile")) {
            console.log("YEAH 'MOBILE' FOUND >>>>", service.service_label);
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    getRequestData() {
      const payload = {
        phone_number: this.phoneNumber.nationalNumber.replace(/\s/g, ""),
        country_code: this.phoneNumber.countryCallingCode,
        amount: parseFloat(this.amount),
      };

      if (this.selectedCurrency === "NGN") {
        payload.currency_type = this.currencyType.code;
      }

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload,
        query: "",
      };
      return verificationData;
    },
    async buyAirtime() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      const airtimeData = this.getRequestData();
      try {
        this.isSubmitting = true;
        let apiResponse = await this.$store.dispatch(
          "services/vendAirtime",
          airtimeData
        );
        console.log("Api Response from Airtime Service ->", apiResponse);
        this.apiResponse = apiResponse;
        this.hasNewResponse = true;
        this.showSuccessNotification(apiResponse.message);
        Nprogress.done();
        this.isSubmitting = false;
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        if (error.allowed_amounts) {
          console.log("Allowed Amounts", error.allowed_amounts);
          this.modalMessage = error.message;
          this.modalDetails = error.allowed_amounts;
          this.allowedAmounts = error.allowed_amounts;
          this.amount = "";
          this.allowedAmount = true;
          $("#allowedAmount").modal();
        }
        this.showErrorLoginNotification(error.message);
      }
    },
    async getCurrencies() {
      try {
        const currencies = await this.$store.dispatch("wallets/getCurrencies");
        this.currencies = currencies.data.map((item) => {
          return item.code;
        });
      } catch (error) {
        console.log("Currency error :", error);
      }
    },
  },
  async mounted() {
    try {
      await this.getCurrencies();
      this.computeService();
      this.clientID = this.$store.state.account.clientID;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
