<template>
	<div
		class="tab-pane fade show active"
		id="Card"
		role="tabpanel"
		aria-labelledby="Card-tab"
	>
		<div class="row align-items-center">
			<div class="col-sm-5 text-center p-3 border-right">
				<DebitCard />
			</div>

			<div class="col-sm-7">
				<div class="row justify-content-end">
					<div class="col-4">
						<p class="call-name">Card Type</p>
						<p class="m-0">Physical</p>
					</div>

					<div class="col-4">
						<p class="call-name">Spending Limit</p>
						<p class="m-0">None</p>
					</div>

					<div class="col-4">
						<p class="call mb-name">Status</p>
						<p class="m-0">Active</p>
					</div>

					<div class="col-4 mt-4">
						<button
							type="button"
							class="btn btn-sm btn--primary--border px-3"
							@click="$router.push('/wallet/card')"
						>
							Manage Card
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DebitCard from "../../../../../../../utils/DebitCard/DebitCard";

	export default {
		name: "WalletCardsContent",
		components: {
			DebitCard,
		},
	};
</script>
