<template>
	<div class="d-flex justify-content-center">
		<div class="text-center">
			<img src="/assets/img/icons/success.svg" alt="" width="50px" />

			<h5 class="py-3" v-html="message" />

			<slot />

			<button
				v-show="otherBtn"
				class="btn btn--sm btn--primary-outline px-5 fw-bold mr-3"
				@click="otherAct"
			>
				{{ otherBtn }}</button
			><button
				class="btn btn--sm btn--primary px-5 mx-auto fw-bold"
				@click="success"
			>
				Done
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "SuccessState",
	props: ["message", "onSuccess", "otherAction", "otherBtn"],
	methods: {
		success(e) {
			this.onSuccess && this.onSuccess(e);
		},
		otherAct(e) {
			this.otherAction && this.otherAction(e);
		},
	},
};
</script>
