<template>
	<div>
		<img
			@click="openRemoveAccount"
			class="pointer"
			src="/assets/img/icons/trash.svg"
			alt="Delete Coupon"
		/>

		<DeleteFTAccountModal
			:id="`rem${account.index}`"
			:closeModal="closeModal"
			:account="account"
			:removeAccount="removeAccount"
		/>
	</div>
</template>

<script>
	import DeleteFTAccountModal from "../../modals/DeleteFTAccountModal/DeleteFTAccountModal";

	export default {
		name: "DeleteFTAccountButton",
		props: ["account", "removeAccount"],
		methods: {
			openRemoveAccount() {
				$(`#rem${this.account.index}`).modal("show");
			},
			closeModal() {
				$(`#rem${this.account.index}`).modal("hide");
			},
		},
		components: {
			DeleteFTAccountModal,
		},
	};
</script>
