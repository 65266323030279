export const walletCsvRows = {
	reference: "Wallet Reference",
	wallet_id: "Wallet ID",
	account_name: "Account Name",
	account_number: "Account Number",
	bank_name: "Bank Name",
	"Customer.first_name": "First Name",
	"Customer.Last_name": "Last Name",
	"Customer.email": "Email",
	"Customer.phone": "Phone Number",
	balance: "Balance",
	total_transactions_value: "Total Transaction(#)",
	createdAt: "Date Created",
};

export const tableHeadings = {
	account_name: "Account Name",
	account_number: "Account Number",
	bank_name: "Bank Name",
	wallet_id: "Wallet ID",
	"Customer.business": "Customer Type",
	createdAt: "Date Created",
};
