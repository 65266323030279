<template>
	<div @click="handleClick" class="table-modal-wrapper transaction">
		<div ref="modal" class="table-modal lg">
			<div style="text-align: right" class="p-3">
				<i
					style="cursor: pointer"
					@click="closeModal"
					class="far fa-times-circle"
				></i>
			</div>

			<p class="badge custom-badge" @click="copy">
				Refund Reference: <b>{{ data.reference }}</b>
			</p>

			<div
				class="p-4 border-top"
				style="
					margin-bottom: 10px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				"
			>
				<h4 class="font-weight-bold">Refund Information</h4>
				<div class="content">
					<div class="information">
						<p>Amount Refunded</p>
						<span
							>{{ data.currency }}
							{{ toCurrency(data.amount, " ") }}</span
						>
					</div>
					<div class="information">
						<p>Refund Charges</p>
						<span
							>{{ data.currency }}
							{{ toCurrency(data.charges, " ") }}</span
						>
					</div>
					<div class="information">
						<p>Payment Method</p>
						<span>{{ titleCase(data.payment_method) }}</span>
					</div>
					<div class="information">
						<p>Refund Status</p>
						<span
							v-if="data.status == 'successful'"
							class="text-success"
						>
							{{ titleCase(data.status) }}
						</span>
						<span
							v-else-if="
								data.status == 'pending' ||
								data.status == 'processing'
							"
							class="text-warning"
						>
							{{ titleCase(data.status) }}
						</span>
						<span v-else class="text-danger">
							{{ titleCase(data.status) }}
						</span>
					</div>

					<div class="information">
						<p>Refund Date</p>
						<span>
							{{
								timeDateFormat(data.createdAt).date +
								" " +
								timeDateFormat(data.createdAt).time
							}}
						</span>
					</div>

					<template v-if="data.system_generated">
						<PaymentRefundMeta :metadata="data.metadata || {}" />
					</template>
				</div>
			</div>
			<div
				style="
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 20px;
					margin-bottom: 30px;
				"
			>
				<h4 class="font-weight-bold">Payment Information</h4>
				<div class="content">
					<div class="information">
						<p>Payment Reference</p>
						<span>{{
							data.system_generated
								? data.original_transaction_reference
								: data.original_transaction.reference
						}}</span>
					</div>

					<div class="information">
						<p>Amount</p>
						<span
							>{{ data.currency }}
							{{
								toCurrency(
									data.system_generated
										? data.amount
										: data.original_transaction.amount,
									" "
								)
							}}</span
						>
					</div>

					<div class="information">
						<p>Charges</p>
						<span
							>{{ data.currency }}
							{{
								toCurrency(
									data.system_generated
										? 0
										: data.original_transaction.charges,
									" "
								)
							}}</span
						>
					</div>

					<div class="information">
						<p>Payment Status</p>
						<span
							v-if="paymentStatus == 'successful'"
							class="text-success"
						>
							{{ titleCase(paymentStatus) }}
						</span>
						<span v-else class="text-warning">
							{{ titleCase(paymentStatus) }}
						</span>
					</div>

					<template v-if="!data.system_generated">
						<PaymentRefundMeta
							:metadata="data.original_transaction.metadata || {}"
						/>
					</template>

					<template v-if="data.original_transaction">
						<div class="information">
							<p>Payment Method</p>
							<span>{{
								titleCase(data.original_transaction.source)
							}}</span>
						</div>
						<div class="information">
							<p>Transaction Date</p>
							<span>
								{{
									timeDateFormat(
										data.original_transaction.createdAt
									).date +
									" " +
									timeDateFormat(
										data.original_transaction.createdAt
									).time
								}}
							</span>
						</div>
						<div class="information">
							<p>Transaction Narration</p>
							<span>{{ data.narration || "Not Available" }}</span>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import PaymentRefundMeta from "./PaymentRefundMeta";
import { banks } from "../../../../dataset";
import {
	titleCase,
	timeDateFormat,
	copyToClipboard,
	toCurrency,
} from "@/utils/helpers";

export default {
	mixins: [notifications],
	name: "PaymentRefundModal",
	props: {
		data: {
			type: Object,
		},
	},
	components: {
		PaymentRefundMeta,
	},
	computed: {
		bankName() {
			if (this.data.metadata.destination) {
				const { bank_code } = this.data.metadata.destination;
				const bank = banks.find((v) => v.code === bank_code);
				return titleCase(bank.name);
			}
			return "";
		},
		paymentStatus() {
			return this.data.system_generated
				? this.data.status
				: this.data.original_transaction.status;
		},
	},
	methods: {
		titleCase,
		timeDateFormat,
		toCurrency,
		handleClick(e) {
			if (!e.target.closest(".table-modal")) {
				return this.$emit("close-modal");
			}
		},
		closeModal(e) {
			this.$emit("close-modal");
		},
		copy() {
			const copied = copyToClipboard(this.data.reference);
			copied &&
				this.showSuccessNotification("Transaction Reference Copied!");
		},
	},
};
</script>
