<template>
	<div class="card pt-3">
		<div class="row">
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="filterForm">
							<SubscriptionTrailFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadTrails"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="trailRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="handleClick"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template slot="CustomerId" slot-scope="{ row }">
						{{
							row.metadata.metadata.wallet
								? `${row.metadata.metadata.wallet.first_name} ${row.metadata.metadata.wallet.first_name}`
								: "Not Available"
						}}
					</template>
					<template slot="id" slot-scope="{ row }">
						{{ row.metadata.metadata.plan.name }}
					</template>
					<template slot="title" slot-scope="{ row }">
						{{ truncate(row.title, 20) }}
					</template>
					<template slot="description" slot-scope="{ row }">
						{{ truncate(titleCase(row.description), 20) }}
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						{{
							timeDateFormat(row.createdAt).date +
							" " +
							timeDateFormat(row.createdAt).time
						}}
					</template>
					<template slot="status" slot-scope="{ row }">
						<span :class="getStatus(row.status).style">
							{{ getStatus(row.status).value }}</span
						>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../public/assets/mixins";
import SubscriptionTrailFilters from "./SubscriptionTrailFilters/SubscriptionTrailFilters";
import { getRequestData, subscriptionTrailCsvRows } from "../enums";

const tableHeadings = {
	CustomerId: "Subscriber",
	id: "Plan",
	title: "Title",
	description: "Description",
	createdAt: "Date Created",
	status: "Status",
};

export default {
	mixins: [notifications, componentData],
	name: "SubscriptionTrails",
	data() {
		return {
			loading: false,
			rLoading: false,
			errorMsg: "",
			size: 25,
			filters: {},
			...this.tableOptions(tableHeadings, 20),
		};
	},
	components: {
		SubscriptionTrailFilters,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		getStatus(status) {
			return {
				value: status ? "Active" : "Not-Active",
				style: status ? "text-success" : "text-danger",
			};
		},
		handleClick({ row }) {
			this.$router.push(`/wallet/subscriptions/trails/${row.id}`);
		},
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch(filters) {
			const newFilters = Object.fromEntries(
				Object.entries(filters).filter(([_, v]) => v != "" && v != null)
			);
			if (filters) this.filters = newFilters;
			this.getTrails();
			this.$refs.trailRef.refresh();
		},
		clearFilter() {
			this.filters = {};
			this.$refs.trailRef.refresh();
		},
		async getTrails() {
			this.options.requestFunction = async (data) => {
				const requestData = getRequestData(
					this.$store,
					this.filters,
					data,
					this.size
				);
				try {
					return (
						await this.$store.dispatch(
							"trails/getAllTrails",
							requestData
						)
					).data.data;
				} catch (error) {
					this.loading = false;
					this.errorMsg = this.getError(error);
				}
			};
		},
		async downloadTrails() {
			this.rLoading = true;
			const request = getRequestData(this.$store, this.filters, {});

			const response = await this.$store.dispatch(
				"trails/getAllTrails",
				request
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				this.downloadReport(subscriptionTrailCsvRows, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},

	created() {
		this.getTrails();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
