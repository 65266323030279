<template>
  <div @click="$emit('handleSelect', data)" class="card-option w-100 pointer">
    <div class="d-flex align-items-center justify-content-between card-scheme">
      <div class="d-flex align-items-center px-0 icon__text">
        <img :src="card_image" alt="" />
        <p class="ml-2 mb-0">{{ titleCase(card.scheme) }}</p>
      </div>
      <div>
        <label class="rdiobox mr-0 mb-0">
          <input type="radio" :checked="checked" />
          <span class="fs-15"></span>
        </label>
      </div>
    </div>
    <div class="card-number w-50">
      {{ card_number.match(/.{1,4}/g).join(" ") }}
    </div>
  </div>
</template>

<script>
import { CARD_IMAGES, titleCase } from "@/utils/helpers";
export default {
  props: ["checked", "data"],
  data() {
    return {};
  },
  computed: {
    card() {
      return this.data.card || {};
    },
    card_number() {
      return `${this.card.bin}*****${this.card.last4}`;
    },
    card_image() {
      if (this.card && this.card.scheme) {
        return CARD_IMAGES[this.card.scheme];
      }
      return CreditCard;
    },
  },
  methods: {
    titleCase,
  },
};
</script>
