<template>
	<div id="editApplicationService" class="modal">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content modal-content-demo">
				<div class="modal-body">
					<button
						type="button"
						class="close pos-absolute t-20 r-30 tx-26"
						data-dismiss="modal"
						aria-label="Close"
						id="close-editApplicationService"
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h5 class="modal-title mg-b-5">
						Update Application Services
					</h5>
					<p>Let's get you all setup editing your app service(s).</p>
					<div class="b-b"></div>
					<div class="gaps-2x"></div>
					<form @submit.prevent="editApplication">
						<!-- CLient ID -->
						<div class="form-group">
							<label class="input-item-label">Client ID</label>
							<input
								type="text"
								disabled
								class="form-control input-bordered"
								:placeholder="clientID"
							/>
						</div>

						<!-- Application Services -->
						<div class="form-group">
							<label class="input-item-label">Services</label>
							<v-select
								class="v-select h-auto"
								placeholder="Select a service"
								v-model="modalData.app_services"
								@keydown.enter.native.prevent
								:options="userServices"
								label="service_label"
								multiple
								required
							></v-select>
						</div>
						<div class="gaps-2x"></div>
						<button
							type="submit"
							class="btn btn-lg btn-primary"
							:disabled="isProcessing == true"
						>
							<span v-if="isProcessing"
								>...editing application services</span
							>
							<span v-else>Update Application Service(s)</span>
						</button>
						<button
							type="button"
							class="btn btn-lg btn-light ml-2"
							data-dismiss="modal"
						>
							Close
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Nprogress from "nprogress";
	import { required } from "vuelidate/lib/validators";
	import { notifications } from "../../../public/assets/mixins/notifications";

	export default {
		mixins: [notifications],
		props: {
			modalData: {
				type: Object,
			},
		},
		data() {
			return {
				isProcessing: false,
			};
		},
		computed: {
			userServices() {
				return this.$store.state.account.profile.api_customer
					.customerServices;
			},
			metaData() {
				return this.$store.state.metaData;
			},
			clientID() {
				return this.$store.state.account.clientID;
			},
			getBundledPrice() {
				let total = 0;
				if (this.modalData.app_services) {
					for (let service of this.modalData.app_services) {
						total += service.price_per_call;
					}
				}
				return total;
			},
		},
		methods: {
			formatEditApplicationData() {
				let dataToBeSubmitted = {};
				let requestData = new FormData();

				if (this.modalData.app_services) {
					for (
						let index = 0;
						index < this.modalData.app_services.length;
						index++
					) {
						requestData.append(
							"service",
							this.modalData.app_services[index].id
						);
					}
				}

				dataToBeSubmitted.appname = this.modalData.app_name;
				dataToBeSubmitted.apikey = this.modalData.api_key;
				dataToBeSubmitted.clientid = this.clientID;
				dataToBeSubmitted.id = this.modalData.id;
				dataToBeSubmitted.requestPayload = requestData;

				return dataToBeSubmitted;
			},
			closeModalSuccess() {
				let closeButton = document.getElementById(
					"close-editApplicationService"
				);
				closeButton.click();

				this.$emit("refetchApplication");
			},
			async editApplication() {
				try {
					let requestPayload = this.formatEditApplicationData();
					let application = await this.$store.dispatch(
						"application/editApplicationServices",
						requestPayload
					);
					console.log("[] ->", application);
					this.showSuccessNotification(application.message);
					let self = this;
					setTimeout(() => {
						self.closeModalSuccess();
					}, 2500);
					Nprogress.done();
				} catch (error) {
					Nprogress.done();
					console.log("[error] ->", error);
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
