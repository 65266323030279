<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">Direct Debit Mandates</span>
            <div class="description">
              View and manage all the direct debit mandate instructions on your
              account.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <FilterBar
          @clicked="onFilterClick"
          :activityTypes="activityTypes"
        ></FilterBar>
        <div class="row">
          <div class="col-12">
            <v-client-table
              :data="tableData"
              :columns="columns"
              :options="options"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DebitHistory from "../transactions/DebitHistoryLogs";
import FilterBar from "../UI/FilterBar";
import moment from "moment";
export default {
  components: {
    DebitHistory,
    FilterBar,
  },
  data() {
    return {
      activityTypes: ["none", "pending", "successful"],
      tableData: [],
      allMandates: [],
      columns: [
        "mandateId",
        "status",
        "amount",
        "maxDebits",
        "startDate",
        "endDate",
      ],
      options: {
        sortable: [],
        perPage: 10,
        pagination: {
          chunk: 5,
        },

        headings: {
          mandateId: "Mandate ID",
          status: "Status",
          amount: "Amount",
          maxDebits: "Max Debits",
          startDate: "Start Date",
          endDate: "End Date",
        },
        perPageValues: [],
        filterByColumn: true,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
  methods: {
    onFilterClick(value) {
      let result = this.allMandates;

      if (value.endTime) {
        result = result.filter((mandate) => mandate.createdAt < value.endTime);
      }

      if (value.startTime) {
        result = result.filter(
          (mandate) => mandate.createdAt > value.startTime
        );
      }

      if (value.dateRange) {
        const startOfDay = moment()
          .startOf("day")
          .format("MMM D, YYYY, HH:mmA");
        const startOfWeek = moment()
          .startOf("week")
          .format("MMM D, YYYY, HH:mmA");
        const startOfMonth = moment()
          .startOf("month")
          .format("MMM D, YYYY, HH:mmA");

        if (value.dateRange == "none") return result;

        if (value.dateRange == "Today") {
          result = result.filter(
            (mandate) => startOfDay > moment(mandate.createdAt)
          );
        }

        if (value.dateRange == "This week") {
          result = result.filter(
            (mandate) => startOfWeek > moment(mandate.createdAt)
          );
        }
        if (value.dateRange == "This month") {
          result = result.filter(
            (mandate) => startOfMonth > moment(mandate.createdAt)
          );
        }
      }

      if (value.activityType) {
        if (value.activityType == "none") return result;
        result = result.filter(
          (mandate) => mandate.status == value.activityType
        );
      }

      this.tableData = result;
    },
    async getMandates() {
      try {
        let merchantData = {
          clientID: this.user.api_customer.client_id,
        };
        const ddMandates = await this.$store.dispatch(
          "directdebit/getDirectDebitMandates",
          merchantData
        );
        this.allMandates = ddMandates.data;
        this.tableData = this.allMandates;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.getMandates();
  },
};
</script>
