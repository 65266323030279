var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"overall-content",attrs:{"id":"print-statement"}},[_c('div',{ref:"printRef"},[_vm._l((_vm.getPaginatedData(
          _vm.data.data || _vm.data.invoices,
          8
        )),function(page,index){return [_c('div',{key:index},[_c('div',{staticClass:"bg--primary all-content my-4"},[_c('div',{staticClass:"statement-content box-shadow bg-white"},[(index == 0 && !_vm.type)?_c('TransactionContentHead',{attrs:{"data":_vm.data.data,"dates":_vm.dates}}):(index == 0 && _vm.type == 'wallet')?_c('WalletContentHeader',{attrs:{"data":_vm.data.data,"dates":_vm.dates}}):(index == 0 && _vm.type == 'invoices')?_c('InvoiceContentHeader',{attrs:{"data":_vm.data.invoices,"extra":_vm.data,"dates":_vm.dates}}):_vm._e(),_c('StatementTable',{attrs:{"transactions":page,"type":_vm.type}}),(
                  _vm.getPaginatedData(_vm.data.data || _vm.data.invoices, 8).length -
                    1 ===
                  index
                )?_c('ContentFooter'):_vm._e(),_c('div',{attrs:{"className":"page-no text-center my-4 black"}},[_vm._v(" Page "+_vm._s(index + 1)+" of "+_vm._s(_vm.getPaginatedData(_vm.data.data || _vm.data.invoices, 8).length)+" ")])],1)]),_c('div',{attrs:{"className":"page-break"}})])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }