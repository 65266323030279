<template>
  <div class="card pt-3">
    <div class="row">
      <div class="col-12 mt-3">
        <div class="px-3">
          <FilterWrapper>
            <template slot="pageSelect"> </template>
            <template slot="filterForm">
              <TransactionHistoryTableFilters
                :loading="loading"
                :rLoading="rLoading"
                :submitFilter="refetch"
                :clearFilter="clearFilter"
                :download="downloadTransactionHistory"
              />
            </template>
          </FilterWrapper>
        </div>
      </div>
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div v-else-if="errorMsg" class="col-12">
        <FailedState :errorMsg="errorMsg" :refetch="refetch" />
      </div>

      <div class="col-12" :class="tbClass">
        <v-server-table
          ref="subRef"
          class="v-table row-pointer"
          :columns="columns"
          :options="options"
          @row-click="showData"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
        >
          <template slot="created_at" slot-scope="{ row }">
            {{ timeDateFormat(row.created_at).date }}
             {{ timeDateFormat(row.created_at).time }}
          </template>
          <template slot="sender" slot-scope="{ row }">
            {{ `${row.senderFirstName} ${row.senderLastName}` }}
          </template>
          <template slot="active" slot-scope="{ row }">
            <span :class="statusColor(row.status)">{{ row.status }}</span>
          </template>
        </v-server-table>
      </div>
    </div>
    <RemittanceModal
      v-if="showModal"
      :data="modalData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TransactionHistoryTableFilters from "./TransactionHistoryTableFilters/TransactionHistoryTableFilters";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import RemittanceModal from "./RemittanceModal";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import AccessControl from "@/utils/AccessControl/AccessControl";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  statusColor,
  getWalletApikey,
} from "@/utils/helpers";
import { trnasactionHistoryCsvRows } from "../enums";
const tableHeadings = {
  reference: "Reference",
  sender: "Sender",
  senderEmail: "Email",
  currency: "Currency",
  amount: "Amount",
  active: "Status",
  created_at: "Created At",
};

export default {
  mixins: [componentData],
  name: "TranactionHistory",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 25),
    };
  },
  components: {
    RemittanceModal,
    FilterWrapper,
    TransactionHistoryTableFilters,
    AccessControl,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    statusColor,
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getTransactionHistory();
      this.$refs.subRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.subRef.refresh();
    },
    async getTransactionHistory() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch(
              "remittance/getTransactionHistory",
              payload
            )
          ).data.data;
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
    async downloadTransactionHistory() {
      const download = (filterData) =>
        this.downloadReport(trnasactionHistoryCsvRows, filterData.data.data);
      this.downloadTableReport("remittance/getTransactionHistory", download);
    },
  },

  created() {
    this.getTransactionHistory();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
