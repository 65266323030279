<template>
	<TransactionsTable :params="{ remittance: true }" />
</template>

<script>
import TransactionsTable from "../../common/TransactionsTable/TransactionsTable";

export default {
	name: "RemittanceTransactions",
	components: {
		TransactionsTable,
	},
};
</script>
