<template>
  <div
    class="tab-pane active fade show"
    id="Beneficiary_Information"
    role="tabpanel"
    aria-labelledby="Beneficiary_Information-tab"
  >
    <div class="row mt-4" v-if="senderData">
      <div class="col-md-7 mb-4">
        <ul class="sub_card h-100">
          <h5>Personal Information</h5>
          <li>
            <p>First Name</p>
            <p>{{ senderData.firstName }}</p>
          </li>
          <li>
            <p>Last Name</p>
            <p>{{ senderData.lastName }}</p>
          </li>
          <li>
            <p>Middle Name</p>
            <p>{{ senderData.middleName }}</p>
          </li>
          <li>
            <p>Email</p>
            <p>{{ senderData.email }}</p>
          </li>
          <li>
            <p>Phone</p>
            <p>{{ senderData.phoneNumber }}</p>
          </li>
          <li>
            <p>Country</p>
            <p>{{ senderData.country }}</p>
          </li>
          <li>
            <p>State</p>
            <p>{{ senderData.state }}</p>
          </li>
          <li>
            <p>City</p>
            <p>{{ senderData.city }}</p>
          </li>
          <li>
            <p>Post Code</p>
            <p>{{ senderData.postCode }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import {
  titleCase,
  toCurrency,
  statusColor,
  timeDateFormat,
  getWalletApikey,
} from "@/utils/helpers";
import Spinner from "@/utils/Spinner/Spinner";

export default {
  name: "BeneficiaryInformation",
  components: {
    Spinner,
  },
  data() {
    return {
      senderData: null,
    };
  },
  methods: {
    titleCase,
    toCurrency,
    statusColor,
    timeDateFormat,
    getWalletApikey,
    async getBeneficiaries() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          reference: this.$route.params.id,
        },
      };

      try {
        const resp = (
          await this.$store.dispatch("remittance/getBeneficiaries", payload)
        ).data.data;
        this.senderData = resp.data[0];
      } catch (error) {
        console.log("transctionError", error);
      }
    },
  },
  created() {
    this.getBeneficiaries();
  },
};
</script>
