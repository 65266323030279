import payment from "@/services/wallets/payment";
import { apiClient } from "@/services/api";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
const checkoutBaseUrl = process.env.VUE_APP_CHECKOUT_URL;

export default {
	namespaced: true,
	actions: {
		async getPaymentLinks({}, payload) {
			let response = await payment.getPaymentLinks(payload);
			let { data } = response || {};
			let newData = data.data.data.map((link) => ({
				...link,
				url: `${checkoutBaseUrl}/link/${link.reference}`,
			}));
			return {
				...response,
				data: {
					...response.data,
					data: { ...response.data.data, data: newData },
				},
			};
		},
		async getPaymentLink({}, payload) {
			return await payment.getPaymentLink(payload);
		},

		async createPaymentLink({}, payload) {
			return await payment.createPaymentLink(payload);
		},
		async sendPaymentLink({}, payload) {
			return await payment.sendPaymentLink(payload);
		},
		async updatePaymentLink({}, payload) {
			return await payment.updatePaymentLink(payload);
		},
		async deletePaymentLink({}, payload) {
			let response = await payment.deletePaymentLink(payload);
			return response;
		},

		async getMerchantSubAccounts({}, payload) {
			return await payment.getMerchantSubAccounts(payload);
		},
		async getMerchantSplitPayments({}, payload) {
			return await payment.getMerchantSplitPayments(payload);
		},
		async getMerchantSubAccount({}, payload) {
			let response = await payment.getMerchantSubAccount(payload);
			return response;
		},
		async getMerchantSplitPayment({}, payload) {
			let response = await payment.getMerchantSplitPayment(payload);
			return response;
		},
		async getSplitPaymentTransaction({}, payload) {
			let response = await payment.getSplitPaymentTransaction(payload);
			return response;
		},
		async createMerchantSubAccounts({}, payload) {
			let response = await payment.createMerchantSubAccounts(payload);
			return response;
		},
		async createMerchantSplitPayment({}, payload) {
			let response = await payment.createMerchantSplitPayment(payload);
			return response;
		},
		async updateMerchantSplitPayment({}, payload) {
			let response = await payment.updateMerchantSplitPayment(payload);
			return response;
		},
		async getMerchantPayout({}, payload) {
			return await payment.getMerchantPayout(payload);
		},
		async getPayoutDetails({}, payload) {
			return await payment.getPayoutDetails(payload);
		},
		async getPaymentCollections({}, payload) {
			return await payment.getPaymentCollections(payload);
		},
		async getPaymentPages({}, payload) {
			return await payment.getPaymentPages(payload);
		},
		async getOnePaymentPage({}, payload) {
			return await payment.getOnePaymentPage(payload);
		},
		async getPaymentCollection({}, payload) {
			return await payment.getPaymentCollection(payload);
		},
		async createPaymentCollection({}, payload) {
			return await payment.createPaymentCollection(payload);
		},
		async createPaymentPage({}, payload) {
			return await payment.createPaymentPage(payload);
		},
		async updatePaymentPage({}, payload) {
			return await payment.updatePaymentPage(payload);
		},
		async getDisputes({}, payload) {
			return await payment.getDisputes(payload);
		},
		async getDispute({}, payload) {
			return await payment.getDispute(payload);
		},
		async createDispute({}, payload) {
			return await payment.createDispute(payload);
		},
		async updateDisputeStatus({}, payload) {
			return await payment.updateDisputeStatus(payload);
		},
		async getRefunds({}, payload) {
			return await payment.getRefunds(payload);
		},
		async chargeCard({}, payload) {
			return await payment.chargeCard(payload);
		},
		async getRefunds({}, payload) {
			try {
				return await payment.getRefunds(payload);
			} catch (error) {
				handleError(error);
			}
		},
		async getRefunds({}, payload) {
			try {
				return await payment.getRefunds(payload);
			} catch (error) {
				handleError(error);
			}
		},
	},
	getters: {},
};
