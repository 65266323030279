<template>
	<div class="form-group">
		<label v-if="!hideLabel" class="input-item-label"
			>Select Currency</label
		>
		<span
			v-if="!hideLabel"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<v-select
			class="v-select"
			placeholder="Select Currency"
			@keydown.enter.native.prevent
			label="currency"
			:options="currencyList"
			:value="value"
			v-on:input="$emit('input', $event)"
			@close="$emit('close', $event)"
			:disabled="disabled"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";

export default {
	name: "CurrencySelect",
	// mixins: [componentData],
	data() {
		return {
			loading: false,
			currencies: [],
			wallets: [],
		};
	},
	props: [
		"name",
		"hideLabel",
		"value",
		"error",
		"handleError",
		"getFirstData",
		"disabled",
		"ngnOnly",
	],
	computed: {
		currencyList() {
			return this.currencies?.reduce((results, currency) => {
				const exist = this.wallets?.find(
					(wallet) => currency.currency === wallet.currency
				);
				return exist ? [...results, currency] : results;
			}, []);
		},
	},
	methods: {
		async getCurrencies() {
			this.loading = true;
			try {
				const response = await this.$store.dispatch(
					"wallets/getCurrencies"
				);

				const currency = response.data.find(
					(val) => val.code === "NGN"
				);

				this.currencies = this.ngnOnly ? [currency] : response.data;
				this.loading = false;

				this.$emit("input", currency);
				this.getFirstData && this.getFirstData(currency);
			} catch (error) {
				this.loading = false;
				this.currencies = [];
			}
		},
		async getMasterWallet() {
			try {
				const response = (
					await this.$store.dispatch("wallets/getMasterWallet", {
						apiKey: getWalletApikey(this.$store),
					})
				).data;

				this.wallets = response;
			} catch (error) {
				this.wallets = [];
			}
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getMasterWallet();
		this.getCurrencies();
	},
};
</script>
