<template>
  <div class="p-4">
    <!-- <EmptyWallet v-if="!merchant" /> -->

    <template>
      <div class="flex__between">
        <div class="SectionItem no-b-b mb-4 justify-content-start">
          <img
            src="/assets/img/icons/arrow-left.svg"
            width="30"
            alt=""
            class="pointer"
            @click="$router.push('/wallet/terminal/pos')"
          />
          <p class="title ml-3 mb-0">Terminal Details</p>
        </div>
      </div>

      <template>
        <div class="content__slider">
          <Tabs
            :tabNames="['Terminal_Information', 'Terminal_Transactions']"
            :tabId="'subTab'"
          />
        </div>

        <div class="tab-content" id="subTabContent">
          <TerminalInformation  />
          <TerminalHistory />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Tabs from "../../../../../utils/Tabs/Tabs";
import EmptyWallet from "../../../common/EmptyWallet/EmptyWallet";
import TerminalHistory from "./TerminalHistory/TerminalHistory.vue";
import TerminalInformation from "./TerminalInformation/TerminalInformation";
import Spinner from "../../../../../utils/Spinner/Spinner";
import FailedState from "../../../../../utils/ResponseState/FailedState";
import "./SubscriptionDetails.css";

export default {
  name: "VirtualTerminalDetails",
  data() {
    return {
      id: "",
      loading: false,
      errorMsg: "",
      subscription: {},
    };
  },
  components: {
    Spinner,
    FailedState,
    EmptyWallet,
    Tabs,
    TerminalInformation,
    TerminalHistory,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
  },
  methods: {},

  async mounted() {
    const id = this.$route.params.id;
    this.id = id;
    this.getSubsciption(id);
  },
};
</script>
