<template>
  <CustomSelect
    class="v-select"
    :label="label"
    :cls="cls"
    :placeholder="placeholder"
    :getLabel="getLabel"
    :optionLabel="optionLabel"
    :options="countries"
    :value="value"
    @input="$emit('input', $event)"
    :disabled="disabled"
    :multiple="multiple"
    :clearable="clear"
    :loading="loading"
    @close="$emit('close', $event)"
    :required="required"
    :error="error"
  />
</template>

<script>
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
export default {
  name: "CountrySelect",
  components: {
    CustomSelect,
  },
  props: [
    "name",
    "label",
    "optionLabel",
    "placeholder",
    "value",
    "required",
    "disabled",
    "getLabel",
    "loading",
    "error",
    "handleError",
    "multiple",
    "cls",
    "clear",
    "otherCls",
  ],
  computed: {
    countries() {
      return this.$store.state.metaData.countries;
    },
  },
  watch: {
    value() {
      this.handleError && this.handleError(this.name);
    },
  },
};
</script>
