<template>
  <section>
    <div class="mb-3 border-bottom">
      <div class="d-flex align-items-center justify-content-start mb-2">
        <img
          v-if="successMsg"
          src="/assets/img/icons/arrow-left.svg"
          width="30"
          alt=""
          class="pointer mr-3"
          @click="goBack"
        />
        <p class="user-profile-name m-0">Know Your Customer (Advance)</p>
      </div>

      <div class="flex__between pb-3">
        <h5 class="user-profile-email sub m-0">
          Verify customer information linked to account number
        </h5>
        <PricesAndAccess
          :serviceLabel="'Know-Your-Customer (Advance)'"
          :getServiceStatus="getServiceStatus"
        />
      </div>
    </div>

    <form v-if="!successMsg" @submit.prevent="handleSubmit" class="row pt-3">
      <div class="col-sm-6">
        <BankSelect
          :name="'bank'"
          :handleError="handleError"
          :error="
            formErrors.bank ||
            ($v.request.bank.$error && formVuelidator($v.request.bank))
          "
          v-model="request.bank"
          @close="$v.request.bank.$touch()"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'nuban'"
          :maxlength="10"
          :placeholder="'Account Number'"
          v-model="request.nuban"
          @blur="$v.request.nuban.$touch()"
          :error="
            formErrors.nuban ||
            ($v.request.nuban.$error && formVuelidator($v.request.nuban))
          "
          :handleError="handleError"
          :label="'Enter Account Number'"
          required="true"
        />
      </div>

      <div class="col-sm-6 mt-3">
        <ActiveAppSelect
          :name="'app'"
          :handleError="handleError"
          :error="
            formErrors.app ||
            ($v.request.app.$error && formVuelidator($v.request.app))
          "
          v-model="request.app"
          @close="$v.request.app.$touch()"
        />
      </div>
      <div class="col-sm-6 mt-3">
        <CurrencySelect
          :name="'currency_type'"
          :handleError="handleError"
          :error="
            formErrors.currency_type ||
            ($v.request.currency_type.$error &&
              formVuelidator($v.request.currency_type))
          "
          v-model="request.currency_type"
          @close="$v.request.currency_type.$touch()"
        />
      </div>

      <div v-if="hasService" class="col-sm-6 mt-4">
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100"
          :disabled="loading"
        >
          <Spinner v-if="loading" />
          <span v-else>Verify</span>
        </button>
      </div>
    </form>

    <AdvancedBankAccountVerificationResult
      v-else
      :result="apiResponse.data.data"
    />

    <ResponsePreview>
      <template slot="endpoint">
        POST : '{{ API }}Onboarding/Kyc/NubanVerify'
      </template>

      <template slot="header">
        {{
          `{
						"clientid": ${clientID},
						"appname": ${request.app ? request.app.app_name : "app_name"},
						"apikey": ${request.app ? request.app.api_key : "app_key"}
					}`
        }}
      </template>

      <template slot="body">
        {{
          `{
						"nuban": ${request.nuban ? request.nuban : "nuban"},
						"code": ${request.code ? request.bank.code : "bank_code"}
						"currency_type": ${
              request.currency_type
                ? request.currency_type.code
                : "currency_type"
            }
					}`
        }}
      </template>

      <template slot="sample">
        {{ apiResponse ? apiResponse.data : sampleResponse }}
      </template>
    </ResponsePreview>
  </section>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import BankSelect from "../../../../utils/Selects/BankSelect/BankSelect";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import Spinner from "../../../../utils/Spinner/Spinner";
import ActiveAppSelect from "../../../../utils/Selects/ActiveAppSelect/ActiveAppSelect";
import CurrencySelect from "../../../../utils/Selects/CurrencySelect/CurrencySelect";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import PricesAndAccess from "../../../../utils/PricesAndAccess/PricesAndAccess";
import AdvancedBankAccountVerificationResult from "./AdvancedBankAccountVerificationResult/AdvancedBankAccountVerificationResult";
import ResponsePreview from "../../../../utils/ResponsePreview/ResponsePreview";
import { sampleResponse } from "./AdvancedBankAcctVerSample";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const initialState = () => ({
  request: {
    bank: "",
    nuban: "",
    app: "",
    currency_type: "",
  },
  loading: false,
  successMsg: false,
  formErrors: {},
  apiResponse: "",
  sampleResponse,
});

export default {
  mixins: [notifications],
  name: "AdvancedBankAccountVerification",
  data() {
    return { hasService: false, ...initialState() };
  },
  validations: {
    request: {
      currency_type: { required },
      bank: { required },
      app: { required },
      nuban: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  components: {
    CustomSelect,
    CustomInput,
    CurrencySelect,
    BankSelect,
    ActiveAppSelect,
    Spinner,
    PricesAndAccess,
    AdvancedBankAccountVerificationResult,
    ResponsePreview,
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
    clientID() {
      return this.$store.state.account.clientID;
    },
  },
  methods: {
    formVuelidator,
    goBack() {
      Object.assign(this.$data, { ...initialState() });
    },
    getServiceStatus(hasService) {
      this.hasService = hasService;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$v.$touch();
      const { formErrors, isValid } = formValidator(this.request);

      if (isValid && !this.$v.$invalid) this.verifiyInformation();
      else this.formErrors = formErrors;
    },
    async verifiyInformation() {
      try {
        this.loading = true;

        const headers = {
          clientid: this.$store.state.account.clientID,
          appname: this.request.app.app_name,
          apikey: this.request.app.api_key,
        };
        const { bank, nuban, currency_type } = this.request || {};

        const response = await this.$store.dispatch(
          "services/nubanOnboardingVerification",
          {
            headers,
            payload: {
              nuban,
              code: bank.code,
              currency_type: currency_type.code,
            },
          }
        );

        if (response.status === 200) {
          this.apiResponse = response;
          this.loading = false;
          this.successMsg = true;
        }
      } catch (error) {
        this.loading = false;
        typeof error.message === "string"
          ? this.showErrorLoginNotification(error.message)
          : this.showErrorLoginNotification("Error Validation Profile");
      }
    },
  },
};
</script>
