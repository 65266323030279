<template>
	<Modal :id="id" :toggle="closeModal">
		<SuccessState
			v-if="successMsg"
			:message="'Chargeback Accepted Successfully'"
			:onSuccess="handleRefetch"
		/>
		<form v-else @submit.prevent="handleSubmit" class="h-100">
			<h2 class="heading">Accept Chargeback Dispute?</h2>
			<p class="sub-heading my-4">
				When you accept this chargeback dispute, a refund transaction
				will be processed and your customer's account will be refunded,
				click on "Accept Chargeback" to complete accept process.
			</p>
			<FormButtons
				:loading="loading"
				:handleCloseModal="closeModal"
				closeBtnName="Cancel"
				btnName="Accept Chargeback"
			/>
		</form>
	</Modal>
</template>

<script>
import Modal from "@/utils/Modal/Modal";
import { getWalletApikey } from "@/utils/helpers";
import FormButtons from "@/utils/FormButtons/FormButtons";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { notifications } from "../../../../../../../public/assets/mixins/notifications";

export default {
	props: ["id", "chargeback", "closeModal", "refetch"],
	mixins: [notifications],
	data() {
		return {
			loading: false,
			successMsg: false,
		};
	},
	components: {
		Modal,
		SuccessState,
		FormButtons,
	},
	methods: {
		handleRefetch() {
			this.closeModal();
			this.refetch();
			this.successMsg = false;
			this.loading = false;
		},
		async handleSubmit() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/updateChargeBack",
					{
						apiKey: getWalletApikey(this.$store),
						reference: this.chargeback.reference,
						payload: {
							status: "ACCEPTED",
						},
					}
				);

				if (
					(response.status === 200 || response.status === 201) &&
					response.data.status === true
				) {
					this.successMsg = true;
				} else this.showErrorLoginNotification(response.data.message);

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>

<style scoped>
.heading {
	color: #071b2b;
	font-size: 22px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
}

.sub-heading {
	color: #505050;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
}
</style>
