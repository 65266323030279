<template>
  <div class="az-content-box">
    <div class="az-content-left">
      <div class="az-services-menu">
        <div
          id="accordion"
          class="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div class="card-header panel-heading" role="tab" id="headingOne">
            <a
              data-toggle="collapse"
              href="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <label
                class="az-content-label az-content-label-sm"
                style="cursor: pointer"
                >Identity(Nigeria)</label
              >
            </a>
          </div>
          <div
            id="collapseOne"
            data-parent="#accordion"
            class="collapse show"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <nav class="nav az-nav-column mg-b-20" role>
              <a
                data-toggle="tab"
                @click="tabswitch(1)"
                href
                :class="{ active: tab == 1 }"
                class="nav-link"
                >Bank Verification Number (BVN)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(8)"
                href
                :class="{ active: tab == 8 }"
                class="nav-link"
                >BVN With Image (IBVN)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(13)"
                href
                :class="{ active: tab == 13 }"
                class="nav-link"
                >Bank Verification Number (Bulk-BVN)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(2)"
                href
                :class="{ active: tab == 2 }"
                class="nav-link"
                >Permanent Voters Card (PVC)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(3)"
                href
                :class="{ active: tab == 3 }"
                class="nav-link"
                >National Identity Number (NIN)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(15)"
                href
                :class="{ active: tab == 15 }"
                class="nav-link"
                >Bulk National Identity (Bulk-NIN)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(14)"
                href
                :class="{ active: tab == 14 }"
                class="nav-link"
                >National Identity Number (NIN-By-PhoneNumber)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(25)"
                href
                :class="{ active: tab == 25 }"
                class="nav-link"
                >National Identity Number (NIN-By-PhoneNumber-Basic)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(31)"
                href
                :class="{ active: tab == 31 }"
                class="nav-link"
                >Bulk National Identity Number (Bulk-pNIN-Basic)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(30)"
                href
                :class="{ active: tab == 30 }"
                class="nav-link"
                >Virtual National Identity Number (vNIN)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(4)"
                href
                :class="{ active: tab == 4 }"
                class="nav-link"
                >Nigerian International Passport (NIP)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(5)"
                href
                :class="{ active: tab == 5 }"
                class="nav-link"
                >Drivers Licence (DL)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(6)"
                href
                :class="{ active: tab == 6 }"
                class="nav-link"
                >Address Verification</a
              >
            </nav>
          </div>
        </div>
        <!-- Ghana Identity Verification--->
        <div
          id="accordion"
          class="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div class="card-header panel-heading" role="tab" id="headingFive">
            <a
              data-toggle="collapse"
              href="#collapseFive"
              aria-controls="collapseFive"
            >
              <label
                class="az-content-label az-content-label-sm"
                style="cursor: pointer"
                >Identity(Ghana)</label
              >
            </a>
          </div>
          <div
            id="collapseFive"
            data-parent="#accordion"
            class="collapse"
            role="tabpanel"
            aria-labelledby="headingFive"
          >
            <nav class="nav az-nav-column mg-b-20" role>
              <a
                data-toggle="tab"
                @click="tabswitch(18)"
                href
                :class="{ active: tab == 18 }"
                class="nav-link"
                >Voters ID Card (GVC)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(19)"
                href
                :class="{ active: tab == 19 }"
                class="nav-link"
                >International Passport (GIP)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(20)"
                href
                :class="{ active: tab == 20 }"
                class="nav-link"
                >Social Security Number (GSSNIT)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(21)"
                href
                :class="{ active: tab == 21 }"
                class="nav-link"
                >Drivers Licence (GDL)</a
              >
            </nav>
          </div>
        </div>
        <!-- Ghana Identity Verification--->
        <div
          id="accordion"
          class="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div class="card-header panel-heading" role="tab" id="headingSix">
            <a
              data-toggle="collapse"
              href="#collapseSix"
              aria-controls="collapseSix"
            >
              <label
                class="az-content-label az-content-label-sm"
                style="cursor: pointer"
                >Identity(Kenya)</label
              >
            </a>
          </div>
          <div
            id="collapseSix"
            data-parent="#accordion"
            class="collapse"
            role="tabpanel"
            aria-labelledby="headingSix"
          >
            <nav class="nav az-nav-column mg-b-20" role>
              <a
                data-toggle="tab"
                @click="tabswitch(39)"
                href
                :class="{ active: tab == 39 }"
                class="nav-link"
                >International Passport (KIP)</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(40)"
                href
                :class="{ active: tab == 40 }"
                class="nav-link"
                >National ID</a
              >
            </nav>
          </div>
        </div>
      </div>
    </div>

    <loader v-if="isProcessing == true"></loader>

    <div class="az-content-body tab-content" v-else>
      <!-- <transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			> -->
      <template v-if="profile.is_active && application.length > 0">
        <BVNService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 1"
        ></BVNService>
        <vNINService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 30"
        />
        <PVC-Service
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 2"
        />
        <NIN-Service
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 3"
        />
        <NIP-Service
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 4"
        />
        <DL-Service
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 5"
        />
        <Address-Service
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 6"
        />
        <BVNWithImageService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 8"
        />
        <BulkBVNService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 13"
        />
        <NINByPhoneService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 14"
        />
        <NINByPhoneServiceBasic
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 25"
        />
        <BulkPNINBasicService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 31"
        />
        <BulkNINUploadService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 15"
        />
        <GhanaVotersIdService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 18"
        />
        <GhanaInternationalPassportService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 19"
        />
        <GhanaSSNITService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 20"
        />
        <GhanaDriversLicenceService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 21"
        />
        <KenyaInternationalPassport
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 39"
        />
        <KenyaNationalIDService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 40"
        />
      </template>
      <!-- </transition> -->
      <DefaultContent
        v-if="!profile.is_active"
        :image="'empty-resources.png'"
        :title="`Seems you've not verified your account.`"
        :subtitle="'Verify your account to start testing out the different services. Contact the Admin to verify your account'"
      />
      <DefaultContent
        v-if="profile.is_active && application.length == 0"
        :image="'empty-resources.png'"
        :title="'No Application Created'"
        :subtitle="'You currently do not have any active application, Please create one start testing services'"
      >
        <router-link class="btn btn-md btn-primary" to="/applications">
          <i class="la la-plus"></i> Create Application
        </router-link>
      </DefaultContent>
    </div>
  </div>
</template>

<script>
import BVNService from "@/components/services/BVNService";
import BVNWithImageService from "@/components/services/BVNWithImageService";
import DLService from "@/components/services/DLService";
import NINService from "@/components/services/NINService";
import PVCService from "@/components/services/PVCService";
import AddressService from "@/components/services/AddressService";
import NIPService from "@/components/services/NIPService";
import BulkBVNService from "@/components/services/BVNBulkUploadService";
import BulkPNINBasicService from "@/components/services/BulkPNINBasicService.vue";
import BulkNINUploadService from "@/components/services/BulkNINUploadService";
import NINByPhoneService from "@/components/services/pNINService";
import NINByPhoneServiceBasic from "@/components/services/pNINServiceBasic";
import GhanaVotersIdService from "@/components/services/GhanaVotersId";
import GhanaInternationalPassportService from "@/components/services/GhanaInternationalPassport";
import GhanaSSNITService from "@/components/services/GhanaSsnItService";
import GhanaDriversLicenceService from "@/components/services/GhanaDriversLicenceService";
import KenyaInternationalPassport from "@/components/services/KenyaInternationalPassport.vue";
import KenyaNationalIDService from "@/components/services/KenyaNationalIDService.vue";
import vNINService from "@/components/services/vNINService.vue";
import DevOnly from "@/utils/AccessControl/DevOnly";

export default {
  components: {
    BulkPNINBasicService,
    vNINService,
    NINByPhoneServiceBasic,
    BVNService,
    BVNWithImageService,
    BulkBVNService,
    BulkNINUploadService,
    DLService,
    NINService,
    NINByPhoneService,
    AddressService,
    NIPService,
    PVCService,
    GhanaVotersIdService,
    GhanaInternationalPassportService,
    GhanaSSNITService,
    GhanaDriversLicenceService,
    DevOnly,
    KenyaInternationalPassport,
    KenyaNationalIDService,
  },
  computed: {
    profile() {
      return this.$store.state.account.profile.api_customer;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  data() {
    return {
      tab: 1,
      apps: {},
      application: [],
      isProcessing: true,
      isShown: false,
      isShown1: false,
    };
  },
  methods: {
    tabswitch(tab) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (tab) {
        this.tab = tab;
        this.$router.replace({ query: { tab: this.tab } }).catch(() => {});
      } else {
        this.tab = 1;
      }
    },
    toggle() {
      // Toggles control property
      this.isShown = !this.isShown;
    },
    toggle1() {
      // Toggles control property
      this.isShown1 = !this.isShown1;
    },

    async fetchApps() {
      try {
        const app = await this.$store.dispatch("application/getApplications");
        this.apps = app;
        let applications = app.applications;
        let filteredApplication = [];
        if (applications.length > 0) {
          filteredApplication = applications.filter(
            (app) => app.is_active == 1
          );
        }
        // console.log('[filtered] ->', filteredApplication);
        this.application = filteredApplication;

        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  async mounted() {
    if (this.$route.query.tab) {
      let tab = this.$route.query.tab;
      this.tabswitch(tab);
    }
    this.fetchApps();
  },
};
</script>
<style scoped>
.accordion .card-header a {
  padding: 14px 0px !important;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
  color: #212229;
  background-color: transparent !important;
}

.accordion .card-header a.collapsed {
  background-color: transparent !important;
}

.panel-heading a:before {
  content: "\002B";
  float: right;
  transition: all 0.5s;
}

.panel-heading a.collapsed:before {
  content: "\2212" !important;
  float: right;
  transition: all 0.5s;
}
</style>
