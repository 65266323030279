<template>
	<div>
		<div v-if="verifying" class="d-flex justify-content-end text-danger">
			<span class="pr-2">Verifying Account...</span>
			<Spinner :variant="'clip'" :size="'16px'" :color="'red'" />
		</div>
		<div v-else-if="verifyError" class="text-right text-danger">
			Error Validating Data!!!
		</div>
		<div v-else-if="user" class="text-right mb-3">
			Account Name: <b>{{ user }}</b>
		</div>
	</div>
</template>

<script>
	import Spinner from "../../../../utils/Spinner/Spinner";

	export default {
		name: "CustomerValidationStatus",
		props: ["verifying", "verifyError", "user"],
		components: {
			Spinner,
		},
	};
</script>
