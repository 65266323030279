<template>
  <Modal
    :id="id"
    :title="'Update Payment Collection'"
    :toggle="handleCloseModal"
  >
    <form @submit.prevent="handleSubmit" class="row h-100">
      <div class="col-sm-6">
        <CustomInput
          :name="'title'"
          :label="'Page Title'"
          :placeholder="'Enter Page Title'"
          :required="true"
          v-model="request.title"
          :error="$v.request.title.$error && formVuelidator($v.request.title)"
        />
      </div>

      <div class="col-sm-6">
        <CustomInput
          :name="'description'"
          :label="'Page Description'"
          :placeholder="'What is the  page desctiption'"
          :required="true"
          v-model="request.description"
          :error="
            $v.request.description.$error &&
            formVuelidator($v.request.description)
          "
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'amount'"
          :label="'Amount'"
          :placeholder="'Amount'"
          :required="true"
          :isNum="true"
          v-model="request.amount"
          :error="$v.request.amount.$error && formVuelidator($v.request.amount)"
        />
      </div>
      <div class="col-sm-6">
        <label class="input-item-label">
          Expiry Date
          <span class="text-danger" title="This field is required">*</span>
        </label>
        <DatePicker
          :name="'to'"
          v-model="request.expiry_date"
          :disabledDates="{ to: new Date() }"
          :placeholder="'Expiry Date'"
          :error="
            $v.request.expiry_date.$error &&
            formVuelidator($v.request.expiry_date)
          "
        />
      </div>
      <div class="col-sm-6">
        <div class="form-group">
           <CustomUpload
            :name="'first'"
            :label="'Image'"
            :required="true"
            :validFileTypes="['.png', '.jpg', '.jpeg']"
            :handleChange="handleUpload"
            :fileName="request.images && request.images.name"
          />
          <div v-if="previewImage" class="mt-2">
            <img :src="previewImage" alt="img" width="50" height="50" />
          </div>
        </div>
      </div>

      <div class="col-12">
        <FormButtons :loading="loading" :handleCloseModal="handleCloseModal" />
      </div>
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "@/utils/Modal/Modal";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import FormButtons from "@/utils/FormButtons/FormButtons";
import SplitPaymentTable from "../CreatePaymentCollectionModal/SplitPaymentTable/SplitPaymentTable";
import { getWalletApikey, toCurrency } from "@/utils/helpers";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import { numeric, required } from "vuelidate/lib/validators";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

const initialState = () => ({
  request: {
    title: "",
    description: "",
    amount: "",
    collect_name: false,
    reminder: false,
    collect_shipping_dest: false,
    expiry_date: "",
    images: "",
  },
  uploadError: false,
  formErrors: {},
  successMsg: false,
  apiData: {},
});

export default {
  mixins: [notifications],
  name: "EditPaymentCollectionModal",
  data() {
    return {
      ...initialState(),
      loading: false,
      previewImage: "",
      pageData: "",
    };
  },
  validations: {
    request: {
      amount: {
        required,
        numeric,
      },
      title: {
        required,
      },
      description: {
        required,
      },
      expiry_date: {
        required,
      },
    },
  },
  props: ["id", "closeModal", "collectionRef"],
  components: {
    pageData: null,
    Modal,
    CurrencySelect,
    CustomInput,
    CustomSelect,
    FormButtons,
    SuccessState,
    SplitPaymentTable,
    DatePicker,
    CustomUpload
  },
  methods: {
    toCurrency,
    formVuelidator,
    getLabel: (val) => {
      return `${val.name} ( ${val.account_number} )`;
    },
    async handleUpload({ target: { files } }) {
      this.mediaData = null;

      const file = files[0];
      if (file) {
        this.previewImage = URL.createObjectURL(file);
        this.request.images = file;
      }
    },
    handleCloseModal() {
      Object.assign(this.$data, initialState());
      this.closeModal();
    },
    handleRefetch() {
      this.handleCloseModal();
    },
    handleSubmit() {
      this.$v.$touch();
      const { title, description, amount, expiry_date } = this.request;
      const payload = { title, description, amount, expiry_date };
      const { formErrors, isValid } = formValidator(payload);
      if (isValid && !this.$v.$invalid) {
        this.createCollection();
      } else this.formErrors = formErrors;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    async createCollection() {
      try {
        this.loading = true;
        const payload = {
          ...this.request,
          amount: parseFloat(this.request.amount),
        };
        payload.expiry_date =
          typeof payload.expiry_date === "object"
            ? payload.expiry_date.toISOString()
            : payload.expiry_date;
        let formData = new FormData();
        formData.append("title", payload.title);
        formData.append("description", payload.description);
        formData.append("amount", payload.amount);
        formData.append("collect_name", payload.collect_name);
        formData.append("reminder", payload.reminder);
        formData.append("collect_shipping_dest", payload.collect_shipping_dest);
        formData.append("expiry_date", payload.expiry_date);
        payload.images && formData.append("images", payload.images);
        const response = await this.$store.dispatch(
          "payment/updatePaymentPage",
          {
            apiKey: getWalletApikey(this.$store),
            request: formData,
            reference: this.$route.params.reference,
          }
        );

        if (response.status === 200 || response.status === 201) {
          this.loading = false;
          this.showSuccessNotification(response.data.message);
          this.handleCloseModal();
          this.$router.push(`/wallet/payment`);
        }
      } catch (error) {
        this.loading = false;
        this.showErrorLoginNotification(error.message);
      }
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    async getInformation() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        reference: this.$route.params.reference,
      };
      try {
        const resp = await this.$store.dispatch(
          "payment/getOnePaymentPage",
          payload
        );
        this.pageData = resp.data.data;
        this.previewImage = this.pageData.image_urls === null ? this.pageData.image_urls : this.pageData.image_urls[0] ;

        this.request = {
          title: this.pageData.title,
          description: this.pageData.description,
          amount: this.pageData.amount,
          add_social_links: this.pageData.add_social_links,
          collect_name: this.pageData.collect_name,
          collect_email: this.pageData.collect_email,
          collect_phone: this.pageData.collect_phone,
          collect_shipping_dest: this.pageData.collect_shipping_dest,
          reminder: this.pageData.reminder,
          expiry_date: this.pageData.expiry_date,
        };
      } catch (error) {
        console.log("error", error);
      }
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
  },
  computed: {
    succesRes() {
      return this.$store.state.account.customerKeys;
    },
  },
  mounted() {
    this.getInformation();
  },
};
</script>
