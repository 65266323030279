<template>
	<div class="row">
		<div class="col-12">
			<transition mode="out-in" name="fade">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									Phone Number Verification (Bulk)
								</h2>
								<h5 class="user-profile-email sub">
									Verify Phone Number of various users in
									Bulk.
								</h5>
							</div>
						</div>
						<div class="content">
							<span
								class="badge badge-warning"
								style="font-size: 14px; font-weight: 500"
								v-if="
									hasService &&
										userRole !== 'Secondary Customer'
								"
								>(Each API call costs &#8358;{{
									`${servicePrice}`
								}})</span
							>
							<span
								v-if="!hasService"
								class="badge badge-danger"
								style="font-size: 14px; margin-top: 5px; font-weight: 500"
								>You don't have access to this service</span
							>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="verifyBVNBulk">
							<div class="row mt-4">
								<div class="col-12">
									<div class="form-group">
                    <CustomUpload
                      :name="'first'"
                      :label="'Carriers File'"
                      :required="true"
                      :validFileTypes="['.csv']"
                      :handleChange="handleUpload"
                      :fileName="mediaData && mediaData.name"
                      :error="uploadError && 'File size must be less than 5MB'"
                    />
									</div>
								</div>

								<!-- Currency Type  -->
								<div class="col-12">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label">
											Use the template below </label
										><br />
										<a
											href="../../assets/mobilecarrierfile.csv"
											download
											>Download this template</a
										>
									</div>
								</div>

								<!-- API Version details -->
								<!-- <div class="col-md-12 mt-4">
                  <label class="input-item-label">Select an API Version</label>
                  <v-select
                    class="v-select"
                    placeholder="Select an API version"
                    v-model="apiVersion"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.apiVersion.$error">
                    <li class="parsley-required">API version field is required.</li>
                  </ul>
                </div>-->

								<!-- Call ID -->
								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
									>
										<span v-if="isSubmitting"
											>...Validating request</span
										>
										<span v-else>Verify Phone Numbers</span>
									</button>
									<!-- <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Validating request</span>
                    <span v-else>Verify Mobile Carriers</span>
                  </button>                   -->
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Response Page -->
				<div class="user-settings-section" v-if="hasNewResponse">
					<div class="user-settings-section-header">
						<div class="user-settings-section-header-text">
							<h1>BVN Bulk Upload Request Response</h1>
							<span class="text-span"
								>Preview of the information gotten from bulk bvn
								request made<br
							/></span>
							<span class="text-span"
								><b>Note:</b> Only 5 items are displayed, check
								your mail for the complete Response</span
							>
						</div>
						<a
							href="#"
							class="btn btn-outline-danger"
							@click="hasNewResponse = false"
						>
							<i class="la la-close"></i>Close Panel
						</a>
					</div>
					<!-- <div v-for="value in computedObj">{{value}}</div> -->
					<div
						class="preview-content"
						v-for="item in verificationResult.results.slice(0, 5)"
						:key="item"
					>
						<!-- <div class="invoice-info-row preview-row">
              <div class="bvn-image">
                <img
                  width="200px"
                  height="200px"
                  :src="item.results.personal_info.image_url"
                  :alt="`image for ${item.results.personal_info.full_name}`"
                />
              </div>
            </div> -->
						<p class="invoice-info-row preview-row">
							<span>Fullname</span>
							<span>{{ item.personal_info.full_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Firstname</span>
							<span>{{ item.personal_info.first_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Lastname</span>
							<span>{{ item.personal_info.last_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Email:</span>
							<span>{{
								item.personal_info.email ||
									"Information not available"
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>BVN</span>
							<span>{{ item.bvn_number }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Reg Date</span>
							<span>{{ item.formatted_registration_date }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Date of Birth</span>
							<span>{{
								item.personal_info.formatted_date_of_birth
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>LGA</span>
							<span>{{ item.personal_info.lga_of_origin }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>State of origin</span>
							<span>{{
								item.personal_info.state_of_origin
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Nationality</span>
							<span>{{ item.personal_info.nationality }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Marital Status</span>
							<span>{{ item.personal_info.marital_status }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Phone number</span>
							<span>{{ item.personal_info.phone_number }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Request Reference</span>
							<span>{{ item.request_reference }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Verification Status</span>
							<span>{{ item.verification_status }}</span>
						</p>

						<div class="gaps-1-5x"></div>
					</div>
				</div>

				<!-- End of transition -->
			</transition>

			<hr />
			<div class="row">
				<div class="col-12">
					<div class="user-details-info">
						<div class="user-details--top-info">
							<h2 class="user-profile-name">
								API Request Format
							</h2>
							<h5 class="user-profile-email sub">
								Representation of how the API call can be made
								with the appropriate data neccesary
							</h5>
						</div>
					</div>
				</div>

				<div class="col-12">
					<pre
						class="code code-html"
					><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}CustomerInformation/verifyMobileCarrierBulk`
      </code></pre>
				</div>
				<div class="col-12">
					<pre
						class="code code-html"
					><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
				</div>
				<div class="col-12">
					<!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
					<!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
					<!-- "dob": "{{dob}}" -->
					<pre
						class="code code-html"
					><label style="top:42%;">Body</label><code>
          {
            "msc_bulk": "{{"msc_bulk"}}"
          }
      </code></pre>
				</div>
				<div class="col-12">
					<pre
						class="code code-html"
					><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :({
                  "status_code": 200,
                  "message": "Verification Completed Successfully",
                  "results": {
                      "status_code": 200,
                      "status": "",
                      "message": "Your request has been queued."
                    }
                }) }}
      </code></pre>
				</div>
			</div>
		</div>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		></ApplicationInitializationModal>

		<!-- Modal -->
		<div
			class="modal fade"
			id="sucessModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="sucessModaltitle"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="sucessModalLongTitle">
							<i class="la la-exclamation-circle"></i> Success
						</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>
							Your Bulk Phone Number Verification request has been
							recieved and is being processed. A comprehensive
							report will sent to your mail shortly.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Nprogress from "nprogress";
	import {
		required,
		minLength,
		alphaNum,
		sameAs,
	} from "vuelidate/lib/validators";
	import AuthWrapper from "@/components/auth/AuthWrapper";
	import { notifications } from "../../../public/assets/mixins/notifications";
	import { modalMixin } from "../../../public/assets/mixins/modalMixin";
	import csvFile from "../../../public/assets/mobilecarrierfile.csv";
  import CustomUpload from "@/utils/CustomUpload/CustomUpload";

	export default {
		props: {
			application: {
				type: Array,
			},
			services: {
				type: Array,
			},
		},
		mixins: [notifications, modalMixin],
		validations: {
			app: { required },
			// apiVersion: { required },
		},
		data() {
			return {
				initializedAppResult: {},
				app: "",
				versions: ["v1", "v2"],
				verificationTypes: ["BVN with Image", "BVN without Image"],
				verificationType: "BVN without Image",
				apiVersion: "",
				call_id: "",
				clientID: "",
				apikey: "",
				appname: "",
				phonenumber: "",
				image_1: "",
				firstname: "",
				lastname: "",
				uploadError: "",
				hasNewResponse: false,
				dob: "",
				logData: [],
				apiResponse: "",
				customerWallets: this.$store.state.account.customerWallets,
				currencyType: "",
				// services
				hasService: false,
				servicePrice: "",

				isSubmitting: false,
				verificationResult: [],
			};
		},
    components:{ CustomUpload },
		watch: {
			hasNewResponse(newValue, oldValue) {
				console.log("New-Value", newValue);
				console.log("Old-Value", oldValue);
				if (oldValue == true && newValue == false) {
					this.apiResponse = "";
				}
			},
		},
		computed: {
			API() {
				return this.$store.state.appUrlV2;
			},
		},
		methods: {
			computeService() {
				if (this.services) {
					const serv = this.services.find(
						({ service_label }) =>
							service_label === "Phone Number Verification - Bulk"
					);
					if (serv) {
						console.log("the serv", serv);
						this.hasService = true;
						this.servicePrice =
							serv.pivot.price_per_call || serv.price_per_call;
					}
				}
			},
      async handleUpload({ target: { files } }) {
        this.mediaData = null;

        const file = files[0];
        if (file) {
          const dataSize = 1024 * 1024;
          if (file.size / dataSize < 5) {
            this.mediaData = file;
            this.uploadError = false;
          } else {
            this.uploadError = true;
          }
        }
      },
			getRequestData() {
				console.log("In Get-Request-Block");
				if (!this.hasBundleBilling) {
					this.call_id = "";
				}

				let mobileCarrier = new FormData();
				mobileCarrier.append("msc_bulk", this.mediaData);

				let verificationData = {
					clientID: this.clientID,
					appname: this.app.app_name,
					apikey: this.app.api_key,
					query: this.call_id,
					payload: {
						currency_type: this.currencyType.currency,
					},
				};
				console.log("currencyType", this.currencyType);
				console.log("VerificationData", verificationData);

				return { verificationData, payload: mobileCarrier };
			},
			async verifyBVNBulk() {
				console.log("In Verify-Bulk-BVN...");
				this.$v.$touch();

				if (this.$v.$invalid) {
					console.log("[invalid fields] ->");
					return;
				}

				if (this.uploadError) return;

				let verificationData = this.getRequestData();
				this.isSubmitting = true;
				console.log("[Ver-Data] ->", verificationData);

				try {
					let apiResponse = await this.$store.dispatch(
						"services/verifyMobileCarrierBulk",
						verificationData
					);
					console.log(
						"[api Response from Bulk-Bvn ] ->",
						apiResponse.results
					);
					this.apiResponse = apiResponse;

					$("#sucessModal").modal();
					this.isSubmitting = false;
					this.showSuccessNotification(apiResponse.message);
					Nprogress.done();
				} catch (error) {
					Nprogress.done();
					this.isSubmitting = false;
					console.log("error :", error);
					this.showErrorLoginNotification(error.message);
					MobileCarrierBulkVerification;
				}

				// if (this.verificationType === "BVN without Image") {

				// } else {
				//   try {
				//     let apiResponse = await this.$store.dispatch(
				//       "services/verifyBVNBulkWithImage",
				//       verificationData
				//     );
				//     console.log("[api Response from Bulk-Bvn ] ->", apiResponse.results);
				//     this.apiResponse = apiResponse;

				//     $("#sucessModal").modal();
				//     this.isSubmitting = false;
				//     this.showSuccessNotification(apiResponse.message);
				//     Nprogress.done();
				//   } catch (error) {
				//     Nprogress.done();
				//     this.isSubmitting = false;
				//     console.log("error :", error);
				//     this.showErrorLoginNotification(error.message);
				//   }
				// }
			},
		},
		mounted() {
			this.computeService();
			this.clientID = this.$store.state.account.clientID;
			console.log("Stored-ClinetID", this.clientID);
		},
	};
</script>
