<template>
	<div class="form-group" :class="otherCls">
		<div class="flex__between">
			<div>
				<label v-show="label" class="input-item-label">{{
					label
				}}</label>
				<span
					v-if="required"
					class="text-danger pl-1"
					title="This field is required"
					>*</span
				>
			</div>
			<template>
				<slot />
			</template>
		</div>
		<div
			class="flex__start input-wrapper"
			:class="`${disabled ? 'disabled' : ''}  ${error ? 'error' : ''}`"
		>
			<slot name="input_left" />
			<input
				:id="id"
				:type="type"
				:min="min"
				:max="max"
				:value="value"
				v-on:input="$emit('input', $event.target.value)"
				:maxlength="maxlength"
				:minlength="minlength"
				class="form-control input-bordered w-100"
				:placeholder="placeholder"
				step="any"
				:disabled="disabled"
				@keypress="isNumber"
				@paste="handlePaste"
				@blur="$emit('blur', $event)"
				:readonly="readonly"
				@wheel.prevent="$event.currentTarget.blur()"
			/>
			<div v-show="icon" class="img__wrap">
				<img
					:src="icon"
					alt=""
					class="pointer"
					@click="iconClick ? iconClick() : ''"
				/>
			</div>
		</div>
		<div v-if="typeof error === 'string'">
			<span v-show="error" class="text-danger">{{ error }}</span>
		</div>
	</div>
</template>

<script>
import { blockInvalidChar } from "@/utils/helpers";

export default {
	name: "CustomInput",
	props: [
		"id",
		"value",
		"name",
		"type",
		"min",
		"max",
		"placeholder",
		"required",
		"label",
		"error",
		"minlength",
		"maxlength",
		"isNum",
		"isCSV",
		"isRef",
		"disabled",
		"handleError",
		"icon",
		"iconClick",
		"avoidPaste",
		"btn",
		"otherCls",
		"readonly",
	],
	methods: {
		isNumber(val) {
			if (this.isNum) {
				if (isNaN(Number(val.key))) return val.preventDefault();
				return blockInvalidChar(val);
			}
			if (this.isCSV) {
				if (!val.key.match(/^([\d,])$/)) return val.preventDefault();
			}
			if (this.isRef) {
				if (!val.key.match(/^[a-zA-Z0-9-_]+$/))
					return val.preventDefault();
			}
		},
		handlePaste(e) {
			if (this.avoidPaste) {
				e.preventDefault();
				return false;
			}
			if (this.isNum) {
				const value = e.clipboardData.getData("Text");
				if (/[e\D\+\-\.]/.test(value)) {
					e.preventDefault();
					return false;
				}
				return true;
			}
		},
	},
	watch: {
		value() {
			this.handleError && this.handleError(this.name);
		},
	},
};
</script>
