<template>
	<div class="card">
		<div class="row">
			<div v-show="title" class="col-12 pt-3 px-4">
				<h5 class="font-weight-bold">{{ title }} ({{ len }})</h5>
			</div>
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<PerPageSelect
								:handleChange="refetch"
								:limit="limit"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					v-if="params.type != 'invoices'"
					ref="transRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@loading="
						() => {
							setLoading(true);
							$emit('loading', { status: true });
						}
					"
					@loaded="
						($event) => {
							setLoading(false);
							$emit('loading', { status: false, value: $event });
						}
					"
					@error="
						($event) => {
							setError($event);
							$emit('error', false);
						}
					"
				>
					<template
						slot="metadata.account_number"
						slot-scope="{ row }"
					>
						{{
							row.metadata && row.metadata.account_number
								? row.metadata.account_number
								: "-------------------"
						}}
					</template>
					<template slot="amount" slot-scope="{ row }">
						{{ row.currency }}{{ toCurrency(row.amount, " ") }}
					</template>
					<template slot="source" slot-scope="{ row }">
						{{
							titleCase(
								row.source === "merchant_transfer"
									? "Bank_transfer"
									: row.source
							)
						}}
					</template>
					<template
						slot="metadata.source_account.account_name"
						slot-scope="{ row }"
					>
						{{
							row.metadata && row.metadata.source_account
								? row.metadata.source_account.account_name
								: "---------"
						}}
					</template>
					<template slot="action" slot-scope="{ row }">
						<span :class="statusColor(row.action)">
							{{ titleCase(row.action) }}
						</span>
					</template>
					<template slot="status" slot-scope="{ row }">
						<span :class="statusColor(row.status)">
							{{ titleCase(row.status) }}
						</span>
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						<span>
							{{
								timeDateFormat(row.createdAt).date +
								" " +
								timeDateFormat(row.createdAt).time
							}}
						</span>
					</template>
				</v-server-table>
				<v-server-table
					v-else
					ref="transRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@loading="
						() => {
							setLoading(true);
							$emit('loading', { status: true });
						}
					"
					@loaded="
						($event) => {
							setLoading(false);
							$emit('loading', { status: false, value: $event });
						}
					"
					@error="
						($event) => {
							setError($event);
							$emit('error', false);
						}
					"
				>
					<template slot="amount" slot-scope="{ row }">
						{{ row.currency }}{{ toCurrency(row.amount, " ") }}
					</template>
					<template slot="status" slot-scope="{ row }">
						<span
							:class="
								statusColor(
									row.status == 'paid'
										? 'paid'
										: 'outstanding'
								)
							"
						>
							{{
								titleCase(
									row.status == "paid"
										? "paid"
										: "outstanding"
								)
							}}
						</span>
					</template>
					<template slot="amount_paid" slot-scope="{ row }">
						<span>
							{{
								toCurrency(
									row.partial.is_partial
										? row.partial.installments.reduce(
												(a, b) => a + b,
												0
										  )
										: row.status == "paid"
										? row.amount
										: 0,
									row.currency
								)
							}}
						</span>
					</template>
					<template slot="outstanding" slot-scope="{ row }">
						<span>
							{{
								toCurrency(
									row.partial.is_partial
										? row.amount -
												row.partial.installments.reduce(
													(a, b) => a + b,
													0
												)
										: row.status != "paid"
										? row.amount
										: 0,
									row.currency
								)
							}}
						</span>
					</template>
					<template slot="issued_date" slot-scope="{ row }">
						<span>
							{{
								row.issued_date
									? timeDateFormat(row.issued_date).date +
									  " " +
									  timeDateFormat(row.issued_date).time
									: "Not Available"
							}}
						</span>
					</template>
					<template slot="due_date" slot-scope="{ row }">
						<span>
							{{
								row.due_date
									? timeDateFormat(row.due_date).date +
									  " " +
									  timeDateFormat(row.due_date).time
									: "Not Available"
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import PerPageSelect from "@/utils/Selects/PerPageSelect/PerPageSelect";
import TransactionsTableFilters from "../../../../common/TransactionsTable/TransactionsTableFilters/TransactionsTableFilters.vue";
import TransactionsTableModal from "./../../../../common/TransactionsTable/TransactionsTableModal.vue";
import ViewMore from "@/utils/ViewMore/ViewMore";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
	timeDateFormat,
	titleCase,
	tableOptions,
	getWalletApikey,
	statusColor,
	downloadReport,
	toCurrency,
} from "@/utils/helpers";

const tableHeadings = {
	reference: "Transaction Reference",
	amount: "Amount",
	source: "Payment Method",
	action: "Type",
	status: "Status",
	createdAt: "Date",
};

const walletHeadings = {
	reference: "Transaction ID",
	amount: "Amount",
	action: "Type",
	source: "Payment Method",
	status: "Status",
	createdAt: "Transaction Date",
};

const invoiceHeadings = {
	invoice_reference: "Receipt Number",
	status: "Status",
	issued_date: "Invoice Date",
	amount: "Invoice Amt",
	amount_paid: "Amount Paid",
	outstanding: "Outstanding",
	due_date: "Due Date",
};

export default {
	mixins: [notifications],
	name: "AccountStatmentTable",
	data() {
		return {
			loading: false,
			rLoading: false,
			modalData: null,
			errorMsg: "",
			query: {},
			dateParams: {},
			limit: 25,
			ref: "",
			len: 0,
			...tableOptions(
				{ ...tableHeadings, ...this.headings },
				this.perPage
			),
		};
	},
	props: {
		title: String,
		source: String,
		params: {
			type: Object,
			default: function () {
				return {};
			},
		},
		getTotals: Function,
		headings: {
			type: Object,
			default: function () {
				return {};
			},
		},
		perPage: {
			type: Number,
			default: 25,
		},
	},
	components: {
		ViewMore,
		Spinner,
		FailedState,
		FilterWrapper,
		PerPageSelect,
		TransactionsTableFilters,
		TransactionsTableModal,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		titleCase,
		timeDateFormat,
		statusColor,
		toCurrency,
		handlePage(data) {
			console.log("the page", data);
		},
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch({ query = {}, limit }) {
			const newQuery = Object.fromEntries(
				Object.entries(query).filter(([_, v]) => v != "")
			);
			if (query) this.query = newQuery;
			if (limit) {
				this.$refs.transRef.setLimit(limit);
				this.limit = limit;
			}
			if (query.reference) this.ref = query.reference;
			this.getTransactions();
			this.$refs.transRef.refresh();
		},
		clearFilter() {
			this.query = {};
			this.dateParams = {};
			this.ref = "";
			this.$refs.transRef.refresh();
		},
		getRequestData({}, limit = 9999999999) {
			const params = { ...this.params };

			const requestData = {
				apiKey: getWalletApikey(this.$store),
				params: {
					...params,
					limit,
					reference: this.ref,
				},
			};

			return requestData;
		},
		async getTransactions() {
			this.options.requestFunction = async (data) => {
				const requestData = this.getRequestData(data, this.limit);

				const payload =
					this.params.type == "invoices"
						? {
								apiKey: requestData.apiKey,
								params: {
									from: requestData.params.from,
									to: requestData.params.to,
									customer: requestData.params.customer,
								},
						  }
						: requestData;

				return await this.$store
					.dispatch(
						this.params.type == "invoices"
							? "invoices/getCustomerInvoiceStatement"
							: "transactions/getAllTransactions",
						payload
					)
					.then((response) => {
						const allData = response.data.data;

						if (this.params.type == "invoices") {
							allData.data = response.data.data.invoices;
						}

						this.len = allData.total;

						if (this.getTotals) {
							const total = allData.data.reduce(
								(acc, arr) => acc + arr.amount,
								0
							);

							const totals = {
								total,
								count: 0,
							};

							if (allData.count) {
								totals.count = allData.count;
							} else if (allData.invoiceCount) {
								totals.count = allData.invoiceCount;
							}

							this.getTotals(totals);
						}
						return {
							...allData,
							count: allData.count || allData.invoiceCount || 0,
						};
					});
			};
		},
		async downloadTransactions() {
			this.rLoading = true;
			const requestData = this.getRequestData({});

			const response = await this.$store.dispatch(
				"transactions/getAllTransactions",
				requestData
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				downloadReport(tableHeadings, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},
	created() {
		this.getTransactions();
	},
	mounted() {
		let headings = {};

		if (!this.params.type) headings = tableHeadings;
		else if (this.params.type == "wallet") headings = walletHeadings;
		else if (this.params.type == "invoices") headings = invoiceHeadings;

		this.columns = Object.keys({
			...headings,
			...this.headings,
		}).map((keyVal) => keyVal);
	},
};
</script>
