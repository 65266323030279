<template>
  <div class="col-12">
    <div class="row">
      <div class="col-sm-12">
        <CustomSelect
          name="meter_type"
          label="Meter Type"
          placeholder="Select Meter Type"
          :options="options"
          v-model="request.meter_type"
          @close="$v.request.meter_type.$touch()"
          :error="formErrors.meter_type"
          :handleError="handleError"
          :isNum="true"
          :maxlength="11"
        />
      </div>

      <div class="col-sm-12">
        <CustomInput
          type="number"
          :name="'customer_id'"
          :placeholder="placeHolder"
          v-model="request.customer_id"
          @blur="$v.request.customer_id.$touch()"
          :error="formErrors.customer_id"
          :handleError="handleError"
          :label="customLabel"
          required="true"
          :isNum="true"
        />
        <CustomerValidationStatus
          :verifying="verifying"
          :verifyError="verifyError"
          :user="request.name"
        />
      </div>

      <div class="col-sm-6">
        <CustomInput
          type="number"
          :name="'amount'"
          :placeholder="'Amount'"
          v-model="request.amount"
          @blur="$v.request.amount.$touch()"
          :error="
            formErrors.amount ||
            ($v.request.amount.$error && formVuelidator($v.request.amount))
          "
          :handleError="handleError"
          :label="'Amount'"
          :isNum="true"
          required="true"
        />
      </div>

      <div class="col-sm-6">
        <CustomPhoneInput
          :onlyCountries="['NG']"
          :name="'phone_number'"
          v-model="request.phone_number"
          @blur="$v.request.phone_number.$touch()"
          :label="'Phone Number'"
          placeholder="Enter phone number"
          :error="
            formErrors.phone_number ||
            ($v.request.phone_number.$error &&
              formVuelidator($v.request.phone_number))
          "
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 mt-3">
        <PayBillsButton
          :disabled="loading || !request.name"
          :bill="getRequestData()"
          :handleSubmit="handleSubmit"
          :reset="reset"
          :setApiResponse="setApiResponse"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="user-details-info">
          <div class="user-details--top-info">
            <h2 class="user-profile-name">API Request Format</h2>
            <h5 class="user-profile-email sub">
              Representation of how the API call can be made with the
              appropriate data neccesary
            </h5>
          </div>
        </div>
      </div>

      <div class="col-12">
        <pre
          class="code code-html"
        ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{ API }}Billers/pay`
      </code></pre>
      </div>
      <div class="col-12">
        <pre
          class="code code-html"
        ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{ clientID }}',
        'appname': '{{ app ? app.app_name : 'app_name' }}',
        'apikey': '{{ app ? app.api_key : 'app_key' }}',
      }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label style="top:42%;">Body</label><code>
          {
            amount: '{{ request.amount ? request.amount : 'amount' }}',
            customer_id: '{{ request.customer_id ? request.customer_id : 'customer_id' }}',
            customer_name: '{{ accountInfo.name ? accountInfo.name : 'customer_name' }}',
            customer_address: '{{ accountInfo.address ? accountInfo.address : 'customer_address' }}',
            service_code: '{{ billService.slug ? billService.slug : 'service_code' }}',
            service_type: "electricity",
            api_service_id: '{{ billService.id ? billService.id : 'api_service_id' }}',
            phone_number: '{{ request.phone_number.formatted ? request.phone_number.formatted.replace(/\s/g, "") : 'phone_number'  }}',
            meter_type: '{{ request.meter_type ? request.meter_type : 'meter_type' }}'
          }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>Sample Response</label><code>
            {{ apiResponse ? apiResponse : `
                    {
                      "status_code": 200,
                      "message": "Success",
                      "data": {
                          "name": "Akanni Kubra Aminat",
                          "address": "43 Maryjane Street 59 835 RasheedahVille",
                          "accountId": "999999999",
                          "amount": 1000,
                          "units": 5.04,
                          "token": "23013623557111409267",
                          "status": "Successful",
                          "tranxId": "4c04eb40-97cf-4883-9e28-afb0e69f7efd"
                      }
                  }`
            }}
      </code></pre>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import CustomerValidationStatus from "../CustomerValidationStatus/CustomerValidationStatus";
import PayBillsButton from "../buttons/PayBillsButton/PayBillsButton";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { numeric, required } from "vuelidate/lib/validators";
import { P } from "@bachdgvn/vue-otp-input";

const initialState = () => ({
  request: {
    customer_id: "",
    phone_number: "",
    amount: "",
    name: "",
    meter_type: "",
  },
  accountInfo: {},
  formErrors: {},
  loading: false,
  verifying: false,
  apiResponse: "",
  verifyError: "",
});

export default {
  name: "ElectricityBiller",
  data() {
    return { ...initialState() };
  },
  props: ["app", "billService", "reset"],
  components: {
    CustomSelect,
    CustomInput,
    CustomerValidationStatus,
    PayBillsButton,
    CustomPhoneInput,
  },
  validations: {
    request: {
      phone_number: {
        required,
        isPhoneValid: isPhoneValid("request.phone_number"),
      },
      amount: {
        required,
        numeric,
      },
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
    clientID() {
      return this.$store.state.account.clientID;
    },
    placeHolder() {
      return this.request.meter_type == ""
        ? "Enter Customer/Meter Number"
        : this.request.meter_type == "prepaid"
        ? "Enter Meter Number"
        : "Enter Customer Number";
    },
    customLabel() {
      return this.request.meter_type == ""
        ? "Customer/Meter Number"
        : this.request.meter_type == "prepaid"
        ? "Meter Number"
        : "Customer Number";
    },
    options() {
      return this.billService.metadata.prepaid == true &&
        this.billService.metadata.postpaid == true
        ? ["prepaid", "postpaid"]
        : this.billService.metadata.prepaid == true
        ? ["prepaid"]
        : ["postpaid"];
    },
  },
  methods: {
    formVuelidator,
    setApiResponse(response) {
      this.apiResponse = response;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleError2(name, error) {
      this.formErrors = { ...this.formErrors, [name]: error };
    },
    handleSubmit() {
      this.$v.$touch();
      const payload = { ...this.request };
      !payload.phone_number && delete payload.phone_number;
      const { formErrors, isValid } = formValidator(payload);
      if (isValid && !this.$v.$invalid) {
        if (parseFloat(payload.amount) < 1000) {
          this.handleError2("amount", "Minimum of N1000.00");
          return false;
        }
        return true;
      } else this.formErrors = formErrors;
      return false;
    },
    getRequestData() {
      const { phone_number, customer_id, amount, meter_type } = this.request;

      return {
        headers: {
          clientid: this.$store.state.account.clientID,
          appname: this.app.app_name,
          apikey: this.app.api_key,
        },
        payload: {
          amount,
          customer_id,
          customer_name: this.accountInfo.name,
          customer_address: this.accountInfo.address,
          service_code: this.billService.slug,
          service_type: "electricity",
          api_service_id: this.billService.id,
          phone_number:
            phone_number.formatted && phone_number.formatted.replace(/\s/g, ""),
          meter_type,
        },
      };
    },
    handleValidationError() {
      this.verifying = false;
      this.request.name = "";
      this.verifyError = true;
    },
    async validateBiller() {
      try {
        this.verifying = true;
        this.verifyError = false;

        const pay = {
          customer_id: this.request.customer_id,
          service_code: this.billService.slug,
          service_type: "electricity",
          meter_type: this.request.meter_type,
          api_service_id: this.billService.id,
        };

        const response = await this.$store.dispatch(
          "services/validateElectricity",
          {
            payload: pay,
            headers: {
              clientid: this.$store.state.account.clientID,
              appname: this.app.app_name,
              apikey: this.app.api_key,
            },
          }
        );

        const { status, data } = response || {};

        if (status === 200 && data.status_code === 200) {
          this.request.name = data.data.name;
          this.accountInfo = data.data;
          this.verifying = false;
        } else this.handleValidationError();
      } catch (error) {
        this.handleValidationError();
      }
    },
  },
  watch: {
    "request.customer_id"(value) {
      if (value.length >= 8) this.validateBiller();
    },
    billService(newValue, oldValue) {
      if (oldValue !== newValue)
        Object.assign(this.$data, { ...initialState() });
    },
  },
};
</script>
