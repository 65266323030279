<template>
	<div>
		<img
			@click="openDeletePaymentLinkModal"
			class="pointer"
			src="/assets/img/icons/trash.svg"
			alt="Delete Payment Link"
		/>

		<DeletePaymentLinkModal
			:id="'deletePaymentLink'"
			:reference="reference"
			:closeModal="closeModal"
			:paymentLinkRef="paymentLinkRef"
		/>
	</div>
</template>

<script>
	import DeletePaymentLinkModal from "../../modals/DeletePaymentLinkModal/DeletePaymentLinkModal";

	export default {
		name: "DeletePaymentLinkButton",
		props: ["reference", "paymentLinkRef"],
		methods: {
			openDeletePaymentLinkModal() {
				$("#deletePaymentLink").modal("show");
			},
			closeModal() {
				$("#deletePaymentLink").modal("hide");
			},
		},
		components: {
			DeletePaymentLinkModal,
		},
	};
</script>
