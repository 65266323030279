<template>
    <Modal :id="id" :title="'Preparing Invoice'" :toggle="closeModal">
        <form @submit.prevent="handleSubmit">
            <h4 class="text-center my-4">
                Are you sure you want to continue without Billing Address?
            </h4>

            <FormButtons :btnName="'Continue'" :handleCloseModal="closeModal" />
        </form>
    </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../public/assets/mixins/filters";

export default {
    mixins: [filters, notifications],
    name: "AddressWarningModal",
    props: ["id", "closeModal", "continue"],
    methods: {
        async handleSubmit() {
            this.closeModal();
            this.continue();
        },
    },
};
</script>
