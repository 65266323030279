<template>
	<div>
		<img
			v-if="useIcon"
			@click="openDeletePlanModal"
			class="pointer"
			src="/assets/img/icons/trash.svg"
			alt="Delete Plan"
		/>

		<button
			v-else
			type="button"
			class="btn btn-sm btn-danger px-4 ml-3"
			@click="openDeletePlanModal"
		>
			Delete Plan
		</button>

		<DeletePlanModal
			:id="'deletePlan'"
			:closeModal="closeModal"
			:planRef="planRef"
			:reference="reference"
		/>
	</div>
</template>

<script>
	import DeletePlanModal from "../../modals/DeletePlanModal/DeletePlanModal";

	export default {
		name: "DeletePlanButton",
		props: ["planRef", "reference", "useIcon"],
		methods: {
			openDeletePlanModal() {
				$("#deletePlan").modal("show");
			},
			closeModal() {
				$("#deletePlan").modal("hide");
			},
		},
		components: {
			DeletePlanModal,
		},
	};
</script>
