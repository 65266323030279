<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <!-- Mobile Carrier Service -->
                <h2 class="user-profile-name">Know Your Customer (Basic)</h2>
                <h5 class="user-profile-email sub">
                  Onboard your users using phone number
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyMobileCarrier">
              <div class="row mt-4">
                <!-- Country Code -->
                <div class="col-12">
                  <CustomPhoneInput
                    :required="true"
                    label="Phone Number"
                    v-model="phonenumber"
                    @blur="$v.phonenumber.$touch()"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phonenumber.$error && formVuelidator($v.phonenumber)
                    "
                  />
                </div>

                <!-- Application details -->
                <div class="col-md-12">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- API Version details -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label">
                    Select an API Version
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an API version"
                    v-model="apiVersion"
                    @close="$v.apiVersion.$touch()"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  >
                  </v-select>
                  <ul
                    class="parsley-errors-list filled"
                    v-show="$v.apiVersion.$error"
                  >
                    <li class="parsley-required">
                      API version field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Verifying Phone Number</span>
                    <span v-else>Verify Phone Number</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Phone Number Verification Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from Phone Number
                Verification request made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content" v-if="apiVersion == 'v1'">
            <p class="invoice-info-row preview-row">
              <span>Valid</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.valid
                  : "Not Available"
              }}</span>
            </p>
            <!-- <p class="invoice-info-row preview-row">
              <span>Photo</span>
              <img width="80" height="80" :src="verificationResult.data.image" />
            </p> -->
            <p class="invoice-info-row preview-row">
              <span>Fullname</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.full_name
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Birth</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.date_of_birth
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Number</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.number
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Local Format</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.local_format
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>International Format:</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.international_format
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Country Prefix</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.country_prefix
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Country Code</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.country_code
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Country Name</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.country_name
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Carrier</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.carrier
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Line Type</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.line_type
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Mno</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.mno
                  : "Not Available"
              }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
          <div class="preview-content" v-else>
            <p class="invoice-info-row preview-row">
              <span>Valid</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.valid
                  : "Not Available"
              }}</span>
            </p>
            <!-- <p class="invoice-info-row preview-row">
              <span>Photo</span>
              <img width="80" height="80" :src="verificationResult.data.image" />
            </p> -->
            <p class="invoice-info-row preview-row">
              <span>Fullname</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.full_name
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Birth</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.date_of_birth
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Number</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.number
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Local Format</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.local_format
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>International Format:</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.international_format
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Country Prefix</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.country_prefix
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Country Code</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.country_code
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Country Name</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.country_name
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Carrier</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.carrier
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Line Type</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.line_type
                  : "Not Available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Mno</span>
              <span>{{
                verificationResult.data
                  ? verificationResult.data.mno
                  : "Not Available"
              }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{ API }}Onboarding/Kyc/VerifyMobileNumber`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{ clientID }}',
        'appname': '{{ app ? app.app_name : 'app_name' }}',
        'apikey': '{{ app ? app.api_key : 'app_key' }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
          <!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
          <!-- "dob": "{{dob}}" -->
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {{
       `{
						"country_code": ${
              phonenumber.formatted
                ? phonenumber.countryCallingCode
                : "country_code"
            },
            "phone_number": ${
              phonenumber.formatted
                ? `${phonenumber.formatted.replace(/\s/g, "")}`
                : "phonenumber"
            }
					}`
        }}
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{ apiResponse ? apiResponse : `
                    {
                "status_code": 200,
                "error": false,
                "message": "User Info Retrieved",
                "data": {
                    "smart_id": "cdffeaca1c",
                    "valid": true,
                    "number": "2347013330339",
                    "local_format": "07013330339",
                    "international_format": "+2347013330339",
                    "country_prefix": "+234",
                    "country_code": "NG",
                    "country_name": "Nigeria (Federal Republic of)",
                    "location": null,
                    "carrier": "Airtel Nigeria Ltd",
                    "line_type": "mobile",
                    "mno": "Airtel",
                    "first_name": "MUSTAPHA",
                    "last_name": "ABDULAZIZ",
                    "middle_name": null,
                    "address_line": "NO 222 GORON DUTSE KAIGAMA STREET",
                    "state": "Kano",
                    "lga": "Dala",
                    "town": "KANO",
                    "image": "https://dev-api.blusalt.net/_/IS5oZ_rjf",
                    "nin": "19631167794",
                    "birth_state": "Kano",
                    "birth_lga": "Dala",
                    "birth_country": "nigeria",
                    "date_of_birth": "1999-04-15",
                    "id_number": "07013330339",
                    "gender": "male",
                    "religion": "islam"
                }
            }`}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    >
    </ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    phonenumber: {
      required,
      isPhoneValid: isPhoneValid("phonenumber"),
    },
    apiVersion: { required },
    app: { required },
  },
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      countrycodes: ["234"],
      app: "",
      apiVersion: "",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      phonenumber: "",
      local_format: "",
      mno: "",
      hasNewResponse: false,
      country_prefix: "",
      logData: [],
      apiResponse: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  components: { CustomPhoneInput },
  watch: {
    hasNewResponse(newValue, oldValue) {
      console.log("New-Response >>>>", newValue);
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      if (this.apiVersion == "v2" || this.apiVersion == "") {
        return this.$store.state.appUrlV2;
      } else {
        return process.env.VUE_APP_BASE_URL;
      }
    },
  },
  methods: {
    formVuelidator,
    computeService() {
      if (this.services) {
        const serv = this.services.find(
          ({ service_label }) => service_label.includes("Know Your Customer (Basic)")
        );
        if (serv) {
          this.hasService = true;
          this.servicePrice = serv.pivot.price_per_call || serv.price_per_call;
        }
      }
    },

    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          phone_number: this.phonenumber.formatted.replace(/\s/g, ""),
          country_code: this.phonenumber.countryCallingCode,
          apiVersion: this.apiVersion,
          currency_type: this.currencyType.currency,
        },
        query: this.call_id,
      };

      return verificationData;
    },
    async verifyMobileCarrier() {
      console.log("VERIFY-MOBILE-CARRIER ----->");
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        let apiResponse = await this.$store.dispatch(
          "services/verifyMobileCarrierAdvanced",
          verificationData
        );
        console.log("[api Response from MC ] ->", apiResponse);
        this.apiResponse = apiResponse;
        this.showSuccessNotification(apiResponse.message);
        console.log("Verification", apiResponse);
        this.verificationResult = apiResponse;
        this.hasNewResponse = true;
        this.isSubmitting = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
