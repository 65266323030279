import { render, staticRenderFns } from "./OtherInformation.vue?vue&type=template&id=25047660&scoped=true&"
import script from "./OtherInformation.vue?vue&type=script&lang=js&"
export * from "./OtherInformation.vue?vue&type=script&lang=js&"
import style0 from "./OtherInformation.vue?vue&type=style&index=0&id=25047660&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25047660",
  null
  
)

export default component.exports