<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="flex__between">
				<div class="SectionItem no-b-b mb-4 justify-content-start">
					<img
						src="/assets/img/icons/arrow-left.svg"
						width="30"
						alt=""
						class="pointer"
						@click="$router.push('/wallet/subscriptions')"
					/>
					<p class="title ml-3 mb-0">Subscription Details</p>
				</div>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getSubscription(id)"
			/>

			<template v-else>
				<div class="content__slider">
					<Tabs
						:tabNames="[
							'Subscription_Details',
							'Subscriber_Information',
						]"
						:tabId="'subTab'"
					/>
				</div>

				<div class="tab-content" id="subContent">
					<SubscriptionDetailsMain :subscription="subscription" />
					<SubscriberInformation
						:wallet="subscription.metadata.wallet"
					/>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import Tabs from "@/utils/Tabs/Tabs";
import EmptyWallet from "../../../common/EmptyWallet/EmptyWallet";
import SubscriptionDetailsMain from "./SubscriptionDetailsMain/SubscriptionDetailsMain";
import SubscriberInformation from "./SubscriberInformation/SubscriberInformation";
import Spinner from "@/utils/Spinner/Spinner";
import FailedState from "@/utils/ResponseState/FailedState";
import { getWalletApikey } from "@/utils/helpers";
import "./SubscriptionDetails.css";

export default {
	name: "SubscriptionDetails",
	data() {
		return {
			id: "",
			loading: false,
			errorMsg: "",
			subscription: {},
		};
	},
	components: {
		Spinner,
		FailedState,
		EmptyWallet,
		Tabs,
		SubscriberInformation,
		SubscriptionDetailsMain,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		async getSubscription(id) {
			try {
				this.loading = true;
				this.errorMsg = "";
				const response = await this.$store.dispatch(
					"subscriptions/getSubscription",
					{
						apiKey: getWalletApikey(this.$store),
						id,
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.subscription = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
	},

	async mounted() {
		const id = this.$route.params.id;
		this.id = id;
		this.getSubscription(id);
	},
};
</script>
