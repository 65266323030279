<template>
  <div class="">
    <div class="flex__between  pt-3">
      <div class="form-group">
        <h4>All Request Logs</h4>
      </div>
    </div>
    <ClientLogs />
  </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import ClientLogs from "./ClientLogs";
import { timeDateFormat, titleCase, toCurrency } from "../../../utils/helpers";

export default {
  name: "RequestLogs",
  mixins: [componentData],
  data() {
    return {
      loading: false,
      rLoading: false,
      errorMsg: "",
      size: 25,
      filters: {},
      isMainUser: "",
    };
  },
  components: {
    ClientLogs,
  },
  computed: {},
  methods: {
    titleCase,
    timeDateFormat,
    toCurrency,
    setError(error) {
      this.errorMsg = error.message;
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
  },
};
</script>
