<template>
	<div class="row">
		<!-- Transfer section -->
		<div class="col-lg-12 col-md-12">
			<div>
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
							<label class="input-item-label">
								Beneficiaries
								<span
									class="text-danger"
									title="This field is required"
									>*</span
								>
							</label>
							<a
								href
								@click.prevent="selectAllBeneficiaries"
								class="right"
								>Select all beneficiaries</a
							>
							<v-select
								class="v-select multiple"
								placeholder="Select Beneficiaries"
								@keydown.enter.native.prevent
								v-model="beneficiaries"
								:required="!beneficiaries"
								:options="beneficiaryList"
								label="type_label"
								multiple
							>
								<template
									v-slot:no-options="{ search, searching }"
								>
									<template v-if="searching">
										No results found for
										<em>{{ search }}</em
										>.
									</template>
									<em style="opacity: 0.5" v-else
										>You currently have no
										beneficiaries.</em
									>
								</template>
							</v-select>
							<!-- <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.beneficiaries.$error"
                >
                  <li class="parsley-required">Beneficiaries are required.</li>
              </ul>-->
						</div>
					</div>
					<div class="col-12">
						<form @submit.prevent="performTransaction">
							<div class="row">
								<!-- amount field -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>Amount</label
										>
										<input
											type="text"
											v-model="amount"
											class="form-control input-bordered"
											placeholder="Enter Amount"
											:disabled="accountValidated"
										/>
									</div>
								</div>
								<!-- description field -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>Description</label
										>
										<input
											type="text"
											v-model="description"
											class="form-control input-bordered"
											placeholder="Enter Description"
											:disabled="accountValidated"
										/>
									</div>
								</div>
								<!-- pin field -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>PIN</label
										>
										<input
											type="password"
											v-model="pin"
											class="form-control input-bordered"
											placeholder="Enter PIN"
										/>
									</div>
								</div>
								<!-- ref code field -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>Reference Code</label
										>
										<div
											class="d-flex justify-content-between"
										>
											<input
												type="text"
												v-model="reference_code"
												class="form-control input-bordered col-md-8"
												placeholder="Enter Reference Code"
											/>
											<button
												ref="ref_btn"
												class="btn btn-sm btn-outline-primary col-md-3"
												@click.prevent="randomString"
											>
												Generate Code
											</button>
										</div>
									</div>
								</div>
								<!-- save_beneficiary -->
								<div class="col-md-6">
									<div
										class="form-group d-flex justify-content-between mt-2"
									>
										<label class="input-item-label"
											>Save Beneficiary?</label
										>
										<div
											class="d-flex justify-content-between"
										>
											<div
												class="custom-control custom-checkbox mr-2"
											>
												<input
													type="checkbox"
													class="custom-control-input"
													id="customCheck1"
													@input="beneficiarySaved"
													v-model="trueCheckbox"
												/>
												<label
													class="custom-control-label"
													for="customCheck1"
													>Yes</label
												>
											</div>
											<div
												class="custom-control custom-checkbox"
											>
												<input
													type="checkbox"
													class="custom-control-input"
													id="customCheck2"
													@input="beneficiaryNotSaved"
													v-model="falseCheckbox"
												/>
												<label
													class="custom-control-label"
													for="customCheck2"
													>No</label
												>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6"></div>
								<div class="gaps-2x"></div>
								<button
									class="btn btn-md btn-outline-primary mg-b-20 mx-3"
									@click.prevent="back"
								>
									<span>Go Back</span>
								</button>
								<button
									class="btn btn-md btn-primary mg-b-20"
									type="submit"
									@submit.prevent="performTransaction"
									:disabled="submitting == true"
								>
									<span v-if="submitting == true"
										>Sumbitting...</span
									>
									<span v-else>Sumbit</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Nprogress from "nprogress";
export default {
	data() {
		return {
			activeTab: "selectTransferType",
			transferTab: "",
			accountValidated: false,
			verifyAccount: [],
			submitting: false,
			isSubmitting: false,
			accountVerification: false,
			error: "",
			beneficiary_type: "",
			beneficiaries: "",
			accountNumber: true,
			bank_details: "",
			account_number: "",
			save_beneficiary: "",
			amount: "",
			trueCheckbox: "",
			falseCheckbox: true,
			beneficiary_name: "",
			description: "",
			pin: "kghxqwveJ3eSQJip/cmaMQ==",
			transfer_code: "",
			transfer_type: [
				{ id: 1, type_label: "Transfer to Saved Beneficiary" },
				{ id: 2, type_label: "Transfer to New Beneficiary" },
			],
			beneficiary: [
				{ id: 1, type_label: "Transfer to Saved Beneficiary" },
				{ id: 2, type_label: "Transfer to New Beneficiary" },
				{ id: 3, type_label: "Transfer to New" },
				{ id: 4, type_label: "Transfer to" },
				{ id: 5, type_label: "Transfer" },
				{ id: 6, type_label: "Tran" },
			],
		};
	},
	props: {
		public_key: {
			type: String,
		},
		reference_code: {
			type: String,
		},
		beneficiaryList: {
			type: Array,
		},
	},
	methods: {
		beneficiarySaved() {
			this.falseCheckbox = false;
			this.save_beneficiary = 1;
		},
		beneficiaryNotSaved() {
			this.trueCheckbox = false;
			this.save_beneficiary = 0;
		},
		selectAllBeneficiaries() {
			this.beneficiaries = this.beneficiaryL;
		},
		back() {
			this.accountValidated = false;
			this.accountVerification = false;
		},
		async verifyAccountNumber() {
			Nprogress.start();
			this.submitting = true;
			let data = {
				account_number: this.account_number,
				bank_code: this.bank_details.bank_code,
			};
			try {
				await this.$store
					.dispatch("fundstransfer/verifyAccountNumber", data)
					.then((response) => {
						this.verifyAccount = response.data.results[0];
						this.beneficiary_name = this.verifyAccount.account_name;
						this.accountVerification = true;
						this.accountNumber = false;
						this.accountValidated = true;
						this.randomString();
					});
				this.isLoading = false;
				this.submitting = false;
				Nprogress.done();
			} catch (error) {
				this.submitting = false;
				this.accountNumber = false;
				Nprogress.done();
				console.log(error);
			}
		},
		async performTransaction() {
			Nprogress.start();
			this.submitting = true;
			let data = {
				account_number: this.account_number,
				bank_code: this.bank_details.bank_code,
				amount: this.amount,
				beneficiary_name: this.beneficiary_name,
				metadata: {
					name: this.beneficiary_name,
					payment: this.description,
				},
				narration: this.description,
				save_beneficiary: this.save_beneficiary,
				reference_code: this.reference_code,
				pin: this.pin,
				source: "bank",
			};
			try {
				await this.$store
					.dispatch("fundstransfer/initiateTransfer", data)
					.then((response) => {
						console.log("[data from FT transaction] ->", response);
						if (response.data.results[0].status === "otp") {
							localStorage.setItem(
								"transfer_code",
								response.data.results[0].transfer_code
							);
							this.submitting = false;
							Nprogress.done();
							this.$emit("OTPModal");
						} else {
							this.$swal({
								title: `Transaction Succesful`,
								text: "Transaction Is Queued To Be Processed",
								icon: "success",
								button: "Ok",
							}).then((value) => {});
							this.$router.push("/funds-transfer-transactions");
							this.submitting = false;
							Nprogress.done();
						}
					});
			} catch (error) {
				console.log("error :", error);
				this.showErrorLoginNotification(error.message);
				this.submitting = false;
				Nprogress.done();
			}
		},
	},
};
</script>

<style></style>
