<template>
	<TransactionsTable :source="'invoices'" />
</template>

<script>
import TransactionsTable from "../../common/TransactionsTable/TransactionsTable";

export default {
	name: "InvoiceTransactions",
	components: {
		TransactionsTable,
	},
};
</script>
