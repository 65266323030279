<template>
	<Modal
		:id="id"
		:title="'Update Services'"
		:toggle="closeModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Services Updated Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit">
			<CustomSelect
				name="services"
				label="services"
				:optionLabel="'service_label'"
				placeholder="Select services"
				:options="allServices"
				v-model="services"
				:required="true"
				:multiple="true"
				:error="formErrors.services"
				:handleError="handleError"
				:cls="'h-auto'"
			/>

			<FormButtons
				:loading="loading"
				:btnName="'Update'"
				:handleCloseModal="closeModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../utils/Modal/Modal";
	import FormButtons from "../../../../utils/FormButtons/FormButtons";
	import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../../utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../utils/FormValidator/FormValidator";
	import Spinner from "../../../../utils/Spinner/Spinner";

	export default {
		mixins: [notifications],
		name: "EditServiceModal",
		data() {
			return {
				services: this.$store.state.account.profile.api_customer
					.customerServices,
				loading: false,
				successMsg: false,
				formErrors: {},
			};
		},
		props: ["id", "closeModal", "refetch"],
		components: {
			Modal,
			CustomSelect,
			SuccessState,
			Spinner,
			FormButtons,
		},
		computed: {
			allServices() {
				return this.$store.state.metaData.services;
			},
			clientID() {
				return this.$store.state.account.clientID;
			},
		},
		methods: {
			handleRefetch() {
				this.closeModal();
				this.refetch();
			},
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator({
					services: this.services,
				});
				if (isValid) this.editServices();
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},

			async editServices() {
				let requestData = new FormData();
				this.services.map((service) => {
					requestData.append("services", service.id);
				});

				try {
					this.loading = true;
					await this.$store
						.dispatch("account/editAccountServices", {
							clientid: this.clientID,
							payload: requestData,
						})
						.then(() => {
							this.loading = false;
							this.successMsg = true;
						});
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
