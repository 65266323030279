<template>
  <div>
    <button
      style="border-width: 1px"
      type="button"
      class="btn btn-sm btn--primary--border px-4"
      @click="openChagebackModal"
    >
      <img
        src="/assets/img/icons/card-remove-2.svg"
        alt="image"
        height="20"
        class="mr-2"
      />
      Challenge
    </button>
    <ChallengeChargebackModal
      id="challengeChargeback"
      :chargeback="chargeback"
      :refetch="refetch"
      :closeModal="closeChagebackModal"
    />
  </div>
</template>

<script>
import ChallengeChargebackModal from "../../modals/ChallengeChargebackModal/ChallengeChargebackModal.vue";

export default {
  name: "ChallengeChargeBtn",
  props: ["refetch", "chargeback"],
  components: {
    ChallengeChargebackModal,
  },
  methods: {
    openChagebackModal() {
      $("#challengeChargeback").modal("show");
    },
    closeChagebackModal() {
      $("#challengeChargeback").modal("hide");
    },
  },
};
</script>
