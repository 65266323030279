import pricing from "@/services/wallets/pricing";

export default {
	namespaced: true,
	actions: {
		async getServicesPricing({}, payload) {
			let response = await pricing.getServicesPricing(payload);
			return response;
		},
	},
};
