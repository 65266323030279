<template>
	<div
		class="tab-pane fade active show api__key pt-4"
		id="api_keys_and_webhooks"
		role="tabpanel"
		aria-labelledby="api_keys_and_webhooks-tab"
	>
		<h5>API KEY SETTINGS</h5>
		<div class="d-flex align-items-center flex-md-nowrap flex-wrap mt-4">
			<label class="text-nowrap mb-3">Public Key</label>
			<div class="flex__center w-100">
				<CustomInput
					name="publicKey"
					type="password"
					v-model="request.publicKey"
					:disabled="true"
					:icon="
						request.publicKey ? '/assets/img/icons/copy.svg' : ''
					"
					:iconClick="() => copy(request.publicKey)"
				/>
			</div>
		</div>

		<div class="d-flex align-items-center flex-md-nowrap flex-wrap">
			<label class="text-nowrap mb-3">Secret Key</label>
			<div class="flex__center w-100">
				<CustomInput
					name="apiKey"
					v-model="request.apiKey"
					type="password"
					:disabled="true"
					:icon="request.apiKey ? '/assets/img/icons/copy.svg' : ''"
					:iconClick="() => copy(request.apiKey)"
				/>
				<ChangeKeyButton
					v-show="request.apiKey"
					:keyType="'secretKey'"
					:data="{ environment }"
					:modalTitle="'Change Secret key'"
				/>
			</div>
		</div>

		<hr class="my-4" />

		<h5>WEBHOOK SETTINGS</h5>

		<div class="d-flex align-items-center flex-md-nowrap flex-wrap mt-4">
			<label class="text-nowrap mb-3">Web Hook</label>
			<div class="flex__center w-100">
				<CustomInput
					name="webhook"
					v-model="request.webhook"
					:icon="request.webhook ? '/assets/img/icons/copy.svg' : ''"
					:iconClick="() => copy(request.webhook)"
				/>
				<ChangeKeyButton
					v-show="request.webhook"
					:data="{ environment, webhook: request.webhook }"
					:keyType="'webhook'"
					:modalTitle="'Change Web Hook'"
					:btnName="'update'"
				/>
			</div>
		</div>

		<div class="flex__between mt-5">
			<h5>
				<a
					href="https://documenter.getpostman.com/view/11355566/UVR8po7M#intro"
					target="_blank"
					>View API Documentation</a
				>
			</h5>
			<toggle-button
				v-model="environment"
				color="#006848"
				:labels="{
					checked: 'Live',
					unchecked: 'Test',
				}"
				:value="environment"
				:sync="true"
			/>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import ChangeKeyButton from "../buttons/ChangeKeyButton/ChangeKeyButton";
import { copyToClipboard } from "../../../../utils/helpers";
import "./APIKeySettings.css";

export default {
	name: "APIKeySettings",
	mixins: [notifications],
	data() {
		return {
			environment: this.setEnvironment(),
			request: { ...this.setKey() },
			adminAccess: false,
		};
	},
	props: ["keyValues"],
	components: {
		CustomInput,
		ChangeKeyButton,
	},
	methods: {
		copy(value) {
			const copied = copyToClipboard(value);
			copied && this.showSuccessNotification("API Key Copied!");
		},
		setEnvironment() {
			return (
				this.$store.state.account.customerActiveKeys.environment ===
				"Live"
			);
		},
		setKey(newVal) {
			const env =
				this.$store.state.account.customerActiveKeys.environment;
			let value = newVal === undefined ? env === "Live" : newVal;

			const { webhook, testWebhook, publicKey } = this.keyValues || {};
			const { testPublicKey, apiKey, testApiKey } = this.keyValues || {};
			const environment = newVal ? "Live" : "Test";

			const keys = {
				webhook: value ? webhook : testWebhook,
				publicKey: value ? publicKey : testPublicKey,
				apiKey: value ? apiKey : testApiKey,
			};

			this.request = keys;

			if (newVal !== undefined)
				this.$store.dispatch("account/setActiveKeys", {
					keys,
					environment,
				});

			return keys;
		},
	},
	computed: {
		keys() {
			return (
				this.$store.state.account.customerActiveKeys.environment !==
				"Live"
			);
		},
	},
	watch: {
		environment(newVal) {
			this.setKey(newVal);
		},
	},
};
</script>
