<template>
	<div class="row">
		<div class="col-12">
			<transition mode="out-in" name="fade">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									Bank Account verification
								</h2>
								<h5 class="user-profile-email sub">
									Verify the list of all verified bank account
									linked to a phone number
								</h5>
							</div>
						</div>
						<div class="content">
							<span
								class="badge badge-warning"
								style="font-size: 14px; font-weight: 500"
								v-if="
									hasService &&
									userRole !== 'Secondary Customer'
								"
								>(Each API call costs &#8358;{{
									`${servicePrice}`
								}})</span
							>
							<span
								v-if="!hasService"
								class="badge badge-danger"
								style="
									font-size: 14px;
									margin-top: 5px;
									font-weight: 500;
								"
								>You don't have access to this service</span
							>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="verifyDL">
							<div class="row mt-4">
								<div class="col-md-6">
									<CustomPhoneInput
										:onlyCountries="['NG']"
										:required="true"
										label="Phone Number"
										v-model="phonenumber"
										@blur="$v.phonenumber.$touch()"
										placeholder="Enter your phone number"
										:error="
											$v.phonenumber.$error &&
											formVuelidator($v.phonenumber)
										"
									/>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label">
											Account Number
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
										</label>
										<input
											type="text"
											v-model="accountNummber"
											@blur="$v.accountNummber.$touch()"
											class="form-control input-bordered"
											placeholder="Enter your account number"
											@keypress="onlyNumbers"
										/>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.accountNummber.$error"
										>
											<li class="parsley-required">
												{{
													formVuelidator(
														$v.accountNummber
													)
												}}
											</li>
										</ul>
									</div>
								</div>

								<div class="col-md-12">
									<label class="input-item-label">
										Bank Name
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select a bank"
										v-model="bankName"
										@close="$v.bankName.$touch()"
										@keydown.enter.native.prevent
										:required="!bankName"
										:disabled="!bankList"
										:options="bankList"
										label="name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.bankName.$error"
									>
										<li class="parsley-required">
											Bank name field is required.
										</li>
									</ul>
								</div>

								<!-- API Version details -->
								<!-- <div class="col-md-12">
                  <label class="input-item-label">Select an API Version</label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="apiVersion"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.apiVersion.$error">
                    <li class="parsley-required">API version field is required.</li>
                  </ul>
                </div>-->
								<!-- Application  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@close="$v.app.$touch()"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<!-- Currency Type  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select Currency Type
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Currency Type"
										v-model="currencyType"
										@keydown.enter.native.prevent
										:disabled="!customerWallets"
										:options="customerWallets"
										label="currency"
									></v-select>
								</div>

								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
										:disabled="
											isSubmitting == true || !hasService
										"
									>
										<span v-if="isSubmitting"
											>...Verifying</span
										>
										<span v-else>Verify Number</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="user-settings-section" v-if="hasNewResponse">
					<div class="user-settings-section-header">
						<div class="user-settings-section-header-text">
							<h1>Bank Account Verification Response</h1>
							<span class="text-span"
								>Preview of the information gotten from the bank
								account verification request request made</span
							>
						</div>
						<a
							href="#"
							class="btn btn-outline-danger"
							@click="hasNewResponse = false"
						>
							<i class="la la-close"></i>Close Panel
						</a>
					</div>
					<div class="preview-content">
						<p class="invoice-info-row preview-row">
							<span>Account number to be Verified</span>
							<span>{{ verificationResult.account_number }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Request Reference</span>
							<span>{{
								verificationResult.request_reference
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Verification status</span>
							<span>{{
								verificationResult.verification_status
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Account Name</span>
							<span>{{ verificationResult.account_name }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>BVN</span>
							<span>{{
								verificationResult.bvn || "Not Available"
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Linked Bank</span>
							<span>{{
								verificationResult.account_bank.bank_name
							}}</span>
						</p>
					</div>
				</div>
			</transition>
			<hr />
		</div>

		<div class="row">
			<div class="col-12">
				<div class="user-details-info">
					<div class="user-details--top-info">
						<h2 class="user-profile-name">API Request Format</h2>
						<h5 class="user-profile-email sub">
							Representation of how the API call can be made with
							the appropriate data neccesary
						</h5>
					</div>
				</div>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:35%;">End-Point</label><code>
         POST : '{{API}}IdentityVerification/BankAccountVerify'
    </code></pre>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:42%;">Body</label><code>
      {
          "phone_number": "{{phonenumber.formatted ? getPhoneNumber(phoneNumber) : "phonenumber"}}"
          "account_number": "{{accountNummber ? accountNummber : 'account_number'}}"
          "bank_name": "{{bankName ? bankName : 'bankName'}}"
      }
       </code></pre>
			</div>
			<div class="col-12">
				<pre class="code code-html"><label>Sample Response</label><code>
       {{apiResponse ? apiResponse : `
       {
              "status_code": 200,
              "status": "Success",
              "message": "Bank Account Verification Completed Successfully",
              "results": {
                "request_reference": "abcdefghiogk",
                "verification_status": "VERIFIED",
                "service_type": "Bank Account Number Verification",
                "account_number": "0123456789",
                "account_name": "ADEKUNLE USMAN CHIKE",
                "bvn": "0123456789",
                "account_bank": {
                  "bank_name": ""blusalt bank,
                  "bank_slug": "blusalt-bank",
                  "bank_code": "058",
                  "is_active": 1
                }
              }
            }
          }
        }
       `}}
       </code></pre>
			</div>
		</div>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		></ApplicationInitializationModal>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { banks } from "../../dataset";
import {
	required,
	numeric,
	minLength,
	maxLength,
} from "vuelidate/lib/validators";

import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { getPhoneNumber } from "@/utils/helpers";

export default {
	props: {
		application: {
			type: Array,
		},
		services: {
			type: Array,
		},
	},
	mixins: [notifications, modalMixin],
	validations: {
		app: { required },
		phonenumber: {
			required,
			isPhoneValid: isPhoneValid("phonenumber"),
		},
		accountNummber: {
			required,
			numeric,
			minLength: minLength(10),
			maxLength: maxLength(10),
		},
		bankName: { required },
		// apiVersion: { required },
	},
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
	},
	computed: {
		API() {
			return this.$store.state.appUrlV2;
		},
	},
	data() {
		return {
			app: "",
			initializedAppResult: {},
			versions: ["v1", "v2"],
			apiVersion: "",
			clientID: "",
			call_id: "",
			dl: "",
			apikey: "",
			appname: "",
			accountNummber: "",
			bankName: "",
			phonenumber: "",
			hasNewResponse: false,
			apiResponse: "",
			bankList: banks,
			customerWallets: this.$store.state.account.customerWallets,
			currencyType: "",
			// services
			hasService: false,
			servicePrice: "",

			isSubmitting: false,
			verificationResult: [],
		};
	},
	components: { CustomPhoneInput },
	methods: {
		getPhoneNumber,
		formVuelidator,
		computeService() {
			if (this.services) {
				this.services.filter((service) => {
					if (
						service.service_label.includes(
							"Bank Account Verification"
						)
					) {
						this.hasService = true;
						this.servicePrice = service.pivot.price_per_call;
					}
				});
			}
		},
		getRequestData() {
			if (!this.hasBundleBilling) {
				this.call_id = "";
			}

			let verificationData = {
				clientID: this.clientID,
				appname: this.app.app_name,
				apikey: this.app.api_key,
				payload: {
					phone_number: getPhoneNumber(this.phonenumber),
					account_number: this.accountNummber,
					bank_name: this.bankName.name,
					apiVersion: this.apiVersion,
					currency_type: this.currencyType.currency,
				},
				query: this.call_id,
			};

			return verificationData;
		},
		async verifyDL() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			let verificationData = this.getRequestData();
			this.isSubmitting = true;

			try {
				let apiResponse = await this.$store.dispatch(
					"services/bankAccountVerify",
					verificationData
				);
				this.apiResponse = apiResponse;
				this.showSuccessNotification(apiResponse.message);
				this.verificationResult = apiResponse.results;
				this.hasNewResponse = true;
				this.isSubmitting = false;
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSubmitting = false;
				console.log("error :", error);
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.computeService();

		this.clientID = this.$store.state.account.clientID;
	},
};
</script>

<style scoped>
.bank-list:not(:last-of-type) {
	border-bottom: 1px solid rgb(8, 27, 42) !important;
}
</style>
