<template>
	<div>
		<div :id="`${id}`">
			<h2 class="mb-0">
				<button
					class="btn btn-link"
					type="button"
					data-toggle="collapse"
					:data-target="`#${target}`"
					aria-expanded="false"
					:aria-controls="`${target}`"
				>
					{{ title }}
				</button>
			</h2>
		</div>

		<div
			:id="`${target}`"
			class="collapse"
			:aria-labelledby="`${id}`"
			data-parent="#walletAccordion"
		>
			<div class="accordion__body">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "WalletAccordion",
		props: ["id", "target", "title"],
	};
</script>
