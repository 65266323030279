<template>
  <div class="mt-3">
    <div class="SectionItem no-b-b mb-3 flex__between">
      <p class="title">Documentations</p>

      <UpdateDocumentsBtn @refresh="$emit('refresh')" />
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Certificate of Incorporation</label>
          </div>
          <div :class="{ 'link pointer': data.certificate_of_incorporation }">
            <a
              v-if="data.certificate_of_incorporation"
              :href="data.certificate_of_incorporation"
              download="Proof of Address"
              target="_blank"
            >
              Certificate of Incorporation Link
            </a>
            <span v-else> Not Available</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">
              CAC Form / Business Certificate
            </label>
          </div>
          <div :class="{ 'link pointer': data.form_cac }">
            <a
              v-if="data.form_cac"
              :href="data.form_cac"
              download="Proof of Address"
              target="_blank"
            >
              CAC Form / Business Certificate Link
            </a>
            <span v-else> Not Available</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">
              Memorandum and Articles of Association
            </label>
          </div>
          <div
            :class="{ 'link pointer': data.memorandum_articles_of_association }"
          >
            <a
              v-if="data.memorandum_articles_of_association"
              :href="data.memorandum_articles_of_association"
              download="Proof of Address"
              target="_blank"
            >
              Memorandum and Articles of Association Link
            </a>
            <span v-else> Not Available</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Proof of Address</label>
          </div>
          <div :class="{ 'link pointer': data.proof_of_address }">
            <a
              v-if="data.proof_of_address"
              :href="data.proof_of_address"
              download="Proof of Address"
              target="_blank"
            >
              Proof of Address Link
            </a>
            <span v-else> Not Available</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">TIN Document</label>
          </div>
          <div :class="{ 'link pointer': data.tin_document }">
            <a
              v-if="data.tin_document"
              :href="data.tin_document"
              download="TIN Document"
              target="_blank"
            >
              TIN Document Link
            </a>
            <span v-else> Not Available</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateDocumentsBtn from "./buttons/UpdateDocumentsBtn.vue";
export default {
  props: ["data"],
  components: { UpdateDocumentsBtn },
};
</script>

<style scoped>
.link {
  display: flex;
  margin-bottom: 0;
  word-break: break-all;
  flex-wrap: wrap;
  color: #3d87ce;
  cursor: pointer;
}
</style>
