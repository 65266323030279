import { apiClient } from './api'
apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2

export default {
  RegisterCustomerApp(applicationDetails) {
    return apiClient.post(`Authorize/RegisterCustomerApp`, applicationDetails)
  },
  EditApplication(applicationDetails) {
    let headers = { clientid: applicationDetails.clientid }
    return apiClient.put(`Authorize/EditApplication/${applicationDetails.id}`, applicationDetails.requestPayload, { headers })
  },
  creditApplication(applicationDetails) {
    let headers = { appname: applicationDetails.appname, apikey: applicationDetails.apikey, clientid: applicationDetails.clientid }
    return apiClient.patch(`/App/CreditApp`, applicationDetails.payload, { headers })
  },
  editSecondaryCustomerApplication(applicationDetails) {
    let headers = { clientid: applicationDetails.clientid }
    return apiClient.patch(`Authorize/PrimaryCustomerEditSecondaryCustomerApp/${applicationDetails.id}`, applicationDetails.requestPayload, { headers })
  },
  toggleApplicationStatus(appDetails) {
    let headers = { appname: appDetails.appname, apikey: appDetails.apikey, clientID: appDetails.clientid }
    return apiClient.put(`Authorize/ActivateCustomerApp`, appDetails.payload, { headers })
  },
  EditApplicationServices(appDetails) {
    console.log('[logs] ->', appDetails)
    let headers = {
      appname: appDetails.appname,
      apikey: appDetails.apikey,
      clientid: appDetails.clientid
    }
    return apiClient.put(`Authorize/EditApplicationService/${appDetails.id}`, appDetails.requestPayload, { headers })
  },
  addAppToSecondaryCustomer(appDetails) {
    console.log('[logs] ->', appDetails)
    let headers = { clientid: appDetails.clientid }
    return apiClient.post(`Authorize/SecondaryCustomer/Application`, appDetails.payload, { headers })
  },
  RetrieveApiKey(appDetails) {
    return apiClient.post(`Authorize/RetrieveApiKey`, appDetails)
  },
  InitializeWebApp(appDetails) {
    let headers = {
      appname: appDetails.app_name,
      apikey: appDetails.api_key,
      clientid: appDetails.clientID
    }
    return apiClient.get(`App/InitializeWebApp`, { headers })
  },
  ReGenerateApiKey({headers, payload}) {
    return apiClient.post(`Authorize/ReGenerateApiKey`, payload, { headers })
  },
  GetApps() {
    return apiClient.get(`Authorize/GetApps`)
  },
  RetreiveClientId(appDetails) {
    return apiClient.post(`Authorize/RetreiveClientId`, appDetails)
  },
  whitelabelSettings(appSettings) {
    return apiClient.put(`Authorize/UpdateAppSetting`, appSettings)
  }
}
