import tax from "@/services/wallets/subscriptions/tax";

export default {
	namespaced: true,
	actions: {
		async getAllTaxes({}, payload) {
			return await tax.getAllTaxes(payload);
		},
		async getTax({}, payload) {
			return await tax.getTax(payload);
		},
		async createTax({}, payload) {
			return await tax.createTax(payload);
		},
		async updateTax({}, payload) {
			return await tax.updateTax(payload);
		},
		async deleteTax({}, payload) {
			return await tax.deleteTax(payload);
		},
	},
};
