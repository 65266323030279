<template>
	<Modal
		:id="id"
		:title="'Verify OTP'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'OTP Successfully Verified'"
			:onSuccess="handleCloseModal"
		/>

		<template v-else>
			<div class="flex__center">
				<h3>{{ timerCount }}s</h3>
			</div>

			<form @submit.prevent="handleSubmit">
				<CustomInput
					:name="'otp'"
					v-model="otp"
					:label="'Enter OTP'"
					:placeholder="'Enter OTP'"
					:required="true"
					:isNum="true"
					:maxlength="6"
					:error="formErrors.otp"
					:handleError="handleError"
				/>

				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/></form
		></template>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../utils/Modal/Modal";
	import FormButtons from "../../../../../utils/FormButtons/FormButtons";
	import CustomInput from "../../../../../utils/CustomInput/CustomInput";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
	import Spinner from "../../../../../utils/Spinner/Spinner";

	const initialState = () => ({
		otp: "",
		loading: false,
		successMsg: false,
		formErrors: {},
	});

	export default {
		mixins: [notifications],
		name: "ValidateOTPModal",
		data() {
			return { ...initialState() };
		},
		props: ["id", "closeModal", "request", "timerCount", "request"],
		components: {
			Modal,
			CustomInput,
			SuccessState,
			Spinner,
			FormButtons,
		},
		computed: {},
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator({
					otp: this.otp,
				});
				if (isValid) this.VerifyOtp();
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},

			async VerifyOtp() {
				try {
					this.loading = true;
					const { headers, payload } = this.request || {};
					const newRequest = {
						headers,
						payload: {
							otp: this.otp,
							phone_number: payload.phone_number,
						},
					};

					const response = await this.$store.dispatch(
						"services/verifyOTP",
						newRequest
					);

					if (response.status === 200) {
						this.loading = false;
						this.successMsg = true;
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
		watch: {
			timerCount: {
				handler(value) {
					value > 0
						? setTimeout(() => {
								this.timerCount--;
						  }, 1000)
						: this.handleCloseModal();
				},
			},
		},
	};
</script>
