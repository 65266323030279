<template>
  <div class="az-content-box">
    <div class="az-content-left">
      <div class="az-services-menu">
        <div
          id="accordion"
          class="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <!-- Facials -->
          <div class="card-header panel-heading" role="tab" id="headingOne">
            <a
              data-toggle="collapse"
              href="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <label class="az-content-label az-content-label-sm"
                >Facial Verification</label
              >
            </a>
          </div>
          <div
            id="collapseOne"
            data-parent="#accordion"
            class="collapse show"
            role="tabpanel"
            aria-labelledby="headingOne"
          >
            <nav class="nav az-nav-column mg-b-20">
              <a
                data-toggle="tab"
                @click="tabswitch(11)"
                href
                :class="{ active: tab == 11 }"
                class="nav-link"
                >OCR Validation</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(10)"
                href
                :class="{ active: tab == 10 }"
                class="nav-link"
                >Document Capture</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(9)"
                href
                :class="{ active: tab == 9 }"
                class="nav-link"
                >Facial Comparison</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(12)"
                href
                :class="{ active: tab == 12 }"
                class="nav-link"
                >Body Outlining</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(26)"
                href
                :class="{ active: tab == 26 }"
                class="nav-link"
                >Liveness Check</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(28)"
                href
                :class="{ active: tab == 28 }"
                class="nav-link"
                >Liveness Check (Advanced)</a
              >
            </nav>
          </div>
        </div>
      </div>
    </div>

    <loader v-if="isProcessing == true"></loader>

    <div class="az-content-body tab-content" v-else>
      <!-- <transition
				mode="out-in"
				name="fade"
				v-if="profile.is_active && application.length > 0"
			> -->
      <template v-if="profile.is_active && application.length > 0">
        <FacialComparisonService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 9"
        />
         <BodyOutlineService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 12"
        />
        <DocumentCaptureService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 10"
        />
        <PhysicalIdentityVerificationService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 11"
        />
        <LivenessService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 26"
        />
        <AdvancedLivenessService
          :application="application"
          :services="apps.customerServices"
          v-if="tab == 28"
        />
      </template>
      <!-- </transition> -->
      <DefaultContent
        v-if="!profile.is_active"
        :image="'empty-resources.png'"
        :title="`Seems you've not verified your account.`"
        :subtitle="'Verify your account to start testing out the different services. Contact the Admin to verify your account'"
      ></DefaultContent>
      <DefaultContent
        :subtitle="'You currently do not have any active application, Please create one start testing services'"
      >
        <router-link class="btn btn-md btn-primary" to="/applications">
          <i class="la la-plus"></i> Create Application
        </router-link>
      </DefaultContent>
    </div>
  </div>
</template>

<script>
import PhysicalIdentityVerificationService from "@/components/services/PhysicalIdentityVerificationService";
import FacialComparisonService from "@/components/services/FacialComparisonService";
import DocumentCaptureService from "@/components/services/DocumentCaptureService";
import LivenessService from "@/components/services/LivenessService.vue";
import AdvancedLivenessService from "@/components/services/AdvancedLivenessService.vue";
import BodyOutlineService from "@/components/services/BodyOutlineService.vue";


export default {
  components: {
    BodyOutlineService,
    DocumentCaptureService,
    LivenessService,
    PhysicalIdentityVerificationService,
    FacialComparisonService,
    AdvancedLivenessService,
  },
  computed: {
    profile() {
      return this.$store.state.account.profile.api_customer;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  data() {
    return {
      tab: 11,
      apps: {},
      application: [],
      isProcessing: true,
      isShown: false,
      isShown1: false,
    };
  },
  methods: {
    tabswitch(tab) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (tab) {
        this.tab = tab;
        this.$router.replace({ query: { tab: this.tab } }).catch(() => {});
      } else {
        this.tab = 11;
      }
    },
    toggle() {
      // Toggles control property
      this.isShown = !this.isShown;
    },
    toggle1() {
      // Toggles control property
      this.isShown1 = !this.isShown1;
    },

    async fetchApps() {
      try {
        const app = await this.$store.dispatch("application/getApplications");
        this.apps = app;
        let applications = app.applications;
        let filteredApplication = [];
        if (applications.length > 0) {
          filteredApplication = applications.filter(
            (app) => app.is_active == 1
          );
        }
        // console.log('[filtered] ->', filteredApplication);
        this.application = filteredApplication;

        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  async mounted() {
    if (this.$route.query.tab) {
      let tab = this.$route.query.tab;
      this.tabswitch(tab);
    }
    this.fetchApps();
  },
};
</script>
<style scoped>
.accordion .card-header a {
  padding: 14px 0px !important;
}
.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
  color: #212229;
  background-color: transparent !important;
}

.accordion .card-header a.collapsed {
  background-color: transparent !important;
}

.panel-heading a:before {
  content: "\002B";
  float: right;
  transition: all 0.5s;
}
.panel-heading a.collapsed:before {
  content: "\2212" !important;
  float: right;
  transition: all 0.5s;
}
</style>
