<template>
	<WalletAccordion
		:title="'Advance Information'"
		:id="'headingTwo'"
		:target="'advance'"
	>
		<!-- <section>
			<p>Account Type</p>
			<span>{{ wallet.account_type || "None" }} </span>
		</section>

		<section>
			<p>DOB</p>
			<span>{{ wallet.dob || "None" }} </span>
		</section> -->

		<section>
			<p>KYC</p>
			<span>{{ wallet.kyc || "None" }} </span>
		</section>
	</WalletAccordion>
</template>

<script>
	import WalletAccordion from "../WalletAccordion/WalletAccordion";

	export default {
		name: "WalletAdvanceInfo",
		props: ["wallet"],
		components: {
			WalletAccordion,
		},
	};
</script>
