<template>
	<TransactionsTable
		:source="'payments_collection'"
		:fullHeadings="tableHeadings"
		:downloadHeadings="headings"
	/>
</template>

<script>
import TransactionsTable from "../../common/TransactionsTable/TransactionsTable";
import { collectionTableHeadings } from "../extra";

const tableHeadings = {
	reference: "Transaction Reference",
	amount: "Amount",
	source: "Payment Method",
	type: "Payment Source",
	action: "Type",
	status: "Status",
	createdAt: "Date",
};

export default {
	name: "CollectionTransactions",
	data() {
		return {
			headings: collectionTableHeadings,
			tableHeadings,
		};
	},
	components: {
		TransactionsTable,
	},
};
</script>
