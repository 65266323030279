<template>
	<div
		class="tab-pane fade"
		id="Spending_limit"
		role="tabpanel"
		aria-labelledby="Spending_limit-tab"
	>
		<div class="card">
			<EmptySpendingLimit />
			<!-- <div class="py-5 px-md-5 px-sm-3">
				<h5>Current spending limit: <b>#67,000.00</b></h5>

				<div class="progress rounded my-4">
					<div class="progress-bar" style="width:30%"></div>
				</div>

				<h5 class="text-secondary">Amount spent: <b>#7,000.00</b></h5>
			</div> -->
		</div>
	</div>
</template>

<script>
	import EmptySpendingLimit from "./EmptySpendingLimit/EmptySpendingLimit";

	export default {
		name: "CardSpendingLimit",
		components: {
			EmptySpendingLimit,
		},
	};
</script>
