
import { subscriptionAPI } from "@/services/api";

export default {
	getAllPlans({ apiKey, params }) {
		return subscriptionAPI.get(`/plan`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getPlan({ apiKey, reference }) {
		return subscriptionAPI.get(`/plan/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createPlan({ apiKey, request }) {
		return subscriptionAPI.post(`/plan`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	updatePlan({ apiKey, reference, request }) {
		return subscriptionAPI.put(`/plan/${reference}`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},

	deletePlan({ apiKey, reference }) {
		return subscriptionAPI.delete(`/plan/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
