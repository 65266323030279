<template>
	<div
		class="tab-pane fade"
		id="Cards"
		role="tabpanel"
		aria-labelledby="Cards-tab"
	>
		<div class="card h-100 px-3 pt-3">
			<div class="flex__between pb-2 border-bottom">
				<Tabs
					:tabNames="tabNames"
					:tabId="'CardsTab'"
					:tabClass="'no-border'"
				/>

				<div class="d-flex">
					<div
						class="wallet__switch"
						:class="activeCurrency === 'NGN' ? 'active' : ''"
						@click="switchWallet('NGN')"
					>
						<h4>
							NGN
						</h4>
					</div>
					<div
						class="wallet__switch"
						:class="activeCurrency === 'USD' ? 'active' : ''"
						@click="switchWallet('USD')"
					>
						<h4>
							USD
						</h4>
					</div>
				</div>
			</div>

			<div class="tab-content" id="myCardContent">
				<WalletCardsContent />
				<CardMandates />
			</div>
		</div>

		<WalletCardTransactions />
	</div>
</template>

<script>
	import WalletCardsContent from "./WalletCardsContent/WalletCardsContent";
	import WalletCardTransactions from "./WalletCardTransactions/WalletCardTransactions";
	import CardMandates from "./CardMandates/CardMandates";
	import Tabs from "../../../../../../utils/Tabs/Tabs";

	const tabNames = ["Card", "Mandates"];

	export default {
		name: "WalletCards",
		data() {
			return {
				tabNames,
				activeCurrency: "NGN",
			};
		},
		components: {
			Tabs,
			CardMandates,
			WalletCardsContent,
			WalletCardTransactions,
		},
		methods: {
			switchWallet(type) {
				this.activeCurrency = type;
			},
		},
	};
</script>
