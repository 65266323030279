<template>
    <div class="mr-3">
        <div class="dropdown az-profile-menu">
            <img
                type="button dropdown-toggle"
                data-toggle="dropdown"
                src="/assets/img/icons/ellipsis.svg"
                alt
                width="30px"
            />

            <div class="dropdown-menu">
                <span class="dropdown-item" @click="handleClick">
                    View Details
                </span>
                <AccessControl>
                    <span class="dropdown-item" @click="openSendPaymentLink">
                        Send Payment Link
                    </span></AccessControl
                >
                <span class="dropdown-item" @click="() => copy(link.url)">
                    Copy Payment Link
                </span>
                <AccessControl>
                    <span
                        class="dropdown-item"
                        @click="openEditPaymentLinkModal"
                    >
                        Edit Payment Link
                    </span>
                </AccessControl>
            </div>
        </div>

        <UpdatePaymentLinkModal
            :id="link.reference"
            :payment="link"
            :closeModal="closeEditPaymentLinkModal"
            :paymentLinkRef="paymentLinkRef"
        />

        <SendPaymentLinkModal
            :id="`send${link.reference}`"
            :reference="link.reference"
            :closeModal="closeSendPaymentLink"
        />
    </div>
</template>

<script>
import UpdatePaymentLinkModal from "../../modals/UpdatePaymentLinkModal/UpdatePaymentLinkModal";
import SendPaymentLinkModal from "../../modals/SendPaymentLinkModal/SendPaymentLinkModal";
import AccessControl from "@/utils/AccessControl/AccessControl";

export default {
    name: "ProviderActions",
    props: ["provider", "link", "copy", "paymentLinkRef"],
    components: {
        UpdatePaymentLinkModal,
        SendPaymentLinkModal,
        AccessControl,
    },
    methods: {
        openEditPaymentLinkModal(e) {
            $(`#${this.link.reference}`).modal("show");
        },
        closeEditPaymentLinkModal() {
            $(`#${this.link.reference}`).modal("hide");
        },
        openSendPaymentLink(e) {
            $(`#send${this.link.reference}`).modal("show");
        },
        closeSendPaymentLink() {
            $(`#send${this.link.reference}`).modal("hide");
        },
        handleClick() {
            this.$router.push(`/wallet/payment-links/${this.link.reference}`);
        },
    },
};
</script>
