<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary--border px-4"
			@click="openDepositModal"
		>
			{{ app === "account" ? "Fund Wallet" : "Deposit Funds" }}
		</button>
		<DepositFundModal
			:id="'depositFund'"
			:closeModal="closeModal"
			:wallet="wallet"
			:app="app"
			:setDefault="setDefault"
			:miniText="miniText"
			:minAmount="minAmount"
			:walletId="walletId"
			:refetch="refetch"
			:email="email"
		/>
	</div>
</template>

<script>
import DepositFundModal from "../../modals/DepositFundModal/DepositFundModal";

export default {
	name: "DepositFundButton",
	props: [
		"wallet",
		"email",
		"app",
		"setDefault",
		"miniText",
		"minAmount",
		"walletId",
		"refetch",
	],
	methods: {
		openDepositModal() {
			$("#depositFund").modal("show");
		},
		closeModal() {
			$("#depositFund").modal("hide");
		},
	},
	components: {
		DepositFundModal,
	},
};
</script>
