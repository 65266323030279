<template>
	<div class="card">
		<div class="row">
			<div v-show="title" class="col-12 pt-3 px-4">
				<h5 class="font-weight-bold">{{ title }} ({{ len }})</h5>
			</div>
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<PerPageSelect
								:handleChange="refetch"
								:limit="limit"
							/>
						</template>
						<template slot="filterForm">
							<SplitTransactionFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadTransactions"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="transRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="showData"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template slot="amount" slot-scope="{ row }">
						{{ row.currency }}{{ toCurrency(row.amount, " ") }}
					</template>
					<template slot="charges" slot-scope="{ row }">
						{{ row.currency }}{{ toCurrency(row.charges, " ") }}
					</template>
					<template slot="narration" slot-scope="{ row }">
						{{ row.narration || "Not available" }}
					</template>
					<template slot="source" slot-scope="{ row }">
						{{
							titleCase(
								row.source === "merchant_transfer"
									? "Bank_transfer"
									: row.source
							)
						}}
					</template>
					<template
						slot="metadata.source_account.account_name"
						slot-scope="{ row }"
					>
						{{
							row.metadata && row.metadata.source_account
								? row.metadata.source_account.account_name
								: "---------"
						}}
					</template>
					<template slot="type" slot-scope="{ row }">
						<span :class="statusColor(row.action)">
							{{ titleCase(row.action) }}
						</span>
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						<span>
							{{
								timeDateFormat(row.createdAt).date +
								" " +
								timeDateFormat(row.createdAt).time
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>

		<TransactionsTableModal
			v-if="showModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import FilterWrapper from "../../../../utils/FilterWrapper/FilterWrapper";
import PerPageSelect from "../../../../utils/Selects/PerPageSelect/PerPageSelect";

import SplitTransactionFilters from "../filters/SplitTransactionFilters/SplitTransactionFitlers.vue";

import TransactionsTableModal from "../../../../components/wallet/common/TransactionsTable/TransactionsTableModal.vue";

import ViewMore from "../../../../utils/ViewMore/ViewMore";
import FailedState from "../../../../utils/ResponseState/FailedState";
import Spinner from "../../../../utils/Spinner/Spinner";
import {
	timeDateFormat,
	titleCase,
	tableOptions,
	getWalletApikey,
	statusColor,
	downloadReport,
	toCurrency,
	getError,
} from "../../../../utils/helpers";

const tableHeadings = {
	reference: "Payment Reference",
	amount: "Amount",
	charges: "Charges",
	"metadata.source_account.account_name": "Customer",
	source: "Payment Method",
	type: "Action",
	createdAt: "Date",
};

export default {
	mixins: [notifications],
	name: "SplitPaymentTransactions",
	data() {
		return {
			loading: false,
			rLoading: false,
			showModal: false,
			modalData: null,
			errorMsg: "",
			query: {},
			dateParams: {},
			limit: 25,
			ref: "",
			len: 0,
			...tableOptions(
				{ ...tableHeadings, ...this.headings },
				this.perPage
			),
		};
	},
	props: {
		title: String,
		source: String,
		params: {
			type: Object,
			default: function () {
				return {};
			},
		},
		getTotals: Function,
		headings: {
			type: Object,
			default: function () {
				return {};
			},
		},
		perPage: {
			type: Number,
			default: 25,
		},
	},
	components: {
		ViewMore,
		Spinner,
		FailedState,
		FilterWrapper,
		PerPageSelect,
		TransactionsTableModal,
		SplitTransactionFilters,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		showData({ row }) {
			this.modalData = row;
			this.showModal = true;
		},
		closeModal(e) {
			this.showModal = false;
			this.modalData = null;
		},
		titleCase,
		timeDateFormat,
		statusColor,
		toCurrency,
		getError,
		handlePage(data) {
			console.log("the page", data);
		},
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch({ dateParams = {}, query = {}, limit = 25 }) {
			const newQuery = Object.fromEntries(
				Object.entries(query).filter(([_, v]) => v != "")
			);
			if (dateParams) this.dateParams = { ...dateParams };
			if (query) this.query = newQuery;
			if (limit) {
				this.$refs.transRef.setLimit(limit);
				this.limit = limit;
			}
			this.getTransactions();
			this.$refs.transRef.refresh();
		},
		clearFilter() {
			this.dateParams = {};
			this.$refs.transRef.refresh();
		},
		getRequestData(limit = 9999999999) {
			const requestData = {
				apiKey: getWalletApikey(this.$store),
				params: {
					...this.params,
					...this.dateParams,
					limit,
					...this.query,
				},
			};
			this.source && (requestData.params.type = this.source);
			return requestData;
		},
		async getTransactions() {
			try {
				this.loading = true;
				this.options.requestFunction = async (data) => {
					const requestData = this.getRequestData(this.limit);

					try {
						return (
							await this.$store.dispatch(
								"payment/getSplitPaymentTransaction",
								requestData
							)
						).data.data;
					} catch (error) {
						this.loading = false;
						this.errorMsg = getError(error);
					}
				};
				// console.log(response);

				// if (response.status === 200 || response.status === 201) {
				//   this.loading = false;
				//   this.successMsg = true;
				// }
			} catch (error) {
				this.loading = false;
				this.errorMsg = getError(error);
			}
		},
		async downloadTransactions() {
			this.rLoading = true;
			const requestData = this.getRequestData({});

			const response = await this.$store.dispatch(
				"transactions/getAllTransactions",
				requestData
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				downloadReport(tableHeadings, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},

	created() {
		this.getTransactions();
	},
	mounted() {
		this.columns = Object.keys({
			...tableHeadings,
			...this.headings,
		}).map((keyVal) => keyVal);
	},
};
</script>
