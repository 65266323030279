import wallets from "@/services/wallets";
import account from "@/services/account";
import { apiClient } from "@/services/api";
apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

const handleError = (error) => {
	if (!error.response) {
		throw Error("Network Error, Please check your network and retry");
	} else {
		throw error.response.data;
	}
};

export default {
	namespaced: true,
	state: {
		token: "",
		profile: {},
		userRole: "",
		credit: "",
		clientID: "",
		services: [],
		baseWallet: "",
		customerWallets: "",
		customerKeys: {},
		customerActiveKeys: {},
	},
	mutations: {
		SET_CLIENT_ID(state, clientID) {
			localStorage.setItem("local-ID", clientID);
			state.clientID = clientID;
		},
		SET_TOKEN(state, token) {
			state.token = token;
		},
		SET_SERVICES(state, services) {
			state.services = services;
		},
		SET_CREDIT(state, credit) {
			state.credit = credit;
		},
		ClEAR_TOKEN(state) {
			state.token = "";
		},
		SET_USER_PROFILE(state, profile) {
			state.profile = profile;
		},
		SET_BASE_WALLET(state, walletInfo) {
			state.baseWallet = walletInfo;
		},
		SET_CUSTOMER_WALLETS(state, walletsInfo) {
			state.customerWallets = walletsInfo;
		},
		SET_API_KEYS(state, keys) {
			state.customerKeys = keys;
		},
		SET_ACTIVE_KEYS(state, keys) {
			state.customerActiveKeys = keys;
		},
		SET_USER_ROLE(state, userRole) {
			state.userRole = userRole;
			localStorage.setItem("identifiedAs", userRole);
			localStorage.setItem("saltState", userRole);
		},
	},
	actions: {
		async getProfile({ commit, dispatch }) {
			let userData;

			try {
				let { data } = await account.getProfile();

				userData = data.results[0];
				const { email, team } = userData || {};

				if (team.length > 0) {
					const currentUser = team.find(
						({ user }) => user && user.email === email
					);

					localStorage.setItem(
						"accountRole",
						currentUser.organization_role
					);
				}

				const baseCurrency =
					userData.api_customer.base_currency_type || "NGN";

				const customerWallets = userData.customer_wallet || [];

				const baseWallet = customerWallets.find((wallet) => {
					return wallet.currency == baseCurrency;
				});

				localStorage.setItem("baseWallet", JSON.stringify(baseWallet));

				commit("SET_USER_PROFILE", userData);
				commit("SET_CUSTOMER_WALLETS", customerWallets);
				commit("SET_BASE_WALLET", baseWallet);
				if (userData.roles[0].role_label.includes("Customer")) {
					commit("SET_CLIENT_ID", userData.api_customer.client_id);
					commit(
						"SET_CREDIT",
						userData.api_customer.total_credit_remaining
					);
					commit("SET_USER_ROLE", userData.roles[0].role_label);
					commit(
						"SET_SERVICES",
						userData.api_customer.customerServices
					);
				}

				dispatch("getAPIKeys");
				return data.results[0];
			} catch (error) {
				// if (error.response.data.status_code == 401) {
				// 	dispatch("account/logout", null, { root: true });
				// }
				console.log("error new in own", error);
				throw Error(error);
			}
		},
		async editProfile({ commit, dispatch, rootState }, profileDetails) {
			try {
				if (rootState.account.userRole == "Secondary Customer") {
					let { data } = await account.editSecondaryCustomerProfile(
						profileDetails
					);
					dispatch("getProfile");
					return data;
				}
				let { data } = await account.editProfile(profileDetails);
				dispatch("getProfile");
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async editAccountServices(
			{ commit, dispatch, rootState },
			profileDetails
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.editAccountServices(
					profileDetails
				);
				dispatch("getProfile");
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async generateMfaOtp({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				const { data } = await account.generateMfaOtp();
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async updateMfa({ commit, dispatch }, payload) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				const { data } = await account.updateMfa(payload);
				dispatch("getProfile");
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async addTeamMember({ commit, dispatch }, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.addTeamMember(requestData);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getSecondaryCustomers() {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.getSecondaryCustomers();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getSingleSecondaryCustomers(context, credentials) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.getSingleSecondaryCustomers(
					credentials
				);
				return data.results[0];
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getAppSetting(context) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.getAppSetting();
				return data.results;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async SecondaryCustomerLoadCredit({ commit, dispatch }, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.SecondaryCustomerLoadCredit(
					requestData
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async changePassword(context, credentials) {
			try {
				const apiResponse = await account.passwordChange(credentials);
				return apiResponse.data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getDashboardAnalytics(context, crednetials) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				const { data } = await account.getDashboardAnalytics(
					crednetials
				);
				return data;
			} catch (error) {
				console.log(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		logout({ commit }) {
			commit("ClEAR_TOKEN");
			localStorage.clear();
			location.reload();
		},

		// WhiteLabelling functionalities
		async primaryCustomerEditSecondaryCustomer(
			{ commit, dispatch },
			requestData
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } =
					await account.primaryCustomerEditSecondaryCustomer(
						requestData
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async customerRequestWhiteLabelling({ commit, dispatch }, requestData) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.customerRequestWhiteLabelling(
					requestData
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async secondaryCustomerCreditLogs({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.SecondaryCustomerCreditLogs();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async actualSecondaryCustomerLog({ commit, dispatch }, headers) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.actualSecondaryCustomerLog(
					headers
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async updateSecondaryCustomerPricing(
			{ commit, dispatch },
			requestData
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.updateSecondaryCustomerPricing(
					requestData
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getAllWhiteLabelledServices({ commit, dispatch }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.getAllWhiteLabelledServices();
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async getSecondaryCustomerServicePricing(
			{ commit, dispatch },
			appDetails
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } = await account.getSecondaryCustomerServicePricing(
					appDetails
				);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		async updateSecondaryCustomerServicePricing(
			{ commit, dispatch },
			appDetails
		) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

			try {
				let { data } =
					await account.updateSecondaryCustomerServicePricing(
						appDetails
					);
				return data;
			} catch (error) {
				console.log("[] ->", error.response);
				throw Error(error.response.data.message);
			}
		},
		// Compliance
		async getCompliance({}) {
			try {
				let { data } = await account.getCompliance();
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async addCompliance({}, payload) {
			try {
				let { data } = await account.addCompliance(payload);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		async deleteDirector({}, id) {
			try {
				let { data } = await account.deleteDirector(id);
				return data;
			} catch (error) {
				handleError(error);
			}
		},
		// KYC Actions
		async uploadKYCData({ commit, dispatch }, requestData) {
			try {
				let { data } = await account.uploadKYCData(requestData);
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getKYCData({ commit, dispatch }, requestHeader) {
			try {
				let { data } = await account.getKYCData(requestHeader);
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async viewKYCDocument({ commit, dispatch }, requestData) {
			try {
				let { data } = await account.viewKYCDocument(requestData);
				return data;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		setActiveKeys({ commit }, keys) {
			try {
				console.log("keys", keys);
				commit("SET_ACTIVE_KEYS", keys);
			} catch (error) {
				console.log("Error committing keys", error);
			}
		},
		async getAPIKeys({ commit }) {
			try {
				let { data } = await wallets.getAPIKeys();

				const isProd = process.env.NODE_ENV === "production";

				const { webhook, testWebhook, publicKey } = data.results || {};
				const { testPublicKey, apiKey, testApiKey } =
					data.results || {};

				const keys = {
					webhook: isProd ? webhook : testWebhook,
					publicKey: isProd ? publicKey : testPublicKey,
					apiKey: isProd ? apiKey : testApiKey,
				};

				commit("SET_ACTIVE_KEYS", {
					keys,
					environment: isProd ? "Live" : "Test",
				});
				commit("SET_API_KEYS", data.results);
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
	},
	getters: {
		getClientID(state) {
			return state.clientID;
		},
		getClientProfile(state) {
			return state.profile;
		},
		getClientToken(state) {
			return state.token;
		},
	},
};
