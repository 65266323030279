<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openModal"
    >
      Update Split Payment
    </button>
    <UpdateSplitPaymentModal
      :data="data"
      :id="'updateSplitPayment'"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
import UpdateSplitPaymentModal from "../../modals/UpdateSplitPaymentModal/UpdateSplitPaymentModal.vue";

export default {
  name: "UpdateSplitPaymentButton",
  props: ["splitPaymentRef", "data"],
  methods: {
    openModal() {
      $("#updateSplitPayment").modal("show");
    },
    closeModal() {
      $("#updateSplitPayment").modal("hide");
    },
  },
  components: {
    UpdateSplitPaymentModal,
  },
};
</script>
