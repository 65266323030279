<template>
  <div
    class="tab-pane active fade show"
    id="Sender_Information"
    role="tabpanel"
    aria-labelledby="Sender_Information-tab"
  >
    <div class="row mt-4" v-if="senderData">
      <div class="col-md-6 mb-4">
        <ul class="sub_card h-100">
          <h5>Personal Information</h5>
          <li>
            <p>First Name</p>
            <p>{{ senderData.firstName }}</p>
          </li>
          <li>
            <p>Last Name</p>
            <p>{{ senderData.lastName }}</p>
          </li>
          <li>
            <p>Email</p>
            <p>{{ senderData.email }}</p>
          </li>
          <li>
            <p>Phone</p>
            <p>{{ senderData.phoneNumber }}</p>
          </li>
          <li>
            <p>Country</p>
            <p>{{ senderData.country }}</p>
          </li>
          <li>
            <p>State</p>
            <p>{{ senderData.state }}</p>
          </li>
          <li>
            <p>Address Line 1</p>
            <p>{{ senderData.addressOne }}</p>
          </li>
          <li>
            <p>Address Line 2</p>
            <p>{{ senderData.addressTwo }}</p>
          </li>
          <li>
            <p>Post Code</p>
            <p>{{ senderData.postCode }}</p>
          </li>
          <li>
            <p>ID Type</p>
            <p>
              {{ senderData.idType }} <br />
              <!-- <span v-if="senderData.isIdVerified" class="text-success"
                >Verified</span
              >
              <span v-else class="text-danger">Not Verified</span> -->
            </p>
          </li>
          <li>
            <p>Id Number</p>
            <p>{{ senderData.idNumber }}</p>
          </li>
          <li>
            <p>AML Status</p>
            <p v-if="senderData.AML" class="text-success">Verified</p>
            <p v-else class="text-danger">Not Verified</p>
          </li>
        </ul>
      </div>
      <!-- <div class="col-md-4 mb-4">
        <ul class="sub_card h-100">
          <h5 class="">Device ID</h5>
          <li>
            <p>Device</p>
            <p>{{ senderData.device }}</p>
          </li>
          <li>
            <p>Imei</p>
            <p>{{ senderData.imei }}</p>
          </li>
          <li>
            <p>IP</p>
            <p>{{ senderData.ip }}</p>
          </li>
        </ul>
      </div> -->
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import {
  titleCase,
  toCurrency,
  statusColor,
  timeDateFormat,
  getWalletApikey,
} from "../../../../../../utils/helpers";
import Spinner from "@/utils/Spinner/Spinner";

export default {
  name: "SubscriptionDetailsMain",
  data() {
    return {
      senderData: null,
    };
  },
  components: {
    Spinner,
  },
  methods: {
    titleCase,
    toCurrency,
    statusColor,
    timeDateFormat,
    async getSenders() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          reference: this.$route.params.id,
        },
      };

      try {
        const resp = (
          await this.$store.dispatch("remittance/getSenders", payload)
        ).data.data;
        this.senderData = resp.data[0];
      } catch (error) {
        console.log("transctionError", error);
      }
    },
  },
  created() {
    this.getSenders();
  },
};
</script>
