<template>
	<div>
		<BankSelect
			:name="'bank'"
			:handleError="handleError"
			:error="formErrors.bank"
			v-model="request.bank"
		/>

		<div class="py-3">
			<CustomInput
				:name="'account_number'"
				:maxlength="10"
				:label="'Account Number'"
				:placeholder="'Enter account number'"
				:isNum="true"
				v-model="request.account_number"
				:handleError="handleError"
				:error="formErrors.account_number"
				required="true"
			/>
			<div
				v-if="verifying"
				class="d-flex justify-content-end text-danger"
			>
				<span class="pr-2">Verifying Account...</span>
				<Spinner :variant="'clip'" :size="'16px'" :color="'red'" />
			</div>
			<div v-else-if="verifyError" class="text-right text-danger">
				Error Validating Account
			</div>
		</div>

		<CustomInput
			:name="'account_name'"
			:maxlength="10"
			:placeholder="'Account name'"
			v-model="request.account_name"
			:error="formErrors.account_name"
			:handleError="handleError"
			:label="'Select bank and enter account number to verify'"
			:disabled="true"
			required="true"
		/>

		<div class="flex__between pt-4">
			<button
				class="btn btn-outline w-100 mr-3"
				@click="handleCloseModal"
			>
				<span>{{ btnName }}</span>
			</button>
			<button
				class="btn btn-lg btn-primary w-100 ml-3"
				:disabled="loading"
				@click="handleSubmit"
			>
				<Spinner v-if="loading" />
				<span v-else>Continue</span>
			</button>
		</div>
	</div>
</template>

<script>
	import BankSelect from "../../../../utils/Selects/BankSelect/BankSelect";
	import CustomInput from "../../../../utils/CustomInput/CustomInput";
	import { formValidator } from "../../../../utils/FormValidator/FormValidator";
	import Spinner from "../../../../utils/Spinner/Spinner";

	const initisalState = () => ({
		request: {
			bank: "",
			account_name: "",
			account_number: "",
		},
		formErrors: {},
		verifying: false,
		verifyError: "",
	});

	export default {
		name: "ResolveAccountForm",
		data() {
			return { ...initisalState() };
		},
		props: {
			btnName: { type: String, default: "Close" },
			closeModal: { type: Function },
			formSubmit: { type: Function },
			loading: { type: Boolean },
			account_number: Number,
		},
		components: { CustomInput, Spinner, BankSelect },
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initisalState() });
				this.closeModal();
				this.setFormData();
			},
			handleSubmit() {
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) this.formSubmit(this.request);
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			async verifyAccountNumber() {
				try {
					this.verifying = true;
					this.verifyError = false;
					const response = await this.$store.dispatch(
						"wallets/resolveBankName",
						{
							account_number: this.request.account_number,
							bank_code: this.request.bank.code,
							apiKey: this.user.merchant.api_key,
						}
					);

					if (response.status === 200) {
						this.request.account_name = response.data.data.account_name.toUpperCase();
						this.verifying = false;
					}
				} catch (error) {
					this.verifying = false;
					this.request.account_name = "";
					this.verifyError = true;
				}
			},
			setFormData() {
				if (this.account_number) {
					this.request = {
						bank: "",
						account_name: "",
						account_number: this.account_number,
					};
				}
			},
		},
		computed: {
			user() {
				return this.$store.state.account.profile;
			},
			resolveBank() {
				return `${this.request.bank.code}|${this.request.account_number}`;
			},
		},
		mounted() {
			this.setFormData();
		},
		watch: {
			resolveBank() {
				if (
					this.request.bank &&
					String(this.request.account_number).length === 10
				)
					this.verifyAccountNumber();
			},
		},
	};
</script>
