<template>
  <div
    class="modal fade"
    :id="id"
    :class="cls"
    role="dialog"
    data-backdrop="static"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <template v-if="!!$slots.customHeader">
          <div class="border-bottom py-3 px-4 custom-header">
            <button
              type="button"
              class="border-0 bg-transparent close"
              @click="close"
              :disabled="loading"
            >
              <img src="/assets/img/close.png" alt="" />
            </button>
            <slot name="customHeader"> </slot>
          </div>
        </template>

        <div
          v-else
          class="text-center pos-relative modal-header"
          :class="{ 'border-0': !title }"
        >
          <h4 class="themed-text m-0 font-weight-bold">
            {{ title }}
          </h4>
          <button
            type="button"
            class="close"
            @click="close"
            :disabled="loading"
          >
            <img src="/assets/img/close.png" alt="" />
          </button>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["id", "title", "toggle", "loading", "cls"],
  methods: {
    close() {
      this.toggle && this.toggle();
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  right: 35px;
  top: 18px;
}
.close img {
  height: 20px;
}
</style>
