<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreateWalletModal"
		>
			Create Wallet
		</button>
		<CreateWalletModal
			:id="'createWallet'"
			:closeModal="closeModal"
			:walletRef="walletRef"
		/>
	</div>
</template>

<script>
import CreateWalletModal from "../../modals/CreateWalletModal/CreateWalletModal";

export default {
	name: "CreateWalletButton",
	props: ["walletRef"],
	methods: {
		openCreateWalletModal() {
			$("#createWallet").modal("show");
		},
		closeModal() {
			$("#createWallet").modal("hide");
		},
	},
	components: {
		CreateWalletModal,
	},
};
</script>
