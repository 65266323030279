<template>
	<div
		class
		:style="[
			WLsettings.background_image_url
				? `'backgroundImage: url(/assets/img/${WLsettings.background_image_url})'`
				: ``,
		]"
		style="height: 100vh; width: 100%"
	>
		<!--    <nav-->
		<!--      class="navbar navbar-light navbar-expand-lg justify-content-between"-->
		<!--      :style="`background-color: ${WLsettings.sidebar_color};`"-->
		<!--    >-->
		<!--      <a class="navbar-brand px-3" href="#">-->
		<!--        <img :src="WLsettings.logo_url" width="60" height="60" alt />-->
		<!--      </a>-->
		<!--      <div class="row justify-content-between">-->
		<!--        <div class="collapse navbar-collapse">-->
		<!--          <ul class="navbar-nav">-->
		<!--            <li class="nav-item active px-3">-->
		<!--              <a class="nav-link" href="#">-->
		<!--                <h3 class="text-white font-weight-bold">Platform</h3>-->
		<!--              </a>-->
		<!--            </li>-->
		<!--            <li class="nav-item px-3">-->
		<!--              <a class="nav-link" href="#">-->
		<!--                <h3 class="text-white font-weight-bold">API Docs</h3>-->
		<!--              </a>-->
		<!--            </li>-->
		<!--            <li class="nav-item px-3">-->
		<!--              <a class="nav-link" href="#">-->
		<!--                <h3 class="text-white font-weight-bold">Support Forum</h3>-->
		<!--              </a>-->
		<!--            </li>-->
		<!--          </ul>-->
		<!--        </div>-->
		<!--      </div>-->
		<!--    </nav>-->
		<div
			class="row justify-content-center align-items-center px-5"
			style="height: 80%; width: 100%"
		>
			<transition mode="out-in" name="slide">
				<div class="row">
					<!-- LEFT SIDE INFO BAR -->
					<div class="col-sm-12 col-lg-5 d-none d-lg-block">
						<div class="card">
							<div
								style="
									text-align: center;
									margin: 40px 0px 30px;
								"
							>
								<div class="my-5">
									<img
										src="../../../public/assets/img/gifs/finance.gif"
										width="350"
									/>
								</div>
								<div>
									<p class="lead">
										A Modern and Trusted Platform for
										Digital, Payments and Financial Services
										Infrastructure
									</p>
								</div>
								<div>
									<p class="lead">
										The One-Stop Connection to Multiple APIs
										to stay focused on core expertise of
										your business
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-lg-2 d-none d-lg-block">
						<div class="vertical-divider"></div>
					</div>
					<!-- RIGHT SIDE LOGIN FORM -->
					<div class="col-sm-12 col-lg-5">
						<div class="card-innr card-login auth-card">
							<a href="/" class="logo">
								<img
									:src="
										WLsettings.logo_url
											? WLsettings.logo_url
											: '/assets/img/blusalt-logo-sb-inverse.svg'
									"
									alt
								/>
							</a>
							<div
								class="card-header-w-des no-border align-items-center justify-content-between"
							>
								<div
									style="
										text-align: center;
										margin: 40px 0px 30px;
									"
								>
									<h4
										class="card-title"
										style="color: rgb(34, 34, 34)"
									>
										Welcome Back, Please login to your
										account.
									</h4>
								</div>
							</div>
							<form
								v-if="otpRequested"
								@submit.prevent="submitOtp"
							>
								<div class="form-group">
									<label class="input-item-label"
										>Enter the otp sent to your phone
										number</label
									>
									<input
										type="text"
										class="form-control input-bordered"
										placeholder="Enter OTP"
										v-model.trim="otp"
									/>
									<div class="gaps-2x"></div>
									<button
										type="submit"
										class="btn btn-lg btn-primary btn-block mg-b-20"
										:disabled="isProcessing"
									>
										<span v-if="isProcessing"
											>Attempting to signin</span
										>
										<span v-else>Sign In</span>
									</button>
								</div>
							</form>
							<form v-else @submit.prevent="performLogin">
								<div class="form-group">
									<label class="input-item-label"
										>Email/Phone-number</label
									>
									<input
										type="text"
										class="form-control input-bordered"
										placeholder="Enter your email or phone number"
										v-model.trim="username"
									/>
									<ul
										class="parsley-errors-list filled"
										id="parsley-id-26"
										v-show="$v.username.$error"
									>
										<li class="parsley-required">
											Username is required.
										</li>
									</ul>
								</div>
								<div class="form-group mg-b-20">
									<label class="input-item-label"
										>Password</label
									>
									<div class="pos-relative">
										<input
											:type="type"
											class="form-control input-bordered"
											placeholder="Enter your password"
											v-model="password"
										/>
										<div
											class="d-flex pos-absolute t-5 mt-2 r-10"
										>
											<span
												v-if="type === 'text'"
												@click="type = 'password'"
												class="input-icon input-icon-right"
											>
												<em
													class="fas fa-eye fs-24"
												></em>
											</span>
											<span
												v-if="type === 'password'"
												@click="type = 'text'"
												class="input-icon input-icon-right"
											>
												<em
													class="fas fa-eye-slash fs-24"
												></em>
											</span>
										</div>
									</div>
									<ul
										class="parsley-errors-list filled"
										id="parsley-id-26"
										v-show="$v.password.$error"
									>
										<li class="parsley-required">
											Password is required.
										</li>
									</ul>
								</div>
								<div class="pb-3">
									<router-link
										to="/forgot-password"
										class="left"
										>Forgot password?</router-link
									>
								</div>
								<div class="gaps-2x"></div>
								<button
									type="submit"
									class="btn btn-lg btn-primary btn-block mg-b-20"
									:disabled="isProcessing == true"
								>
									<span v-if="isProcessing"
										>Attempting to signin</span
									>
									<span v-else>Sign In</span>
								</button>
								<p v-if="false">
									Don't have an account?
									<router-link to="/register"
										>Create one now</router-link
									>
								</p>
							</form>
							<div class="az-signup-footer">
								<p>
									<span
										v-if="WLsettings.copy_right_info"
										v-text="WLsettings.copy_right_info"
									></span>
									<span v-else
										>© {{ year }} Blusalt Financial Services
										Ltd. All rights reserved.</span
									>
									<a
										:href="
											WLsettings.company_website_url
												? WLsettings.company_website_url
												: 'http://blusalt.net'
										"
									>
										Go to website
										<i class="la la-arrow-right"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";
import { whitelabelMixin } from "../../../public/assets/mixins/settings";
export default {
	mixins: [notifications, whitelabelMixin],
	created() {
		this.WLsettings =
			this.$store.state.whitelabelling.whitelabelledSettings;
	},
	data() {
		return {
			imageUrl: "app-background.svg",
			isLoading: true,
			type: "password",
			username: "",
			password: "",
			otp: "",
			otpRequestToken: "",
			isProcessing: false,
			otpRequested: false,
			WLsettings: {},
		};
	},
	validations: {
		username: {
			required,
		},
		password: {
			required,
		},
	},
	computed: {
		WLsettings() {
			return this.$store.state.whitelabelling.whitelabelledSettings;
		},
		year() {
			return new Date().getFullYear();
		},
	},
	methods: {
		clearInputFields() {
			this.password = "";
			this.username = "";
			this.otp = "";
		},
		async submitOtp() {
			Nprogress.start();
			this.isProcessing = true;
			try {
				let apiResponse = await this.$store.dispatch(
					"auth/loginUserOtp",
					{
						request_token: this.otpRequestToken,
						code: this.otp,
					}
				);
				// console.log('[apiResponse of login] ->', apiResponse);
				Nprogress.done();
				this.showSuccessNotification(apiResponse.data.message);
				setTimeout(() => {
					location.replace("/account");
				}, 2000);
			} catch (error) {
				Nprogress.done();
				this.clearInputFields();
				this.isProcessing = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async performLogin() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log("[invalid fields] ->");
				return;
			}
			Nprogress.start();
			this.isProcessing = true;
			let credential = {
				username: this.username,
				password: this.password,
			};
			try {
				let apiResponse = await this.$store.dispatch(
					"auth/loginUser",
					credential
				);
				// console.log('[apiResponse of login] ->', apiResponse);
				Nprogress.done();
				this.showSuccessNotification(apiResponse.data.message);
				if (
					apiResponse.data.action &&
					apiResponse.data.action === "otp"
				) {
					this.otpRequested = true;
					this.isProcessing = false;
					this.otpRequestToken = apiResponse.data.request_token;
				} else {
					setTimeout(() => {
						location.replace("/dashboard");
					}, 500);
				}
			} catch (error) {
				Nprogress.done();
				this.clearInputFields();
				this.isProcessing = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
<style lang="css" scoped>
.vertical-divider {
	border-left: 3px solid #e8e8e8;
	height: 100%;
	position: absolute;
	left: 50%;
	margin-left: -3px;
}
</style>
>
