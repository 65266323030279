<template>
	<div>
		<img
			@click="openDeleteItem"
			class="pointer"
			src="/assets/img/icons/trash.svg"
			alt="Delete Product"
		/>

		<DeleteItemModal
			:id="`${item.reference}`"
			:closeModal="closeModal"
			:itemsRef="itemsRef"
			:item="item"
		/>
	</div>
</template>

<script>
	import DeleteItemModal from "../../modals/DeleteItemModal/DeleteItemModal";

	export default {
		name: "DeleteItemButton",
		props: ["itemsRef", "item"],
		methods: {
			openDeleteItem() {
				$(`#${this.item.reference}`).modal("show");
			},
			closeModal() {
				$(`#${this.item.reference}`).modal("hide");
			},
		},
		components: {
			DeleteItemModal,
		},
	};
</script>
