<template>
	<Modal :id="id" :title="'Deposit Funds'" :toggle="closeAll">
		<WalletForm
			:miniText="miniText"
			:minAmount="minAmount"
			:closeModal="closeAll"
			:submitForm="depositFund"
			:setDefault="setDefault"
			:walletId="walletId"
			:app="app"
			:loading="loading"
		/>
	</Modal>
</template>

<script>
import Axios from "axios";
import {
	notifications,
	componentData,
} from "../../../../../../public/assets/mixins";
import Modal from "@/utils/Modal/Modal";
import WalletForm from "../WalletForm/WalletForm";

export default {
	mixins: [notifications, componentData],
	name: "DepositFundModal",
	props: [
		"id",
		"closeModal",
		"wallet",
		"app",
		"setDefault",
		"miniText",
		"minAmount",
		"walletId",
		"refetch",
		"email",
	],
	data() {
		return {
			loading: false,
		};
	},
	components: { Modal, WalletForm },
	computed: {
		apiKey() {
			const publicKey = this.getWalletPublickey(this.$store);
			return this.app === "wallet"
				? publicKey
				: process.env.VUE_APP_PUBLIC_API_KEY;
		},
	},
	methods: {
		closeAll() {
			this.loading = false;
			this.closeModal();
		},
		async depositFund(wallet, amount) {
			this.loading = true;

			const metadata = { inApp: true };
			this.email && (metadata.email = this.email);

			const payload = {
				walletId: wallet.reference,
				amount: amount,
				currency: wallet.currency,
				metadata,
			};

			try {
				const url = `${process.env.VUE_APP_WALLETS_BASE_URL}/checkout/initialize`;

				const { data } = await Axios.post(url, payload, {
					headers: {
						Authorization: this.apiKey,
						"x-api-key": this.xEnv,
					},
				});
				this.loading = false;
				this.$store.state.checkoutData = {
					url: data.data.authorization_url,
					refetch: this.refetch,
				};
				this.closeModal();
			} catch (error) {
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
