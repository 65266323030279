<template>
	<div
		id="collapseFour"
		data-parent="#accordion"
		class="collapse show"
		role="tabpanel"
		aria-labelledby="headingFour"
	>
		<nav class="nav az-nav-column mg-b-20" role>
			<a
				data-toggle="tab"
				@click="tabswitch(tabNumber)"
				href
				:class="{ active: tab == tabNumber }"
				class="nav-link"
				>{{ title }}</a
			>
		</nav>
	</div>
</template>

<script>
	export default {
		name: "TabSwitch",
		props: ["tab", "tabNumber", "tabswitch", "title"],
	};
</script>
