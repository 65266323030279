<template>
	<div class="form-group">
		<label class="input-item-label">Preferred Bank</label>
		<span class="text-danger pl-1" title="This field is required">*</span>
		<v-select
			class="v-select"
			placeholder="Select bank"
			@keydown.enter.native.prevent
			label="name"
			:options="banks"
			:value="value"
			:disabled="disabled"
			v-on:input="$emit('input', $event)"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: "BankProviderSelect",
	data() {
		return {
			loading: false,
			banks: [],
		};
	},
	props: ["name", "value", "error", "handleError", "disabled"],
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async getBankProviders() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getBankProvidersFromCore"
				);
				this.loading = false;
				this.banks = response.data.data;
			} catch (error) {
				this.banks = [];
				this.loading = false;
			}
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getBankProviders();
	},
};
</script>
