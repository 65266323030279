<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div class="p-4" v-else>
			<div class="SectionItem no-b-b mb-3 flex__between">
				<p class="title">Customers</p>
			</div>

			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<AddCustomerButton :customerRef="$refs.customerRef"
						/></AccessControl>
					</template>
					<template slot="filterForm">
						<CustomersFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('customerRef')"
							:download="downloadCustomers"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<AddCustomerButton :customerRef="$refs.customerRef" />
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="customerRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@row-click="handleClick"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="business" slot-scope="{ row }">
							<span>
								{{ row.business ? "Business" : "Individual" }}
							</span>
						</template>
						<template slot="phone" slot-scope="{ row }">
							{{ getPhone(row.phone) }}
						</template>

						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import AddCustomerButton from "./buttons/AddCustomerButton/AddCustomerButton";
import CustomersFilters from "./CustomersFilters/CustomersFilters";
import { customerCsvRows, tableHeadings } from "./enums";

export default {
	name: "Customers",
	mixins: [componentData],
	data() {
		return {
			emptyTitle: "No Customer Found",
			...this.tableOptions(tableHeadings, this.size),
		};
	},
	components: {
		AddCustomerButton,
		CustomersFilters,
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(`/wallet/customer/${row.reference}/${row.id}`);
		},
		refetch(filters) {
			this.handleRefetch(filters, this.getCustomers, "customerRef");
		},
		async getCustomers() {
			this.handleAPICall("customers/getAllCustomers");
		},
		async downloadCustomers() {
			const download = (filterData) =>
				this.downloadReport(customerCsvRows, filterData.data.data);
			this.downloadTableReport("customers/getAllCustomers", download);
		},
	},
	created() {
		this.getCustomers();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
