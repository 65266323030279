const countryCodes = [
  {
    name: "Nigeria",
    dial_code: "234",
  },
  {
    name: "Ghana",
    dial_code: "233",
  },
  {
    name: "Kenya",
    dial_code: "254",
  },
  {
    name: "Uganda",
    dial_code: "256",
  },
  {
    name: "Tanzania",
    dial_code: "255",
  },
  {
    name: "Rwanda",
    dial_code: "250",
  },
  {
    name: "Malawi",
    dial_code: "265",
  },
  {
    name: "Zambia",
    dial_code: "260",
  },
  {
    name: "Cote D'Ivoire",
    dial_code: "255",
  },
  {
    name: "Ethiopia",
    dial_code: "251",
  },
  {
    name: "South Africa",
    dial_code: "27",
  },
  {
    name: "Afghanistan",
    dial_code: "93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: " 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "506",
    code: "CR",
  },
  {
    name: "Croatia",
    dial_code: "385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "372",
    code: "EE",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "49",
    code: "DE",
  },
  {
    name: "Gibraltar",
    dial_code: "350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "77",
    code: "KZ",
  },
  {
    name: "Kiribati",
    dial_code: "686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "261",
    code: "MG",
  },
  {
    name: "Malaysia",
    dial_code: "60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "227",
    code: "NE",
  },
  {
    name: "Niue",
    dial_code: "683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "7",
    code: "RU",
  },
  {
    name: "Reunion",
    dial_code: "262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "252",
    code: "SO",
  },
  {
    name: "South Sudan",
    dial_code: "211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "992",
    code: "TJ",
  },
  {
    name: "Thailand",
    dial_code: "66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "688",
    code: "TV",
  },
  {
    name: "Ukraine",
    dial_code: "380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "967",
    code: "YE",
  },
  {
    name: "Zimbabwe",
    dial_code: "263",
    code: "ZW",
  },
];

const countryCurrency = [
  { code: "AED", name: "United Arab Emirates dirham", symbol: "" },
  { code: "AFN", name: "Afghan afghani", symbol: "" },
  { code: "ALL", name: "Albanian lek", symbol: "" },
  { code: "AMD", name: "Armenian dram", symbol: "" },
  { code: "ANG", name: "Netherlands Antillean guilder", symbol: "" },
  { code: "AOA", name: "Angolan kwanza", symbol: "" },
  { code: "ARS", name: "Argentine peso", symbol: "" },
  { code: "AUD", name: "Australian dollar", symbol: "" },
  { code: "AWG", name: "Aruban florin", symbol: "" },
  { code: "AZN", name: "Azerbaijani manat", symbol: "" },
  { code: "BAM", name: "Bosnia and Herzegovina convertible mark", symbol: "" },
  { code: "BBD", name: "Barbados dollar", symbol: "" },
  { code: "BDT", name: "Bangladeshi taka", symbol: "" },
  { code: "BGN", name: "Bulgarian lev", symbol: "" },
  { code: "BHD", name: "Bahraini dinar", symbol: "" },
  { code: "BIF", name: "Burundian franc", symbol: "" },
  { code: "BMD", name: "Bermudian dollar", symbol: "" },
  { code: "BND", name: "Brunei dollar", symbol: "" },
  { code: "BOB", name: "Boliviano", symbol: "" },
  { code: "BRL", name: "Brazilian real", symbol: "" },
  { code: "BSD", name: "Bahamian dollar", symbol: "" },
  { code: "BTN", name: "Bhutanese ngultrum", symbol: "" },
  { code: "BWP", name: "Botswana pula", symbol: "" },
  { code: "BYN", name: "New Belarusian ruble", symbol: "" },
  { code: "BYR", name: "Belarusian ruble", symbol: "" },
  { code: "BZD", name: "Belize dollar", symbol: "" },
  { code: "CAD", name: "Canadian dollar", symbol: "" },
  { code: "CDF", name: "Congolese franc", symbol: "" },
  { code: "CHF", name: "Swiss franc", symbol: "" },
  { code: "CLF", name: "Unidad de Fomento", symbol: "" },
  { code: "CLP", name: "Chilean peso", symbol: "" },
  { code: "CNY", name: "Renminbi|Chinese yuan", symbol: "" },
  { code: "COP", name: "Colombian peso", symbol: "" },
  { code: "CRC", name: "Costa Rican colon", symbol: "₡" },
  { code: "CUC", name: "Cuban convertible peso", symbol: "" },
  { code: "CUP", name: "Cuban peso", symbol: "" },
  { code: "CVE", name: "Cape Verde escudo", symbol: "" },
  { code: "CZK", name: "Czech koruna", symbol: "" },
  { code: "DJF", name: "Djiboutian franc", symbol: "" },
  { code: "DKK", name: "Danish krone", symbol: "" },
  { code: "DOP", name: "Dominican peso", symbol: "" },
  { code: "DZD", name: "Algerian dinar", symbol: "" },
  { code: "EGP", name: "Egyptian pound", symbol: "" },
  { code: "ERN", name: "Eritrean nakfa", symbol: "" },
  { code: "ETB", name: "Ethiopian birr", symbol: "" },
  { code: "EUR", name: "Euro", symbol: "€" },
  { code: "FJD", name: "Fiji dollar", symbol: "" },
  { code: "FKP", name: "Falkland Islands pound", symbol: "" },
  { code: "GBP", name: "Pound sterling", symbol: "£" },
  { code: "GEL", name: "Georgian lari", symbol: "" },
  { code: "GHS", name: "Ghanaian cedi", symbol: "" },
  { code: "GIP", name: "Gibraltar pound", symbol: "" },
  { code: "GMD", name: "Gambian dalasi", symbol: "" },
  { code: "GNF", name: "Guinean franc", symbol: "" },
  { code: "GTQ", name: "Guatemalan quetzal", symbol: "" },
  { code: "GYD", name: "Guyanese dollar", symbol: "" },
  { code: "HKD", name: "Hong Kong dollar", symbol: "" },
  { code: "HNL", name: "Honduran lempira", symbol: "" },
  { code: "HRK", name: "Croatian kuna", symbol: "" },
  { code: "HTG", name: "Haitian gourde", symbol: "" },
  { code: "HUF", name: "Hungarian forint", symbol: "" },
  { code: "IDR", name: "Indonesian rupiah", symbol: "" },
  { code: "ILS", name: "Israeli new shekel", symbol: "₪" },
  { code: "INR", name: "Indian rupee", symbol: "₹" },
  { code: "IQD", name: "Iraqi dinar", symbol: "" },
  { code: "IRR", name: "Iranian rial", symbol: "" },
  { code: "ISK", name: "Icelandic króna", symbol: "" },
  { code: "JMD", name: "Jamaican dollar", symbol: "" },
  { code: "JOD", name: "Jordanian dinar", symbol: "" },
  { code: "JPY", name: "Japanese yen", symbol: "¥" },
  { code: "KES", name: "Kenyan shilling", symbol: "" },
  { code: "KGS", name: "Kyrgyzstani som", symbol: "" },
  { code: "KHR", name: "Cambodian riel", symbol: "" },
  { code: "KMF", name: "Comoro franc", symbol: "" },
  { code: "KPW", name: "North Korean won", symbol: "" },
  { code: "KRW", name: "South Korean won", symbol: "₩" },
  { code: "KWD", name: "Kuwaiti dinar", symbol: "" },
  { code: "KYD", name: "Cayman Islands dollar", symbol: "" },
  { code: "KZT", name: "Kazakhstani tenge", symbol: "" },
  { code: "LAK", name: "Lao kip", symbol: "" },
  { code: "LBP", name: "Lebanese pound", symbol: "" },
  { code: "LKR", name: "Sri Lankan rupee", symbol: "" },
  { code: "LRD", name: "Liberian dollar", symbol: "" },
  { code: "LSL", name: "Lesotho loti", symbol: "" },
  { code: "LYD", name: "Libyan dinar", symbol: "" },
  { code: "MAD", name: "Moroccan dirham", symbol: "" },
  { code: "MDL", name: "Moldovan leu", symbol: "" },
  { code: "MGA", name: "Malagasy ariary", symbol: "" },
  { code: "MKD", name: "Macedonian denar", symbol: "" },
  { code: "MMK", name: "Myanmar kyat", symbol: "" },
  { code: "MNT", name: "Mongolian tögrög", symbol: "" },
  { code: "MOP", name: "Macanese pataca", symbol: "" },
  { code: "MRO", name: "Mauritanian ouguiya", symbol: "" },
  { code: "MUR", name: "Mauritian rupee", symbol: "" },
  { code: "MVR", name: "Maldivian rufiyaa", symbol: "" },
  { code: "MWK", name: "Malawian kwacha", symbol: "" },
  { code: "MXN", name: "Mexican peso", symbol: "" },
  { code: "MXV", name: "Mexican Unidad de Inversion", symbol: "" },
  { code: "MYR", name: "Malaysian ringgit", symbol: "" },
  { code: "MZN", name: "Mozambican metical", symbol: "" },
  { code: "NAD", name: "Namibian dollar", symbol: "" },
  { code: "NGN", name: "Nigerian naira", symbol: "₦" },
  { code: "NIO", name: "Nicaraguan córdoba", symbol: "" },
  { code: "NOK", name: "Norwegian krone", symbol: "" },
  { code: "NPR", name: "Nepalese rupee", symbol: "" },
  { code: "NZD", name: "New Zealand dollar", symbol: "" },
  { code: "OMR", name: "Omani rial", symbol: "" },
  { code: "PAB", name: "Panamanian balboa", symbol: "" },
  { code: "PEN", name: "Peruvian Sol", symbol: "" },
  { code: "PGK", name: "Papua New Guinean kina", symbol: "" },
  { code: "PHP", name: "Philippine peso", symbol: "₱" },
  { code: "PKR", name: "Pakistani rupee", symbol: "" },
  { code: "PLN", name: "Polish złoty", symbol: "zł" },
  { code: "PYG", name: "Paraguayan guaraní", symbol: "₲" },
  { code: "QAR", name: "Qatari riyal", symbol: "" },
  { code: "RON", name: "Romanian leu", symbol: "" },
  { code: "RSD", name: "Serbian dinar", symbol: "" },
  { code: "RUB", name: "Russian ruble", symbol: "" },
  { code: "RWF", name: "Rwandan franc", symbol: "" },
  { code: "SAR", name: "Saudi riyal", symbol: "" },
  { code: "SBD", name: "Solomon Islands dollar", symbol: "" },
  { code: "SCR", name: "Seychelles rupee", symbol: "" },
  { code: "SDG", name: "Sudanese pound", symbol: "" },
  { code: "SEK", name: "Swedish krona", symbol: "" },
  { code: "SGD", name: "Singapore dollar", symbol: "" },
  { code: "SHP", name: "Saint Helena pound", symbol: "" },
  { code: "SLL", name: "Sierra Leonean leone", symbol: "" },
  { code: "SOS", name: "Somali shilling", symbol: "" },
  { code: "SRD", name: "Surinamese dollar", symbol: "" },
  { code: "SSP", name: "South Sudanese pound", symbol: "" },
  { code: "STD", name: "São Tomé and Príncipe dobra", symbol: "" },
  { code: "SVC", name: "Salvadoran colón", symbol: "" },
  { code: "SYP", name: "Syrian pound", symbol: "" },
  { code: "SZL", name: "Swazi lilangeni", symbol: "" },
  { code: "THB", name: "Thai baht", symbol: "฿" },
  { code: "TJS", name: "Tajikistani somoni", symbol: "" },
  { code: "TMT", name: "Turkmenistani manat", symbol: "" },
  { code: "TND", name: "Tunisian dinar", symbol: "" },
  { code: "TOP", name: "Tongan paʻanga", symbol: "" },
  { code: "TRY", name: "Turkish lira", symbol: "" },
  { code: "TTD", name: "Trinidad and Tobago dollar", symbol: "" },
  { code: "TWD", name: "New Taiwan dollar", symbol: "" },
  { code: "TZS", name: "Tanzanian shilling", symbol: "" },
  { code: "UAH", name: "Ukrainian hryvnia", symbol: "₴" },
  { code: "UGX", name: "Ugandan shilling", symbol: "" },
  { code: "USD", name: "United States dollar", symbol: "$" },
  { code: "UYI", name: "Uruguay Peso en Unidades Indexadas", symbol: "" },
  { code: "UYU", name: "Uruguayan peso", symbol: "" },
  { code: "UZS", name: "Uzbekistan som", symbol: "" },
  { code: "VEF", name: "Venezuelan bolívar", symbol: "" },
  { code: "VND", name: "Vietnamese đồng", symbol: "₫" },
  { code: "VUV", name: "Vanuatu vatu", symbol: "" },
  { code: "WST", name: "Samoan tala", symbol: "" },
  { code: "XAF", name: "Central African CFA franc", symbol: "" },
  { code: "XCD", name: "East Caribbean dollar", symbol: "" },
  { code: "XOF", name: "West African CFA franc", symbol: "" },
  { code: "XPF", name: "CFP franc", symbol: "" },
  { code: "XXX", name: "No currency", symbol: "" },
  { code: "YER", name: "Yemeni rial", symbol: "" },
  { code: "ZAR", name: "South African rand", symbol: "" },
  { code: "ZMW", name: "Zambian kwacha", symbol: "" },
  { code: "ZWL", name: "Zimbabwean dollar", symbol: "" },
];

const countrySortCode = [
  { code3: "AFG", name: "Afghanistan" },
  { code3: "ALB", name: "Albania" },
  { code3: "DZA", name: "Algeria" },
  { code3: "ASM", name: "American Samoa" },
  { code3: "AND", name: "Andorra" },
  { code3: "AGO", name: "Angola" },
  { code3: "AIA", name: "Anguilla" },
  { code3: "ATA", name: "Antarctica" },
  { code3: "ATG", name: "Antigua and Barbuda" },
  { code3: "ARG", name: "Argentina" },
  { code3: "ARM", name: "Armenia" },
  { code3: "ABW", name: "Aruba" },
  { code3: "AUS", name: "Australia" },
  { code3: "AUT", name: "Austria" },
  { code3: "AZE", name: "Azerbaijan" },
  { code3: "BHS", name: "Bahamas" },
  { code3: "BHR", name: "Bahrain" },
  { code3: "BGD", name: "Bangladesh" },
  { code3: "BRB", name: "Barbados" },
  { code3: "BLR", name: "Belarus" },
  { code3: "BEL", name: "Belgium" },
  { code3: "BLZ", name: "Belize" },
  { code3: "BEN", name: "Benin" },
  { code3: "BMU", name: "Bermuda" },
  { code3: "BTN", name: "Bhutan" },
  {
    code3: "BOL",
    name: "Bolivia (Plurinational State of)",
  },
  {
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  { code3: "BIH", name: "Bosnia and Herzegovina" },
  { code3: "BWA", name: "Botswana" },
  { code3: "BVT", name: "Bouvet Island" },
  { code3: "BRA", name: "Brazil" },
  {
    code3: "IOT",
    name: "British Indian Ocean Territory",
  },
  { code3: "BRN", name: "Brunei Darussalam" },
  { code3: "BGR", name: "Bulgaria" },
  { code3: "BFA", name: "Burkina Faso" },
  { code3: "BDI", name: "Burundi" },
  { code3: "CPV", name: "Cabo Verde" },
  { code3: "KHM", name: "Cambodia" },
  { code3: "CMR", name: "Cameroon" },
  { code3: "CAN", name: "Canada" },
  { code3: "CYM", name: "Cayman Islands" },
  {
    code3: "CAF",
    name: "Central African Republic",
  },
  { code3: "TCD", name: "Chad" },
  { code3: "CHL", name: "Chile" },
  { code3: "CHN", name: "China" },
  { code3: "CXR", name: "Christmas Island" },
  {
    code3: "CCK",
    name: "Cocos (Keeling) Islands",
  },
  { code3: "COL", name: "Colombia" },
  { code3: "COM", name: "Comoros" },
  {
    code3: "COD",
    name: "Congo (the Democratic Republic of the)",
  },
  { code3: "COG", name: "Congo" },
  { code3: "COK", name: "Cook Islands" },
  { code3: "CRI", name: "Costa Rica" },
  { code3: "HRV", name: "Croatia" },
  { code3: "CUB", name: "Cuba" },
  { code3: "CUW", name: "Curaçao" },
  { code3: "CYP", name: "Cyprus" },
  { code3: "CZE", name: "Czechia" },
  { code3: "CIV", name: "Côte d'Ivoire" },
  { code3: "DNK", name: "Denmark" },
  { code3: "DJI", name: "Djibouti" },
  { code3: "DMA", name: "Dominica" },
  { code3: "DOM", name: "Dominican Republic" },
  { code3: "ECU", name: "Ecuador" },
  { code3: "EGY", name: "Egypt" },
  { code3: "SLV", name: "El Salvador" },
  { code3: "GNQ", name: "Equatorial Guinea" },
  { code3: "ERI", name: "Eritrea" },
  { code3: "EST", name: "Estonia" },
  { code3: "SWZ", name: "Eswatini" },
  { code3: "ETH", name: "Ethiopia" },
  {
    code3: "FLK",
    name: "Falkland Islands [Malvinas]",
  },
  { code3: "FRO", name: "Faroe Islands" },
  { code3: "FJI", name: "Fiji" },
  { code3: "FIN", name: "Finland" },
  { code3: "FRA", name: "France" },
  { code3: "GUF", name: "French Guiana" },
  { code3: "PYF", name: "French Polynesia" },
  {
    code3: "ATF",
    name: "French Southern Territories",
  },
  { code3: "GAB", name: "Gabon" },
  { code3: "GMB", name: "Gambia" },
  { code3: "GEO", name: "Georgia" },
  { code3: "DEU", name: "Germany" },
  { code3: "GHA", name: "Ghana" },
  { code3: "GIB", name: "Gibraltar" },
  { code3: "GRC", name: "Greece" },
  { code3: "GRL", name: "Greenland" },
  { code3: "GRD", name: "Grenada" },
  { code3: "GLP", name: "Guadeloupe" },
  { code3: "GUM", name: "Guam" },
  { code3: "GTM", name: "Guatemala" },
  { code3: "GGY", name: "Guernsey" },
  { code3: "GIN", name: "Guinea" },
  { code3: "GNB", name: "Guinea-Bissau" },
  { code3: "GUY", name: "Guyana" },
  { code3: "HTI", name: "Haiti" },
  {
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
  },
  { code3: "VAT", name: "Holy See" },
  { code3: "HND", name: "Honduras" },
  { code3: "HKG", name: "Hong Kong" },
  { code3: "HUN", name: "Hungary" },
  { code3: "ISL", name: "Iceland" },
  { code3: "IND", name: "India" },
  { code3: "IDN", name: "Indonesia" },
  {
    code3: "IRN",
    name: "Iran (Islamic Republic of)",
  },
  { code3: "IRQ", name: "Iraq" },
  { code3: "IRL", name: "Ireland" },
  { code3: "IMN", name: "Isle of Man" },
  { code3: "ISR", name: "Israel" },
  { code3: "ITA", name: "Italy" },
  { code3: "JAM", name: "Jamaica" },
  { code3: "JPN", name: "Japan" },
  { code3: "JEY", name: "Jersey" },
  { code3: "JOR", name: "Jordan" },
  { code3: "KAZ", name: "Kazakhstan" },
  { code3: "KEN", name: "Kenya" },
  { code3: "KIR", name: "Kiribati" },
  {
    code3: "PRK",
    name: "Korea (the Democratic People's Republic of)",
  },
  { code3: "KOR", name: "Korea (the Republic of)" },
  { code3: "KWT", name: "Kuwait" },
  { code3: "KGZ", name: "Kyrgyzstan" },
  {
    code3: "LAO",
    name: "Lao People's Democratic Republic",
  },
  { code3: "LVA", name: "Latvia" },
  { code3: "LBN", name: "Lebanon" },
  { code3: "LSO", name: "Lesotho" },
  { code3: "LBR", name: "Liberia" },
  { code3: "LBY", name: "Libya" },
  { code3: "LIE", name: "Liechtenstein" },
  { code3: "LTU", name: "Lithuania" },
  { code3: "LUX", name: "Luxembourg" },
  { code3: "MAC", name: "Macao" },
  { code3: "MDG", name: "Madagascar" },
  { code3: "MWI", name: "Malawi" },
  { code3: "MYS", name: "Malaysia" },
  { code3: "MDV", name: "Maldives" },
  { code3: "MLI", name: "Mali" },
  { code3: "MLT", name: "Malta" },
  { code3: "MHL", name: "Marshall Islands" },
  { code3: "MTQ", name: "Martinique" },
  { code3: "MRT", name: "Mauritania" },
  { code3: "MUS", name: "Mauritius" },
  { code3: "MYT", name: "Mayotte" },
  { code3: "MEX", name: "Mexico" },
  {
    code3: "FSM",
    name: "Micronesia (Federated States of)",
  },
  {
    code3: "MDA",
    name: "Moldova (the Republic of)",
  },
  { code3: "MCO", name: "Monaco" },
  { code3: "MNG", name: "Mongolia" },
  { code3: "MNE", name: "Montenegro" },
  { code3: "MSR", name: "Montserrat" },
  { code3: "MAR", name: "Morocco" },
  { code3: "MOZ", name: "Mozambique" },
  { code3: "MMR", name: "Myanmar" },
  { code3: "NAM", name: "Namibia" },
  { code3: "NRU", name: "Nauru" },
  { code3: "NPL", name: "Nepal" },
  { code3: "NLD", name: "Netherlands" },
  { code3: "NCL", name: "New Caledonia" },
  { code3: "NZL", name: "New Zealand" },
  { code3: "NIC", name: "Nicaragua" },
  { code3: "NER", name: "Niger" },
  { code3: "NGA", name: "Nigeria" },
  { code3: "NIU", name: "Niue" },
  { code3: "NFK", name: "Norfolk Island" },
  {
    code3: "MNP",
    name: "Northern Mariana Islands",
  },
  { code3: "NOR", name: "Norway" },
  { code3: "OMN", name: "Oman" },
  { code3: "PAK", name: "Pakistan" },
  { code3: "PLW", name: "Palau" },
  { code3: "PSE", name: "Palestine, State of" },
  { code3: "PAN", name: "Panama" },
  { code3: "PNG", name: "Papua New Guinea" },
  { code3: "PRY", name: "Paraguay" },
  { code3: "PER", name: "Peru" },
  { code3: "PHL", name: "Philippines" },
  { code3: "PCN", name: "Pitcairn" },
  { code3: "POL", name: "Poland" },
  { code3: "PRT", name: "Portugal" },
  { code3: "PRI", name: "Puerto Rico" },
  { code3: "QAT", name: "Qatar" },
  {
    code3: "MKD",
    name: "Republic of North Macedonia",
  },
  { code3: "ROU", name: "Romania" },
  { code3: "RUS", name: "Russian Federation" },
  { code3: "RWA", name: "Rwanda" },
  { code3: "REU", name: "Réunion" },
  { code3: "BLM", name: "Saint Barthélemy" },
  {
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { code3: "KNA", name: "Saint Kitts and Nevis" },
  { code3: "LCA", name: "Saint Lucia" },
  {
    code3: "MAF",
    name: "Saint Martin (French part)",
  },
  {
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
  },
  {
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
  },
  { code3: "WSM", name: "Samoa" },
  { code3: "SMR", name: "San Marino" },
  { code3: "STP", name: "Sao Tome and Principe" },
  { code3: "SAU", name: "Saudi Arabia" },
  { code3: "SEN", name: "Senegal" },
  { code3: "SRB", name: "Serbia" },
  { code3: "SYC", name: "Seychelles" },
  { code3: "SLE", name: "Sierra Leone" },
  { code3: "SGP", name: "Singapore" },
  {
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
  },
  { code3: "SVK", name: "Slovakia" },
  { code3: "SVN", name: "Slovenia" },
  { code3: "SLB", name: "Solomon Islands" },
  { code3: "SOM", name: "Somalia" },
  { code3: "ZAF", name: "South Africa" },
  {
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
  },
  { code3: "SSD", name: "South Sudan" },
  { code3: "ESP", name: "Spain" },
  { code3: "LKA", name: "Sri Lanka" },
  { code3: "SDN", name: "Sudan" },
  { code3: "SUR", name: "Suriname" },
  { code3: "SJM", name: "Svalbard and Jan Mayen" },
  { code3: "SWE", name: "Sweden" },
  { code3: "CHE", name: "Switzerland" },
  { code3: "SYR", name: "Syrian Arab Republic" },
  { code3: "TWN", name: "Taiwan" },
  { code3: "TJK", name: "Tajikistan" },
  {
    code3: "TZA",
    name: "Tanzania, United Republic of",
  },
  { code3: "THA", name: "Thailand" },
  { code3: "TLS", name: "Timor-Leste" },
  { code3: "TGO", name: "Togo" },
  { code3: "TKL", name: "Tokelau" },
  { code3: "TON", name: "Tonga" },
  { code3: "TTO", name: "Trinidad and Tobago" },
  { code3: "TUN", name: "Tunisia" },
  { code3: "TUR", name: "Turkey" },
  { code3: "TKM", name: "Turkmenistan" },
  {
    code3: "TCA",
    name: "Turks and Caicos Islands",
  },
  { code3: "TUV", name: "Tuvalu" },
  { code3: "UGA", name: "Uganda" },
  { code3: "UKR", name: "Ukraine" },
  {
    code3: "ARE",
    name: "United Arab Emirates",
  },
  {
    code3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    code3: "UMI",
    name: "United States Minor Outlying Islands",
  },
  {
    code3: "USA",
    name: "United States of America",
  },
  { code3: "URY", name: "Uruguay" },
  { code3: "UZB", name: "Uzbekistan" },
  { code3: "VUT", name: "Vanuatu" },
  {
    code3: "VEN",
    name: "Venezuela (Bolivarian Republic of)",
  },
  { code3: "VNM", name: "Viet Nam" },
  { code3: "VGB", name: "Virgin Islands (British)" },
  { code3: "VIR", name: "Virgin Islands (U.S.)" },
  { code3: "WLF", name: "Wallis and Futuna" },
  { code3: "ESH", name: "Western Sahara" },
  { code3: "YEM", name: "Yemen" },
  { code3: "ZMB", name: "Zambia" },
  { code3: "ZWE", name: "Zimbabwe" },
  { code3: "ALA", name: "Åland Islands" },
];

const newCountryFormat = countryCodes.map((item) => {
  const label = `${item.name} (+${item.dial_code})`;
  const value = item.dial_code;
  return {
    label: label,
    value: value,
  };
});

export { countryCodes, newCountryFormat, countryCurrency, countrySortCode };



