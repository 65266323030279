<template>
	<Modal
		:id="id"
		:title="'Create Payment Collection'"
		:toggle="handleCloseModal"
	>
		<div class="row" v-if="step === 0 && !successMsg">
			<div class="col-md-6">
				<div
					class="my-4 border p-4 rounded pointer shadow-sm"
					@click="goto(1)"
				>
					<div class="flex__center">
						<img
							src="/assets/img/icons/card-remove.svg"
							alt="image"
							height="60"
						/>
					</div>
					<h5 class="text-center">Basic Page</h5>
					<p class="text-center">
						Create a simple one time payment page.
					</p>
				</div>
			</div>
			<div class="col-md-6">
				<div
					class="my-4 border p-4 rounded pointer shadow-sm"
					@click="goto(2)"
				>
					<div class="flex__center">
						<img
							src="/assets/img/icons/card-remove.svg"
							alt="image"
							height="60"
						/>
					</div>
					<h5 class="text-center">Product Page</h5>
					<p class="text-center">
						Collect payment for a product or service you're
						offering.
					</p>
				</div>
			</div>
		</div>

		<form
			@submit.prevent="handleSubmit"
			class="h-100"
			v-if="step === 1 && !successMsg"
		>
      <div class="row">
        <div class="col-sm-6">
          <CustomInput
            :name="'title'"
            :label="'Page Title'"
            :placeholder="'Enter Page Title'"
            :required="true"
            v-model="request.title"
            :error="
              $v.request.title.$error &&
              formVuelidator($v.request.title)
            "
          />
        </div>

        <div class="col-sm-6">
          <CustomInput
            :name="'description'"
            :label="'Page Description'"
            :placeholder="'What is the  page desctiption'"
            :required="true"
            v-model="request.description"
            :error="
              $v.request.description.$error &&
              formVuelidator($v.request.description)
            "
          />
        </div>

        <div class="col-sm-6">
          <CurrencySelect
            :name="'currencyId'"
            :handleError="handleError"
            :error="
              $v.request.currencyId.$error &&
              formVuelidator($v.request.currencyId)
            "
            v-model="request.currencyId"
            :ngnOnly="true"
          />
        </div>
        <div class="col-sm-6">
          <CustomInput
            :name="'amount'"
            :label="'Amount'"
            :placeholder="'Amount'"
            :required="true"
            :isNum="true"
            v-model="request.amount"
          />
        </div>
        <div class="col-6">
          <CustomSelect
            :name="'split_id'"
            :label="'Select Split'"
            :placeholder="'Select Split'"
            :optionLabel="'name'"
            :options="splitList"
            v-model="request.split_id"
            @close="$v.request.split_id.$touch()"
          />
        </div>
        <div class="col-sm-6">
          <label class="input-item-label">
            Expiry Date
            <span class="text-danger" title="This field is required"
              >*</span
            >
          </label>
          <DatePicker
            :name="'to'"
            v-model="request.expiry_date"
            :disabledDates="{ to: new Date() }"
            :placeholder="'Expiry Date'"
            :error="
              $v.request.expiry_date.$error &&
              formVuelidator($v.request.expiry_date)
            "
          />
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <CustomUpload
              :name="'first'"
              :label="'Image'"
              :required="false"
              :validFileTypes="['.png', '.jpg', '.jpeg']"
              :handleChange="handleUpload"
              :fileName="request.images && request.images.name"
              :error="''"
            />
            <div v-if="previewImage" class="mt-2">
              <img
                :src="previewImage"
                alt="img"
                width="50"
                height="50"
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-6">
          <label class="input-item-label">Add Contact Info</label>
          <div class="d-flex">
            <label class="rdiobox mr-4">
              <input
                type="radio"
                :value="true"
                v-model="request.add_contact_info"
              />
              <span class="fs-15">Yes </span>
            </label>
            <label class="rdiobox">
              <input
                type="radio"
                :value="false"
                v-model="request.add_contact_info"
              />
              <span class="fs-15">No</span>
            </label>
          </div>
        </div>
        <template v-if="request.split_id">
          <div
            class="col-12"
            v-for="(split, key) in request.split_id.config"
            :key="key"
          >
            <div class="row border-bottom py-2 mx-1">
              <div class="col-1">
                {{ key + 1 }}
              </div>
              <div class="col-4">
                {{ split.sub_account }}
              </div>
              <div class="col-4">
                {{ split.type }}
              </div>
              <div class="col-3 text-right">
                {{
                  split.type === "flat"
                    ? toCurrency(split.value)
                    : split.value
                }}
              </div>
            </div>
          </div>
        </template>
        <div class="col-12">
          <FormButtons
            :closeBtnName="'Back'"
            :loading="loading"
            :handleCloseModal="() => goto(0)"
          />
        </div>
      </div>
		</form>
		<form
			@submit.prevent="handleSubmit"
			class="h-100"
			v-if="step === 2 && !successMsg"
		>
      <div class="row">
        <div class="col-sm-6">
          <CustomInput
            :name="'title'"
            :label="'Page Title'"
            :placeholder="'Enter Page Title'"
            :required="true"
            v-model="request.title"
            :error="
              $v.request.title.$error &&
              formVuelidator($v.request.title)
            "
          />
        </div>

        <div class="col-sm-6">
          <CustomInput
            :name="'description'"
            :label="'Page Description'"
            :placeholder="'What is the  page desctiption'"
            :required="true"
            v-model="request.description"
            :error="
              $v.request.description.$error &&
              formVuelidator($v.request.description)
            "
          />
        </div>
        <div class="col-sm-6">
          <ProductSelect
            :product="request.productRef"
            :required="true"
            :setRequest="setRequest"
            :error="
              $v.request.productRef.$error &&
              formVuelidator($v.request.productRef)
            "
          />
        </div>
        <div class="col-sm-6">
          <CurrencySelect
            :name="'currencyId'"
            :handleError="handleError"
            :error="
              $v.request.currencyId.$error &&
              formVuelidator($v.request.currencyId)
            "
            v-model="request.currencyId"
            :ngnOnly="true"
          />
        </div>
        <div class="col-6">
          <CustomSelect
            :name="'split_id'"
            :label="'Select Split'"
            :placeholder="'Select Split'"
            :optionLabel="'name'"
            :options="splitList"
            v-model="request.split_id"
            @close="$v.request.split_id.$touch()"
          />
        </div>
        <div class="col-sm-6">
          <label class="input-item-label">
            Expiry Date
            <span class="text-danger" title="This field is required"
              >*</span
            >
          </label>
          <DatePicker
            :name="'to'"
            v-model="request.expiry_date"
            :disabledDates="{ to: new Date() }"
            :placeholder="'Expiry Date'"
            :error="
              $v.request.expiry_date.$error &&
              formVuelidator($v.request.expiry_date)
            "
          />
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <CustomUpload
              :name="'first'"
              :label="'Image'"
              :required="false"
              :validFileTypes="['.png', '.jpg', '.jpeg']"
              :handleChange="handleUpload"
              :fileName="request.images && request.images.name"
              :error="''"
            />
            <div v-if="previewImage" class="mt-2">
              <img
                :src="previewImage"
                alt="img"
                width="50"
                height="50"
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-6">
          <label class="input-item-label">Add Contact Info</label>
          <div class="d-flex">
            <label class="rdiobox mr-4">
              <input
                type="radio"
                :value="true"
                v-model="request.add_contact_info"
              />
              <span class="fs-15">Yes </span>
            </label>
            <label class="rdiobox">
              <input
                type="radio"
                :value="false"
                v-model="request.add_contact_info"
              />
              <span class="fs-15">No</span>
            </label>
          </div>
        </div>
        <template v-if="request.split_id">
          <div
            class="col-12"
            v-for="(split, key) in request.split_id.config"
            :key="key"
          >
            <div class="row border-bottom py-2 mx-1">
              <div class="col-1">
                {{ key + 1 }}
              </div>
              <div class="col-4">
                {{ split.sub_account }}
              </div>
              <div class="col-4">
                {{ split.type }}
              </div>
              <div class="col-3 text-right">
                {{
                  split.type === "flat"
                    ? toCurrency(split.value)
                    : split.value
                }}
              </div>
            </div>
          </div>
        </template>
        <div class="col-12">
          <FormButtons
            :closeBtnName="'Back'"
            :loading="loading"
            :handleCloseModal="() => goto(0)"
          />
        </div>
      </div>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "@/utils/Modal/Modal";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import ProductSelect from "@/utils/Selects/ProductSelect/ProductSelect";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import FormButtons from "@/utils/FormButtons/FormButtons";
import SplitPaymentTable from "./SplitPaymentTable/SplitPaymentTable";
import { getWalletApikey, copyToClipboard, toCurrency } from "@/utils/helpers";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import { numeric, requiredIf, required } from "vuelidate/lib/validators";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

const initialState = () => ({
	request: {
		title: "",
		description: "",
		amount: "",
		currencyId: "",
		split_id: "",
		productRef: "",
		collect_name: false,
		reminder: false,
		collect_shipping_dest: false,
		add_contact_info: true,
		expiry_date: "",
		images: "",
	},
	step: 0,
	uploadError: false,
	formErrors: {},
	successMsg: false,
	apiData: {},
});

export default {
	mixins: [notifications],
	name: "CreatePaymentCollectionModal",
	data() {
		return {
			...initialState(),
			splitList: [],
			productList: [],
			loading: false,
			currencies: [],
			previewImage: "",
		};
	},
	validations: {
		request: {
			title: {
				required,
			},
			description: {
				required,
			},
			currencyId: {
				required,
			},
			expiry_date: {
				required,
			},
			productRef: {
				required: requiredIf(function () {
					console.log(this.page_type);
					return this.step == 2;
				}),
			},
		},
	},
	props: ["id", "closeModal", "collectionRef"],
	components: {
		Modal,
		ProductSelect,
		CurrencySelect,
		CustomInput,
		CustomSelect,
		FormButtons,
		SuccessState,
		SplitPaymentTable,
		DatePicker,
		CustomUpload,
	},
	methods: {
		toCurrency,
		formVuelidator,
		getLabel: (val) => {
			return `${val.name} ( ${val.account_number} )`;
		},
		async handleUpload({ target: { files } }) {
			this.mediaData = null;

			const file = files[0];
			if (file) {
				this.previewImage = URL.createObjectURL(file);
				this.request.images = file;
			}
		},
		async copy() {
			const { account_name, account_number, amount } = this.apiData;
			const { currency, bank_name } = this.apiData;
			const account = `Account Name: ${account_name}, Account Number: ${account_number}, Bank Name: ${bank_name}, Amount: ${currency} ${amount}`;
			const copied = copyToClipboard(account);
			copied &&
				this.showSuccessNotification("Account Information Copied!");
		},
		handleCloseModal() {
			Object.assign(this.$data, initialState());
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			this.collectionRef.refresh();
		},
		checkSplitValue(split) {
			let amt = parseFloat(this.request.amount);
			let isSplitValid = true;
			const { config } = split || this.request.split_id || {};
			let [flatValues, percentValues] = [0, 0];

			[...config].forEach(({ value, type }) => {
				if (type === "flat")
					flatValues = flatValues + parseFloat(value);
				else percentValues = percentValues + parseFloat(value);
			});

			percentValues = (percentValues / 100) * amt;
			let totaVal = percentValues + flatValues;

			if (this.step === 1 && (totaVal > amt || Number.isNaN(totaVal))) {
				isSplitValid = false;
				this.showErrorLoginNotification(
					"Total split amount must not be greater that the initial amount"
				);
			} else isSplitValid = true;
			return isSplitValid;
		},

		handleSubmit() {
			this.$v.$touch();
			const { amount, customer, split_id } = this.request;
			const payload = { ...customer, amount };
			delete payload.split_id;
			delete payload.amount;
			const { formErrors, isValid } = formValidator(payload);
			if (isValid && !this.$v.$invalid) {
				if (split_id) {
					const isSplited = this.checkSplitValue();
					isSplited && this.createCollection();
				} else this.createCollection();
			} else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		async getMerchantSPlits() {
			try {
				const splitList = await this.$store.dispatch(
					"payment/getMerchantSplitPayments",
					{ apiKey: getWalletApikey(this.$store) }
				);
				this.splitList = splitList.data.data.data;
			} catch (error) {
				this.splitList = [];
			}
		},
		async getProducts() {
			try {
				const productResp = await this.$store.dispatch(
					"products/getProducts",
					{
						apiKey: getWalletApikey(this.$store),
						limit: 10000,
					}
				);
				this.productList = productResp.data.data.data;
			} catch (error) {
				console.log("unable to fetch products", error);
			}
		},
		async getCurrencies() {
			try {
				const response = await this.$store.dispatch(
					"wallets/getCurrencies"
				);
				this.currencies = response.data;
			} catch (error) {
				this.currencies = [];
			}
		},
		async createCollection() {
			try {
				this.loading = true;
				const { amount, split_id, productRef } = this.request;
				const payload = {
					...this.request,
					amount: parseFloat(amount),
				};

				if (split_id) payload.split_id = split_id.reference;
				else delete payload.split_id;
				if (productRef)
					payload.productRef = [`${productRef.reference}`];
				else delete payload.productRef;
				payload.page_type = payload.productRef ? "product" : "basic";
				payload.currencyId = payload.currencyId.id;
				payload.expiry_date = payload.expiry_date.toISOString();

				let formData = new FormData();
				formData.append("title", payload.title);
				formData.append("description", payload.description);
				payload.amount && formData.append("amount", payload.amount);
				formData.append("currencyId", payload.currencyId);
				payload.split_id &&
					formData.append("split_id", payload.split_id);
				payload.productRef &&
					formData.append("productRef[0]", payload.productRef);
				formData.append("collect_name", payload.collect_name);
				formData.append("reminder", payload.reminder);
				formData.append(
					"collect_shipping_dest",
					payload.collect_shipping_dest
				);
				formData.append("page_type", payload.page_type);
				formData.append("add_contact_info", payload.add_contact_info);
				formData.append("expiry_date", payload.expiry_date);
				payload.images && formData.append("images", payload.images);

				if (payload.page_type === "basic" && !amount) {
					this.showErrorLoginNotification("Amount is required");
					this.loading = false;
					return false;
				}
				const response = await this.$store.dispatch(
					"payment/createPaymentPage",
					{
						apiKey: getWalletApikey(this.$store),
						request: formData,
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.showSuccessNotification(response.data.message);
					this.handleCloseModal();
					this.collectionRef.refresh();
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		goto(step) {
			this.step = step;
			this.previewImage = "";
			this.loading = false;
			this.request = {
				images: "",
				title: "",
				description: "",
				amount: "",
				currencyId: "",
				split_id: "",
				productRef: "",
				collect_name: false,
				reminder: false,
				collect_shipping_dest: false,
				add_contact_info: false,
				expiry_date: "",
			};
			this.$v.$reset();
		},
		setRequest(name, value) {
			this.request[name] = value;
		},
	},
	computed: {
		succesRes() {
			return this.$store.state.account.customerKeys;
		},
	},
	mounted() {
		this.getMerchantSPlits();
		this.getProducts();
		this.getCurrencies();
	},
	watch: {
		"request.split_id"(v) {
			this.checkSplitValue(v);
		},
	},
};
</script>
