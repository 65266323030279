<template>
	<div>
		<TransferListTableHeader
			:resetTransfer="resetTransfer"
			:accountFile="accountFile"
		/>

		<div class="card">
			<div class="p-3 d-flex justify-content-end">
				<div class="">
					<CustomUpload
						:btnName="'Replace File'"
						:cls="'btn-sm btn--primary--border '"
						:name="'accountList'"
						:validFileTypes="['text/csv']"
						:showOnlyBtn="true"
						:handleChange="handleUpload"
					/>
				</div>
				<AddMoreAccountButton
					:handleUpload="handleUpload"
					:accountFile="accountFile"
					:updateAccountList="updateAccountList"
				/>

				<button
					:disabled="disabledContinue"
					class="btn btn-sm btn--primary"
					@click="next"
				>
					Continue
				</button>
			</div>

			<div class="transfer__list">
				<v-client-table
					ref="bulkRef"
					class="v-table row-pointer"
					:data="account"
					:columns="columns"
					:options="options"
				>
					<template slot="accountName" slot-scope="{ row }">
						{{ row.accountName || "--------------------" }}
					</template>
					<template slot="resolved" slot-scope="{ row }">
						<span :class="statusColor(row.resolved)">
							{{ row.resolved ? "Resolved" : "Unresolved" }}
						</span>
					</template>
					<template slot="action" slot-scope="props">
						<UpdateFTAccountButton
							v-show="!props.row.resolved"
							:account="props"
							:accountFile="accountFile"
							:updateAccountList="updateAccountList"
						/>
					</template>
					<template slot="action2" slot-scope="props">
						<DeleteFTAccountButton
							:account="props"
							:removeAccount="removeAccount"
						/>
					</template>
				</v-client-table>
			</div>
		</div>
	</div>
</template>

<script>
import FilterWrapper from "../../../../utils/FilterWrapper/FilterWrapper";
import TransaferListFilters from "./TransaferListFilters/TransaferListFilters";
import TransferListTableHeader from "./TransferListTableHeader/TransferListTableHeader";
import CustomUpload from "../../../../utils/CustomUpload/CustomUpload";
import AddMoreAccountButton from "../../buttons/AddMoreAccountButton/AddMoreAccountButton";
import DeleteFTAccountButton from "../../buttons/DeleteFTAccountButton/DeleteFTAccountButton";
import UpdateFTAccountButton from "../../buttons/UpdateFTAccountButton/UpdateFTAccountButton";
import {
	statusColor,
	titleCase,
	tableOptions,
} from "../../../../utils/helpers";

const tableHeadings = {
	accountName: "Account Name",
	accountNumber: "Account Number",
	bankCode: "Bank Code",
	amount: "Amount (Naira)",
	narration: "Narration",
	resolved: "Status",
	action: "",
	action2: "",
};

export default {
	name: "TransferListTable",
	data() {
		return {
			filters: {},
			...tableOptions(tableHeadings, 100),
		};
	},
	props: [
		"accountFile",
		"resetTransfer",
		"next",
		"updateAccountList",
		"handleUpload",
	],
	components: {
		FilterWrapper,
		CustomUpload,
		TransaferListFilters,
		AddMoreAccountButton,
		UpdateFTAccountButton,
		DeleteFTAccountButton,
		TransferListTableHeader,
	},
	methods: {
		titleCase,
		statusColor,
		clearFilter() {
			this.filters = {};
		},
		submitFilter(filters) {
			this.filters = filters;
		},
		removeAccount({ accountNumber, amount }) {
			let allAccount = [...this.account];
			const index = allAccount.findIndex(
				(v) => v.accountNumber === accountNumber && v.amount === amount
			);
			allAccount.splice(index, 1);
			this.updateAccountList(allAccount);
		},
	},
	computed: {
		account() {
			return this.accountFile;
		},
		disabledContinue() {
			const verifCount = this.accountFile.filter(
				({ resolved }) => resolved === true
			).length;
			return verifCount === 0;
		},
	},
	async mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
