<template>
	<div class="content">
    <Spinner v-if="loading" />
		<div v-else>
      <span
        class="badge badge-warning"
        style="font-size: 14px; font-weight: 500"
        v-if="hasService && userRole !== 'Secondary Customer'"
        >(Each API call costs &#8358;{{ `${servicePrice}` }})
      </span>
      <span
        v-if="!hasService"
        class="badge badge-danger"
        style="font-size: 14px; margin-top: 5px; font-weight: 500"
        >You don't have access to this service</span
      >
    </div>
	</div>
</template>

<script>
import { notifications } from "../../../public/assets/mixins/notifications";
import Spinner from "@/utils/Spinner/Spinner";

export default {
	mixins: [notifications],
	name: "PricesAndAccess",
  components: {
		Spinner,
  },
	data() {
		return {
      loading: true,
			hasService: false,
			servicePrice: "",
		};
	},
	props: ["serviceLabel", "getServiceStatus"],
	methods: {
		async fetchApps() {
			try {
				const res = await this.$store.dispatch(
					"application/getApplications"
				);
				const services = res.customerServices;
				services &&
					services.filter(
						({ service_label, pivot, price_per_call }) => {
							if (service_label.includes(this.serviceLabel)) {
								this.hasService = true;
								this.getServiceStatus &&
									this.getServiceStatus(true);
								this.servicePrice =
									price_per_call || pivot.price_per_call;
							}
						}
					);
			} catch (error) {
				this.hasService = false;
			}
      this.loading=false
		},
	},
	mounted() {
		this.fetchApps();
	},
};
</script>
