<template>
  <Modal
    :id="id"
    :title="'Add New Customer'"
    :toggle="handleCloseModal"
    :loading="loading"
  >
    <SuccessState
      v-if="successMsg"
      :message="'Customer Created Successfully'"
      :onSuccess="handleRefetch"
    />

    <form v-else @submit.prevent="handleSubmit" class="h-100 row">
      <div class="col-sm-6">
        <CustomInput
          :name="'first_name'"
          v-model="request.first_name"
          :label="'First Name'"
          :placeholder="'Enter first name'"
          :required="true"
          :error="formErrors.first_name"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6">
        <CustomInput
          :name="'last_name'"
          v-model="request.last_name"
          :label="'Last Name'"
          :placeholder="'Enter last name'"
          :required="true"
          :error="formErrors.last_name"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6">
        <CustomInput
          :name="'email'"
          v-model="request.email"
          :label="'Email'"
          :placeholder="'Enter email'"
          :required="true"
          :error="formErrors.email"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6">
        <CustomPhoneInput
          :name="'phone'"
          v-model="request.phone"
          @blur="$v.request.phone.$touch()"
          :label="'Phone Number'"
          :placeholder="'Enter Phone Number'"
          :error="
            formErrors.phone ||
            ($v.request.phone.$error && formVuelidator($v.request.phone))
          "
          :required="true"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6">
        <CustomInput
          :name="'bvn'"
          v-model="request.bvn"
          :label="'BVN'"
          :placeholder="'Enter BVN'"
          :isNum="true"
          :error="formErrors.bvn"
          :handleError="handleError"
          type="number"
          :maxlength="11"
        />
      </div>
      <div class="col-sm-6">
        <label class="input-item-label">Title</label>
        <div class="d-flex">
          <label class="rdiobox mr-4">
            <input
              type="radio"
              :value="'Mr'"
              v-model="request.title"
              @blur="$v.request.title.$touch()"
            />
            <span class="fs-15">Mr.</span>
          </label>

          <label class="rdiobox mr-4">
            <input
              type="radio"
              :value="'Mrs'"
              v-model="request.title"
              @blur="$v.request.title.$touch()"
            />
            <span class="fs-15">Mrs.</span>
          </label>

          <label class="rdiobox">
            <input
              type="radio"
              :value="'Miss'"
              v-model="request.title"
              @blur="$v.request.title.$touch()"
            />
            <span class="fs-15">Miss.</span>
          </label>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label class="input-item-label">Gender</label>
        <div class="d-flex">
          <label class="rdiobox mr-4">
            <input
              type="radio"
              :value="'M'"
              v-model="request.gender"
              @blur="$v.request.gender.$touch()"
            />
            <span class="fs-15">Male</span>
          </label>

          <label class="rdiobox">
            <input
              type="radio"
              :value="'F'"
              v-model="request.gender"
              @blur="$v.request.gender.$touch()"
            />
            <span class="fs-15">Female </span>
          </label>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label class="input-item-label">Account Type</label>
        <div class="d-flex">
          <label class="rdiobox mr-4">
            <input type="radio" :value="false" v-model="request.business" />
            <span class="fs-15">Individual</span>
          </label>

          <label class="rdiobox">
            <input type="radio" :value="true" v-model="request.business" />
            <span class="fs-15">Business </span>
          </label>
        </div>
      </div>
      <div class="col-sm-6" v-if="request.business">
        <CustomInput
          :name="'name'"
          v-model="request.name"
          :label="'Business Name'"
          :placeholder="'Enter name'"
          :required="true"
          :error="formErrors.name"
          :handleError="handleError"
        />
      </div>
      <div class="col-sm-6" v-if="request.business">
        <CustomInput
          :name="'rc_number'"
          v-model="request.rc_number"
          :label="' RC Number'"
          :placeholder="'RC1234567'"
          :required="true"
          :error="formErrors.rc_number"
          :handleError="handleError"
        />
      </div>

      <template v-if="request.business">
        <div class="col-sm-12">
          <CustomInput
            :name="'address'"
            v-model="request.address_one"
            :label="'Business Address 1'"
            :placeholder="'Enter Business Address 1'"
            :error="formErrors.address_one"
            :handleError="handleError"
            :required="true"
          />
        </div>

        <div class="col-sm-12">
          <CustomInput
            :name="'address'"
            v-model="request.address_two"
            :label="'Business Address 2'"
            :placeholder="'Enter Business Address 2'"
            :error="formErrors.address_two"
            :handleError="handleError"
          />
        </div>

        <div class="col-md-6">
          <CustomSelect
            name="country"
            label="Country"
            placeholder="Select a country"
            :optionLabel="'name'"
            :options="metaData.countries"
            v-model="request.country"
            :required="true"
            :error="formErrors.country"
            :handleError="handleError"
            @input="changeState"
          />
        </div>

        <!-- State field -->
        <div class="col-md-6">
          <CustomSelect
            name="state"
            label="State"
            placeholder="Select a state"
            :optionLabel="'state_label'"
            :options="states"
            v-model="request.state"
            :required="true"
            :error="formErrors.state"
            :disabled="!request.country"
            :handleError="handleError"
          />
        </div>

        <div class="col-md-6">
          <CustomInput
            :name="'city'"
            v-model="request.city"
            :label="'City'"
            :placeholder="'Enter your city'"
            :error="formErrors.city"
            :required="true"
          />
        </div>

        <div class="col-md-6">
          <CustomInput
            :name="'zip_code'"
            v-model="request.zip_code"
            :label="'Postal Code'"
            :placeholder="'Enter your postal code'"
            :error="formErrors.zip_code"
          />
        </div>
      </template>

      <div class="col-12">
        <FormButtons :loading="loading" :handleCloseModal="handleCloseModal" />
      </div>
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import FormButtons from "../../../../../utils/FormButtons/FormButtons";
import Spinner from "../../../../../utils/Spinner/Spinner";
import { getWalletApikey } from "../../../../../utils/helpers";
import CustomSelect from "@/utils/CustomSelect/CustomSelect.vue";

const rcFormat = (value) => {
  return /^RC\d{7}$/i.test(value);
};

const defaultValue = () => ({
  request: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    title: "",
    bvn: "",
    gender: "M",
    business: false,
    rc_number: "",
    address_one: "",
    address_two: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    name: "",
  },
  formErrors: {},
  successMsg: false,
});

export default {
  mixins: [notifications],
  name: "AddCustomerModal",
  data() {
    return {
      ...defaultValue(),
      loading: false,
    };
  },
  validations: {
    request: {
      phone: {
        isPhoneValid: isPhoneValid("request.phone"),
      },
    },
  },
  props: ["id", "closeModal", "refetch", "customerRef"],
  components: {
    Modal,
    CustomInput,
    SuccessState,
    Spinner,
    FormButtons,
    CustomPhoneInput,
    CustomSelect,
  },
  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
  },
  methods: {
    formVuelidator,
    changeState() {
      this.request.state = [];
      this.states = this.request.country.states;
    },
    handleCloseModal() {
      Object.assign(this.$data, { ...defaultValue() });
      this.closeModal();
    },
    handleRefetch() {
      this.handleCloseModal();
      this.customerRef && this.customerRef.refresh();
      this.refetch && this.refetch(this.customer);
    },
    handleSubmit() {
      let payload = { ...this.request };
      !payload.bvn && delete payload.bvn;
      !payload.title && delete payload.title;
      payload.phone && (payload.phone = payload.phone.number);

      if (!payload.business) {
        delete payload.rc_number;
        delete payload.address_one;
        delete payload.address_two;
        delete payload.city;
        delete payload.country;
        delete payload.state;
        delete payload.zip_code;
        delete payload.name;
      }

      if (payload.rc_number) {
        if (!rcFormat(payload.rc_number)) {
          this.formErrors = {
            ...this.formErrors,
            rc_number: "Invalid RC Number",
          };
          return false;
        }
      }

      if (payload.bvn && payload.bvn.length !== 11) {
        this.formErrors = {
          ...this.formErrors,
          bvn: "BVN must contain 11 digits",
        };
        return false;
      }

      !payload.address_two && delete payload.address_two;
      !payload.zip_code && delete payload.zip_code;

      const { formErrors, isValid } = formValidator(payload);
      if (isValid) this.createCustomer(payload);
      else {
        this.formErrors = formErrors;
      }
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    async createCustomer(payload) {
      const newPayload = payload;
      if (newPayload.business) {
        newPayload.business_details = {
          rc_number: newPayload.rc_number,
          name: newPayload.name,
          address: newPayload.address_one,
        };

        newPayload.country = newPayload.country.name;
        newPayload.state = newPayload.state.state_label;

        !newPayload.address_two && delete newPayload.address_two;
        !newPayload.zip_code && delete newPayload.zip_code;

        delete newPayload.name;
        delete newPayload.rc_number;
      }

      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "customers/createCustomer",
          {
            apiKey: getWalletApikey(this.$store),
            request: { ...newPayload, phone: this.request.phone.number },
          }
        );
        const { status, data } = response || {};
        if (status === 200 && data.status) {
          this.loading = false;
          this.successMsg = true;
          this.customer = data.data;
        } else {
          this.loading = false;
          this.showError(data.message);
        }
      } catch (error) {
        this.loading = false;
        this.showError(error.response.data.message);
      }
    },
    showError(message) {
      this.loading = false;
      this.showErrorLoginNotification(message);
    },
  },
};
</script>
