<template>
  <div id="editAccountService" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-editAccountService"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Update Account Services</h5>
          <p>Let's get you all setup editing your app service(s).</p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="updateServices">
            <!-- CLient ID -->
            <div class="form-group">
              <label class="input-item-label">Client ID</label>
              <input
                type="text"
                disabled
                class="form-control input-bordered"
                :placeholder="clientID"
              />
            </div>

            <!-- Application Services -->
            <div class="form-group">
              <label class="input-item-label">Services</label>
              <v-select
                class="v-select"
                placeholder="Select a service"
                v-model="modalData.customerServices"
                @keydown.enter.native.prevent
                :options="userServices"
                label="service_label"
                multiple
                required
              ></v-select>
            </div>
            <div class="gaps-2x"></div>
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              :disabled="isProcessing == true"
            >
              <span v-if="isProcessing">...editing account services</span>
              <span v-else>Update account Service(s)</span>
            </button>
            <button
              type="button"
              class="btn btn-lg btn-light ml-2"
              data-dismiss="modal"
            >
              Close
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
  mixins: [notifications],
  props: {
    modalData: {
      type: Object,
    },
  },
  data() {
    return {
      isProcessing: false,
    };
  },
  computed: {
    userServices() {
      return this.$store.state.metaData.services;
    },
    metaData() {
      return this.$store.state.metaData;
    },
    clientID() {
      return this.$store.state.account.clientID;
    },
  },
  methods: {
    formatEditApplicationData() {
      let requestData = new FormData();

      if (this.modalData.customerServices) {
        for (
          let index = 0;
          index < this.modalData.customerServices.length;
          index++
        ) {
          requestData.append(
            "services",
            this.modalData.customerServices[index].id
          );
        }
      }
      return requestData;
    },
    closeModalSuccess() {
      let closeButton = document.getElementById("close-editAccountService");
      closeButton.click();

      this.$emit("refetchServices");
    },
    async updateServices() {
      this.isProcessing = true;
      try {
        let requestPayload = this.formatEditApplicationData();
        let application = await this.$store.dispatch(
          "account/editAccountServices",
          { clientid: this.clientID, payload: requestPayload }
        );
        this.showSuccessNotification(application.message);
        let self = this;
        setTimeout(() => {
          self.closeModalSuccess();
          self.isProcessing = false;
        }, 2500);
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false;
        console.log("[error] ->", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
};
</script>