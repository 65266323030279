<template>
	<div>
		<div v-if="inv" class="merchant_logo_form" @click="openAddLogo">
			<img src="/assets/img/icons/cloud.svg" alt="" class="mr-2" />
			<div>
				<p>Add your company logo</p>
				<span
					>Upload your company logo to customize your invoice.
					Recommended size 100 x 100 (.png, .jpg)</span
				>
			</div>
		</div>
		<template v-else>
			<img
				v-if="useIcon"
				@click="openAddLogo"
				src="/assets/img/icons/edit.png"
				alt="Edit logo"
				width="25px"
				class="pointer"
			/>

			<button
				v-else
				type="button"
				class="btn btn-sm btn--primary px-4 ml-3"
			>
				{{ logo ? "Update Logo" : "Add Logo" }}
			</button>
		</template>

		<AddLogoModal
			:id="'addLogo'"
			:inv="inv"
			:closeModal="closeModal"
			:setLogo="setLogo"
		/>
	</div>
</template>

<script>
import AddLogoModal from "../../modals/AddLogoModal/AddLogoModal";

export default {
	name: "AddLogoButton",
	props: ["logo", "inv", "setLogo", "useIcon"],
	methods: {
		openAddLogo() {
			$("#addLogo").modal("show");
		},
		closeModal() {
			$("#addLogo").modal("hide");
		},
	},
	components: {
		AddLogoModal,
	},
};
</script>
