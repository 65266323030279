<template>
	<div class="user-settings-section border-0">
		<div class="user-prof">
			<img :src="userImage" alt="" class="rounded" />
		</div>
		<div class="invoice-info mt-4">
			<p class="invoice-info-row preview-row">
				<span>Firstname</span>
				<span>{{
					result.personal_info.first_name ||
						"Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Lastname</span>
				<span>{{
					result.personal_info.last_name ||
						"Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Middlename</span>
				<span>{{
					result.personal_info.middle_name ||
						"Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Gender</span>
				<span>{{
					result.personal_info.gender || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Email</span>
				<span
					>{{
						result.personal_info.email ||
							"Information not available"
					}}
				</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>BVN</span>
				<span>{{
					result.bvn_number || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>NIN</span>
				<span>{{ result.nin || "Information not available" }}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Date of Birth</span>
				<span>{{
					result.personal_info.date_of_birth ||
						"Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Phone Number</span>
				<span>{{
					result.personal_info.phone_number ||
						"Information not available"
				}}</span>
			</p>
		</div>
	</div>
</template>

<script>
	import "./AdvancedBankAccountVerificationResult.css";

	export default {
		name: "AdvancedBankAccountVerificationResult",
		props: ["result"],
		computed: {
			userImage() {
				return (
					this.result.personal_info.image_url ||
					"/assets/img/avatar1.svg"
				);
			},
		},
	};
</script>
