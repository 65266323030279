import trails from "@/services/wallets/subscriptions/trails";

const handleError = (error) => {
	if (!error.response) {
		throw Error("Network Error, Please check your network and retry");
	} else {
		throw error.response.data;
	}
};

export default {
	namespaced: true,
	actions: {
		async getAllTrails({}, payload) {
			try {
				return await trails.getAllTrails(payload);
			} catch (error) {
				handleError(error);
			}
		},
		async getTrail({}, payload) {
			try {
				return await trails.getTrail(payload);
			} catch (error) {
				handleError(error);
			}
		},
	},
};
