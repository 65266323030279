<template>
  <div class="px-3">
    <ContactPersons :details="details" />
    <div class="mt-5" :class="tbClass">
      <div class="p-2 bg-light-blue mb-2">Key Personnel</div>
      <v-server-table
        ref="shareHoldersRef"
        class="v-table"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @error="setError"
      >
        <template slot="action" slot-scope="{ row }">
          <button @click="showData({ row })" class="btn btn--primary">
            More Details
          </button>
        </template>
      </v-server-table>
    </div>
    <OwnershipModal
      v-if="showModal"
      :data="modalData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import OwnershipModal from "../Ownership/OwnershipModal";
import ContactPersons from "./ContactPersons/ContactPersons";

const tableHeadings = {
  name: "Name",
  designation: "Designation",
  action: "Action",
};

export default {
  name: "Personnel",
  mixins: [componentData],
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...this.tableOptions(tableHeadings, 20),
    };
  },
  components: { OwnershipModal, ContactPersons },
  computed: {
    details() {
      return this.$store.state.services.reportDetails;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    handlClick({ row }) {
      this.$router.push(`/services/reports/${row.id}/ownership`);
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getShareHolders();
      this.$refs.shareHoldersRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.shareHoldersRef.refresh();
    },
    async getShareHolders() {
      this.options.requestFunction = async (data) => {
        try {
          const parsedData = JSON.parse(this.details.key_personnel);
          const resp = {
            total: parsedData.length,
            count: parsedData.length,
            data: parsedData,
            totalPages: length,
            currentPage: 1,
          };
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
  },

  created() {
    this.getShareHolders();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
