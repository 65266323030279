<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreatePaymentDisputeModal"
		>
			Create Payment Dispute
		</button>
		<CreatePaymentDisputeModal
			:id="'createPaymentDispute'"
			:closeModal="closeModal"
			:disputeRef="disputeRef"
		/>
	</div>
</template>

<script>
	import CreatePaymentDisputeModal from "../../modals/CreatePaymentDisputeModal/CreatePaymentDisputeModal";

	export default {
		name: "CreatePaymentDisputeButton",
		props: ["disputeRef"],
		methods: {
			openCreatePaymentDisputeModal() {
				$("#createPaymentDispute").modal("show");
			},
			closeModal() {
				$("#createPaymentDispute").modal("hide");
			},
		},
		components: {
			CreatePaymentDisputeModal,
		},
	};
</script>
