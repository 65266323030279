<template>
	<div class="wrapper">
		<TableWrapper :loading="isLoading" :errorMsg="errorMsg" :hideBtn="true">
			<div slot="content" class="flex__center mt-4">
				<div class="text-center">
					<img
						src="/assets/img/icons/success.svg"
						alt=""
						width="50px"
					/>

					<h5 class="pt-3 mb-0" v-html="successMsg" />
				</div>
			</div>
		</TableWrapper>
	</div>
</template>

<script>
import { componentData } from "../../public/assets/mixins/componentData";
import Nprogress from "nprogress";
import { getError } from "@/utils/helpers";

export default {
	name: "CustomerEmailVerification",
	mixins: [componentData],
	data() {
		return {
			isLoading: true,
			errorMsg: "",
			successMsg: "",
		};
	},

	methods: {
		async verifyCustomerEmail() {
			const { query } = this.$route;
			const { confirmation_token, email } = query || {};

			if (confirmation_token && email) {
				Nprogress.start();
				this.errorMsg = "";
				this.isLoading = true;
				try {
					let response = await this.$store.dispatch(
						"auth/customerEmailVerification",
						{ email, confirmation_token }
					);

					console.log("response", response);

					const { status, data } = response;
					if (status === 200 || status === 201) {
						this.successMsg = data.message;
					} else {
						this.errorMsg =
							"Invalid email or token was provided or has expired";
					}
					Nprogress.done();
					this.isLoading = false;
				} catch (error) {
					Nprogress.done();
					this.isLoading = false;
					this.errorMsg = getError(error.response);
				}
			} else
				this.errorMsg =
					"Invalid email or token was provided or has expired";
		},
	},
	async mounted() {
		this.verifyCustomerEmail();
	},
};
</script>

<style>
.wrapper {
	padding: 20px;
	border-radius: 10px;
	border: none;
	box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
	max-width: 400px;
	margin: 5vh auto;
}
</style>
