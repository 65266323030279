<template>
	<div>
		<img
			@click="opendeleteCouponModal"
			class="pointer"
			src="/assets/img/icons/trash.svg"
			alt="Delete Coupon"
		/>

		<DeleteCouponModal
			:id="'deleteCoupon'"
			:closeModal="closeModal"
			:couponRef="couponRef"
			:couponCode="couponCode"
		/>
	</div>
</template>

<script>
	import DeleteCouponModal from "../../modals/DeleteCouponModal/DeleteCouponModal";

	export default {
		name: "DeleteCouponButton",
		props: ["couponRef", "couponCode"],
		methods: {
			opendeleteCouponModal() {
				$("#deleteCoupon").modal("show");
			},
			closeModal() {
				$("#deleteCoupon").modal("hide");
			},
		},
		components: {
			DeleteCouponModal,
		},
	};
</script>
