<template>
	<div>
		<v-client-table
			class="v-table items__table"
			:data="allItems"
			:columns="columns"
			:options="options"
		/>

		<InvoiceTotalAnalysis
			:payload="invoice"
			:items="this.invoice.line_items"
			:hideForm="true"
		/>
	</div>
</template>

<script>
import { filters } from "../../../../../../public/assets/mixins/filters";
import InvoiceTotalAnalysis from "../../common/InvoiceTotalAnalysis/InvoiceTotalAnalysis";
import { newInvoiceItemsHeadings } from "../../enums";

export default {
	mixins: [filters],
	name: "PreviewItemsTable",
	data() {
		return {
			...this.tableOptions(
				newInvoiceItemsHeadings(
					this.invoice.currency.code
						? this.invoice.currency.code
						: this.invoice.currency
				),
				5000,
				[],
				0
			),
		};
	},
	props: ["invoice"],
	components: {
		InvoiceTotalAnalysis,
	},
	computed: {
		allItems() {
			const items = this.invoice.line_items.reduce(
				(acc, { name, quantity }) => {
					if (name.reference)
						return [
							...acc,
							{
								name: name.name,
								amount: this.toCurrency(name.amount),
								quantity,
								total: this.toCurrency(
									name.amount * parseInt(quantity)
								),
							},
						];
					else return acc;
				},
				[]
			);

			return items;
		},
	},
	async mounted() {
		let currency = this.invoice.currency;
		currency = currency.code ? currency.code : currency;
		this.columns = Object.keys(newInvoiceItemsHeadings(currency)).map(
			(keyVal) => keyVal
		);
	},
};
</script>
