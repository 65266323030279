<template>
	<div @click="handleClick" class="table-modal-wrapper transaction">
		<div ref="modal" class="table-modal">
			<div style="text-align: right" class="p-3">
				<i
					style="cursor: pointer;"
					@click="closeModal"
					class="far fa-times-circle"
				></i>
			</div>
			<p class="badge custom-badge" @click="copy">
				Transaction Reference: <b>{{ data.reference }}</b>
			</p>

			<div
				class="p-4 border-top"
				style="margin-bottom: 10px; display: flex; flex-direction:column; justify-content: center; "
			>
				<h4>Payment Information</h4>
				<div class="content">
					<div class="information">
						<p>Amount</p>
						<span>{{ toCurrency(data.amount) }}</span>
					</div>
					<div class="information">
						<p>Transaction Date</p>
						<span>
							{{
								timeDateFormat(data.createdAt).date +
									" " +
									timeDateFormat(data.createdAt).time
							}}
						</span>
					</div>
					<div class="information">
						<p>Transaction Narration</p>
						<span>{{ data.narration }}</span>
					</div>
					<div v-if="data.account_number" class="information">
						<p>Account Number</p>
						<span>{{ data.account_number }}</span>
					</div>
					<div v-if="data.account_name" class="information">
						<p>Account Name</p>
						<span>{{ data.account_name }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import {
	titleCase,
	timeDateFormat,
	toCurrency,
	copyToClipboard,
} from "@/utils/helpers";

export default {
	mixins: [notifications],
	props: {
		data: {
			type: Object,
		},
	},

	methods: {
		titleCase,
		toCurrency,
		timeDateFormat,
		handleClick(e) {
			if (!e.target.closest(".table-modal")) {
				return this.$emit("close-modal");
			}
		},
		closeModal(e) {
			this.$emit("close-modal");
		},
		copy() {
			const copied = copyToClipboard(this.data.reference);
			copied &&
				this.showSuccessNotification("Transaction Reference Copied!");
		},
	},
};
</script>
