<template>
  <Modal
    :id="id"
    :title="'Update Company Documents'"
    :toggle="handleCloseModal"
  >
    <SuccessState
      v-if="successMsg"
      :message="'Documents Updated Successfully'"
      :onSuccess="handleSuccessCloseModal"
    />
    <form v-else @submit.prevent="handleSubmit" class="row h-100">
      <div class="col-sm-6">
        <CustomUpload
          :name="'certificate_of_incorporation'"
          :label="'Certificate of Incorporation'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="(e) => handleUpload('certificate_of_incorporation', e)"
          :fileName="
            request.certificate_of_incorporation &&
            request.certificate_of_incorporation.name
          "
          :error="
            uploadError.certificate_of_incorporation &&
            'File size must be less than 5MB'
          "
        />
      </div>
      <div class="col-sm-6">
        <CustomUpload
          :name="'form_cac'"
          :label="'CAC Form / Business Certificate'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="(e) => handleUpload('form_cac', e)"
          :fileName="request.form_cac && request.form_cac.name"
          :error="uploadError.form_cac && 'File size must be less than 5MB'"
        />
      </div>
      <div class="col-sm-6">
        <CustomUpload
          :name="'memorandum_articles_of_association'"
          :label="'Memorandum of Association'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="
            (e) => handleUpload('memorandum_articles_of_association', e)
          "
          :fileName="
            request.memorandum_articles_of_association &&
            request.memorandum_articles_of_association.name
          "
          :error="
            uploadError.memorandum_articles_of_association &&
            'File size must be less than 5MB'
          "
        />
      </div>
      <div class="col-sm-6">
        <CustomUpload
          :name="'proof_of_address'"
          :label="'Proof of Address'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="(e) => handleUpload('proof_of_address', e)"
          :fileName="request.proof_of_address && request.proof_of_address.name"
          :error="
            uploadError.proof_of_address && 'File size must be less than 5MB'
          "
        />
      </div>

      <div class="col-sm-6">
        <CustomUpload
          :name="'tin_document'"
          :label="'TIN Document'"
          :validFileTypes="['.jpg', '.jpeg', '.png',]"
          :handleChange="(e) => handleUpload('tin_document', e)"
          :fileName="request.tin_document && request.tin_document.name"
          :error="uploadError.tin_document && 'File size must be less than 5MB'"
        />
      </div>

      <div class="col-12">
        <div class="flex__between pt-4">
          <button
            type="button"
            class="btn btn-outline w-100 mr-3"
            :disabled="loading"
            @click="handleCloseModal"
          >
            <span>Close</span>
          </button>
          <button
            type="submit"
            class="btn btn-lg w-100 ml-3 btn--primary"
            :disabled="loading"
          >
            <Spinner v-if="loading" :color="'#fff'" />
            <span v-else>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "@/utils/Modal/Modal.vue";
import Spinner from "@/utils/Spinner/Spinner.vue";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";
import SuccessState from "@/utils/ResponseState/SuccessState.vue";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import { notifications } from "../../../../../../public/assets/mixins/notifications";

const initialState = {
  request: {
    certificate_of_incorporation: "",
    form_cac: "",
    memorandum_articles_of_association: "",
    proof_of_address: "",
    tin_document: "",
  },
  uploadError: {
    certificate_of_incorporation: false,
    form_cac: false,
    memorandum_articles_of_association: false,
    proof_of_address: false,
    tin_document: false,
  },
  loading: false,
  successMsg: false,
};

export default {
  props: ["id", "closeModal"],
  mixins: [notifications],
  data() {
    return {
      ...initialState,
    };
  },
  components: { CustomUpload, SuccessState, Modal, Spinner },
  methods: {
    formVuelidator,
    async handleUpload(name, { target: { files } }) {
      const file = files[0];
      const dataSize = 1024 * 1024;

      if (file.size / dataSize < 5) {
        this.request[name] = file;
        this.uploadError[name] = false;
      } else {
        this.uploadError[name] = true;
      }
    },
    handleSuccessCloseModal() {
      this.$emit("refresh");
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    handleCloseModal() {
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    async handleSubmit() {
      const isValid = !Object.values(this.request).every(
        (file) => file == null || file == ""
      );

      if (!isValid) {
        this.showErrorLoginNotification("Upload at least one document");
        return;
      }

      try {
        this.loading = true;
        const payload = new FormData();

        Object.entries(this.request).map(([key, value]) => {
          if (value) payload.append(key, value);
        });

        const response = await this.$store.dispatch(
          "account/addCompliance",
          payload
        );

        if (response.status) {
          this.loading = false;
          this.successMsg = true;
        }
      } catch (error) {
        this.loading = false;
        this.showErrorLoginNotification(error.message);
      }
    },
  },
};
</script>
