<template>
	<div>
		<div class="col-12">
			<transition mode="out-in" name="fade">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									Virtual NIN (vNIN) Request form
								</h2>
								<h5 class="user-profile-email sub">
									Verify vNIN of various users
								</h5>
							</div>
						</div>
						<div class="content">
							<PricesAndAccess
								serviceLabel="(vNIN) Virtual National Identity Number"
								:getServiceStatus="getServiceStatus"
							/>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="verifyVNIN">
							<div class="row mt-4">
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label">
											Virtual NIN
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
										</label>
										<input
											type="text"
											v-model="vnin"
											@blur="$v.vnin.$touch()"
											class="form-control input-bordered"
											placeholder="vNIN"
										/>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.vnin.$error"
										>
											<li class="parsley-required">
												Virtual NIN is required.
											</li>
										</ul>
									</div>
								</div>
								<div class="col-md-6">
									<CustomPhoneInput
										:onlyCountries="['NG']"
										label="Phone Number"
										v-model="phone_number"
										@blur="$v.phone_number.$touch()"
										placeholder="Enter your phone number"
										:error="
											$v.phone_number.$error &&
											formVuelidator($v.phone_number)
										"
									/>
								</div>

								<div class="col-md-12">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@close="$v.app.$touch()"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<!-- Currency Type  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select Currency Type
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Currency Type"
										v-model="currencyType"
										@keydown.enter.native.prevent
										:disabled="!customerWallets"
										:options="customerWallets"
										label="currency"
									></v-select>
								</div>

								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
										:disabled="
											isSubmitting == true || !hasService
										"
									>
										<span v-if="isSubmitting"
											>...Verifying vNIN</span
										>
										<span v-else>Verify vNIN</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="user-settings-section" v-if="hasNewResponse">
					<div class="user-settings-section-header">
						<div class="user-settings-section-header-text">
							<h1>Virtual NIN Request Response</h1>
							<span class="text-span"
								>Preview of the information gotten from Virtual
								NIN request made</span
							>
						</div>
						<a
							href="#"
							class="btn btn-outline-danger"
							@click="hasNewResponse = false"
						>
							<i class="la la-close"></i>Close Panel
						</a>
					</div>

					<div class="preview-content">
						<div class="invoice-info-row preview-row">
							<div class="nin-image">
								<img
									width="200px"
									height="200px"
									:src="
										verificationResult.personal_info
											.image_url
									"
									:alt="`image for ${verificationResult.personal_info.first_name}`"
								/>
							</div>
						</div>
						<p class="invoice-info-row preview-row">
							<span>Firstname</span>
							<span>{{
								verificationResult.personal_info.first_name
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Lastname</span>
							<span>{{
								verificationResult.personal_info.last_name
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Middle Name</span>
							<span>{{
								verificationResult.personal_info.middle_name ||
								"Not Available"
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>vNIN</span>
							<span>{{ verificationResult.vnin }}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Gender</span>
							<span>{{
								verificationResult.personal_info.gender
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Date of Birth</span>
							<span>{{
								verificationResult.personal_info
									.formatted_date_of_birth
							}}</span>
						</p>
						<p class="invoice-info-row preview-row">
							<span>Phone number</span>
							<span>{{
								verificationResult.personal_info.phone_number
							}}</span>
						</p>
					</div>
				</div>
			</transition>

			<hr />
		</div>

		<div class="row">
			<div class="col-12">
				<div class="user-details-info">
					<div class="user-details--top-info">
						<h2 class="user-profile-name">API Request Format</h2>
						<h5 class="user-profile-email sub">
							Representation of how the API call can be made with
							the appropriate data necessary
						</h5>
					</div>
				</div>
			</div>

			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:35%;">End-Point</label><code>
            'POST': '{{API}}IdentityVerification/vNIN',
      </code></pre>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:42%;">Header</label><code>
            {
              'clientid': '{{clientID}}',
              'appname': '{{ app ? app.app_name : 'app_name'  }}',
              'apikey': '{{ app ? app.api_key : 'app_key'  }}',
            }
      </code></pre>
			</div>
			<div class="col-12">
				<pre
					class="code code-html"
				><label style="top:42%;">Body</label><code>
            {
              "phone_number": "{{phone_number.formatted ? getPhoneNumber(phone_number) : "phonenumber"}}"
              "vnin": "{{vnin ? vnin : "vnin"}}"
            }
     </code></pre>
			</div>
			<div class="col-12">
				<pre class="code code-html"><label>Sample Response</label><code>
        {{apiResponse ? apiResponse : `{
    "status_code": 200,
    "message": "User Info Retrieved",
    "status": "found",
    "results": {
        "request_reference": "NenM4h290823220",
        "vnin": "AY449929365463KF",
        "service_type": "vNIN",
        "personal_info": {
            "first_name": "JOHN",
            "middle_name": "",
            "last_name": "DOVE",
            "gender": "male",
            "phone_number": "07012345678",
            "date_of_birth": "1960-10-20",
            "formatted_date_of_birth": "October 20,60",
            "image_url": "https://dev-api.blusalt.net/_/cepXXXXX"
        }
    }
}`}}
      </code></pre>
			</div>
		</div>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		></ApplicationInitializationModal>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import PricesAndAccess from "@/utils/PricesAndAccess/PricesAndAccess";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { getError, getPhoneNumber } from "@/utils/helpers";

export default {
	props: ["application"],
	mixins: [notifications, modalMixin],
	validations: {
		app: { required },
		vnin: { required },
		phone_number: {
			isPhoneValid: isPhoneValid("phone_number"),
		},
	},
	components: {
		PricesAndAccess,
		CustomPhoneInput,
	},
	data() {
		return {
			phone_number: "",
			vnin: "",
			initializedAppResult: {},
			call_id: "",
			clientID: "",
			app: "",
			apikey: "",
			appname: "",
			phonenumber: "",
			hasNewResponse: false,
			apiResponse: "",
			customerWallets: this.$store.state.account.customerWallets,
			currencyType: "",
			hasService: false,
			servicePrice: "",

			isSubmitting: false,
			verificationResult: [],
		};
	},
	computed: {
		API() {
			return this.$store.state.appUrlV2;
		},
	},
	methods: {
		getPhoneNumber,
		formVuelidator,
		getServiceStatus(hasService) {
			this.hasService = hasService;
		},
		computeService() {
			if (this.services) {
				this.services.filter((service) => {
					if (service.service_label.includes("pNIN")) {
						this.hasService = true;
						this.servicePrice = service.pivot.price_per_call;
					}
				});
			}
		},
		getRequestData() {
			if (!this.hasBundleBilling) {
				this.call_id = "";
			}

			const payload = {
				vnin: this.vnin,
				currency_type: this.currencyType.currency,
			};

			if (this.phone_number) {
				payload.phone_number = `0${this.phone_number.nationalNumber}`;
			}

			let verificationData = {
				clientID: this.clientID,
				appname: this.app.app_name,
				apikey: this.app.api_key,
				payload: { ...payload },
				query: this.call_id,
			};

			return verificationData;
		},
		async verifyVNIN() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			let verificationData = this.getRequestData();
			this.isSubmitting = true;

			try {
				let apiResponse = await this.$store.dispatch(
					"services/verifyVNIN",
					verificationData
				);
				this.apiResponse = apiResponse;
				if (apiResponse.status.toLowerCase() == "not found") {
					throw { message: apiResponse.status };
				}
				this.showSuccessNotification(apiResponse.message);
				this.verificationResult = apiResponse.results;
				this.hasNewResponse = true;
				this.isSubmitting = false;
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSubmitting = false;
				this.showErrorLoginNotification(getError(error.message));
			}
		},
	},
	mounted() {
		this.computeService();

		this.clientID = this.$store.state.account.clientID;
	},
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
	},
	mounted() {
		this.baseUrl = process.env.VUE_APP_BASE_URL_CORE;
		this.clientID = this.$store.state.account.clientID;
	},
};
</script>
