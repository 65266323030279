<template>
	<div class="debit-card" :style="{ backgroundImage: `url('${image}')` }">
		<div class="flex__between">
			<h5 class="font-weight-light">Debit</h5>
			<h5 class="font-italic font-weight-bold">VISA</h5>
		</div>
		<h5 class="IBM">4556 5642 0695 5168</h5>

		<div class="d-flex align-items-center font-weight-light py-2">
			<p class="text-left pr-3">
				<span class="d-block">VALID</span>
				<span>TILL</span>
			</p>
			<h5>07/23</h5>
		</div>
		<h5 class="text-left">OMOJUGBA OLAWALE</h5>
	</div>
</template>

<script>
	import "./DebitCard.css";

	export default {
		name: "DebitCard",
		data() {
			return {
				image: "/assets/img/card.png",
			};
		},
	};
</script>
