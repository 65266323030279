import { getWalletApikey } from "../../../utils/helpers";

export const intervals = [
	"hourly",
	"daily",
	"weekly",
	"monthly",
	"quarterly",
	"biannually",
	"yearly",
];

export const subscriptionCsvRows = {
	"metadata.plan.name": "Plan",
	"metadata.wallet.first_name": "First Name",
	"metadata.wallet.last_name": "Last Name",
	subscriptionType: "Subscription Type",
	amountAfter: "Amount(#)",
	startDate: "Date Created",
	renewalDate: "Renewal Date",
	active: "Active",
};

export const subscriptionTrailCsvRows = {
	"metadata.metadata.wallet.first_name": "First Name",
	"metadata.metadata.wallet.last_name": "Last Name",
	"metadata.metadata.plan.name": "Plan",
	title: "Title",
	description: "Description",
	createdAt: "Date Created",
	status: "Active",
};

export const getRequestData = (store, filters, data, size = 1000000) => {
	const requestData = {
		apiKey: getWalletApikey(store),
		params: {
			size,
			...data,
			...filters,
		},
	};
	return requestData;
};
