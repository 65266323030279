<template>
	<div class="card pt-3">
		<div class="row">
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<AccessControl>
								<CreateSubscriptionButton
									:subRef="$refs.subRef"
								/>
							</AccessControl>
						</template>
						<template slot="filterForm">
							<SubscriptionTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadSubscription"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="subRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="handleClick"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template slot="index" slot-scope="props">
						{{ props.index }}
					</template>
					<template slot="planRef" slot-scope="{ row }">
						{{ row.metadata.plan.name }}
					</template>
					<template slot="setup_amount" slot-scope="{ row }">
						<span></span>
						{{
							row.metadata.wallet
								? `${row.metadata.wallet.first_name} ${row.metadata.wallet.last_name}`
								: "Not Available"
						}}
					</template>
					<template slot="subscriptionType" slot-scope="{ row }">
						{{
							titleCase(row.subscriptionType) ||
							"------------------"
						}}
					</template>
					<template slot="amountAfter" slot-scope="{ row }">
						{{ row.metadata.plan ? row.metadata.plan.currency : " "
						}}{{ toCurrency(row.amountAfter, " ") }}
					</template>
					<template slot="startDate" slot-scope="{ row }">
						{{ timeDateFormat(row.startDate).date || "N/A" }}
					</template>
					<template slot="renewalDate" slot-scope="{ row }">
						{{ timeDateFormat(row.renewalDate).date || "N/A" }}
					</template>
					<template slot="active" slot-scope="{ row }">
						<span :class="getStatus(row.active).style">
							{{ getStatus(row.active).value }}</span
						>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../public/assets/mixins/componentData";
import CreateSubscriptionButton from "../buttons/CreateSubscriptionButton/CreateSubscriptionButton";
import SubscriptionTableFilters from "./SubscriptionTableFilters/SubscriptionTableFilters";
import { subscriptionCsvRows, getRequestData } from "../enums";

const tableHeadings = {
	index: "S/N",
	planRef: "Plan",
	setup_amount: "Subscriber",
	subscriptionType: "Subscription Type",
	amountAfter: "Amount",
	startDate: "Date Created",
	renewalDate: "Renewal Date",
	active: "Status",
};

export default {
	mixins: [notifications, componentData],
	name: "SubscriptionOverview",
	data() {
		return {
			loading: false,
			rLoading: false,
			errorMsg: "",
			size: 25,
			filters: {},
			...this.tableOptions(tableHeadings, 25),
		};
	},
	components: {
		CreateSubscriptionButton,
		SubscriptionTableFilters,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(`/wallet/subscription/${row.id}`);
		},
		getStatus(status) {
			return {
				value: status ? "Active" : "Not-Active",
				style: status ? "text-success" : "text-danger",
			};
		},
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			console.log("Error Most", error);
			this.errorMsg = error.message;
		},
		refetch(filters) {
			const newFilters = Object.fromEntries(
				Object.entries(filters).filter(([_, v]) => v != "" && v != null)
			);
			if (filters) this.filters = newFilters;
			this.getSubscriptions();
			this.$refs.subRef.refresh();
		},
		clearFilter() {
			this.filters = {};
			this.$refs.subRef.refresh();
		},

		async getSubscriptions() {
			this.options.requestFunction = async (data) => {
				const requestData = getRequestData(
					this.$store,
					this.filters,
					data,
					this.size
				);
				try {
					return (
						await this.$store.dispatch(
							"subscriptions/getAllSubscriptions",
							requestData
						)
					).data.data;
				} catch (error) {
					this.loading = false;
					this.errorMsg = this.getError(error);
				}
			};
		},
		async downloadSubscription() {
			this.rLoading = true;
			const request = getRequestData(this.$store, this.filters, {});

			const response = await this.$store.dispatch(
				"subscriptions/getAllSubscriptions",
				request
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				this.downloadReport(subscriptionCsvRows, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},

	created() {
		this.getSubscriptions();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
