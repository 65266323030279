<template>
  <Modal :id="id" :title="'Confirm Transfer Request'" :toggle="handleClose">
    <ContentWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="requestOtp"
    >
      <template slot="content">
        <form @submit.prevent="handleSubmit">
          <div class="d-flex justify-content-center">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator="-"
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
            />
          </div>
          <div class="flex__between pt-4">
            <button
              type="button"
              class="btn btn-outline w-100 mr-3"
              @click="closeModal"
            >
              <span>Close</span>
            </button>
            <button
              type="submit"
              class="btn btn-lg btn-primary w-100 ml-3"
              :disabled="otp.length < 6"
            >
              Submit
            </button>
          </div>
        </form>
      </template>
    </ContentWrapper>
  </Modal>
</template>

<script>
import Modal from "@/utils/Modal/Modal.vue";
import Spinner from "@/utils/Spinner/Spinner.vue";
import ContentWrapper from "@/utils/TableWrapper/ContentWrapper";

import { getWalletApikey } from "@/utils/helpers";
import { notifications } from "../../../../../public/assets/mixins/notifications";

const initialState = {
  loading: true,
  otp: "",
  errorMsg: "",
};

export default {
  components: { Modal, Spinner, ContentWrapper },
  mixins: [notifications],
  props: ["id", "user", "closeModal"],
  data() {
    return { ...initialState };
  },
  methods: {
    handleOnChange(value) {
      this.otp = value;
    },
    handleSubmit() {
      if (this.otp.length < 6) {
        return;
      }
      this.$emit("handleOtp", this.otp);
      Object.assign(this.$data, { ...initialState });

      this.closeModal();
    },
    handleClose() {
      Object.assign(this.$data, { ...initialState });
      this.closeModal();
    },
    async requestOtp() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "wallets/requestMerchantOtp",
          {
            headers: {
              "x-api-key": getWalletApikey(this.$store),
            },
          }
        );
        if (response.status === true) {
          this.loading = false;
          this.showSuccessNotification("An OTP has been sent to you");
        }
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.message;
      }
    },
  },
  mounted() {
    this.requestOtp();
  },
};
</script>
