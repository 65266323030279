<template>
	<div class="">
		<Tabs :tabNames="tabNames" :tabId="'customerTab'" />

		<div class="tab-content" id="myTabContent">
			<div
				class="tab-pane fade active show"
				id="Transactions"
				role="tabpanel"
				aria-labelledby="Transactions-tab"
			>
				<TransactionsTable :title="'Recent'" :params="tableParam" />
			</div>
			<CustomerSubscriptions />
			<CustomerBankAccounts />
			<CustomerInvoices />
      <CustomerAccountStatement />
		</div>
	</div>
</template>

<script>
import Tabs from "../../../../../utils/Tabs/Tabs";
import TransactionsTable from "../../../common/TransactionsTable/TransactionsTable";
import CustomerBankAccounts from "./CustomerBankAccounts/CustomerBankAccounts";
import CustomerSubscriptions from "./CustomerSubscriptions/CustomerSubscriptions";
import CustomerInvoices from "./CustomerInvoices/CustomerInvoices";
import CustomerAccountStatement from "./CustomerAccountStatement/CustomerAccountStatement";

const tabNames = ["Transactions", "Subscriptions", "Bank_account", "Invoices", "Account_Statement"];

export default {
	name: "CustomerDetialsContent",
	data() {
		return {
			tabNames,
			tableParam: {
				customer: this.$route.params.reference,
			},
		};
	},
	components: {
		Tabs,
		TransactionsTable,
		CustomerBankAccounts,
		CustomerSubscriptions,
		CustomerInvoices,
    CustomerAccountStatement
	},
};
</script>
