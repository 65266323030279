import axios from "axios";
let remittanceBaseUrl = process.env.VUE_APP_REMITTANCE_BASE_URL;

export default {
  getTransactionHistory({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/transactions`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getSenders({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/getCustomers`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getSenderTransactionHistory({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/customerTransaction`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getBeneficiaries({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/getBeneficiary`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getCustomerBeneficiaries({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/getCustomerBeneficiary`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getBeneficiaryTransaction({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/beneficiaryTransaction`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getProviders({ apiKey, params }) {
    return axios.get(`${remittanceBaseUrl}/getProviders`, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  addBeneficiary({ apiKey, payload }) {
    return axios.post(`${remittanceBaseUrl}/createBeneficiary`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  addCustomer({ apiKey, payload }) {
    return axios.post(`${remittanceBaseUrl}/createCustomer`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  createPayment({ apiKey, payload }) {
    return axios.post(`${remittanceBaseUrl}/payment`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  nameEnquiry({ apiKey, payload }) {
    return axios.post(`${remittanceBaseUrl}/nameEnquiry`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  getChargeFee({ apiKey, payload }) {
    return axios.post(`${remittanceBaseUrl}/charge-amount`, payload, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
};
