<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary--border px-4"
      @click="openSenderModal"
    >
      Send Money
    </button>
    <ConfrimPaymentModal
      :id="'sendMoney'"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
import ConfrimPaymentModal from "../../modal/ConfrimPaymentModal/ConfrimPaymentModal.vue";

export default {
  name: "SendMoneyButton",
  methods: {
    openSenderModal() {
      $("#sendMoney").modal("show");
    },
    closeModal() {
      $("#sendMoney").modal("hide");
    },
  },
  components: {
    ConfrimPaymentModal,
  },
};
</script>
