<template>
	<div>
		<h2
			v-if="useText"
			class="font-weight-bold text--primary m-0 pointer"
			@click="openCreateTaxModal"
		>
			+
		</h2>
		<button
			v-else
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreateTaxModal"
		>
			Create Tax
		</button>

		<CreateTaxModal
			:id="'createTax'"
			:closeModal="closeModal"
			:taxRef="taxRef"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import CreateTaxModal from "../../modals/CreateTaxModal/CreateTaxModal";

export default {
	name: "CreateTaxButton",
	props: ["taxRef", "useText", "refetch"],
	methods: {
		openCreateTaxModal() {
			$("#createTax").modal("show");
		},
		closeModal() {
			$("#createTax").modal("hide");
		},
	},
	components: {
		CreateTaxModal,
	},
};
</script>
