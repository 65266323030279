<template>
  <div
    class="tab-pane active fade show"
    id="Terminal_Information"
    role="tabpanel"
    aria-labelledby="Terminal_Information-tab"
  >
    <div class="row mt-4">
      <div class="col-md-6 mb-4">
        <ul class="sub_card h-100">
          <h5>Basic Information</h5>
          <li>
            <p>Terminal ID</p>
            <p>{{ virtualTerminalData.terminalInformation.serialNumber }}</p>
          </li>
          <li>
            <p>Terminal Name</p>
            <p>{{ virtualTerminalData.terminalInformation.terminalName }}</p>
          </li>
          <li>
            <p>Device</p>
            <p>{{ virtualTerminalData.terminalInformation.device }}</p>
          </li>
          <li>
            <p>Device OS</p>
            <p>{{ virtualTerminalData.terminalInformation.deviceOs }}</p>
          </li>
          <!-- <h5 class="mt-4 pt-4">Account Information</h5>
          <li>
            <p>Bank Name</p>
            <p>{{ virtualTerminalData.bankName }}</p>
          </li>
          <li>
            <p>Account Number</p>
            <p>{{ virtualTerminalData.accNum }}</p>
          </li>
          <li>
            <p>Account Name</p>
            <p>{{ virtualTerminalData.agentName }}</p>
          </li> -->
        </ul>
      </div>
      <div class="col-md-6">
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">
            {{ toCurrency(virtualTerminalData.totalAmount) }}
          </h2>
          <p class="call-name">Total Amount Procesed</p>
        </div>
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">{{ virtualTerminalData.totalCount }}</h2>
          <p class="call-name">Number of Transactions</p>
        </div>
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">
            {{ toCurrency(virtualTerminalData.totalCharge) }}
          </h2>
          <p class="call-name">Total Charge Value</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../../public/assets/mixins";

export default {
  name: "TerminalInformation",
  mixins: [componentData],
  data() {
    return {
      virtualTerminalData: null,
    };
  },
  methods: {
    async getTerminalInfo() {
      const payload = {
        apiKey: this.getWalletApikey(this.$store),
        params: {
          id: this.$route.params.id,
        },
      };
      let resp = await this.$store.dispatch("wallets/getTerminalInfo", payload);

      this.virtualTerminalData = resp.data;
    },
  },
  created() {
    this.getTerminalInfo();
  },
};
</script>
