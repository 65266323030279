<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openModal"
		>
			Edit
		</button>

		<EditCompanyModal
			:data="data"
			:id="'updateCompany'"
			:closeModal="closeModal"
			@refresh="$emit('refresh')"
		/>
	</div>
</template>

<script>
import EditCompanyModal from "../modals/EditCompanyModal.vue";

export default {
	props: ["data"],
	data() {
		return {};
	},
	methods: {
		openModal() {
			$("#updateCompany").modal("show");
		},
		closeModal() {
			$("#updateCompany").modal("hide");
		},
	},
	components: {
		EditCompanyModal,
	},
};
</script>
