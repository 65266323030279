<template>
	<div
		class="tab-pane fade"
		id="Bulk_transfer"
		role="tabpanel"
		aria-labelledby="Bulk_transfer-tab"
	>
		<div v-if="loading" class="row flex__center">
			<div class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>
		</div>
		<div v-else-if="step === 1" class="row">
			<div class="col-lg-9 col-sm-6">
				<InitiateBulkTransfer
					:handleNext="handleNext"
					:handleUpload="handleUpload"
				/>
			</div>
		</div>
		<TransferListTable
			v-else-if="step === 2"
			:accountFile="accountFile"
			:next="next"
			:resetTransfer="resetTransfer"
			:handleUpload="handleUpload"
			:updateAccountList="updateAccountList"
		/>
		<VerifiedAccountList
			v-else-if="step === 3"
			:verifiedAccount="verifiedAccount"
			:resetTransfer="resetTransfer"
			:goBack="goBack"
			:settings="settings"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import Spinner from "../../../utils/Spinner/Spinner";
import InitiateBulkTransfer from "./InitiateBulkTransfer/InitiateBulkTransfer";
import TransferListTable from "./TransferListTable/TransferListTable";
import VerifiedAccountList from "./VerifiedAccountList/VerifiedAccountList";
import {
	getWalletPublickey,
	getResponseStatus,
	getError,
} from "@/utils/helpers";
import { bulkTrans } from "./helper";
import "./BulkTransfer.css";

const initialState = () => ({
	step: 1,
	loading: false,
	accountFile: "",
});

export default {
	mixins: [notifications],
	name: "BulkTransfer",
	data() {
		return { step: 1, ...initialState() };
	},
	props: ["settings"],
	components: {
		InitiateBulkTransfer,
		TransferListTable,
		VerifiedAccountList,
		Spinner,
	},
	computed: {
		verifiedAccount() {
			return this.accountFile
				? this.accountFile.filter((acc) => acc.resolved === true)
				: [];
		},
	},
	methods: {
		getError,
		getResponseStatus,
		handleNext() {
			this.loading = true;
			this.accountFile = bulkTrans;
			this.step = 2;
			this.loading = false;
		},
		resetTransfer() {
			Object.assign(this.$data, { ...initialState() });
		},
		next() {
			this.verifiedAccount.length && (this.step += 1);
		},
		goBack() {
			this.step -= 1;
		},
		updateAccountList(accountFile) {
			this.accountFile = accountFile;
		},
		async handleUpload({ target: { files } }) {
			const file = files[0];
			if (file)
				if (["application/csv", "text/csv"].includes(file.type))
					this.verifiyAccounts(file);
				else this.showErrorLoginNotification("Inavlid CSV File");
		},
		async verifiyAccounts(file) {
			try {
				this.loading = true;

				const formData = new FormData();
				formData.append("file", file);

				const response = await this.$store.dispatch(
					"fundstransfer/verifyAccountsBulk",
					{
						formData,
						headers: {
							clientid:
								this.$store.state.account.clientID ||
								localStorage.getItem("local-ID"),
							publickey: getWalletPublickey(this.$store),
						},
					}
				);
				const success = getResponseStatus(response);

				if (success) {
					this.accountFile = response.data.data;
					this.step = 2;
				} else {
					this.accountFile = [];
					this.showErrorLoginNotification(
						response.data.message || "Error Verifying Accounts !"
					);
				}

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.accountFile = [];
				this.showErrorLoginNotification(getError(error));
			}
		},
	},
	mounted() {
		console.log("tets log", this.$store.state);
	},
};
</script>
