<template>
	<div
		class="tab-pane fade"
		id="Subscriber_Information"
		role="tabpanel"
		aria-labelledby="Subscriber_Information-tab"
	>
		<div class="card overflow-hidden mt-3">
			<div class="row py-3 align-items-center">
				<div class="col-sm-4 text-center border-right ">
					<p class="call-name pt-2">Name</p>
					<h4>{{ wallet.first_name }} {{ wallet.last_name }}</h4>
				</div>

				<div class="col-sm-4 text-center border-right ">
					<p class="call-name pt-2">Account Details</p>
					<h4 class="">
						<p class="mb-2">{{ titleCase(wallet.account_name) }}</p>
						<p class="mb-2">{{ wallet.account_number }}</p>
						({{ wallet.bank_name }})
					</h4>
				</div>

				<div class="col-sm-4 text-center ">
					<p class="call-name pt-2">Contacts</p>
					<h4>
						{{ wallet.email }}
						<p class="pt-2">{{ wallet.phone }}</p>
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { titleCase } from "../../../../../../utils/helpers";

	export default {
		name: "SubscriberInformation",
		props: ["wallet"],
		methods: {
			titleCase,
		},
	};
</script>
