<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openEditPaymentCollectionModal"
    >
      Update Collection
    </button>
    <EditPaymentCollectionModal
      :id="'editPaymentCollection'"
      :closeModal="closeModal"
      :collectionRef="collectionRef"
    />
  </div>
</template>

<script>
import EditPaymentCollectionModal from "../../modals/EditPaymentCollectionModal/EditPaymentCollectionModal";

export default {
  name: "EditPaymentCollectionButton",
  props: ["collectionRef"],
  methods: {
    openEditPaymentCollectionModal() {
      $("#editPaymentCollection").modal("show");
    },
    closeModal() {
      $("#editPaymentCollection").modal("hide");
    },
  },
  components: {
    EditPaymentCollectionModal,
  },
};
</script>
