import { apiClient } from './api'

export default {
  activateDirectDebit(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID
    }
    return apiClient.post(`Authorize/DirectDebit/ActivateDirectDebit`, applicationDetails.payload, { headers })
  },
  getDirectDebitMerchantProfile(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID,
    }
    return apiClient.get(`Authorize/DirectDebit/GetProfile`, { headers })
  },
  getDirectDebitWalletTransactions(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID
    }
    return apiClient.get(`Authorize/DirectDebit/GetWalletTransactions`, { headers })
  },
  getDirectDebitTransactions(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID
    }
    return apiClient.get(`Authorize/DirectDebit/GetTransactions`, { headers })
  },
  getDirectDebitMandates(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID
    }
    return apiClient.get(`Authorize/DirectDebit/GetMandates`, { headers })
  },
  getDirectDebitCustomers(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID
    }
    return apiClient.get(`Authorize/DirectDebit/GetCustomers`, { headers })
  },
  withdrawFunds(userDetails) {
    let headers = {
      clientid: userDetails.clientID
    }
    return apiClient.put(`Authorize/DirectDebit/WithdrawFunds`, userDetails.payload, { headers })
  },

}