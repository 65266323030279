<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openEditModal"
		>
			Edit Profile
		</button>
		<EditProfileModal
			:id="'editProfile'"
			:user="user"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
	import EditProfileModal from "../../modals/EditProfileModal/EditProfileModal";

	export default {
		name: "EditProfileButton",
		props: ["user", "refetch"],
		methods: {
			openEditModal() {
				$("#editProfile").modal("show");
			},
			closeModal() {
				$("#editProfile").modal("hide");
			},
		},
		components: {
			EditProfileModal,
		},
	};
</script>
