<template>
	<Modal
		:id="id"
		:title="'Whitelisted IP Address'"
		:toggle="handleCloseModal"
		class="text-left sub__net no-margin"
	>
		<template v-if="currentState === 'IDLE'">
			<form
				@submit.prevent="handleSubmit"
				class="sub__net__form border__line"
			>
				<div class="ip__box">
					<CustomInput
						name="one"
						type="number"
						:max="256"
						:min="0"
						v-model="ip.one"
						:isNum="true"
						:error="Boolean(formErrors.one)"
						:handleError="handleError"
						:required="true"
						:label="' '"
					/>
				</div>
				<span>.</span>
				<div class="ip__box">
					<CustomInput
						name="two"
						v-model="ip.two"
						:isNum="true"
						:max="256"
						:min="0"
						type="number"
						:error="Boolean(formErrors.two)"
						:handleError="handleError"
						:required="true"
						:label="' '"
					/>
				</div>
				<span>.</span>
				<div class="ip__box">
					<CustomInput
						name="three"
						type="number"
						v-model="ip.three"
						:isNum="true"
						:max="256"
						:min="0"
						:error="Boolean(formErrors.three)"
						:handleError="handleError"
						:required="true"
						:label="' '"
					/>
				</div>
				<span>.</span>
				<div class="ip__box">
					<CustomInput
						name="four"
						type="number"
						v-model="ip.four"
						:isNum="true"
						:max="256"
						:min="0"
						:error="Boolean(formErrors.four)"
						:handleError="handleError"
						:required="true"
						:label="' '"
					/>
				</div>
				<span>/</span>
				<div class="ip__box">
					<CustomInput
						name="sub"
						type="number"
						:max="256"
						:min="0"
						v-model="ip.sub"
						:isNum="true"
					/>
				</div>
				<button type="submit" class="btn no-bg ml-3">
					<img
						src="/assets/img/close.png"
						width="20px"
						height="20px"
						class="add__ip"
						alt=""
					/>
				</button>
			</form>

			<ul class="my-4" v-for="(ipList, index) in ipLists" :key="index">
				<li v-if="ipList" class="d-flex">
					<span class="pr-5">{{ index + 1 }}.</span>
					{{ ipList }}
					<img
						@click="() => deleteItems(ipList)"
						src="/assets/img/icons/trash.svg"
						width="15px"
						height="15px"
						class="ml-auto pointer"
						alt=""
					/>
				</li>
			</ul>

			<div v-if="!hideBtn" class="mx-auto col-6">
				<button
					@click="requestOtp"
					class="btn btn-lg btn-primary w-100"
					:disabled="loading"
				>
					<Spinner v-if="loading" />
					<span v-else>Save Changes</span>
				</button>
			</div>
		</template>

		<div v-else-if="currentState === 'OTP'">
			<WalletOTP
				:loading="loading"
				:value="otp"
				:handleChange="handleOtp"
				:handleSubmit="handleIPSubmit"
			/>
		</div>

		<SuccessState
			v-if="currentState === 'SUCCESS'"
			:message="'IP Addresses Updated Successfully'"
			:onSuccess="updateRequestList"
		/>
	</Modal>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../../public/assets/mixins";
import WalletOTP from "../../../WalletOverview/modals/WalletOTP/WalletOTP";

const initialState = () => ({
	ip: {
		one: "",
		two: "",
		three: "",
		four: "",
		sub: "",
	},
	otp: "",
	loading: false,
	formErrors: {},
	newWhitelistedIP: "",
	hideBtn: false,
	currentState: "IDLE",
});

export default {
	name: "UpdateWhitelistedIPModal",
	mixins: [notifications, componentData],
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "whitelistedIP", "updateWhitelistedIP"],
	components: { WalletOTP },
	methods: {
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleOtp(otp) {
			this.otp = otp;
		},
		updateRequestList() {
			this.updateWhitelistedIP(this.newWhitelistedIP || "");
			this.handleCloseModal();
		},
		addToList() {
			const { one, two, three, four, sub } = this.ip;
			let newIp = `${one}.${two}.${three}.${four}`;
			if (sub) newIp = `${newIp}/${sub}`;
			const exist = this.ipLists.find((v) => v === newIp);
			if (exist) this.showErrorLoginNotification("IP Already Exist!");
			else {
				const v = this.newWhitelistedIP;
				this.newWhitelistedIP = `${newIp}${v ? `,${v}` : ""}`;
				this.ip = { ...initialState().ip };
			}
		},
		deleteItems(value) {
			const ips = this.ipLists.filter((v) => v !== value);
			this.newWhitelistedIP = ips.length > 0 ? ips.join(",") : "";
		},
		handleSubmit(e) {
			// e.preventDefault();
			const payload = { ...this.ip };
			delete payload.sub;
			const { formErrors, isValid } = this.formValidator(payload);
			if (isValid) this.addToList();
			else this.formErrors = formErrors;
		},

		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		async requestOtp() {
			if (this.ipLists.length < 1)
				return this.showErrorLoginNotification(
					"Add at least one ip address"
				);

			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/requestOtp",
					{
						apiKey: this.getWalletApikey(this.$store),
					}
				);
				const success = this.getResponseStatus(response);
				if (success) this.currentState = "OTP";
				else this.showErrorLoginNotification(response.data.message);
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async handleIPSubmit() {
			try {
				this.loading = true;

				const request = {
					whitelistedIP: this.newWhitelistedIP,
					otp: this.otp,
				};

				const response = await this.$store.dispatch(
					"wallets/updateMerchantAccount",
					{
						apiKey: this.getWalletApikey(this.$store),
						request,
					}
				);

				const success = this.getResponseStatus(response);
				if (success) this.currentState = "SUCCESS";
				else this.showErrorLoginNotification(response.data.message);
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(
					error.response.data.message || "Something Went Wrong"
				);
			}
		},
	},
	computed: {
		ipLists() {
			return this.newWhitelistedIP.split(",");
		},
	},
	watch: {
		newWhitelistedIP(newVal) {
			this.hideBtn = newVal ? false : true;
		},
	},
	mounted() {
		if (this.whitelistedIP) this.newWhitelistedIP = this.whitelistedIP;
	},
};
</script>

<style>
.ip__box {
	width: 60px;
	margin: 0 15px;
}

.add__ip {
	transform: rotate(45deg);
}

.sub__net__form {
	display: flex;
	justify-content: center !important;
	align-items: flex-end;
	margin-bottom: 30px;
}

.sub__net__form span {
	font-size: 23px;
	font-weight: 800;
	padding-bottom: 10px;
}
.sub__net li {
	border-bottom: 1px solid #c4c4c4;
	padding: 10px 0;
	font-size: 18px;
}
.sub__net input {
	padding: 0 0 0 10px !important;
}
</style>
