<template>
	<Modal
		:id="id"
		:title="'Create Payment Dispute'"
		:toggle="handleCloseModal"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Payment Dispute Created Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row h-100">
			<div class="col-sm-6">
				<CustomInput
					:name="'first_name'"
					v-model="request.first_name"
					:label="'First Name'"
					:placeholder="'Enter First Name'"
					:required="true"
					:error="formErrors.first_name"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'last_name'"
					v-model="request.last_name"
					:label="'Last Name'"
					:placeholder="'Enter Last Name'"
					:required="true"
					:error="formErrors.last_name"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'email'"
					v-model="request.email"
					:label="'Email'"
					:placeholder="'Enter Email'"
					:required="true"
					:error="formErrors.email"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'phone_number'"
					v-model="request.phone_number"
					:label="'Phone Number'"
					:placeholder="'Enter Phone Number'"
					:required="true"
					:isNum="true"
					:error="formErrors.phone_number"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'amount'"
					v-model="request.amount"
					:label="'Enter Amount'"
					:placeholder="'Enter amount to fund'"
					:required="true"
					:isNum="true"
					:error="formErrors.amount"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'transaction_id'"
					v-model="request.transaction_id"
					:label="'Transaction ID'"
					:placeholder="'Enter Transaction ID'"
					:required="true"
					:error="formErrors.transaction_id"
					:handleError="handleError"
				/>
			</div>

			<div class="col-12">
				<CustomTextArea
					:name="'reason'"
					v-model="request.reason"
					:label="'Reason'"
					:placeholder="'Input your reason here'"
					:required="true"
					:error="formErrors.reason"
					:handleError="handleError"
				/>
			</div>

			<div class="col-12">
				<CustomUpload
					:name="'proof'"
					v-model="request.proof"
					:label="'Proof of Transaction (Max 2MB)'"
					:required="true"
					:error="formErrors.proof"
					:handleError="handleError"
					:validFileTypes="['jpg', 'jpeg', 'png']"
					:handleChange="handleUpload"
				/>

				<img
					v-show="preview"
					:src="preview"
					alt="proof"
					width="50px"
					height="50px"
				/>
			</div>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import CustomUpload from "../../../../../utils/CustomUpload/CustomUpload";
import CustomTextArea from "../../../../../utils/CustomTextArea/CustomTextArea";
import FormButtons from "../../../../../utils/FormButtons/FormButtons";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import Modal from "../../../../../utils/Modal/Modal";
import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
import Spinner from "../../../../../utils/Spinner/Spinner";
import { getWalletApikey } from "../../../../../utils/helpers";

const initialState = () => ({
	request: {
		first_name: "",
		last_name: "",
		email: "",
		phone_number: "",
		amount: "",
		transaction_id: "",
		proof: "",
		currency: "NGN",
		reason: "",
	},
	preview: "",
	loading: false,
	formErrors: {},
	successMsg: "",
});

export default {
	mixins: [notifications],
	name: "CreatePaymentDisputeModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "disputeRef"],
	components: {
		CustomInput,
		CustomSelect,
		CustomUpload,
		CustomTextArea,
		FormButtons,
		SuccessState,
		Modal,
		Spinner,
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault();
			const { formErrors, isValid } = formValidator(this.request);
			if (isValid) this.createDispute();
			else this.formErrors = formErrors;
		},
		handleRefetch() {
			this.handleCloseModal();
			this.disputeRef.refresh();
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		setEmptyImg() {
			this.request.proof = "";
			this.preview = "";
		},
		async handleUpload({ target: { files } }) {
			const file = files[0];
			if (file)
				if (["image/png", "image/jpeg"].includes(file.type)) {
					if (file.size > 2097152) {
						this.setEmptyImg();
						this.showErrorLoginNotification(
							"File too large. Max of 2MB"
						);
					} else {
						this.preview = URL.createObjectURL(file);
						this.request.proof = file;
					}
				} else {
					this.setEmptyImg();
					this.showErrorLoginNotification("Inavlid Image");
				}
			else this.setEmptyImg();
		},
		async createDispute() {
			try {
				this.loading = true;

				const { transaction_id } = this.request || {};

				const request = new FormData();
				request.append("first_name", this.request.first_name);
				request.append("last_name", this.request.last_name);
				request.append("email", this.request.email);
				request.append("phone_number", this.request.phone_number);
				request.append("amount", this.request.amount);
				request.append("transaction_id", transaction_id);
				request.append("currency", this.request.currency);
				request.append("proof", this.request.proof);
				request.append("reason", this.request.reason);

				const response = await this.$store.dispatch(
					"payment/createDispute",
					{
						apiKey: getWalletApikey(this.$store),
						request,
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
