<template>
  <div class="col-md-6 mx-auto mt-4 pt-4">
    <div class="row">
      <div class="col-md-12">
        <div class="SectionItem">
          <div class="SectionHeader">
            <span class="title">Customer Invite Form</span>
            <div class="description">
              Fill in all the details to get started.
            </div>
          </div>
        </div>
      </div>
      <!-- Create Customer section -->
      <div class="col-lg-12 col-md-12">
        <div>
          <div class="row">
            <div class="col-12">
              <form @submit.prevent="performRegistration">
                <fieldset class="form-group">
                  <legend>Personal Information</legend>
                  <div class="row">
                    <!-- First name field -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Firstname</label>
                        <input
                          type="text"
                          name="firstname"
                          class="form-control input-bordered"
                          v-model="customerDetails.firstname"
                          placeholder="Enter your first name"
                          :disabled="hasName"
                        />
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.firstname.$error"
                        >
                          <li class="parsley-required">
                            Firstname is required.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- last name field -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Lastname</label>
                        <input
                          type="text"
                          name="lastname"
                          class="form-control input-bordered"
                          v-model="customerDetails.lastname"
                          placeholder="Enter your last name"
                          :disabled="hasName"
                        />
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.lastname.$error"
                        >
                          <li class="parsley-required">
                            Lastname is required.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Phone number</label>
                        <input
                          type="tel"
                          name="email"
                          class="form-control input-bordered"
                          v-model="customerDetails.phoneNumber"
                          placeholder="Enter your phone number"
                        />
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.phoneNumber.$error"
                        >
                          <li class="parsley-required">
                            Phone number is required.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- Email field -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Email</label>
                        <input
                          type="email"
                          name="email"
                          class="form-control input-bordered"
                          v-model="customerDetails.email"
                          placeholder="Enter your email"
                          disabled
                        />
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.email.$error"
                        >
                          <li class="parsley-required">Email is required.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <!-- Whitlabelled field -->
                <fieldset class="form-group">
                  <legend>Service Preference</legend>
                  <div class="row">
                    <!-- <div class="col-md-12">
                      <div class="form-group">
                        <label class="input-item-label">Do you want to enable white-labelling</label>
                        <v-select class="v-select" @keydown.enter.native.prevent name="address"
                          v-model="customerDetails.whitelabelling" :options="enableWhitelabelling"
                          placeholder="Would you like to enable white-labelling"></v-select>
                        <ul class="parsley-errors-list filled" id="parsley-id-26"
                          v-show="$v.customerDetails.whitelabelling.$error">
                          <li class="parsley-required">
                            whitelabelling option is required.
                          </li>
                        </ul>
                      </div>
                    </div> -->

                    <!--Services field -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="input-item-label">Services</label>
                        <a
                          href="#"
                          class="right"
                          v-if="customerDetails.services"
                          >Fail over services provides fallbacks and makes
                          results more efficent</a
                        >
                        <v-select
                          class="v-select multiple"
                          placeholder="Select a service"
                          @keydown.enter.native.prevent
                          v-model="customerDetails.services"
                          :required="!customerDetails.services"
                          :options="metaData.services"
                          label="service_label"
                          multiple
                        ></v-select>
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.services.$error"
                        >
                          <li class="parsley-required">
                            Services are required.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- Fail over servies -->
                    <div
                      :class="[
                        customerDetails.hasFailOvers == 'Yes'
                          ? 'col-md-4'
                          : 'col-md-12',
                      ]"
                    >
                      <div class="form-group">
                        <label class="input-item-label"
                          >Fail-over services</label
                        >
                        <v-select
                          class="v-select"
                          @keydown.enter.native.prevent
                          name="address"
                          :disabled="!customerDetails.services"
                          v-model="customerDetails.hasFailOvers"
                          :options="failOvers"
                          placeholder="Would you like to select fail-over services"
                        ></v-select>
                      </div>
                    </div>

                    <!-- Select Fail over services -->
                    <div
                      class="col-md-8"
                      v-if="customerDetails.hasFailOvers == 'Yes'"
                    >
                      <div class="form-group">
                        <label class="input-item-label"
                          >Fail-over services</label
                        >
                        <v-select
                          class="v-select"
                          @keydown.enter.native.prevent
                          name="fail overs"
                          v-model="customerDetails.failOverOptions"
                          :options="failOverOptions"
                          multiple
                          placeholder="Select fail-over services"
                          label="service_label"
                        ></v-select>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <!-- Address field -->
                <fieldset class="form-group">
                  <legend>Business Information</legend>
                  <div class="row">
                    <!-- Organisation field -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="input-item-label"
                          >Organisation Name</label
                        >
                        <input
                          type="text"
                          name="organisation"
                          class="form-control input-bordered"
                          v-model="customerDetails.organisation"
                          placeholder="Enter your organisation name"
                        />
                      </div>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.customerDetails.organisation.$error"
                      >
                        <li class="parsley-required">
                          Organisation is required.
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label"
                          >Business Registration Number</label
                        >
                        <input
                          type="text"
                          name="organisation"
                          class="form-control input-bordered"
                          v-model="customerDetails.cac"
                          placeholder="Enter your Bus. Reg. Number"
                        />
                      </div>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.customerDetails.cac.$error"
                      >
                        <li
                          v-show="!$v.customerDetails.cac.required"
                          class="parsley-required"
                        >
                          Bus. Reg. Num. is required.
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label"
                          >Business Certificate</label
                        >
                        <input
                          type="file"
                          ref="formDoc"
                          @change="uploadFile"
                          class="form-control input-bordered"
                          placeholder="select Business certificate"
                          accept=".pdf,.doc,.docx,image/jpg,image/jpeg"
                        />
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.cacCertificate.$error"
                        >
                          <li
                            v-show="!$v.customerDetails.cacCertificate.required"
                            class="parsley-required"
                          >
                            CAC Certificate is required and must be less than
                            7mb.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label"
                          >Tax Identification Number</label
                        >
                        <input
                          type="text"
                          name="tax"
                          class="form-control input-bordered"
                          v-model="customerDetails.tin"
                          placeholder="Enter your TIN"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">TIN Document</label>
                        <input
                          type="file"
                          ref="tinDoc"
                          @change="uploadTinDoc"
                          class="form-control input-bordered"
                          placeholder="select Tin Document"
                          accept=".pdf,.doc,.docx,image/jpg,image/jpeg"
                        />
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.tin_document.$error"
                        >
                          <li
                            v-show="!$v.customerDetails.tin_document.required"
                            class="parsley-required"
                          >
                            TIN Document is required and must be less than 7mb.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Address One</label>
                        <input
                          type="text"
                          name="address_one"
                          class="form-control input-bordered"
                          v-model="customerDetails.address_one"
                          placeholder="Enter your organization's address"
                        />
                      </div>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.customerDetails.address_one.$error"
                      >
                        <li
                          v-show="!$v.customerDetails.address_one.required"
                          class="parsley-required"
                        >
                          Address is required.
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Address Two</label>
                        <input
                          type="text"
                          name="address_two"
                          class="form-control input-bordered"
                          v-model="customerDetails.address_two"
                          placeholder="Enter your organization's address"
                        />
                      </div>
                    </div>

                    <!-- City field -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">City</label>
                        <input
                          type="text"
                          class="form-control input-bordered"
                          v-model="customerDetails.city"
                          placeholder="Enter your organization's city"
                        />
                      </div>
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.customerDetails.city.$error"
                      >
                        <li class="parsley-required">City is required.</li>
                      </ul>
                    </div>

                    <!--Country field -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Country</label>
                        <v-select
                          class="v-select"
                          placeholder="Select a country"
                          v-model="customerDetails.country"
                          @keydown.enter.native.prevent
                          :options="metaData.countries"
                          @input="changeState"
                          label="name"
                        >
                        </v-select>
                        <!-- <ul class="parsley-errors-list filled" id="parsley-id-26">
                        <li class="parsley-required">This value is required.</li>
                      </ul>-->
                      </div>
                    </div>

                    <!-- State field -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">State</label>
                        <v-select
                          class="v-select"
                          placeholder="Select a state"
                          v-model="customerDetails.state"
                          @keydown.enter.native.prevent
                          :disabled="!customerDetails.country"
                          :options="states"
                          label="state_label"
                        ></v-select>
                        <ul
                          class="parsley-errors-list filled"
                          id="parsley-id-26"
                          v-show="$v.customerDetails.state.$error"
                        >
                          <li class="parsley-required">State is required.</li>
                        </ul>
                      </div>
                    </div>

                    <!--Password field -->
                    <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Password</label>
                      <input
                        type="password"
                        autocomplete
                        class="form-control input-bordered"
                        v-model="customerDetails.password"
                        placeholder="Enter a password"
                      />
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.customerDetails.password.$error"
                      >
                        <li class="parsley-required">Password must be a minimum of 6 charachers.</li>
                      </ul>
                    </div>
                  </div>-->

                    <!-- Confirm password field -->
                    <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Confirm Password</label>
                      <input
                        type="password"
                        autocomplete
                        class="form-control input-bordered"
                        v-model.lazy="customerDetails.confirmpassword"
                        placeholder="Confrim the password"
                      />
                      <ul class="parsley-errors-list filled" id="parsley-id-26">
                        <li
                          class="parsley-required"
                          v-if="$v.customerDetails.confirmpassword.$error"
                        >Field is required and must be the same as the password.</li>
                      </ul>
                    </div>
                  </div>-->
                  </div>
                </fieldset>

                <div class="gaps-2x"></div>
                <button
                  class="btn btn-lg btn-primary btn-block mg-b-20"
                  type="submit"
                  :disabled="submitting == true"
                >
                  <span v-if="submitting == true">Creating account</span>
                  <span v-else>Submit</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- End row -->
    </div>

    <!-- End AZ -->
  </div>
</template>

<script>
import Nprogress from "nprogress";
import {
  required,
  requiredIf,
  minLength,
  numeric,
  alphaNum,
  sameAs,
} from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";
import { getError } from "@/utils/helpers";

export default {
  name: "CustomerInvitePage",
  mixins: [notifications],
  components: {
    AuthWrapper,
  },
  data() {
    return {
      hasName: false,
      isLoading: true,
      states: [],
      metaData: {},
      submitting: false,
      enableWhitelabelling: ["Yes", "No"],
      failOvers: ["Yes", "No"],
      customerDetails: {
        authToken: null,
        firstname: "",
        lastname: "",
        organisation: "",
        phoneNumber: "",
        email: "",
        services: "",
        address_one: "",
        address_two: "",
        tin: "",
        // whitelabelling: "",
        cacCertificate: null,
        tin_document: null,
        city: "",
        state: "",
        country: "",
        cac: null,
        // password: '',
        // confirmpassword: '',
        hasFailOvers: "",
        failOverOptions: "",
      },
    };
  },
  watch: {
    "customerDetails.services"(newVal, old) {
      let newServiceArray, failOverServices, missingIndex;
      if (newVal) newServiceArray = newVal.map((service) => service.id);

      if (this.customerDetails.failOverOptions) {
        failOverServices = this.customerDetails.failOverOptions.map(
          (service) => service.id
        );
      }

      if (newServiceArray && failOverServices) {
        for (let index = 0; index < failOverServices.length; index++) {
          if (!newServiceArray.includes(failOverServices[index])) {
            missingIndex = index;
            break;
          }
        }
        this.customerDetails.failOverOptions.splice(missingIndex, 1);
      }
    },
  },
  validations: {
    customerDetails: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      phoneNumber: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
      },
      services: {
        required,
      },
      // whitelabelling: {
      //   required,
      // },
      state: {
        required,
      },
      address_one: {
        required,
      },
      city: {
        required,
      },
      organisation: {
        required,
      },
      cac: {
        required,
      },
      cacCertificate: {
        required: requiredIf(function (nestedModel) {
          return this.customerDetails.cacCertificate == null;
        }),
      },
      tin_document: {
        required: requiredIf(function (nestedModel) {
          return this.customerDetails.cacCertificate == null;
        }),
      },
      // confirmpassword: {
      //   required,
      //   sameAsPassword: sameAs("password")
      // }
    },
  },
  computed: {
    changeState() {
      this.customerDetails.state = "";
      if (!this.customerDetails.country) {
        this.states = [];
      } else {
        this.states = this.customerDetails.country.states || [];
      }
    },

    failOverOptions() {
      if (this.customerDetails.services) {
        return this.customerDetails.services;
      }
    },
  },
  methods: {
    uploadTinDoc(e) {
      const file = e.target.files[0];
      const dataSize = 1024 * 1024;
      if (file.size / dataSize < 10) {
        this.customerDetails.tin_document = file;
        this.tinUploadError = false;
      } else {
        this.tinUploadError = true;
      }
    },
    uploadFile(e) {
      const file = e.target.files[0];
      const dataSize = 1024 * 1024;
      if (file.size / dataSize < 5) {
        this.customerDetails.cacCertificate = file;
        this.uploadError = false;
        return;
      }
      this.uploadError = true;
    },
    formatRequestData() {
      let requestData = new FormData();

      if (this.customerDetails.services) {
        this.customerDetails.services.map((service) => {
          requestData.append("services", service.id);
        });
      }
      if (this.customerDetails.hasFailOvers == "Yes") {
        this.customerDetails.failOverOptions.map((service) => {
          requestData.append("fail_over_services", service.id);
        });
      }

      requestData.append("firstname", this.customerDetails.firstname);
      requestData.append("lastname", this.customerDetails.lastname);
      requestData.append(
        "organization_name",
        this.customerDetails.organisation
      );
      requestData.append("email", this.customerDetails.email);
      requestData.append("address_one", this.customerDetails.address_one);
      requestData.append("address_two", this.customerDetails.address_two);
      requestData.append("city", this.customerDetails.city);
      requestData.append("phone_number", this.customerDetails.phoneNumber);
      // requestData.append('fail_over_services', this.customerDetails.failOverOptions)
      requestData.append(
        "state_id",
        this.customerDetails.state ? this.customerDetails.state.id : ""
      );
      requestData.append(
        "country_id",
        this.customerDetails.country ? this.customerDetails.country.id : ""
      );
      // requestData.append(
      //   "is_whitelabelled",
      //   this.customerDetails.whitelabelling == "Yes" ? 1 : 0
      // );
      requestData.append("authToken", this.customerDetails.authToken);
      requestData.append("cac", this.customerDetails.cac);
      requestData.append("tin", this.customerDetails.tin);
      requestData.append(
        "cac_certificate",
        this.customerDetails.cacCertificate
      );
      requestData.append("form_cac", this.customerDetails.cacCertificate);
      requestData.append("tin_document", this.customerDetails.tin_document);
      // requestData.append('password', this.customerDetails.password)

      return requestData;
    },
    async performRegistration() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("[invalid fields] ->", this.$v);
        return;
      }
      Nprogress.start();
      this.submitting = true;
      let formattedResponse = this.formatRequestData();
      try {
        let { data } = await this.$store.dispatch(
          "auth/adminRegisterCustomer",
          formattedResponse
        );
        this.showSuccessNotification(data.message);
        Nprogress.done();
        let self = this;
        setTimeout(() => {
          self.$router.push("/");
        }, 2000);
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.showErrorLoginNotification(getError(error));
      }
    },
  },
  async mounted() {
    const { query } = await this.$route;

    if (!query.authToken) {
      this.$router.push("/login");
      return;
    }

    try {
      let {
        data: { data },
      } = await this.$store.dispatch("auth/getCustomerDetails", {
        authToken: query.authToken,
      });

      this.customerDetails.firstname = data.first_name;
      this.customerDetails.lastname = data.last_name;

      if (!data.first_name || !data.first_name) this.hasName = false;
      else this.hasName = true;
    } catch (e) {
      this.hasName = false;
      // this.$router.push("/login");
      // return;
    }

    this.customerDetails.email = query.email;
    this.customerDetails.authToken = query.authToken;

    try {
      let apiResponse = await this.$store.dispatch("getMetaData");
      this.metaData = apiResponse.results;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  },
};
</script>

<style>
legend {
  width: auto;
  color: #17568b;
}

fieldset {
  padding: 20px;
  border: 1px solid #17568b;
  border-radius: 10px;
}
</style>
