<template>
  <div class="card-option mb-0 w-100">
    <div
      @click="
        $emit('handleSelect', {
          id: 'NEW_CARD',
        })
      "
      class="pointer d-flex align-items-center justify-content-between card-scheme"
    >
      <div class="d-flex align-items-center px-0 icon__text">
        <!-- <AddCircleInvertIcon /> -->
        <p class="ms-4 mb-0">Add New Card</p>
      </div>
      <div>
        <label class="rdiobox mr-0 mb-0">
          <input
            type="radio"
            value="wallet"
            :checked="
              form.tokenized_card && form.tokenized_card.id == 'NEW_CARD'
            "
          />
          <span class="fs-15"></span>
        </label>
      </div>
    </div>
    <div
      v-if="form.tokenized_card && form.tokenized_card.id == 'NEW_CARD'"
      class="row"
    >
      <div class="col-12">
        <CustomInput
          label="Card Number"
          :name="'cardNumber'"
          :error="formErrors.cardNumber"
          v-model="form.cardNumber"
          placeholder="0000 0000 0000 0000"
          :handleError="handleError"
          :isNum="true"
        >
          <template #input_left>
            <div className="icon__text">
              <img :src="card_image" alt="" />
            </div>
          </template>
        </CustomInput>
      </div>

      <div class="col-12">
        <CustomInput
          label="Card Name"
          :name="'cardName'"
          :error="formErrors.cardName"
          v-model="form.cardName"
          placeholder="Card Holder"
          :handleError="handleError"
        />
      </div>

      <div class="col-12 col-lg-6">
        <CustomInput
          name="expiryDate"
          label="Expiry Date"
          :value="form.expiryDate"
          placeholder="MM / YYYY"
          pattern="\d\d/\d\d\d\d"
          :error="formErrors.expiryDate"
          maxLength="7"
          v-model="form.expiryDate"
          :isNum="true"
          required
        />
      </div>
      <div class="col-12 col-lg-6">
        <CustomInput
          name="cvv"
          type="tel"
          :error="formErrors.cvv"
          v-model="form.cvv"
          pattern="\d{3,4}"
          required
          :isNum="true"
          :handleError="handleError"
          :label="cvvLabel"
          :placeholder="cvvLabel"
          :maxLength="cvvLength"
        />
      </div>

      <div class="col-12">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="save"
            v-model="form.save_card"
          />
          <label class="custom-control-label" for="save"
            >Save Card Details</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/utils/CustomInput/CustomInput";

export default {
  props: [
    "form",
    "cvvLabel",
    "cvvLength",
    "handleError",
    "formErrors",
    "card_image",
  ],
  data() {
    return {};
  },
  components: {
    CustomInput,
  },
};
</script>
