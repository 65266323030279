<template>
	<div class="col-md-12 col-lg-12">
		<div class="default-ImageEmpty">
			<div class="d-flex justify-content-around">
				<img
					:src="`/assets/img/${image}`"
					alt
					:style="`width: ${imgSize}`"
				/>
				<div
					v-html="content"
					class="d-flex justify-content-center align-items-center"
				></div>
			</div>
			<h3 class="defaultTitle">{{ title }}</h3>
			<p>{{ subtitle }}</p>
			<AccessControl> <slot></slot></AccessControl>
		</div>
	</div>
</template>

<script>
	import AccessControl from "@/utils/AccessControl/AccessControl";

	export default {
		components: {
			AccessControl,
		},
		props: {
			image: {
				type: String,
			},
			title: {
				type: String,
			},
			subtitle: {
				type: String,
			},
			content: {
				type: String,
			},
			imgSize: {
				type: String,
				default: "300px",
			},
		},
	};
</script>
