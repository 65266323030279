<template>
	<div class="p-4">
		<div class="SectionItem no-b-b mb-4 justify-content-start">
			<img
				src="/assets/img/icons/arrow-left.svg"
				width="30"
				alt=""
				class="pointer"
				@click="$router.push('/transactions/debit')"
			/>
			<p class="title ml-3 mb-0">
				Debit History Details
			</p>
		</div>

		<div v-if="loading" class="flex__center mt-5 pt-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="() => getDebitHistory(reference)"
		/>

		<template v-else>
			<div class="card history-row">
				<p>
					<span>Request Reference</span>
					<span>{{ debitHistory.request_reference }}</span>
				</p>
				<p>
					<span>Identity Number</span>
					<span>{{ debitHistory.identity_number }}</span>
				</p>
				<p v-if="debitHistory.app">
					<span>Application Used</span>
					<span>{{ debitHistory.app.app_name }}</span>
				</p>
				<p v-if="debitHistory.service">
					<span>Service Used</span>
					<span>{{ debitHistory.service.service_label }}</span>
				</p>
				<p>
					<span>Client ID</span>
					<span>{{ debitHistory.client_id }}</span>
				</p>
				<p>
					<span>Transaction Status</span>
					<span :class="status.style">{{ status.val }}</span>
				</p>
				<p>
					<span>Price per Call</span>
					<span>&#8358;{{ debitHistory.price_per_call }}</span>
				</p>
				<p v-if="debitHistory.fail_reason != null">
					<span>Fail Reason</span>
					<span>{{ debitHistory.fail_reason }}</span>
				</p>
				<p>
					<span>Host URL</span>
					<span>{{ debitHistory.host_url }}</span>
				</p>
				<p v-if="debitHistory.fail_reason != null">
					<span>Billing Type</span>
					<span>{{ debitHistory.billing_type_id }}</span>
				</p>
				<p>
					<span>Request Method</span>
					<span>{{ debitHistory.request_method }}</span>
				</p>
				<p class="border-0">
					<span>Request Time</span>
					<span>{{
						debitHistory.request_time | moment(" MM-DD-YYYY h:mm a")
					}}</span>
				</p>
			</div>

			<div>
				<pre class="code code-html" style="width:100%;">
				<label style="top:42%;">Request Payload</label>
				<code v-html="debitHistory.request_payload_data" />
	  		</pre>
			</div></template
		>
	</div>
</template>

<script>
	import Spinner from "../../../../utils/Spinner/Spinner";
	import FailedState from "../../../../utils/ResponseState/FailedState";

	export default {
		name: "DebitHistoryDetails",

		data() {
			return {
				reference: "",
				loading: false,
				errorMsg: "",
				debitHistory: {},
			};
		},
		components: {
			Spinner,
			FailedState,
		},
		computed: {
			user() {
				return this.$store.state.account.profile;
			},
			status() {
				return this.debitHistory.status === 1
					? { val: "Successful", style: "text-success" }
					: { val: "Failed", style: "text-danger" };
			},
		},
		methods: {
			
			async getDebitHistory(reference) {
				try {
					this.loading = true;
					this.errorMsg = "";
					const response = await this.$store.dispatch(
						"logs/CustomerDebitDetails",
						{ reference }
					);
					const { status, data } = response || {};
					if (status === 200) {
						this.loading = false;
						this.debitHistory = data.result;
					}
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
		},

		async mounted() {
			const reference = this.$route.params.reference;
			this.reference = reference;
			this.getDebitHistory(reference);
		},
	};
</script>

<style scoped>
	.history-row {
		overflow: hidden;
		margin-top: 15px;
	}
	.history-row p {
		padding: 10px 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #efefef;
		font-size: 16px;
	}
</style>
