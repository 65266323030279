<template>
	<Modal :id="id" :title="'Request for funds'" :toggle="handleCloseModal">
		<WalletForm
			v-if="step == 1"
			:loading="loading"
			:closeModal="handleCloseModal"
			:submitForm="requestFunds"
			:minAmount="1000"
			:setDefault="true"
			:walletId="walletId"
			:showReturn="true"
			:amountPlaceholder="'Enter amount to request'"
		/>

		<div v-if="step == 2">
			<div class="py-3">
				<CustomInput
					:label="'Payment Link'"
					:placeholder="'Payment'"
					v-model="paymentLink"
					:icon="paymentLink ? '/assets/img/icons/copy.svg' : ''"
					:iconClick="() => copy(paymentLink)"
				/>
			</div>

			<button
				type="button"
				class="btn btn-primary w-100"
				@click="visitLink"
			>
				Visit Link
			</button>
		</div>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import Modal from "../../../../../utils/Modal/Modal";
import WalletForm from "../WalletForm/WalletForm";

const initialState = () => ({
	loading: false,
	step: 1,
	paymentLink: "",
});

export default {
	mixins: [notifications, componentData],
	name: "RequestFundModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "pub_key", "closeModal", "walletId"],
	components: { Modal, WalletForm },
	methods: {
		visitLink() {
			window.open(this.paymentLink, "blank");
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		async requestFunds(wallet, amount, returnUrl) {
			const walletDetails = {
				apiKey: this.getWalletPublickey(this.$store),
				payload: {
					walletId: wallet.reference,
					amount: amount,
					currency: wallet.currency,
					returnUrl: returnUrl ? returnUrl : "https://blusalt.net",
					metadata: {
						transaction_type: "request_fund",
						inApp: true,
					},
				},
			};
			try {
				this.loading = true;
				await this.$store
					.dispatch("wallets/initializeSDK", walletDetails)
					.then((response) => {
						this.paymentLink = response.data.authorization_url;
						this.step += 1;
						this.loading = false;
					});
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async copy(value) {
			const copied = this.copyToClipboard(value);
			copied && this.showSuccessNotification("Payment Link Copied!");
		},
	},
};
</script>
