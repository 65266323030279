import axios from "axios";

let utilityBaseUrl = process.env.VUE_APP_UTILITY_BASE_URL;

export default {
  uploadImage({ apiKey, body }) {
    console.log({ body, apiKey });
    return axios.post(
      `${utilityBaseUrl}upload?fieldName=images&type=single&access=private`,
      { body },
      {
        headers: {
          "api-key": apiKey,
        },
      }
    );
  },
};
