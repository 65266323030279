<template>
  <div
    class="tab-pane fade show"
    id="Transaction_History"
    role="tabpanel"
    aria-labelledby="Transaction_History-tab"
  >
    <div class="row">
      
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div v-else-if="errorMsg" class="col-12">
        <FailedState :errorMsg="errorMsg" :refetch="refetch" />
      </div>

      <div class="col-12 mt-4" :class="tbClass">
        <v-server-table
          ref="couponRef"
          class="v-table"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
          @row-click="showData"
        >
        <template slot="receiver" slot-scope="{ row }">
            {{ `${row.receiverFirstName} ${row.receiverLastName}` }}
          </template>
          <template slot="created_at" slot-scope="{ row }">
            <span>
              {{
                timeDateFormat(row.created_at).date +
                " " +
                timeDateFormat(row.created_at).time
              }}
            </span>
          </template>
          <template slot="status" slot-scope="{ row }">
            <span
              :class="
                row.status === 'SUCCESSFUL' ? 'text-success' : 'text-primary'
              "
              >{{ row.status }}</span
            >
          </template>
        </v-server-table>
      </div>
    </div>
    <RemittanceTransactionModal
      v-if="showModal"
      :data="modalData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import RemittanceTransactionModal from "../../../TransactionHistory/RemittanceModal.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  getWalletApikey,
} from "@/utils/helpers";

const tableHeadings = {
  customerReference: "Reference",
  receiver: "Reciever",
  currency: "Currency",
  amount: "Amount",
  status: "Status",
  created_at: "Created At",
};

export default {
  mixins: [notifications],
  name: "Transactions",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 20),
    };
  },
  components: {
    RemittanceTransactionModal,
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getTransactionHistory();
      this.$refs.couponRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.couponRef.refresh();
    },
    async getTransactionHistory() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            customerReference: this.$route.params.id,
            ...this.filters
          },
        };

        try {
          const resp = (
            await this.$store.dispatch(
              "remittance/getSenderTransactionHistory",
              payload
            )
          ).data.data;
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
  },

  created() {
    this.getTransactionHistory();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
