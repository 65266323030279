<template>
  <div class="col-md-12 col-lg-12 col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="payment-details--wrapper no-flex">
          <div class="SectionItem">
            <div class="SectionHeader">
              <span class="title">Services listing</span>
              <div
                class="description"
              >View all the service(s) you've registered for that are usable but your application(s).</div>
            </div>

            <div class="content">
              <a
                href="#"
                class="mr-1 btn btn-lg btn-dark"
                @click="populateModal(user.api_customer)"
                data-toggle="modal"
                v-if="userRole !== 'Secondary Customer'"
                data-effect="effect-scale"
                data-backdrop="static"
                data-target="#editAccountService"
              >Edit Services</a>
            </div>
          </div>

          <div class="mg-b-15 d-flex">
            <div class="service-header-title" style="width:70%;">Services</div>
            <div class="service-header-title d-flex" style="width:30%;">
              <div style="width:100%;">Serivice Pricing</div>
            </div>
          </div>
          <div v-for="(service, key) in userServices" :key="key">
            <div class="row" v-if="key !== 0">
              <div class="col-md-12">
                <hr />
              </div>
            </div>
            <div class="d-flex">
              <div class style="width:70%;">
                <h2 class="payment-section-span">{{service.service_label}}</h2>
              </div>
              <div class="align-items-center d-flex" style="width:30%;">
                <div style="width:100%;">
                  <span class="payment-section-span">&#8358;{{service.pivot.price_per_call}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AccountServiceEditModal @refetchServices="refetchServices" :modalData="modalData"></AccountServiceEditModal>
  </div>
</template>

<script>
import AccountServiceEditModal from "@/components/modals/AccountServiceEditModal.vue";

export default {
  components: {
    AccountServiceEditModal
  },
  data() {
    return {
      modalClicked: false,
      modalData: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
    userServices() {
      return this.$store.state.account.profile.api_customer.customerServices;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label
    },
  },
  methods: {
    refetchServices() {
      this.modalClicked = false,
        this.$emit('refetchServices')
    },
    populateModal(data) {
      this.modalData = { ...data }
      this.modalClicked = true
    },
  }
}
</script>

<style>
</style>