<template>
	<div class="card pt-4">
		<v-server-table
			class="v-table row-pointer"
			:columns="columns"
			:options="options"
		/>
	</div>
</template>

<script>
	import { timeDateFormat, titleCase } from "../../../utils/helpers";

	const tableHeadings = {
		amount_credited: "Amount Credited",
		name: "Credited By",
		created_at: "Date",
	};

	export default {
		name: "CreditHistory",
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			titleCase,
			timeDateFormat,
		},
		data() {
			return {
				columns: [],
				options: {
					sortable: [],
					perPage: 25,
					pagination: {
						chunk: 5,
						dropdown: false,
					},
					headings: tableHeadings,
					perPageValues: [],
					filterable: false,
				},
			};
		},

		created() {
			this.options.requestFunction = async (data) => {
				const clientid =
					this.$store.state.account.clientID ||
					localStorage.getItem("local-ID");

				const response = await this.$store.dispatch(
					"logs/CustomerCreditHistory",
					{
						clientid,
						params: data,
					}
				);
				let apiData = response.results.data;
				if (apiData.length) {
					apiData = apiData[0].credit_history.map((val) => ({
						...val,
						name: val.loadedBy
							? `${val.loadedBy.firstname} ${val.loadedBy.lastname}`
							: "Wallet Funding",
					}));
				} else apiData = [];
				return { ...response, data: apiData, count: apiData.length };
			};
		},
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
