<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openAddTeamModal"
		>
			Add New Team Member
		</button>
		<AddTeamModal :id="'addteam'" :closeModal="closeModal" />
	</div>
</template>

<script>
	import AddTeamModal from "../../modals/AddTeamModal/AddTeamModal";

	export default {
		name: "AddTeamButton",
		methods: {
			openAddTeamModal() {
				$("#addteam").modal("show");
			},
			closeModal() {
				$("#addteam").modal("hide");
			},
		},
		components: {
			AddTeamModal,
		},
	};
</script>
