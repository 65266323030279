<template>
  <div id="DeactivateApplicationModal" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close_deactivatemodal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Confirm Action</h5>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <p>Do you want to deactivate this application,please note that all deactivated apps cannot make service request</p>

          <div class="gaps-2x"></div>
          <button
            class="btn btn-danger mr-1"
            :disabled="isProcessing == true"
            @click.prevent="toggleApplicationStatus"
          >Deactivate App</button>
          <button
            class="btn btn-dark"
            :disabled="isProcessing == true"
            @click.prevent="cancelModal"
          >Cancel</button>

          <div class="gaps-2x"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress"
import { required } from 'vuelidate/lib/validators';
import { notifications } from '../../../public/assets/mixins/notifications';

export default {
  mixins: [notifications],
  props: {
    modalData: {
      type: Object
    },
    modalId: {
      type: String
    },
  },
  data() {
    return {
      isProcessing: false
    }
  },
  computed: {
    clientID() {
      return this.$store.state.account.clientID
    },
  },
  methods: {
    closeModal() {
      let closeButton = document.getElementById('close_deactivatemodal')
      closeButton.click()
      this.$emit('refetchApplication')
    },
    cancelModal() {
      let closeButton = document.getElementById('close_deactivatemodal')
      closeButton.click()
      this.$emit('refetchApplication')
    },
    async toggleApplicationStatus() {
      Nprogress.start()
      this.isProcessing = true
      let appDetails = {
        apikey: this.modalData.api_key,
        appname: this.modalData.app_name,
        clientid: this.clientID,
        payload: {
          is_active: !this.modalData.is_active
        }
      }
      try {
        console.log('[app details] ->', appDetails)
        let activationStatus = await this.$store.dispatch('application/toggleApplicationStatus', appDetails)
        this.showSuccessNotification(activationStatus.message)
        let self = this
        setTimeout(() => {
          self.closeModal()
          self.isProcessing = false
        }, 2500);

      } catch (error) {
        this.showErrorLoginNotification(error.message)
        console.log('[errors getting applications] ->', error)
        this.isProcessing = false
      }
    },
  }
}
</script>


