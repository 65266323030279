import { productAPI } from "./api";
const productURL = "/api/v1/product";

export default {
  getProducts({ apiKey, params }) {
    return productAPI.get(productURL, {
      params,
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  createProduct({ apiKey, request }) {
    return productAPI.post(productURL, request, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  updateProduct({ apiKey, request, reference }) {
    return productAPI.put(`${productURL}/${reference}`, request, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
  deleteProduct({ apiKey, reference }) {
    return productAPI.delete(`${productURL}/${reference}`, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  },
};
