<template>
  <div
    class="tab-pane fade show"
    id="Beneficiaries"
    role="tabpanel"
    aria-labelledby="Beneficiaries-tab"
  >
    <div class="row">
      <div class="col-12 mt-3">
        <div class="px-3">
          <FilterWrapper>
            <template slot="pageSelect"> </template>
            <template slot="filterForm">
              <TransactionsTableFilters
                :loading="loading"
                :rLoading="rLoading"
                :submitFilter="refetch"
                :clearFilter="clearFilter"
                :download="downloadCustomerBeneficiaries"
              />
            </template>
          </FilterWrapper>
        </div>
      </div>
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div v-else-if="errorMsg" class="col-12">
        <FailedState :errorMsg="errorMsg" :refetch="refetch" />
      </div>

      <div class="col-12" :class="tbClass">
        <v-server-table
          ref="benefRef"
          class="v-table"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ timeDateFormat(row.createdAt).date }}
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../../public/assets/mixins/componentData";
import TransactionsTableFilters from "../../../TransactionHistory/TransactionHistoryTableFilters/TransactionHistoryTableFilters.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  getWalletApikey,
} from "@/utils/helpers";


const tableHeadings = {
  beneficiaryReference: "Receiver ID",
  firstName: "Firstname",
  lastName: "Lastname",
  email: "Email",
  phoneNumber: "Phone",
  city: "City",
  country: "Country",
};

export default {
  mixins: [componentData],
  name: "Beneficiaries",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 20),
    };
  },
  components: {
    TransactionsTableFilters,
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getCustomerBeneficiaries();
      this.$refs.benefRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.benefRef.refresh();
    },
    async getCustomerBeneficiaries() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
            reference: this.$route.params.id,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch(
              "remittance/getCustomerBeneficiaries",
              payload
            )
          ).data.data;
          return { ...resp, data: resp, count: resp.length };
        } catch (error) {
          this.loading = false;
          this.errorMsg = this.getError(error);
        }
      };
    },
    async downloadCustomerBeneficiaries() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data.data);
      this.downloadTableReport("remittance/getCustomerBeneficiaries", download);
    },
  },

  created() {
    this.getCustomerBeneficiaries();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
