<template>
	<div class="card p-4">
		<AccessControl>
			<div class="d-flex justify-content-end pb-3">
				<AddTeamButton /></div
		></AccessControl>

		<v-client-table
			class="v-table row-pointer"
			:data="team"
			:columns="columns"
			:options="options"
		/>
	</div>
</template>

<script>
import AddTeamButton from "../buttons/AddTeamButton/AddTeamButton";
import AccessControl from "@/utils/AccessControl/AccessControl";
import { tableOptions, toCurrency } from "@/utils/helpers";

const tableHeadings = {
	id: "S/N",
	email: "Email",
	name: "Name",
	role: "Role",
};
const roles = ["Owner", "Admin", "Operations", "Developer", "Customer Service"];

export default {
	name: "Teams",
	data() {
		return { ...tableOptions(tableHeadings, 20) };
	},
	components: {
		AddTeamButton,
		AccessControl,
	},
	computed: {
		team() {
			const accountRole = localStorage.getItem("accountRole");
			const allRoles = this.$store.state.account.profile.team.reduce(
				(acc, role) =>
					role.organization_role >= accountRole
						? [...acc, role]
						: acc,
				[]
			);

			return allRoles.map(({ user, organization_role }, index) => ({
				id: index + 1,
				role: organization_role
					? roles[organization_role - 1]
					: "Not Available",
				name: user
					? `${user.firstname} ${user.lastname}`
					: "Not Available",
				email: user ? user.email : "Not Available",
			}));
		},
	},

	methods: {
		toCurrency,
	},
	async mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
