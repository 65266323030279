<template>
  <div
    class="tab-pane fade show"
    id="Agent_Transactions"
    role="tabpanel"
    aria-labelledby="Agent_Transactions-tab"
  >
    <div class="content__slider">
      <Tabs
        :tabNames="['Online_Transactions', 'Cash_Registers']"
        :tabId="'subTab'"
      />
    </div>

    <div class="tab-content" id="subTabContent">
      <OnlineTransactions />
      <CashRegister />
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../../public/assets/mixins/componentData";
import Tabs from "../../../../../../utils/Tabs/Tabs.vue";
import { virtualTerminalHistory } from "../../../data";
import OnlineTransactions from "./OnlineTranactions/OnlineTranactions.vue";
import CashRegister from "./CashRegisters/CashRegisters.vue";
import {
  tableOptions,
} from "@/utils/helpers";

const tableHeadings = {
  reference: "Reference",
  type: "Type",
  amount: "Amount",
  charges: "Charges",
  stan: "Stan",
  createdAt: "Created At",
  status: "status",
};

export default {
  mixins: [componentData],
  name: "Transactions",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 20),
      virtualTerminalHistory,
    };
  },
  components: {
    Tabs,
    OnlineTransactions,
    CashRegister,
  },
};
</script>
