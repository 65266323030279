import { apiClient } from "./api";

export default {
  getAuditTrail({ clientid, params }) {
    return apiClient.get(`Authorize/ClientAudit`, {
      params,
      headers: { clientid },
    });
  },
  allClientLogs({ request, params, headers = {} }) {
    let newHeaders = {};
    if (request && request.clientID) {
      newHeaders.clientid = request.clientID;
    } else {
      newHeaders = headers;
    }
    if (params.appname) {
      newHeaders.appname = params.appname;
    }
    if (params.apiKey) {
      newHeaders.apiKey = params.apiKey;
    }
    return apiClient.get(`Authorize/ClientLogs`, {
      headers: newHeaders,
      params,
    });
  },
  allLogs({ request, params }) {
    let headers = {
      appname: request.appname,
      apikey: request.apikey,
      clientid: request.clientID,
    };
    return apiClient.get(`Authorize/Logs`, { headers, params });
  },
  BVNLogs(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Logs/bvn`, { headers });
  },
  PVCLogs(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Logs/bvn`, { headers });
  },
  NINLogs(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Logs/bvn`, { headers });
  },
  DLLogs(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Logs/bvn`, { headers });
  },
  NIPLogs(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Logs/bvn`, { headers });
  },

  CustomerCreditHistory({ clientid, params }) {
    return apiClient.get("Authorize/CustomerCreditHistory", {
      params,
      headers: { clientid },
    });
  },
  CustomerDebitHistory({ clientid, params }) {
    return apiClient.get(`Authorize/Customer/Debit/Logs/${clientid}`, {
      params,
    });
  },

  CustomerDebitDetails({ reference }) {
    return apiClient.get(`/Authorize/Customer/Debit/Details/${reference}`);
  },

  // Whitelabelling logs
  SecondaryCustomerCallLog(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientID,
    };
    return apiClient.get(`Authorize/Primary/SecondaryCustomerCallLog`, {
      headers,
    });
  },
  loggedInSecondaryCustomerCallLog() {
    return apiClient.get(`/Authorize/SecondaryCustomerCallLog`);
  },
  // History logs export
  exportDebitHistory({ clientid, params }) {
    return apiClient.get(`/Authorize/Customer/Debit/Logs/Export/${clientid}`, {
      params,
    });
  },
  getSMSLogs({ params }) {
    return apiClient.get(`Authorize/Messaging/CustomerSmsLogs`, { params });
  },
};
