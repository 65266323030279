var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-modal start-0 livesness__check",staticStyle:{"z-index":"9999"}},[_c('div',{staticClass:"close",on:{"click":_vm.close}},[_c('img',{attrs:{"src":"/assets/img/close.svg","alt":"close.svg"}})]),(_vm.step == 1)?_c('div',{key:"1",staticClass:"modal-content bg-white"},[_c('p',{staticClass:"breadcrumb"},[_vm._v("Liveness check")]),_c('div',{staticClass:"welcome-screen"},[_c('p',{staticClass:"title"},[_vm._v("Liveness Check")]),_vm._m(0),_c('button',{staticClass:"btn custom-btn",on:{"click":_vm.handleStart}},[_vm._v(" Continue ")])]),_vm._m(1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step == 2),expression:"step == 2"}],key:"2",staticClass:"no-padding modal-content bg-white position-relative"},[_c('p',{staticClass:"breadcrumb position-absolute",style:({
				color: _vm.loading ? 'white' : null,
				paddingInline: '28px',
				paddingBlock: '20px',
			})},[_vm._v(" Liveness check ")]),_c('video',{ref:"webcam",attrs:{"muted":"","id":"video"},domProps:{"muted":true}},[_vm._v(" Video stream not available. ")]),_c('div',{staticClass:"position-absolute custom-overlay"},[(!_vm.loading)?_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[_c('p',{key:_vm.countdown,staticClass:"countdown"},[_vm._v(" "+_vm._s(_vm.countdown <= 0 ? null : _vm.countdown)+" ")])]):_c('Spinner',{attrs:{"color":"white"}})],1),_c('p',{staticClass:"position-absolute text-center bottom-text"},[_vm._v(" Position your head within the circle ")])]),(_vm.step == 3)?_c('div',{key:"3",staticClass:"modal-content bg-white",staticStyle:{"height":"553px"}},[_c('p',{staticClass:"breadcrumb"},[_vm._v("Liveness check")]),_c('div',{staticClass:"between-column"},[_c('div',{staticClass:"welcome-screen"},[_c('p',{staticClass:"title"},[_vm._v("Liveness Done!")]),_c('div',{staticClass:"between"},[_c('button',{staticClass:"btn custom-btn invert",on:{"click":_vm.handleStart}},[_vm._v(" Redo ")]),_c('button',{staticClass:"btn custom-btn",on:{"click":_vm.confirm}},[_vm._v(" Confirm ")])])]),_vm._m(2)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"margin-bottom":"13.75px"},attrs:{"src":"/assets/img/welcome-icon.svg","alt":"welcome-icon"}}),_c('p',{staticClass:"subtitle text-center"},[_vm._v(" Face forward and make sure your "),_c('br'),_vm._v(" eyes are well visible ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{staticClass:"logo",attrs:{"src":"/assets/img/blusalt-liveness-logo.svg","alt":"welcome-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{staticClass:"logo",attrs:{"src":"/assets/img/blusalt-liveness-logo.svg","alt":"welcome-icon"}})])
}]

export { render, staticRenderFns }