<template>
	<div class="row">
		<transition mode="out-in" name="fade">
			<div class="col-12">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									CAC Basic Search
								</h2>
								<h5 class="user-profile-email sub">
									Verifies company details by providing a
									Company's Registration Number and basic
									search parameters
								</h5>
							</div>
						</div>
						<div class="content">
							<span
								class="badge badge-warning"
								style="font-size: 14px; font-weight: 500"
								v-if="
									hasService &&
										userRole !== 'Secondary Customer'
								"
								>(Each API call costs &#8358;{{
									`${servicePrice}`
								}})</span
							>
							<span
								v-if="!hasService"
								class="badge badge-danger"
								style="font-size: 14px; margin-top: 5px; font-weight: 500"
								>You don't have access to this service</span
							>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="verifyCAC">
							<div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Country</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <v-select
                      class="v-select"
                      placeholder="Select a country"
                      v-model="country_code"
                      @keydown.enter.native.prevent
                      :required="!country_code"
                      :disabled="!countrySortCode"
                      :options="countrySortCode"
                      label="name"
                    ></v-select>
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.country_code.$error"
                    >
                      <li class="parsley-required">
                        Country is required.
                      </li>
                    </ul>
                  </div>
                </div>
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label">
											Business Registration Number
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
										</label>
										<input
											type="text"
											v-model="rcNumber"
											class="form-control input-bordered"
											placeholder="Enter business registration number"
										/>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.rcNumber.$error"
										>
											<li class="parsley-required">
												Business Registration Number is required.
											</li>
										</ul>
									</div>
								</div>

								<!-- phone numbeer -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>Type<span
												class="text-danger"
												title="This field is required"
												>*</span
											></label
										>
										<v-select
											class="v-select"
											placeholder="Company Type"
											:reduce="(company) => company.key"
											v-model="type"
											@keydown.enter.native.prevent
											:required="!type"
											:options="types"
											label="label"
										></v-select>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.type.$error"
										>
											<li class="parsley-required">
												Type is required.
											</li>
										</ul>
									</div>
								</div>

								<!-- Application details -->
								<div class="col-md-6">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<!-- API Version details -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select an API Version
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an API version"
										v-model="apiVersion"
										@keydown.enter.native.prevent
										:required="!apiVersion"
										:disabled="!versions"
										:options="versions"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.apiVersion.$error"
									>
										<li class="parsley-required">
											API version field is required.
										</li>
									</ul>
								</div>

								<!-- Currency Type  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select Currency Type
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Currency Type"
										v-model="currencyType"
										@keydown.enter.native.prevent
										:disabled="!customerWallets"
										:options="customerWallets"
										label="currency"
									></v-select>
								</div>

								<!-- Call ID -->
								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
										:disabled="
											isSubmitting == true || !hasService
										"
									>
										<span v-if="isSubmitting"
											>...Verifying</span
										>
										<span v-else>Verify </span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Response Page -->
				<div
					class="user-settings-section"
					v-if="typeof verificationResult == 'object'"
				>
					<div class="user-settings-section" v-if="hasNewResponse">
						<div class="user-settings-section-header">
							<div class="user-settings-section-header-text">
								<h1>CAC Verification Response</h1>
								<span class="text-span"
									>Preview of the CAC Verification</span
								>
							</div>
							<a
								href="#"
								class="btn btn-outline-danger"
								@click="hasNewResponse = false"
							>
								<i class="la la-close"></i>Close Panel
							</a>
						</div>
						<div class="preview-content">
							<p class="invoice-info-row preview-row">
								<span>Company Name</span>
								<span>{{
									verificationResult.company_name
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Company Type</span>
								<span>{{
									verificationResult.company_type ||
										verificationResult.type
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
                <span>Registration Number</span>
                <span>{{ verificationResult.registration_number }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Registration Date</span>
                <span>{{ verificationResult.registration_date | moment("dddd Do MMMM, YYYY h:mm A")}}</span>
              </p>
							<p class="invoice-info-row preview-row">
								<span>Company Status</span>
								<span>{{ verificationResult.company_status }}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Request Reference</span>
								<span>{{
									verificationResult.request_reference
								}}</span>
							</p>

							<div class="gaps-1-5x"></div>
						</div>
					</div>
				</div>
				<!-- End of transition -->

				<hr />
				<div class="row">
					<div class="col-12">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									API Request Format
								</h2>
								<h5 class="user-profile-email sub">
									Representation of how the API call can be
									made with the appropriate data neccesary
								</h5>
							</div>
						</div>
					</div>

					<div class="col-12">
						<pre
							class="code code-html"
						><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}BusinessInformation/CAC/Basic`
      </code></pre>
					</div>
					<div class="col-12">
						<pre
							class="code code-html"
						><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
					</div>
					<div class="col-12">
						<!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
						<!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
						<!-- "dob": "{{dob}}" -->
						<pre
							class="code code-html"
						><label style="top:42%;">Body</label><code>
      {
        "registration_number": "{{rcNumber || "rc_number"}}",
        "type": "{{type || "bn|co|it"}}",
        "country_code": "{{country_code ? country_code.code3.substring(0,2) : "NG"}}"
      }
      </code></pre>
					</div>
					<div class="col-12">
						<pre
							class="code code-html"
						><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
  "status_code": 200,
  "error": false,
  "message": "CAC retrieved",
  "data": {
    "valid": true,
    "request_reference": "nfMiNZ536238730",
    "rc_number": "1614828",
    "type": "co",
    "registration_date": "2019-09-17T13:33:22.770+00:00",
    "company_name": "FLY BIRD GLOBAL COMPANY LIMITED",
    "status": "ACTIVE",
    "address": "No 13, Gimba Road, Opposite Legend Club, jimeta yola, Jimeta yola, Jimeta, ADAMAWA",
    "share_capital": "1000000",
    "share_capital_in_words": "one million naira",
    "class": "basic"
    "company_contact_persons": [
            {
                "contacts": {
                    "email": [
                        "johndoe@gmail.com"
                    ],
                    "phone": [
                        "08099889988"
                    ]
                },
                "name": "John Doe"
            }
    ],
  }
}`}}
      </code></pre>
					</div>
				</div>
			</div>
		</transition>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		></ApplicationInitializationModal>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  countrySortCode,
} from "@/assets/js/countryDailcodes";
export default {
	name: "CACService",
	props: {
		application: {
			type: Array,
		},
		services: {
			type: Array,
		},
	},
	mixins: [notifications, modalMixin],
	validations: {
		rcNumber: { required },
		type: { required },
		apiVersion: { required },
    country_code: {required},
		app: { required },
	},
	data() {
		return {
      countrySortCode,
			initializedAppResult: {},
			versions: ["v1", "v2"],
			app: "",
			apiVersion: "v2",
			call_id: "",
			clientID: "",
			rcNumber: "",
			type: "",
      country_code: "",
			types: [
				{
					label: "Business Name",
					key: "bn",
				},
				{
					label: "Limited Company",
					key: "co",
				},
				{
					label: "Incorporated Trustee",
					key: "it",
				},
			],
			apikey: "",
			appname: "",
			searchClass: "basic",
			hasNewResponse: false,
			logData: [],
			apiResponse: "",
			customerWallets: this.$store.state.account.customerWallets,
			currencyType: "NGN",
			hasService: false,
			servicePrice: "",
			isSubmitting: false,
			verificationResult: [],
			searchClasses: ["basic"],
		};
	},
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
	},
	computed: {
		API() {
			if (this.apiVersion == "v2" || this.apiVersion == "") {
				return this.$store.state.appUrlV2;
			} else {
				return process.env.VUE_APP_BASE_URL;
			}
		},
	},
	methods: {
		computeService() {
			if (this.services) {
				this.services.filter((service) => {
					if (service.service_label.includes("CAC Basic Enquiry")) {
						this.hasService = true;
						this.servicePrice = service.pivot.price_per_call;
					}
				});
			}
		},

		getRequestData() {
			if (!this.hasBundleBilling) {
				this.call_id = "";
			}

			let verificationData = {
				clientID: this.clientID,
				appname: this.app.app_name,
				apikey: this.app.api_key,
				payload: {
					registration_number: this.rcNumber,
					type: this.type,
					apiVersion: this.apiVersion,
					currency_type: this.currencyType.currency,
          country_code: this.country_code.code3.substring(0, 2),
					// class: this.searchClass,
				},
				query: this.call_id,
			};

			return verificationData;
		},
		async verifyCAC() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			let verificationData = this.getRequestData();
			this.isSubmitting = true;

			try {
				let apiResponse = await this.$store.dispatch(
					"services/verifyBasicCAC",
					verificationData
				);
				console.log("[api Response from bvn ] ->", apiResponse);
				this.apiResponse = apiResponse;
				this.showSuccessNotification(apiResponse.message);
				this.verificationResult = apiResponse.data;
				console.log(this.verificationResult);
				this.hasNewResponse = true;
				this.isSubmitting = false;
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSubmitting = false;
				console.log("error :", error);
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.computeService();
		this.clientID = this.$store.state.account.clientID;

		console.log(
			"Customer Wallets",
			this.$store.state.account.customerWallets
		);
	},
};
</script>
