<template>
	<div class="">
		<div class="border-bottom pb-3 mb-4">
			<h3 class="m-0">Multi Factor Authentication</h3>
			<div class="flex__between">
				<div class="mr-4">
					<span v-if="!user.mfa_active">
						Set up Multi Factor Authentication to secure your
						account
					</span>
					<span v-else
						>You have enabled multi factor authentication</span
					>
				</div>
				<ToggleMFAButton
					:user="user"
					:refresh="refresh"
				/>
			</div>
		</div>

		<template>
			<h3 class="m-0">Change Password</h3>
			<div class="flex__between">
				<span
					>Update your secret combination of letters and numbers</span
				>
				<ChangePasswordButton />
			</div>
		</template>
	</div>
</template>

<script>
	import ToggleMFAButton from "../../buttons/ToggleMFAButton/ToggleMFAButton";
	import ChangePasswordButton from "../../buttons/ChangePasswordButton/ChangePasswordButton";

	export default {
		name: "ProfileFooter",
		props: ["user", "refresh"],
		components: {
			ToggleMFAButton,
			ChangePasswordButton,
		},
	};
</script>
