<template>
	<Modal :id="id" :title="'Create Wallet'" :toggle="handleCloseModal">
		<SuccessState
			v-if="successMsg"
			:message="'Wallet Created Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row h-100">
			<div class="col-sm-6">
				<CustomInput
					:name="'first_name'"
					v-model="request.customer.first_name"
					@blur="$v.request.customer.first_name.$touch()"
					:label="'First Name'"
					:placeholder="'Enter First Name'"
					:error="
						formErrors.first_name ||
						($v.request.customer.first_name.$error &&
							formVuelidator($v.request.customer.first_name))
					"
					:handleError="handleError"
					:required="true"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'last_name'"
					v-model="request.customer.last_name"
					@blur="$v.request.customer.last_name.$touch()"
					:label="'Last Name'"
					:placeholder="'Enter Last Name'"
					:error="
						formErrors.last_name ||
						($v.request.customer.last_name.$error &&
							formVuelidator($v.request.customer.last_name))
					"
					:handleError="handleError"
					:required="true"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'middle_name'"
					v-model="request.customer.middle_name"
					:label="'Middle Name'"
					:placeholder="'Enter Middle Name'"
					:error="formErrors.middle_name"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'email'"
					v-model="request.customer.email"
					@blur="$v.request.customer.email.$touch()"
					:label="'Email'"
					:placeholder="'Enter Email'"
					:error="
						formErrors.email ||
						($v.request.customer.email.$error &&
							formVuelidator($v.request.customer.email))
					"
					:handleError="handleError"
					:required="true"
				/>
			</div>

			<div class="col-sm-6">
				<CustomPhoneInput
					:name="'mobile_no'"
					v-model="request.customer.mobile_no"
					@blur="$v.request.customer.mobile_no.$touch()"
					:label="'Mobile Number'"
					:placeholder="'Enter Mobile Number'"
					:error="
						formErrors.mobile_no ||
						($v.request.customer.mobile_no.$error &&
							formVuelidator($v.request.customer.mobile_no))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CurrencySelect
					:name="'currency'"
					:handleError="handleError"
					:error="formErrors.currency"
					v-model="request.currency"
					:getFirstData="getFirstData"
				/>
			</div>

			<div class="form-group col-12">
				<label class="input-item-label">Account Type</label>
				<div class="d-flex">
					<label class="rdiobox mr-4">
						<input
							type="radio"
							:value="false"
							v-model="accountType"
							@blur="$v.accountType.$touch()"
						/>
						<span class="fs-15">Individual</span>
					</label>

					<label class="rdiobox">
						<input
							type="radio"
							:value="true"
							v-model="accountType"
							@blur="$v.accountType.$touch()"
						/>
						<span class="fs-15">Business </span>
					</label>
				</div>
			</div>
			<div v-show="!accountType" class="col-sm-6">
				<CustomInput
					:name="'bvn'"
					v-model="request.customer.bvn"
					@blur="$v.request.customer.bvn.$touch()"
					:label="'BVN ( Optional )'"
					:placeholder="'Enter your bvn'"
					:isNum="true"
					:error="
						$v.request.customer.bvn.$error &&
						formVuelidator($v.request.customer.bvn)
					"
				/>
			</div>

			<template v-if="accountType">
				<div class="col-sm-6">
					<CustomInput
						:name="'name'"
						v-model="request.customer.name"
						@blur="$v.request.customer.name.$touch()"
						:label="'Account Name'"
						:placeholder="'Enter Account Name'"
						:error="
							formErrors.name ||
							($v.request.customer.name.$error &&
								formVuelidator($v.request.customer.name))
						"
						:handleError="handleError"
						:required="true"
					/>
				</div>

				<div class="col-sm-6">
					<CustomInput
						:name="'rc_number'"
						v-model="request.customer.rc_number"
						@blur="$v.request.customer.rc_number.$touch()"
						:label="'CAC Registration Number'"
						:placeholder="'Enter RC Number'"
						:error="
							formErrors.rc_number ||
							($v.request.customer.rc_number.$error &&
								formVuelidator($v.request.customer.rc_number))
						"
						:handleError="handleError"
						:required="true"
					/>
				</div>

				<div class="col-sm-12">
					<CustomInput
						:name="'address'"
						v-model="request.customer.address_one"
						@blur="$v.request.customer.address_one.$touch()"
						:label="'Business Address 1'"
						:placeholder="'Enter Business Address 1'"
						:error="
							formErrors.address_one ||
							($v.request.customer.address_one.$error &&
								formVuelidator($v.request.customer.address_one))
						"
						:handleError="handleError"
						:required="true"
					/>
				</div>

				<div class="col-sm-12">
					<CustomInput
						:name="'address'"
						v-model="request.customer.address_two"
						@blur="$v.request.customer.address_two.$touch()"
						:label="'Business Address 2'"
						:placeholder="'Enter Business Address 2'"
						:error="formErrors.address_two"
						:handleError="handleError"
					/>
				</div>
			</template>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../../public/assets/mixins";
import { isPhoneValid } from "@/utils/FormValidator/FormVuelidator";
import {
	minLength,
	maxLength,
	numeric,
	required,
	alpha,
	email,
	requiredIf,
} from "vuelidate/lib/validators";

const initialState = () => ({
	request: {
		currency: "",
		customer: {
			first_name: "",
			last_name: "",
			middle_name: "",
			email: "",
			mobile_no: "",
			name: "",
			rc_number: "",
			address_one: "",
			bvn: "",
		},
	},
	accountType: false,
	formErrors: {},
	loading: false,
	successMsg: false,
});

export default {
	mixins: [notifications, componentData],
	name: "CreateWalletModal",
	data() {
		return { ...initialState() };
	},
	validations: {
		request: {
			customer: {
				first_name: { required, alpha },
				last_name: { required, alpha },
				email: { required, email },
				bvn: {
					numeric,
					minLength: minLength(11),
					maxLength: maxLength(11),
				},
				mobile_no: {
					required,
					isPhoneValid: isPhoneValid("request.customer.mobile_no"),
				},
				rc_number: {
					required: requiredIf(function () {
						return this.accountType;
					}),
				},
				address_one: {
					required: requiredIf(function () {
						return this.accountType;
					}),
				},
				name: {
					required: requiredIf(function () {
						return this.accountType;
					}),
				},
			},
		},
	},
	props: ["id", "closeModal", "walletRef"],
	methods: {
		isPhoneValid,
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			location.reload();
		},
		handleSubmit() {
			const { currency, customer } = this.request;
			let payload = {
				...customer,
				currency,
				mobile_no: this.getPhoneNumber(customer.mobile_no),
			};

			if (!this.accountType) {
				payload = { ...customer, currency };
				delete payload.rc_number;
				delete payload.name;
				delete payload.address_one;
				delete payload.address_two;
			}
			delete payload.middle_name;
			delete payload.bvn;

			if (!customer.address_two) {
				delete customer.address_two;
				delete payload.address_two;
			}

			this.$v.$touch();
			const { formErrors, isValid } = this.formValidator(payload);
			if (isValid && !this.$v.$invalid) this.createWallet();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		async createWallet() {
			try {
				this.loading = true;

				const { currency, customer } = this.request;
				let payload = {
					currency: currency.code,
					wallet_reference: this.$uuid.v1(),
					type: "bank",
				};

				if (this.accountType) {
					payload = {
						...payload,
						business: {
							...customer,
							mobile_no: this.getPhoneNumber(customer.mobile_no),
						},
					};
					!customer.middle_name &&
						delete payload.business.middle_name;
					delete payload.business.bvn;
				} else {
					payload = {
						...payload,
						customer: {
							...customer,
							mobile_no: this.getPhoneNumber(customer.mobile_no),
						},
					};
					delete payload.customer.rc_number;
					delete payload.customer.name;
					delete payload.customer.address;
					delete payload.customer.address_one;
					!customer.middle_name &&
						delete payload.customer.middle_name;
					!customer.bvn && delete payload.customer.bvn;
				}

				const response = await this.$store.dispatch(
					"wallets/createWallet",
					{
						apiKey: this.getWalletApikey(this.$store),
						request: payload,
					}
				);

				if (response.status === 200 || response.status === 201) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		getFirstData(currency) {
			this.request.currency = currency;
		},
	},
};
</script>
