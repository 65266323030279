import services from "@/services/services";
import { apiClient } from "@/services/api";

const handleError = (error) => {
  if (!error.response) {
    throw Error("Network Error, Please check your network and retry");
  } else {
    throw error.response.data;
  }
};

export default {
  namespaced: true,
  state: {
    apps: [],
    reportDetails: null,
  },
  mutations: {
    SET_REPORT_DETAILS(state, details) {
      state.reportDetails = details;
    },
  },
  actions: {
    async amlLookup({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.AmlLookUp(verificationData);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr LookUp] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async getBillersCategory({}) {
      try {
        return await services.getBillersCategory();
      } catch (error) {
        handleError(error);
      }
    },
    async getBillerServices({ _ }, payload) {
      try {
        return await services.getBillerServices(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async validateCableTV({ _ }, request) {
      try {
        return await services.validateCableTV(request);
      } catch (error) {
        handleError(error);
      }
    },
    async getCableTVPlans({ _ }, request) {
      try {
        return await services.getCableTVPlans(request);
      } catch (error) {
        handleError(error);
      }
    },
    async getInternetPlans({ _ }, request) {
      try {
        return await services.getInternetPlans(request);
      } catch (error) {
        handleError(error);
      }
    },

    async validateElectricity({ _ }, request) {
      try {
        return await services.validateElectricity(request);
      } catch (error) {
        handleError(error);
      }
    },
    async payBill({ _, dispatch }, request) {
      try {
        const response =  await services.payBill(request);
        dispatch("account/getProfile", null, { root: true });
        return response
      } catch (error) {
        throw (error.response);
      }
    },

    async validateBiller({ commit, dispatch }, applicationDetails) {
      // console.log('[] ->', verificationData.payload.apiVersion)
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.ValidateBiller(applicationDetails);
        return data.data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async getBillers({ commit, dispatch }, applicationDetails) {
      // console.log('[] ->', verificationData.payload.apiVersion)
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.GetBillers(applicationDetails);
        return data.data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyBVN({ commit, dispatch }, verificationData) {
      // console.log('[] ->', verificationData.payload.apiVersion)
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.BVNVerification(verificationData);
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyCAC({ commit, dispatch }, verificationData) {
      // console.log('[] ->', verificationData.payload.apiVersion)
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.CACVerification(verificationData);
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyBasicCAC({ commit, dispatch }, verificationData) {
      // console.log('[] ->', verificationData.payload.apiVersion)
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.BasicCACVerification(verificationData);
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyTIN({ commit, dispatch }, verificationData) {
      // console.log('[] ->', verificationData.payload.apiVersion)
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.verifyTIN(verificationData);
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyMobileCarrier({ commit, dispatch }, verificationData) {
      console.log(
        "[] Inside-Mobile-Carrier-Module->",
        verificationData.payload.apiVersion
      );
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.MobileCarrierVerification(
          verificationData
        );
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyMobileCarrierAdvanced({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.VerifyMobileNumber(verificationData);
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyMobileCarrierBulk({ commit, dispatch }, verificationData) {
      console.log("[] Inside-BVN-BULK-Module->", verificationData);

      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.MobileCarrierBulkVerification(
          verificationData
        );
        console.log("[data from get BVN-BULK] ->", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async sendSingleSms({ commit, dispatch }, verificationData) {
      console.log(
        "[] Inside-Single-SMS-Module->",
        verificationData.payload.apiVersion
      );
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let response = await services.SingleSmsSender(verificationData);
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return response;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyGhanaVoterId({ commit, dispatch }, verificationData) {
      console.log(
        "[] Inside-Ghana-VoterId-Module->",
        verificationData.payload.apiVersion
      );
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.VerifyGhanaVoterId(verificationData);
        // console.log("[data from verify Ghana VoterId] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyGhanaInternationalPassport(
      { commit, dispatch },
      verificationData
    ) {
      console.log(
        "[] Inside-Ghana-Int-Pass-Module>",
        verificationData.payload.apiVersion
      );
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.VerifyGhanaInternationalPassport(
          verificationData
        );
        // console.log("[data from get Passport] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyGhanaDriversLicence({ commit, dispatch }, verificationData) {
      console.log(
        "[] Inside-Ghana-Int-Pass-Module>",
        verificationData.payload.apiVersion
      );
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.VerifyGhanaDriversLicence(
          verificationData
        );
        // console.log("[data from get GDL] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyGhanaSsnit({ commit, dispatch }, verificationData) {
      console.log(
        "[] Inside-Ghana-SSnit--Module>",
        verificationData.payload.apiVersion
      );
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.VerifyGhanaSsnit(verificationData);
        // console.log("[data from get GSSNIT] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyBVNBulk({ commit, dispatch }, verificationData) {
      console.log("[] Inside-BVN-BULK-Module->", verificationData);

      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.BulkBVNVerification(verificationData);
        console.log("[data from get BVN-BULK] ->", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyBVNBulkWithImage({ commit, dispatch }, verificationData) {
      console.log("[] Inside-BVN-BULK-Image-Module->", verificationData);

      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.BulkBVNWithImageVerification(
          verificationData
        );
        console.log("[data from get BVN-BULK] ->", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyBVNWithImage({ commit, dispatch }, verificationData) {
      console.log("[with image] ->", verificationData.payload);
      // if (verificationData.payload.apiVersion == 'v1') {
      //   apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1
      // } else {
      //   apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2
      // }
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.BVNWithImageVerification(
          verificationData
        );
        // console.log("[data from get BVN] ->", data.results);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        console.log("[errr verificATION] ->", error.response.data);
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyPVC({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.PVCVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get PVC] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyNIN({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.NINVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get apiClients] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyNINByMsisdn({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.NINByMsisdnVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get apiClients] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyNINByMsisdnBasic({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.NINByMsisdnVerificationBasic(
          verificationData
        );
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get apiClients] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyDL({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.DLVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get apiClients] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyNIP({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.NIPVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get apiClients] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async addressVerification({ commit, dispatch }, verificationData) {
      if (verificationData.payload.apiVersion == "v1") {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V1;
      } else {
        apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      }
      try {
        let { data } = await services.addressVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get apiClients] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async numberToBankListing({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.numberToBankListing(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async bankAccountVerify({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.bankAccountVerify(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async comparePhoto({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.comparePhoto(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async OCRValidation({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.OCRValidation(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyKenyaInternationalPassport({}, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.verifyKenyaInternationalPassport(verificationData);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyKenyaNationalID({}, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.verifyKenyaNationalID(verificationData);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifyVNIN({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.verifyVNIN(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },

    async DocumentCapture({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.DocumentCapture(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },

    async OCRVerification({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_CORE;
      try {
        let { data } = await services.OCRVerification(verificationData);
        dispatch("account/getProfile", null, { root: true });
        // console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    // OTP Service
    async sendOTP({}, request) {
      try {
        return await services.SendOTP(request);
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },

    async verifyOTP({}, request) {
      try {
        return await services.verifyOTP(request);
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    // Bulk NIN Service
    async verifyNINBulk({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.BulkNINVerification(verificationData);
        console.log("data from NIN-BULK] ->", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    async verifypNINBasicBulk({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.verifypNINBasicBulk(verificationData);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    // Bulk NIN Service - Phone Number
    async verifyNINBulkMobile({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await services.BulkNINVerificationMobile(
          verificationData
        );
        console.log("data from NIN-BULK Mobile ->", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw Error(error.response.data.message);
        }
      }
    },
    // Vend Airtime Service
    async vendAirtime({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.airtimeVend(verificationData);
        console.log("Airtime Vend Respone", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
    // Get Data Plans
    async getDataPlans({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.getDataPlans(verificationData);
        console.log("Airtime Vend Respone", data);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
    // Top Up Data
    async topData({ commit, dispatch }, verificationData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.topData(verificationData);
        console.log("Airtime Vend Respone", data);
        dispatch("account/getProfile", null, { root: true });
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
    // Fetch Config Data
    async fetchMessagingConfig({ dispatch }, configData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.fetchMessagingConfig(configData);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
    async fetchSMSConfig({ dispatch }, configData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await services.fetchSMSConfig(configData);
        return data;
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
    async getSMSConfig({}, configData) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        return await services.getMessagingConfig(configData);
      } catch (error) {
        if (!error.response) {
          throw Error("Network Error, Please check your network and retry");
        } else {
          throw error.response.data;
        }
      }
    },
    async fetchBanks({}) {
      try {
        return await services.fetchBanks();
      } catch (error) {
        handleError(error);
      }
    },
    async nubanOnboardingVerification({}, payload) {
      try {
        return await services.nubanOnboardingVerification(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async bvnMatching({}, payload) {
      try {
        return await services.bvnMatching(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async bodyOutline({}, payload) {
      try {
        return await services.bodyOutline(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async liveness({}, payload) {
      try {
        return await services.liveness(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async livenessAdvanced({}, payload) {
      try {
        return await services.livenessAdvanced(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async binVerification({}, payload) {
      try {
        return await services.binVerification(payload);
      } catch (error) {
        handleError(error);
      }
    },
    async phoneNumberVerifStandard({}, payload) {
      return await services.phoneNumberVerifStandard(payload);
    },
    async getReport({}, payload) {
      return await services.getReport(payload);
    },
    async getReportDetails({ commit }, payload) {
      const resp = await services.getReportDetails(payload);
      commit("SET_REPORT_DETAILS", resp.data.data);
      return resp;
    },
    async downloadReportDetails({ commit }, payload) {
      const resp = await services.downloadReportDetails(payload);
      return resp;
    },
    async deleteReport({ commit }, payload) {
      const resp = await services.deleteReport(payload);
      return resp;
    },
    async getReportAPIDocs() {
      return await services.getReportAPIDocs();
    },
    async getReportAPIWebhooks() {
      return await services.getReportAPIWebhooks();
    },
    async updateReportAPIWebhooks({}, payload) {
      return await services.updateReportAPIWebhooks(payload);
    },
  },
  getters: {},
};
