<template>
	<div id="walletAccordion">
		<div class="d-flex pb-4 ">
			<div class="az-img-user mr-2">
				<img src="/assets/img/avatar1.svg" alt="" />
			</div>
			<div class="">
				<h4 v-if="customer.business" class="m-0">
					{{ customer.business_details.name }}
				</h4>
				<h4 v-else class="m-0">
					{{ titleCase(customer.first_name) }}
					{{ titleCase(customer.last_name) }}
				</h4>
				<span
					>Added on {{ timeDateFormat(customer.createdAt).date }}
					{{ timeDateFormat(customer.createdAt).time }}</span
				>
			</div>
		</div>

		<div class="accordion__body">
			<CustomerPersonalInfo :customer="customer" />
		</div>
	</div>
</template>

<script>
import CustomerPersonalInfo from "./CustomerPersonalInfo/CustomerPersonalInfo";
import { timeDateFormat, titleCase } from "../../../../../utils/helpers";

export default {
	name: "WalletDetailSideView",
	data() {
		return {
			blacklist: false,
		};
	},
	props: ["customer"],
	components: {
		CustomerPersonalInfo,
	},
	methods: {
		timeDateFormat,
		titleCase,
	},
};
</script>
