<template>
	<form @submit.prevent="handleSubmit">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<CustomInput
					:name="'reference'"
					v-model="filters.reference"
					:placeholder="'Reference'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<DatePicker
					:name="'from'"
					v-model="dateFilters.from"
					:placeholder="'Start Date'"
					:disabledDates="{ from: new Date() }"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<DatePicker
					:name="'to'"
					v-model="dateFilters.to"
					:disabledDates="{ from: new Date(), to: dateFilters.from }"
					:placeholder="'End Date'"
				/>
			</div>

			<div class="col-sm-6 px-sm-2 col-md-4 col-xl-2">
				<CurrencySelect
					:hideLabel="true"
					:name="'currency'"
					v-model="filters.currency"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>

				<div class="d-inline-block">
					<DownloadReport
						:downloadReport="download"
						:loading="rLoading"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import DatePicker from "../../../../../utils/DatePicker/DatePicker";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
import Spinner from "../../../../../utils/Spinner/Spinner";
import DownloadReport from "../../../../../utils/DownloadReport/DownloadReport";
import { timeRangeFormat } from "../../../../../utils/helpers";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect.vue";

const initialState = () => ({
	filters: {
		action: "",
		reference: "",
		currency: "",
	},
	dateFilters: {
		from: null,
		to: null,
	},
	isFilter: false,
	qLoading: false,
});

export default {
	name: "TransactionsTableFilters",
	data() {
		return { ...initialState() };
	},
	props: ["loading", "rLoading", "clearFilter", "submitFilter", "download"],
	components: {
		DatePicker,
		CustomInput,
		CustomSelect,
		Spinner,
		DownloadReport,
		CurrencySelect,
	},
	computed: {
		disabled() {
			return !Object.values({
				...this.filters,
				...this.dateFilters,
			}).some((val) => val);
		},
	},
	methods: {
		resetFilters() {
			Object.assign(this.$data, { ...initialState() });
			this.clearFilter();
			this.isFilter = false;
		},
		handleSubmit() {
			this.isFilter = true;
			if (this.dateFilters.from)
				this.dateFilters.from = timeRangeFormat(
					this.dateFilters.from,
					true
				);
			if (this.dateFilters.to)
				this.dateFilters.to = timeRangeFormat(this.dateFilters.to);

			this.submitFilter({
				dateParams: { ...this.dateFilters },
				query: { ...this.filters },
			});
		},
	},
	watch: {
		"filters.from": function () {
			this.filters.to = null;
		},
	},
};
</script>
