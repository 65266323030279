export const sampleResponse = {
  status_code: 200,
  message: "Verification Successful",
  data: {
    request_id: "1684401820,3fecce91-4b65-41a8-94b7-6491814c3ebf",
    time_used: 1173,
    biz_no: "6766d6d1-64f3-4a8f-8d92-7a81d3fe8041",
    liveness: {
      procedure_validation: "PASSED",
      face_genuineness: "PASSED",
    },
    image_best: "base64 image",
    original_image: "base64 image",
    face_attributes: {
      image_quality: {
        value: 93.734,
        threshold: 70.1,
      },
      mouth_status: {
        surgical_mask_or_respirator: 32.516,
        other_occlusion: 0.035,
        close: 65.349,
        open: 2.1,
      },
      eyes_status: {
        left_eye_status: {
          no_glass_eye_open: 99.985,
          no_glass_eye_close: 0,
          normal_glass_eye_open: 0.01,
          normal_glass_eye_close: 0,
          dark_glasses: 0.004,
          occlusion: 0.001,
        },
        right_eye_status: {
          no_glass_eye_open: 99.999,
          no_glass_eye_close: 0,
          normal_glass_eye_open: 0.001,
          normal_glass_eye_close: 0,
          dark_glasses: 0,
          occlusion: 0,
        },
      },
      headpose: {
        pitch_angle: 3.5159388,
        roll_angle: -1.3431847,
        yaw_angle: 1.1771779,
      },
      glass: "No",
    },
  },
};
