<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<ChargebackFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('chargebackRef')"
							:download="downloadChargeBack"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="content">
					<v-server-table
						ref="chargebackRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
						@row-click="handleClick"
					>
						<template slot="reference" slot-scope="{ row }">
							{{ truncateString(row.reference, 25) }}
						</template>
						<template
							slot="transaction_reference"
							slot-scope="{ row }"
						>
							{{ truncateString(row.transaction_reference, 25) }}
						</template>
						<template
							slot="Transaction.client_reference"
							slot-scope="{ row }"
						>
							{{
								row.Transaction.client_reference
									? row.Transaction.client_reference
									: "Not Available"
							}}
						</template>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
								{{ timeDateFormat(row.createdAt).time }}
							</span>
						</template>
						<template
							slot="Transaction.amount"
							slot-scope="{ row }"
						>
							{{
								toCurrency(
									row.Transaction.amount,
									row.Transaction.currency
								)
							}}
						</template>
						<template slot="status" slot-scope="{ row }">
							<StatusPill :status="row.status" />
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import ChargebackFilters from "./ChargebackFilters/ChargebackFilters";
import { tableHeadings, chargebackCsvRows } from "../helpers/enums";
import { toCurrency, truncateString } from "@/utils/helpers";
import StatusPill from "@/utils/StatusPill/StatusPill.vue";

export default {
	mixins: [notifications, componentData],
	name: "ChargeBackContent",
	data() {
		return {
			emptyTitle: "No Chargebacks Found",
			...this.tableOptions(tableHeadings, 20),
		};
	},
	components: {
		ChargebackFilters,
		StatusPill,
	},
	methods: {
		handleClick({ row }) {
			return this.$router.push({
				name: "chargeback",
				params: { id: row.reference },
			});
		},
		toCurrency,
		truncateString,
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.bank_name)
				newFilters.bank_name = newFilters.bank_name.name;
			if (newFilters.status) newFilters.status = newFilters.status.value;
			this.handleRefetch(newFilters, this.getCollection, "chargebackRef");
		},
		async getCollection() {
			this.handleAPICall("wallets/getChargeBacks");
		},
		async downloadChargeBack() {
			const download = (filterData) =>
				this.downloadReport(chargebackCsvRows, filterData.data.data);
			this.downloadTableReport("wallets/getChargeBacks", download);
		},
	},
	created() {
		this.getCollection();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
