<template>
  <div class="date-range d-flex flex-column custom__date shadow bg-white">
    <div class="d-flex flex-column flex-lg-row">
      <Datepicker
        v-model="dates.from"
        :inline="true"
        :disabledDates="{ from: dates.to }"
        @selected="$emit('reset')"
      />
      <div style="padding: 5px; border-left: 0.5px solid #ccbbbb8a" />
      <Datepicker
        v-model="dates.to"
        :inline="true"
        :disabledDates="{ from: new Date(), to: dates.from }"
        @selected="$emit('reset')"
      />
    </div>
    <div class="text-right">
      <button
        type="button"
        class="btn btn-lg btn-primary"
        @click="$emit('close')"
      >
        <span>Ok</span>
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  props: ["dates"],
  components: { Datepicker },
};
</script>

<style scoped>
.date-range >>> .vdp-datepicker__calendar {
  border: 0 !important;
}
</style>
