import application from "@/services/application";
import { apiClient } from "@/services/api";
apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

export default {
	namespaced: true,
	state: {
		apps: [],
	},
	mutations: {
		SET_APPS(state, apps) {
			state.apps = apps;
		},
	},
	actions: {
		async getApplications({ commit }) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			let apps;
			try {
				let { data } = await application.GetApps();
				apps = data.results;
				commit("SET_APPS", apps);
				return apps;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async createApplication({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.RegisterCustomerApp(
					appDetails
				);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async addAppToSecondaryCustomer({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.addAppToSecondaryCustomer(
					appDetails
				);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async editApplication({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.EditApplication(appDetails);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async creditApplication({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.creditApplication(appDetails);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async toggleApplicationStatus({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.toggleApplicationStatus(
					appDetails
				);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async editSecondaryCustomerApplication({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let {
					data,
				} = await application.editSecondaryCustomerApplication(
					appDetails
				);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async editApplicationServices({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.EditApplicationServices(
					appDetails
				);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async regenerateKey({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.ReGenerateApiKey(appDetails);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async initializeWebApp({ commit }, appDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.InitializeWebApp(appDetails);
				return data;
			} catch (error) {
				console.error(error.response.data.message);
				throw Error(error.response.data.message);
			}
		},
		async whitelabelSettings({}, appSettings) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let { data } = await application.whitelabelSettings(
					appSettings
				);
				return data;
			} catch (error) {
				throw Error(error.response.data);
			}
		},
	},
	getters: {},
};
