<template>
	<div
		class="tab-pane active fade show"
		id="Split_Details"
		role="tabpanel"
		aria-labelledby="Split_Details-tab"
	>
		<div class="card overflow-hidden">
			<div class="row py-3">
				<div class="col-sm-4 text-center border-right">
					<p class="call-name pt-2">Total Sub Accounts</p>
					<h4 class="call-count">
						{{ splitPayment.config.length }}
					</h4>
				</div>

				<div class="col-sm-3 text-center border-right">
					<p class="call-name pt-2">Currency</p>
					<h4 class="call-count">
						{{ splitPayment.currency }}
					</h4>
				</div>

				<div class="col-sm-5 text-center">
					<p class="call-name pt-2">Date Created</p>
					<h4 class="call-count">
						{{ timeDateFormat(splitPayment.createdAt).date }}
					</h4>
				</div>
			</div>
		</div>

		<SplitPaymentTransactions :params="params" :getTotals="getTotals" />
	</div>
</template>

<script>
import SplitPaymentTransactions from "../SplitPaymentTransactions/SplitPaymentTransactions";
import {
	titleCase,
	toCurrency,
	statusColor,
	timeDateFormat,
} from "@/utils/helpers";

export default {
	name: "SplitPaymentDetailsInfo",
	props: ["splitPayment", "reference"],
	data() {
		return {
			totals: {},
			params: { split_payment_reference: this.reference },
		};
	},
	components: {
		SplitPaymentTransactions,
	},
	methods: {
		titleCase,
		toCurrency,
		statusColor,
		timeDateFormat,
		getTotals(totals) {
			this.totals = totals;
		},
	},
};
</script>
