<template>
	<div>
		<img
			@click="openEditTax"
			class="pointer mr-4"
			src="/assets/img/icons/edit.png"
			alt="update tax"
			width="25px"
		/>

		<CreateTaxModal
			:id="tax.id"
			:closeModal="closeModal"
			:taxRef="taxRef"
			:taxData="tax"
		/>
	</div>
</template>

<script>
import CreateTaxModal from "../../modals/CreateTaxModal/CreateTaxModal";

export default {
	name: "UpdateTaxButton",
	props: ["taxRef", "tax"],
	methods: {
		openEditTax() {
			$(`#${this.tax.id}`).modal("show");
		},
		closeModal() {
			$(`#${this.tax.id}`).modal("hide");
		},
	},
	components: {
		CreateTaxModal,
	},
};
</script>
