<template>
  <div class="user-settings-section border-0">
    <div class="user-prof">
      <img :src="setImage" alt="" class="rounded" />
      <img :src="userImage" alt="" class="rounded" />
    </div>
    <div class="invoice-info mt-4">
      <p class="invoice-info-row preview-row">
        <span>Confidence</span>
        <span>
          {{
          `${+result.result_ref1.confidence.toFixed(2)}%` || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>Low Errow Rate</span>
        <span>
          {{
          `${+lowErrorRate.toFixed(2)}%` || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>High Error Rate</span>
        <span>
          {{
          `${+highErrorRate.toFixed(2)}%` || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>Procedure Validation</span>
        <span>
          {{
          result.liveness.procedure_validation || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>Face Genuineness</span>
        <span>
          {{
          result.liveness.face_genuineness || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>Image Quality</span>
        <span>
          {{
          result.face_attributes.image_quality.value || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>Glasses</span>
        <span>
          {{
          result.face_attributes.glass || "Information not available"
          }}
        </span>
      </p>
       <p class="invoice-info-row preview-row">
				<span>Reference ID</span>
				<span>
					{{ result.request_id || "Information not available" }}
				</span>
			</p>
    </div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  name: "LivenessReport",
  props: ["result", "image"],
  computed: {
    lowErrorRate() {
      return Object.values(this.result.result_ref1.thresholds).reduce((a, b) => a < b ? a : b)

    },
    highErrorRate() {
      return Object.values(this.result.result_ref1.thresholds).reduce((a, b) => a > b ? a : b)

    },
    setImage() {
      return URL.createObjectURL(this.image);
    },
    userImage() {
      return (
        this.result.image_best ? `data:image/png;base64, ${this.result.image_best}` :
          "/assets/img/avatar1.svg"
      );
    },
  },
};
</script>

<style scoped>
.user-settings-section-head p {
  font-size: 15px;
  padding: 3px 0;
}

.invoice-info {
  border-top: 1px dotted #ced4da;
  border-bottom: 1px dotted #ced4da;
}

.user-prof img {
  width: 300px;
  height: auto;
  object-fit: cover;
}

.user-prof {
  display: flex;
  justify-content: space-between;
  width: auto;
  height: auto;
  background: none !important;
  overflow: hidden;
}
</style>
