<template>
	<div class="card pt-3">
		<div class="row">
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<AccessControl>
								<CreateCouponButton
									:couponRef="$refs.couponRef"
								/>
							</AccessControl>
						</template>
						<template slot="filterForm">
							<CouponTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadCoupon"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="couponRef"
					class="v-table"
					:columns="columns"
					:options="options"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template slot="discountType" slot-scope="{ row }">
						{{ titleCase(row.discountType) }}
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						{{ timeDateFormat(row.createdAt).date }}
					</template>
					<template slot="endDate" slot-scope="{ row }">
						{{
							row.endDate
								? timeDateFormat(row.createdAt).date
								: "Unlimited"
						}}
					</template>

					<template slot="action" slot-scope="{ row }">
						<AccessControl>
							<div class="d-flex">
								<UpdateCouponButton
									:coupon="row"
									:couponRef="$refs.couponRef"
								/>
								<DeleteCouponButton
									:couponRef="$refs.couponRef"
									:couponCode="row.couponCode"
								/>
							</div> </AccessControl
					></template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../public/assets/mixins";
import CreateCouponButton from "../buttons/CreateCouponButton/CreateCouponButton";
import DeleteCouponButton from "../buttons/DeleteCouponButton/DeleteCouponButton";
import UpdateCouponButton from "../buttons/UpdateCouponButton/UpdateCouponButton";
import CouponTableFilters from "./CouponTableFilters/CouponTableFilters";
import { getRequestData } from "../enums";

const tableHeadings = {
	couponCode: "Coupon Code",
	discountType: "Discount Type",
	discountValue: "Discount(#)",
	createdAt: "Date Created",
	endDate: "Date Range",
	action: "",
};

export default {
	mixins: [notifications, componentData],
	name: "Coupons",
	data() {
		return {
			loading: false,
			rLoading: false,
			errorMsg: "",
			size: 25,
			filters: {},
			...this.tableOptions(tableHeadings, 20),
		};
	},
	components: {
		CreateCouponButton,
		DeleteCouponButton,
		UpdateCouponButton,
		CouponTableFilters,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch(filters) {
			const newFilters = Object.fromEntries(
				Object.entries(filters).filter(([_, v]) => v != "" && v != null)
			);
			if (filters) this.filters = newFilters;
			this.getCoupons();
			this.$refs.couponRef.refresh();
		},
		clearFilter() {
			this.filters = {};
			this.$refs.couponRef.refresh();
		},
		async getCoupons() {
			this.options.requestFunction = async (data) => {
				const requestData = getRequestData(
					this.$store,
					this.filters,
					data,
					this.size
				);
				try {
					return (
						await this.$store.dispatch(
							"coupons/getAllCoupons",
							requestData
						)
					).data.data;
				} catch (error) {
					this.loading = false;
					this.errorMsg = this.getError(error);
				}
			};
		},
		async downloadCoupon() {
			this.rLoading = true;
			const request = getRequestData(this.$store, this.filters, {});

			const response = await this.$store.dispatch(
				"coupons/getAllCoupons",
				request
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				const newHeading = { ...tableHeadings };
				delete newHeading.action;
				this.downloadReport(newHeading, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},

	created() {
		this.getCoupons();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
