import products from "@/services/products";

export default {
	namespaced: true,
	actions: {
		async getProducts({}, payload) {
			return await products.getProducts(payload);
		},
		async createProduct({}, payload) {
			return await products.createProduct(payload);
		},
		async updateProduct({}, payload) {
			return await products.updateProduct(payload);
		},
		async deleteProduct({}, payload) {
			return await products.deleteProduct(payload);
		},
	},
};
