<template>
	<section>
		<div class="mb-3 border-bottom pb-3">
			<p class="user-profile-name m-0">Utility Bills Payment form</p>
			<h5 class="user-profile-email sub m-0">
				Pay utility bills for your users
			</h5>
		</div>

		<div class="row mt-3">
			<div class="col-sm-6">
				<ActiveAppSelect
					:name="'app'"
					:handleError="handleError"
					v-model="app"
				/>
			</div>

			<div class="col-sm-6">
				<BillerCategorySelect
					:name="'billCategory'"
					:handleError="handleError"
					v-model="billCategory"
				/>
			</div>

			<div class="col-12">
				<CustomSelect
					name="service_id"
					label="Biller"
					:optionLabel="'service'"
					placeholder="Select Biller"
					:options="billServices"
					v-model="service_id"
					:required="true"
				/>
			</div>

			<template v-if="service_id && app">
				<CableTVBiller
					v-if="billCategory.name === 'Cable TV'"
					:app="app"
					:billService="service_id"
					:reset="reset"
				/>
				<InternetBiller
					v-if="billCategory.name === 'Internet'"
					:app="app"
					:billService="service_id"
					:reset="reset"
				/>
				<ElectricityBiller
					v-if="billCategory.name === 'Electricity'"
					:app="app"
					:billService="service_id"
					:reset="reset"
				/>
			</template>
		</div>
	</section>
</template>

<script>
import CustomSelect from "../../../utils/CustomSelect/CustomSelect";
import ActiveAppSelect from "../../../utils/Selects/ActiveAppSelect/ActiveAppSelect";
import BillerCategorySelect from "../../../utils/Selects/BillerCategorySelect/BillerCategorySelect";
import CableTVBiller from "./CableTVBiller/CableTVBiller";
import InternetBiller from "./InternetBiller/InternetBiller";
import ElectricityBiller from "./ElectricityBiller/ElectricityBiller";

export default {
	name: "UtilityBills",
	data() {
		return {
			billCategory: "",
			app: "",
			service_id: "",
			billServices: [],
		};
	},
	components: {
		BillerCategorySelect,
		ActiveAppSelect,
		CableTVBiller,
		CustomSelect,
		InternetBiller,
		ElectricityBiller,
	},
	methods: {
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		reset() {
			this.service_id = "";
		},
		getRequestData(payload) {
			return {
				headers: {
					clientid: this.$store.state.account.clientID,
					appname: this.app.app_name,
					apikey: this.app.api_key,
				},
				payload,
			};
		},
		async getBillerServices() {
			try {
				const res = await this.$store.dispatch(
					"services/getBillerServices",
					{ api_service_id: this.billCategory.id }
				);
				const { status, data } = res || {};
				if (status === 200) {
					this.service_id = "";
					this.billServices = data.data;
				}
			} catch (error) {
				this.billServices = [];
			}
		},
		async handlePayBill(payload) {
			try {
				const res = await this.$store.dispatch("services/payBill", {
					api_service_id: this.billCategory.id,
				});
				const { status, data } = res || {};
				if (status === 200) {
					this.service_id = "";
					this.billServices = data.data;
				}
			} catch (error) {
				this.billServices = [];
			}
		},
	},
	watch: {
		billCategory() {
			this.getBillerServices();
		},
	},
};
</script>
