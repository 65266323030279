<template>
  <form>
    <div><h3 class="title mb-3">Payment Information</h3></div>
    <div>
      <div class="row">
        <div class="col-4">
          <div class="mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'amount'"
              :label="'Amount to be sent'"
              :placeholder="'Amount'"
              :required="true"
              :isNum="true"
              type="number"
              v-model="senderForm.amount"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.amount.$error"
            >
              <li class="parsley-required">First Name is required.</li>
            </ul>
          </div>
          <div class="mb-2">
            <div class="form-group">
              <label class="input-item-label">Bank</label>
              <v-select
                class="v-select"
                placeholder="Select a bank"
                :options="providers"
                label="name"
                @keydown.enter.native.prevent
                name="receiverBankCode"
                v-model="senderForm.receiverBankCode"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.receiverBankCode.$error"
            >
              <li class="parsley-required">Bank is required.</li>
            </ul>
          </div>
          <div class="mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'receiverAccountNumber'"
              :label="'Account Number'"
              :isNum="true"
              :placeholder="'Account Number'"
              :required="true"
              v-model="senderForm.receiverAccountNumber"
              type="number"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.receiverAccountNumber.$error"
            >
              <li class="parsley-required">Account Number is required.</li>
            </ul>
          </div>
          <p v-if="resolvedName" class="font-weight-bold mb-4">
            {{ resolvedName }}
          </p>
          <div class="mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'Narration'"
              :label="'Narration'"
              :required="true"
              :placeholder="'Narration'"
              v-model="senderForm.narration"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.narration.$error"
            >
              <li class="parsley-required">Narration is required.</li>
            </ul>
          </div>
        </div>
        <div class="ml-4 col-4 bg-light p-4">
          <div class="content">
            <h4>Quotation</h4>
            <div class="information flex__between mb-3">
              <span>Amount Sent</span>
              <span> {{ formatNumber(senderForm.amount) }} USD</span>
            </div>
            <div class="information flex__between mb-3">
              <span>Charge Fee</span>

              <span> {{ charges }} USD</span>
            </div>
            <div class="information flex__between">
              <span>Total</span>
              <span> {{ formatNumber(senderForm.amount) }} USD</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex__between col-12 col-sm-3 mt-4">
      <input
        type="button"
        value="Prev"
        class="btn btn-lg btn-primary mr-4"
        :disabled="loading"
        @click="$emit('handlePrev')"
      />
      <input
        type="button"
        value="Continue"
        class="btn btn-lg btn-primary"
        :disabled="loading"
        @click="handleSubmit"
      />
    </div>
  </form>
</template>

<script>
import Spinner from "../../../../utils/Spinner/Spinner.vue";
import FailedState from "../../../../utils/ResponseState/FailedState";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { required } from "vuelidate/lib/validators";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import { formatNumber, getError, getWalletApikey } from "@/utils/helpers";
import { newCountryFormat } from "@/assets/js/countryDailcodes";
import remittance from "@/services/wallets/remittance";

export default {
  mixins: [notifications],
  name: "RecieverForm",
  data() {
    return {
      resolvedName: "",
      senderForm: {},
      loading: false,
      providers: [],
      newCountryFormat,
      states: [],
      charges: "0",
    };
  },
  validations: {
    senderForm: {
      amount: { required },
      narration: { required },
      receiverBankCode: { required },
      receiverAccountNumber: { required },
    },
  },
  components: {
    Spinner,
    FailedState,
    CustomInput,
    CustomSelect,
    DatePicker,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
  },
  methods: {
    clearInputFields() {
      this.senderForm = {};
      this.loading = false;
    },
    formatNumber,
    changeState() {
      // this.senderForm.state = [];
      this.states = this.senderForm.country.states;
    },
    handleSubmit() {
      this.$v.$touch();

      if (!this.resolvedName) {
        this.showErrorLoginNotification(
          "Unable to proceed, account number not validated"
        );
        return;
      }
      if (this.$v.$invalid) return;
      const nameArr = this.resolvedName.split(" ");
      const senderPayload = JSON.parse(
        window.localStorage.getItem("__send_money")
      );
      window.localStorage.setItem(
        "__send_money",
        JSON.stringify({ ...senderPayload, ...this.senderForm })
      );

      if (
        senderPayload.receiverFirstName.toLowerCase() ===
          nameArr[0].toLowerCase() &&
        senderPayload.receiverLastName.toLowerCase() ===
          nameArr[nameArr.length - 1].toLowerCase()
      ) {
        this.$emit("handleNext");
      } else {
        this.showErrorLoginNotification(
          "Unable to proceed, the resolved bank account does not match with the recievier's name"
        );
      }
    },
    async verifyName() {
      try {
        let response = await this.$store.dispatch("remittance/nameEnquiry", {
          payload: {
            accountNumber: this.senderForm.receiverAccountNumber,
            providerReference:
              this.senderForm.receiverBankCode.providerReference,
          },
          apiKey: getWalletApikey(this.$store),
        });

        this.resolvedName = response.data.data.accountName;
      } catch (error) {
        console.log(getError(error.response));
        this.resolvedName = '';
        this.showErrorLoginNotification("Unable to validate account number");
      }
    },
    async getCharges(value) {
      try {
        let response = await this.$store.dispatch("remittance/getChargeFee", {
          payload: {
            amount: value,
          },
          apiKey: getWalletApikey(this.$store),
        });

        this.charges = response.data.data.charge;
      } catch (error) {
        this.showErrorLoginNotification(getError(error.response));
      }
    },
    async fetchProviders() {
      const resp = await remittance.getProviders({
        apiKey: getWalletApikey(this.$store),
      });
      this.providers = resp.data.data.data;
    },
  },
  watch: {
    receiverAccountNumber(value) {
      value.length === 10 && this.verifyName();
    },
    amount(value) {
      this.getCharges(value);
    },
  },
  created() {
    this.fetchProviders();
  },
  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
    receiverAccountNumber() {
      return this.senderForm.receiverAccountNumber;
    },
    amount() {
      return this.senderForm.amount;
    },
  },
  mounted() {
    this.states = this.metaData.countries[0].states;
  },
};
</script>
