<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<PaymentPayoutFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('payoutRef')"
							:download="getPayouts"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="content">
					<v-server-table
						class="v-table row-pointer"
						ref="payoutRef"
						:columns="columns"
						:options="options"
						@row-click="handleClick"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
								{{ timeDateFormat(row.createdAt).time }}
							</span>
						</template>
						<template slot="BankAccountId" slot-scope="{ row }">
							<span>
								{{ titleCase(recipient(row.BankAccountId)) }}
							</span>
						</template>
						<template slot="amount" slot-scope="{ row }">
							<span>
								{{ toCurrency(row.amount) }}
							</span>
						</template>
						<template slot="charges" slot-scope="{ row }">
							<span>
								{{ toCurrency(row.amount + row.charges) }}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import PaymentPayoutFilters from "./PaymentPayoutFilters/PaymentPayoutFilters";
import { payoutTableHeadings } from "../enums";

export default {
	mixins: [componentData],
	name: "PaymentPayouts",
	data() {
		return {
			merchantBanks: [],
			emptyTitle: "No Payout Found",
			...this.tableOptions(payoutTableHeadings, 20),
		};
	},
	components: {
		PaymentPayoutFilters,
	},
	methods: {
		handleClick({ row }) {
			this.$router.push({
				path: "/wallet/payment/payout/details",
				query: { ref: row.reference },
			});
		},
		recipient(bankID) {
			const bank = this.merchantBanks.find(({ id }) => id === bankID);
			return bank ? bank.account_name : "-------------";
		},
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.bank_name)
				newFilters.bank_name = newFilters.bank_name.name;
			if (newFilters.status) newFilters.status = newFilters.status.value;
			this.handleRefetch(newFilters, this.getPayouts, "payoutRef");
		},
		async getMerchantBanks() {
			try {
				const response = await this.$store.dispatch(
					"wallets/getMerchantBanks",
					{ apiKey: this.getWalletApikey(this.$store) }
				);
				this.merchantBanks = response.data.data;
			} catch (error) {
				this.merchantBanks = [];
			}
		},
		async getPayouts() {
			this.handleAPICall("payment/getMerchantPayout");
		},
		async downloadPayout() {
			const download = (filterData) =>
				this.downloadReport({}, filterData.data.data);
			this.downloadTableReport("payment/getMerchantPayout", download);
		},
	},
	created() {
		this.getPayouts();
	},
	mounted() {
		this.setColums(payoutTableHeadings);
		this.getMerchantBanks();
	},
};
</script>
