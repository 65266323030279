<template>
	<div class="row">
		<div class="col-12">
			<!-- @confirm="(video) => handleStream(video)"  -->
			<transition mode="out-in" name="fade">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									Document Capture Request form
								</h2>
								<h5 class="user-profile-email sub">
									Capture data from physical identication
									cards.
								</h5>
							</div>
						</div>
						<div class="content">
							<span
								class="badge badge-warning"
								style="font-size: 14px; font-weight: 500"
								v-if="
									hasService &&
									userRole !== 'Secondary Customer'
								"
								>(Each API call costs &#8358;{{
									`${servicePrice || 0}`
								}})</span
							>
							<span
								v-if="!hasService"
								class="badge badge-danger"
								style="
									font-size: 14px;
									margin-top: 5px;
									font-weight: 500;
								"
								>You don't have access to this service</span
							>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="capturePhysicalIdentity">
							<div class="row mt-4">
								<div class="col-12">
									<CustomPhoneInput
										label="Phone Number"
										v-model="phonenumber"
										@blur="$v.phonenumber.$touch()"
										placeholder="Enter your phone number"
										:error="
											$v.phonenumber.$error &&
											formVuelidator($v.phonenumber)
										"
									/>
								</div>

								<!-- API Version details -->
								<div class="col-md-12">
									<label class="input-item-label">
										Select a service
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select a service"
										v-model="serviceSelected"
										@close="$v.serviceSelected.$touch()"
										@keydown.enter.native.prevent
										:required="!serviceSelected"
										:value="computedService[0]"
										:disabled="!computedService"
										:options="computedService"
										label="label"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.serviceSelected.$error"
									>
										<li class="parsley-required">
											Service field is required.
										</li>
									</ul>
								</div>

								<!-- Application details -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@close="$v.app.$touch()"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<!-- Currency Type  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select Currency Type
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Currency Type"
										v-model="currencyType"
										@keydown.enter.native.prevent
										:disabled="!customerWallets"
										:options="customerWallets"
										label="currency"
									></v-select>
								</div>

								<!-- Call ID -->
								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>
								<!-- <ul class="parsley-errors-list filled" v-if="uploadError">
                        <span class="text-danger">File size must be less than 5MB</span>
                      </ul> -->
								<div
									class="mt-4"
									:class="{
										'col-md-6': serviceSelected.both_sides,
										'col-md-12':
											!serviceSelected.both_sides,
									}"
								>
									<div class="form-group">
										<CustomUpload
											:name="'first'"
											:label="'Front Image'"
											:required="true"
											:validFileTypes="[
												'.jpg',
												'.jpeg',
												'.png',
											]"
											:handleChange="
												(e) => handleUpload('front', e)
											"
											:fileName="
												mediaData && mediaData.name
											"
											:error="
												(uploadError &&
													'File size must be less than 5MB') ||
												(!$v.mediaData.required &&
													$v.mediaData.$dirty &&
													'Front image is required')
											"
										/>
									</div>
								</div>

								<!-- phone numbeer -->
								<div
									class="col-md-6 mt-4"
									v-if="serviceSelected.both_sides"
								>
									<div class="form-group">
										<CustomUpload
											:name="'second'"
											:label="'Back Image'"
											:required="true"
											:validFileTypes="[
												'.jpg',
												'.jpeg',
												'.png',
											]"
											:handleChange="
												(e) => handleUpload('back', e)
											"
											:fileName="
												mediaData2 && mediaData2.name
											"
											:error="
												(uploadError &&
													'File size must be less than 5MB') ||
												(!$v.mediaData2.required &&
													$v.mediaData2.$dirty &&
													'Back image field is required')
											"
										/>
									</div>
								</div>
								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
										:disabled="
											isSubmitting == true || !hasService
										"
									>
										<span v-if="isSubmitting"
											>...Validating request</span
										>
										<span v-else>Validate data</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>

				<!-- Response Page -->
				<div class="user-settings-section" v-if="hasNewResponse">
					<div class="user-settings-section-header">
						<div class="user-settings-section-header-text">
							<h1>Document Capture Request Response</h1>
							<span class="text-span"
								>Preview of the information gotten from facial
								comparison request made</span
							>
						</div>
						<a
							href="#"
							class="btn btn-outline-danger"
							@click="hasNewResponse = false"
						>
							<i class="la la-close"></i>Close Panel
						</a>
					</div>
					<div class="preview-content">
						<div class="d-flex justify-content-between">
							<p class="preview-row d-flex flex-column">
								<span class="mb-2">Front Image</span>
								<img
									width="200px"
									height="200px"
									style="
										background-size: cover;
										background-color: black;
									"
									:src="
										verificationResult.id_card_front_image
									"
									:alt="`image for second comapred file`"
								/>
							</p>
							<p
								v-if="
									verificationResult.id_card_back_image !=
									'Not available'
								"
								class="preview-row d-flex flex-column"
							>
								<span class="mb-2">Back Image</span>
								<img
									width="200px"
									height="200px"
									style="
										background-size: cover;
										background-color: black;
									"
									:src="verificationResult.id_card_back_image"
									:alt="`image for second comapred file`"
								/>
							</p>
						</div>
						<template v-if="verificationResult.scanned_data">
							<p class="invoice-info-row preview-row">
								<span>Last Name</span>
								<span>{{
									verificationResult.scanned_data.lastName
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Other Name</span>
								<span>{{
									verificationResult.scanned_data.otherNames
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Full Name</span>
								<span>{{
									verificationResult.scanned_data.fullName
								}}</span>
							</p>
							<p
								v-if="
									verificationResult.scanned_data
										.documentNumber
								"
								class="invoice-info-row preview-row"
							>
								<span>Document Number</span>
								<span>{{
									verificationResult.scanned_data
										.documentNumber
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Issuer</span>
								<span>{{
									verificationResult.scanned_data.issuer
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Gender</span>
								<span>{{
									verificationResult.scanned_data.gender ==
									"M"
										? "Male"
										: "Female"
								}}</span>
							</p>
							<p
								v-if="
									verificationResult.scanned_data.dateOfBirth
								"
								class="invoice-info-row preview-row"
							>
								<span>Date of Birth</span>
								<span>{{
									formatDate(
										verificationResult.scanned_data
											.dateOfBirth
									)
								}}</span>
							</p>
							<p
								v-if="
									verificationResult.scanned_data.dateOfIssue
								"
								class="invoice-info-row preview-row"
							>
								<span>Date of Issuance</span>
								<span>{{
									formatDate(
										verificationResult.scanned_data
											.dateOfIssue
									)
								}}</span>
							</p>
							<p
								v-if="
									verificationResult.scanned_data
										.dateOfExpiration
								"
								class="invoice-info-row preview-row"
							>
								<span>Date of Expiration</span>
								<span>{{
									formatDate(
										verificationResult.scanned_data
											.dateOfExpiration
									)
								}}</span>
							</p>
						</template>
						<div class="gaps-1-5x"></div>
					</div>
				</div>

				<!-- End of transition -->
			</transition>

			<hr />
			<div class="row">
				<div class="col-12">
					<div class="user-details-info">
						<div class="user-details--top-info">
							<h2 class="user-profile-name">
								API Request Format
							</h2>
							<h5 class="user-profile-email sub">
								Representation of how the API call can be made
								with the appropriate data neccesary
							</h5>
						</div>
					</div>
				</div>

				<div class="col-12">
					<pre
						class="code code-html"
					><label style="top:35%;">End-Point</label><code>
        'POST' : `{{ API }}IdentityVerification/DocumentCapture`
      </code></pre>
				</div>
				<div class="col-12">
					<pre
						class="code code-html"
					><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{ clientID }}',
        'appname': '{{ app ? app.app_name : 'app_name' }}',
        'apikey': '{{ app ? app.api_key : 'app_key' }}',
      }
      </code></pre>
				</div>
				<div class="col-12">
					<!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
					<!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
					<!-- "dob": "{{dob}}" -->
					<pre
						class="code code-html"
					><label style="top:42%;">Body</label><code>
      {
        {{ serviceSelected.both_sides ? '"id_card_back_image": "id_card_back_image",' : null }}
        "id_card_front_image": "{{ "id_card_front_image" }}",
        "phone_number": "{{phonenumber.formatted ? getPhoneNumber(phonenumber) : "phonenumber"}}"
        "identity_type_id": "{{ serviceSelected ? serviceSelected.value : "service_id" }}",
      }
      </code></pre>
				</div>
				<div class="col-12">
					<pre
						class="code code-html"
					><label>Sample Response</label><code>
            {{ apiResponse ? apiResponse : `
              {
              "status_code": 200,
              "status": "Successful",
              "message": "Data validation result returned",
              "result": [
                {
                  "id_card_front_image": "https://51d257e7.ngrok.io/_/3cUnB_ae",
                  "id_card_back_image": "https://51d257e7.ngrok.io/_/FtaPHktw",
                  "scanned_data": {
                    "last_name": "AGORO",
                    "other_names": "ADEGBENGA BABATUNDE",
                    "full_name": [
                      "AGORO",
                      "ADEGBENGA",
                      "BABATUNDE"
                    ],
                    "passport_number": "LSR07064AA20",
                    "issuer": "LAGOS STATE",
                    "gender": "M",
                    "nationality": "",
                    "date_of_birth": {
                      "original_string": "29-03-1983",
                      "formatted_date_of_birth": "April 29, 1983"
                    },
                    "date_of_expiry": {
                      "original_string": "29-03-2023",
                      "formatted_date_of_expiry": "April 29, 2023"
                    },
                    "license_number": "LSR07064AA20",
                    "date_of_issue": {
                      "original_string": "08-11-2018",
                      "formatted_date_of_issue": "December 08, 2018"
                    }
                  },
                }
              ]
            }`}}
      </code></pre>
				</div>
			</div>
		</div>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		>
		</ApplicationInitializationModal>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required, requiredIf } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import moment from "moment";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";
import { getPhoneNumber } from "@/utils/helpers";

export default {
	props: {
		application: {
			type: Array,
		},
		services: {
			type: Array,
		},
	},
	mixins: [notifications, modalMixin],
	validations: {
		mediaData: { required },
		mediaData2: {
			required: requiredIf(function () {
				return this.serviceSelected.both_sides ? true : false;
			}),
		},
		phonenumber: {
			required,
			isPhoneValid: isPhoneValid("phonenumber"),
		},
		app: { required },
		serviceSelected: { required },
	},
	data() {
		return {
			initializedAppResult: {},
			app: "",
			versions: ["v1", "v2"],
			apiVersion: "",
			call_id: "",
			clientID: "",
			apikey: "",
			appname: "",
			phonenumber: "",
			serviceSelected: "",
			hasNewResponse: false,
			logData: [],
			mediaData: null,
			mediaData2: null,
			uploadError: false,
			uploadError2: false,
			apiResponse: "",
			customerWallets: this.$store.state.account.customerWallets,
			currencyType: "",
			image_1: null,
			image_2: null,
			hasService: false,
			servicePrice: "",
			isSubmitting: false,
			verificationResult: [],
			imageError: null,
		};
	},
	components: { CustomPhoneInput, CustomUpload },
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
		serviceSelected() {
			if (this.$refs.image_2) {
				this.$refs.image_2.value = null;
			}
			this.$refs.image_1.value = null;

			this.mediaData = null;
			this.mediaData2 = null;
		},
	},
	computed: {
		API() {
			return this.$store.state.appUrlV2;
		},
		metaServices() {
			return this.$store.state.metaData.services;
		},
		computedService() {
			let services = [
				{
					label: "(DL) Drivers Licence",
					value: "drivers_licence",
					both_sides: true,
				},
				{
					label: "(NIP) Nigerian International Passport",
					value: "passport",
					both_sides: false,
				},
				{
					label: "(NIN) National Identity Number",
					value: "national_id",
					both_sides: true,
				},
				{
					label: "(PVC) Permanent Voters Card",
					value: "pvc",
					both_sides: true,
				},
			];
			return services;
		},
	},
	methods: {
		getPhoneNumber,
		formVuelidator,
		formatDate(obj) {
			const { year, month, day } = obj;
			return moment(`${year}-${month}-${day}`).format("MMMM Do YYYY");
		},
		checkRequirements(e) {
			if (this.serviceSelected == "") {
				this.$v.serviceSelected.$touch();
				e.preventDefault();
				return;
			}

			if (this.serviceSelected.both_sides == true) {
				e.preventDefault();
				return;
			}
		},
		async handleUpload(type, { target: { files } }) {
			const file = files[0];
			const dataSize = 1024 * 1024;

			if (file) {
				if (type == "front") {
					if (file.size / dataSize < 5) {
						this.mediaData = file;
						console.log(this.mediaData);
						this.uploadError = false;
					} else {
						this.uploadError = true;
					}
				} else if (type == "back") {
					if (file.size / dataSize < 5) {
						this.mediaData2 = file;
						this.uploadError2 = false;
					} else {
						this.uploadError2 = true;
					}
				}
			}
		},
		computeService() {
			if (this.services) {
				this.services.filter((service) => {
					if (service.service_label.includes("Document Capture")) {
						this.hasService = true;
						console.log(this.servicePrice);
						this.servicePrice = service.pivot.price_per_call;
					}
				});
			}
		},
		getRequestData() {
			if (!this.hasBundleBilling) {
				this.call_id = "";
			}

			let imagesData = new FormData();
			imagesData.append(
				"phone_number",
				this.phonenumber.formatted
					? getPhoneNumber(this.phonenumber)
					: ""
			);
			imagesData.append("identity_type", this.serviceSelected.value);
			imagesData.append(
				"id_card_front_image",
				new File([this.mediaData], "front.png", { type: "image/png" })
			);
			if (this.serviceSelected.both_sides) {
				imagesData.append(
					"id_card_back_image",
					new File([this.mediaData2], "back.png", {
						type: "image/png",
					})
				);
			}
			let verificationData = {
				clientID: this.clientID,
				appname: this.app.app_name,
				apikey: this.app.api_key,
				query: this.call_id,
				payload: {
					currency_type: this.currencyType.currency,
				},
			};
			return { verificationData, imagesData };
		},
		async capturePhysicalIdentity() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log("[invalid fields] ->");
				return;
			}

			if (this.uploadError || this.uploadError2) return;

			let verificationData = this.getRequestData();
			this.isSubmitting = true;
			try {
				let apiResponse = await this.$store.dispatch(
					"services/DocumentCapture",
					verificationData
				);
				this.isSubmitting = false;
				this.apiResponse = apiResponse;
				this.showSuccessNotification(apiResponse.message);
				this.verificationResult = apiResponse.result[0];
				console.log();
				this.hasNewResponse = true;
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSubmitting = false;
				console.log("error :", error);
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.computeService();
		this.clientID = this.$store.state.account.clientID;
	},
};
</script>

<style scoped>
.upload-input {
	height: auto;
}
</style>
