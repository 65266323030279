<template>
	<div>
		<button
			class="btn btn-sm btn--primary ml-auto"
			:disabled="loading"
			@click="checkBalance"
		>
			<Spinner v-if="loading" :color="'#fff'" />
			<span v-else>Check Balance</span>
		</button>

		<Modal
			:id="'checkBal'"
			:title="'Checking Balance'"
			:toggle="handleCloseModal"
			class="text-left"
		>
			<div class="text-center">
				<h5>Your Fund Transfer Account Balance is</h5>
				<h2 class="font-weight-bold">{{ toCurrency(balance) }}</h2>

				<div class="col-6 mt-4 mx-auto">
					<button
						type="submit"
						class="btn btn-sm btn--primary w-100 "
						@click="handleCloseModal"
					>
						Done
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../utils/Modal/Modal";
	import Spinner from "../../../../utils/Spinner/Spinner";
	import { toCurrency } from "../../../../utils/helpers";

	const initialState = () => ({
		loading: false,
		balance: 0,
	});

	export default {
		mixins: [notifications],
		name: "CheckBalanceButton",
		data() {
			return { ...initialState() };
		},
		components: { Modal, Spinner },
		methods: {
			toCurrency,
			setError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				$("#checkBal").modal("hide");
			},
			async checkBalance() {
				try {
					this.loading = true;

					const response = await this.$store.dispatch(
						"fundstransfer/checkBankBalance",
						{ pin: "kghxqwveJ3eSQJip/cmaMQ==" }
					);
					const { status, data } = response;

					if (status === 200) {
						this.loading = false;
						this.balance = data.results[0].account_balance;
						$("#checkBal").modal("show");
					} else this.setError(data.message);
				} catch (error) {
					this.setError(error.message);
				}
			},
		},
	};
</script>
