<template>
	<div
		class="tab-pane fade"
		id="otp_service"
		role="tabpanel"
		aria-labelledby="otp_service-tab"
	>
		<div class="flex__end">
			<span
				class="badge badge-warning"
				style="font-size: 14px; font-weight: 500"
				>(Each API call costs &#8358;{{ servicePrice }})</span
			>
		</div>

		<form
			@submit.prevent="handleSubmit"
			class="row border-bottom pb-4 my-4"
		>
			<div class="col-12">
				<CustomPhoneInput
					:name="'phone_number'"
					:required="true"
					label="Phone Number"
					v-model="request.phone_number"
					@blur="$v.request.phone_number.$touch()"
					:placeholder="'Enter Phone Number'"
					:error="
						formErrors.phone_number ||
						($v.request.phone_number.$error &&
							formVuelidator($v.request.phone_number))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-md-6">
				<CustomSelect
					name="app"
					:optionLabel="'app_name'"
					label="Application"
					placeholder="Select Application"
					:options="applications"
					v-model="request.app"
					:required="true"
					:error="formErrors.app"
					:handleError="handleError"
				/>
			</div>

			<div class="col-md-6">
				<CustomSelect
					name="apiVersion"
					label="API Version"
					placeholder="Select API version"
					:options="['v1', 'v2']"
					v-model="request.apiVersion"
					:required="true"
					:error="formErrors.apiVersion"
					:handleError="handleError"
				/>
			</div>

			<div class="col-md-12">
				<CustomInput
					:name="'message'"
					v-model="request.message"
					:label="'Message'"
					:placeholder="'This is a sample {code}, expires in 60 seconds'"
					:required="true"
					:error="formErrors.message"
					:handleError="handleError"
				/>
			</div>

			<div class="col-md-4 mt-4">
				<button
					type="submit"
					class="btn btn-lg btn-primary w-100 mt-1"
					:disabled="loading"
				>
					<Spinner v-if="loading" />
					<span v-else>Generate OTP</span>
				</button>
			</div>
		</form>

		<MessagingAPIFormat :request="request" :isOTP="true" />

		<ValidateOTPModal
			:id="'verifyOtpModal'"
			:closeModal="closeModal"
			:timerCount="timerCount"
			:request="getRequestData()"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import MessagingAPIFormat from "../common/MessagingAPIFormat/MessagingAPIFormat";
import ValidateOTPModal from "../modals/ValidateOTPModal/ValidateOTPModal";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import Spinner from "../../../../utils/Spinner/Spinner";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { required } from "vuelidate/lib/validators";

const initialState = () => ({
	request: {
		app: "",
		phone_number: "",
		message: "",
		apiVersion: "",
		expiry_time: 60,
	},
	loading: false,
	formErrors: {},
	successMsg: false,
	timerCount: 0,
});

export default {
	name: "OTPService",
	mixins: [notifications],
	validations: {
		request: {
			phone_number: {
				required,
				isPhoneValid: isPhoneValid("phone_number"),
			},
		},
	},
	data() {
		return {
			...initialState(),
			formErrors: {},
			clientID: this.$store.state.account.clientID,
		};
	},
	props: ["servicePrice", "applications"],
	components: {
		CustomSelect,
		CustomInput,
		Spinner,
		MessagingAPIFormat,
		ValidateOTPModal,
		CustomPhoneInput,
	},
	methods: {
		formVuelidator,
		handleSubmit(e) {
			e.preventDefault();
			const { formErrors, isValid } = formValidator(this.request);
			if (isValid) this.sendSMS();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		openModal() {
			$("#verifyOtpModal").modal("show");
		},
		closeModal() {
			$("#verifyOtpModal").modal("hide");
			Object.assign(this.$data, { ...initialState(), phone_number: { normal:'' } });
      this.request.phone_number = {normal: ''}
		},
		getRequestData() {
			const { message, app, phone_number } = this.request;
			const headers = {
				clientid: this.clientID,
				appname: app.app_name,
				apikey: app.api_key,
			};
			const payload = {
				expiry_time: 60,
				phone_number:
					phone_number.formatted &&
					phone_number.formatted.replace(/\s/g, ""),
				country_code: phone_number.countryCallingCode,
				message,
			};
			return { headers, payload };
		},
		async sendSMS() {
			let request = this.getRequestData();
			this.loading = true;

			try {
				let response = await this.$store.dispatch(
					"services/sendOTP",
					request
				);

				if (response.status === 200) {
					this.loading = false;
					this.timerCount = 60;
					this.openModal();
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
