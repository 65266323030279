<template>
    <div>
        <h2
            v-if="useText"
            class="font-weight-bold text--primary m-0 pointer"
            @click="openCreateCoupon"
        >
            +
        </h2>
        <button
            v-else
            type="button"
            class="btn btn-sm btn--primary px-4 ml-3"
            @click="openCreateCoupon"
        >
            Create Coupon
        </button>
        <CreateCouponModal
            :id="'createCoupon'"
            :closeModal="closeModal"
            :couponRef="couponRef"
            :refetch="refetch"
        />
    </div>
</template>

<script>
import CreateCouponModal from "../../modals/CreateCouponModal/CreateCouponModal";

export default {
    name: "CreateCouponButton",
    props: ["couponRef", "useText", , "refetch"],
    methods: {
        openCreateCoupon() {
            $("#createCoupon").modal("show");
        },
        closeModal() {
            $("#createCoupon").modal("hide");
        },
    },
    components: {
        CreateCouponModal,
    },
};
</script>
