<template>
	<div
		class="tab-pane fade"
		id="Bank_account"
		role="tabpanel"
		aria-labelledby="Bank_account-tab"
	>
		<div class="card pt-4">
			<v-server-table
				class="v-table row-pointer"
				:columns="columns"
				:options="options"
			/>
		</div>
	</div>
</template>

<script>
	import WalletInfo from "@/components/wallet/WalletInfo";
	import {
		timeDateFormat,
		getWalletApikey,
		tableOptions,
	} from "../../../../../../utils/helpers";

	const tableHeadings = {
		bank_name: "Bank Name",
		account_name: "Account Name",
		account_number: "Account Number ",
		currency: "Currency",
	};

	export default {
		name: "CustomerBankAccounts",
		components: {
			WalletInfo,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			timeDateFormat,
			async getAccount(data, url) {
				return (
					await this.$store.dispatch(
						"reservedAccounts/getReservedAccounts",
						{
							apiKey: getWalletApikey(this.$store),
							params: {
								...data,
								customer: this.$route.params.reference,
							},
						}
					)
				).data.data;
			},
		},
		data() {
			return { ...tableOptions(tableHeadings) };
		},
		created() {
			this.options.requestFunction = async (data) => {
				const payload = {
					apiKey: getWalletApikey(this.$store),
					params: {
						...data,
						customer: this.$route.params.reference,
					},
				};
				const response = (
					await this.$store.dispatch("wallets/getWallets", payload)
				).data.data;

				if (response.total === 0) {
					return (
						await this.$store.dispatch(
							"reservedAccounts/getReservedAccounts",
							payload
						)
					).data.data;
				}
				return response;
			};
		},
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
