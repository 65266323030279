import customers from "@/services/wallets/customers";

export default {
  namespaced: true,
  actions: {
    async sendAccStatement({}, payload) {
      try {
        return await customers.sendAccStatement(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getAllCustomers({}, payload) {
      try {
        return await customers.getAllCustomers(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getCustomer({}, payload) {
      try {
        return await customers.getCustomer(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async createCustomer({}, payload) {
      return await customers.createCustomer(payload);
    },
    async updateCustomer({}, payload) {
      try {
        return await customers.updateCustomer(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getCustomerCard({}, payload) {
      try {
        return await customers.getCustomerCard(payload);
      } catch (error) {
        throw Error(error);
      }
    },
  },
  async getCustomer({}, payload) {
    try {
      return await customers.getCustomer(payload);
    } catch (error) {
      throw Error(error);
    }
  },
  async createCustomer({}, payload) {
    return await customers.createCustomer(payload);
  },
  async updateCustomer({}, payload) {
    try {
      return await customers.updateCustomer(payload);
    } catch (error) {
      throw Error(error);
    }
  },
};
