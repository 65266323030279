<template>
  <div>
    <div class="row">
      <div class="col-12 mt-3">
        <Spinner v-if="loading" />
        <div class="p-md-4 p-3" v-else>
          <div class="my-4">
            <router-link to="/services/reports" class="mr-2 text-secondary"
              >Reports</router-link
            >
            &lt;
            <router-link to="" class="ml-2 text-primary">
              Report Details</router-link
            >
          </div>
          <div class="">
            <div class="SectionItem no-b-b mb-3 d-block">
              <span class="title">
                <router-link to="/services/reports" class="nav-sub-link"
                  ><img
                    src="/assets/img/icons/close.svg"
                    alt="image"
                    height="30"
                /></router-link>
                Close</span
              >
            </div>
          </div>
          <div
            :class="
              details.report && details.report.status === 'Not Found'
                ? ' my-5 bg-danger-light rounded text-danger p-2'
                : ' my-5 bg-success-light rounded text-success p-2'
            "
          >
            <span
              v-if="details.report && details.report.status === 'Not Found'"
            >
              <img
                src="/assets/img/icons/error.svg"
                alt="image"
                width="13"
                height="13"
                class="mr-2"
              />
            </span>
            <span v-else>
              <img
                src="/assets/img/icons/CaretDown.svg"
                alt="image"
                class="mr-2"
              />
            </span>
            <span v-if="details.report"
              ><span v-if="details.report.status">{{
                details.report.status
              }}</span></span
            >
            <span v-else>User found</span>
          </div>
          <div>
            <h5 class="font-weight-bold">Search Information</h5>
            <div class="row" v-if="details.search_name">
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">SEARCH name</span
                ><br />
                <span>{{ titleCase(details.search_name) }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">SEARCH TYPE</span
                ><br />
                <span>{{ titleCase(details.search_type) }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase"
                  >Verification ID</span
                ><br />
                <span>{{ details.verification_id }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Date REQUESTED</span
                ><br />
                <span>{{ details.created_at }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Initiated By</span
                ><br />
                <span>{{ details.initiated_by }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Report URL</span
                ><br />
                <span>{{ details.report_url }}</span>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">SEARCH TERM</span
                ><br />
                <span>{{ titleCase(details.registration_number) }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase"
                  >Verification ID</span
                ><br />
                <span>{{ details.report.verification_id }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Date REQUESTED</span
                ><br />
                <span>{{ details.created_at }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Initiated By</span
                ><br />
                <span>{{ details.report.initiated_by }}</span>
              </div>
            </div>
          </div>

          <div v-if="details.report">
            <div v-if="details.report.status === 'Verified'">
              <div class="mt-5">
                <button
                  v-if="!details.search_name"
                  class="btn btn-sm btn--secondary--border px-4"
                  @click="downloadReport()"
                >
                  <img
                    src="/assets/img/icons/document-download.svg"
                    class="mr-2"
                    alt=""
                  />
                  Download Report
                </button>
                <button
                  class="btn btn-sm btn--secondary--border px-4 mx-3"
                  @click="fetchReportDetails()"
                >
                  <img
                    src="/assets/img/icons/refresh-2.svg"
                    class="mr-2"
                    alt=""
                  />
                  Refresh Data
                </button>
                <button
                  class="btn btn-sm btn--secondary--border px-4 border-danger"
                  @click="openDeleteReport()"
                >
                  <img
                    src="/assets/img/icons/trash-2.svg"
                    class="mr-2"
                    alt=""
                  />
                  Delete Verification
                </button>
              </div>
            </div>
          </div>

          <DeleteReportModal
            :id="'delete-report-modal'"
            :closeModal="closeModal"
            :item="details"
          />
          <div v-if="details.report">
            <div v-if="details.report.status === 'Verified'">
              <div class="content__slider mt-4">
                <TabsV2
                  :tabNames="
                    details.registration_number ? tabNames : NINTabNames
                  "
                  :tabId="'reportTab'"
                  :tabLink="true"
                />
              </div>
              <transition mode="out-in" name="slide">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import { notifications } from "../../../../public/assets/mixins/notifications";
import DeleteReportModal from "../modals/DeleteReportModal/DeleteReportModal";
import TabsV2 from "../../../utils/Tabs/TabsV2";
import Spinner from "../../../utils/Spinner/Spinner";
import { getWalletApikey, titleCase } from "@/utils/helpers";

const BusinessTabName = (id) => [
  {
    tabName: "Ownership",
    value: "ownership",
    link: `/services/reports/${id}/ownership`,
  },
  {
    tabName: "Personnel",
    value: "personnel",
    link: `/services/reports/${id}/personnel`,
  },
  {
    tabName: "Company Information",
    value: "company_information",
    link: `/services/reports/${id}/company-information`,
  },
];
const NINTabName = (id) => [
  {
    tabName: "Main Information",
    value: "Main_Information",
    link: `/services/reports/${id}/main_information`,
  },
  {
    tabName: "Other Information",
    value: "Other_Information",
    link: `/services/reports/${id}/other_information`,
  },
];

export default {
  mixins: [componentData, notifications],
  name: "ServiceReportDetails",
  data() {
    return {
      tabNames: BusinessTabName(this.$route.params.id),
      NINTabNames: NINTabName(this.$route.params.id),
      details: null,
      loading: false,
    };
  },
  components: { TabsV2, Spinner, DeleteReportModal },
  methods: {
    async fetchReportDetails() {
      this.loading = true;
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          id: this.$route.params.id,
        },
      };
      const resp = (
        await this.$store.dispatch("services/getReportDetails", payload)
      ).data.data;
      this.loading = false;
      this.details = resp;
    },
    async downloadReport() {
      if (this.details.nin) {
        window.open(this.details.report.report_url, "_blank");
      } else {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            id: this.$route.params.id,
          },
        };
        const resp = (
          await this.$store.dispatch("services/downloadReportDetails", payload)
        ).data.message;

        if (resp === "Success") {
          this.showSuccessNotification(
            "Report details has been sent to your mail."
          );
        }
      }
    },
    openDeleteReport() {
      $("#delete-report-modal").modal("show");
    },
    closeModal() {
      $("#delete-report-modal").modal("hide");
    },
    titleCase,
  },
  created() {
    this.fetchReportDetails();
  },
};
</script>
