<template>
	<div class="">
		<div class="d-flex pb-4">
			<div class="az-img-user mr-2">
				<img src="/assets/img/avatar1.svg" alt="" />
			</div>
			<div v-if="wallet.Customer" class="">
				<h4 v-if="wallet.Customer.business" class="m-0">
					{{ wallet.Customer.business_details.name }}
				</h4>
				<h4 v-else class="m-0">
					{{ titleCase(wallet.Customer.first_name) }}
					{{ titleCase(wallet.Customer.last_name) }}
				</h4>
				<span
					>Added on
					{{ timeDateFormat(wallet.Customer.createdAt).date }}
					{{ timeDateFormat(wallet.Customer.createdAt).time }}</span
				>
			</div>
		</div>

		<div class="accordion" id="walletAccordion">
			<WalletPersonalInfo :wallet="wallet" />

			<WalletAdvanceInfo :wallet="wallet" />
		</div>
	</div>
</template>

<script>
import WalletPersonalInfo from "./WalletPersonalInfo/WalletPersonalInfo";
import WalletAdvanceInfo from "./WalletAdvanceInfo/WalletAdvanceInfo";
import WalletAccordion from "./WalletAccordion/WalletAccordion";
import { timeDateFormat, titleCase } from "../../../../../utils/helpers";

export default {
	name: "WalletDetailSideView",
	data() {
		return {
			blacklist: false,
		};
	},
	props: ["wallet"],
	components: {
		WalletPersonalInfo,
		WalletAdvanceInfo,
		WalletAccordion,
	},
	methods: {
		timeDateFormat,
		titleCase,
	},
};
</script>
