import { apiClient } from "./api";

const token = localStorage.getItem("salt-token");
export default {
	requestFtService(FTService) {
		return apiClient.get(
			`/Authorize/FundsTransfer/RequestFundTransferService`,
			FTService
		);
	},
	submitBusinessInformation(businessInformation) {
		return apiClient.post(
			`/Authorize/FundsTransfer/BusinessInformation`,
			businessInformation
		);
	},
	submitBusinessSocials(businessSocials) {
		return apiClient.post(
			`/Authorize/FundsTransfer/BusinessSocials`,
			businessSocials
		);
	},
	submitIP(IP) {
		return apiClient.post(`/Authorize/FundsTransfer/submitIP`, IP);
	},
	submitBusinessEmails(businessEmails) {
		return apiClient.post(
			`/Authorize/FundsTransfer/BusinessEmails`,
			businessEmails
		);
	},
	submitBusinessContactInfo(businessContactInfo) {
		return apiClient.post(
			`/Authorize/FundsTransfer/BusinessContactInfo`,
			businessContactInfo
		);
	},
	submitBusinessAddressInfo(businessAddressInfo) {
		return apiClient.post(
			`/Authorize/FundsTransfer/BusinessAddressInfo`,
			businessAddressInfo
		);
	},
	getBusinessInfo(businessInfo) {
		return apiClient.get(
			`/Authorize/FundsTransfer/BusinessInformation`,
			businessInfo
		);
	},
	completeFTSubmission(FTSubmission) {
		return apiClient.get(
			`/Authorize/FundsTransfer/CompleteFundsTransferSubmission`,
			FTSubmission
		);
	},
	submitFundingSource(fundingSource) {
		return apiClient.post(
			`/Authorize/FundsTransfer/FundingSource`,
			fundingSource
		);
	},
	getFundingSource(fundingSource) {
		return apiClient.get(
			`/Authorize/FundsTransfer/FundingSource`,
			fundingSource
		);
	},
	editBillingType(billingType) {
		return apiClient.put(
			`/Authorize/FundsTransfer/EditBillingType`,
			billingType
		);
	},
	getBillingType(billingType) {
		return apiClient.get(
			`/Authorize/FundsTransfer/BillingType`,
			billingType
		);
	},
	getBankList(bankList) {
		return apiClient.get(`/Authorize/BankList`, bankList);
	},
	verifyAccountNumber(accountNumber) {
		return apiClient.post(
			`/Authorize/NotEncrypted/FundsTransfer/VerifyBankAccount`,
			accountNumber
		);
	},
	checkBankBalance(bankBalance) {
		return apiClient.post(
			`/Authorize/NotEncrypted/FundsTransfer/CheckBankBalance`,
			bankBalance
		);
	},
	initiateTransfer(transferDetails) {
		return apiClient.post(
			`/Authorize/NotEncrypted/FundsTransfer/Transfer`,
			transferDetails
		);
	},
  initiateSingleTransfer({headers, payload}) {
    return apiClient.post(
			`/Authorize/FundsTransfer/Transfer`,
			payload,
      { headers }
		);
  },
	submitBusinessSettings(settings) {
		return apiClient.put(
			`/Authorize/FundsTransfer/BusinessSettings`,
			settings
		);
	},
	getBusinessSettings(settings) {
		return apiClient.get(
			`/Authorize/FundsTransfer/BusinessSettings`,
			settings
		);
	},
	finalizeTransfer(OTPDetails) {
		console.log("data jennie", OTPDetails);
		let headers = {
			clientid: OTPDetails.clientid,
			publickey: OTPDetails.publickey,
		};
		return apiClient.post(
			`/Authorize/NotEncrypted/FundsTransfer/FinalizeTransfer`,
			OTPDetails.data,
			{ headers }
		);
	},
	getAllTransactions(Transactions) {
		let headers = {
			clientid: Transactions.clientid,
			publickey: Transactions.publickey,
		};
		return apiClient.get(`/Authorize/FundsTransfer/Transactions`, {
			headers,
		});
	},
	getSingleTransaction(Transaction) {
		let headers = {
			clientid: Transaction.clientid,
			publickey: Transaction.publickey,
		};

		return apiClient.get(
			`/Authorize/FundsTransfer/Transactions/${Transaction.transaction_reference}`,
			{ headers }
		);
	},
	getAllBeneficiaries(Beneficiaries) {
		return apiClient.get(
			`/Authorize/FundsTransfer/Beneficiaries`,
			Beneficiaries
		);
	},
	encryptData(data) {
		const headers = { Authorization: `Bearer ${token}` };
		return apiClient.post(`Authorize/EncryptData`, data, { headers });
	},
	decryptData(data) {
		const headers = { Authorization: `Bearer ${token}` };
		return apiClient.post(`Authorize/DecryptData`, data, { headers });
	},
	fetchKeys() {
		return apiClient.get(
			`Authorize/FundsTransfer/Customer/GetAuthorizationKeys`
		);
	},
	fetchAllBeneficiaries() {
		return apiClient.get(`Authorize/FundsTransfer/Beneficiaries`);
	},
	verifyAccountsBulk({ formData, headers }) {
    return apiClient.post(
      `/Authorize/FundsTransfer/BulkTransferFile`,
      formData,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
	initiateBulkTransfer({ headers: apiHeaders, payload }) {
		const headers = { Authorization: `Bearer ${token}`, ...apiHeaders };

		return apiClient.post(
			`/Authorize/FundsTransfer/App/BulkTransfer`,
			payload,
			{ headers }
		);
	},
};
