<template>
	<DefaultContent
		:image="'empty-resources.png'"
		:title="'No Wallets Found'"
		:subtitle="'Click the button below to get started'"
	>
		<ActivateWalletButton />
	</DefaultContent>
</template>

<script>
	import ActivateWalletButton from "../../WalletOverview/buttons/ActivateWalletButton/ActivateWalletButton";

	export default {
		name: "EmptyWallet",
		components: {
			ActivateWalletButton,
		},
	};
</script>
