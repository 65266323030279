<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12 ">
        <CustomInput :name="'customer_id'" :placeholder="'Enter IUC Number'" v-model="request.customer_id"
          :error="formErrors.customer_id" :handleError="handleError" :label="'IUC Number'" required="true"
          :isNum="true" />
        <CustomerValidationStatus :verifying="verifying" :verifyError="verifyError" :user="request.customer_name" />
      </div>

      <div class="col-sm-6 ">
        <CustomSelect name="bouquet" label="Bouquet" :optionLabel="'name'" placeholder="Select Bouquet"
          :options="bouquetList" v-model="request.bouquet" :required="true" :error="formErrors.bouquet"
          :handleError="handleError" />
      </div>

      <div class="col-sm-6">
        <CustomSelect name="plan" label="Plan" placeholder="Select Plan" :getLabel="getPlanLabel" :options="planList"
          v-model="request.plan" :required="true" :error="formErrors.plan" :handleError="handleError" />
      </div>

      <div class="col-sm-6 mt-3">
        <PayBillsButton :disabled="loading || !request.customer_name" :bill="getRequestData()"
          :handleSubmit="handleSubmit" :reset="reset" :setApiResponse="setApiResponse" />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="user-details-info">
          <div class="user-details--top-info">
            <h2 class="user-profile-name">API Request Format</h2>
            <h5 class="user-profile-email sub">
              Representation of how the API call can be made with the
              appropriate data neccesary
            </h5>
          </div>
        </div>
      </div>

      <div class="col-12">
        <pre class="code code-html"><label style="top:35%;">End-Point</label><code>
        'POST' : `{{ API }}Billers/pay`
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{ clientID }}',
        'appname': '{{ app ? app.app_name : 'app_name' }}',
        'apikey': '{{ app ? app.api_key : 'app_key' }}',
      }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label style="top:42%;">Body</label><code>
       {
          amount: '{{ request.plan ? request.plan.price : 'price' }}',
          service_id: '{{ billService ? billService.slug : 'service_id' }}',
          customer_id: '{{ validatedRes ? validatedRes.customerNo : 'customer_id' }}'',
          customer_name: '{{ request.customer_name ? request.customer_name : "customer_name" }}',
          invoice_period: '{{ request.plan ? request.plan.invoicePeriod : "invoice_perioed" }}',
          api_service_id: '{{ billService ? billService.id : "api_service_id" }}',
          service_type: 'cable_tv',
          product_codes: ["{{ request.bouquet ? request.bouquet.code : "bouqet_code" }}"],
      }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>Sample Response</label><code>
            {{ apiResponse ? apiResponse : `
                    {
                      "status_code": 200,
                      "message": "Success",
                      "data": {
                          "message": "successful",
                          "amount": 2150,
                          "type": "dstv",
                          "package": "Padi",
                          "transactionRef": "6038087f-9dce-45c8-838d-796a317fea0d",
                          "transactionNo": "430081666695613"
                      }
                    }`
            }}
      </code></pre>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import CustomerValidationStatus from "../CustomerValidationStatus/CustomerValidationStatus";
import PayBillsButton from "../buttons/PayBillsButton/PayBillsButton";

const initialState = () => ({
  request: {
    customer_id: "",
    bouquet: "",
    plan: "",
    customer_name: "",
  },
  bouquetList: [],
  planList: [],
  validatedRes: {},
  formErrors: {},
  loading: false,
  verifying: false,
  verifyError: "",
  apiResponse: "",
  clientID: ""
});

export default {
  name: "CableTVBiller",
  data() {
    return { ...initialState() };
  },
  props: ["app", "billService", "reset"],
  components: {
    CustomSelect,
    CustomInput,
    CustomerValidationStatus,
    PayBillsButton,
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    setApiResponse(response) {
      this.apiResponse = response
    },
    getPlanLabel(plan) {
      const { price, monthsPaidFor: mnth } = plan || {};
      return `N${price} | ${mnth}Month${mnth > 1 ? "s" : ""}`;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleSubmit() {
      const { formErrors, isValid } = formValidator(this.request);
      if (isValid) return true;
      else this.formErrors = formErrors;
      return false;
    },
    getRequestData() {
      const { plan, customer_name, bouquet } = this.request || {};
      return {
        headers: {
          clientid: this.$store.state.account.clientID,
          appname: this.app.app_name,
          apikey: this.app.api_key,
        },
        payload: {
          amount: plan.price,
          service_id: this.billService.slug,
          customer_id: this.validatedRes.customerNo
            ? this.validatedRes.customerNo.toString()
            : "",
          customer_name,
          invoice_period: plan.invoicePeriod,
          api_service_id: this.billService.id,
          service_type: "cable_tv",
          product_codes: [bouquet.code],
        },
      };
    },
    getBillerData() {
      return {
        payload: {
          customer_id: this.request.customer_id,
          service_code: this.billService.slug,
          service_type: "cable_tv",
          api_service_id: this.billService.id,
        },
      };
    },
    handleValidationError() {
      this.verifying = false;
      this.request.customer_name = "";
      this.verifyError = true;
    },
    async validateBiller() {
      try {
        this.verifying = true;
        this.verifyError = false;

        const payload = this.getBillerData();

        const response = await this.$store.dispatch(
          "services/validateCableTV",
          payload
        );

        const { status, data } = response || {};

        if (status === 200 && data.status_code === 200) {
          const { statusDescription } = data.data || {};
          this.validatedRes = statusDescription;
          this.request.customer_name =
            statusDescription.firstname || statusDescription.customerName;
          this.verifying = false;
        } else this.handleValidationError();
      } catch (error) {
        this.handleValidationError();
      }
    },
    async getPlans() {
      try {
        const response = await this.$store.dispatch(
          "services/getCableTVPlans",
          { payload: { service_type: this.billService.slug } }
        );

        if (response.status === 200) {
          this.bouquetList = response.data.data;
        }
      } catch (error) {
        this.bouquetList = "";
      }
    },
  },
  watch: {
    "request.customer_id"(value) {
      if (value.length >= 8) this.validateBiller();
    },
    "request.bouquet"(value) {
      const plans = value.availablePricingOptions;
      if (plans) this.planList = plans;
      else this.planList = [];
      this.request.plan = "";
    },
    billService(newValue, oldValue) {
      if (oldValue !== newValue)
        Object.assign(this.$data, { ...initialState() });
      this.getPlans();
    },
  },
  async mounted() {
    this.billService && this.getPlans();
    try {
      this.clientID = this.$store.state.account.clientID;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
