<template>
  <div class="row">
    <div class="col-12 mt-3">
      <div class="flex__end"><InviteAgentButton :refetch="refetch" /></div>
      <div class="px-3">
        <FilterWrapper>
          <template slot="pageSelect"> </template>
          <template slot="filterForm">
            <AgentsTableFilters
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="clearFilter"
              :download="downloadAgents"
            />
          </template>
        </FilterWrapper>
      </div>
    </div>

    <div v-if="loading" class="col-12">
      <div class="flex__center my-5 py-5">
        <Spinner :size="'30px'" />
      </div>
    </div>

    <div v-else-if="errorMsg" class="col-12">
      <FailedState :errorMsg="errorMsg" :refetch="refetch" />
    </div>

    <div class="col-12" :class="tbClass">
      <v-server-table
        ref="agentRef"
        class="v-table"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @error="setError"
        @row-click="handlClick"
      >
      <template slot="agent" slot-scope="{ row }">
          <span>
            {{ `${row.firstName} ${row.lastName}` }}
          </span>
        </template>
        <template slot="created_at" slot-scope="{ row }">
          {{ timeDateFormat(row.created_at).date }}
        </template>
        <template slot="status" slot-scope="{ row }">
          <span
            :class="row.status === 'active' ? 'text-success' : 'text-danger'"
            >{{ titleCase(row.status) }}</span
          >
        </template>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import AgentsTableFilters from "./AgentsTableFilters/AgentsTableFilters";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import InviteAgentButton from "../buttons/InviteAgentButton/InviteAgentButton.vue";
import Spinner from "@/utils/Spinner/Spinner";
import { agentObj } from "../data";
import { agentCsvRows } from "../enums";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  getWalletApikey,
} from "@/utils/helpers";

const tableHeadings = {
  reference: "Receiver ID",
  email: "Email Address",
  agent: "Assigned Agent",
  phoneNumber: "Phone Number",
  created_at: "Date Added",
  status: "Status",
};

export default {
  mixins: [componentData],
  name: "Agent",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 20,
      filters: {},
      ...tableOptions(tableHeadings, 20),
      agentObj,
    };
  },
  components: {
    AgentsTableFilters,
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
    InviteAgentButton,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    handlClick({ row }) {
      this.$router.push(`/wallet/terminal/agents/${row.reference}`);
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getAgents();
      this.$refs.agentRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.agentRef.refresh();
    },
    async getAgents() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            limit: this.size,
            ...this.filters,
          },
        };

        try {
          const resp = (await this.$store.dispatch("pos/getAgents", payload))
            .data.data;
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
    async downloadAgents() {
      const download = (filterData) =>
        this.downloadReport(agentCsvRows, filterData.data.data);
      this.downloadTableReport("pos/getAgents", download);
    },
  },

  created() {
    this.getAgents();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
