<template>
  <div class="px-3">
    <div class="p-2 mx-2 mt-3 bg-light-blue">Summary</div>
    <div class="p-4">
      <div class="row table-like-header">
        <div class="col-4 p-2">Company Name</div>
        <div class="col-4 p-2">Company Registration Number</div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-body">
        <div class="col-4 p-2">{{ details.company_name || 'N/A'  }}</div>
        <div class="col-4 p-2">{{ details.registration_number || 'N/A'  }}</div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Country Code</div>
        <div class="col-4 p-2">VAT Number</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.country_code || 'N/A'  }}</div>
        <div class="col-4 p-2">{{ details.vat_number || 'N/A'  }}</div>
      </div>
    </div>
    <div class="p-2 mx-2 mt-3 bg-light-blue">Basic Information</div>
    <div class="p-4 pt-0">
      <div class="row table-like-header">
        <div class="col-4 p-2">Status</div>
        <div class="col-4 p-2">Type of entity</div>
        <div class="col-4 p-2">Activity</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4 p-2">
          <span class="rounded text-primary p-1">{{ details.status || 'N/A'  }}</span>
        </div>
        <div class="col-4 p-2">{{ details.company_type || 'N/A'  }}</div>
        <div class="col-4 p-2">N/A</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Date Dissolved</div>
        <div class="col-4 p-2">Registry Number</div>
        <div class="col-4 p-2">Registration Date</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.date_disolved || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.registration_number || 'N/A'  }}</div>
        <div class="col-4 p-2">
          {{ timeDateFormat(details.registration_date).date || 'N/A'  }}
        </div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Last Updated</div>
        <div class="col-4 p-2">Shares Value</div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">
          {{ timeDateFormat(details.last_updated_at).date  || 'N/A'  }}
        </div>
        <div class="col-4 p-2">{{ details.shares_value }}</div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Subscribed Share Capital</div>
        <div class="col-4 p-2">Shares Issued</div>
        <div class="col-4 p-2">Paid Share Capital</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.subscribed_share_capital || 'N/A'  }}</div>
        <div class="col-4 p-2">{{ details.shares_issued || 'N/A'  }}</div>
        <div class="col-4 p-2">{{ details.share_capital  || 'N/A' }}</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Address</div>
        <div class="col-4 p-2">Head office Address</div>
        <div class="col-4 p-2">Branch Office Address</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.address }}</div>
        <div class="col-4 p-2">{{ details.head_office_address || 'N/A'  }}</div>
        <div class="col-4 p-2">{{ details.branch_address || 'N/A' }}</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Website</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.website_email || 'N/A' }}</div>
      </div>
    </div>
    <ContactPersons :details=details />
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import ContactPersons from "../Personnel/ContactPersons/ContactPersons";
import "../style.css";
import { timeDateFormat } from "../../../../utils/helpers/index";

export default {
  name: "ContactInformation",
  mixins: [componentData],
  data() {
    return {
      details: null,
      loading: false,
      size: 25,
      filters: {},
      ...this.tableOptions(tableHeadings, 20),
    };
  },
  components: { ContactPersons },
  computed: {
    details() {
      return this.$store.state.services.reportDetails;
    },
  },
  methods: {
    timeDateFormat,
  },

  created() {},
};
</script>

<style>
.row {
  font-size: 15px;
}
.table-like-header {
  background: #f9f9f9;
}
.table-like-body {
  font-weight: 100;
}
</style>
