<template>
	<div class="form-group mb-4">
		<label v-show="label" class="input-item-label">{{ label }}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<div class="input-wrapper date" :class="disabled ? 'disabled' : ''">
			<Datepicker
				:name="name"
				:clear-button="true"
				:id="id"
				:value="value"
				v-on:input="$emit('input', $event)"
				:placeholder="placeholder"
				:disabled="disabled"
				:format="format"
				:disabledDates="disabledDates"
			/>

			<img
				src="/assets/img/icons/calender.svg"
				alt=""
				class="pointer"
				width="10px"
			/>
		</div>

		<span v-if="error" class="text-danger">{{ error }}</span>
	</div>
</template>

<script>
	import Datepicker from "vuejs-datepicker";

	export default {
		name: "DatePicker",
		props: [
			"id",
			"name",
			"label",
			"placeholder",
			"value",
			"required",
			"disabled",
			"format",
			"error",
			"disabledDates",
			"handleError",
		],
		components: {
			Datepicker,
		},
		watch: {
			value() {
				this.handleError && this.handleError(this.name);
			},
		},
	};
</script>

<style scoped>
	.vdp-datepicker {
		width: 100%;
	}
</style>
