<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">Address Verification form</h2>
                <h5 class="user-profile-email sub">
                  Verify Address of various users
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyAddress">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      First name
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="firstname"
                      @blur="$v.firstname.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter firstname"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.firstname.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.firstname) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      middle name
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="middlename"
                      @blur="$v.middlename.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter middlename"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.middlename.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.middlename) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Last name
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="lastname"
                      @blur="$v.lastname.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter lastname"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.lastname.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.lastname) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Gender</label>
                    <v-select
                      class="v-select"
                      placeholder="Select gender"
                      v-model="gender"
                      @close="$v.gender.$touch()"
                      :required="!gender"
                      @keydown.enter.native.prevent
                      :options="genderSelector"
                    ></v-select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Date of Birth
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="date"
                      v-model="dob"
                      @blur="$v.dob.$touch()"
                      class="form-control input-bordered"
                      placeholder="Select the date of birth"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.dob.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.dob) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <CustomPhoneInput
                    :onlyCountries="['NG']"
                    label="Phone Number"
                    v-model="phonenumber"
                    @blur="$v.phonenumber.$touch()"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phonenumber.$error && formVuelidator($v.phonenumber)
                    "
                  />
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Email</label>
                    <input
                      type="email"
                      v-model="email"
                      @blur="$v.email.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the email"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Description</label>
                    <input
                      type="text"
                      v-model="description"
                      @blur="$v.description.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the description"
                    />
                  </div>
                </div>

                <!-- Building info -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Flat Number</label>
                    <input
                      type="text"
                      v-model="flatno"
                      @blur="$v.flatno.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the flat number"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.flatno.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.flatno) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Building Name
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="buildingname"
                      @blur="$v.buildingname.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the building name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.buildingname.$error"
                    >
                      <li class="parsley-required">
                        Building Name is required.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Building Number</label>
                    <input
                      type="text"
                      v-model="buildingno"
                      @blur="$v.buildingno.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the building number"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Street
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="street"
                      @blur="$v.street.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the street name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.street.$error"
                    >
                      <li class="parsley-required">Street is required.</li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Sub Street</label>
                    <input
                      type="text"
                      v-model="substreet"
                      @blur="$v.substreet.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the sub street name"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Landmark
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="landmark"
                      @blur="$v.landmark.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter a landmark close to the address"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.landmark.$error"
                    >
                      <li class="parsley-required">Landmark is required.</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      State
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="state"
                      @blur="$v.state.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the state"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.state.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.state) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      City
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="city"
                      @blur="$v.city.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the city"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.city.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.city) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Post Code</label>
                    <input
                      type="text"
                      v-model="postcode"
                      @blur="$v.postcode.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter the postcode"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.postcode.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.postcode) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Country
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="country"
                      @blur="$v.country.$touch()"
                      class="form-control input-bordered"
                      placeholder="Select the country"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.country.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.country) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Image -->
                <div class="col-md-12">
                  <CustomUpload
                    :name="'logo'"
                    :label="'Your picture'"
                    :required="true"
                    :validFileTypes="['jpg', 'jpeg', 'png']"
                    :handleChange="handleUpload"
                    :fileName="mediaData.name"
                  />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="uploadError || $v.mediaData.$error"
                    >
                      <li class="parsley-required">
                        File size must be less than 5MB and required
                      </li>
                    </ul>
                </div>
                <!-- Application -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Verifying Address</span>
                    <span v-else>Verify Address</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Response from verification -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>PVC Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from pvc request made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content">
            <p class="invoice-info-row preview-row">
              <span>Firstname</span>
              <span>{{ verificationResult.first_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Lastname</span>
              <span>{{ verificationResult.last_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Email:</span>
              <span>{{
                verificationResult.email || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Gender:</span>
              <span>{{
                verificationResult.gender || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Occupation:</span>
              <span>{{
                verificationResult.occupation || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Polling delimitation</span>
              <span>{{ verificationResult.polling_delimitation }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>PVC</span>
              <span>{{ verificationResult.pvc_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Birth</span>
              <span>{{
                verificationResult.date_of_birth || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Phone number</span>
              <span>{{
                verificationResult.phone_number || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Request Reference</span>
              <span>{{ verificationResult.request_reference }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Verification Status</span>
              <span>{{ verificationResult.verification_status }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>
      </transition>
      <hr />
    </div>

    <div class="row">
      <div class="col-12">
        <div class="user-details-info">
          <div class="user-details--top-info">
            <h2 class="user-profile-name">API Request Format</h2>
            <h5 class="user-profile-email sub">
              Representation of how the API call can be made with the
              appropriate data neccesary
            </h5>
          </div>
        </div>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>End-Point</label><code>         
        'POST' : '{{API}}IdentityVerification/Address'          
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>Header</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{appname}',
        'apikey': '{apikey}',
      }        
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>Body</label><code>
    {
      "first_name:"{first_name}" 
      "middle_name:"{middle_name}" 
      "last_name:"{last_name}" 
      "gender:"{gender}" 
      "dob:"{date_of_birth}" 
      "phone_number:"{phone_number}" 
      "email:"{email}" 
      "description:"{description}" 
      "flat_number:"{flat_number}" 
      "building_name:"{building_name}" 
      "building_number:"{building_number}" 
      "street:"{street}" 
      "sub_street:"{sub_street}" 
      "landmark:"{landmark}" 
      "state:"{state}" 
      "city:"{city}" 
      "postcode:"{postcode}" 
      "country:"{counry}" 
      "images:"{image selection}"     
    }
      </code></pre>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, numeric, alpha } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },

  mixins: [notifications, modalMixin],
  validations: {
    app: { required },
    state: { required, alpha },
    firstname: { required, alpha },
    lastname: { required, alpha },
    middlename: { required, alpha },
    phonenumber: {
      required,
      isPhoneValid: isPhoneValid("phonenumber"),
    },
    // gender: { required },
    dob: { required },
    // email: { required },
    flatno: { numeric },
    buildingname: { required },
    // buildingno: { required },
    street: { required },
    landmark: { required },
    postcode: { numeric },
    city: { required, alpha },
    country: { required, alpha },
    mediaData: { required },
  },
  components: {
    CustomPhoneInput,
    CustomUpload
  },
  data() {
    return {
      initializedResult: {},
      initializedAppResult: {},
      call_id: "",
      firstname: "",
      lastname: "",
      middlename: "",
      genderSelector: ["Male", "Female"],
      gender: "",
      landmark: "",
      description: "",
      postcode: "",
      substreet: "",
      dob: "",
      email: "",
      flatno: "",
      buildingname: "",
      buildingno: "",
      street: "",
      city: "",
      country: "",
      state: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",

      // App info
      app: "",
      clientID: "",
      apikey: "",
      appname: "",
      phonenumber: "",
      state: "",
      hasNewResponse: false,

      // services
      hasService: false,
      servicePrice: "",

      // media
      mediaData: "",
      uploadError: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  methods: {
    formVuelidator,
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Address Verification")) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let formData = new FormData();
      formData.append("first_name", this.firstname);
      formData.append("middle_name", this.middlename);
      formData.append("last_name", this.lastname);
      formData.append("gender", this.gender);
      formData.append("dob", this.dob);
      formData.append(
        "phone_number",
        this.phonenumber.formatted
          ? `0${this.phonenumber.nationalNumber}`
          : ""
      );
      formData.append("email", this.email);
      formData.append("description", this.description);
      formData.append("flat_number", this.flatno);
      formData.append("building_name", this.buildingname);
      formData.append("building_number", this.buildingno);
      formData.append("street", this.street);
      // formData.append("sub_street", this.sub)
      formData.append("landmark", this.landmark);
      formData.append("state", this.state);
      formData.append("city", this.city);
      formData.append("postcode", this.postcode);
      formData.append("country", this.country);
      formData.append("image", this.mediaData);
      formData.append("currency_type", this.currencyType.currency);

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: formData,
        query: this.call_id,
      };

      return verificationData;
    },
    async verifyAddress() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        let apiResponse = await this.$store.dispatch(
          "services/addressVerification",
          verificationData
        );
        this.$swal({
          title: `Information Submitted`,
          text: `${apiResponse.message}`,
          icon: "success",
          button: "Ok",
        });
        this.showSuccessNotification(apiResponse.message);
        // this.verificationResult = apiResponse.results
        // this.hasNewResponse = true
        this.isSubmitting = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async handleUpload({ target: { files } }) {
			const file = files[0];
			if (file){
				const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.mediaData = file;
          this.uploadError = false;
        } else {
          this.uploadError = true;
        }
      } 
		},
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>

<style>
</style>