import Vue from "vue";
import Vuex from "vuex";

import { apiClient } from "../services/api";

import logs from "./modules/logs";
import application from "./modules/application";
import account from "./modules/account";
import auth from "./modules/auth";
import services from "./modules/services";
import utility from "./modules/utility";
import products from "./modules/products";
import whitelabelling from "./modules/whitelabelling";
import fundstransfer from "./modules/fundstransfer";
import wallets from "./modules/wallets/wallets";
import remittance from "./modules/wallets/remittance";
import transactions from "./modules/wallets/transactions";
import directdebit from "./modules/directdebit";
import customers from "./modules/wallets/customers";
import payment from "./modules/wallets/payment";
import pos from "./modules/wallets/pos";
import plans from "./modules/wallets/subscriptions/plans";
import tax from "./modules/wallets/subscriptions/tax";
import coupons from "./modules/wallets/subscriptions/coupons";
import subscriptions from "./modules/wallets/subscriptions/subscriptions";
import trails from "./modules/wallets/subscriptions/trails";
import reservedAccounts from "./modules/wallets/reservedAccounts";
import invoices from "./modules/wallets/invoices/invoices";
import pricing from "./modules/wallets/pricing";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		logs,
		account,
		application,
		services,
		utility,
		wallets,
		pos,
		products,
		remittance,
		whitelabelling,
		fundstransfer,
		directdebit,
		transactions,
		customers,
		payment,
		pos,
		plans,
		tax,
		coupons,
		subscriptions,
		trails,
		reservedAccounts,
		invoices,
		pricing,
	},
	state: {
		metaData: [],
		appUrlV2: process.env.VUE_APP_BASE_URL_V2,
		isLoading: false,
		checkoutData: {},
		environment: "dev",
	},
	mutations: {
		SET_META_DATA(state, metadata) {
			state.metaData = metadata;
			state.isLoading = false;
		},
		SET_APP_URL(state, url) {
			state.appUrlV2 = url;
		},
	},

	actions: {
		setAppUrlV2({ commit }, url) {
			commit("SET_APP_URL", url);
		},
		async getMetaData({ commit }) {
			try {
				let { data } = await apiClient.get(`Authorize/MetaData`);
				commit("SET_META_DATA", data.results);
				return data;
			} catch (error) {
				if (error.response) {
					throw new Error(error.response);
				} else {
					throw new Error("Unable to fetch meta data");
				}
			}
		},
		setEnvironment({}, environment) {},
	},
});
