<template>
	<div
		class="tab-pane fade"
		id="Subscriptions"
		role="tabpanel"
		aria-labelledby="Subscriptions-tab"
	>
		<SubscriptionTable :dataParams="params" />
	</div>
</template>

<script>
	import SubscriptionTable from "../../../../common/SubscriptionTable/SubscriptionTable";

	export default {
		name: "WalletSubscriptions",
		components: { SubscriptionTable },
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
			params() {
				return { walletRef: this.$route.params.reference };
			},
		},
	};
</script>
