import { notifications } from "./notifications";

export const exceptions = {
  mixins: [notifications],
  methods: {
    errorHandler(error) {
      const errorMessage = error.msg || error.message || "something went wrong";
      const errorCode = error.code || error.statusCode || 500;
      const errorSource = error.src || error.source || "";
      console.log(`Error ${errorCode}: ${errorMessage} at ${errorSource}`);
      this.showErrorLoginNotification(errorMessage);
    },
  },
};
