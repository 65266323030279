<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openModal"
    >
      Create Split Payment
    </button>
    <CreateSplitPaymentModal
      :id="'createSplitPayment'"
      :closeModal="closeModal"
      :splitPaymentRef="splitPaymentRef"
    />
  </div>
</template>

<script>
import CreateSplitPaymentModal from "../../modals/CreateSplitPaymentModal/CreateSplitPaymentModal";

export default {
  name: "CreateSplitPaymentButton",
  props: ["splitPaymentRef"],
  methods: {
    openModal() {
      $("#createSplitPayment").modal("show");
    },
    closeModal() {
      $("#createSplitPayment").modal("hide");
    },
  },
  components: {
    CreateSplitPaymentModal,
  },
};
</script>
