<template>
  <div class="mt-3">
    <div class="SectionItem no-b-b mb-3 flex__between">
      <p class="title">Company Information</p>

      <UpdateCompanyBtn :data="company" @refresh="$emit('refresh')" />
    </div>
    <div class="row col-6">
      <div class="col-sm-6">
        <CustomInput
          :name="'rc_number'"
          v-model="rc_number"
          :label="'RC Number'"
          :placeholder="'Enter RC Number'"
          :disabled="true"
        />
      </div>
      <div class="col-sm-6">
        <CustomInput
          :name="'tin'"
          v-model="tin"
          :label="'TIN'"
          :placeholder="'Enter TIN'"
          :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UpdateCompanyBtn from "./buttons/UpdateCompanyBtn.vue";
import CustomInput from "@/utils/CustomInput/CustomInput.vue";

export default {
  props: ["company"],
  data() {
    return {
      rc_number: "Not Available",
      tin: "Not Available",
    };
  },
  components: { CustomInput, UpdateCompanyBtn },
  mounted() {
    this.company.rc_number && (this.rc_number = this.company.rc_number);
    this.company.tin && (this.tin = this.company.tin);
  },
};
</script>
