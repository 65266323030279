export const tableHeadings = {
	reference: "Chargeback Reference",
	transaction_reference: "Transaction Reference",
	"Transaction.client_reference": "Client Reference",
	"Transaction.amount": "Amount",
	createdAt: "Date Created",
	status: "Status",
};

export const chargebackCsvRows = {
	reference: "Chargeback Reference",
	transaction_reference: "Transaction Reference",
	"Transaction.client_reference": "Client Reference",
	reason_code: "Reason Code",
	due_at: "Due At",
	"Transaction.currency": "Currency",
	"Transaction.amount": "Amount",
	createdAt: "Date Created",
	status: "Status",
};

export const reasons = {
	13.6: "Credit Not Processed",
	13.1: "Merchandise/Services Not Received",
};
