<template>
	<Modal
		:id="id"
		:title="'Update Company Information'"
		:toggle="handleCloseModal"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Information Updated Successfully'"
			:onSuccess="handleSuccessCloseModal"
		/>
		<form v-else @submit.prevent="handleSubmit" class="row h-100">
			<div class="col-sm-6">
				<CustomInput
					:name="'rc_number'"
					v-model="rc_number"
					@blur="$v.rc_number.$touch()"
					:label="'RC Number'"
					:placeholder="'Enter RC Number'"
					:error="$v.rc_number.$error && formVuelidator($v.rc_number)"
					:required="true"
				/>
			</div>
			<div class="col-sm-6">
				<CustomInput
					:name="'tin'"
					v-model="tin"
					@blur="$v.tin.$touch()"
					:label="'TIN'"
					:placeholder="'Enter TIN'"
					:error="$v.tin.$error && formVuelidator($v.tin)"
					:required="true"
				/>
			</div>
			<div class="col-12">
				<div class="flex__between pt-4">
					<button
						type="button"
						class="btn btn-outline w-100 mr-3"
						:disabled="loading"
						@click="handleCloseModal"
					>
						<span>Close</span>
					</button>
					<button
						type="submit"
						class="btn btn-lg w-100 ml-3 btn--primary"
						:disabled="loading"
					>
						<Spinner v-if="loading" :color="'#fff'" />
						<span v-else>Submit</span>
					</button>
				</div>
			</div>
		</form>
	</Modal>
</template>

<script>
import Modal from "@/utils/Modal/Modal.vue";
import Spinner from "@/utils/Spinner/Spinner.vue";
import { numeric, required } from "vuelidate/lib/validators";
import CustomInput from "@/utils/CustomInput/CustomInput.vue";
import SuccessState from "@/utils/ResponseState/SuccessState.vue";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import { notifications } from "../../../../../../public/assets/mixins/notifications";

const initialState = {
	rc_number: "",
	tin: "",
	loading: false,
	successMsg: false,
};

export default {
	props: ["id", "closeModal", "data"],
	mixins: [notifications],
	data() {
		return {
			...initialState,
		};
	},
	components: { CustomInput, SuccessState, Modal, Spinner },
	validations: {
		rc_number: { required },
		tin: { numeric, required },
	},
	methods: {
		formVuelidator,
		handleSuccessCloseModal() {
			this.$emit("refresh");
			Object.assign(this.$data, { ...initialState });
			this.closeModal();
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState });
			this.closeModal();
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) return;

			try {
				this.loading = true;
				const payload = new FormData();

				payload.append("rc_number", this.rc_number);
				payload.append("tin", this.tin);

				const response = await this.$store.dispatch(
					"account/addCompliance",
					payload
				);

				if (response.status) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.rc_number = this.data.rc_number;
		this.tin = this.data.tin;
	},
};
</script>
