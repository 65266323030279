<template>
	<Modal :id="id" :title="'Add Sender'" :toggle="closeModal">
		<form @submit.prevent="handleSubmit" class="h-100">
			<div>
				<div class="row">
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'firstName'"
							:label="'First Name'"
							:placeholder="'First Name'"
							:required="true"
							v-model="senderForm.firstName"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.firstName.$error"
						>
							<li class="parsley-required">
								First Name is required.
							</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'middleName'"
							:label="'Middle Name'"
							:placeholder="'Middle Name'"
							:required="true"
							v-model="senderForm.middleName"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.middleName.$error"
						>
							<li class="parsley-required">
								Middle Name is required.
							</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'lastName'"
							:label="'Last Name'"
							:placeholder="'Last Name'"
							:required="true"
							v-model="senderForm.lastName"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.lastName.$error"
						>
							<li class="parsley-required">
								Last Name is required.
							</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'maidenName'"
							:label="'Maiden Name'"
							:placeholder="'Maiden Name'"
							v-model="senderForm.maidenName"
						/>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'occupation'"
							:label="'Occupation'"
							:placeholder="'Occupation'"
							v-model="senderForm.occupation"
						/>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'email'"
							:label="'Email'"
							:placeholder="'Email'"
							:required="true"
							v-model="senderForm.email"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.email.$error"
						>
							<li class="parsley-required">Email is required.</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'phoneNumber'"
							:maxlength="11"
							:placeholder="'Phone Number'"
							:label="'Enter Phone Number'"
							:required="true"
							:isNum="true"
							v-model="senderForm.phoneNumber"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.phoneNumber.$error"
						>
							<li class="parsley-required">
								Phone Number is required.
							</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'addressOne'"
							:placeholder="'Address'"
							:label="'Address 1'"
							:required="true"
							v-model="senderForm.addressOne"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.addressOne.$error"
						>
							<li class="parsley-required">
								Address is required.
							</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'addressTwo'"
							:placeholder="'Address'"
							:label="'Address 2'"
							v-model="senderForm.addressTwo"
						/>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'nationality'"
							:placeholder="'Nationality'"
							:label="'Nationality'"
							v-model="senderForm.nationality"
						/>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label class="input-item-label"
								>Country <span class="text-danger">*</span>
							</label>
							<v-select
								class="v-select"
								placeholder="Select a country"
								:options="metaData.countries"
								label="name"
								@input="changeState"
								@keydown.enter.native.prevent
								name="country"
								v-model="senderForm.country"
							></v-select>
						</div>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.country.$error"
						>
							<li class="parsley-required">
								Country is required.
							</li>
						</ul>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label"
								>State <span class="text-danger">*</span></label
							>
							<v-select
								class="v-select"
								placeholder="Select a state"
								v-model="senderForm.state"
								@keydown.enter.native.prevent
								:disabled="!senderForm.country"
								:options="states"
								label="state_label"
							></v-select>
						</div>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.state.$error"
						>
							<li class="parsley-required">State is required.</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'city'"
							:maxlength="11"
							:placeholder="'City'"
							:label="'City'"
							:required="true"
							v-model="senderForm.city"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.city.$error"
						>
							<li class="parsley-required">City is required.</li>
						</ul>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'postCode'"
							:maxlength="11"
							:placeholder="'Postcode'"
							:label="'Postcode'"
							:required="true"
							:isNum="true"
							v-model="senderForm.postCode"
						/>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.senderForm.postCode.$error"
						>
							<li class="parsley-required">
								Postcode is required.
							</li>
						</ul>
					</div>

					<div class="col-md-6 mb-2">
						<label class=""> Gender </label>
						<div class="d-flex">
							<label class="rdiobox mr-4">
								<input
									name="gender"
									type="radio"
									value="Male"
									v-model="senderForm.gender"
								/>
								<span class="fs-15">Male</span>
							</label>
							<label class="rdiobox">
								<input
									name="gender"
									type="radio"
									value="Female"
									v-model="senderForm.gender"
								/>
								<span class="fs-15"> Female </span>
							</label>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label class="input-item-label"
								>Date of Birth</label
							>
							<DatePicker
								:name="'birthDate'"
								v-model="senderForm.birthDate"
								:disabledDates="{ from: new Date() }"
								:placeholder="'Date of Birth'"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.senderForm.birthDate.$error"
							>
								<li class="parsley-required">
									Date of Birth is required.
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'idType'"
							:placeholder="'ID Type'"
							:label="'ID Type'"
							v-model="senderForm.idType"
						/>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'idNumber'"
							:placeholder="'ID Number'"
							:label="'ID Number'"
							v-model="senderForm.idNumber"
						/>
					</div>

					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label class="input-item-label">Date Issued</label>
							<DatePicker
								:name="'idIssueDate'"
								v-model="senderForm.idIssueDate"
								:placeholder="'Date Issued'"
							/>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label class="input-item-label">Expiry Date </label>
							<DatePicker
								:name="'idIssueDate'"
								v-model="senderForm.idExpiryDate"
								:placeholder="'Expiry Date '"
							/>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<CustomInput
							:otherCls="'mb-0'"
							:name="'SSN'"
							:placeholder="'SSN'"
							:label="'SSN'"
							v-model="senderForm.SSN"
						/>
					</div>
					<div class="col-md-6 mb-2">
						<label class=""> AML Status </label>
						<div class="d-flex">
							<label class="rdiobox mr-4">
								<input
									name="gender"
									type="radio"
									:checked="true"
									value="true"
									v-model="senderForm.AML"
								/>
								<span class="fs-15">Verified</span>
							</label>
							<label class="rdiobox">
								<input
									name="gender"
									type="radio"
									value="false"
									v-model="senderForm.AML"
								/>
								<span class="fs-15"> Not Verified </span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="flex__between">
				<button
					type="button"
					class="btn btn-outline w-100 mr-3"
					@click="handleClose"
				>
					<span>Cancel</span>
				</button>
				<button
					type="submit"
					class="btn btn-lg btn-primary w-100 ml-3"
					:disabled="loading"
				>
					<Spinner v-if="loading" />
					<span v-else>Create</span>
				</button>
			</div>
		</form>
	</Modal>
</template>

<script>
import Axios from "axios";
import Nprogress from "nprogress";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import { required } from "vuelidate/lib/validators";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
import Spinner from "../../../../../utils/Spinner/Spinner.vue";
import { newCountryFormat } from "@/assets/js/countryDailcodes";
import DatePicker from "@/utils/DatePicker/DatePicker";
import { getWalletApikey, getError } from "@/utils/helpers";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
	mixins: [notifications],
	name: "AddSenderModal",
	data() {
		return {
			senderForm: {},
			loading: false,
			newCountryFormat,
			states: [],
			mediaData: "",
			uploadError: false,
		};
	},
	validations: {
		senderForm: {
			firstName: { required },
			middleName: { required },
			lastName: { required },
			email: { required },
			phoneNumber: { required },
			addressOne: { required },
			country: { required },
			state: { required },
			city: { required },
			birthDate: { required },
			postCode: { required },
		},
	},
	props: ["id", "closeModal", "refetch"],
	components: {
		Modal,
		CustomInput,
		CustomSelect,
		DatePicker,
		Spinner,
		CustomUpload,
	},

	methods: {
		clearInputFields() {
			this.senderForm = {};
			this.loading = false;
		},
		changeState() {
			// this.senderForm.state = [];
			this.states = this.senderForm.country.states;
		},
		handleClose() {
			this.clearInputFields();
			this.closeModal();
		},
		async handleSubmit(e) {
			this.$v.$touch();
			if (this.senderForm.image === undefined) {
				this.uploadError = true;
			}
			if (this.$v.$invalid) return;

			this.loading = true;

			try {
				let response = await this.$store.dispatch(
					"remittance/addCustomer",
					{
						payload: {
							...this.senderForm,
							country: this.senderForm.country.name,
							state: this.senderForm.state.state_label,
							AML: this.senderForm.AML == "true",
						},
						apiKey: getWalletApikey(this.$store),
					}
				);

				this.showSuccessNotification(response.data.message);

				setTimeout(() => {
					this.handleClose();
					this.loading = false;
					this.clearInputFields();
					this.refetch({});
				}, 1000);
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				console.log("[error] ", error.response);
				this.loading = false;
				this.showErrorLoginNotification(getError(error.response));
			}
		},
	},
	computed: {
		metaData() {
			return this.$store.state.metaData;
		},
	},
	mounted() {
		this.states = this.metaData.countries[0].states;
	},
};
</script>
