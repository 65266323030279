<template>
	<div class="row">
		<div class="col-12">
			<div class="user-details-info">
				<div class="user-details--top-info">
					<h2 class="user-profile-name">
						API Request Format
					</h2>
					<h5 class="user-profile-email sub">
						Representation of how the API call can be made with the
						appropriate data neccesary
					</h5>
				</div>
			</div>
		</div>

		<div class="col-12" v-if="isOTP">
			<pre class="code code-html">
                <label style="top:35%;">End-Point</label>
                	<code> 'POST' : `{{API}}Otp/send` </code>
            </pre>
		</div>

		<div class="col-12" v-else>
			<pre class="code code-html">
                <label style="top:35%;">End-Point</label>
					<code> 'POST' : `{{API}}Messaging/Sms` </code>
            </pre>
		</div>

		<div class="col-12">
			<pre class="code code-html">
                <label style="top:42%;">Headers</label>
                <code>
                    {
                        'clientid': '{{$store.state.account.clientID}}',
                        'appname': '{{ request.app ? request.app.app_name : 'app_name'  }}',
                        'apikey': '{{ request.app ? request.app.api_key : 'app_key'  }}',
                    }
                </code>
            </pre>
		</div>

		<div class="col-12" v-if="isOTP">
			<pre class="code code-html">
                <label style="top:42%;">Body</label>
         <code v-if="!request.phone_number.country">
						{
							"phone_number": "{{request.phone_number ? request.phone_number : "phone_number"}}"
							"expiry_time": "{{ "60" }}"
							"message": "{{"This is just a test from me Jibril, your service number is {code} and it will expire in {exp-time}"}}"
							"country_code": "{{request.countryCode ? request.countryCode.value : "country_code"}}"
						}
					</code>
         <code v-if="request.phone_number.country">
           {
							"phone_number": "{{request.phone_number.formatted ? request.phone_number.formatted.replace(/\s/g, "") : "phone_number"}}"
							"country_name": "{{request.phone_number.formatted ? request.phone_number.countryCallingCode  : "country_name"}}"
							"message": "{{request.message ? request.message : "message"}}"
						}
         </code>
            </pre>
		</div>

		<div class="col-12" v-else>
			<pre class="code code-html">
                <label style="top:42%;">Body</label>
					<code>
						{
							"phone_number": "{{request.phone_number.length>0 ? request.phone_number.map(phone=>phone.nationalNumber) : "phone_number"}}"
							"country_name": "{{request.country_name ? request.country_name.value : "country_name"}}"
							"message": "{{request.message ? request.message : "message"}}"
						}
					</code>
            </pre>
		</div>

		<div class="col-12">
			<pre class="code code-html">
                <label>Sample Response</label>
                <code>
                    {{`
                        {
                            "status_code": 200,
                            "error": false,
                            "message": "Sms Request Received",
                            "data": {
                                "status": "ok",
                                "recipient": "2348887766"
                            }
                        }`
					}}
                </code>
            </pre>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MessagingAPIFormat",
		props: ["request", "isOTP"],
		computed: {
			API() {
				const { apiVersion } = this.request || {};
				if (apiVersion == "v2" || apiVersion == "")
					return this.$store.state.appUrlV2;
				else return process.env.VUE_APP_BASE_URL;
			},
		},
	};
</script>
