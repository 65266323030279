<template>
	<div class="form-group">
		<label class="input-item-label">Bill Category</label>
		<span class="text-danger pl-1" title="This field is required">*</span>
		<v-select
			class="v-select"
			placeholder="Select Application"
			@keydown.enter.native.prevent
			label="name"
			:options="billerCategories"
			:value="value"
			v-on:input="$emit('input', $event)"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: "BillerCategorySelect",
	data() {
		return {
			loading: false,
			billerCategories: [],
		};
	},
	props: ["name", "value", "error", "handleError"],
	methods: {
		async getBillerCategories() {
			this.loading = true;
			try {
				const res = await this.$store.dispatch(
					"services/getBillersCategory"
				);
				const { status, data } = res || {};
				if (status === 200) {
					this.loading = false;
					this.billerCategories = data.data;
				}
			} catch (error) {
				this.loading = false;
				this.billerCategories = [];
			}
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getBillerCategories();
	},
};
</script>
