<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="SectionItem no-b-b mb-3">
				<div class="row">
					<div class="col-12">
						<div class="SectionHeader">
							<span class="title">API Key Settings </span>
							<div class="description">
								Manage API keys and access keys for different
								services
							</div>
						</div>
					</div>
					<!-- Wallet API Key  -->
					<div class="col-12 mt-5">
						<div class="user-settings-section">
							<form @submit.prevent="">
								<div class="row mt-4">
									<!-- Wallet API Key -->
									<div class="col-sm-12">
										<h5 class="mr-4">
											<strong>Application API Key</strong>
										</h5>
										<div class="input-group mb-3">
											<input
												ref="api_key"
												type="text"
												style="min-width: 700px"
												disabled
												class="form-control api__keys"
												placeholder="API Key"
												:value="hashAPI"
											/>
											<div
												class="input-group-prepend"
												@click="copyToClipboard"
											>
												<div class="input-group-text">
													Copy
												</div>
											</div>
										</div>
										<p>
											This API key is used for wallet
											requests across the application
										</p>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
	mixins: [notifications],
	name: "APIKeys",
	data() {
		return {
			hashAPI: "",
		};
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		copyToClipboard() {
			const tempInput = document.createElement("input");
			tempInput.value = this.user.merchant.api_key;

			document.body.appendChild(tempInput);
			tempInput.select();
			document.execCommand("copy");
			document.body.removeChild(tempInput);
			console.log("hereeee");
			this.showSuccessNotification("API Key copied!");
		},
		hashKey(key) {
			const hash =
				"_XXXXXXXXXXXXX_XXXXXXXXXXXX_XXXXXXXXXXXXXX_XXXXXXXXXXXXX_XXXXXXXXXXXX_XXXXXXXXXXXXXX";
			if (key) return key.slice(0, 9) + hash;
			else return "";
		},
	},
	async mounted() {
		this.hashAPI = this.hashKey(this.user.merchant.api_key);
	},
};
</script>
