<template>
	<div class="card">
		<div class="row">
			<div class="col-12 py-3 px-4">
				<h5 class="font-weight-bold">Transactions</h5>
			</div>

			<div class="col-12">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<PerPageSelect
								:handleChange="refetch"
								:limit="limit"
							/>
						</template>
						<template slot="filterForm">
							<TransactionsTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadTransactions"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>

			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="resRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="showData"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template slot="amount" slot-scope="{ row }">
						{{ toCurrency(row.amount) }}
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						<span>
							{{
								timeDateFormat(row.createdAt).date +
								" " +
								timeDateFormat(row.createdAt).time
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>
		<ReservedAccountTransactionsModal
			v-if="showModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import FilterWrapper from "../../../../../utils/FilterWrapper/FilterWrapper";
import PerPageSelect from "../../../../../utils/Selects/PerPageSelect/PerPageSelect";
import TransactionsTableFilters from "../../../common/TransactionsTable/TransactionsTableFilters/TransactionsTableFilters";
import FailedState from "../../../../../utils/ResponseState/FailedState";
import Spinner from "../../../../../utils/Spinner/Spinner";
import ReservedAccountTransactionsModal from "./ReservedAccountTransactionsModal";
import {
	timeDateFormat,
	tableOptions,
	getWalletApikey,
	downloadReport,
	toCurrency,
} from "../../../../../utils/helpers";

const tableHeadings = {
	reference: "Transaction Ref",
	account_name: "Account Name",
	account_number: "Account Number",
	amount: " Amount",
	createdAt: "Date",
};

export default {
	mixins: [notifications],
	name: "ReservedAccountTransactions",
	data() {
		return {
			showModal: false,
			modalData: null,
			loading: false,
			rLoading: false,
			errorMsg: "",
			query: {},
			dateParams: {},
			limit: 25,
			...tableOptions(tableHeadings, 25),
		};
	},
	props: ["reference"],
	components: {
		ReservedAccountTransactionsModal,
		FailedState,
		Spinner,
		PerPageSelect,
		FilterWrapper,
		TransactionsTableFilters,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		toCurrency,
		showData({ row }) {
			this.modalData = row;
			this.showModal = true;
		},
		closeModal(e) {
			this.showModal = false;
			this.modalData = null;
		},
		timeDateFormat,
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch({ dateParams, query, limit }) {
			if (query) {
				const newQuery = Object.fromEntries(
					Object.entries(query).filter(([_, v]) => v != "")
				);
				this.query = newQuery;
			}
			if (dateParams) this.dateParams = { ...dateParams };
			if (limit) {
				this.$refs.resRef.setLimit(limit);
				this.limit = limit;
			}
			this.$refs.resRef.refresh();
		},
		clearFilter() {
			this.query = {};
			this.dateParams = {};
			this.$refs.resRef.refresh();
		},
		getRequestData(data, limit = 9999999999) {
			const requestData = {
				apiKey: getWalletApikey(this.$store),
				params: {
					...data,
					...this.dateParams,
					limit,
					query: { ...this.query },
					reserved_account: this.reference,
				},
			};
			return requestData;
		},

		async getTransactions() {
			this.options.requestFunction = async (data) => {
				const requestData = this.getRequestData(data, this.limit);

				return await this.$store
					.dispatch(
						"reservedAccounts/getReservedAccountTransactions",
						requestData
					)
					.then((response) => ({ ...response.data.data }));
			};
		},
		async downloadTransactions() {
			this.rLoading = true;
			const requestData = this.getRequestData({});

			const response = await this.$store.dispatch(
				"reservedAccounts/getReservedAccountTransactions",
				requestData
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				downloadReport(tableHeadings, data.data.data);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},

	created() {
		this.getTransactions();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
