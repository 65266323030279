<template>
	<div
		class="tab-pane fade active show "
		id="Personal_Information"
		role="tabpanel"
		aria-labelledby="Personal_Information-tab"
	>
		<div class="card overflow-hidden mt-3">
			<div class="row py-3">
				<div class="col-sm-4 text-center border-right ">
					<p class="call-name pt-2">Total Transactions</p>
					<h4 class="call-count">
						{{ reservedAccount.total_transactions || 0 }}
					</h4>
				</div>

				<div class="col-sm-4 text-center border-right ">
					<p class="call-name pt-2">Total Amount</p>
					<h4 class="call-count">
						{{
							toCurrency(reservedAccount.total_transactions_value)
						}}
					</h4>
				</div>

				<div class="col-sm-3 text-center">
					<p class="call-name pt-2">Account Details</p>
					<span class="">
						<h4>{{ reservedAccount.account_name }}</h4>
						<span class="flex__center pl-4">
							{{ reservedAccount.bank_name }} (
							{{ reservedAccount.account_number }} )
							<img
								src="/assets/img/icons/copy.svg"
								alt="Copy Account Number"
								class="ml-4 pointer"
								@click="copy(reservedAccount.account_number)"
							/>
						</span>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import { copyToClipboard, toCurrency } from "../../../../../utils/helpers";

	export default {
		mixins: [notifications],
		name: "PersonalInformation",
		props: ["reservedAccount"],
		methods: {
			toCurrency,
			async copy(value) {
				const copied = copyToClipboard(value);
				copied &&
					this.showSuccessNotification("Account Number Copied!");
			},
		},
	};
</script>
