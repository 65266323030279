<template>
  <div class="col-12">
    <div class="row">
      <div class="col-sm-6">
        <CustomSelect
          name="plan"
          label="Amount"
          :getLabel="getPlanLabel"
          placeholder="Select Plan"
          :options="plans"
          v-model="request.plan"
          :required="true"
          :error="formErrors.plan"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6" v-if="billService.slug !== 'smile'">
        <CustomPhoneInput
          :onlyCountries="['NG']"
          :name="'phone_number'"
          v-model="request.phone_number"
          @blur="$v.request.phone_number.$touch()"
          :label="'Phone Number'"
          placeholder="Enter phone number"
          :error="
            $v.request.phone_number.$error &&
            formVuelidator($v.request.phone_number)
          "
          :handleError="handleError"
          required="true"
        />
      </div>

      <div v-else class="col-sm-6">
        <CustomInput
          :name="'phone_number'"
          :label="'Account Number'"
          placeholder="Enter account number"
          v-model="request.phone_number"
          :error="
            $v.request.phone_number.$error &&
            formVuelidator($v.request.phone_number)
          "
          :handleError="handleError"
          required="true"
          :isNum="true"
        />
      </div>

      <div class="col-sm-6 mt-3">
        <PayBillsButton
          :bill="getRequestData()"
          :handleSubmit="handleSubmit"
          :reset="reset"
          :setApiResponse="setApiResponse"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="user-details-info">
          <div class="user-details--top-info">
            <h2 class="user-profile-name">API Request Format</h2>
            <h5 class="user-profile-email sub">
              Representation of how the API call can be made with the
              appropriate data neccesary
            </h5>
          </div>
        </div>
      </div>

      <div class="col-12">
        <pre
          class="code code-html"
        ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{ API }}Billers/pay`
      </code></pre>
      </div>
      <div class="col-12">
        <pre
          class="code code-html"
        ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{ clientID }}',
        'appname': '{{ app ? app.app_name : 'app_name' }}',
        'apikey': '{{ app ? app.api_key : 'app_key' }}',
      }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label style="top:42%;">Body</label><code>
       {
          amount: '{{ request.plan ? request.plan.amount : 'price' }}',
          phone_number: '{{ request.phone_number.formatted ? request.phone_number.formatted.replace(/\s/g, "") : 'phone_number'  }}',
          service_id: '{{ billService ? billService.slug : 'service_id' }}',
          service_code: '{{ request.plan ? request.plan.productId : 'service_code' }}'',
          api_service_id: '{{ billService ? billService.id : "api_service_id" }}',
          service_type: 'internet',
      }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>Sample Response</label><code>
            {{ apiResponse ? apiResponse : `
                    {
                      "status_code": 200,
                      "message": "Success",
                      "data": {
                          "statusCode": "00",
                          "statusDescription": "Successful",
                          "amount": 300,
                          "tranxReference": "2210251224153974628",
                          "network": "SMILE",
                          "tranxDate": "25-10-2022 12:24 pm",
                          "mReference": "970bfd99-e5f5-4bda-bbfd-86fe618584e5",
                          "recipient": "09999999999"
                      }
                    }`
            }}
      </code></pre>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import PayBillsButton from "../buttons/PayBillsButton/PayBillsButton";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import { getPhone } from "../../../../utils/helpers";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import { required } from "vuelidate/lib/validators";

const initialState = () => ({
  request: {
    phone_number: "",
    plan: "",
  },
  plans: [],
  formErrors: {},
  apiResponse: "",
  clientID: "",
});

export default {
  name: "InternetBiller",
  data() {
    return { ...initialState() };
  },
  props: ["app", "billService", "reset"],
  validations() {
    return {
      request: {
        phone_number: {
          required,
          isPhoneValid:
            this.billService.slug !== "smile"
              ? isPhoneValid("request.phone_number")
              : true,
        },
      },
    };
  },
  components: {
    PayBillsButton,
    CustomSelect,
    CustomInput,
    CustomPhoneInput,
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    formVuelidator,
    setApiResponse(response) {
      this.apiResponse = response;
    },
    getPlanLabel(plan) {
      const { amount, productId, validity } = plan || {};
      return productId ? `${productId} - ${amount} | ${validity}` : amount;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleError2(name, error) {
      this.formErrors = { ...this.formErrors, [name]: error };
    },
    handleSubmit() {
      this.$v.$touch();
      const { formErrors, isValid } = formValidator(this.request);
      if (isValid && !this.$v.$invalid) {
        return true;
      } else this.formErrors = formErrors;
      return false;
    },
    getValidPhone(value) {
      const phone_number = getPhone(value, true);
      const dev = "development" || "dev";
      if (process.env.NODE_ENV === dev) return "9999999999";
      else return phone_number;
    },
    getRequestData() {
      let { phone_number, plan } = this.request || {};
      phone_number =
        this.billService.slug === "smile"
          ? this.request.phone_number
          : this.request.phone_number.formatted &&
            this.request.phone_number.formatted.replace(/\s/g, "");
      return {
        headers: {
          clientid: this.$store.state.account.clientID,
          appname: this.app.app_name,
          apikey: this.app.api_key,
        },
        payload: {
          amount: plan.amount,
          phone_number,
          service_code: plan.productId ? plan.productId : phone_number,
          service_id: this.billService.slug,
          api_service_id: this.billService.id,
          service_type: "internet",
        },
      };
    },
    async getPlans() {
      try {
        const { id, slug } = this.billService || {};

        const response = await this.$store.dispatch(
          "services/getInternetPlans",
          {
            payload: {
              service_name: slug,
              api_service_id: id,
            },
          }
        );

        if (response.status === 200) {
          this.plans = response.data.data;
        }
      } catch (error) {
        this.plans = "";
      }
    },
  },
  watch: {
    billService(newValue, oldValue) {
      if (oldValue !== newValue)
        Object.assign(this.$data, { ...initialState() });
      this.getPlans();
    },
  },
  async mounted() {
    this.billService && this.getPlans();
    try {
      this.clientID = this.$store.state.account.clientID;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
