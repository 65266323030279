<template>
	<div class="form-group">
		<label class="input-item-label">Wallet</label>
		<span class="text-danger pl-1" title="This field is required">*</span>
		<v-select
			class="v-select"
			placeholder="Select wallet"
			@keydown.enter.native.prevent
			:get-option-label="getLabel"
			:options="wallets"
			:value="value"
			v-on:input="$emit('input', $event)"
			:disabled="disabled"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
export default {
	name: "MasterWalletSelect",
	data() {
		return {
			loading: false,
			wallets: [],
		};
	},
	props: [
		"name",
		"value",
		"error",
		"handleError",
		"disabled",
		"getFirstData",
	],
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async getMasterWallet() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getMasterWallet",
					{
						apiKey: this.user.merchant.api_key,
					}
				);
				this.loading = false;
				this.wallets = response.data;
				this.getFirstData &&
					this.getFirstData(
						response.data.length > 0
							? response.data.find(
									(wallet) => wallet.currency == "NGN"
							  )
							: ""
					);
			} catch (error) {
				this.wallets = [];
				this.loading = false;
			}
		},
		getLabel: (val) => {
			return val.account_name
				? `${val.account_name} ( ${val.account_number} )`
				: null;
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getMasterWallet();
	},
};
</script>
