<template>
	<div v-if="adminAccess" :class="cls">
		<slot />
	</div>
	<div v-else>
		<div v-if="page" class="text-center py-5">
			<img src="/assets/img/access-denied.png" width="80" alt="" />
			<h2 class="mt-2">Access Denied!!!</h2>
			<h4>Admin or Owner access is required to access this page</h4>
		</div>
	</div>
</template>

<script>
import { ACCOUNT_ROLES } from "@/constants/roles";

export default {
	name: "AccessControl",
	data() {
		return {
			adminAccess: false,
		};
	},
	props: ["page", "cls"],
	mounted() {
		const accountRole = localStorage.getItem("accountRole");
		const { ADMIN, OWNER } = ACCOUNT_ROLES || {};
		if (accountRole == ADMIN || accountRole == OWNER)
			this.adminAccess = true;
	},
};
</script>
