<template>
	<div class="form-group">
		<label class="input-item-label">{{
			label ? label : "Select Wallet"
		}}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<v-select
			class="v-select"
			placeholder="Select wallet"
			@keydown.enter.native.prevent
			:get-option-label="getLabel"
			:options="wallets"
			:value="value"
			:multiple="multiple"
			v-on:input="$emit('input', $event)"
			:disabled="disabled"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger error">{{ error }}</span>
	</div>
</template>

<script>
import { getWalletApikey } from "../../helpers";

export default {
	name: "WalletSelect",
	data() {
		return {
			loading: false,
			wallets: [],
		};
	},
	props: [
		"name",
		"value",
		"error",
		"handleError",
		"disabled",
		"multiple",
		"walletId",
		"getFirstData",
		"label",
		"required",
	],
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async getWallets() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getWallets",
					{
						apiKey: getWalletApikey(this.$store),
						params: { limit: 9999999999999999 },
					}
				);
				const { status, data } = response || {};
				if (status === 200) {
					this.loading = false;
					this.wallets = data.data.data.filter(
						(val) => val.reference !== "master"
					);
					if (this.walletId) {
						const currentWallet = data.data.data.find(
							(val) => val.wallet_id === this.walletId
						);
						this.getFirstData && this.getFirstData(currentWallet);
					}
				}
			} catch (error) {
				this.wallets = [];
				this.loading = false;
			}
		},
		getLabel: (val) => {
			return val.account_name
				? `${val.account_name} ( ${val.account_number} ${
						val.reference === "master" ? "- Master" : ""
				  } )`
				: null;
		},
	},
	watch: {
		value() {
			this.handleError(this.name);
		},
	},
	async mounted() {
		this.getWallets();
	},
};
</script>
