<template>
  <EmptyWallet v-if="!merchant" />
  <div v-else class="az-content-body">
    <div class="row">
      <div class="col-md-12">
        <!-- Button trigger modal -->
        <button
          type="button"
          ref="btn"
          class="d-none"
          data-toggle="modal"
          data-target="#enterOTP"
        ></button>
        <div class="SectionItem mb-3 d-flex justify-content-between">
          <div class="SectionHeader">
            <span class="title">Funds Transfer</span>
            <div class="description">
              Send funds by filling the details below.
            </div>
          </div>
          <template>
            <div v-if="loading">
              <div class="flex__center">
                <Spinner :size="'10px'" />
              </div>
            </div>
            <div
              v-else-if="!loading && wallet"
              class="d-flex align-items-center"
            >
              <button
                v-if="mask"
                type="button"
                class="btn btn--primary btn-sm px-3"
                @click="mask = !mask"
              >
                <em class="fas fa-eye fs-24 pointer text--white mr-2" />
                Check Balance
              </button>

              <div v-else class="d-flex align-items-center">
                <h4 class="pt-0 mb-0 call-count">
                  {{ balance }}
                </h4>

                <em
                  class="fas fa-eye-slash fs-24 pointer text--primary ml-4"
                  @click="mask = !mask"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- nav tabs -->
      <div class="col-12">
        <Tabs :tabNames="tabNames" :tabId="'transferTab'" />
        <div class="tab-content" id="TransferTabContent"></div>

        <div class="row">
          <div class="col-12 mt-4 tab-content" style="min-height: 450px">
            <div
              class="row tab-pane fade show active"
              id="Single_transfer"
              role="tabpanel"
              aria-labelledby="Single_transfer-tab"
            >
              <div class="col-8">
                <AppSingleTransfer
                  :settings="settings"
                  :bankList="bankList"
                  :beneficiaryList="beneficiaryList"
                  :fetchAllBeneficiaries="fetchAllBeneficiaries"
                />
              </div>
            </div>

            <BulkTransfer
              :settings="settings"
              @randomString="randomString"
              :public_key="public_key"
              :reference_code="reference_code"
              :beneficiaryList="beneficiaryList"
            />
          </div>
        </div>
      </div>
      <!-- End row -->
    </div>
    <!-- End AZ -->
  </div>
</template>

<script>
import Nprogress from "nprogress";
import Tabs from "../utils/Tabs/Tabs";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";
import AppSingleTransfer from "@/components/fundsTransfer/SingleTransfer";
import AppBulkTransfer from "@/components/fundsTransfer/BulkTransfer";
import BulkTransfer from "@/components/fundsTransfer/BulkTransfer/BulkTransfer";
import CheckBalanceButton from "@/components/fundsTransfer/buttons/CheckBalanceButton/CheckBalanceButton";
import { getWalletApikey, toCurrency } from "@/utils/helpers";
import ContentWrapper from "@/utils/TableWrapper/ContentWrapper.vue";
import Spinner from "@/utils/Spinner/Spinner";
import EmptyWallet from "@/components/wallet/common/EmptyWallet/EmptyWallet.vue";

const tabNames = ["Single_transfer", "Bulk_transfer"];

export default {
  name: "FundsTransfer",
  mixins: [notifications],
  components: {
    EmptyWallet,
    AuthWrapper,
    AppSingleTransfer,
    AppBulkTransfer,
    BulkTransfer,
    Tabs,
    CheckBalanceButton,
    ContentWrapper,
    Spinner,
  },
  data() {
    return {
      isFetchingSettings: true,
      loading: false,
      isLoading: true,
      metaData: {},
      submitting: false,
      isSubmitting: false,
      verifying: false,
      error: "",
      bankList: [],
      beneficiaryList: [],
      bank_balance: {},
      id: "",
      public_key: "",
      secret_key: "",
      reference_code: "",
      tabNames,
      wallet: "",
      mask: true,
      errorMsg: "",
      settings: "",
    };
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    balance() {
      const balance = this.wallet.balance;
      return toCurrency(balance, this.wallet.currency);
    },
  },

  methods: {
    toCurrency,
    activateOTPModal() {
      this.$refs.btn.click();
    },
    dismissModal(id) {
      document.getElementById(id).click();
    },
    randomString() {
      var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ";
      var string_length = 12;
      var randomstring = "";
      for (var i = 0; i < string_length; i++) {
        var randNum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(randNum, randNum + 1);
      }
      this.reference_code = randomstring;
    },
    async fetchAllBeneficiaries() {
      Nprogress.start();
      try {
        await this.$store
          .dispatch("fundstransfer/fetchAllBeneficiaries")
          .then((response) => {
            this.beneficiaryList = response.data.results;
          });
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        console.log("error :", error);
        this.showErrorNotification(error.message);
      }
    },
    async getBankList() {
      try {
        await this.$store
          .dispatch("fundstransfer/getBankList")
          .then((response) => {
            console.log("getBankList:", response);
            this.bankList = response.data.result;
          });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getMasterWallet() {
      try {
        this.loading = true;
        const response = (
          await this.$store.dispatch("wallets/getMasterWallet", {
            apiKey: getWalletApikey(this.$store),
          })
        ).data;
        this.wallet = response.find((wallet) => wallet.currency == "NGN");
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async getWalletSettings() {
      try {
        this.isFetchingSettings = true;
        const response = await this.$store.dispatch(
          "wallets/getMerchantWalletSettings",
          { apiKey: getWalletApikey(this.$store) }
        );

        if (response.status === 200) {
          this.isFetchingSettings = false;
          this.settings = response.data.data;
        }
      } catch (error) {
        this.isFetchingSettings = false;
        this.errorMsg = error.message;
      }
    },
  },
  mounted() {
    this.getMasterWallet();
    this.getWalletSettings();
    this.getBankList();
    this.fetchAllBeneficiaries();
  },
};
</script>

<style>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
  display: none !important;
}
.v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
.v-select.v-select.vs--single.vs--searchable .v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
.input__field {
  border-color: #ff6868;
}
</style>
