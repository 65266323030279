<template>
	<div class="az-content-body py-5">
		<div class="row">
			<div class="SectionItem d-flex justify-content-between">
				<div class="SectionHeader">
					<span class="title">Preferences</span>
				</div>
			</div>
			<div class>
				<!-- nav tabs -->
				<div class="row">
					<div class="col-12 ml-4">
						<nav>
							<div
								class="nav-tab__user-account nav nav-tabs"
								style="top: -1px"
								id="nav-tab"
								role="tablist"
							>
								<a
									data-toggle="tab"
									id="nav-api-keys-tab"
									href="#nav-api-keys"
									role="tab"
									aria-controls="nav-api-keys"
									aria-selected="false"
									class="nav-link"
									>API Keys and Webhooks</a
								>

								<a
									data-toggle="tab"
									id="nav-dd-settings-tab"
									href="#nav-dd-settings"
									role="tab"
									aria-controls="nav-dd-settings"
									aria-selected="false"
									class="nav-link"
									>Direct Debit Settings</a
								>
							</div>
						</nav>
					</div>
					<div
						class="col-12 mt-4 tab-content"
						id="nav-tabContent"
						style="min-height: 450px"
					>
						<div
							class="dashboard rounded p-4 tab-pane fade ml-4"
							id="nav-api-keys"
							role="tabpanel"
							aria-labelledby="nav-api-keys-tab"
						>
							<div class="row">
								<div class="input-group mb-2 mr-sm-8">
									<label class="key__label mr-4"
										>Public Key</label
									>
									<input
										ref="public_key"
										type="password"
										class="form-control api__keys"
										v-model="public_key"
										id="inlineFormInputGroupMinimumPrice1"
										placeholder="Public API Key"
									/>
									<div
										class="input-group-prepend"
										@click="
											copyToClipboard($refs.public_key)
										"
									>
										<div class="input-group-text">Copy</div>
									</div>
								</div>
							</div>
							<div class="row mt-4">
								<div class="input-group mb-2 mr-sm-8 mt-2">
									<label class="key__label mr-4"
										>Secret Key</label
									>
									<input
										ref="secret_key"
										type="text"
										class="form-control api__keys"
										v-model="secret_key"
										id="inlineFormInputGroupMinimumPrice1"
										placeholder="Secret API Key"
									/>
									<div
										class="input-group-prepend"
										@click="
											copyToClipboard($refs.secret_key)
										"
									>
										<div class="input-group-text">Copy</div>
									</div>
								</div>
							</div>
							<hr />
							<div class="row mt-4">
								<h5 class="mb-4">WEBHOOK SETTINGS</h5>

								<div class="input-group mb-2 mr-sm-8 mt-2">
									<label class="key__label mr-4"
										>Webhook URL</label
									>
									<input
										v-if="updateWebhook"
										ref="secret_key"
										type="text"
										class="form-control api__keys"
										id="inlineFormInputGroupMinimumPrice1"
										placeholder="http://demo.webhook.url/"
									/>
									<input
										v-else
										ref="secret_key"
										type="text"
										class="form-control api__keys"
										v-model="webhookURL"
										id="inlineFormInputGroupMinimumPrice1"
										readonly
									/>

									<div
										class="input-group-prepend"
										@click="
											copyToClipboard($refs.secret_key)
										"
									>
										<div class="input-group-text">Copy</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<button
											v-if="updateWebhook"
											class="btn btn-primary my-3"
											@click.prevent="updateWebhookFxn"
											ref="submitBtn"
											type="submit"
											:disabled="isSubmitting == true"
										>
											<span v-if="isSubmitting"
												>...Saving Settings</span
											>
											<span v-else>Save Webhook URL</span>
										</button>
										<button
											v-else
											class="btn btn-primary my-3"
											@click="
												{
													{
														updateWebhook =
															!updateWebhook;
													}
												}
											"
										>
											<span>Update Webhook URL</span>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div
							class="dashboard rounded p-4 tab-pane fade ml-4"
							id="nav-dd-settings"
							role="tabpanel"
							aria-labelledby="nav-dd-settings-tab"
						>
							<form @submit.prevent="">
								<div class="row">
									<!-- Charge Type -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="input-item-label"
												>Charge Type</label
											>
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
											<v-select
												class="v-select"
												placeholder="Select a charge type"
												@keydown.enter.native.prevent
												:options="chargeOptions"
												v-if="updatePreference"
											></v-select>
											<input
												v-else
												type="text"
												:value="chargeOption"
												readonly
												required
												class="form-control input-bordered"
											/>
										</div>
									</div>
									<!-- Settlement Type -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="input-item-label"
												>Settlement Type</label
											>
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
											<v-select
												class="v-select"
												placeholder="Select settlement type"
												@keydown.enter.native.prevent
												:options="settlementTypes"
												v-if="updatePreference"
											></v-select>
											<input
												v-else
												type="text"
												:value="settlementType"
												readonly
												required
												class="form-control input-bordered"
											/>
										</div>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12">
										<button
											v-if="updatePreference"
											class="btn btn-lg btn-primary mg-b-20"
											type="submit"
											:disabled="isSubmitting"
										>
											<span v-if="isSubmitting"
												>...Loading</span
											>
											<span v-else>Update Settings</span>
										</button>
										<button
											v-else
											class="btn btn-lg btn-primary mg-b-20"
										>
											<span>Edit Settings</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";

export default {
	mixins: [notifications, modalMixin],
	data() {
		return {
			use_otp_for_transaction: false,
			receive_email_notification_for_transfer: false,
			monthlyEmailReport: false,
			initializedAppResult: {},
			versions: ["v1", "v2"],
			app: "",
			apiVersion: "",
			webhookURL: "http://demo.webhook.url/",
			call_id: "",
			clientID: "",
			hasNewResponse: false,
			isSubmitting: false,
			verificationResult: [],
			public_key: "",
			secret_key: "",
			copied: false,
			updateWebhook: false,
			updatePreference: false,
			etz_terminal_id: "",
			chargeOptions: ["customer", "merchant"],
			settlementTypes: ["wallet", "bank"],
			settlementType: "",
			chargeOption: "",
		};
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async updateWebhookFxn() {
			this.updateWebhook = !this.updateWebhook;
		},
		copyToClipboard(text) {
			text.select();
			text.setSelectionRange(0, 99999);
			document.execCommand("copy");
			this.copied = true;
			this.showSuccessNotification("API Key copied!");
			setTimeout(() => {
				this.copied = false;
			}, 1000);
		},
	},
	mounted() {
		this.chargeOption = this.user.directDebit.chargeType;
	},
};
</script>
<style scoped>
.input-group-text {
	cursor: pointer;
}
.api__keys {
	overflow: hidden;
	text-overflow: ellipsis;
}
.key__label {
	line-height: 30px;
	font-size: 14px;
	font-weight: 700;
}
</style>
