<template>
	<div
		class="tab-pane fade show active"
		id="send_single_sms"
		role="tabpanel"
		aria-labelledby="send_single_sms-tab"
	>
		<div class="flex__end">
			<span
				class="badge badge-warning"
				style="font-size: 14px; font-weight: 500"
				>(Each API call costs &#8358;{{ servicePrice }})</span
			>
		</div>

		<form
			@submit.prevent="handleSubmit"
			class="row border-bottom pb-4 my-4"
		>
			<div class="col-8">
				<div class="row">
					<div class="col-12">
						<label class="input-item-label"> Phone Number </label>
					</div>
					<div class="col-9 col-md-6">
						<CustomPhoneInput
							label=""
							v-model="request.phonenumber"
							placeholder="Enter your phone number"
							:error="formErrors.phonenumber"
							:dropdownOptions="{
								disabled: request.phone_number.length > 0,
							}"
						/>
					</div>

					<div class="col-3 col-md-2">
						<button
							@click.prevent="handleNumber"
							class="btn w-100 btn-lg btn-primary"
						>
							Add
						</button>
					</div>
				</div>
			</div>

			<div class="col-12 mb-4">
				<ul
					v-if="request.phone_number.length > 0"
					class="d-flex mt-2 no-marker"
				>
					<li
						v-for="(phone, index) in request.phone_number"
						:key="index"
					>
						<div class="d-flex mr-2">
							<span>
								{{ phone.nationalNumber }}
							</span>
							<img
								@click.prevent="
									request.phone_number.splice(index, 1)
								"
								src="/assets/img/close.svg"
								alt="close.svg"
								class="ms-2 close"
							/>
						</div>
					</li>
				</ul>
			</div>

			<div class="col-md-4">
				<CustomSelect
					name="app"
					:optionLabel="'app_name'"
					label="Application"
					placeholder="Select Application"
					:options="applications"
					v-model="request.app"
					:required="true"
					:error="formErrors.app"
					:handleError="handleError"
				/>
			</div>

			<template v-if="request.app">
				<div v-if="qloading" class="flex__center py-5 col-12">
					<Spinner :size="'30px'" />
				</div>

				<template v-else>
					<div class="col-md-4 mt-2">
						<CustomSelect
							name="sender_id"
							label="Sender ID"
							placeholder="Select Sender ID"
							:options="merchSenders"
							v-model="request.sender_id"
							:required="true"
							:error="formErrors.sender_id"
							:handleError="handleError"
						/>
					</div>

					<template v-if="request.sender_id">
						<div class="col-md-4 mt-2">
							<CustomSelect
								name="apiVersion"
								label="API Version"
								placeholder="Select API version"
								:options="['v1', 'v2']"
								v-model="request.apiVersion"
								:required="true"
								:error="formErrors.apiVersion"
								:handleError="handleError"
							/>
						</div>

						<div class="col-md-4">
							<CustomSelect
								name="currency_type"
								label="Currency"
								:optionLabel="'currency'"
								placeholder="Select Currency Type"
								:options="$store.state.account.customerWallets"
								v-model="request.currency_type"
								:required="true"
								:error="formErrors.currency_type"
								:handleError="handleError"
							/>
						</div>

						<div class="col-12">
							<CustomTextArea
								:name="'message'"
								v-model="request.message"
								:label="'Message'"
								:placeholder="'Input your message here'"
								:required="true"
								:error="formErrors.message"
								:handleError="handleError"
								:showCount="true"
							/>
						</div>

						<div class="col-md-4 mt-2">
							<button
								type="submit"
								class="btn btn-lg btn-primary w-100"
								:disabled="loading"
							>
								<Spinner v-if="loading" />
								<span v-else>Send SMS</span>
							</button>
						</div>
					</template>
				</template>
			</template>
		</form>
		<MessagingAPIFormat :request="request" />
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import MessagingAPIFormat from "../common/MessagingAPIFormat/MessagingAPIFormat";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import CustomTextArea from "../../../../utils/CustomTextArea/CustomTextArea";
import { formValidator } from "../../../../utils/FormValidator/FormValidator";
import Spinner from "../../../../utils/Spinner/Spinner";
import { countries, kirusa, twilio, aficas_talking } from "../enums";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

const initialState = () => ({
	request: {
		app: "",
		phonenumber: "",
		phone_number: [],
		message: "",
		apiVersion: "",
		currency_type: "",
		sender_id: "",
		use_default: false,
	},
	loading: false,
	qloading: false,
	formErrors: {},
	successMsg: false,
	merchSenders: [],
});

export default {
	name: "SendSingleSms",
	mixins: [notifications],
	data() {
		return {
			...initialState(),
			clientID: this.$store.state.account.clientID,
		};
	},
	props: ["servicePrice", "applications"],
	components: {
		CustomSelect,
		CustomInput,
		Spinner,
		CustomTextArea,
		MessagingAPIFormat,
		CustomPhoneInput,
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault();

			const payload = { ...this.request };
			delete payload.phonenumber;
			const { formErrors, isValid } = formValidator(payload);
			if (isValid) this.sendSMS();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleNumber() {
			if (
				this.request.phonenumber.valid &&
				this.request.phonenumber.valid == true
			) {
				const found = this.request.phone_number.find(
					(phone) =>
						phone.nationalNumber ==
						this.request.phonenumber.nationalNumber
				);

				if (!found) {
					this.request.phone_number = [
						...this.request.phone_number,
						{ ...this.request.phonenumber },
					];

					this.request.phonenumber = { normal: "" };

					this.formErrors = {
						...this.formErrors,
						phonenumber: "",
					};

					return;
				}
			} else {
				this.formErrors = {
					...this.formErrors,
					phonenumber: "Invalid Phone Number",
				};
			}
		},
		async getSmsConfig() {
			try {
				this.qloading = true;
				let response = await this.$store.dispatch(
					"services/getSMSConfig",
					{
						clientID: this.clientID,
						appname: this.request.app.app_name,
					}
				);
				const { status, data } = response || {};

				if (status === 200) {
					this.qloading = false;
					const { result, message } = data || {};
					const { sender_id_route1, sender_id_route2 } = result || {};

					if (sender_id_route1) {
						this.merchSenders.push(sender_id_route1);
						this.request.sender_id = sender_id_route1;
					}
					if (sender_id_route2) {
						this.merchSenders.push(sender_id_route2);
						if (!this.request.sender_id)
							this.request.sender_id = sender_id_route2;
					}

					if (!sender_id_route1 && !sender_id_route2) {
						this.request.sender_id = "BluService";
						this.request.useDefault = true;
					}
					this.showSuccessNotification(message);
				}
			} catch (error) {
				this.qloading = false;
				this.request.sender_id = "BluService";
				this.request.useDefault = true;
				this.showErrorLoginNotification(error.message);
			}
		},
		checkCode(provider, name) {
			return provider.filter((country) => country.name === name);
		},
		providerEvaluator(name) {
			const kirusaCode = this.checkCode(kirusa, name);
			const africasCode = this.checkCode(aficas_talking, name);
			const twilioCode = this.checkCode(twilio, name);

			if (kirusaCode && kirusaCode.length > 0) return kirusaCode;
			else if (twilioCode && twilioCode.length > 0) return twilioCode;
			else if (africasCode && africasCode.length > 0) return africasCode;
		},
		getRequestData() {
			const { phone_number, app } = this.request;
			const payload = {
				...this.request,
				phone_number: phone_number.map((phone) => phone.nationalNumber),
				country_name: phone_number[0].countryCallingCode,
				currency_type: this.request.currency_type.currency,
			};
			delete payload.app;
			let verificationData = {
				headers: {
					clientid: this.clientID,
					appname: app.app_name,
					apikey: app.api_key,
				},
				payload,
			};
			return verificationData;
		},
		async sendSMS() {
			let verificationData = this.getRequestData();
			this.loading = true;

			try {
				let response = await this.$store.dispatch(
					"services/sendSingleSms",
					verificationData
				);

				const { status, data } = response || {};
				if (status === 200) {
					this.loading = false;
					this.response = data;
					this.showSuccessNotification(data.message);
					Object.assign(this.$data, { ...initialState() });
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	computed: {
		allCountries() {
			return countries.map(({ name }) => name);
		},
	},
	watch: {
		"request.app": async function (newVal) {
			newVal !== "" && this.getSmsConfig();
		},
	},
};
</script>

<style scoped>
.close {
	width: 20px;
	margin-left: 10px;
}

ul.no-marker {
	list-style-type: none;
	padding-left: 0;
}
</style>
