import axios from "axios";

let walletsBaseUrl = process.env.VUE_APP_WALLETS_BASE_URL;

export default {
	getServicesPricing({ apiKey, params }) {
		return axios.get(`${walletsBaseUrl}/pricing`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
