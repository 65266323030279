<template>
	<div class="">
		<Tabs :tabNames="tabNames" :tabId="'myTab'" />

		<div class="tab-content" id="myTabContent">
			<WalletTab :wallet="wallet" :refetch="refetch" />
			<WalletCards />
			<WalletSubscriptions />
		</div>
	</div>
</template>

<script>
	import Tabs from "../../../../../utils/Tabs/Tabs";
	import WalletTab from "./WalletTab/WalletTab";
	import WalletCards from "./WalletCards/WalletCards";
	import WalletSubscriptions from "./WalletSubscriptions/WalletSubscriptions";

	const tabNames = ["Wallet", "Subscriptions"];

	export default {
		name: "WalletDetialsContent",
		data() {
			return {
				tabNames,
			};
		},
		props: ["wallet", "refetch"],
		components: {
			Tabs,
			WalletTab,
			WalletCards,
			WalletSubscriptions,
		},
	};
</script>
