import invoices from "@/services/wallets/invoices/invoices";

export default {
	namespaced: true,
	actions: {
		async getAllInvoices({}, payload) {
			return await invoices.getAllInvoices(payload);
		},
		async createInvoice({}, payload) {
			return await invoices.createInvoice(payload);
		},
		async updateInvoice({}, payload) {
			return await invoices.updateInvoice(payload);
		},
		async issueInvoice({}, payload) {
			return await invoices.issueInvoice(payload);
		},
		async deleteInvoice({}, payload) {
			return await invoices.deleteInvoice(payload);
		},
    async getCustomerInvoiceStatement({}, payload) {
      return await invoices.getCustomerInvoiceStatement(payload)
    }
	},
};
