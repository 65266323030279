<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<button
								class="btn btn-sm btn--primary px-4"
								@click="newInvoice"
							>
								<img
									src="/assets/img/icons/receipt-add.svg"
									class="mr-2"
									alt=""
								/>
								Create Invoice
							</button>
						</AccessControl>
					</template>
					<template slot="filterForm">
						<InvoiceFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('invoiceRef')"
							:download="downloadInvoice"
							:isInvoice="true"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
				:emptyText="emptyText"
			>
				<template slot="empty">
					<AccessControl>
						<button
							class="btn btn-sm btn--primary px-4"
							@click="newInvoice"
						>
							<img
								src="/assets/img/icons/receipt-add.svg"
								class="mr-2"
								alt=""
							/>
							Create Invoice
						</button>
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="invoiceRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="{ row }">
							{{ timeDateFormat(row.createdAt).date }}
							{{ timeDateFormat(row.createdAt).time }}
						</template>
						<template slot="due_date" slot-scope="{ row }">
							{{ timeDateFormat(row.due_date).date || "N/A" }}
							{{ timeDateFormat(row.due_date).time }}
						</template>
						<template slot="amount" slot-scope="{ row }">
							{{ row.currency }} {{ toCurrency(row.amount, " ") }}
						</template>
						<template
							slot="other_info.customer.first_name"
							slot-scope="{ row }"
						>
							{{ row.other_info.customer.first_name }}
							{{ row.other_info.customer.last_name }}
						</template>
						<template slot="invoice_type" slot-scope="{ row }">
							{{ titleCase(row.invoice_type) }}
						</template>
						<template slot="status" slot-scope="{ row }">
							{{ titleCase(row.status) }}
						</template>
						<template slot="action" slot-scope="{ row }">
							<DraftActionButton
								:draftRef="$refs.invoiceRef"
								:invoice="row"
								:copy="copy"
								:editInvoice="() => editInvoice(row)"
								:duplicateInvoice="() => duplicateInvoice(row)"
							/>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import DraftActionButton from "../buttons/DraftActionButton/DraftActionButton";
import InvoiceFilters from "../common/InvoiceFilters/InvoiceFilters";
import { invoiceTableHeadings, invoiceCsvRows } from "../enums";

export default {
	mixins: [componentData, notifications],
	name: "InvoiceList",
	data() {
		return {
			emptyTitle: "No Invoices Yet, Let’s get you Started",
			emptyText:
				"You have not created any invoice yet, Click on “Add Invoice” to add a new Invoice. You can click on Items Tab to add Items you might want to add in your invoice.",
			...this.tableOptions(invoiceTableHeadings, this.size),
		};
	},
	components: {
		InvoiceFilters,
		DraftActionButton,
	},
	methods: {
		editInvoice(invoice) {
			this.$router.push({
				name: "NewInvoice",
				params: { invoice },
			});
		},
		duplicateInvoice(invoice) {
			this.$router.push({
				name: "NewInvoice",
				params: { invoice, duplicate: true },
			});
		},
		newInvoice() {
			this.$router.push("/invoice/new");
		},
		refetch(filters) {
			this.handleRefetch(filters, this.getInvoice, "invoiceRef");
		},
		async getInvoice() {
			this.handleAPICall("invoices/getAllInvoices");
		},
		async downloadInvoice() {
			const download = (filterData) =>
				this.downloadReport(invoiceCsvRows, filterData.data.data);
			this.downloadTableReport("invoices/getAllInvoices", download);
		},
		async copy(value) {
			const copied = this.copyToClipboard(value);
			copied && this.showSuccessNotification("Invoice Link Copied!");
		},
	},

	created() {
		this.getInvoice();
	},
	mounted() {
		this.setColums(invoiceTableHeadings);
	},
};
</script>
