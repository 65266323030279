export const sampleResponse = {
	data: {
		status: 200,
		message: "Status Message",
		data: {
			cardScheme: "CARD SCHEME",
			cardType: "CARD TYPE",
			cardBrand: "CARD BRAND",
			county: {
				countryCode: "COUNTRY CODE",
				cardCountry: "COUNTRY NAME",
				countryFlag: "CONTRY FLAG",
				countryLatitude: "COUNTRY LATITUDE",
				countryLongitude: "COUNTRY LONGITUDE",
				countryAbbrv: "COUNTRY ABBREVATION",
				cardCurrency: "CARD CURRENCY",
			},
			cardIssuer: {
				bankName: "BANK NAME",
				bankURL: "BANK URL",
				bankPhone: "BANK PHONE",
			},
		},
	},
};
