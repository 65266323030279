<template>
	<div class="d-flex justify-content-center p-5">
		<div class="text-center">
			<img src="/assets/img/icons/error.svg" alt="" width="50px" />

			<h5 class="text-danger mt-4">{{ errorMsg }}</h5>

			<br />

			<template v-if="!hideBtn">
				<button
					class="btn btn--sm btn--primary px-5 mx-auto fw-bold"
					@click="refetch"
				>
					Retry
				</button>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FailedState",
		props: ["errorMsg", "hideBtn", "refetch"],
	};
</script>
