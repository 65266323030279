<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openModal"
    >
      Add Director
    </button>

    <CreateDirectorModal
      :id="'addDirector'"
      :closeModal="closeModal"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import CreateDirectorModal from "../modals/CreateDirectorModal.vue";

export default {
  data() {
    return {};
  },
  methods: {
    openModal() {
      $("#addDirector").modal("show");
    },
    closeModal() {
      $("#addDirector").modal("hide");
    },
  },
  components: {
    CreateDirectorModal,
  },
};
</script>
