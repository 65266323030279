<template>
	<div class="card overflow-hidden mt-3">
		<div class="row py-3 align-items-center">
			<div class="col-sm-3 text-center border-right">
				<h4 class="call-count pl-2">
					{{ toCurrency(totalBalance, wallet.currency) }}
				</h4>
				<p class="call-name pt-2">Total Balance</p>
			</div>

			<div class="col-sm-3 text-center border-right">
				<h4 class="call-count">
					{{ toCurrency(wallet.balance, wallet.currency) }}
				</h4>
				<p class="call-name pt-2">Available Balance</p>
			</div>

			<div class="col-sm-3 text-center border-right">
				<h4 class="call-count">
					{{
						toCurrency(
							Math.abs(wallet.balance_pending_settlement),
							wallet.currency
						)
					}}
				</h4>
				<p class="call-name pt-2">Pending Payouts</p>
			</div>
			<div class="col-sm-3 text-center">
				<p
					class="m-0 text--primary font-weight-bold"
					style="font-size: 18px"
				>
					{{ titleCase(wallet.account_name) }}
				</p>
				<span class="call-name pt-2 ml-3 pl-3" style="font-size: 16px">
					{{ accountNumber }}
				</span>

				<em
					class="fas fa-eye fs-24 pointer text--primary ml-3"
					@click="mask = !mask"
				/>
				<p class="m-0" style="font-size: 16px">
					{{ titleCase(wallet.bank_name) }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../public/assets/mixins";

export default {
	mixins: [notifications, componentData],
	name: "WalletInfo",
	data() {
		return {
			mask: true,
		};
	},
	props: ["wallet"],
	methods: {
		async copy(value) {
			const copied = this.copyToClipboard(this.wallet.account_number);
			copied &&
				this.showSuccessNotification(
					"Account Number Copied Successfully!"
				);
		},
	},
	computed: {
		totalBalance() {
			const { balance, balance_pending_settlement } = this.wallet || {};
			return balance_pending_settlement < 0
				? parseFloat(balance)
				: parseFloat(balance) + parseFloat(balance_pending_settlement);
		},
		accountNumber() {
			const acc = this.wallet.account_number;
			const maskedValue = "******" + acc.slice(7);
			return this.mask ? maskedValue : acc;
		},
	},
};
</script>
