<template>
  <div>
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">NIP Request form</h2>
                <h5 class="user-profile-email sub">
                  Verify NIP identities of various users
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyNIP">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Passport Number
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="nip"
                      @blur="$v.nip.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter paspport number"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.nip.$error"
                    >
                      <li class="parsley-required">
                        Passport number is required.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <CustomPhoneInput
                    :onlyCountries="['NG']"
                    label="Phone Number"
                    v-model="phonenumber"
                    @blur="$v.phonenumber.$touch()"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phonenumber.$error && formVuelidator($v.phonenumber)
                    "
                  />
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      First Name
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="first_name"
                      @blur="$v.first_name.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter First name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.first_name.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.first_name) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Last Name
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="last_name"
                      @blur="$v.last_name.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter Last name"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.last_name.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.last_name) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Date Of Birth
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <v-date-picker
                      :model-config="{
                        mask: 'YYYY-MM-DD',
                      }"
                      v-model="dob"
                      @popoverDidHide="$v.dob.$touch()"
                      mode="date"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="form-control input-bordered"
                          placeholder="Enter date of birth"
                        />
                      </template>
                    </v-date-picker>
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.dob.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.dob) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <label class="input-item-label">
                    Select Application to billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- API Version details -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label">
                    Select an API Version
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an API version"
                    v-model="apiVersion"
                    @close="$v.apiVersion.$touch()"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul
                    class="parsley-errors-list filled"
                    v-show="$v.apiVersion.$error"
                  >
                    <li class="parsley-required">
                      API version field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Verifying NIP</span>
                    <span v-else>Verify NIP</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>NIP Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from NIP request made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content" v-if="apiVersion == 'v1'">
            <div class="invoice-info-row preview-row">
              <div class="nin-image">
                <img
                  width="200px"
                  height="200px"
                  :src="verificationResult.personal_info.image_url"
                  :alt="`image for ${verificationResult.personal_info.first_name}`"
                />
              </div>
            </div>
            <p class="invoice-info-row preview-row">
              <span>Firstname</span>
              <span>{{ verificationResult.first_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Lastname</span>
              <span>{{ verificationResult.last_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Email</span>
              <span>{{
                verificationResult.email || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Gender</span>
              <span>{{
                verificationResult.gender || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Insurance</span>
              <span>{{
                verificationResult.date_of_issuance ||
                "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Passport number</span>
              <span>{{ verificationResult.passport_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Passport expiration date</span>
              <span>{{ verificationResult.passport_expiration_date }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Place of issuance</span>
              <span>{{ verificationResult.place_of_issuance }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Birth</span>
              <span>{{ verificationResult.formatted_date_of_birth }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Phone number</span>
              <span>{{ verificationResult.phone_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Request Reference</span>
              <span>{{ verificationResult.request_reference }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Verification Status</span>
              <span>{{ verificationResult.verification_status }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
          <div class="preview-content" v-else>
            <div class="invoice-info-row preview-row">
              <div class="nin-image">
                <img
                  width="200px"
                  height="200px"
                  :src="verificationResult.personal_info.image_url"
                  :alt="`image for ${verificationResult.personal_info.first_name}`"
                />
              </div>
            </div>
            <p class="invoice-info-row preview-row">
              <span>Firstname</span>
              <span>{{ verificationResult.personal_info.first_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Lastname</span>
              <span>{{ verificationResult.personal_info.last_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Email</span>
              <span>{{
                verificationResult.personal_info.email ||
                "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Gender</span>
              <span>{{
                verificationResult.personal_info.gender ||
                "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Insurance</span>
              <span>{{
                verificationResult.date_of_issuance ||
                "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Passport number</span>
              <span>{{ verificationResult.passport_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Passport expiration date</span>
              <span>{{ verificationResult.passport_expiration_date }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Place of issuance</span>
              <span>{{ verificationResult.place_of_issuance }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Birth</span>
              <span>{{
                verificationResult.personal_info.formatted_date_of_birth
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Phone number</span>
              <span>{{ verificationResult.personal_info.phone_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Request Reference</span>
              <span>{{ verificationResult.request_reference }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Verification Status</span>
              <span>{{ verificationResult.verification_status }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>
      </transition>
      <hr />
    </div>

    <div class="row">
      <div class="col-12">
        <div class="user-details-info">
          <div class="user-details--top-info">
            <h2 class="user-profile-name">API Request Format</h2>
            <h5 class="user-profile-email sub">
              Representation of how the API call can be made with the
              appropriate data neccesary
            </h5>
          </div>
        </div>
      </div>
      <div class="col-12">
        <pre
          class="code code-html"
        ><label style="top:35%;">End-Point</label><code>
            'POST' : '{{API}}IdentityVerification/NIP'
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label style="top:42%;">Header</label><code>
           {
               'clientid': '{{clientID}}',
               'appname': '{{ app ? app.app_name : 'app_name'  }}',
               'apikey': '{{ app ? app.api_key : 'app_key'  }}',
            }
       </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label style="top:42%;">Body</label><code>
            {
              "passport_number": "{{nip ? nip : "passport number"}}",
              "phone_number": "{{phonenumber.formatted ? phonenumber.formatted.replace(/\s/g, "") : "phonenumber"}}",
              "first_name": "{{first_name || 'full_name'}}",
              "dob": "{{timeDateFormat(dob, "YYYY-MM-DD").otherDate || 'dob'}}"
            }
      </code></pre>
      </div>
      <div class="col-12">
        <pre class="code code-html"><label>Sample Response</label><code>
             {{apiResponse ? apiResponse :`{
    "status_code": 200,
    "status": "Success",
    "message": "NIP Verification Completed Successfully",
    "results": {
      "request_reference": "f305594sdDJccke",
      "passport_number": "A06965943",
      "place_of_issuance": "IKOYI, LAGOS",
      "date_of_issuance": "July 4, 2020",
      "passport_expiration_date": "July 2, 2023",
      "verification_status": "VERIFIED",
      "service_type": "NIP Verification",
      "personal_info": {
        "first_name": "CHIOMA",
        "middle_name": " ",
        "last_name": "ADEKUNLE",
        "full_name": "CHIOMA ADEKUNLE",
        "email": null,
        "gender": "Female",
        "phone_number": "080123456789",
        "date_of_birth": "2020-20-20",
        "formatted_date_of_birth": "April 20, 2020",
        "image_url": "https://dev-api.blusalt.net/_/notalink"
      }
    }
  }`}}
      </code></pre>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, alpha } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import { timeDateFormat } from "@/utils/helpers";
import {
  formVuelidator,
  isDate,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },

  mixins: [notifications, modalMixin],
  validations: {
    nip: { required },
    app: { required },
    dob: { required, isDate },
    first_name: { required, alpha },
    last_name: { required, alpha },
    phonenumber: {
      required,
      isPhoneValid: isPhoneValid("phonenumber"),
    },
    apiVersion: { required },
  },
  components: {
    CustomPhoneInput,
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      if (this.apiVersion == "v2" || this.apiVersion == "") {
        return this.$store.state.appUrlV2;
      } else {
        return process.env.VUE_APP_BASE_URL;
      }
    },
  },
  data() {
    return {
      initializedAppResult: {},
      call_id: "",
      clientID: "",
      apiVersion: "",
      versions: ["v1", "v2"],
      nip: "",
      app: "",
      apikey: "",
      appname: "",
      phonenumber: "",
      hasNewResponse: false,
      apiResponse: "",
      dob: "",
      first_name: "",
      last_name: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  methods: {
    timeDateFormat,
    formVuelidator,
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("NIP")) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          passport_number: this.nip,
          phone_number: this.phonenumber.formatted.replace(/\s/g, ""),
          first_name: this.first_name,
          last_name: this.last_name,
          dob: timeDateFormat(this.dob, "YYYY-MM-DD").otherDate,
          currency_type: this.currencyType.currency,
          apiVersion: this.apiVersion,
        },
        query: this.call_id,
      };

      return verificationData;
    },
    async verifyNIP() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        let apiResponse = await this.$store.dispatch(
          "services/verifyNIP",
          verificationData
        );
        this.apiResponse = apiResponse;
        this.showSuccessNotification(apiResponse.message);
        this.verificationResult = apiResponse.results;
        this.hasNewResponse = true;
        this.isSubmitting = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();

    this.clientID = this.$store.state.account.clientID;
  },
};
</script>