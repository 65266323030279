<template>
	<AccessControl :page="true">
		<ContentWrapper
			:loading="isLoading"
			:errorMsg="errorMsg"
			:refetch="getKYCData"
		>
			<template slot="content">
				<div
					v-if="compliance.reason"
					:class="
						compliance.status && compliance.status === 'unapproved'
							? ' my-5 bg-danger-light rounded text-danger p-2'
							: ' my-5 bg-success-light rounded text-success p-2'
					"
				>
					<span
						v-if="
							compliance.status &&
							compliance.status === 'unapproved'
						"
					>
						<img
							src="/assets/img/icons/error.svg"
							alt="image"
							width="13"
							height="13"
							class="mr-2"
						/>
					</span>
					<span v-else>
						<img
							src="/assets/img/icons/CaretDown.svg"
							alt="image"
							class="mr-2"
						/>
					</span>
					<span v-if="compliance.reason">
						{{ compliance.reason }}
					</span>
					<span v-else>Invalid Information</span>
				</div>
				<CompanyInfo
					:company="{
						rc_number: compliance.rc_number,
						tin: compliance.tin,
					}"
					@refresh="getKYCData()"
				/>
				<DirectorsTable
					:directors="compliance.directors"
					@refresh="getKYCData()"
				/>
				<Documents :data="compliance" @refresh="getKYCData()" />
			</template>
		</ContentWrapper>
	</AccessControl>
</template>

<script>
import Nprogress from "nprogress";
import Spinner from "@/utils/Spinner/Spinner.vue";
import CompanyInfo from "./Compliance/Company/CompanyInfo";
import Documents from "./Compliance/Documents/Documents.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import DirectorsTable from "./Compliance/Directors/DirectorsTable.vue";
import { componentData } from "../../../public/assets/mixins/componentData";

export default {
	mixins: [componentData],
	name: "KYC",
	data() {
		return {
			compliance: {
				rc_number: "",
				tin: "",
				directors: [],
			},
			errorMsg: null,
			isLoading: false,
		};
	},
	components: {
		Spinner,
		Documents,
		CompanyInfo,
		AccessControl,
		DirectorsTable,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		async getKYCData() {
			this.isLoading = true;
			this.errorMsg = null;

			try {
				this.isSubmitting = true;
				const complianceResponse = await this.$store.dispatch(
					"account/getCompliance"
				);

				if (complianceResponse.data && complianceResponse.data[0]) {
					this.compliance = complianceResponse.data[0];
				}
				Nprogress.done();
				this.isLoading = false;
			} catch (error) {
				Nprogress.done();
				this.isLoading = false;
				this.errorMsg = "Error fetching compliance data";
			}
		},
	},
	async mounted() {
		await this.getKYCData();
	},
};
</script>
