<template>
	<Modal
		:id="id"
		:title="'Create Sub Account'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Sub Account Created  Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="h-100">
			<ResolveAccountForm
				:loading="loading"
				:closeModal="handleCloseModal"
				:formSubmit="createSubAccount"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import ResolveAccountForm from "../../../common/ResolveAccountForm/ResolveAccountForm";
import BankSelect from "../../../../../utils/Selects/BankSelect/BankSelect";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import Spinner from "../../../../../utils/Spinner/Spinner";
import {
	getError,
	getWalletApikey,
	getResponseStatus,
} from "../../../../../utils/helpers";

const initialState = () => ({
	successMsg: false,
	loading: false,
});

export default {
	mixins: [notifications],
	name: "CreateSubAccountModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "subAcctRef"],
	components: {
		Modal,
		CustomInput,
		SuccessState,
		Spinner,
		BankSelect,
		ResolveAccountForm,
	},
	methods: {
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			location.reload();
		},
		async createSubAccount(data) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"payment/createMerchantSubAccounts",
					{
						apiKey: getWalletApikey(this.$store),
						request: {
							account_number: data.account_number,
							name: data.account_name,
							bank_code: data.bank.code,
						},
					}
				);

				const success = getResponseStatus(response);
				if (success) this.successMsg = true;
				else this.showErrorLoginNotification(response.data.message);

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(getError(error));
			}
		},
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
};
</script>
