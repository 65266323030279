<template>
	<Modal
		:id="id"
		:title="'Delete Plan'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Plan Deleted Successfully'"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="deletePlan" class="h-100">
			<h4 class="text-center my-4">
				Are you sure you wanted to delete this plan?
			</h4>

			<FormButtons
				:btnName="'Delete'"
				:primary="false"
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../utils/Modal/Modal";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import Spinner from "../../../../../utils/Spinner/Spinner";
	import FormButtons from "../../../../../utils/FormButtons/FormButtons";
	import { getWalletApikey } from "../../../../../utils/helpers";

	const defaultValue = {
		loading: false,
		successMsg: false,
	};

	export default {
		mixins: [notifications],
		name: "DeletePlanModal",
		data() {
			return { ...defaultValue };
		},
		props: ["id", "reference", "closeModal", "planRef"],
		components: { Modal, SuccessState, Spinner, FormButtons },
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...defaultValue });
				this.closeModal();
			},
			handleRefetch() {
				this.handleCloseModal();
				this.planRef
					? this.planRef.refresh()
					: this.$router.push("/subscriptions/plans");
			},

			async deletePlan() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"plans/deletePlan",
						{
							apiKey: getWalletApikey(this.$store),
							reference: this.reference,
						}
					);

					if (response.status === 200) {
						this.loading = false;
						this.successMsg = true;
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
