<template>
  <EmptyWallet v-if="!merchant" />
</template>

<script>
	import { notifications } from "../../public/assets/mixins/notifications";
	import { modalMixin } from "../../public/assets/mixins/modalMixin";
  import EmptyWallet from "@/components/wallet/common/EmptyWallet/EmptyWallet.vue";

	export default {
		mixins: [notifications, modalMixin],
		components: {
      EmptyWallet,
			
		},
		computed: {
      merchant() {
      return this.$store.state.account.profile.merchant;
      },
		},
		async mounted() {
      if(this.merchant){
        this.$router.push("/funds-transfer")
      }
		},
	};
</script>
<style scoped>
	/* .shadow {
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
} */
	.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
		display: none !important;
	}
	.v-select.vs__dropdown-toggle {
		padding: 2px 15px !important;
	}
	.v-table.hidden-xs.VueTables.VueTables--client .VuePagination nav {
		display: none !important;
	}
</style>
