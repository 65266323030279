<template>
	<div class="card ">
		<div class="row">
			<div class="col-12 py-3 px-4">
				<h5 class=" font-weight-bold">Subscribers</h5>
			</div>
			<div class="col-12">
				<v-server-table
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
				>
					<template slot="account_number" slot-scope="props">
						<div v-if="props.row.account_number">
							{{ props.row.account_number.slice(0, 3) }}-XXXXXX
						</div>
					</template>
					<template slot="wallet_id" slot-scope="props">
						<div>{{ props.row.wallet_id.slice(0, 3) }}xxxx</div>
					</template>
					<template slot="createdAt" slot-scope="props">
						<span>
							{{
								timeDateFormat(props.row.createdAt).date +
									" " +
									timeDateFormat(props.row.createdAt).time
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		timeDateFormat,
		getWalletApikey,
	} from "../../../../../../utils/helpers";

	const tableHeadings = {
		account_name: "Name",
		account_number: "Email",
		createdAt: "Date",
	};

	export default {
		name: "PlanSubscriber",
		methods: {
			timeDateFormat,
		},
		data() {
			return {
				columns: [],
				options: {
					perPage: 5,
					pagination: {
						chunk: 5,
						dropdown: false,
					},
					headings: tableHeadings,
					perPageValues: [],
					filterable: false,
				},
			};
		},
		created() {
			this.options.requestFunction = async (data) => {
				return (
					await this.$store.dispatch("customers/getAllCustomers", {
						apiKey: getWalletApikey(this.$store),
						params: data,
					})
				).data.data;
			};
		},
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
