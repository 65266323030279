<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreatePaymentLinkModal"
		>
			Create Payment Link
		</button>
		<CreatePaymentLinkModal
			:id="'createPaymentLink'"
			:closeModal="closeModal"
			:paymentLinkRef="paymentLinkRef"
		/>
	</div>
</template>

<script>
import CreatePaymentLinkModal from "../../modals/CreatePaymentLinkModal/CreatePaymentLinkModal";

export default {
	name: "CreatePaymentLinkButton",
	props: ["paymentLinkRef"],
	methods: {
		openCreatePaymentLinkModal() {
			$("#createPaymentLink").modal("show");
		},
		closeModal() {
			$("#createPaymentLink").modal("hide");
		},
	},
	components: {
		CreatePaymentLinkModal,
	},
};
</script>
