<template>
  <div class="row" v-if="finalPayload">
    <div class="col-12 px-4 mb-4 flex__between">
      <div><h3>Confirmation</h3></div>
      <div class="d-flex">
        <button
          class="btn btn-primary mr-3 btn-sm"
          @click="$emit('handlePrev')"
        >
          Edit Info</button
        ><SendMoneyButton />
      </div>
    </div>
    <div class="col-lg-6 px-4">
      <div class="content">
        <h4>Sender Information</h4>

        <div class="information flex__between mb-3">
          <span>First Name</span>
          <span> {{ finalPayload.senderFirstName }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Last Name</span>
          <span> {{ finalPayload.senderLastName }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Maiden Name</span>
          <span> {{ finalPayload.senderMaidenName }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Gender</span>
          <span> {{ finalPayload.senderGender }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Occupation</span>
          <span> {{ finalPayload.senderOccupation }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Phone Number</span>
          <span> {{ finalPayload.senderPhoneNumber }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Nationality</span>
          <span> {{ finalPayload.senderNationality }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Address</span>
          <span> {{ finalPayload.senderAddressOne }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>City</span>
          <span> {{ finalPayload.senderCity }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Country</span>
          <span v-if="finalPayload.senderCountry.name"> {{ finalPayload.senderCountry.name }}</span>
          <span v-else> {{ finalPayload.senderCountry }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Country Code</span>
          <span> {{ finalPayload.senderCountryCode.code3 }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Country Currency</span>
          <span> {{ finalPayload.senderCountryCurrency.code }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Post Code</span>
          <span> {{ finalPayload.senderPostCode }}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 px-4">
      <div class="content">
        <h4>Payment Information</h4>
        <div class="information flex__between mb-3">
          <span>Reciever's Bank Name</span>
          <span> {{ finalPayload.receiverBankCode.name }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Reciever's Account Number</span>
          <span> {{ finalPayload.receiverAccountNumber }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Destination Currency</span>
          <span> {{ finalPayload.receiverCountryCode.code3 }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Amount Sent</span>
          <span>{{ formatNumber(finalPayload.amount) }} USD</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Charge Fee</span>
          <span>{{ charges }} USD</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Total</span>
          <span>{{ formatNumber(finalPayload.amount) }} USD</span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 px-4">
      <div class="content">
        <h4>Reciever Information</h4>
        <div class="information flex__between mb-3">
          <span>First Name</span>
          <span>{{ finalPayload.receiverFirstName }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Last Name</span>
          <span>{{ finalPayload.receiverLastName }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Email</span>
          <span>{{ finalPayload.receiverEmail }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Phone</span>
          <span>{{ finalPayload.receiverPhoneNumber }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Address</span>
          <span>{{ finalPayload.receiverAddress }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>City</span>
          <span>{{ finalPayload.receiverCity }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>State</span>
          <span v-if="finalPayload.receiverState.state_label" >{{ finalPayload.receiverState.state_label  }}</span>
          <span v-else >{{ finalPayload.receiverState  }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Country</span>
          <span v-if="finalPayload.receiverCountry.name">{{ finalPayload.receiverCountry.name }}</span>
          <span v-else>{{ finalPayload.receiverCountry }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Country Code</span>
          <span>{{ finalPayload.receiverCountryCode.code3 }}</span>
        </div>
        <div class="information flex__between mb-3">
          <span>Country Currency</span>
          <span>{{ finalPayload.receiverCountryCurrency.code }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber, getError, getWalletApikey } from "@/utils/helpers";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import SendMoneyButton from "../buttons/ConfirmPaymentButton/ConfirmPaymentButton.vue";

export default {
  mixins: [notifications],
  name: "ConfirmationPage",
  data() {
    return {
      finalPayload: null,
      charges: 0
    };
  },

  components: { SendMoneyButton },
  computed: {
    async getCharges() {
      try {
        let response = await this.$store.dispatch("remittance/getChargeFee", {
          payload: {
            amount: this.finalPayload.amount,
          },
          apiKey: getWalletApikey(this.$store),
        });

        this.charges = response.data.data.charge;
      } catch (error) {
        console.log({error});
        this.showErrorLoginNotification(getError(error.response));
      }
    },
  },
  methods: { formatNumber },
  created() {
    this.finalPayload = JSON.parse(window.localStorage.getItem("__send_money"));
    this.getCharges()
  },
};
</script>
