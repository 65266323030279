<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openSubAcctModal"
		>
			Create Sub Account
		</button>
		<CreateSubAccountModal
			:id="'createSubAccount'"
			:closeModal="closeModal"
			:subAcctRef="subAcctRef"
		/>
	</div>
</template>

<script>
	import CreateSubAccountModal from "../../modals/CreateSubAccountModal/CreateSubAccountModal";

	export default {
		name: "CreateSubAccountButton",
		props: ["subAcctRef"],
		methods: {
			openSubAcctModal() {
				$("#createSubAccount").modal("show");
			},
			closeModal() {
				$("#createSubAccount").modal("hide");
			},
		},
		components: {
			CreateSubAccountModal,
		},
	};
</script>
