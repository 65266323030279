<template>
	<Modal
		:id="id"
		:title="plan ? 'Update Plan' : 'Create New Plan'"
		:toggle="handleCloseModal"
	>
		<SuccessState
			v-if="successMsg"
			:message="
				plan ? 'Plan Updated Successfully' : 'Plan Created Successfully'
			"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row h-100">
			<div class="col-sm-6">
				<CustomInput
					:name="'name'"
					v-model="request.name"
					:label="'Plan Name'"
					:placeholder="'Enter Plan Name'"
					:required="true"
					:error="formErrors.name"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'amount'"
					:type="'number'"
					v-model="request.amount"
					:label="'Enter Amount'"
					:placeholder="'Enter Amount'"
					:required="true"
					:error="formErrors.amount"
					:handleError="handleError"
          :isNum="true"
				/>
			</div>

			<template v-if="!plan">
        <div class="col-sm-6">
          <CustomSelect
            name="subscription_type"
            label="Subscription Type"
            placeholder="Select Subscription Type"
            :options="['one-time', 'recurring']"
            v-model="request.subscription_type"
            :required="true"
            :error="formErrors.subscription_type"
            :handleError="handleError"
          />
			  </div>

        <div
          v-if="request.subscription_type === 'recurring'"
          class="col-sm-6"
        >
          <CustomSelect
            name="interval"
            label="Interval"
            placeholder="Select Interval"
            :options="intervals"
            v-model="request.interval"
            :required="true"
            :error="formErrors.interval"
            :handleError="handleError"
          />
        </div>
      </template>

			<div class="col-12">
				<CustomInput
					:name="'description'"
					v-model="request.description"
					:label="'Description'"
					:placeholder="'Enter Description'"
					:required="true"
					:error="formErrors.description"
					:handleError="handleError"
				/>
			</div>

			<div v-if="!plan" class="col-sm-6">
				<CustomInput
					:name="'setup_amount'"
					:type="'number'"
					v-model="request.setup_amount"
					:label="'Enter Setup Amount ( Optional )'"
					:placeholder="'Enter Setup Amount'"
					:error="formErrors.setup_amount"
					:handleError="handleError"
          :isNum="true"
				/>
			</div>

			<div class="col-sm-6">
				<TaxSelect
					v-if="plan && plan.tax"
					:name="'TaxId'"
					:error="formErrors.TaxId"
					:handleError="handleError"
					v-model="request.TaxId"
					:action="(tax) => setRequest('TaxId', tax)"
					:setRequest="(tax) => setRequest('TaxId', tax)"
					:tax="request.TaxId"
				/>
				<TaxSelect
					v-else
					:name="'TaxId'"
					:error="formErrors.TaxId"
					:handleError="handleError"
					v-model="request.TaxId"
					:action="(tax) => setRequest('TaxId', tax)"
					:setRequest="(tax) => setRequest('TaxId', tax)"
				/>
			</div>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import {
	notifications,
	componentData,
	formActions,
} from "../../../../../../public/assets/mixins";
import { intervals } from "../../enums";
import TaxSelect from "@/utils/Selects/TaxSelect/TaxSelect";

const initialState = () => ({
	request: {
		name: "",
		description: "",
		amount: "",
		interval: "",
		subscription_type: "",
		setup_amount: "",
		TaxId: "",
	},
	loading: false,
	successMsg: false,
	formErrors: {},
	intervals,
});

export default {
	name: "CreatePlanModal",
	mixins: [notifications, componentData, formActions],
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "planRef", "plan", "refetch"],
  components: {
    TaxSelect
  },
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},

	methods: {
		handleSubmit(e) {
			e.preventDefault();
			const payload = { ...this.request };
			if (payload.TaxId) payload.TaxId = payload.TaxId.id;
			else delete payload.TaxId;
			!payload.setup_amount && delete payload.setup_amount;
			if (payload.subscription_type === "one-time")
				delete payload.interval;

      if(this.plan) {
        delete payload.interval;
        delete payload.subscription_type;
      }

			const { formErrors, isValid } = this.formValidator(payload);

			if (isValid)
				this.plan ? this.updatePlan(payload) : this.createPlan(payload);
			else this.formErrors = formErrors;
		},
		handleRefetch() {
			this.handleCloseModal();
			this.planRef ? this.planRef.refresh() : this.refetch();
		},
		setRequest(name, value) {
			this.request[name] = value;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
			this.setPlanData();
		},
		async createPlan(payload) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"plans/createPlan",
					{
						apiKey: this.getWalletApikey(this.$store),
						request: payload,
					}
				);

				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
		async updatePlan(payload) {
			try {
				this.loading = true;
				delete payload.setup_amount;
				const response = await this.$store.dispatch(
					"plans/updatePlan",
					{
						apiKey: this.getWalletApikey(this.$store),
						request: payload,
						reference: this.plan.reference,
					}
				);

				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
		setPlanData() {
			if (this.plan) {
				this.request = {
					...this.request,
					name: this.plan.name,
					description: this.plan.description,
					amount: this.plan.amount,
					setup_amount: this.plan.setup_amount,
					TaxId: this.plan.tax,
				};
			}
		},
	},
	mounted() {
		this.setPlanData();
	},
};
</script>
