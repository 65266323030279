export const bulkTrans = [
	{
		accountNumber: 1019744699,
		bankName: "Polaris Bank",
		accountName: "Olawale Festus O.",
		amount: 300000,
		narration: "Salary Disbursement",
		status: "verified",
	},
	{
		accountNumber: 9019744698,
		bankName: "Zenith Bank",
		accountName: "Olawale Festus O.",
		amount: 200000,
		narration: "Salary Disbursement",
		status: "unresolved",
	},
	{
		accountNumber: 3019744698,
		bankName: "United Bank of Africa",
		accountName: "Olawale Festus O.",
		amount: 350000,
		narration: "Salary Disbursement",
		status: "unresolved",
	},
	{
		accountNumber: 1019744692,
		bankName: "Polaris Bank",
		accountName: "Olawale Festus O.",
		amount: 300000,
		narration: "Salary Disbursement",
		status: "verified",
	},
	{
		accountNumber: 1019744688,
		bankName: "Polaris Bank",
		accountName: "Olawale Festus O.",
		amount: 300000,
		narration: "Salary Disbursement",
		status: "verified",
	},
	{
		accountNumber: 1019644698,
		bankName: "Polaris Bank",
		accountName: "Olawale Festus O.",
		amount: 300000,
		narration: "Salary Disbursement",
		status: "verified",
	},
	{
		accountNumber: 1019744698,
		bankName: "Polaris Bank",
		accountName: "Olawale Festus O.",
		amount: 300000,
		narration: "Salary Disbursement",
		status: "unresolved",
	},
];
