<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div class="p-4" v-else>
			<div class="SectionItem no-b-b mb-3 flex__between">
				<p class="title">Wallets</p>
			</div>

			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreateWalletButton :walletRef="$refs.walletRef"
						/></AccessControl>
					</template>
					<template slot="filterForm">
						<AccountFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('walletRef')"
							:download="downloadWallet"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty">
					<AccessControl>
						<CreateWalletButton :walletRef="$refs.walletRef" />
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="walletRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@row-click="handleClick"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="account_number" slot-scope="{ row }">
							<div v-if="row.account_number">
								{{
									row.account_number
										? `${row.account_number.slice(
												0,
												3
										  )}-XXXXXX`
										: "Not Available"
								}}
							</div>
							<span v-else></span>
						</template>
						<template slot="wallet_id" slot-scope="props">
							<div>{{ props.row.wallet_id.slice(0, 3) }}xxxx</div>
						</template>
						<template slot="Customer.business" slot-scope="{ row }">
							<div v-if="row.Customer">
								<span>
									{{
										row.Customer.business
											? "Business"
											: "Individual"
									}}
								</span>
							</div>
							<span v-else></span>
						</template>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{
									timeDateFormat(row.createdAt).date +
									" " +
									timeDateFormat(row.createdAt).time
								}}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins";
import CreateWalletButton from "../WalletOverview/buttons/CreateWalletButton/CreateWalletButton";
import AccountFilters from "../common/AccountFilters/AccountFilters";
import { tableHeadings, walletCsvRows } from "./enums";

export default {
	mixins: [componentData],
	name: "Wallets",
	data() {
		return {
			emptyTitle: "No Wallet Found",
			...this.tableOptions(tableHeadings, this.size),
		};
	},
	components: {
		CreateWalletButton,
		AccountFilters,
	},
	methods: {
		handleClick({ row }) {
			this.$router.push(`/wallet-${row.reference}`);
		},
		refetch(filters) {
			this.handleRefetch(
				{ ...filters, currency: filters.currency.code },
				this.handleWallet,
				"walletRef"
			);
		},
		async handleWallet() {
			this.options.requestFunction = async (data) => {
				const params = { ...data, ...this.filters };
				try {
					const response = (
						await this.$store.dispatch("wallets/getWallets", {
							apiKey: this.getWalletApikey(this.$store),
							params,
						})
					).data.data;
					const newData = response.data.filter(
						(val) =>
							val.reference !== "master" &&
							val.account_number &&
							val.account_name
					);
					const count = response.count;
					return { ...response, count, total: count, data: newData };
				} catch (error) {
					this.loading = false;
					this.errorMsg = this.getError(error);
				}
			};
		},
		async downloadWallet() {
			const download = (filterData) =>
				this.downloadReport(walletCsvRows, filterData.data.data);
			this.downloadTableReport("wallets/getWallets", download);
		},
	},
	created() {
		this.handleWallet();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
