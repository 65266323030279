<template>
	<Modal
		:id="id"
		:title="'Delete Coupon'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Coupon Deleted Successfully'"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="deleteCoupon" class="h-100">
			<h4 class="text-center my-4">
				Are you sure you wanted to delete this coupon?
			</h4>

			<FormButtons
				:btnName="'Delete'"
				:primary="false"
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../../public/assets/mixins";

const defaultValue = {
	loading: false,
	successMsg: false,
};

export default {
	mixins: [notifications, componentData],
	name: "DeleteCouponModal",
	data() {
		return { ...defaultValue };
	},
	props: ["id", "couponCode", "closeModal", "couponRef"],
	methods: {
		handleCloseModal() {
			Object.assign(this.$data, { ...defaultValue });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			this.couponRef.refresh();
			location.reload();
		},

		async deleteCoupon() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"coupons/deleteCoupon",
					{
						apiKey: this.getWalletApikey(this.$store),
						couponCode: this.couponCode,
					}
				);

				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
	},
};
</script>
