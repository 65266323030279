<template>
  <div class="row">
    <div class="col-12 mt-3">
      <div class="flex__end"><AddBeneficiaryButton :refetch="refetch" /></div>
      <div class="px-3">
        <FilterWrapper>
          <template slot="pageSelect"> </template>
          <template slot="filterForm">
            <BeneficiaryTableFilters
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="clearFilter"
              :download="downloadBeneficiaries"
            />
          </template>
        </FilterWrapper>
      </div>
    </div>
    <div v-if="loading" class="col-12">
      <div class="flex__center my-5 py-5">
        <Spinner :size="'30px'" />
      </div>
    </div>

    <div v-else-if="errorMsg" class="col-12">
      <FailedState :errorMsg="errorMsg" :refetch="refetch" />
    </div>

    <div class="col-12" :class="tbClass">
      <v-server-table
        ref="couponRef"
        class="v-table row-pointer"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @error="setError"
        @row-click="handlClick"
      >
        <template slot="sender" slot-scope="{ row }">
          <span>
            {{ `${row.firstName} ${row.lastName}` }}
          </span>
        </template>
        <template slot="createdAt" slot-scope="{ row }">
          {{ timeDateFormat(row.createdAt).date }}
        </template>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import BeneficiaryTableFilters from "./BeneficiaryTableFilters/BeneficiaryTableFilters.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import AddBeneficiaryButton from "../buttons/AddBeneficiaryButton/AddBeneficiaryButton.vue";

import {
  timeDateFormat,
  tableOptions,
  titleCase,
  getWalletApikey,
} from "@/utils/helpers";
import { beneficiaryCsvRows } from "../enums";

const tableHeadings = {
  beneficiaryReference: "Receiver ID",
  sender: "Beneficiary",
  email: "Email",
  phoneNumber: "Phone",
  city: "City",
  country: "Country",
};

export default {
  mixins: [componentData],
  name: "Beneficiaries",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 20),
    };
  },
  components: {
    BeneficiaryTableFilters,
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
    AddBeneficiaryButton,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    handlClick({ row }) {
      this.$router.push(
        `/wallet/remittance/beneficiary/${row.beneficiaryReference}`
      );
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getBeneficiaries();
      this.$refs.couponRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.couponRef.refresh();
    },
    async getBeneficiaries() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch("remittance/getBeneficiaries", payload)
          ).data.data;
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
    async downloadBeneficiaries() {
      const download = (filterData) =>
        this.downloadReport(beneficiaryCsvRows, filterData.data.data);
      this.downloadTableReport("remittance/getBeneficiaries", download);
    },
  },

  created() {
    this.getBeneficiaries();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
