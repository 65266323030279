<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openEditService"
		>
			Edit Services
		</button>
		<EditServiceModal
			:id="'editService'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
	import EditServiceModal from "../../modals/EditServiceModal/EditServiceModal";

	export default {
		name: "EditServiceButton",
		props: ["refetch"],
		methods: {
			openEditService() {
				$("#editService").modal("show");
			},
			closeModal() {
				$("#editService").modal("hide");
			},
		},
		components: {
			EditServiceModal,
		},
	};
</script>
