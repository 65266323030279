<template>
  <div>
    <div class="flex__between">
      <div>
        <BlusaltLogo />
      </div>
      <div class="primary logo-side-text">ACCOUNT STATEMENT</div>
    </div>

    <div class="duration">
      <div class="primary heading">Payment Transactions</div>
      <div class="primary period">{{ duration.from }} - {{ duration.to }}</div>
    </div>

    <div class="d-flex justify-content-between address">
      <div class="w-100 col-4 px-0">
        <div class="from">
          <div class="heading bg--primary">FROM</div>
          <div class="address-info faint-primary-bg text--black">
            Blusalt Financial Limited, <br />
            info@blusalt.net <br />
            (+234) 812 234 5678
          </div>
        </div>
        <div class="mt-4 to">
          <div class="heading bg--primary">TO</div>
          <div class="address-info faint-primary-bg text--black">
            {{ customer.first_name }} {{ customer.last_name }}, <br />
            {{ customer.email }} <br />
            {{ customer.phone }}
          </div>
        </div>
      </div>

      <div class="w-100 col-4 px-0">
        <div class="from">
          <div class="heading bg--primary">NUMBER OF TRANSACTIONS</div>
          <div class="address-info faint-primary-bg text--black">
            {{ data.length }}
          </div>
        </div>
        <div class="mt-4 to">
          <div class="heading bg--primary">TOTAL AMOUNT</div>
          <div class="flex__between summaries faint-primary-bg">
            <div class="summary-items black">Sub-Total</div>
            <div class="summary-items black">
              {{ toCurrency(totalAmount, currency) }}
            </div>
          </div>
          <div class="flex__between summaries faint-primary-bg">
            <div class="summary-items black">Charges</div>
            <div class="summary-items black">
              {{ toCurrency(totalCharge, currency) }}
            </div>
          </div>
          <div class="flex__between summaries faint-primary-bg">
            <div class="summary-items black bold">Total</div>
            <div class="summary-items black bold">
              {{ toCurrency(totalAmount + totalCharge, currency) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toCurrency } from "@/utils/helpers";
import { humanDateFormat } from "@/utils/helpers";
import BlusaltLogo from "./BlusaltLogo.vue";

export default {
  props: ["data", "dates"],
  components: {
    BlusaltLogo,
  },
  computed: {
    totalAmount() {
      return this.calcTotal("amount");
    },
    totalCharge() {
      return this.calcTotal("charges");
    },
    currency() {
      return this.data[0].currency;
    },
    duration() {
      const from = humanDateFormat(this.dates.from);
      const to = humanDateFormat(this.dates.to);

      return { from, to };
    },
    customer() {
      const { email, first_name, last_name, phone } =
        this.data[0].Customer || {};
      return { email, first_name, last_name, phone };
    },
  },
  methods: {
    toCurrency,
    calcTotal(key) {
      return this.data.reduce((prev, curr) => {
        prev += curr[key];
        return prev;
      }, 0);
    },
  },
};
</script>
