<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="SectionItem no-b-b mb-4 justify-content-start">
				<img
					src="/assets/img/icons/arrow-left.svg"
					width="30"
					alt=""
					class="pointer"
					@click="$router.push('/wallet/reserved-accounts')"
				/>
				<p
					v-if="reservedAccount.Customer.business"
					class="title ml-3 mb-0"
				>
					{{
						reservedAccount.Customer.business_details.name ||
							"Reserved Account Details"
					}}
				</p>
				<p v-else class="title ml-3 mb-0">
					{{ reservedAccount.Customer.first_name }}
					{{ reservedAccount.Customer.last_name }}
				</p>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getReservedAccount(reference)"
			/>

			<template v-else>
				<div class="content__slider">
					<Tabs
						:tabNames="[
							'Personal_Information',
							'Business_Information',
						]"
						:tabId="'reserveTab'"
					/>
				</div>

				<div class="tab-content" id="myReserveContent">
					<PersonalInformation :reservedAccount="reservedAccount" />
					<BusinessInformation :customer="reservedAccount.Customer" />
				</div>

				<ReservedAccountTransactions :reference="reference" />
			</template>
		</template>
	</div>
</template>

<script>
import Tabs from "../../../../utils/Tabs/Tabs";
import ReservedAccountTransactions from "./ReservedAccountTransactions/ReservedAccountTransactions";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
import Spinner from "../../../../utils/Spinner/Spinner";
import FailedState from "../../../../utils/ResponseState/FailedState";
import { getWalletApikey } from "../../../../utils/helpers";

export default {
	name: "ReservedAccountDetails",
	data() {
		return {
			reference: "",
			loading: false,
			errorMsg: "",
			reservedAccount: {},
		};
	},
	components: {
		ReservedAccountTransactions,
		BusinessInformation,
		PersonalInformation,
		Spinner,
		FailedState,
		EmptyWallet,
		Tabs,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		async getReservedAccount(reference) {
			try {
				this.loading = true;
				this.errorMsg = "";
				const response = await this.$store.dispatch(
					"reservedAccounts/getReservedAccount",
					{
						apiKey: getWalletApikey(this.$store),
						reference,
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.reservedAccount = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
	},

	async mounted() {
		const reference = this.$route.params.reference;
		this.reference = reference;
		this.getReservedAccount(reference);
	},
};
</script>
