<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<InvoiceFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('draftRef')"
							:download="downloadInvoice"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
				:emptyText="emptyText"
			>
				<template slot="content">
					<v-server-table
						ref="draftRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="{ row }">
							{{ timeDateFormat(row.createdAt).date }}
						</template>
						<template slot="due_date" slot-scope="{ row }">
							{{ timeDateFormat(row.due_date).date || "N/A" }}
							{{ timeDateFormat(row.due_date).time }}
						</template>
						<template slot="amount" slot-scope="{ row }">
							{{ row.currency }} {{ toCurrency(row.amount, " ") }}
						</template>
						<template
							slot="other_info.customer.first_name"
							slot-scope="{ row }"
						>
							{{ row.other_info.customer.first_name }}
							{{ row.other_info.customer.last_name }}
						</template>
						<template slot="invoice_type" slot-scope="{ row }">
							{{ titleCase(row.invoice_type) }}
						</template>
						<template slot="status" slot-scope="{ row }">
							{{ titleCase(row.status) }}
						</template>
						<template slot="action" slot-scope="{ row }">
							<DraftActionButton
								:draftRef="$refs.draftRef"
								:invoice="row"
								:editInvoice="() => editInvoice(row)"
								:duplicateInvoice="() => duplicateInvoice(row)"
							/>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import InvoiceFilters from "../common/InvoiceFilters/InvoiceFilters";
import DraftActionButton from "../buttons/DraftActionButton/DraftActionButton";
import { draftTableHeadings, invoiceCsvRows } from "../enums";

export default {
	mixins: [componentData],
	name: "InvoiceList",
	data() {
		return {
			filters: { status: "draft" },
			emptyTitle: "No Drafts Available",
			emptyText: "You have not created any draft yet.",
			...this.tableOptions(draftTableHeadings, this.size),
		};
	},
	components: {
		InvoiceFilters,
		DraftActionButton,
	},
	methods: {
		editInvoice(invoice) {
			this.$router.push({
				name: "NewInvoice",
				params: { invoice },
			});
		},
		duplicateInvoice(invoice) {
			this.$router.push({
				name: "NewInvoice",
				params: { invoice, duplicate: true },
			});
		},
		refetch(filters) {
			this.handleRefetch(filters, this.getInvoice, "draftRef");
		},
		async getInvoice() {
			this.handleAPICall("invoices/getAllInvoices");
		},
		async downloadInvoice() {
			const download = (filterData) =>
				this.downloadReport(invoiceCsvRows, filterData.data.data);
			this.downloadTableReport("invoices/getAllInvoices", download);
		},
	},

	created() {
		this.getInvoice();
	},
	mounted() {
		this.setColums(draftTableHeadings);
	},
};
</script>
