<template>
  <div>
    <div class="col-lg-12">
      <div class="SectionItem no-b-b mb-3">
        <div class="row">
          <div class="col-12">
            <div class="SectionHeader">
              <span class="title">Request Logs</span>
              <div class="description">
                View the historical data of your API Requests on any of the
                Blusalt services registered against your account.
              </div>
            </div>
          </div>
          <div class="col-6 mt-4">
            <v-select
              class="v-select"
              placeholder="Select an application"
              v-model="selectedApp"
              @keydown.enter.native.prevent
              :required="!apps"
              :disabled="!apps"
              :options="apps.applications"
              label="app_name"
            ></v-select>
          </div>

          <div class="col-12 mt-3">
            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 30vh"
              v-if="isFetching == true"
            >
              <Loader></Loader>
            </div>

            <DefaultContent
              v-if="isFetching == false && identificationLogs.length == 0"
              :image="'empty-resources.png'"
              :title="'No Historical API Request Data'"
              :subtitle="'Start by making an API Request in order to access the logs of your requests.'"
            ></DefaultContent>

            <div
              class="user-setting-activity-logs"
              v-if="isFetching == false && identificationLogs.length > 0"
            >
              <v-client-table
                ref="serverTableRef"
                name="question-table"
                class="v-table"
                :data="identificationLogs"
                :columns="columns"
                :options="options"
              >
                <template slot="request_reference" slot-scope="props">
                  <div class="user-details">
                    <div class="user-block">
                      <div class="user-info">
                        <div>{{ props.row.request_reference }}</div>
                      </div>
                      <div class="user-sub" style="font-size: 15px">
                        <div>
                          {{
                            props.row.request_time
                              | moment(" MM-DD-YYYY h:mm a")
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template slot="actual_price" slot-scope="props">
                  <div>&#8358;{{ props.row.actual_price }}</div>
                </template>
                <template
                  slot="price_set_by_whitelabel_customer"
                  slot-scope="props"
                >
                  <div>
                    &#8358;{{ props.row.price_set_by_whitelabel_customer }}
                  </div>
                </template>
                <template slot="status" slot-scope="props">
                  <span
                    v-if="props.row.status == 1"
                    class="badge badge-success badge-app"
                    >Successful</span
                  >
                  <span v-else class="badge badge-danger badge-app"
                    >failed</span
                  >
                </template>
              </v-client-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
export default {
  props: {
    apiUser: {
      type: Object,
    },
  },
  data() {
    return {
      apps: "",
      application: "",
      selectedApp: "",
      identificationLogs: [],
      isFetching: false,
      cardNumber: "",
      columns: [
        "request_reference",
        "service.service_label",
        "identity_number",
        "request_time",
        "price_per_call",
        "status",
      ],
      options: {
        sortable: [],
        perPage: 25,
        pagination: {
          chunk: 5,
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search..",
          noResults: "No matching records",
        },
        headings: {
          request_reference: "Request Reference",
          identity_number: "Identity Number",
          request_time: "Request Time",
          "service.service_label": "Service",
          price_per_call: "Amount Debited",
          status: "Status",
        },
      },
    };
  },

  watch: {
    selectedApp: async function (newValue, oldValue) {
      if (newValue != null) {
        this.isFetching = true;

        let verificationData = {
          clientID: this.apps.client_id,
          appname: newValue.app_name,
          apikey: newValue.api_key,
        };

        try {
          let apiResponse = await this.$store.dispatch(
            "logs/getLogs",
            verificationData
          );
          if (apiResponse.result) {
            this.identificationLogs = apiResponse.result;
          } else {
            this.identificationLogs = [];
          }
          this.isFetching = false;
          Nprogress.done();
        } catch (error) {
          Nprogress.done();
          this.identificationLogs = [];
          this.isFetching = false;
          console.log("error :", error);
        }
      }
    },
  },
  methods: {
    async fetchApps() {
      try {
        this.apps = await this.$store.dispatch("application/getApplications");
      } catch (error) {
        console.log("[errors getting applications] ->", error);
      }
    },
    async fetchLogs() {
      let verificationData = {
        clientID: this.apiUser.api_customer.client_id,
      };
      try {
        let apiResponse = await this.$store.dispatch(
          "logs/getAllLogs",
          verificationData
        );
        if (apiResponse.message == "No service requests Found") {
          this.identificationLogs = [];
          this.isProcessing = false;
        } else {
          this.identificationLogs = apiResponse.result;
          this.isProcessing = false;
        }
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  mounted() {
    this.fetchLogs();
    this.fetchApps();
  },
};
</script>

<style></style>
