import { subscriptionAPI } from "@/services/api";

export default {
	getAllSubscriptions({ apiKey, params }) {
		return subscriptionAPI.get(`/subscription`, {
			params,
			headers: {
				"x-api-key": apiKey,
				Accept: "text/plain",
			},
		});
	},
	getSubscription({ apiKey, id }) {
		return subscriptionAPI.get(`/subscription/${id}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createSubscription({ apiKey, request }) {
		return subscriptionAPI.post(`/subscription`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	cancelSubscription({ apiKey, id }) {
		return subscriptionAPI.put(
			`/subscription/${id}`,
			{},
			{
				headers: {
					"x-api-key": apiKey,
				},
			}
		);
	},
};
