<template>
	<div class="card ">
		<div class="row">
			<div class="col-12 py-3 px-4">
				<h5 class=" font-weight-bold">Wallet Funding Historys</h5>
			</div>
			<div class="col-12">
				<v-server-table
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="showData"
				>
					<template slot="createdAt" slot-scope="{ row }">
						<span>
							{{ timeDateFormat(row.createdAt).date }}
							{{ timeDateFormat(row.createdAt).time }}
						</span>
					</template>
					<template slot="amount" slot-scope="{ row }">
						{{ row.currency }} {{ toCurrency(row.amount, " ") }}
					</template>
					<template slot="charges" slot-scope="{ row }">
						{{ row.currency }} {{ toCurrency(row.charges, " ") }}
					</template>
					<template slot="action" slot-scope="{ row }">
						<span :class="statusColor(row.action)">
							{{ titleCase(row.action) }}
						</span>
					</template>
					<template slot="source" slot-scope="{ row }">
						{{
							titleCase(
								row.source === "merchant_transfer"
									? "Bank_transfer"
									: row.source
							)
						}}
					</template>
				</v-server-table>
			</div>
		</div>

		<TransactionsTableModal
			v-if="showModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import TransactionsTableModal from "../../../wallet/common/TransactionsTable/TransactionsTableModal";
import {
	tableOptions,
	timeDateFormat,
	titleCase,
	statusColor,
	toCurrency,
} from "../../../../utils/helpers";

const tableHeadings = {
	reference: "Transaction ID",
	amount: "Amount",
	charges: "Charges",
	action: "Type",
	source: "Source",
	createdAt: "Date",
};

export default {
	name: "WalletHistory",
	data() {
		return {
			showModal: false,
			modalData: {},
			...tableOptions(tableHeadings),
		};
	},
	components: {
		TransactionsTableModal,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		titleCase,
		timeDateFormat,
		statusColor,
		toCurrency,
		showData({ row }) {
			this.modalData = row;
			this.showModal = true;
		},
		closeModal(e) {
			this.showModal = false;
			this.modalData = null;
		},
	},

	created() {
		this.options.requestFunction = async (data) => {
			const response = await this.$store.dispatch(
				"wallets/getWalletHistory",
				{ params: { action: "credit" } }
			);
			return response.results;
		};
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
