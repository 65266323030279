<template>
	<div class="p-md-4 p-3">
		<div class="SectionItem no-b-b mb-3 flex__between">
			<p class="title">Account</p>
		</div>

		<div class="content__slider">
			<TabsV2
				:tabNames="tabNames"
				:tabId="'accountTab'"
				:tabLink="true"
			/>
		</div>

		<transition mode="out-in" name="slide">
			<router-view />
		</transition>
	</div>
</template>

<script>
import TabsV2 from "../../utils/Tabs/TabsV2";
import "./Accounts.css";

const tabNames = [
	{ tabName: "Profile", value: "profile", link: "/account/profile" },
	{ tabName: "Wallet", value: "sallet", link: "/account" },
	{ tabName: "Services", value: "services", link: "/account/services" },
	{
		tabName: "Request Log",
		value: "request_log",
		link: "/account/request-logs",
	},
	{ tabName: "Team", value: "team", link: "/account/team" },
	{ tabName: "Compliance", value: "compliance", link: "/account/kyc" },
];

export default {
	name: "Accounts",
	data() {
		return {
			tabNames,
		};
	},

	components: {
		TabsV2,
	},
};
</script>
