<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">
                  Facial comparison Request form
                </h2>
                <h5 class="user-profile-email sub">
                  Verify the similarity index between two images.
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyBVN">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <CustomUpload
                      :name="'first'"
                      :label="'First Image'"
                      :required="true"
                      :validFileTypes="['.jpg', '.jpeg', '.png']"
                      :handleChange="handleUpload"
                      :fileName="mediaData && mediaData.name"
                      :error="uploadError && 'File size must be less than 5MB'"
                    />
                  </div>
                </div>

                <!-- phone numbeer -->
                <div class="col-md-6">
                  <div class="form-group">
                    <CustomUpload
                      :name="'second'"
                      :label="'Second Image'"
                      :required="true"
                      :validFileTypes="['.jpg', '.jpeg', '.png']"
                      :handleChange="handleSecondUpload"
                      :fileName="mediaSecondData && mediaSecondData.name"
                      :error="uploadSecondError && 'File size must be less than 5MB'"
                    />
                  </div>
                </div>

                <!-- API Version details -->
                <!-- <div class="col-md-12">
                  <label class="input-item-label">Select an API Version</label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="apiVersion"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.apiVersion.$error">
                    <li class="parsley-required">API version field is required.</li>
                  </ul>
                </div>-->

                <!-- Application details -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Comparing images</span>
                    <span v-else>Compare Images</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Facial Comaprison Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from facial comparison
                request made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content">
            <p class="invoice-info-row preview-row">
              <span>Preview of images compared</span>
            </p>
            <div class="invoice-info-row preview-row">
              <div class="compare-image">
                <img
                  width="200px"
                  height="200px"
                  style="background-size: cover; background-color: black"
                  :src="verificationResult.image_url_1"
                  :alt="`image for second comapred file`"
                />
                <img
                  width="200px"
                  height="200px"
                  style="background-size: cover; background-color: black"
                  :src="verificationResult.image_url_2"
                  :alt="`image for second comapred file`"
                />
              </div>
            </div>
            <p class="invoice-info-row preview-row">
              <span>Probability or Resemblance</span>
              <span>{{ verificationResult.probability_of_resemblance }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>First Image link</span>
              <span>{{ verificationResult.image_url_1 }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Second Image link</span>
              <span>{{ verificationResult.image_url_2 }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Request Reference</span>
              <span>{{ verificationResult.request_reference }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>High Error Rate</span>
              <span>{{ verificationResult.high_error_rate }}</span>
            </p>
            <!-- <p class="invoice-info-row preview-row">
              <span>Medium Error Rate</span>
              <span>{{ verificationResult.meduim_error_rate }}</span>
            </p>-->
            <p class="invoice-info-row preview-row">
              <span>Low Error Rate</span>
              <span>{{ verificationResult.low_error_rate }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}IdentityVerification/FacialComparison`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
          <!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
          <!-- "dob": "{{dob}}" -->
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "image_1": "{{image_1 ? image_1 : "image_1"}}",
        "image_2": "{{image_2 ? image_2 : "image_2"}}",
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
            {
              "status_code": 200,
              "status": "success",
              "message": "successfully returned image comparison result",
              "results": {
                "image_url_1": "http://e6138ee4.ngrok.io/_/xWKernu1",
                "image_url_2": "http://e6138ee4.ngrok.io/_/NUqMMcX_",
                "probability_of_resembalance": 93.777,
                "request_reference": "MEiXV53qhPrO",
                "low_error_rate": 73.975,
                "meduim_error_rate": 69.101,
                "high_error_rate": 69.101
              }
            }`}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import {
  required,
  minLength,
  alphaNum,
  sameAs,
} from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    image_1: { required },
    image_2: { required },
    app: { required },
    // apiVersion: { required },
  },
  data() {
    return {
      initializedAppResult: {},
      app: "",
      versions: ["v1", "v2"],
      apiVersion: "",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      image_1: "",
      image_2: "",
      hasNewResponse: false,
      logData: [],

      mediaData: "",
      mediaSecondData: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      uploadError: "",
      uploadSecondError: "",
      apiResponse: "",

      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  components: { CustomUpload },
  methods: {
    async handleUpload({ target: { files } }) {
      this.mediaData = null;

      const file = files[0];
      if (file) {
        const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.mediaData = file;
          this.uploadError = false;
        } else {
          this.uploadError = true;
        }
      }
    },
    async handleSecondUpload({ target: { files } }) {
      this.mediaSecondData = null;

      const file = files[0];
      if (file) {
        const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.mediaSecondData = file;
          this.uploadSecondError = false;
        } else {
          this.uploadSecondError = true;
        }
      }
    },

    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Facial Comparison")) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },

    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let imagesData = new FormData();
      imagesData.append("image_1", this.mediaData);
      imagesData.append("image_2", this.mediaSecondData);

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        query: this.call_id,
        payload: {
          currency_type: this.currencyType.currency,
        },
        // apiVersion: this.apiVersion
      };

      return { verificationData, imagesData };
    },
    async verifyBVN() {
      if (this.uploadError || this.uploadSecondError) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;
      console.log("[cer] ->", verificationData);

      try {
        let apiResponse = await this.$store.dispatch(
          "services/comparePhoto",
          verificationData
        );
        console.log("[api Response from bvn ] ->", apiResponse);
        this.apiResponse = apiResponse;
        this.showSuccessNotification(apiResponse.message);
        this.verificationResult = apiResponse.results;
        this.hasNewResponse = true;
        this.isSubmitting = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>

<style scoped>
.compare-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>
