<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary--border px-4"
      @click="openAgentModal"
    >
      Invite Agent
    </button>
    <InviteAgentModal :id="'inviteAgent'" :refetch="refetch" :closeModal="closeModal" />
  </div>
</template>

<script>
import InviteAgentModal from "../../modal/InviteAgentModal/InviteAgentModal.vue";

export default {
  name: "InviteAgentButton",
  props: ["refetch"],
  methods: {
    openAgentModal() {
      $("#inviteAgent").modal("show");
    },
    closeModal() {
      $("#inviteAgent").modal("hide");
    },
  },
  components: {
    InviteAgentModal,
  },
};
</script>
