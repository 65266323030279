<template>
	<div class="card ">
		<div class="row">
			<div class="col-12 py-3 px-4">
				<h5 class=" font-weight-bold">Card Transactions</h5>
			</div>
			<div class="col-12">
				<v-server-table
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="handleClick"
				>
					<template slot="account_number" slot-scope="props">
						<div v-if="props.row.account_number">
							{{ props.row.account_number.slice(0, 3) }}-XXXXXX
						</div>
					</template>

					<template slot="createdAt" slot-scope="props">
						<span>
							{{
								timeDateFormat(props.row.createdAt).date +
									" " +
									timeDateFormat(props.row.createdAt).time
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
	// import EmptyWalletOverview from "../WalletOverview/EmptyWalletOverview/EmptyWalletOverview";
	import WalletInfo from "@/components/wallet/WalletInfo";
	import { timeDateFormat } from "../../../../../../../utils/helpers";

	const tableHeadings = {
		account_name: "Transaction ID",
		account_number: "Amount",
		createdAt: "Date",
	};

	export default {
		name: "WalletCardTransactions",
		components: {
			// EmptyWalletOverview,
			WalletInfo,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			timeDateFormat,
			viewSingleTransaction({ transaction_reference }) {
				this.$router.push({
					name: "SingleTransaction",
					params: { transaction_reference },
				});
			},
			view() {
				this.transactionData = { ...transactionData };
			},
			handleClick(e) {
				this.$router.push("/wallet/detail");
			},
		},
		data() {
			return {
				columns: [],
				options: {
					perPage: 5,
					pagination: {
						chunk: 5,
						dropdown: false,
					},
					headings: tableHeadings,
					filterable: false,
					perPageValues: [],
					// filterable: ["wallet_id", "account_number", "account_name"],
					// perPageValues: [5, 10, 25, 50, 100],
					// filterByColumn: false,
				},
			};
		},
		created() {
			this.options.requestFunction = async (data) => {
				return (
					await this.$store.dispatch("wallets/getWallets", {
						apiKey: this.merchant.api_key,
						params: data,
					})
				).data.data;
			};
		},
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
