<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="SectionItem no-b-b mb-0 justify-content-start">
				<img
					src="/assets/img/icons/arrow-left.svg"
					width="30"
					alt=""
					class="pointer"
					@click="$router.push('/wallet/payment-links')"
				/>
				<p class="title ml-3 mb-0">
					{{ payment.name || "Payment Link Details" }}
				</p>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getPaymentDetails(reference)"
			/>

			<template v-else>
				<div class="flex__between">
					<span class="ml-5">{{ payment.description }}</span>
					<UpdatePaymentLinkButton
						:payment="payment"
						:refresh="() => getPaymentDetails(reference)"
					/>
				</div>

				<div class="card overflow-hidden mt-3">
					<div class="row py-3">
						<div class="col-sm-4 text-center border-right ">
							<p class="call-name pt-2">Total Amount</p>
							<h4 class="call-count">
								{{ totals.total || 0 }}
							</h4>
						</div>

						<div class="col-sm-3 text-center border-right ">
							<p class="call-name pt-2">Currency</p>
							<h4 class="call-count">
								{{ payment.currency || "---------" }}
							</h4>
						</div>

						<div class="col-sm-5 text-center border-right ">
							<p class="call-name pt-2">Payment Link</p>
							<span class="">
								<a :href="payment.link" target="_blank">{{
									payment.link
								}}</a>
								<img
									src="/assets/img/icons/copy.svg"
									alt=""
									@click="copy(payment.link)"
									class="ml-3 pointer"
								/>
							</span>
						</div>
					</div>
				</div>

				<div class="">
					<h4>Transactions</h4>
				</div>

				<TransactionsTable :params="params" :getTotals="getTotals" />
			</template>
		</template>
	</div>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import TransactionsTable from "../../common/TransactionsTable/TransactionsTable";
	import UpdatePaymentLinkButton from "../buttons/UpdatePaymentLinkButton/UpdatePaymentLinkButton";
	import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
	import Spinner from "../../../../utils/Spinner/Spinner";
	import FailedState from "../../../../utils/ResponseState/FailedState";
	import {
		copyToClipboard,
		truncate,
		getWalletApikey,
	} from "../../../../utils/helpers";

	export default {
		name: "PaymentLinkDetails",
		mixins: [notifications],
		data() {
			return {
				reference: "",
				loading: false,
				errorMsg: "",
				payment: {},
				totals: {},
				params: { payment_link_ref: this.$route.params.reference },
			};
		},
		components: {
			Spinner,
			FailedState,
			EmptyWallet,
			TransactionsTable,
			UpdatePaymentLinkButton,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			truncate,
			async copy(value) {
				const copied = copyToClipboard(value);
				copied && this.showSuccessNotification("Payment Link Copied!");
			},
			getTotals(totals) {
				this.totals = totals;
			},
			async getPaymentDetails(reference) {
				try {
					this.loading = true;
					this.errorMsg = "";
					const response = await this.$store.dispatch(
						"payment/getPaymentLink",
						{
							apiKey: getWalletApikey(this.$store),
							reference,
						}
					);
					if (response.status === 200) {
						this.loading = false;
						this.payment = response.data.data;
					}
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
		},

		async mounted() {
			const reference = this.$route.params.reference;
			this.reference = reference;
			this.getPaymentDetails(reference);
		},
	};
</script>
