<template>
  <Modal
    cls="large-modal generate_statement_modal"
    :id="id"
    :title="'Print Statemnt'"
    :toggle="closeModal"
  >
    <template v-if="!error" #customHeader>
      <div class="flex__between mt-4">
        <div class="text">
          <div class="text--lg fw-bold primary">Preview Statement</div>
          <div class="text--xsmd light-primary">
            This is how your customer will view their statement
          </div>
        </div>
        <div class="d-flex">
          <button
            @click.prevent="handleSendToMail"
            class="btn btn--sm bg-light px-3 mr-4"
            :disabled="sending || isDisabled"
          >
            <Spinner v-if="sending" />
            <span v-else>Send to Mail</span>
          </button>

          <button
            :disabled="isDisabled"
            v-print="'#print-statement'"
            class="btn btn--sm btn--primary px-3 py-0"
          >
            <span class="d-none d-lg-block">Download PDF</span>
          </button>
        </div>
      </div>
    </template>
    <div v-if="!error">
      <div v-if="loading" class="mt-5 pt-5">
        <Spinner />
      </div>
      <AccountStatementContent
        v-else
        :data="transactions"
        :dates="dates"
        @ref="handleRef"
        :type="params.type"
      />
    </div>
    <div v-if="error" class="mt-5 pt-5">
      <FailedState :errorMsg="error" :refetch="getTransactions" />
    </div>
  </Modal>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Modal from "@/utils/Modal/Modal";
import Spinner from "@/utils/Spinner/Spinner";
import { getWalletApikey } from "@/utils/helpers";
import AccountStatementContent from "./AccountStatement/AccountStatementContent.vue";
import FailedState from "@/utils/ResponseState/FailedState";
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../../public/assets/mixins/filters";

export default {
  props: ["id", "closeModal", "dates", "params"],
  mixins: [notifications, filters],
  data() {
    return {
      printRef: "",
      loading: false,
      sending: false,
      error: "",
      transactions: {},
      customer: {},
    };
  },
  computed: {
    isDisabled() {
      return (
        (this.transactions.data && this.transactions.data.length < 1) ||
        (this.transactions.invoices && this.transactions.invoices.length < 1)
      );
    },
  },
  components: {
    Spinner,
    FailedState,
    Modal,
    AccountStatementContent,
  },
  methods: {
    getRequestData(data = {}, limit = 9999999999) {
      const requestData = {
        apiKey: getWalletApikey(this.$store),
        params: {
          ...data,
          limit,
        },
      };
      return requestData;
    },
    async getTransactions() {
      this.loading = true;
      this.error = "";
      const requestData = this.getRequestData({ ...this.params });

      const payload =
        this.params.type == "invoices"
          ? {
              apiKey: requestData.apiKey,
              params: {
                from: requestData.params.from,
                to: requestData.params.to,
                customer: requestData.params.customer,
              },
            }
          : requestData;

      return await this.$store
        .dispatch(
          this.params.type == "invoices"
            ? "invoices/getCustomerInvoiceStatement"
            : "transactions/getAllTransactions",
          payload
        )
        .then((response) => {
          this.loading = false;
          this.transactions = response.data.data || {};

          if (
            this.transactions.invoices &&
            this.transactions.invoices.length > 0
          ) {
            this.customer = this.transactions.invoices[0].other_info.customer;
          }

          if (this.transactions.data && this.transactions.data.length > 0) {
            this.customer = this.transactions.data[0].Customer;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.message;
        });
    },
    handleRef(ref) {
      this.printRef = ref;
    },
    async handleSendToMail() {
      if (this.isDisabled) return;

      this.sending = true;

      const doc = new jsPDF("p", "mm", [600, 500]);

      const canvas = await html2canvas(this.printRef);

      doc.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        1,
        1,
        undefined,
        undefined,
        undefined,
        "FAST"
      );

      const blob = doc.output("blob");
      const payload = new FormData();

      payload.append("account-statement", blob);
      payload.append("customerEmail", this.customer.email);

      try {
        const response = await this.$store.dispatch(
          "customers/sendAccStatement",
          {
            apiKey: getWalletApikey(this.$store),
            payload,
          }
        );

        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status == true
        ) {
          this.sending = false;
          this.showSuccessNotification(response.data.message);
        } else {
          this.sending = false;
          this.showErrorLoginNotification(response.data.message);
        }
      } catch (error) {
        this.sending = false;
        this.showErrorLoginNotification(this.getError(error));
      }
    },
  },

  mounted() {
    this.getTransactions();
  },
};
</script>
