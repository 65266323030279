<template>
	<div class="pt-3">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="px-3">
				<FilterWrapper>
					<template slot="pageSelect">
						<AccessControl>
							<CreateItemButton :itemsRef="$refs.itemsRef" />
						</AccessControl>
					</template>
					<template slot="filterForm">
						<ItemsTableFilters
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('itemsRef')"
							:download="downloadItems"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
				:emptyText="emptyText"
			>
				<template slot="empty">
					<AccessControl>
						<CreateItemButton :itemsRef="$refs.itemsRef" />
					</AccessControl>
				</template>
				<template slot="content">
					<v-server-table
						ref="itemsRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="description" slot-scope="{ row }">
							{{ row.description || "-----------------" }}
						</template>
						<template slot="amount" slot-scope="{ row }">
							{{ row.currency }} {{ toCurrency(row.amount, "") }}
						</template>
						<template slot="created_at" slot-scope="{ row }">
							{{ timeDateFormat(row.created_at).date }}
						</template>
						<template slot="status" slot-scope="{ row }">
							<AccessControl>
								<div class="d-flex">
									<UpdateItemButton
										:item="row"
										:refetch="refetch"
									/>
									<DeleteItemButton :item="row" />
								</div>
							</AccessControl>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins";
import CreateItemButton from "../buttons/CreateItemButton/CreateItemButton";
import UpdateItemButton from "../buttons/UpdateItemButton/UpdateItemButton";
import DeleteItemButton from "../buttons/DeleteItemButton/DeleteItemButton";
import ItemsTableFilters from "./ItemsTableFilters/ItemsTableFilters";
import { itemsTableHeadings, itemsCsvRows } from "../enums";

export default {
	mixins: [componentData],
	name: "Items",
	data() {
		return {
			emptyTitle: "You don’t have any Product Added",
			emptyText:
				"You have not created any item yet, Click on “Create Product” to add a new product.",
			...this.tableOptions(itemsTableHeadings, this.size),
		};
	},
	components: {
		ItemsTableFilters,
		CreateItemButton,
		UpdateItemButton,
		DeleteItemButton,
	},
	methods: {
		refetch(filters) {
			this.handleRefetch(filters, this.getItems, "itemsRef");
		},
		async getItems() {
			this.handleAPICall("products/getProducts");
		},
		async downloadItems() {
			const download = (filterData) =>
				this.downloadReport(itemsCsvRows, filterData.data.data);
			this.downloadTableReport("products/getProducts", download);
		},
	},
	created() {
		this.getItems();
	},
	mounted() {
		this.setColums(itemsTableHeadings);
	},
};
</script>
