<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary ml-3 mb-3 "
			@click="popChangeKeyModal"
		>
			{{ btnName || "Change key" }}
		</button>
		<ChangeKeyModal
			:id="keyType"
			:closeModal="closeModal"
			:keyType="keyType"
			:title="modalTitle"
			:data="data"
		/>
	</div>
</template>

<script>
import ChangeKeyModal from "../../modals/ChangeKeyModal/ChangeKeyModal";

export default {
	name: "ChangeKeyButton",
	props: ["keyType", "modalTitle", "data", "btnName", "refetchKeys"],
	methods: {
		popChangeKeyModal() {
			$(`#${this.keyType}`).modal("show");
		},
		closeModal() {
			$(`#${this.keyType}`).modal("hide");
		},
	},
	components: {
		ChangeKeyModal,
	},
};
</script>
