import moment from "moment";
import { Parser } from "json2csv";
import { v4 as uuidv4 } from "uuid";
import VueIziToast from "vue-izitoast";

import cardValidator from "card-validator";

import VISA from "@/assets/images/card/visa.png";
import MAESTRO from "@/assets/images/card/maestro.png";
import DINNER from "@/assets/images/card/diners.png";
import AMEX from "@/assets/images/card/amex.png";
import DISCOVER from "@/assets/images/card/discover.png";
import ELO from "@/assets/images/card/elo.png";
import HIPERCARD from "@/assets/images/card/hipercard.png";
import JCB from "@/assets/images/card/jcb.png";
import MASTERCARD from "@/assets/images/card/mastercard.png";
import MIR from "@/assets/images/card/mir.png";
import UNION from "@/assets/images/card/unionpay.png";

// export const getError = (error) => {
// 	const errorMessage =
// 		error && error.data && error.data.message
// 			? titleCase(error.data.message.replace(/[^a-z0-9+]+/gi, " "))
// 			: "Error Processing Request. Try again.";

// 	return errorMessage;
// };

export const getError = (err) => {
	if (err === undefined) return "Error Processing Request. Try again.";

	if (typeof err === "string") return err;

	const { error, message, errorData } = err.data || {};
	let errorMessage = "";

	if (err) {
		if (error && error.message)
			errorMessage = titleCase(
				error.message.replace(/[^a-z0-9+]+/gi, " ")
			);
		else if (errorData && errorData.message)
			errorMessage = titleCase(
				errorData.message.replace(/[^a-z0-9+]+/gi, " ")
			);
		else if (message)
			errorMessage = titleCase(message.replace(/[^a-z0-9+]+/gi, " "));
		else if (err.data && typeof err.data === "string")
			errorMessage = titleCase(err.data.replace(/[^a-z0-9+]+/gi, " "));
		else errorMessage = "Error Processing Request. Try again.";
	} else errorMessage = "Error Processing Request. Try again.";

	return errorMessage || "Error Processing Request. Try again.";
};

export const getDateRange = (dateRange) => {
	switch (dateRange) {
		case "Today":
			return moment().startOf("day").format();
		case "This Week":
			return moment().startOf("week").format();
		case "This Month":
			return moment().startOf("month").format();
		case "This Year":
			return moment().startOf("year").format();
		default:
			return moment(new Date()).format();
	}
};

export const timeDateFormat = (obj, format) => {
	if (!obj) return "";

	const time = moment(obj).format("hh:mm:ss a");
	const date = moment(obj).format("DD-MM-YYYY");
	const otherDate = moment(obj).format(format);
	return { time, date, otherDate };
};

export const humanDateFormat = (date, variant = "long") => {
	if (!date || date === undefined) return "";
	let d = new Date(date);
	let day = d.getDate();
	let month = d.toLocaleString("en-us", { month: variant });
	let year = d.getFullYear();
	return day + " " + month + ", " + year;
};

export const timeRangeFormat = (obj, init) => {
	if (!obj) return null;

	const newDate = new Date(obj);
	init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
	return newDate;
};

export const titleCase = (string) => {
	if (string === null || string === undefined) {
		return "";
	} else {
		let text = string.toLowerCase().split("_");
		for (var i = 0; i < text.length; i++) {
			text[i] = text[i][0].toUpperCase() + text[i].slice(1);
		}
		return text.join(" ");
	}
};

export const sentenseCase = (str) => {
	str = str.toLowerCase().split(" ");
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(" ");
};

export const toCurrency = (n, code = "NGN") => {
	n = typeof n === "string" ? Number(n.replace(/[^0-9.]/g, "")) : n;

	if (n === 0 || n === null || n === undefined) return `${code} ${0}`;

	const getNumber = (value) => {
		return (
			`${code} ` +
			Number(value)
				.toFixed(2)
				.replace(/./g, function (c, i, a) {
					return i > 0 && c !== "." && (a.length - i) % 3 === 0
						? "," + c
						: c;
				})
		);
	};

	if (n.toString().startsWith("-")) {
		const newNumber = getNumber(Math.abs(n));
		return `-${newNumber}`;
	}

	return n ? getNumber(n) : "";
};

export const copyToClipboard = (value) => {
	const tempInput = document.createElement("input");
	tempInput.value = value;
	document.body.appendChild(tempInput);
	tempInput.select();
	tempInput.focus();
	document.execCommand("copy");
	document.body.removeChild(tempInput);
	return true;
};

export const tableOptions = (
	headings,
	perPage = 25,
	perPageValues = [],
	chunk = 3
) => ({
	columns: [],
	options: {
		perPage,
		pagination: {
			chunk,
			dropdown: false,
			edge: true,
		},
		headings,
		perPageValues,
		filterable: false,
		responseAdapter: (data) => {
			return { ...data, count: data?.total ?? data?.count };
		},
		setPerPage: (page) => {
			return page;
		},
	},
});

export const truncate = (str, len = 25) => {
	if (str.length > len) {
		return str.substring(0, len) + "...";
	} else {
		return str;
	}
};

export const getWalletApikey = (store) => {
	const keys = store.state.account.customerActiveKeys.keys;
	return keys.apiKey;
};

export const getWalletPublickey = (store) => {
	const keys = store.state.account.customerActiveKeys.keys;
	return keys.publicKey;
};

export const statusColor = (status) => {
	switch (status.toLowerCase()) {
		case "successful":
		case "sent":
		case "credit":
		case "approved":
		case "active":
		case "paid":
		case "posted":
		case true:
			return "text-success";

		case "failed":
		case "debit":
		case "declined":
		case "expired":
		case "outstanding":
		case false:
			return "text-danger";

		case "pending":
			return "text-primary";

		default:
			return "";
	}
};
export const getPhone = (phone, noSign, prefix = "234") => {
	if (phone)
		return phone.charAt(0) === "0"
			? noSign
				? `${prefix}${phone.slice(1)}`
				: `+${prefix}${phone.slice(1)}`
			: phone;
	return "";
};

export const slicePhone = (phone) => {
	if (phone && phone.length === 11)
		return phone.charAt(0) === "0" ? phone.slice(1) : phone;
	return phone;
};

export const downloadReport = async (tableHeadings, data) => {
	const toast = new VueIziToast();

	try {
		let fields = Object.entries(tableHeadings).map((val) => ({
			label: val[1],
			value: val[0],
		}));

		const json2csvParser = new Parser({ fields });
		const csv = json2csvParser.parse(data);
		let blob = new Blob([csv], {
				type: "text/csv",
			}),
			url = window.URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.setAttribute("hidden", true);
		a.setAttribute("href", url);
		a.setAttribute("download", uuidv4());
		document.body.appendChild(a);
		a.click();
		toast.success("Download Successful!", "OK", {
			position: "topRight",
			close: true,
			closeOnEscape: false,
			timeout: 3000,
			displayMode: 2,
		});
	} catch (e) {
		toast.error("Error Downloading Report", "Error", {
			position: "topRight",
			displayMode: 2,
		});
	}
};

export const getCSVFileObject = (data) => {
	try {
		const json2csvParser = new Parser({ quote: "" });
		const csv = json2csvParser.parse(data);
		let blob = new Blob([csv], {
			type: "text/csv",
		});
		const file = new File([blob], "account.csv", {
			lastModified: new Date(),
			type: "text/csv",
		});
		return file;
	} catch (e) {
		return "";
	}
};

export const pageEdges = (curPage, page, total, parpage = 10) => {
	const diff = page - curPage;
	if (diff >= 3 || curPage % 3 === 2) {
		let count = total / parpage;
		let rem = total % parpage;
		count = rem === 0 ? count : count + 1;
		return count;
	} else return page;
};

export const getRequestData = (store, filters, data, size = 1000000) => {
	const requestData = {
		apiKey: getWalletApikey(store),
		params: {
			size,
			limit: size,
			...data,
			...filters,
		},
	};
	return requestData;
};

export const getFilters = (filters) =>
	Object.fromEntries(
		Object.entries(filters).filter(([_, v]) => v != "" && v != null)
	);

export const chartSelectOptions = [
	"Today",
	"This Week",
	"Last Week",
	"This Month",
	"Last Month",
];

const tomorrow = () => {
	// Get today's date
	let today = new Date();
	// Change the date by adding 1 to it (today + 1 = tomorrow)
	today.setDate(today.getDate() + 1);
	// return yyyy-mm-dd format
	return today.toISOString().split("T")[0];
};

export const getDateRangeFromValue = (dateRange) => {
	const to = moment(new Date()).format().substring(0, 10);
	switch (dateRange) {
		case chartSelectOptions[0]:
			return {
				from: to,
				to: tomorrow(),
				format: "week",
				frequency: "day",
			};
		case chartSelectOptions[1]:
			return {
				from: moment().startOf("week").format().substring(0, 10),
				to,
				format: "week",
				frequency: "day",
			};
		case chartSelectOptions[2]:
			return {
				from: moment()
					.subtract(1, "weeks")
					.startOf("week")
					.format()
					.substring(0, 10),
				to: moment()
					.subtract(1, "weeks")
					.endOf("week")
					.format()
					.substring(0, 10),
				format: "week",
				frequency: "day",
			};
		case chartSelectOptions[3]:
			return {
				from: moment().startOf("month").format().substring(0, 10),
				to: moment().endOf("month").format().substring(0, 10),
				format: "month",
				frequency: "week",
			};
		case chartSelectOptions[4]:
			return {
				from: moment()
					.subtract(1, "months")
					.startOf("month")
					.format()
					.substring(0, 10),
				to: moment()
					.subtract(1, "months")
					.endOf("month")
					.format()
					.substring(0, 10),
				format: "month",
				frequency: "week",
			};
		default:
			return {};
	}
};
export const getRequestDataWithHeaders = ({
	store,
	filters = {},
	data = {},
	size = 100000,
	headers,
}) => {
	const requestData = {
		apiKey: getWalletApikey(store),
		params: {
			size,
			limit: size,
			...data,
			...filters,
		},
		headers,
	};
	return requestData;
};

export const formatNumber = (n, float) => {
	n = typeof n === "string" ? Number(n.replace(/[^0-9.]/g, "")) : n;

	if (n === 0 || n === null || n === undefined) return `${0}`;

	return n
		? Number(n)
				.toFixed(2)
				.replace(/./g, function (c, i, a) {
					return i > 0 && c !== "." && (a.length - i) % 3 === 0
						? "," + c
						: c;
				})
		: "";
};

const month = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

export const graphDateFormatter = (data) => {
	const date = new Date(data);
	return `${month[date.getMonth()]} ${date.getDay()}`;
};

export const fsStatFilter = [
	{
		name: "This Year",
		value: "thisYear",
	},
	{
		name: "Last Year",
		value: "lastYear",
	},
	{
		name: "This Month",
		value: "thisMonth",
	},
	{
		name: "Last Month",
		value: "lastMonth",
	},
	{
		name: "Weekly",
		value: "thisWeek",
	},
];

export const startTitleCase = (str) => {
	str = str.toLowerCase().split(" ");
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(" ");
};

export const truncateString = (str, num = 18) => {
	if (str.length > num) {
		return str.slice(0, num).trim() + "...";
	} else {
		return str;
	}
};

export const xEnv = process.env.VUE_APP_X_API_KEY;

const clearNumber = (value = "") => value.replace(/\D+/g, "");

export const formatCardNumber = (value) => {
	if (!value) return value;

	let cardInfo = cardValidator.number(value) || {};
	const clearValue = clearNumber(value);
	let nextValue;

	switch (cardInfo.card.type) {
		case "american-express":
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10
			)} ${clearValue.slice(10, 15)}`;
			break;

		case "diners-club":
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10
			)} ${clearValue.slice(10, 14)}`;
			break;

		default:
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				8
			)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
			break;
	}

	const next = cardInfo.isValid && cardInfo.isPotentiallyValid;

	if (!cardInfo.isValid && !cardInfo.isPotentiallyValid)
		cardInfo.error = "Invalid Card Number";
	else cardInfo.error = "";

	return { ...cardInfo, next, newValue: nextValue.trim() };
};

export const formatExpirationDate = (value) => {
	let clearValue = clearNumber(value);
	let dateObj = {};

	if (clearValue.length >= 3) {
		clearValue = `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
		const validDate = cardValidator.expirationDate(clearValue);
		const { isPotentiallyValid, isValid } = validDate || {};

		dateObj.next = isValid && isPotentiallyValid && clearValue.length === 7;

		if (!isPotentiallyValid && !isValid) {
			dateObj.error = "Invalid Date Supplied";
		} else dateObj.error = "";
	}

	return { ...dateObj, newValue: clearValue };
};

export const CARD_IMAGES = {
	visa: VISA,
	mastercard: MASTERCARD,
	"american-express": AMEX,
	"diners-club": DINNER,
	discover: DISCOVER,
	jcb: JCB,
	unionpay: UNION,
	maestro: MAESTRO,
	mir: MIR,
	elo: ELO,
	hiper: "",
	hipercard: HIPERCARD,
};

export const blockInvalidChar = (e) =>
	["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const getResponseStatus = (response) => {
	const { status, data } = response || {};

	const test =
		data.success === "success" ||
		data.status === "success" ||
		data.success === true ||
		data.status === true;

	console.log("test", test);

	return (
		(status === 200 || status === 201) &&
		(data.success || data.status) &&
		(data.success === "success" ||
			data.status === "success" ||
			data.success === true ||
			data.status === true)
	);
};

export const getPhoneNumber = (phone = {}) => {
	if (!phone || phone === undefined) return "";

	return `+${phone.countryCallingCode}${phone.nationalNumber}`;
};
