<template>
	<div>
		<img
			@click="openEditAccount"
			class="pointer mr-4"
			src="/assets/img/icons/edit.png"
			alt="update FT Account"
			width="25px"
		/>
		<UpdateFTAccountModal
			:id="`upd${this.account.index}`"
			:account="account"
			:accountFile="accountFile"
			:closeModal="closeModal"
			:updateAccountList="updateAccountList"
		/>
	</div>
</template>

<script>
	import UpdateFTAccountModal from "../../modals/UpdateFTAccountModal/UpdateFTAccountModal";

	export default {
		name: "UpdateFTAccountButton",
		props: ["account", "accountFile", "updateAccountList"],
		methods: {
			openEditAccount() {
				$(`#upd${this.account.index}`).modal("show");
			},
			closeModal() {
				$(`#upd${this.account.index}`).modal("hide");
			},
		},
		components: {
			UpdateFTAccountModal,
		},
	};
</script>
