<template>
	<div>
		<CustomBuiltSelect
			:btnName="'Item'"
			:placeholder="'Select Item'"
			:value="value"
			:error="error"
			:loading="loading"
			:selected="selected"
			:getLabel="getLabel"
			:required="required"
			:options="filteredItems"
			:handleChange="handleChange"
			:handleSelect="handleSelect"
			:createFooter="openCreateItem"
		/>

		<CreateItemsModal
			:id="'createItem'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";
import CreateItemsModal from "@/components/wallet/Invoices/modals/CreateItemsModal/CreateItemsModal";
import CustomBuiltSelect from "../CustomBuiltSelect/CustomBuiltSelect";

export default {
	name: "ItemSelect",
	data() {
		return {
			filteredItems: [],
			items: [],
			selected: null,
			value: "",
			loading: false,
		};
	},
	components: {
		CustomBuiltSelect,
		CreateItemsModal,
	},
	props: [
		"required",
		"error",
		"action",
		"item",
		"setRequest",
		"itemsList",
		"getItemOption",
	],
	methods: {
		async refetch(customer) {
			this.action(customer);
			this.itemsList ? this.getItemOption() : this.getItems();
		},
		handleChange({ target: { value } }) {
			if (value) {
				this.value = value;
				let data = [...this.items].filter((item) =>
					item.name.toLowerCase().includes(value.toLowerCase())
				);
				this.filteredItems = data;
			} else {
				this.value = "";
				this.filteredItems = this.items;
			}
		},
		getLabel(item) {
			return item.name ? item.name : "";
		},
		handleSelect(option) {
			this.selected = option;
			this.value = option ? this.getLabel(option) : "";
			this.setRequest(option);
		},
		openCreateItem() {
			$("#createItem").modal("show");
		},
		closeModal() {
			$("#createItem").modal("hide");
		},
		async getItems() {
			this.loading = true;
			try {
				const response = await this.$store.dispatch(
					"products/getProducts",
					{
						apiKey: getWalletApikey(this.$store),
						params: { limit: 100000000000000 },
					}
				);
				this.items = response.data.data.data;
				this.filteredItems = response.data.data.data;
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.items = [];
			}
		},
		setItem(v) {
			this.selected = v;
			this.value = this.getLabel(v);
		},
	},
	mounted() {
		this.setItem(this.item);
		if (this.itemsList && this.itemsList.length) {
			this.items = this.itemsList;
			this.filteredItems = this.itemsList;
		} else this.getItems();
	},
	watch: {
		item(option) {
			this.setItem(option);
		},
		itemsList(v) {
			if (v) {
				this.items = v;
				this.filteredItems = v;
			}
		},
	},
};
</script>
