<template>
	<form @submit.prevent="handleSubmit" class="row h-100">
		<div class="col-sm-6 col-12">
			<CustomInput
				:name="'name'"
				v-model="request.name"
				:label="'Name'"
				:placeholder="'Enter a name'"
				:required="true"
				:error="formErrors.name"
				:handleError="handleError"
			/>
		</div>

		<div class="col-sm-6">
			<CustomInput
				:name="'description'"
				v-model="request.description"
				:label="'Description'"
				:placeholder="'Enter description'"
				:required="true"
				:error="formErrors.description"
				:handleError="handleError"
			/>
		</div>

		<div class="col-12">
			<WalletSelect
				:name="'wallet_id'"
				:error="formErrors.wallet_id"
				:handleError="handleError"
				:label="'Select Wallet (Optional)'"
				v-model="request.wallet_id"
			/>
		</div>

		<div class="form-group col-12">
			<label class="d-flex align-items-center">
				<input
					:name="'expire'"
					type="checkbox"
					:value="false"
					v-model="expire"
				/>
				<span class="ml-2 fs-15" style="margin-top: 2px"
					>Expiry Date</span
				>
			</label>
		</div>

		<div class="col-sm-6" v-show="expire">
			<DatePicker
				:name="'expiry_date'"
				:label="'Expiry Date'"
				v-model="request.expiry_date"
				:placeholder="'Select Expiry Date'"
				:disabledDates="yesterDay"
				:required="true"
				:error="formErrors.expiry_date"
				:handleError="handleError"
			/>
		</div>

		<div class="col-sm-6 col-12">
			<CustomInput
				:name="'amount'"
				v-model="request.amount"
				:label="'Amount (Optional)'"
				:placeholder="'Enter an amount'"
				:isNum="true"
				:error="formErrors.amount"
				:handleError="handleError"
			/>
		</div>

		<div class="col-sm-6 col-12">
			<CustomInput
				:name="'reference'"
				v-model="request.reference"
				:label="'Customize Your Link (Optional)'"
				:placeholder="'Customize your link (e.g pay4me)'"
				:error="formErrors.reference"
				:handleError="handleError"
				:isRef="true"
				:avoidPaste="true"
				:otherCls="'mb-0'"
			/>
		</div>

		<div class="col-sm-6">
			<CustomSelect
				:name="'sub_account'"
				label="Sub Account (Optional)"
				optionLabel="name"
				placeholder="Select Sub Account"
				:options="subAccounts"
				v-model="request.sub_account"
				:error="formErrors.sub_account"
				:handleError="handleError"
				:otherCls="'mb-0'"
			/>
		</div>

		<div v-if="request.reference" class="form-group col-12 text--primary">
			https://payment-link-url/{{ request.reference }}
		</div>
		<div class="col-12 row">
			<div class="form-group col-6 mt-3">
				<label class="d-flex align-items-center">
					<input
						:name="'send_sms'"
						type="checkbox"
						:value="false"
						v-model="request.send_sms"
					/>
					<span class="ml-2 fs-15" style="margin-top: 2px"
						>Send to SMS</span
					>
				</label>
			</div>

			<div class="form-group col-6 mt-3">
				<label class="d-flex align-items-center ml-3">
					<input
						:name="'send_sms'"
						type="checkbox"
						:value="false"
						v-model="request.send_email"
					/>
					<span class="ml-2 fs-15" style="margin-top: 2px"
						>Send to Mail</span
					>
				</label>
			</div>
		</div>

		<div class="col-sm-6 col-12">
			<CustomPhoneInput
				v-show="request.send_sms"
				label="Phone Number"
				v-model="request.customer_phone"
				@blur="$v.request.customer_phone.$touch()"
				placeholder="Enter Phone Number"
				:error="
					$v.request.customer_phone.$error &&
					formVuelidator($v.request.customer_phone)
				"
			/>
		</div>

		<div class="col-sm-6 col-12">
			<CustomInput
				v-show="request.send_email"
				:type="'email'"
				:name="'customer_email'"
				v-model="request.customer_email"
				:label="'Customer Email'"
				:placeholder="'Customer Email'"
				:error="formErrors.customer_email"
				:handleError="handleError"
				:required="true"
			/>
		</div>

		<div class="col-12">
			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</div>
	</form>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import Spinner from "@/utils/Spinner/Spinner";
import FormButtons from "@/utils/FormButtons/FormButtons";
import WalletSelect from "@/utils/Selects/WalletSelect/WalletSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import { getError, getWalletApikey, getPhoneNumber } from "@/utils/helpers";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import { requiredIf } from "vuelidate/lib/validators";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

const initialState = () => ({
	request: {
		wallet_id: "",
		name: "",
		description: "",
		reference: "",
		amount: "",
		customer_phone: "",
		customer_email: "",
		expiry_date: "",
		sub_account: {},
		send_sms: false,
		send_email: false,
	},
	expire: false,
	formErrors: {},
});

export default {
	mixins: [notifications],
	name: "PaymentLinkForm",
	data() {
		return {
			...initialState(),
			loading: false,
			subAccounts: [],
		};
	},
	validations: {
		request: {
			customer_phone: {
				required: requiredIf(function () {
					return this.send_sms == true;
				}),
				isPhoneValid: isPhoneValid("request.customer_phone"),
			},
		},
	},
	props: ["onSuccess", "closeModal", "clear"],
	components: {
		CustomInput,
		CustomSelect,
		Spinner,
		FormButtons,
		WalletSelect,
		DatePicker,
		CustomPhoneInput,
	},
	methods: {
		getPhoneNumber,
		formVuelidator,
		handleSuccess(link) {
			Object.assign(this.$data, { ...initialState() });
			this.onSuccess(link);
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleSubmit() {
			this.$v.$touch();

			const payload = { ...this.request };
			!payload.amount && delete payload.amount;
			!payload.reference && delete payload.reference;
			if (payload.wallet_id)
				payload.wallet_id = payload.wallet_id.reference;
			else delete payload.wallet_id;
			if (payload.sub_account.reference)
				payload.sub_account = payload.sub_account.reference;
			else delete payload.sub_account;
			if (this.request.send_sms)
				payload.customer_phone = getPhoneNumber(payload.customer_phone);
			else delete payload.customer_phone;
			!this.request.send_email && delete payload.customer_email;
			!this.expire && delete payload.expiry_date;
			const { formErrors, isValid } = formValidator(payload);

			if (isValid) {
				if (
					this.request.send_sms &&
					!this.$v.request.customer_phone.isPhoneValid
				)
					return;

				this.createPaymentLink(payload);
			} else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		async createPaymentLink(payload) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"payment/createPaymentLink",
					{ apiKey: getWalletApikey(this.$store), request: payload }
				);

				if (response.status === 201) {
					this.loading = false;
					this.handleSuccess(response.data.data.link);
				}
			} catch (error) {
				this.loading = false;
				console.log("error", error);
				this.showErrorLoginNotification(getError(error));
			}
		},
		async getMerchantSubAccounts() {
			try {
				const subAccounts = await this.$store.dispatch(
					"payment/getMerchantSubAccounts",
					{ apiKey: getWalletApikey(this.$store) }
				);
				this.subAccounts = subAccounts.data.data.data;
			} catch (error) {
				this.subAccounts = [];
			}
		},
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
		yesterDay() {
			return {
				to: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
			};
		},
	},
	mounted() {
		this.getMerchantSubAccounts();
		Object.assign(this.$data, { ...initialState() });
	},
};
</script>
