<template>
	<EmptyWallet v-if="!merchant" />

	<div v-else>
		<div class="p-md-4 p-3">
			<div class="SectionItem no-b-b mb-3">
				<p class="title">Split Payments</p>
			</div>

			<div class="content__slider">
				<TabsV2
					:tabNames="tabNames"
					:tabId="'collectionTab'"
					:tabLink="true"
				/>
			</div>

			<transition mode="out-in" name="slide">
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import TabsV2 from "../../utils/Tabs/TabsV2";
import EmptyWallet from "../wallet/common/EmptyWallet/EmptyWallet.vue";

const tabNames = [
	{
		tabName: "Split Payment",
		value: "split_payment",
		link: "/wallet/split-payments",
	},
	{
		tabName: "Sub Account",
		value: "sub_account",
		link: "/wallet/split-payments/sub-accounts",
	},
];

export default {
	name: "SplitGroup",
	data() {
		return {
			tabNames,
		};
	},
	components: {
		TabsV2,
		EmptyWallet,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
