<template>
	<div class="invoice__analysis">
		<div class="w-100 margin-top">
			<section>
				<span>Sub Total</span>
				<p>
					{{ payload.currency.code }}
					{{ toCurrency(total.totalItems, "") }}
				</p>
			</section>
			<section class="section">
				<span>Tax</span>
				<p>
					{{ payload.currency.code }}
					{{ toCurrency(total.taxValue || 0, "") }}
				</p>
			</section>
			<section class="section">
				<span>Coupon</span>
				<p>
					{{ payload.currency.code }}
					{{ toCurrency(total.addedCoupon || 0, "") }}
				</p>
			</section>
			<div class="tax-float-container py-2">
				<div v-if="show" class="tax-float">
					<div class="tax-float-header flex__between">
						<h5 class="m-0">Apply Tax or Coupon</h5>
						<img
							src="/assets/img/close.png"
							alt=""
							width="15px"
							class="pointer"
							@click="toggle"
						/>
					</div>

					<div class="px-3">
						<TaxSelect
							:label="'Tax'"
							:tax="tax"
							:action="(tax) => setRequest('tax', tax)"
							:setRequest="(tax) => setRequest('tax', tax)"
						/>

						<CouponSelect
							:label="'Coupon'"
							:coupon="couponCode"
							:action="
								(coupon) => setRequest('couponCode', coupon)
							"
							:setRequest="
								(coupon) => setRequest('couponCode', coupon)
							"
						/>
						<div class="flex__between">
							<span
								class="btn font-weight-bold font-italic"
								@click="resetTaxCoupon"
								>Clear</span
							>
							<span class="btn font-weight-bold" @click="toggle"
								>Ok</span
							>
						</div>
					</div>
				</div>
				<span
					v-if="!hideForm"
					class="pointer font-italic font-weight-bold text--primary"
					@click="toggle"
					>Apply Tax or Coupon</span
				>
			</div>

			<section class="section invoice__total">
				<span>Invoice Total</span>
				<h5 class="m-0 font-weight-bold">
					{{ payload.currency.code }}
					{{
						toCurrency(total.allTotal > 0 ? total.allTotal : 0, " ")
					}}
				</h5>
			</section>
		</div>
	</div>
</template>

<script>
import { filters } from "../../../../../../public/assets/mixins/filters";
import TaxSelect from "@/utils/Selects/TaxSelect/TaxSelect";
import CouponSelect from "@/utils/Selects/CouponSelect/CouponSelect";
import { toCurrency } from "@/utils/helpers";

export default {
	mixins: [filters],
	name: "InvoiceTotalAnalysis",
	props: ["items", "payload", "hideForm", "updateRequest"],
	data() {
		return {
			couponCode: "",
			tax: "",
			show: false,
		};
	},
	components: {
		TaxSelect,
		CouponSelect,
	},
	methods: {
		toCurrency,
		toggle() {
			this.show = !this.show;
		},
		setRequest(name, value) {
			this[name] = value;
			this.updateRequest(name, value);
		},
		resetTaxCoupon() {
			this.setRequest("tax", "");
			this.setRequest("couponCode", "");
		},
	},
	computed: {
		total() {
			const totalItems = this.items.reduce(
				(acc, arr) =>
					acc +
					parseInt(arr.quantity) * parseFloat(arr.name.amount || 0),
				0
			);

			const newTax = this.tax || this.payload.tax || {};
			const newCoupon = this.couponCode || this.payload.couponCode || {};

			const { type, rate } = newTax;
			const { discountType, discountValue } = newCoupon;

			const taxValue = parseFloat((rate * totalItems) / 100);
			const addedTax = type === "excluded" ? taxValue : 0;

			const itemWithTax = totalItems + addedTax;

			const couponValue = newCoupon
				? parseFloat((discountValue * totalItems) / 100)
				: 0;
			const addedCoupon =
				discountType === "percentage" ? couponValue : discountValue;

			const allTotal = itemWithTax - (addedCoupon || 0);
			return { totalItems, allTotal, taxValue, addedCoupon };
		},
	},
	async mounted() {
		this.couponCode = this.payload.couponCode;
		this.tax = this.payload.tax;
	},
};
</script>
