<template>
	<div
		class="tab-pane fade"
		id="Invoices"
		role="tabpanel"
		aria-labelledby="Invoices-tab"
	>
		<TransactionsTable
			:title="'Recent'"
			:params="params"
			:source="'invoices'"
		/>
	</div>
</template>

<script>
import TransactionsTable from "../../../../common/TransactionsTable/TransactionsTable";

export default {
	name: "CustomerSubscriptions",
	components: { TransactionsTable },
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		params() {
			return { customer: this.$route.params.reference };
		},
	},
};
</script>
