<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div v-else class="p-md-4 p-3">
			<div class="SectionItem no-b-b mb-3">
				<p class="title">Payment Collections</p>
			</div>

			<div class="content__slider">
				<TabsV2
					:tabNames="tabNames"
					:tabId="'collectionTab'"
					:tabLink="true"
				/>
			</div>

			<transition mode="out-in" name="slide">
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import TabsV2 from "../../../utils/Tabs/TabsV2";

const tabNames = [
	{ tabName: "Collections", value: "collections", link: "/wallet/payment" },
	{ tabName: "Payouts", value: "payouts", link: "/wallet/payment/payouts" },
	{
		tabName: "Disputes",
		value: "disputes",
		link: "/wallet/payment/disputes",
	},
  {
		tabName: "Chargeback",
		value: "chargeback",
		link: "/wallet/payment/chargebacks",
	},
	{ tabName: "Refunds", value: "refunds", link: "/wallet/payment/refunds" },
];

export default {
	name: "Payment",
	data() {
		return {
			tabNames,
		};
	},
	components: {
		TabsV2,
		EmptyWallet,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
