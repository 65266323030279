<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">Bulk pNIN Upload Request form</h2>
                <h5 class="user-profile-email sub">
                  Verify NIN By Phone Numbers of various users in Bulk.
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>

          <div class="contact-details--card">
            <form @submit.prevent="verifyNINBulk">
              <div class="row mt-4">
                <div class="col-md-7">
                  <div class="form-group">
                     <CustomUpload
                      :name="'logo'"
                      :label="'pNIN File'"
                      :required="true"
                      :validFileTypes="['.csv']"
                      :handleChange="handleUpload"
                      :fileName="mediaData.name"
                      :error="uploadError && 'File size must be less than 5MB'"
                    />
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="form-group">
                    <label class="input-item-label">
                      Use the template below </label
                    ><br />
                    <a href="../../assets/bulk_pnin_test.csv" download
                      >Download this template</a
                    >
                  </div>
                </div>

                <!-- Application details -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                  <br />
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <p @click="showModall" class="btn btn-primary">Demo</p> -->
                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Validating request</span>
                    <span v-else>Verify pNINs</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>pNIN Bulk Upload Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from bulk nin request made<br
              /></span>
              <span class="text-span"
                ><b>Note:</b> Only 5 items are displayed, check your mail for
                the complete Response</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <!-- <div v-for="value in computedObj">{{value}}</div> -->
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}IdentityVerification/NIN-Bulk`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "nin_upload": "{{"nin_upload"}}",
		"verification_format": '{{ nin_upload === "NIN Number" ? "nin" : 'phone_number'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
    
{
"message": "Your file is Successfully Uploaded"
}
    `}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>

    <!-- Modal -->
    <div
      class="modal fade"
      id="sucessModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sucessModaltitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="sucessModalLongTitle">
              <i class="la la-exclamation-circle"></i> Success
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Your Bulk NIN request has been recieved and is being processed. A
              comprehensive report will sent to your mail shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    app: { required },
    // apiVersion: { required },
  },
  components: {
    CustomUpload
  },
  data() {
    return {
      initializedAppResult: {},
      app: "",
      versions: ["v1", "v2"],
      apiVersion: "",
      call_id: "",
      clientID: "",
      apikey: "",
      uploadError: "",
      hasNewResponse: false,
      dob: "",
      logData: [],
      apiResponse: "",
      mediaData: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      // services
      hasService: false,
      servicePrice: "",
      isSubmitting: false,
      verificationResult: [],
    };
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    showModall() {
      $("#sucessModal").modal();
    },
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (
            service.service_label.includes(
              "Bulk (pNIN-Basic) NIN Verification with Msisdn"
            )
          ) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    async handleUpload({ target: { files } }) {
      this.mediaData = null;

			const file = files[0];
			if (file){
				const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.mediaData = file;
          this.uploadError = false;
        } else {
          this.uploadError = true;
        }
      } 
		},
    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let ninData = new FormData();
      ninData.append("pnin_upload", this.mediaData);

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        query: this.call_id,
        payload: {
          currency_type: this.currencyType.currency,
        },
      };
      return { verificationData, ninData };
    },
    async verifyNINBulk() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.uploadError) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;
      try {
        this.apiResponse = await this.$store.dispatch(
          "services/verifypNINBasicBulk",
          verificationData
        );

        this.showSuccessNotification(this.apiResponse.message);
        this.verificationResult = this.apiResponse;
        this.isSubmitting = false;
        $("#sucessModal").modal();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
