<template>
	<form @submit.prevent="handleSubmit">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-2 col-xl-2">
				<CustomInput
					:name="'invoice_reference'"
					v-model="filters.invoice_reference"
					:placeholder="'Invoice Reference'"
				/>
			</div>
			<div v-if="isInvoice" class="col-sm-6 px-sm-2 col-md-4  col-xl-2">
				<CustomSelect
					name="status"
					:optionLabel="'name'"
					placeholder="Invoice Status"
					:options="invoiceStatus"
					v-model="filters.status"
				/>
			</div>
			<div class="col-sm-6 px-sm-2 col-md-4  col-xl-2">
				<CustomSelect
					name="invoice_type"
					:optionLabel="'name'"
					placeholder="Invoice type"
					:options="invoiceType"
					v-model="filters.invoice_type"
				/>
			</div>
			<div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
				<DatePicker
					:name="'from'"
					v-model="filters.from"
					:placeholder="'Start Date'"
					:disabledDates="{ from: new Date() }"
				/>
			</div>

			<div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
				<DatePicker
					:name="'to'"
					v-model="filters.to"
					:disabledDates="{ from: new Date(), to: filters.from }"
					:placeholder="'End Date'"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>

				<div class="d-inline-block">
					<DownloadReport
						:downloadReport="download"
						:loading="rLoading"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import { filters } from "../../../../../../public/assets/mixins/filters";
	import { timeRangeFormat } from "@/utils/helpers";
	import { invoiceStatus, invoiceType } from "../../enums";

	const initialState = () => ({
		filters: {
			status: "",
			invoice_type: "",
			invoice_reference: "",
			from: null,
			to: null,
		},
		isFilter: false,
		qLoading: false,
		invoiceStatus,
		invoiceType
	});

	export default {
		mixins: [filters],
		name: "InvoiceFilters",
		data() {
			return { ...initialState() };
		},
		props: [
			"loading",
			"rLoading",
			"clearFilter",
			"submitFilter",
			"download",
			"isInvoice"
		],
		computed: {
			disabled() {
				return !Object.values(this.filters).some((val) => val);
			},
		},
		methods: {
			resetFilters() {
				Object.assign(this.$data, { ...initialState() });
				this.clearFilter();
				this.isFilter = false;
			},
			handleSubmit() {
				this.isFilter = true;
				const filters = { ...this.filters }
				if (filters.from)
					filters.from = timeRangeFormat(filters.from,true);
				if (filters.to)
					filters.to = timeRangeFormat(filters.to);
				this.submitFilter({
					...filters, 
					status: filters.status ? filters.status.value : null,
					invoice_type: filters.invoice_type ? filters.invoice_type.value : null
				});
			},
		},
		watch: {
			"filters.from": function() {
				this.filters.to = null;
			},
		},
	};
</script>
