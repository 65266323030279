<template>
	<div class="card">
		<div class="row">
			<div v-show="title" class="col-12 pt-3 px-4">
				<h5 class="font-weight-bold">{{ title }} ({{ len }})</h5>
			</div>
			<div class="col-12 mt-3">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect">
							<PerPageSelect
								:handleChange="refetch"
								:limit="limit"
							/>
						</template>
						<template slot="filterForm">
							<TransactionsTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadTransactions"
							/>
						</template>
					</FilterWrapper>
				</div>
			</div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>

			<div class="col-12" :class="tbClass">
				<v-server-table
					ref="transRef"
					class="v-table row-pointer"
					:columns="columns"
					:options="options"
					@row-click="showData"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
				>
					<template
						slot="metadata.account_number"
						slot-scope="{ row }"
					>
						{{
							row.metadata && row.metadata.account_number
								? row.metadata.account_number
								: "-------------------"
						}}
					</template>
					<template slot="amount" slot-scope="{ row }">
						{{ row.currency }}{{ toCurrency(row.amount, " ") }}
					</template>
					<template slot="source" slot-scope="{ row }">
						{{
							titleCase(
								row.source === "merchant_transfer"
									? "Bank_transfer"
									: row.source
							)
						}}
					</template>
					<template slot="type" slot-scope="{ row }">
						{{ titleCase(row.type) }}
					</template>
					<template
						slot="metadata.source_account.account_name"
						slot-scope="{ row }"
					>
						{{
							row.metadata && row.metadata.source_account
								? row.metadata.source_account.account_name
								: "---------"
						}}
					</template>
					<template slot="action" slot-scope="{ row }">
						<span :class="statusColor(row.action)">
							{{ titleCase(row.action) }}
						</span>
					</template>
					<template slot="status" slot-scope="{ row }">
						<span :class="statusColor(row.status)">
							{{ titleCase(row.status) }}
						</span>
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						<span>
							{{
								timeDateFormat(row.createdAt).date +
								" " +
								timeDateFormat(row.createdAt).time
							}}
						</span>
					</template>
				</v-server-table>
			</div>
		</div>

		<TransactionsTableModal
			v-if="showModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import FilterWrapper from "../../../../utils/FilterWrapper/FilterWrapper";
import PerPageSelect from "../../../../utils/Selects/PerPageSelect/PerPageSelect";
import TransactionsTableFilters from "./TransactionsTableFilters/TransactionsTableFilters";
import TransactionsTableModal from "./TransactionsTableModal";
import ViewMore from "../../../../utils/ViewMore/ViewMore";
import FailedState from "../../../../utils/ResponseState/FailedState";
import Spinner from "../../../../utils/Spinner/Spinner";
import {
	timeDateFormat,
	titleCase,
	tableOptions,
	getWalletApikey,
	statusColor,
	downloadReport,
	toCurrency,
} from "../../../../utils/helpers";

const tableHeadings = {
	reference: "Transaction Reference",
	amount: "Amount",
	source: "Payment Method",
	action: "Type",
	status: "Status",
	createdAt: "Date",
};

const fundTransferTableHeadings = {
	reference: "Transaction Reference",
	amount: "Amount",
	source: "Payment Method",
	"metadata.destination.recipient_name": "Recipient Account Name",
	"metadata.destination.recipient": "Recipient Account Number",
	action: "Type",
	status: "Status",
	createdAt: "Date",
};

export default {
	mixins: [notifications],
	name: "TransactionsTable",
	data() {
		return {
			loading: false,
			rLoading: false,
			showModal: false,
			modalData: null,
			errorMsg: "",
			query: {},
			dateParams: {},
			limit: 25,
			ref: "",
			len: 0,
			...tableOptions(
				this.fullHeadings
					? this.fullHeadings
					: { ...tableHeadings, ...this.headings }
				// this.perPage
			),
		};
	},
	props: {
		title: String,
		source: String,
		isFundTransfer: Boolean,
		params: {
			type: Object,
			default: function () {
				return {};
			},
		},
		getTotals: Function,
		fullHeadings: Object,
		headings: {
			type: Object,
			default: function () {
				return {};
			},
		},
		perPage: {
			type: Number,
			default: 25,
		},
	},
	components: {
		ViewMore,
		Spinner,
		FailedState,
		FilterWrapper,
		PerPageSelect,
		TransactionsTableFilters,
		TransactionsTableModal,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		showData({ row }) {
			this.modalData = row;
			this.showModal = true;
		},
		closeModal(e) {
			this.showModal = false;
			this.modalData = null;
		},
		titleCase,
		timeDateFormat,
		statusColor,
		toCurrency,
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch({ dateParams, query, limit }) {
			const newQuery = Object.fromEntries(
				Object.entries((query = {})).filter(([_, v]) => v != "")
			);

			if (dateParams) {
				this.dateParams = { ...dateParams };
			}

			if (query.currency) {
				this.query = { ...newQuery, currency: query.currency.code };
			}

			if (limit) {
				this.$refs.transRef.setLimit(limit);
				this.limit = limit;
			}

			if (query.reference) {
				this.ref = query.reference;
			}
			this.getTransactions();
			this.$refs.transRef.refresh();
		},
		clearFilter() {
			this.query = {};
			this.dateParams = {};
			this.ref = "";
			this.$refs.transRef.refresh();
		},
		getRequestData(data, limit = 9999999999) {
			const params = {
				...data,
				...this.params,
				...this.dateParams,
				limit,
				query: { ...this.query },
			};
			this.query.currency && (params.currency = this.query.currency);
			this.ref && (params.reference = this.ref);
			this.source && (params.type = this.source);
			const requestData = {
				apiKey: getWalletApikey(this.$store),
				params,
			};
			return requestData;
		},
		async getTransactions() {
			this.options.requestFunction = async (data) => {
				const requestData = this.getRequestData(data, this.limit);

				return await this.$store
					.dispatch("transactions/getAllTransactions", requestData)
					.then((response) => {
						const allData = response.data.data;
						this.len = allData.total;
						if (this.getTotals) {
							const total = allData.data.reduce(
								(acc, arr) => acc + arr.amount,
								0
							);
							this.getTotals({ total, count: allData.count });
						}
						return { ...allData };
					});
			};
		},
		async downloadTransactions() {
			this.rLoading = true;
			const requestData = this.getRequestData({});

			const response = await this.$store.dispatch(
				"transactions/getAllTransactions",
				requestData
			);
			const { status, data } = response || {};
			if (status === 200 && data.status) {
				downloadReport(
					this.isFundTransfer
						? fundTransferTableHeadings
						: this.fullHeadings
						? this.fullHeadings
						: tableHeadings,
					data.data.data
				);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
	},
	created() {
		this.getTransactions();
	},
	mounted() {
		const newHeading = this.fullHeadings
			? this.fullHeadings
			: { ...tableHeadings, ...this.headings };
		this.columns = Object.keys(newHeading).map((keyVal) => keyVal);
	},
};
</script>
