<template>
  <div class="contact-details--card">
    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Phone Number</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            {{ user.phone_number || "Not Available" }}
          </div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Last Update</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            {{ user.api_customer.created_at | moment("MM-DD-YYYY h:mm a") }}
          </div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Status</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            <span
              v-if="user.api_customer.is_verified"
              class="badge badge-success"
              >Verfied</span
            >
            <span v-else class="badge badge-danger">Not Verfied</span>
          </div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            {{ user.firstname }} {{ user.lastname }}
          </div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">First Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ user.firstname }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Last Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ user.lastname }}</div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Email</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ user.email }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Company/Organization</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            {{ user.api_customer.organization_name }}
          </div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Address</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            {{ user.api_customer.address }}
          </div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Country</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ user.country.name }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">State</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            {{ user.state.state_label }}
          </div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">City</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ user.city }}</div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
};
</script>

<style></style>
