<template>
	<div
		class="card p-4 tab-pane fade"
		id="payment_methods"
		aria-labelledby="payment_methods-tab"
	>
		<div class="flex__between align-items-center pt-3 mb-3">
			<h4 class="mb-0">Payments</h4>
			<button
				v-if="data.length > 0"
				@click="updatePaymentMethods"
				type="button"
				class="btn btn-sm btn--primary px-4"
				:disabled="saving"
			>
				<Spinner v-if="saving" color="#fff" />
				<span v-else> Save Changes </span>
			</button>
		</div>
		<ContentWrapper
			:loading="loading"
			:errorMsg="errorMsg"
			:refetch="getPaymentMethods"
		>
			<template slot="content">
				<div class="row">
					<div class="col-12 col-md-7">
						<div class="align-items-start flex__between w-100 mb-3">
							<div class="w-50">
								<label class="input-item-label"
									>Accept payments via</label
								>
							</div>
							<div class="w-50">
								<label
									v-for="method of uncategorized_methods"
									:key="method.id"
									class="ckbox w-100 pointer mb-4"
								>
									<input
										type="checkbox"
										v-model="method.status"
									/>
									<span style="font-size: 15px">{{
										method.name
									}}</span>
								</label>
								<div class="mb-3">
									<div
										class="app-key-wrap accordion accordion-s2 mb-2"
									>
										<div
											class="app-key-item-wrapper accordion-item"
										>
											<div
												class="app-key-list-item accordion-heading"
											>
												<div class="service-list">
													<div
														class="service-name"
														data-toggle="collapse"
														:data-target="`#local_cards`"
													>
														<div
															class="service-icon"
														>
															<em
																class="app-icon la la-cube"
															></em>
														</div>
														<span>
															Local Cards
														</span>
													</div>
													<em
														class="mr-3 right la la-caret-down"
													></em>
												</div>
											</div>
											<div
												:id="`local_cards`"
												class="mt-2 p-l collapse"
												style
											>
												<label
													v-for="method of local_cards"
													:key="method.id"
													class="ckbox w-100 mb-4 pointer"
												>
													<input
														type="checkbox"
														v-model="method.status"
													/>
													<span
														style="font-size: 15px"
														>{{ method.name }}</span
													>
												</label>
											</div>
										</div>
									</div>
									<div
										class="app-key-wrap accordion accordion-s2"
									>
										<div
											class="app-key-item-wrapper accordion-item"
										>
											<div
												class="app-key-list-item accordion-heading"
											>
												<div class="service-list">
													<div
														class="service-name"
														data-toggle="collapse"
														:data-target="`#intl_cards`"
													>
														<div
															class="service-icon"
														>
															<em
																class="app-icon la la-cube"
															></em>
														</div>
														<span>
															International Cards
														</span>
													</div>
													<em
														class="mr-3 right la la-caret-down"
													></em>
												</div>
											</div>
											<div
												:id="`intl_cards`"
												class="mt-2 p-l collapse"
												style
											>
												<label
													v-for="method of international_cards"
													:key="method.id"
													class="ckbox w-100 mb-4 pointer"
												>
													<input
														type="checkbox"
														v-model="method.status"
													/>
													<span
														style="font-size: 15px"
														>{{ method.name }}</span
													>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</ContentWrapper>
	</div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";
import DevOnly from "@/utils/AccessControl/DevOnly";
import { componentData } from "../../../../../public/assets/mixins";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";

export default {
	name: "Payments",
	mixins: [componentData],
	data() {
		return {
			data: [],
			loading: false,
			saving: false,
			errorMsg: "",
		};
	},
	components: {
		CurrencySelect,
		Spinner,
		DevOnly,
	},
	computed: {
		uncategorized_methods() {
			return this.data.filter((method) => method.category == null);
		},
		international_cards() {
			return this.data.filter(
				(method) =>
					method.category == "card" &&
					method.sub_category == "international"
			);
		},
		local_cards() {
			return this.data.filter(
				(method) =>
					method.category == "card" && method.sub_category == "local"
			);
		},
	},
	methods: {
		async getPaymentMethods() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/getPaymentMethods",
					{
						apiKey: this.getWalletApikey(this.$store),
					}
				);

				if (response.status) {
					this.data = response.data.data.map((method) => ({
						...method,
						status: method.status == "enabled" ? true : false,
					}));
				} else this.errorMsg = response.message;

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.errorMsg = this.getError(error);
			}
		},
		async updatePaymentMethods() {
			const request = {
				paymentMethods: this.data.map((method) => ({
					id: method.id,
					status: method.status ? "enabled" : "disabled",
				})),
			};
			try {
				this.saving = true;
				const response = await this.$store.dispatch(
					"wallets/updatePaymentMethods",
					{
						headers: {
							"x-api-key": this.getWalletApikey(this.$store),
						},
						payload: request,
					}
				);

				if (response.status) {
					this.showSuccessNotification(
						this.titleCase(response.message)
					);
				} else this.showErrorLoginNotification(response.message);

				this.saving = false;
			} catch (error) {
				this.saving = false;
				this.showErrorLoginNotification(this.getError(error));
			}
		},
	},

	created() {
		this.getPaymentMethods();
	},
};
</script>

<style scoped>
.p-l {
	padding-left: 20px;
}
</style>
