<template>
	<div class="flex__between pt-4">
		<button
			type="button"
			class="btn btn-outline w-100 mr-3"
			:disabled="loading"
			@click="handleCloseModal"
		>
			<span>{{ closeBtnName }} </span>
		</button>
		<button
			type="submit"
			class="btn btn-lg w-100 ml-3"
			:class="btnClass"
			:disabled="loading"
		>
			<Spinner v-if="loading" :color="'#fff'" />
			<span v-else>{{ this.btnName }}</span>
		</button>
	</div>
</template>

<script>
import Spinner from "../Spinner/Spinner";

export default {
	name: "FormButtons",
	props: {
		btnName: { default: "Submit" },
		closeBtnName: {
			type: String,
			default: "Close",
		},
		loading: { type: Boolean, default: false },
		primary: { type: Boolean, default: true },
		handleCloseModal: { type: Function },
	},
	components: { Spinner },
	computed: {
		btnClass() {
			return this.primary ? "btn-primary" : "btn-danger";
		},
	},
};
</script>
