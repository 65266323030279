<template>
  <div>
    <CustomBuiltSelect
      :btnName="'Receiver'"
      :placeholder="'Select Receiver'"
      :value="value"
      :loading="loading"
      :selected="selected"
      :getLabel="getLabel"
      :options="filteredItems"
      :handleChange="handleChange"
      :handleSelect="handleSelect"
      :createFooter="openCreateItem"
    />

    <AddBeneficiaryModal
      :id="'createSender'"
      :closeModal="closeModal"
      :refetch="refetch"
    />
  </div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";
import AddBeneficiaryModal from "../../../components/wallet/Remittance/modal/AddBeneficiaryModal/AddBeneficiaryModal";
import CustomBuiltSelect from "../CustomBuiltSelect/CustomBuiltSelect";

export default {
  name: "ReceiverSelect",
  data() {
    return {
      tempSenderData: {},
      filteredItems: [],
      items: [],
      selected: null,
      value: "",
      loading: false,
    };
  },
  components: {
    CustomBuiltSelect,
    AddBeneficiaryModal,
  },
  methods: {
    async refetch(customer) {
      //   this.action(customer);
      //   this.getItems();
    },
    handleChange({ target: { value } }) {
      if (value) {
				this.value = value;
				let data = [...this.items].filter((item) =>
					this.getLabel(item).toLowerCase().includes(value.toLowerCase())
				);
				this.filteredItems = data;
			} else {
				this.value = "";
				this.filteredItems = this.items;
			}
    },
    getLabel(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    handleSelect(option) {
      this.selected = option;
      this.$emit("populateForm", option);
    },
    openCreateItem() {
      $("#createSender").modal("show");
    },
    closeModal() {
      this.getBeneficiaries();
      $("#createSender").modal("hide");
    },
    async getBeneficiaries() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          size: 1000,
        },
      };

      try {
        const resp = (
          await this.$store.dispatch("remittance/getBeneficiaries", payload)
        ).data.data;

        this.items = resp.data;
        this.filteredItems = resp.data;
      } catch (error) {
        console.log("transctionError", error);
      }
    },
  },

  created() {
    this.getBeneficiaries();
  },
  watch: {},
};
</script>
