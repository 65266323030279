export const subAccountCsvRows = {
	reference: "Payment Reference",
	account_name: "Account Name",
	account_number: "Account Number",
	bank_code: "Bank Code",
	currency: "Currency",
	createdAt: "Date Created"
};

export const tableHeadings = {
	account_name: "Account Name",
	account_number: "Account Number",
	reference: "Reference",
	currency: "Currency",
	createdAt: "Date Created",
};
