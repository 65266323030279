<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div v-else class="p-md-4 p-3">
			<div class="SectionItem no-b-b mb-3">
				<p class="title">Transactions</p>
			</div>

			<div class="content__slider">
				<Tabs
					:tabNames="tabNames"
					:tabId="'transactTab'"
					:tabLink="true"
				/>
			</div>

			<transition mode="out-in" name="slide">
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import Tabs from "../../../utils/Tabs/Tabs";

const tabNames = [
	{ tabName: "Wallet", link: "/wallets/transactions" },
	{ tabName: "Collections", link: "/wallets/transactions/collections" },
	{
		tabName: "Reserved_account",
		link: "/wallets/transactions/reserved-account",
	},
	{ tabName: "Transfers", link: "/wallets/transactions/transfers" },
	{ tabName: "Remittance", link: "/wallets/transactions/remittance" },
	{
		tabName: "Subscriptions",
		link: "/wallets/transactions/subscriptions",
	},
	{
		tabName: "Invoices",
		link: "/wallets/transactions/invoices",
	},
];

export default {
	name: "Transactions",
	data() {
		return {
			tabNames,
		};
	},
	components: {
		EmptyWallet,
		Tabs,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
