import logs from "@/services/logs";
import { apiClient } from "@/services/api";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

export default {
  namespaced: true,
  actions: {
    async getAuditTrail({ commit }, requestData) {
      try {
        let { data } = await logs.getAuditTrail(requestData);
        return data;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async logBVN({ commit }, logsData) {
      try {
        let { data } = await logs.BVNLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async logPVC({ commit }, logsData) {
      try {
        let { data } = await logs.PVCLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async logNIN({ commit }, logsData) {
      try {
        let { data } = await logs.NINLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async logDL({ commit }, logsData) {
      try {
        let { data } = await logs.DLLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async logNIP({ commit }, logsData) {
      try {
        let { data } = await logs.NIPLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async getLogs({ commit }, logsData) {
      try {
        let { data } = await logs.allLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async getAllLogs({ commit }, logsData) {
      try {
        let { data }  = await logs.allClientLogs(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async CustomerCreditHistory({}, logsData) {
      try {
        let { data } = await logs.CustomerCreditHistory(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async CustomerDebitHistory({ commit }, logsData) {
      try {
        let { data } = await logs.CustomerDebitHistory(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async CustomerDebitDetails({}, logsData) {
      try {
        return await logs.CustomerDebitDetails(logsData);
      } catch (error) {
        throw Error(error);
      }
    },

    //
    async SecondaryCustomerCallLog({}, logsData) {
      try {
        let { data } = await logs.SecondaryCustomerCallLog(logsData);
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async loggedInSecondaryCustomerCallLog({ commit }) {
      try {
        let { data } = await logs.loggedInSecondaryCustomerCallLog();
        return data;
      } catch (error) {
        throw Error(error);
      }
    },
    async exportDebitHistory({}, requestData) {
      try {
        return await logs.exportDebitHistory(requestData);
      } catch (error) {
        throw Error(error);
      }
    },
    async getSMSLogs({ commit }, query) {
      try {
        let { data } = await logs.getSMSLogs(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
  },
  getters: {},
};
