<template>
	<Modal :id="id" :title="'Update Dispute Status'" :toggle="closeModal">
		<SuccessState
			v-if="successMsg"
			:message="'Dispute Status Updated Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit">
			<CustomSelect
				:name="'status'"
				v-model="status"
				:options="['pending', 'resolved', 'abandoned']"
				:error="formErrors.status"
				:handleError="handleError"
			/>

			<FormButtons :loading="loading" :handleCloseModal="closeModal" />
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import FormButtons from "../../../../../utils/FormButtons/FormButtons";
	import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
	import Modal from "../../../../../utils/Modal/Modal";
	import { getWalletApikey } from "../../../../../utils/helpers";

	export default {
		name: "UpdateDisputeStatusModal",
		mixins: [notifications],
		data() {
			return {
				status: this.dispute.status,
				loading: false,
				formErrors: {},
				successMsg: false,
			};
		},
		props: ["id", "closeModal", "dispute", "refetch"],
		components: {
			Modal,
			SuccessState,
			CustomSelect,
			FormButtons,
		},

		methods: {
			handleRefetch() {
				this.closeModal();
				this.refetch();
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			handleSubmit() {
				const { formErrors, isValid } = formValidator({
					status: this.status,
				});
				if (isValid) this.updateDisputeStatus();
				else this.formErrors = formErrors;
			},
			async updateDisputeStatus() {
				try {
					this.loading = true;

					const response = await this.$store.dispatch(
						"payment/updateDisputeStatus",
						{
							apiKey: getWalletApikey(this.$store),
							reference: this.dispute.reference,
							params: { status: this.status },
						}
					);

					if (response.status === 200) {
						this.loading = false;
						this.successMsg = true;
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
