<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">GVC Verification Request form</h2>
                <h5 class="user-profile-email sub">
                  Verify Voter ID of your users.
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyGhanaVoterId">
              <div class="row mt-4">
                <!-- VoterId number -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label"
                      >VoterId Number
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="voterid"
                      @blur="$v.voterid.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter voterId number"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.voterid.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.voterid) }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Pnone Number -->
                <div class="col-md-6">
                  <CustomPhoneInput
                    defaultCountry="GH"
                    :onlyCountries="['GH']"
                    label="Phone Number"
                    v-model="phonenumber"
                    @blur="$v.phonenumber.$touch()"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phonenumber.$error && formVuelidator($v.phonenumber)
                    "
                  />
                </div>

                <!-- Application details -->
                <div class="col-md-12">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- API Version details -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label">
                    Select an API Version
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an API version"
                    v-model="apiVersion"
                    @close="$v.apiVersion.$touch()"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul
                    class="parsley-errors-list filled"
                    v-show="$v.apiVersion.$error"
                  >
                    <li class="parsley-required">
                      API version field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Verifying Voter</span>
                    <span v-else>Verify Voter</span>
                  </button>

                  <div style="float: right; margin-top: 10px">
                    <input
                      type="checkbox"
                      id="checkbox"
                      v-model="includepic"
                      style="
                        padding-top: 10px;
                        top: 0;
                        left: 0;
                        height: 15px;
                        width: 15px;
                      "
                    />
                    <label for="checkbox">
                      <div style="margin-left: 5px; font-size: 13px">
                        Without Image?
                      </div>
                    </label>
                  </div>
                </div>
                <!-- Include Pic -->
                <!-- <div class="col-12" style="float:right;">
                  <div class="form-group">
                     <input type="checkbox" id="checkbox" v-model="includepic" style="padding-top:10px;top:0;left:0;height:25px;width:25px">
<label for="checkbox"> <div style="margin-left:10px;font-size:16px">Without Image? </div> </label>
                  </div>
                </div> -->
              </div>
            </form>
          </div>
        </div>

        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Ghana VotersId Response</h1>
              <span class="text-span"
                >Preview of the information gotten from VotersId request
                made</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <div class="preview-content" v-if="apiVersion == 'v1'">
            <p class="invoice-info-row preview-row">
              <span>Polling Station</span>
              <span>{{ verificationResult.data.polling_station }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Voter Id</span>
              <span>{{ verificationResult.data.voter_id }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Age</span>
              <span>{{ verificationResult.data.age }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Gender</span>
              <span>{{ verificationResult.data.gender }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Registration Date</span>
              <span>{{ verificationResult.data.reg_date }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Full Name</span>
              <span>{{ verificationResult.data.full_name }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
          <div class="preview-content" v-else>
            <div class="invoice-info-row preview-row">
              <div class="passport-image">
                <img
                  width="200px"
                  height="200px"
                  :src="verificationResult.data.image_url"
                  :alt="`image for ${verificationResult.data.full_name}`"
                />
              </div>
            </div>
            <p class="invoice-info-row preview-row">
              <span>Polling Station</span>
              <span>{{ verificationResult.data.polling_station }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Voter Id</span>
              <span>{{ verificationResult.data.voter_id }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Age</span>
              <span>{{ verificationResult.data.age }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Gender</span>
              <span>{{ verificationResult.data.gender }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Registration Date</span>
              <span>{{ verificationResult.data.reg_date }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Full Name</span>
              <span>{{ verificationResult.data.full_name }}</span>
            </p>
            <div class="gaps-1-5x"></div>
          </div>
        </div>

        <!-- End of transition -->
      </transition>
      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}IdentityVerification/Ghana-VID`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
          <!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
          <!-- {"voter_id":"3482021031", "include_pic": false} -->
          <!-- "dob": "{{dob}}" -->
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "voter_id": "{{voterid ? voterid : "voterid"}}",
        "phone_number": "{{phonenumber.formatted ? phonenumber.formatted.replace(/\s/g, "") : "phonenumber"}}",
        "include_pic": "{{includepic ? includepic : "includepic"}}"
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
    "status_code": 200,
    "error": false,
    "message": "User Info Retrieved",
    "data": {
        "polling_station": "C060807A",
        "voter_id": "3482021031",
        "age": "47",
        "gender": "M",
        "reg_date": "10/04/2012",
        "full_name": "JOHN APPIAH-MENDS"
    }
}`}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, numeric } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    includepic: { required },
    voterid: { required, numeric },
    apiVersion: { required },
    phonenumber: {
      isPhoneValid: isPhoneValid("phonenumber"),
    },
    app: { required },
  },
  components: {
    CustomPhoneInput,
  },
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      voterid: "",
      hasNewResponse: false,
      country_prefix: "",
      logData: [],
      apiResponse: "",
      includepic: false,
      phonenumber: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      console.log("New-Response >>>>", newValue);
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      if (this.apiVersion == "v2" || this.apiVersion == "") {
        return this.$store.state.appUrlV2;
      } else {
        return process.env.VUE_APP_BASE_URL;
      }
    },
  },
  methods: {
    formVuelidator,
    computeService() {
      console.log("COMPUTE-SERVICE >>>>");
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Voters")) {
            console.log(
              "YEAH 'VoterId Service' FOUND >>>>",
              service.service_label
            );
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },

    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          voter_id: this.voterid,
          phone_number: this.phonenumber.formatted.replace(/\s/g, ""),
          include_pic: this.includepic,
          apiVersion: this.apiVersion,
          currency_type: this.currencyType.currency,
        },
        query: this.call_id,
      };

      return verificationData;
    },
    async verifyGhanaVoterId() {
      console.log("VERIFY-VoterID ----->");
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        let apiResponse = await this.$store.dispatch(
          "services/verifyGhanaVoterId",
          verificationData
        );
        console.log("[api Response from GV-ID ] ->", apiResponse);
        this.apiResponse = apiResponse;
        this.showSuccessNotification(apiResponse.message);
        console.log("Verification", apiResponse);
        this.verificationResult = apiResponse;
        this.hasNewResponse = true;
        this.isSubmitting = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
