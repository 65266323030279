import { walletAPI } from "../api";

export default {
	getReservedAccounts({ apiKey, params }) {
		return walletAPI.get(`/reserved-accounts`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getReservedAccount({ apiKey, reference }) {
		return walletAPI.get(`/reserved-accounts/${reference}`, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	getReservedAccountTransactions({ apiKey, params }) {
		return walletAPI.get(`/reserved-accounts-transactions`, {
			params,
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
	createReservedAccount({ apiKey, request }) {
		return walletAPI.post(`/reserved-accounts`, request, {
			headers: {
				"x-api-key": apiKey,
			},
		});
	},
};
