<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="SectionItem no-b-b mb-3">
				<div class="row">
					<div class="col-12">
						<div class="SectionHeader">
							<span class="title">Profile</span>
							<div class="description">
								Customize advanced settings for profile
								infomation
							</div>
						</div>
					</div>
					<!-- User profile   -->
					<div class="col-12 mt-5">
						<div class="user-details--wrapper">
							<div class="user-details-left-header">
								<div class="user-details-info">
									<div
										class="user-details-avatar user-avatar online"
									>
										<img
											:src="
												`/assets/img/avatars/${user
													.api_customer.id % 18}.png`
											"
											alt
										/>
									</div>
									<div class="user-details--top-info">
										<h2 class="user-profile-name">
											{{ user.firstname }}
											{{ user.lastname }}
										</h2>
										<h5 class="user-profile-email">
											{{ user.email }}
										</h5>
										<span class="user--id"
											>ID:
											{{
												user.api_customer.client_id
											}}</span
										>
									</div>
								</div>
								<div>
									<a
										href="#"
										@click="toShow = true"
										v-if="toShow == false"
										class="btn btn-lg btn-light"
										>Edit Profile</a
									>
									<a
										href="#"
										v-if="toShow == true"
										@click="toShow = false"
										class="btn btn-lg btn-danger"
										>Cancel</a
									>
								</div>
							</div>
							<transition mode="out-in" name="fade">
								<AccountProfileEdit
									@closeEdit="closeEdit"
									v-if="toShow == true"
									:apiUser="apiUser"
								></AccountProfileEdit>
								<AccountProfileInfo v-else></AccountProfileInfo>
							</transition>
						</div>
					</div>
					<!-- MFA   -->
					<div class="col-12">
						<div class="user-settings-section">
							<div class="user-settings-section-header">
								<div class="user-settings-section-header-text">
									<h1>Multi Factor Authentication</h1>
									<span
										v-if="!user.mfa_active"
										class="text-span"
										>Set up Multi Factor Authentication to
										secure your account</span
									>
									<span v-else class="text-span"
										>You have enabled multi factor
										authentication</span
									>
								</div>
								<div v-if="!user.mfa_active" class>
									<a
										@click="requestMfa"
										:disabled="requestingMfa"
										class="btn btn-lg btn-light"
										>Enable</a
									>
								</div>
							</div>
						</div>
					</div>
					<!-- CHange Password  -->
					<div class="col-12">
						<div class="user-settings-section">
							<div class="user-settings-section-header">
								<div class="user-settings-section-header-text">
									<h1>Change password</h1>
									<span class="text-span"
										>Update your secret combination of
										letters and numbers</span
									>
								</div>
								<div class>
									<a
										@click="
											showPasswordEdit = !showPasswordEdit
										"
										class="btn btn-lg btn-light"
										>Reset Password</a
									>
								</div>
							</div>

							<transition mode="out-in" name="fade">
								<div
									class="user-setting-password-edit"
									:class="{ closed: !showPasswordEdit }"
								>
									<form @submit.prevent="changePassword">
										<div class="row mg-b-10">
											<div class="col-md-6">
												<div class="form-group">
													<label
														class="input-item-label"
														>Current password</label
													>
													<input
														type="text"
														class="form-control input-bordered"
														v-model="
															PM.currentPassword
														"
													/>
												</div>
											</div>
										</div>
										<div class="row mg-b-10">
											<div class="col-md-6">
												<div class="form-group">
													<label
														class="input-item-label"
														>New password</label
													>
													<input
														type="password"
														class="form-control input-bordered"
														v-model="PM.password"
													/>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label
														class="input-item-label"
														>Confirm new
														password</label
													>
													<input
														type="password"
														class="form-control input-bordered"
														v-model="
															PM.confirmPassword
														"
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<button
													type="submit"
													class="btn btn-primary"
												>
													Change password
												</button>
												<button
													@click="clearPassword"
													type="button"
													class="btn btn-outline-danger ml-2"
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</div>
							</transition>
						</div>

						<!-- Deactivate Account -->
						<div class="user-settings-section" v-if="false">
							<div class="user-settings-section-header">
								<div class="user-settings-section-header-text">
									<h1>Deactivate account</h1>
									<span class="text-span"
										>This will remove your account from all
										teams and disable your account.</span
									>
								</div>
								<div class>
									<a
										href="#"
										class="btn btn-lg btn-outline-danger"
										>Deactivate Account</a
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Nprogress from "nprogress";
	import AccountBilling from "@/components/account/AccountBilling";
	import AccountProfileInfo from "@/components/account/AccountProfileInfo";
	import AccountProfileEdit from "@/components/account/AccountProfileEdit";
	import AccountActivity from "@/components/account/AccountActivity";
	import AccountServices from "@/components/account/AccountServices";
	import Team from "@/components/account/Team";
	import SecondaryCustomerRequestLogs from "@/components/account/SecondaryCustomerRequestLogs";

	import AccountServiceEditModal from "@/components/modals/AccountServiceEditModal.vue";
	import CustomerWallet from "@/components/account/CustomerWallet.vue";

	import { notifications } from "../../../public/assets/mixins/notifications";
	import { ACCOUNT_ROLES } from "@/constants/roles";
	export default {
		mixins: [notifications],
		name: "Account",
		components: {
			AccountBilling,
			SecondaryCustomerRequestLogs,
			AccountActivity,
			AccountServices,
			AccountProfileInfo,
			AccountProfileEdit,
			Team,
			CustomerWallet,
		},

		data() {
			return {
				tab: 1,
				isLoading: true,
				apiUser: {},

				toShow: false,

				showPasswordEdit: false,

				requestingMfa: false,
				accountRole: "",
				operationAccess: false,

				PM: {
					password: "",
					currentPassword: "",
					confirmPassword: "",
				},
			};
		},
		computed: {
			userRole() {
				return this.$store.state.account.profile.roles[0].role_label;
			},
			user() {
				return this.$store.state.account.profile;
			},
		},
		methods: {
			tabswitch(tab) {
				if (tab) {
					this.tab = tab;
					this.$router.replace({ query: { tab: this.tab } });
				} else {
					this.tab = 1;
				}
			},
			viewPermissions() {
				if (
					this.accountRole == ACCOUNT_ROLES.ADMIN ||
					this.accountRole == ACCOUNT_ROLES.OWNER ||
					this.accountRole == ACCOUNT_ROLES.OPERATIONS ||
					this.accountRole == ACCOUNT_ROLES.DEVELOPER
				)
					this.operationAccess = true;
			},
			async requestMfa() {
				let requestHeaders = {
					clientid: this.apiUser.api_customer.client_id,
				};
				this.requestingMfa = true;
				try {
					let response = await this.$store.dispatch(
						"account/enableMfa",
						requestHeaders
					);
					this.showSuccessNotification(response.message);
					Nprogress.done();
					this.requestingMfa = false;
				} catch (error) {
					this.requestingMfa = false;
					this.showErrorRegistrationNotification(error.message);
					Nprogress.done();
				}
			},

			async requestWhiteLabelling() {
				let requestHeaders = {
					clientid: this.apiUser.api_customer.client_id,
				};
				try {
					let response = await this.$store.dispatch(
						"account/customerRequestWhiteLabelling",
						requestHeaders
					);
					this.showSuccessNotification(response.message);
					// console.log("[response from requesting white labelling] ->", response);
					Nprogress.done();
				} catch (error) {
					console.log(
						"[errors from requesting white labelling] ->",
						error
					);
					this.showErrorRegistrationNotification(error.message);
					Nprogress.done();
				}
			},
			closeEdit() {
				this.toShow = !this.toShow;
			},
			clearPassword() {
				this.PM.currentPassword = "";
				this.PM.password = "";
				this.PM.confirmPassword = "";

				this.showPasswordEdit = !this.showPasswordEdit;
			},
			async getProfile() {
				try {
					let apiResponse = await this.$store.dispatch(
						"account/getProfile"
					);
					this.apiUser = { ...apiResponse };
					this.isLoading = false;
				} catch (error) {
					console.log(error);
				}
			},
			async changePassword() {
				let credentials = {
					old_password: this.PM.currentPassword,
					new_password: this.PM.password,
					confirm_new_password: this.PM.confirmPassword,
				};

				try {
					let response = await this.$store.dispatch(
						"account/changePassword",
						credentials
					);
					this.showSuccessNotification(response.message);
					// console.log("[response from password change] ->", response);
					this.clearPassword();
					Nprogress.done();
				} catch (error) {
					console.log("[errors from password change] ->", error);
					this.showErrorRegistrationNotification(error.message);
					Nprogress.done();
				}
			},
		},
		async mounted() {
			if (this.$route.query.tab) {
				let tab = this.$route.query.tab;
				console.log("[tab] ->", tab);
				this.tabswitch(tab);
			}
			this.getProfile();
			this.accountRole = localStorage.getItem("accountRole");
			this.viewPermissions();
		},
	};
</script>
