<template>
	<div>
		<Loader v-if="isFetchingSettings == true" />
		<AuthWrapper
			:whitelabel="
				isFetchingSettings == false && WLsettings.app_domain_name
			"
			v-if="isFetchingSettings == false"
		>
			<CustomLogin v-if="WLsettings.app_domain_name"> </CustomLogin>
			<div
				v-else
				class="no-sm-padding col-xl-4 col-lg-5 col-md-4 col-sm-12"
			>
				<div>
					<div class="card-innr card-login auth-card">
						<a href="/" class="logo">
							<img
								:src="
									WLsettings.logo_url
										? WLsettings.logo_url
										: '/assets/img/blusalt-logo-sb-inverse.svg'
								"
								alt
							/>
						</a>

						<div
							class="card-header-w-des no-border align-items-center justify-content-between"
						>
							<div
								style="
									text-align: center;
									margin: 40px 0px 30px;
								"
							>
								<h2
									style="
										font-weight: 500;
										letter-spacing: -0.02em;
									"
								>
									Building the future of financial services
								</h2>
								<h4
									class="card-title"
									style="color: rgb(34, 34, 34)"
								>
									Welcome Back, Please login to your account.
								</h4>
							</div>
						</div>

						<form v-if="otpRequested" @submit.prevent="submitOtp">
							<div class="form-group">
								<label class="input-item-label"
									>Enter the otp sent to your phone
									number</label
								>
								<input
									type="text"
									class="form-control input-bordered"
									placeholder="Enter OTP"
									v-model.trim="otp"
								/>
								<div class="gaps-2x"></div>
								<button
									type="submit"
									class="btn btn-lg btn-primary btn-block mg-b-20"
									:disabled="isProcessing"
								>
									<span v-if="isProcessing"
										>Attempting to signin</span
									>
									<span v-else>Sign In</span>
								</button>
							</div>
						</form>
						<form v-else @submit.prevent="performLogin">
							<div class="form-group">
								<label class="input-item-label"
									>Email/Phone-number</label
								>
								<input
									type="text"
									class="form-control input-bordered"
									placeholder="Enter your email or phone number"
									v-model.trim="username"
								/>
								<ul
									class="parsley-errors-list filled"
									id="parsley-id-26"
									v-show="$v.username.$error"
								>
									<li class="parsley-required">
										Username is required.
									</li>
								</ul>
							</div>
							<div class="form-group mg-b-20">
								<label class="input-item-label">Password</label>
								<div class="pos-relative">
									<input
										:type="type"
										class="form-control input-bordered"
										placeholder="Enter your password"
										v-model="password"
									/>
									<div
										class="d-flex pos-absolute t-5 mt-2 r-10"
									>
										<span
											v-if="type === 'text'"
											@click="type = 'password'"
											class="input-icon input-icon-right"
										>
											<em class="fas fa-eye fs-24"></em>
										</span>
										<span
											v-if="type === 'password'"
											@click="type = 'text'"
											class="input-icon input-icon-right"
										>
											<em
												class="fas fa-eye-slash fs-24"
											></em>
										</span>
									</div>
								</div>
								<ul
									class="parsley-errors-list filled"
									id="parsley-id-26"
									v-show="$v.password.$error"
								>
									<li class="parsley-required">
										Password is required.
									</li>
								</ul>
							</div>
							<div class="pb-3">
								<router-link to="/forgot-password" class="left"
									>Forgot password?</router-link
								>
							</div>
							<div class="gaps-2x"></div>
							<button
								type="submit"
								class="btn btn-lg btn-primary btn-block mg-b-20"
								:disabled="isProcessing == true"
							>
								<span v-if="isProcessing"
									>Attempting to signin</span
								>
								<span v-else>Sign In</span>
							</button>
							<p v-if="false">
								Don't have an account?
								<router-link to="/register"
									>Create one now</router-link
								>
							</p>
						</form>

						<div class="az-signup-footer">
							<p class="mb-4">
								Don't have an account?
								<a
									target="_blank"
									:href="
										WLsettings.company_website_url
											? WLsettings.company_website_url
											: 'http://blusalt.net/contact'
									"
								>
									Contact Sales
									<i class="la la-arrow-right"></i>
								</a>
							</p>
							<p class="text-center">
								<span
									v-if="WLsettings.copy_right_info"
									v-text="WLsettings.copy_right_info"
								></span>
								<span v-else
									>© {{ year }} Blusalt Financial Services
									Ltd. All rights reserved.</span
								>
							</p>
						</div>
					</div>
				</div>
			</div>
		</AuthWrapper>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";
import { whitelabelMixin } from "../../public/assets/mixins/settings";
import CustomLogin from "@/views/auth/CustomLogin";

export default {
	name: "Login",
	mixins: [notifications, whitelabelMixin],
	components: {
		CustomLogin,
		AuthWrapper,
	},

	data() {
		return {
			type: "password",
			username: "",
			password: "",
			otp: "",
			otpRequestToken: "",
			isProcessing: false,
			otpRequested: false,
		};
	},
	validations: {
		username: {
			required,
		},
		password: {
			required,
		},
	},
	WLsettings() {
		return this.$store.state.whitelabelling.whitelabelledSettings;
	},
	computed: {
		year() {
			return new Date().getFullYear();
		},
	},
	methods: {
		clearInputFields() {
			this.password = "";
			this.username = "";
			this.otp = "";
		},
		async submitOtp() {
			Nprogress.start();
			this.isProcessing = true;

			try {
				let apiResponse = await this.$store.dispatch(
					"auth/loginUserOtp",
					{
						request_token: this.otpRequestToken,
						code: this.otp,
					}
				);
				// console.log('[apiResponse of login] ->', apiResponse);
				Nprogress.done();
				this.showSuccessNotification(apiResponse.data.message);
				setTimeout(() => {
					location.replace("/account");
				}, 2000);
			} catch (error) {
				Nprogress.done();
				this.clearInputFields();
				this.isProcessing = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async performLogin() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log("[invalid fields] ->");

				return;
			}
			Nprogress.start();
			this.isProcessing = true;

			let credential = {
				username: this.username,
				password: this.password,
			};
			try {
				let apiResponse = await this.$store.dispatch(
					"auth/loginUser",
					credential
				);

				console.log("[apiResponse of login] ->", apiResponse);
				Nprogress.done();
				this.showSuccessNotification(apiResponse.data.message);

				if (
					apiResponse.data.action &&
					apiResponse.data.action === "otp"
				) {
					this.otpRequested = true;
					this.isProcessing = false;
					this.otpRequestToken = apiResponse.data.request_token;
				} else {
					setTimeout(() => {
						location.replace("/dashboard");
					}, 500);
				}
			} catch (error) {
				Nprogress.done();
				this.clearInputFields();
				this.isProcessing = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
