<template>
  <div>
    <!-- <AddSenderButton/> -->
    <form>
      <div><h3 class="title mb-3">Sender Information</h3></div>
      <div>
        <div class="row">
          <div class="col-sm-4 mb-2">
            <label> Select Sender </label>
            <SenderSelect @populateForm="populateForm" />
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderFirstName'"
              :label="'Sender`s First Name'"
              :placeholder="'First Name'"
              :required="true"
              v-model="senderForm.senderFirstName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderFirstName.$error"
            >
              <li class="parsley-required">First Name is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderLastName'"
              :label="'Sender`s Last Name'"
              :placeholder="'Last Name'"
              :required="true"
              v-model="senderForm.senderLastName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderLastName.$error"
            >
              <li class="parsley-required">Last Name is required.</li>
            </ul>
          </div>
          <div class="col-md-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderMaidenName'"
              :label="'Maiden Name'"
              :placeholder="'Maiden Name'"
              v-model="senderForm.senderMaidenName"
            />
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderEmail'"
              :label="'Sender`s Email'"
              :placeholder="'Email'"
              :required="true"
              v-model="senderForm.senderEmail"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderEmail.$error"
            >
              <li class="parsley-required">Email is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderPhoneNumber'"
              :maxlength="11"
              :placeholder="'Phone Number'"
              :label="'Enter Phone Number'"
              :required="true"
              :isNum="true"
              v-model="senderForm.senderPhoneNumber"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderPhoneNumber.$error"
            >
              <li class="parsley-required">Phone Number is required.</li>
            </ul>
          </div>

          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderOccupation'"
              :label="'Occupation'"
              :placeholder="'Occupation'"
              v-model="senderForm.senderOccupation"
            />
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderAddressOne'"
              :placeholder="'Address'"
              :label="'Address 1'"
              :required="true"
              v-model="senderForm.senderAddressOne"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderAddressOne.$error"
            >
              <li class="parsley-required">Address is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderAddressTwo'"
              :placeholder="'Address'"
              :label="'Address 2'"
              v-model="senderForm.senderAddressTwo"
            />
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderNationality'"
              :placeholder="'Nationality'"
              :label="'Nationality'"
              v-model="senderForm.senderNationality"
            />
          </div>
          <div class="col-sm-4 mb-2">
            <div class="form-group">
              <label class="input-item-label">Country Currency</label>
              <v-select
                class="v-select"
                placeholder="Select a country"
                :options="countryCurrency"
                label="code"
                @keydown.enter.native.prevent
                name="senderCountryCurrency"
                v-model="senderForm.senderCountryCurrency"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderCountryCurrency.$error"
            >
              <li class="parsley-required">Country Currency is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <div class="form-group">
              <label class="input-item-label">Country Short Code</label>
              <v-select
                class="v-select"
                placeholder="Select a country"
                :options="countrySortCode"
                label="code3"
                @keydown.enter.native.prevent
                name="senderCountryCode"
                v-model="senderForm.senderCountryCode"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderCountryCode.$error"
            >
              <li class="parsley-required">Country short Code is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <div class="form-group">
              <label class="input-item-label">Country</label>
              <v-select
                class="v-select"
                placeholder="Select a country"
                :options="metaData.countries"
                label="name"
                @input="changeState"
                @keydown.enter.native.prevent
                name="senderCountry"
                v-model="senderForm.senderCountry"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderCountry.$error"
            >
              <li class="parsley-required">Country is required.</li>
            </ul>
          </div>

          <div class="col-sm-4">
            <div class="form-group">
              <label class="input-item-label">State</label>
              <v-select
                class="v-select"
                placeholder="Select a state"
                v-model="senderForm.senderState"
                @keydown.enter.native.prevent
                :disabled="!senderForm.senderCountry"
                :options="states"
                label="state_label"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderState.$error"
            >
              <li class="parsley-required">State is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderCity'"
              :maxlength="11"
              :placeholder="'City'"
              :label="'City'"
              :required="true"
              v-model="senderForm.senderCity"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderCity.$error"
            >
              <li class="parsley-required">City is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderRelationship'"
              :maxlength="11"
              :placeholder="'Relationship with reciever'"
              :label="'Relationship with reciever'"
              :required="true"
              v-model="senderForm.senderRelationship"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderRelationship.$error"
            >
              <li class="parsley-required">Relationship is required.</li>
            </ul>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderPostCode'"
              :maxlength="11"
              :placeholder="'Postcode'"
              :label="'Postcode'"
              :required="true"
              :isNum="true"
              v-model="senderForm.senderPostCode"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.senderForm.senderPostCode.$error"
            >
              <li class="parsley-required">Postcode is required.</li>
            </ul>
          </div>

          <div class="col-sm-4 mb-2">
            <label class=""> Gender </label>
            <div class="d-flex">
              <label class="rdiobox mr-4">
                <input
                  name="senderGender"
                  type="radio"
                  :checked="true"
                  value="Male"
                  v-model="senderForm.senderGender"
                />
                <span class="fs-15">Male</span>
              </label>
              <label class="rdiobox">
                <input
                  name="senderGender"
                  type="radio"
                  value="Female"
                  v-model="senderForm.senderGender"
                />
                <span class="fs-15"> Female </span>
              </label>
            </div>
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderIdType'"
              :placeholder="'ID Type'"
              :label="'ID Type'"
              v-model="senderForm.senderIdType"
            />
          </div>
          <div class="col-sm-4 mb-2">
            <CustomInput
              :otherCls="'mb-0'"
              :name="'senderIdNumber'"
              :placeholder="'ID Number'"
              :label="'ID Number'"
              v-model="senderForm.senderIdNumber"
            />
          </div>

          <div class="col-sm-4 mb-2">
            <div class="form-group">
              <label class="input-item-label">Date Issued</label>
              <DatePicker
                :name="'senderIdIssueDate'"
                v-model="senderForm.senderIdIssueDate"
                :disabledDates="{ from: new Date() }"
                :placeholder="'Date Issued'"
              />
            </div>
          </div>
          <div class="col-sm-4 mb-2">
            <div class="form-group">
              <label class="input-item-label">Expiry Date </label>
              <DatePicker
                :name="'senderIdIssueDate'"
                v-model="senderForm.senderIdExpiryDate"
                :disabledDates="{ to: new Date() }"
                :placeholder="'Expiry Date '"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex__between">
        <input
          type="button"
          value="Next"
          class="btn btn-lg btn-primary"
          :disabled="loading"
          @click="handleSubmit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Spinner from "../../../../utils/Spinner/Spinner.vue";
import FailedState from "../../../../utils/ResponseState/FailedState";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { required } from "vuelidate/lib/validators";
import CustomInput from "../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../utils/CustomSelect/CustomSelect";
import DatePicker from "@/utils/DatePicker/DatePicker";
import AddSenderButton from "../buttons/AddSenderButton/AddSenderButton";
import SenderSelect from "@/utils/Selects/SenderSelect/SenderSelect";
import {
  newCountryFormat,
  countryCurrency,
  countrySortCode,
} from "@/assets/js/countryDailcodes";
import Axios from "axios";
import { getWalletApikey } from "@/utils/helpers";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
  mixins: [notifications],
  name: "SenderForm",
  data() {
    return {
      senderForm: {},
      loading: false,
      newCountryFormat,
      countryCurrency,
      countrySortCode,
      states: [],
      mediaData: "",
      uploadError: false,
    };
  },
  validations: {
    states: [],
    senderForm: {
      senderFirstName: { required },
      senderLastName: { required },
      senderRelationship: { required },
      senderEmail: { required },
      senderPhoneNumber: { required },
      senderAddressOne: { required },
      senderCountry: { required },
      senderCountryCurrency: { required },
      senderCountryCode: { required },
      senderState: { required },
      senderCity: { required },
      senderPostCode: { required },
    },
  },
  components: {
    Spinner,
    FailedState,
    CustomInput,
    AddSenderButton,
    CustomSelect,
    DatePicker,
    SenderSelect,
    CustomUpload,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
  },
  methods: {
    clearInputFields() {
      this.senderForm = {};
      this.loading = false;
    },
    changeState() {
      // this.senderForm.state = [];
      this.states = this.senderForm.senderCountry.states;
    },
    populateForm(data) {
      this.senderForm = {
        ...this.senderForm,
        senderFirstName: data.firstName,
        senderLastName: data.lastName,
        senderMaidenName: data.maidenName,
        senderEmail: data.email,
        senderPhoneNumber: data.phoneNumber,
        senderAddressOne: data.addressOne,
        senderAddressTwo: data.addressTwo,
        senderCountry: data.country,
        senderOccupation: data.occupation,
        senderState: data.state,
        senderCity: data.city,
        senderGender: data.gender,
        senderPostCode: data.postCode,
        senderIdType: data.idType,
        senderIdNumber: data.idNumber,
        senderImage: data.image,
        senderIdIssueDate: data.idIssueDate,
        senderIdExpiryDate: data.idExpiryDate,
        customerReference: data.customerReference,
      };
    },
    handleSubmit() {
      this.$v.$touch();
      if (this.senderForm.senderImage === undefined) {
        this.uploadError = true;
      }
      if (this.$v.$invalid) return;
      const senderPayload = JSON.parse(
        window.localStorage.getItem("__send_money")
      );
      window.localStorage.setItem(
        "__send_money",
        JSON.stringify({ ...senderPayload, ...this.senderForm })
      );
      this.$emit("handleNext");
    },
    async handleUpload({ target: { files } }) {
      this.mediaData = null;

      const file = files[0];
      const dataSize = 1024 * 1024;

      if (file) {
        if (file.size / dataSize < 5) {
          this.uploadError = false;
          let idPhoto = new FormData();
          idPhoto.append("mediaFile", file);

          let resp = await Axios.post(
            "https://web-api.blusalt.net/api/v1/mediaFile/uploadMediaFile", // temporary use, change in the future

            idPhoto
          );
          this.senderForm = {
            ...this.senderForm,
            senderImage: resp.data.data.fileData,
          };
        } else {
          this.uploadError = true;
        }
      }
    },
  },

  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
  },
  mounted() {
    this.states = this.metaData.countries[0].states;
  },
};
</script>
