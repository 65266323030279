<template>
  <v-client-table
    v-if="type != 'invoices'"
    class="acct-statement-table"
    :data="transactions"
    :columns="columns"
    :options="options"
  >
    <template slot="metadata.account_number" slot-scope="{ row }">
      {{
        row.metadata && row.metadata.account_number
          ? row.metadata.account_number
          : "-------------------"
      }}
    </template>
    <template slot="amount" slot-scope="{ row }">
      {{ row.currency }}{{ toCurrency(row.amount, " ") }}
    </template>
    <template slot="source" slot-scope="{ row }">
      {{
        titleCase(
          row.source === "merchant_transfer" ? "Bank_transfer" : row.source
        )
      }}
    </template>
    <template slot="metadata.source_account.account_name" slot-scope="{ row }">
      {{
        row.metadata && row.metadata.source_account
          ? row.metadata.source_account.account_name
          : "---------"
      }}
    </template>
    <template slot="action" slot-scope="{ row }">
      <span :class="statusColor(row.action)">
        {{ titleCase(row.action) }}
      </span>
    </template>
    <template slot="type" slot-scope="{ row }">
      <span>
        {{ titleCase(row.type) }}
      </span>
    </template>
    <template slot="status" slot-scope="{ row }">
      <span :class="statusColor(row.status)">
        {{ titleCase(row.status) }}
      </span>
    </template>
    <template slot="createdAt" slot-scope="{ row }">
      <span>
        {{
          timeDateFormat(row.createdAt).date +
          " " +
          timeDateFormat(row.createdAt).time
        }}
      </span>
    </template>
  </v-client-table>
  <v-client-table
    v-else
    class="acct-statement-table"
    :data="transactions"
    :columns="columns"
    :options="options"
  >
    <template slot="amount" slot-scope="{ row }">
      {{ row.currency }}{{ toCurrency(row.amount, " ") }}
    </template>
    <template slot="status" slot-scope="{ row }">
      <span :class="statusColor(row.status == 'paid' ? 'paid' : 'outstanding')">
        {{ titleCase(row.status == "paid" ? "paid" : "outstanding") }}
      </span>
    </template>
    <template slot="amount_paid" slot-scope="{ row }">
      <span>
        {{
          toCurrency(
            row.partial.is_partial
              ? row.partial.installments.reduce((a, b) => a + b, 0)
              : row.status == "paid"
              ? row.amount
              : 0,
            row.currency
          )
        }}
      </span>
    </template>
    <template slot="outstanding" slot-scope="{ row }">
      <span>
        {{
          toCurrency(
            row.partial.is_partial
              ? row.amount - row.partial.installments.reduce((a, b) => a + b, 0)
              : row.status != "paid"
              ? row.amount
              : 0,
            row.currency
          )
        }}
      </span>
    </template>
    <template slot="issued_date" slot-scope="{ row }">
      <span>
        {{
          row.issued_date
            ? timeDateFormat(row.issued_date).date
            : "Not Available"
        }}
      </span>
    </template>
    <template slot="due_date" slot-scope="{ row }">
      <span>
        {{ row.due_date ? timeDateFormat(row.due_date).date : "Not Available" }}
      </span>
    </template>
  </v-client-table>
</template>

<script>
import {
  timeDateFormat,
  titleCase,
  statusColor,
  toCurrency,
} from "@/utils/helpers";
import { filters } from "../../../../../../../../public/assets/mixins/filters";

const tableHeadings = {
  reference: "Reference ID",
  createdAt: "Date",
  source: "Payment Method",
  amount: "Amount",
  type: "Payment Source",
};

const walletHeadings = {
  reference: "Transaction ID",
  amount: "Amount",
  action: "Type",
  source: "Payment Method",
  status: "Status",
  createdAt: "Transaction Date",
};

const invoiceHeadings = {
  invoice_reference: "Receipt Number",
  status: "Status",
  issued_date: "Invoice Date",
  amount: "Invoice Amt",
  amount_paid: "Amount Paid",
  outstanding: "Outstanding",
  due_date: "Due Date",
};

export default {
  name: "StatementsTable",
  props: ["transactions", "type"],
  mixins: [filters],
  data() {
    return {
      columns: [],
      ...this.tableOptions(tableHeadings, 5000, [], 0),
    };
  },
  methods: {
    titleCase,
    timeDateFormat,
    statusColor,
    toCurrency,
  },
  mounted() {
    if (!this.type) {
      this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
    } else if (this.type == "wallet") {
      this.columns = Object.keys(walletHeadings).map((keyVal) => keyVal);
    } else if (this.type == "invoices") {
      this.columns = Object.keys(invoiceHeadings).map((keyVal) => keyVal);
    }
  },
};
</script>

<style scoped>
.acct-statement-table >>> .table td {
  padding: 15px 5px;
  white-space: revert;
}
</style>
