<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary--border px-4"
      @click="openBlockTerminalModal"
    >
      Update Agent
    </button>
    <BlockTerminalModal
      :id="'blockTerminal'"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
import BlockTerminalModal from "../../modal/BlockTerminalModal/BlockTerminalModal.vue";

export default {
  name: "BlockTerminalButton",
  methods: {
    openBlockTerminalModal() {
      $("#blockTerminal").modal("show");
    },
    closeModal() {
      $("#blockTerminal").modal("hide");
    },
  },
  components: {
    BlockTerminalModal,
  },
};
</script>
