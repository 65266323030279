var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Registered ownership as at "+_vm._s(_vm.timeDateFormat(_vm.details.registration_date).date)+" as shown below; ")])])]),_c('div',{staticClass:"d-flex my-3 rounded"},_vm._l((_vm.shareHolders),function(value,key,index){return _c('div',{key:index,staticClass:"shares-chart",style:({
        'background-color': _vm.random_hex_color_code(key),
        minWidth: '20px',
        maxHeight: '50px',
        width: (value.sharesCount / _vm.total) * 100 + '%',
      })},[_c('div',{staticClass:"p-3 text-light ellipsis"},[_vm._v(" "+_vm._s(value.name)+" ")]),_c('div',{staticClass:"float-div p-4 shadow rounded"},[_vm._v(" "+_vm._s(value.name)+" : "+_vm._s((value.sharesCount / _vm.total) * 100)+"% ")])])}),0),_vm._m(0),_c('div',{staticClass:"mt-5",class:_vm.tbClass},[_c('div',{staticClass:"p-2 bg-light-blue mb-2"},[_vm._v("Shareholders")]),_c('v-server-table',{ref:"shareHoldersRef",staticClass:"v-table",attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loading":function($event){return _vm.setLoading(true)},"loaded":function($event){return _vm.setLoading(false)},"error":_vm.setError},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('button',{staticClass:"btn btn--primary",on:{"click":function($event){return _vm.showData({ row })}}},[_vm._v(" More Details ")])]}}])})],1),(_vm.showModal)?_c('OwnershipModal',{attrs:{"data":_vm.modalData},on:{"close-modal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{},[_vm._v("*Person(s) of Significant Control")]),_c('br'),_c('p',{},[_vm._v(" Ownership data might be inconsistent when the company has not filed recently ")])])
}]

export { render, staticRenderFns }