<template>
  <div
    class="tab-pane fade"
    id="Account_Statement"
    role="tabpanel"
    aria-labelledby="Account_Statement-tab"
  >
    <div class="mt-4">
      <form @submit.prevent="">
        <div class="form-group">
          <label class="input-item-label"> Generate Account Statement </label>
        </div>
        <div class="row">
          <div class="col-md-6">
            <CustomSelect
              :name="'type'"
              :label="'Type'"
              :placeholder="'Choose Statement Section'"
              :optionLabel="'label'"
              :options="[
                {
                  label: 'Transactions',
                  value: 'transactions',
                  extra: 'Payment Transactions',
                },
                { label: 'Invoices', value: 'invoices' },
                { label: 'Wallet Transactions', value: 'wallet' },
              ]"
              @input="handleReset"
              :error="$v.request.type.$error && formVuelidator($v.request.type)"
              v-model="request.type"
              :required="false"
            />
          </div>
          <div class="col-md-6 position-relative">
            <CustomInput
              :name="'durations'"
              :value="range"
              @click.native="handleShowDate"
              :otherCls="'pointer'"
              :label="'Durations'"
              :placeholder="'Choose Date'"
              :readonly="true"
              :error="
                $v.request.durations.$error &&
                formVuelidator($v.request.durations)
              "
              :icon="'/assets/img/icons/calender.svg'"
            />
            <DateRangePicker
              v-if="showDate"
              :dates="request.durations"
              @reset="handleReset"
              @close="showDate = false"
            />
          </div>
          <div class="col-md-6">
            <GenerateStatementBtn
              :success="success"
              :loading="loading"
              @refresh="handleRefresh"
              @generate="handleGenerate"
              :transactions="transactions"
              :dates="request.durations"
              :params="params"
            />
          </div>
          <div v-if="show" class="mt-4 col-12">
            <AccountStatmentTable
              :params="params"
              @loading="handleLoading"
              @error="handleError"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { timeDateFormat } from "@/utils/helpers";
import required from "vuelidate/lib/validators/required";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import GenerateStatementBtn from "./GenerateStatementBtn.vue";
import DateRangePicker from "@/utils/DatePicker/DateRangePicker";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import TransactionsTable from "../../../../common/TransactionsTable/TransactionsTable.vue";
import AccountStatmentTable from "./AccountStatmentTable.vue";
export default {
  validations: {
    request: {
      type: {
        required,
      },
      durations: {
        required,
      },
    },
  },
  data() {
    return {
      request: {
        type: "",
        durations: {
          from: new Date(),
          to: new Date(),
        },
      },
      loading: false,
      showDate: false,
      success: false,
      show: false,
      transactions: null,
    };
  },
  computed: {
    params() {
      const params = {
        customer: this.$route.params.reference,
        ...this.request.durations,
      };

      if (
        this.request.type &&
        this.request.type.value &&
        this.request.type.value !== "transactions"
      ) {
        params.type = this.request.type.value;
      }

      return params;
    },
    range() {
      const { durations } = this.request;

      const value = `${timeDateFormat(durations.from).date} to ${
        timeDateFormat(durations.to).date
      }`;

      return value;
    },
  },
  components: {
    AccountStatmentTable,
    CustomInput,
    CustomSelect,
    DateRangePicker,
    GenerateStatementBtn,
    TransactionsTable,
  },
  methods: {
    formVuelidator,
    handleShowDate() {
      this.showDate = !this.showDate;
    },
    handleReset() {
      this.show = false;
      this.success = false;
      this.transactions = "";
    },
    handleError() {
      this.loading = false;
      this.transactions = "";
    },
    handleLoading({ status, value }) {
      this.loading = status;
      this.transactions = value;
      status == false ? (this.success = true) : (this.success = false);
    },

    handleRefresh() {
      this.transactions = "";
      this.type = "";
      this.success = false;
      this.show = false;
      this.request.durations = {
        from: new Date(),
        to: new Date(),
      };
    },
    handleGenerate() {
      this.$v.$touch();

      if (this.$v.$invalid) return;
      this.show = true;
    },
  },
};
</script>
