import fundstransfer from "@/services/fundstransfer";
import { apiClient } from "@/services/api";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

export default {
	namespaced: true,
	state: {
		businessInfo: {},
		authKeys: {},
		transactions: [],
		currentPage: 1,
	},
	mutations: {
		SET_BUSINESS_INFO(state, businessInfo) {
			state.businessInfo = businessInfo;
		},
		SET_AUTH_KEYS(state, authKeys) {
			state.authKeys = authKeys;
		},
		SET_TRANSACTIONS(state, transactions) {
			state.transactions = transactions;
		},
		SET_PAGE(state, page) {
			state.currentPage = page;
		},
	},
	actions: {
		setPage({ commit }, page) {
			commit("SET_PAGE", page);
		},
		async requestFtService(FTService) {
			try {
				let result = await fundstransfer.requestFtService(FTService);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitBusinessInformation({ commit }, businessInformation) {
			try {
				let result = await fundstransfer.submitBusinessInformation(
					businessInformation
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitBusinessContactInfo({ commit }, businessContactInfo) {
			try {
				let result = await fundstransfer.submitBusinessContactInfo(
					businessContactInfo
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitBusinessAddressInfo({ commit }, businessAddressInfo) {
			try {
				let result = await fundstransfer.submitBusinessAddressInfo(
					businessAddressInfo
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitBusinessEmails({ commit }, businessEmails) {
			try {
				let result = await fundstransfer.submitBusinessEmails(
					businessEmails
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitBusinessSocials({ commit }, businessSocials) {
			try {
				let result = await fundstransfer.submitBusinessSocials(
					businessSocials
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitIP({ commit }, IP) {
			try {
				let result = await fundstransfer.submitIP(IP);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getBusinessInfo({ commit }, businessInfo) {
			try {
				let result = await fundstransfer.getBusinessInfo(businessInfo);
				let bizInfo = result.data.results[0];
				commit("SET_BUSINESS_INFO", bizInfo);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async completeFTSubmission({ commit }, FTSubmission) {
			try {
				let result = await fundstransfer.completeFTSubmission(
					FTSubmission
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitFundingSource({ commit }, fundingSource) {
			try {
				let result = await fundstransfer.submitFundingSource(
					fundingSource
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getFundingSource({ commit }, fundingSource) {
			try {
				let result = await fundstransfer.getFundingSource(
					fundingSource
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async editBillingType({ commit }, billingType) {
			try {
				let result = await fundstransfer.editBillingType(billingType);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getBillingType({ commit }, billingType) {
			try {
				let result = await fundstransfer.getBillingType(billingType);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async verifyAccountNumber({ commit }, accountNumber) {
			try {
				let result = await fundstransfer.verifyAccountNumber(
					accountNumber
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getBankList({ commit }, bankList) {
			try {
				let result = await fundstransfer.getBankList(bankList);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async checkBankBalance({ commit }, bankBalance) {
			try {
				let result = await fundstransfer.checkBankBalance(bankBalance);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
    async initiateSingleTransfer({}, data) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      return await fundstransfer.initiateSingleTransfer(data);
    },
		async initiateTransfer({ commit }, transferDetails) {
			apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
			try {
				let result = await fundstransfer.initiateTransfer(
					transferDetails
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async submitBusinessSettings({ commit }, settings) {
			try {
				let result = await fundstransfer.submitBusinessSettings(
					settings
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getBusinessSettings({ commit }, settings) {
			try {
				let result = await fundstransfer.getBusinessSettings(settings);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async finalizeTransfer({ commit }, OTPDetails) {
			try {
				let result = await fundstransfer.finalizeTransfer(OTPDetails);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getAllTransactions({ commit }, Transactions) {
			try {
				let response = await fundstransfer.getAllTransactions(
					Transactions
				);

				console.log("getAllTransactions:", response);
				let transactionList = response.data.results.map(
					(transaction) => {
						return {
							customer_generated_reference:
								transaction.customer_generated_reference,
							transaction_reference:
								transaction.transaction_reference,
							transaction_status: transaction.transaction_status,
							amount: transaction.amount / 100,
							created_at: transaction.created_at,
							recipient_account_name:
								transaction.recipient_account_name,
							id: transaction.id,
						};
					}
				);

				commit("SET_TRANSACTIONS", transactionList);

				return response;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getSingleTransaction({ commit }, Transaction) {
			try {
				let result = await fundstransfer.getSingleTransaction(
					Transaction
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async getAllBeneficiaries({ commit }, Beneficiaries) {
			try {
				let result = await fundstransfer.getAllBeneficiaries(
					Beneficiaries
				);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async encryptData({}, data) {
			try {
				return await fundstransfer.encryptData(data);
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async decryptData({}, data) {
			try {
				return await fundstransfer.decryptData(data);
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async fetchKeys({ commit }, bizInfo_id) {
			try {
				let result = await fundstransfer.fetchKeys(bizInfo_id);
				let authKeys = result.data.results;
				commit("SET_AUTH_KEYS", authKeys);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async fetchAllBeneficiaries({ commit }, data) {
			try {
				let result = await fundstransfer.fetchAllBeneficiaries(data);
				return result;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async verifyAccountsBulk({}, data) {
			try {
				return await fundstransfer.verifyAccountsBulk(data);
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
		async initiateBulkTransfer({}, data) {
      return await fundstransfer.initiateBulkTransfer(data);
		},
	},
};
