<template>
  <div>
    <Loader v-if="isFetchingSettings == true || isLoading == true" />
    <AuthWrapper v-if="isLoading == false && isFetchingSettings == false">
      <div class="card-login register">
        <a href="index.php" class="logo">
          <img
            :src="WLsettings.logo_url ? WLsettings.logo_url: '/assets/img/blusalt-logo-sb-inverse.svg'"
            alt
          />
        </a>
        <h2>Building the future of financial services</h2>
        <h4>Fill the form to create your account</h4>

        <form @submit.prevent="performRegistration">
          <div class="row">
            <!-- First name field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Firstname
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  class="form-control input-bordered"
                  v-model="customerDetails.firstname"
                  placeholder="Enter your first name"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.firstname.$error"
                >
                  <li class="parsley-required">Firstname is required.</li>
                </ul>
              </div>
            </div>

            <!-- last name field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Lastname
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="text"
                  name="lastname"
                  class="form-control input-bordered"
                  v-model="customerDetails.lastname"
                  placeholder="Enter your last name"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.lastname.$error"
                >
                  <li class="parsley-required">Lastname is required.</li>
                </ul>
              </div>
            </div>

            <!-- Email field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Phone number
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="tel"
                  name="phone"
                  class="form-control input-bordered"
                  v-model="customerDetails.phoneNumber"
                  placeholder="Enter your phone number"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.phoneNumber.$error"
                >
                  <li class="parsley-required">Phone number is required.</li>
                </ul>
              </div>
            </div>

            <!-- Email field -->
            <div class="col-md-12">
              <div class="form-group">
                <label class="input-item-label">
                  Email
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  disabled
                  type="email"
                  name="email"
                  class="form-control input-bordered"
                  v-model="customerDetails.email"
                  placeholder="Enter your email"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.email.$error"
                >
                  <li class="parsley-required">Email is required.</li>
                </ul>
              </div>
            </div>

            <!-- Address field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Address
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  class="form-control input-bordered"
                  v-model="customerDetails.address"
                  placeholder="Enter your address"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.address.$error"
                >
                  <li class="parsley-required">Address is required.</li>
                </ul>
              </div>
            </div>

            <!-- City field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  City
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control input-bordered"
                  v-model="customerDetails.city"
                  placeholder="Enter your city"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.city.$error"
                >
                  <li class="parsley-required">City is required.</li>
                </ul>
              </div>
            </div>

            <!--Country field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Country
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <v-select
                  class="v-select"
                  placeholder="Select a country"
                  v-model="customerDetails.country"
                  @keydown.enter.native.prevent
                  :required="!customerDetails.country"
                  :options="metaData.countries"
                  @input="changeState"
                  label="name"
                ></v-select>
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.country.$error"
                >
                  <li class="parsley-required">Country is required.</li>
                </ul>
              </div>
            </div>

            <!-- State field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  State
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <v-select
                  class="v-select"
                  placeholder="Select a state"
                  v-model="customerDetails.state"
                  @keydown.enter.native.prevent
                  :disabled="!customerDetails.country"
                  :required="!customerDetails.state"
                  :options="states"
                  label="state_label"
                ></v-select>
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.state.$error"
                >
                  <li class="parsley-required">State is required.</li>
                </ul>
              </div>
            </div>

            <!--Password field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Password
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="password"
                  autocomplete
                  class="form-control input-bordered"
                  v-model="customerDetails.password"
                  placeholder="Enter a password"
                />
                <ul
                  class="parsley-errors-list filled"
                  id="parsley-id-26"
                  v-show="$v.customerDetails.password.$error"
                >
                  <li class="parsley-required">Password must be a minimum of 6 charachers.</li>
                </ul>
              </div>
            </div>

            <!-- Confirm password field -->
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">
                  Confirm Password
                  <span class="text-danger" title="This field is required">*</span>
                </label>
                <input
                  type="password"
                  autocomplete
                  class="form-control input-bordered"
                  v-model.lazy="customerDetails.confirmpassword"
                  placeholder="Confrim the password"
                />
                <ul class="parsley-errors-list filled" id="parsley-id-26">
                  <li
                    class="parsley-required"
                    v-if="$v.customerDetails.confirmpassword.$error"
                  >Field is required and must be the same as the password.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="gaps-2x"></div>
          <button
            class="btn btn-lg btn-primary btn-block mg-b-20"
            type="submit"
            :disabled="submitting == true"
          >
            <span v-if="submitting == true">Creating account</span>
            <span v-else>Submit</span>
          </button>
          <p>
            Already have an account?
            <router-link to="/">Login</router-link>
          </p>
        </form>
      </div>
    </AuthWrapper>
  </div>
</template>

<script>
import Nprogress from 'nprogress'
import { required, minLength, alphaNum, sameAs, email } from 'vuelidate/lib/validators'
import AuthWrapper from '@/components/auth/AuthWrapper'
import { notifications } from '../../public/assets/mixins/notifications'
import { whitelabelMixin } from "../../public/assets/mixins/settings";


export default {
  name: 'Register',
  mixins: [notifications, whitelabelMixin],
  components: {
    AuthWrapper
  },

  data() {
    return {
      isLoading: true,
      states: [],
      metaData: {},
      submitting: false,
      failOvers: ['Yes', 'No'],
      customerDetails: {
        firstname: '',
        lastname: '',
        organisation: '',
        phoneNumber: '',
        email: '',
        services: '',
        address: '',
        city: '',
        state: '',
        country: '',
        password: '',
        confirmpassword: '',
        hasFailOvers: '',
        failOverOptions: ''
      }
    }
  },
  watch: {
    'customerDetails.services'(newVal, old) {
      let newServiceArray, failOverServices, missingIndex
      if (newVal) newServiceArray = newVal.map(service => service.id)

      if (this.customerDetails.failOverOptions) {
        failOverServices = this.customerDetails.failOverOptions.map(service => service.id)
      }

      if (newServiceArray && failOverServices) {
        for (let index = 0; index < failOverServices.length; index++) {
          if (!newServiceArray.includes(failOverServices[index])) {
            missingIndex = index
            break
          }
        }
        this.customerDetails.failOverOptions.splice(missingIndex, 1)
      }
    }
  },
  validations: {
    customerDetails: {
      firstname: {
        required
      },
      lastname: {
        required
      },
      email: {
        required, email
      },
      phoneNumber: {
        required,
        minLength: minLength(11),
      },
      address: {
        required
      },
      city: {
        required
      },
      country: {
        required
      },
      state: {
        required
      },
      password: {
        required,
        minLength: minLength(6)
      },
      confirmpassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    changeState() {
      this.customerDetails.state = ''
      if (!this.customerDetails.country) {
        this.states = []
      } else {
        this.states = this.customerDetails.country.states || []
      }
    },

    failOverOptions() {
      if (this.customerDetails.services) {
        return this.customerDetails.services
      }
    }
  },
  methods: {
    formatRequestData() {
      let requestData = new FormData()

      if (this.customerDetails.services) {
        this.customerDetails.services.map(service => {
          requestData.append('services', service.id)
        })
      }
      // if (this.customerDetails.hasFailOvers == 'Yes') {
      //   this.customerDetails.failOverOptions.map((service) => { requestData.append('fail_over_services', service.id) })
      // }

      requestData.append('firstname', this.customerDetails.firstname)
      requestData.append('lastname', this.customerDetails.lastname)
      requestData.append('email', this.customerDetails.email)
      requestData.append('address', this.customerDetails.address)
      requestData.append('phone_number', this.customerDetails.phoneNumber)
      requestData.append('city', this.customerDetails.city)
      requestData.append('token', this.$route.params.token)

      // requestData.append('fail_over_services', this.customerDetails.password)
      requestData.append('state_id', this.customerDetails.state ? this.customerDetails.state.id : '')
      requestData.append('country_id', this.customerDetails.country ? this.customerDetails.country.id : '')
      requestData.append('password', this.customerDetails.password)

      return requestData
    },
    async getEmail(){
      let { data } = await this.$store.dispatch('auth/getInviteEmail', this.$route.params.token)
      console.log(data)
      return data.email;
    },
    async performRegistration() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('[invalid fields] ->')
        return
      }

      Nprogress.start()
      this.submitting = true
      let formattedResponse = this.formatRequestData()
      console.log('[formatted response ] ->', formattedResponse)
      try {
        let { data } = await this.$store.dispatch('auth/registerInviteUser', formattedResponse)
        this.showSuccessNotification(data.message)
        this.$swal({
          title: `Customer successfully registered`,
          text: 'You account has been created. Please proceed to login',
          icon: 'success',
          button: 'Ok'
        }).then(value => {
          this.$router.push('/login')
        })
        console.log('[data from registration] ->', data)
        Nprogress.done()
      } catch (error) {
        Nprogress.done()
        this.submitting = false
        console.log('error :', error)
        this.showErrorLoginNotification(error.message)
      }
    }
  },
  async mounted() {
    try {
      let apiResponse = await this.$store.dispatch('getMetaData')
      this.metaData = apiResponse.results
      this.customerDetails.email = await this.getEmail();
      this.isLoading = false
    } catch (error) {
      console.log('[Error] ->', error)
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.card-login.register {
  min-width: 330px;
  max-width: 950px;
}
</style>
