<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b">
          <div class="SectionHeader">
            <span class="title">Account</span>
            <div class="description">Customize advanced settings for conversations</div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {};
</script>
