<template>
	<div>
		<img
			@click="openEditItem"
			class="pointer"
			src="/assets/img/icons/edit.png"
			alt="update billing address"
			width="20px"
		/>

		<UpdateCustomerAddressModal
			:id="customer.reference"
			:closeModal="closeModal"
			:customer="customer"
			:updateAddress="updateAddress"
		/>
	</div>
</template>

<script>
import UpdateCustomerAddressModal from "../../../../wallet/Customers/modals/UpdateCustomerAddressModal/UpdateCustomerAddressModal";

export default {
	name: "UpdateBillingAddressButton",
	props: ["customer", "updateAddress"],
	methods: {
		openEditItem() {
			$(`#${this.customer.reference}`).modal("show");
		},
		closeModal() {
			$(`#${this.customer.reference}`).modal("hide");
		},
	},
	components: {
		UpdateCustomerAddressModal,
	},
};
</script>
