import crypto from "crypto";

export function encrypt(secret_key, data) {
  function stringToKey(inputString) {
    const hash = crypto.createHash("sha256");
    hash.update(inputString);
    return hash.digest().slice(0, 32).toString("hex"); // Get the first 32 bytes (256 bits)
  }

  const algorithm = "aes-256-cbc";
  const keysss = stringToKey(secret_key);

  const key = Buffer.from(keysss, "hex");
  const iv = crypto.randomBytes(16);
  let base64Iv = iv.toString("base64");

  const cipher = crypto.createCipheriv(algorithm, key, iv);

  let encrypted = cipher.update(data, "utf8", "base64");
  encrypted += cipher.final("base64");

  return { base64Iv, data: encrypted };
}

export function decrypt(iv, secretKey, data) {
  function stringToKey(inputString) {
    const hash = crypto.createHash("sha256");
    hash.update(inputString);
    return hash.digest().slice(0, 32).toString("hex"); // Get the first 32 bytes (256 bits)
  }

  const algorithm = "aes-256-cbc";
  const secret_key = stringToKey(secretKey);
  const key = Buffer.from(secret_key, "hex");
  const ivAsBuffer = Buffer.from(iv, "base64");

  const decipher = crypto.createDecipheriv(algorithm, key, ivAsBuffer);

  // Encrypted using same algorithm, key and iv.
  const encrypted = data;

  let decrypted = decipher.update(encrypted, "base64", "utf8");
  decrypted += decipher.final("utf8");

  return JSON.parse(decrypted);
}
