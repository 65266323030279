<template>
  <AuthWrapper>
    <div class="card-login">
      <a href="index.php" class="logo">
        <img src="/assets/img/blusalt-logo-sb-inverse.svg" alt />
      </a>
      <div class="d-flex justify-content-center" v-if="isProcessing == true">
        <Loader></Loader>
      </div>
      <div
        v-if="isProcessing == false && userDetails.hasOwnProperty('firstname')"
        class="activate-header"
        style="padding-bottom: 20px; padding-left: 10px; padding-right: 10px"
      >
        <p>
          Dear
          <span class="activate-name">{{userDetails.firstname}} {{userDetails.lastname}}</span>,
        </p>
        <p>
          Welcome to Blusalt. Below are the details we have on record for your account.
          <br />
        </p>

        <div class="activate-content">
          <label class="tx-gray-600">Customer Information</label>
          <p class="invoice-info-row">
            <span>Firstname</span>
            <span>{{userDetails.firstname}}</span>
          </p>
          <p class="invoice-info-row">
            <span>Lastname</span>
            <span>{{userDetails.lastname}}</span>
          </p>
          <p class="invoice-info-row">
            <span>Email:</span>
            <span>{{userDetails.email}}</span>
          </p>
          <p class="invoice-info-row">
            <span>City</span>
            <span>{{userDetails.city}}</span>
          </p>

          <div class="gaps-1-5x"></div>
          <a href="/" class="btn btn-lg btn-primary btn-block mg-b-20">Login</a>
        </div>
      </div>
      <div
        v-if="isProcessing == false && !userDetails.hasOwnProperty('firstname')"
        class="activate-header d-flex justify-content-center align-items-center flex-column"
      >
        <img src="/assets/img/bad-gateway.png" width="240px" class="d-flex" />
        <p class="mt-5">User Not Found Or Account Already Activated</p>
        <div class="gaps-1-5x"></div>
        <a href="/" class="btn btn-lg btn-primary btn-block mg-b-20">Login</a>
      </div>
    </div>
  </AuthWrapper>
</template>

<script>
import Nprogress from "nprogress"
import { required } from 'vuelidate/lib/validators';
import AuthWrapper from '@/components/auth/AuthWrapper';
import { notifications } from '../../public/assets/mixins/notifications';

export default {
  name: "Account-Activation",
  mixins: [notifications],
  components: {
    AuthWrapper
  },
  props: {
    token: {
      type: String
    }
  },
  data() {
    return {
      isProcessing: true,
      userDetails: {}
    }
  },
  methods: {
    async getActivationStatus() {
      try {
        let apiResponse = await this.$store.dispatch('auth/activateAccount', this.token)
        this.userDetails = apiResponse
        this.isProcessing = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false;
      }
    }
  },
  mounted() {
    this.getActivationStatus()
  }
};
</script>


<style scoped>
.activate-header {
  font-size: 16px;
}
.activate-name {
  font-size: 16px;
  font-weight: 800;
}
.invoice-info-row span {
  font-size: 16px;
}
</style>