
export const shareHolders = {
  total: 1,
  count: 1,
  data: [
    {
      name: "Four Windows And A Shovel Properties Limited",
      designation: "Shareholder",
      action: "",
    },
    {
      name: "Stanbic Ibtc Trustees Limited",
      designation: "Shareholder",
      action: "",
    },
  ],
  totalPages: 1,
  currentPage: 1,
};

export const serviceTypes = [
  { name: "Antimoney Laundering", value: "Antimoney Laundering" },
  { name: "Business Verification", value: "Business Verification" },
  { name: "NIN Verification", value: "NIN Verification" },
];

export 
const detailsHeadings = {
  registration_number: "registration_number",
  type: "type",
  registration_date: "registration_date",
  company_name: "company_name",
  company_type: "company_type",
  email: "email",
  status: "status",
  status: "status",
  state: "state",
  lga: "lga",
  address: "address",
  share_capital: "share_capital",
  share_capital_in_words: "share_capital_in_words",
  affiliates: "affiliates",
  created_at: "created_at",
  updated_at: "updated_at",
  class: "class",
  former_name: "former_name",
  vat_number: "vat_number",
  registration_submission_date: "registration_submission_date",
  date_disolved: "date_disolved",
  tin: "tin",
  jtb_tin: "jtb_tin",
  tax_office: "tax_office",
  website_email: "website_email",
  city: "city",
  branch_address: "branch_address",
  head_office_address: "head_office_address",
  objectives: "objectives",
  last_updated_at: "last_updated_at",
  subscribed_share_capital: "subscribed_share_capital",
  shares_value: "shares_value",
  activity_description: "activity_description",
  shares_issued: "shares_issued",
  parent_country: "parent_country",
  country: "country",
  country_code: "country_code",
  business_id: "business_id",
  company_contact_persons: "company_contact_persons",
  key_personnel: "key_personnel",
  activities: "activities",
  legal_entity_identifier_register: "legal_entity_identifier_register",
  central_index_key_register: "central_index_key_register",
  filings: "filings",
  report: 'report',
};