<template>
	<div class="p-4">
		<template>
			<div class="flex__between">
				<div class="SectionItem no-b-b mb-4 flex__between">
					<div class="d-flex">
						<img
							src="/assets/img/icons/arrow-left.svg"
							width="30"
							alt=""
							class="pointer"
							@click="$router.push('/wallet/payment')"
						/>
						<p class="title ml-3 mb-0">Payment Collections</p>
					</div>
					<EditPaymentCollectionButton />
				</div>
			</div>

			<template>
				<div class="content__slider">
					<TabsV2
						:tabNames="tabNames($route.params.reference)"
						:tabId="'paymentPageTab'"
						:tabLink="true"
					/>
				</div>

				<transition mode="out-in" name="slide">
					<router-view />
				</transition>
			</template>
		</template>
	</div>
</template>

<script>
import TabsV2 from "@/utils/Tabs/TabsV2";
import EditPaymentCollectionButton from "../../buttons/EditPaymentCollectionButton/EditPaymentCollectionButton";

const tabNames = (id) => [
	{
		tabName: "Page Information",
		value: "page_information",
		link: `/wallet/payment/collection/${id}/information`,
	},
	{
		tabName: "Page Transactions",
		value: "page_transactions",
		link: `/wallet/payment/collection/${id}/transactions`,
	},
];

export default {
	name: "PaymentCollectionDetails",
	data() {
		return {
			reference: "",
			loading: false,
			errorMsg: "",
			collection: {},
		};
	},
	components: {
		TabsV2,
		EditPaymentCollectionButton,
	},
	methods: {
		tabNames,
	},
};
</script>
