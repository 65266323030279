<template>
  <Modal :id="id" :title="'Invite Agent'" :toggle="closeModal">
    <form @submit.prevent="handleSubmit">
      <div>
        <div class="row">
          <div class="col-md-6 mb-2">
            <CustomInput
              :name="'firstName'"
              :label="'First Name'"
              :placeholder="'Type here'"
              :required="true"
              v-model="agentForm.firstName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.firstName.$error"
            >
              <li class="parsley-required">First Name is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :name="'lastName'"
              :label="'Last Name'"
              :placeholder="'Type here'"
              :required="true"
              v-model="agentForm.lastName"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.lastName.$error"
            >
              <li class="parsley-required">Last Name is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :name="'email'"
              :label="'Email'"
              :placeholder="'Type here'"
              :required="true"
              v-model="agentForm.email"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.email.$error"
            >
              <li class="parsley-required">Email is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomPhoneInput
              label="Phone Number"
              v-model="agentForm.phoneNumber"
              @blur="$v.agentForm.phoneNumber.$touch()"
              placeholder="Enter Phone Number"
              :error="
                $v.agentForm.phoneNumber.$error &&
                formVuelidator($v.agentForm.phoneNumber)
              "
            />
          </div>
          <div class="col-md-12 mb-2">
            <CustomInput
              :name="'address_1'"
              :placeholder="'Enter Address'"
              :label="'Address 1'"
              :required="true"
              v-model="agentForm.address_1"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.address_1.$error"
            >
              <li class="parsley-required">Address 1 is required.</li>
            </ul>
          </div>
          <div class="col-md-12 mb-2">
            <CustomInput
              :name="'address_2'"
              :placeholder="'Enter Address 2'"
              :label="'Address 2'"
              v-model="agentForm.address_2"
            />
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :name="'city'"
              :label="'City'"
              :placeholder="'Type here'"
              :required="true"
              v-model="agentForm.city"
            />
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.city.$error"
            >
              <li class="parsley-required">City is required.</li>
            </ul>
          </div>
          <div class="col-md-6 mb-2">
            <CustomInput
              :name="'zip_code'"
              :maxlength="11"
              :placeholder="'Zip Code'"
              :label="'Enter Zip Code'"
              :isNum="true"
              v-model="agentForm.zip_code"
            />
          </div>
          <div class="col-sm-6 mb-2">
            <div class="form-group">
              <label class="input-item-label"
                >Country
                <span class="text-danger" title="This field is required"
                  >*</span
                ></label
              >
              <v-select
                class="v-select"
                placeholder="Select a country"
                :options="metaData.countries"
                label="name"
                @input="changeState"
                @keydown.enter.native.prevent
                name="country"
                v-model="agentForm.country"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.country.$error"
            >
              <li class="parsley-required">Country is required.</li>
            </ul>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label class="input-item-label"
                >State
                <span class="text-danger" title="This field is required"
                  >*</span
                ></label
              >
              <v-select
                class="v-select"
                placeholder="Select a state"
                v-model="agentForm.state"
                @keydown.enter.native.prevent
                :disabled="!agentForm.country"
                :options="states"
                label="state_label"
              ></v-select>
            </div>
            <ul
              class="parsley-errors-list filled"
              v-show="$v.agentForm.state.$error"
            >
              <li class="parsley-required">State is required.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex__between mt-4">
        <button
          type="button"
          class="btn btn-outline w-100 mr-3"
          @click="handleClose"
        >
          <span>Cancel</span>
        </button>
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100 ml-3"
          :disabled="loading"
        >
          <Spinner v-if="loading" />
          <span v-else>Create</span>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../utils/Modal/Modal";
import Spinner from "../../../../../utils/Spinner/Spinner.vue";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
import { newCountryFormat } from "@/assets/js/countryDailcodes";
import DatePicker from "@/utils/DatePicker/DatePicker";
import { getWalletApikey, getError } from "@/utils/helpers";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  mixins: [notifications],
  name: "InviteAgentModal",
  data() {
    return {
      newCountryFormat,
      agentForm: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: { normal: "" },
        address_1: "",
        city: "",
        country: "",
        state: "",
      },
      loading: false,
      states: [],
    };
  },
  validations: {
    agentForm: {
      firstName: { required },
      lastName: { required },
      email: { required },
      phoneNumber: {
        required,
        isPhoneValid: isPhoneValid("agentForm.phoneNumber"),
      },
      address_1: { required },
      city: { required },
      country: { required },
      state: { required },
    },
  },
  props: ["id", "closeModal", "refetch"],
  components: {
    Modal,
    CustomInput,
    CustomSelect,
    DatePicker,
    Spinner,
    CustomPhoneInput,
  },

  methods: {
    formVuelidator,
    clearInputFields() {
      this.agentForm = {};
      this.loading = false;
    },
    changeState() {
      this.states = this.agentForm.country.states;
    },
    handleClose() {
      this.closeModal();
    },
    async handleSubmit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;

      try {
        let response = await this.$store.dispatch("pos/addAgent", {
          payload: {
            ...this.agentForm,
            phoneNumber: this.agentForm.phoneNumber.normal,
            country: this.agentForm.country.name,
            state: this.agentForm.state.state_label,
            phoneCode: this.agentForm.phoneNumber.countryCallingCode,
          },
          apiKey: getWalletApikey(this.$store),
        });
        this.showSuccessNotification(response.data.message);
        setTimeout(() => {
          this.handleClose();
          this.loading = false;
          this.clearInputFields();
          this.refetch({});
        }, 1000);
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.loading = false;
        this.showErrorLoginNotification(getError(error));
      }
    },
  },
  computed: {
    metaData() {
      return this.$store.state.metaData;
    },
  },
  mounted() {
    this.states = this.metaData.countries[0].states;
  },
};
</script>
