<template>
  <div class="card pt-3">
    <div class="row">
      <div class="col-12 mt-3">
        
        <div class="px-3">
          <FilterWrapper>
            <template slot="pageSelect"><div class="flex__end"><AddSenderButton :refetch="refetch" /></div> </template>
            <template slot="filterForm">
              <SenderTableFilters
                :loading="loading"
                :rLoading="rLoading"
                :submitFilter="refetch"
                :clearFilter="clearFilter"
                :download="downloadSenders"
              />
            </template>
          </FilterWrapper>
        </div>
      </div>
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div v-else-if="errorMsg" class="col-12">
        <FailedState :errorMsg="errorMsg" :refetch="refetch" />
      </div>

      <div class="col-12" :class="tbClass">
        <v-server-table
          ref="planRef"
          class="v-table row-pointer"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
          @row-click="handlClick"
        >
          <template slot="sender" slot-scope="{ row }">
            <span>
              {{ `${row.firstName} ${row.lastName}` }}
            </span>
          </template>
          <template slot="created_at" slot-scope="{ row }">
            <span>
              {{
                timeDateFormat(row.created_at).date +
                " " +
                timeDateFormat(row.created_at).time
              }}
            </span>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import AccessControl from "@/utils/AccessControl/AccessControl";
import SenderTableFilters from "./SenderTableFilters/SenderTableFilters";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  getWalletApikey,
} from "@/utils/helpers";
import AddSenderButton from "../buttons/AddSenderButton/AddSenderButton.vue";
import { senderCsvRows } from "../enums";

const tableHeadings = {
  customerReference: "Sender Id",
  sender: "Name",
  email: "Email",
  country: "Country",
  phoneNumber: "Phone",
  created_at: "Created At",
};

export default {
  mixins: [componentData],
  name: "Sender",
  data() {
    return {
      loading: false,
      rLoading: false,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 20),
    };
  },
  components: {
    AddSenderButton,
    SenderTableFilters,
    FilterWrapper,
    AccessControl,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    timeDateFormat,
    titleCase,
    handlClick({ row }) {
      this.$router.push(`/wallet/remittance/sender/${row.customerReference}`);
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getSenders();
      this.$refs.planRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.planRef.refresh();
    },
    async getSenders() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch("remittance/getSenders", payload)
          ).data.data;
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
    async downloadSenders() {
      const download = (filterData) =>
        this.downloadReport(senderCsvRows, filterData.data.data);
      this.downloadTableReport("remittance/getSenders", download);
    },
  },

  created() {
    this.getSenders();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
