<template>
	<div class="row">
		<div v-if="loading" class="col-12">
			<div class="flex__center my-5 py-5">
				<Spinner :size="'30px'" />
			</div>
		</div>

		<div v-else-if="errorMsg" class="col-12">
			<FailedState :errorMsg="errorMsg" :refetch="refetch" />
		</div>

		<div v-else-if="isEmpty" class="col-12">
			<EmptyData :title="emptyTitle" :text="emptyText">
				<slot name="empty" />
			</EmptyData>
		</div>

		<div v-else class="col-12">
			<slot name="content" />
		</div>
	</div>
</template>

<script>
import Spinner from "../Spinner/Spinner";
import FailedState from "../ResponseState/FailedState";
import EmptyData from "../EmptyData/EmptyData";

export default {
	name: "ContentWrapper",
	props: [
		"loading",
		"errorMsg",
		"isEmpty",
		"emptyText",
		"emptyTitle",
		"refetch",
	],
	components: {
		Spinner,
		FailedState,
		EmptyData,
	},
};
</script>
