<template>
	<div v-if="metadata && Object.keys(metadata).length > 0">
		<div v-if="metadata.source" class="information">
			<p>Source</p>
			<span>{{ metadata.source }}</span>
		</div>

		<template v-if="metadata.source_account">
			<div
				v-if="metadata.source_account.account_number"
				class="information"
			>
				<p>Account Number</p>
				<span>{{ metadata.source_account.account_number }}</span>
			</div>
			<div
				v-if="metadata.source_account.account_name"
				class="information"
			>
				<p>Account Name</p>
				<span>{{ metadata.source_account.account_name }}</span>
			</div>
			<div v-if="metadata.source_account.bank_name" class="information">
				<p>Bank Name</p>
				<span>{{ metadata.source_account.bank_name }}</span>
			</div>
		</template>

		<template v-if="metadata.destination">
			<div v-if="metadata.destination.recipient" class="information">
				<p>Account Number</p>
				<span>
					{{ metadata.destination.recipient }}
				</span>
			</div>
			<div v-if="metadata.destination.recipient_name" class="information">
				<p>Account Name</p>
				<span>
					{{ metadata.destination.recipient_name }}
				</span>
			</div>

			<div v-else-if="metadata.destination.bank_code" class="information">
				<p>Bank Name</p>
				<span>
					{{ bankName }}
				</span>
			</div>
			<div v-if="metadata.destination.type" class="information">
				<p>Access Medium</p>
				<span>
					{{ titleCase(metadata.destination.type) }}
				</span>
			</div>
		</template>

		<template v-else-if="metadata.account_number">
			<h5 class="font-weight-bold mt-4">Recipients Details</h5>
			<div class="information">
				<p>Account Number</p>
				<span>{{ metadata.account_number }}</span>
			</div></template
		>

		<template v-if="metadata.card">
			<h5 class="font-weight-bold mt-2">Sender Details</h5>
			<div v-if="metadata.card.scheme" class="information">
				<p>Access Medium</p>
				<span>Card</span>
			</div>

			<div v-if="metadata.card.last4" class="information">
				<p>Card Number</p>
				<span>**** **** **** {{ metadata.card.last4 }}</span>
			</div>

			<div v-if="metadata.card.scheme" class="information">
				<p>Card Type</p>
				<span>{{ metadata.card.scheme }}</span>
			</div>

			<div v-if="metadata.cardholder" class="information">
				<p>Account Name</p>
				<span>
					{{ metadata.card.cardholder || "Not Available" }}
				</span>
			</div>
			<div v-if="metadata.card.bankName" class="information">
				<p>Bank Name</p>
				<span>
					{{ metadata.card.bankName || "Not Available" }}
				</span>
			</div>
		</template>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { banks } from "../../../../dataset";
import { titleCase, timeDateFormat, toCurrency } from "@/utils/helpers";

export default {
	mixins: [notifications],
	name: "PaymentRefundMeta",
	props: {
		metadata: {
			type: Object,
		},
	},
	computed: {
		bankName() {
			if (this.metadata.destination) {
				const { bank_code } = this.metadata.destination;
				const bank = banks.find((v) => v.code === bank_code);
				return titleCase(bank.name);
			}
			return "";
		},
	},
	methods: {
		titleCase,
		timeDateFormat,
		toCurrency,
	},
};
</script>
