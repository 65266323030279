<template>
    <Modal :id="id" :title="title" :toggle="closeModal">
        <SuccessState :message="message" :onSuccess="onSuccess" />
    </Modal>
</template>

<script>
import Modal from "../Modal";
import SuccessState from "../../ResponseState/SuccessState";

export default {
    name: "SuccessModal",
    props: ["id", "closeModal", "title", "message", "onSuccess"],
    components: {
        Modal,
        SuccessState,
    },
};
</script>
