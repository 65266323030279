<template>
  <div
    class="tab-pane fade row"
    id="sms_logs"
    role="tabpanel"
    aria-labelledby="sms_logs-tab"
  >
    <div v-if="loading" class="col-12">
      <div class="flex__center my-5 py-5">
        <Spinner :size="'30px'" />
      </div>
    </div>

    <div v-else-if="errorMsg" class="col-12">
      <FailedState :errorMsg="errorMsg" :refetch="refetch" />
    </div>

    <div class="col-12 pt-3" :class="tbClass">
      <div class="px-3">
        <FilterWrapper>
          <template slot="filterForm">
            <SMSFilters
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="() => clearFilter('couponRef')"
              :download="downloadLogs"
            />
          </template>
        </FilterWrapper>
      </div>
      <TableWrapper
        :loading="loading"
        :errorMsg="errorMsg"
        :refetch="refetch"
        :isEmpty="isEmpty"
        :emptyTitle="emptyTitle"
      >
        <template slot="content">
          <v-server-table
            ref="couponRef"
            class="v-table"
            :columns="columns"
            :options="options"
            @loading="setLoading(true)"
            @loaded="setLoading(false)"
            @error="setError"
            @row-click="showData"
          >
            <template slot="createdAt" slot-scope="{ row }">
              {{ timeDateFormat(row.createdAt).date }}{{ " "
              }}{{ timeDateFormat(row.createdAt).time }}
            </template>
            <template slot="status" slot-scope="{ row }">
              <span
                :class="
                  statusColor(row.status)
                "
                >{{ row.status.toUpperCase() }}</span
              >
            </template>
          </v-server-table>

          <SmsDetailModal
            v-if="showModal"
            :data="modalData"
            @close-modal="closeModal"
          />
        </template>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import SmsDetailModal from "./SmsDetailModal";
import SMSFilters from "./Filters/SMSFilters.vue";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  formatNumber,
  statusColor,
} from "@/utils/helpers";

const tableHeadings = {
  referenceId: "Reference",
  number: "Number",
  senderId: "Sender Id",
  status: "Status",
  // message: "Message",
  createdAt: "Date Created",
};

export default {
  mixins: [componentData],
  name: "SmsLogs",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: true,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...tableOptions(tableHeadings, 20),
      stats: null,
      emptyTitle: "No Log Found",
    };
  },
  components: {
    AccessControl,
    FilterWrapper,
    FailedState,
    Spinner,
    SmsDetailModal,
    SMSFilters,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    statusColor,
    showData({ row }) {
      this.showModal = true;
      this.modalData = row;
      console.log("first", row, this.showModal);
    },
    formatNumber,
    closeModal(e) {
      this.showModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getSmsLogs();
      this.$refs.couponRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.couponRef.refresh();
    },
    async getSmsLogs() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch("logs/getSMSLogs", {
          params,
        });
        const newData = response.data.data;
        const metaData = response.data.meta;
        return {
          count: metaData.total_items,
          total: metaData.total_items,
          totalPages: metaData.total_page,
          data: newData,
        };
      };
    },
    async downloadLogs() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.data);
      };
      this.downloadTableReport("logs/getSMSLogs", download);
    },
  },

  created() {
    this.getSmsLogs();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
