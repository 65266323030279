<template>
  <Modal
    :id="id"
    :title="`Delete ${item.search_name || item.company_name}`"
    :toggle="handleCloseModal"
    :loading="loading"
  >
    <SuccessState
      v-if="successMsg"
      :message="'Report Deleted Successfully'"
      :onSuccess="handleRefetch"
    />
    <div v-else-if="loading" class="my-5">
      <Spinner :size="'20px'" />
    </div>

    <form v-else @submit.prevent="deleteItem">
      <h4 class="text-center my-4">
        Are you sure you want to delete
        <b>{{ item.search_name || item.company_name }}</b
        >?
      </h4>

      <FormButtons
        :btnName="'Delete'"
        :primary="false"
        :loading="loading"
        :handleCloseModal="handleCloseModal"
      />
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../public/assets/mixins/filters";

const defaultValue = () => ({
  loading: false,
  successMsg: false,
});

export default {
  mixins: [notifications, filters],
  name: "DeleteReportModal",
  data() {
    return { ...defaultValue() };
  },
  props: ["id", "item", "closeModal"],
  methods: {
    handleCloseModal() {
      Object.assign(this.$data, { ...defaultValue() });
      this.closeModal();
    },
    handleRefetch() {
      this.handleCloseModal();
      this.$router.push('/services/reports')
    },
    setError(msg) {
      this.loading = false;
      this.showErrorLoginNotification(msg);
    },
    async deleteItem() {
      try {
        this.loading = true;

        const response = await this.$store.dispatch("services/deleteReport", {
          params: {
            id: this.$route.params.id,
          },
        });

        const { status, data } = response || {};
        if (status === 200 && data.status_code) {
          this.loading = false;
          this.successMsg = true;
        } else this.setError(data.message);
      } catch (error) {
        this.setError(this.getError(error));
      }
    },
  },
};
</script>
