<template>
	<Modal
		:id="id"
		:title="'Add New Team Member'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Team Member Added Successfully'"
			:onSuccess="handleCloseModal"
		/>

		<form v-else @submit.prevent="handleSubmit">
			<CustomInput
				:name="'email'"
				v-model="request.email"
				:label="'Email'"
				:placeholder="'Enter Email'"
				:required="true"
				:error="formErrors.email"
				:handleError="handleError"
			/>
			<CustomSelect
				name="role"
				label="Role"
				placeholder="Select Role"
				:optionLabel="'name'"
				:options="roles"
				v-model="request.role"
				:required="true"
				:error="formErrors.role"
				:handleError="handleError"
			/>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import { ACCOUNT_ROLES, rolesOwners, rolesAdmin } from "@/constants/roles";
import Modal from "@/utils/Modal/Modal";
import FormButtons from "@/utils/FormButtons/FormButtons";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import Spinner from "@/utils/Spinner/Spinner";

const initialState = () => ({
	request: { role: "", email: "" },
	loading: false,
	successMsg: false,
	formErrors: {},
});

export default {
	mixins: [notifications],
	name: "AddTeamModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal"],
	components: {
		Modal,
		CustomSelect,
		CustomInput,
		SuccessState,
		Spinner,
		FormButtons,
	},
	computed: {
		clientID() {
			return this.$store.state.account.clientID;
		},
		roles() {
			const accountRole = localStorage.getItem("accountRole");
			const { ADMIN, OWNER } = ACCOUNT_ROLES || {};
			return accountRole == ADMIN
				? rolesAdmin
				: accountRole == OWNER
				? rolesOwners
				: [];
		},
	},
	methods: {
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleSubmit(e) {
			e.preventDefault();
			const { formErrors, isValid } = formValidator(this.request);
			if (isValid) this.addTeam();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},

		async addTeam() {
			try {
				this.loading = true;
				const { role } = this.request;

				await this.$store
					.dispatch("account/addTeamMember", {
						clientID: this.clientID,
						payload: { ...this.request, role: role.value },
					})
					.then(() => {
						this.loading = false;
						this.successMsg = true;
					});
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>
