<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4"
      @click="openChagebackModal"
    >
      <img
        src="/assets/img/icons/card-tick.svg"
        alt="image"
        height="20"
        class="mr-2"
      />
      Accept
    </button>
    <AcceptChargebackModal
      id="acceptChargeback"
      :chargeback="chargeback"
      :refetch="refetch"
      :closeModal="closeChagebackModal"
    />
  </div>
</template>

<script>
import AcceptChargebackModal from "../../modals/AcceptChargebackModal/AcceptChargebackModal.vue";

export default {
  name: "AcceptChargebackBtn",
  props: ["refetch", "chargeback"],
  components: {
    AcceptChargebackModal,
  },
  methods: {
    openChagebackModal() {
      $("#acceptChargeback").modal("show");
    },
    closeChagebackModal() {
      $("#acceptChargeback").modal("hide");
    },
  },
};
</script>
