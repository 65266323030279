<template>
  <div class="col-lg-12 col-md-12">
    <div class="user-settings-section no-border">
      <div class="user-settings-section-header">
        <div class="user-settings-section-header-text">
          <h1>Request Logs</h1>
          <span class="text-span">
            View the historical data of your API Requests on any of the services
            registered against your account. menu.
          </span>
        </div>
      </div>

      <div
        class="d-flex justify-content-center align-items-center"
        style="height: 30vh"
        v-if="isFetching == true"
      >
        <Loader></Loader>
      </div>

      <DefaultContent
        v-if="isFetching == false && identificationLogs.length == 0"
        :image="'empty-resources.png'"
        :title="'No Historical API Request Data'"
        :subtitle="'Start by making an API Request in order to access the logs of your requests.'"
      ></DefaultContent>

      <div
        class="user-setting-activity-logs"
        v-if="isFetching == false && identificationLogs.length > 0"
      >
        <v-client-table
          ref="serverTableRef"
          name="question-table"
          class="v-table"
          :data="identificationLogs"
          :columns="columns"
          :options="options"
        >
          <template slot="request_time" slot-scope="props">
            <div>
              {{ props.row.request_time | moment(" MM-DD-YYYY h:mm a") }}
            </div>
          </template>
          <template slot="price" slot-scope="props">
            <div>&#8358;{{ props.row.price }}</div>
          </template>
          <template slot="status" slot-scope="props">
            <span
              v-if="props.row.status == 1"
              class="badge badge-success badge-app"
              >Successful</span
            >
            <span v-else class="badge badge-danger badge-app">failed</span>
          </template>
        </v-client-table>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
export default {
  name: "Secondary-customer-request-logs",
  props: {
    apiUser: {
      type: Object,
    },
  },
  data() {
    return {
      restofData: {},
      identificationLogs: [],
      isFetching: false,
      cardNumber: "",
      columns: [
        "request_reference",
        "identity_number",
        "request_time",
        "service_label",
        "price",
        "status",
      ],
      options: {
        sortable: [],
        perPage: 50,
        pagination: {
          chunk: 5,
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search..",
          noResults: "No matching records",
        },
        headings: {
          request_reference: "Request Reference",
          identity_number: "Identity Number",
          request_time: "Request Time",
          "service.service_label": "Service",
          price: "Price per call",
          status: "Status",
        },
      },
    };
  },

  methods: {
    async fetchLogs() {
      let verificationData = {
        clientID: this.apiUser.api_customer.client_id,
      };
      try {
        let apiResponse = await this.$store.dispatch(
          "logs/loggedInSecondaryCustomerCallLog"
        );
        console.log("[api response] ->", apiResponse);
        this.restofData = {
          ...apiResponse.charges_from_blusalt,
          ...apiResponse.total_price_charged_on_customer,
        };

        this.identificationLogs = apiResponse.secondary_customer_logs;
        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  mounted() {
    this.fetchLogs();
    // this.fetchApps()
  },
};
</script>

<style></style>
