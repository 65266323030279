<template>
	<div>
		<WalletSelect
			v-if="walletId"
			:name="'wallet'"
			:walletId="walletId"
			:error="formErrors.wallet"
			:handleError="handleError"
			v-model="wallet"
			:getFirstData="getFirstData"
			:disabled="setDefault"
		/>

		<CustomSelect
			v-else-if="app === 'account'"
			:name="'wallet'"
			v-model="wallet"
			:options="[baseWallet]"
			:getLabel="getLabel"
		/>

		<MasterWalletSelect
			v-else
			:name="'wallet'"
			:error="formErrors.wallet"
			:handleError="handleError"
			v-model="wallet"
			:getFirstData="getFirstData"
			:disabled="setDefault"
		/>

		<CustomSelect
			v-if="showSettlementAccount"
			:name="'bank_account'"
			label="Settlement Account"
			:getLabel="getLabel"
			:options="[]"
			v-model="bank_account"
			:required="true"
			:disabled="true"
		/>

		<div class="pb-4">
			<CustomInput
				:name="'amount'"
				:type="'number'"
				v-model="amount"
				@blur="$v.amount.$touch()"
				:label="'Enter Amount'"
				:placeholder="amountPlaceholder"
				:required="true"
				:error="
					formErrors.amount ||
					($v.amount.$error && formVuelidator($v.amount))
				"
				:handleError="handleError"
				:isNum="true"
			/>
		</div>

		<CustomInput
			v-show="showReturn"
			:name="'returnUrl'"
			v-model="returnUrl"
			:label="'Enter Return URL ( Optional )'"
			:placeholder="'https://blusalt.net'"
		/>

		<div class="flex__between">
			<button
				type="button"
				class="btn btn-outline w-100 mr-3"
				@click="handleClose"
			>
				<span>Cancel</span>
			</button>
			<button
				type="button"
				class="btn btn-lg btn-primary w-100 ml-3"
				:disabled="loading"
				@click="handleSubmit"
			>
				<Spinner v-if="loading" />
				<span v-else>Continue</span>
			</button>
		</div>
	</div>
</template>

<script>
import MasterWalletSelect from "@/utils/Selects/MasterWalletSelect/MasterWalletSelect";
import WalletSelect from "@/utils/Selects/WalletSelect/WalletSelect";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import Spinner from "@/utils/Spinner/Spinner";
import { minValue, required, numeric } from "vuelidate/lib/validators";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import { getWalletApikey } from "@/utils/helpers";

const initialState = () => ({
	amount: "",
	returnUrl: "",
	formErrors: {},
});

export default {
	name: "WalletForm",
	data() {
		return {
			...initialState(),
			bank_account: {},
			wallet: {},
			merchantBanks: [],
		};
	},
	validations: {
		amount: {
			required,
			numeric,
			minValue: minValue(1000),
		},
	},
	props: {
		loading: Boolean,
		showReturn: Boolean,
		miniText: {
			type: String,
			default: "Minimum of NGN1000",
		},
		showSettlementAccount: Boolean,
		amountPlaceholder: {
			type: String,
			default: "Enter amount to fund",
		},
		app: String,
		closeModal: Function,
		submitForm: Function,
		setDefault: Boolean,
		walletId: String,
		minAmount: {
			type: Number,
			default: 1000,
		},
	},
	components: {
		MasterWalletSelect,
		WalletSelect,
		CustomInput,
		CustomSelect,
		Spinner,
	},
	computed: {
		baseWallet() {
			return this.$store.state.account.baseWallet;
		},
	},
	mounted() {
		if (this.app === "account") this.wallet = this.baseWallet;
	},
	methods: {
		formVuelidator,
		handleSubmit(e) {
			e.preventDefault();
			this.$v.$touch();
			const { formErrors, isValid } = formValidator({
				wallet: this.wallet,
				amount: this.amount,
			});
			if (isValid && !this.$v.$invalid) {
				if (this.amount < this.minAmount)
					this.formErrors.amount = `Minimum of #${this.minAmount}`;
				else this.submitForm(this.wallet, this.amount, this.returnUrl);
			} else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleClose() {
			Object.assign(this.$data, { ...this.$data, ...initialState() });
			this.closeModal();
		},
		getFirstData(master) {
			this.wallet = master;
		},
		getLabel: (val) => {
			return val.account_name
				? `${val.account_name} ( ${val.account_number} - ${
						val.bank_name ? val.bank_name : val.bank
				  } )`
				: null;
		},
		async getMerchantWalletSettings() {
			try {
				const settingsResponse = await this.$store.dispatch(
					"wallets/getMerchantWalletSettings",
					{ apiKey: getWalletApikey(this.$store) }
				);
				const { data } = settingsResponse.data || {};
				this.bank_account = data.BankAccount;
			} catch (error) {}
		},
	},
	mounted() {
		const customerWallet = this.$store.state.account.customerWallets
			? this.$store.state.account.customerWallets[0]
			: {};
		if (this.app === "account") {
			this.wallet = customerWallet;
		}
		this.getMerchantWalletSettings();
	},
};
</script>
