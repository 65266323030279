<template>
	<div class="p-4">
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div class="flex__between flex-wrap">
				<div
					class="SectionItem w-auto no-b-b mb-0 justify-content-start"
				>
					<img
						src="/assets/img/icons/arrow-left.svg"
						width="30"
						alt=""
						class="pointer"
						@click="$router.push('/wallet/payment/disputes')"
					/>
					<p class="title ml-3 mb-0">
						Dispute Details
					</p>
				</div>
				<UpdateDisputeStatusButton
					v-if="dispute.status"
					:dispute="dispute"
					:refetch="() => getPaymentDispute(id)"
				/>
			</div>

			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getPaymentDispute(id)"
			/>

			<template v-else>
				<div class="pt-4" v-show="dispute.reason">
					<h4 class="font-weight-bold">Reason:</h4>
					<h5 class=" pl-4">{{ dispute.reason }}</h5>
				</div>

				<div class="card overflow-hidden mt-3">
					<div class="row py-3">
						<div class="col-sm-3 text-center border-right ">
							<p class="call-name pt-2">Amount</p>
							<h4>
								{{ toCurrency(dispute.amount || 0) }}
							</h4>
						</div>

						<div class="col-sm-4 text-center border-right ">
							<p class="call-name pt-2">Name</p>
							<h4>
								{{ dispute.first_name }} {{ dispute.last_name }}
							</h4>
						</div>

						<div class="col-sm-3 text-center border-right">
							<p class="call-name pt-2">Contacts</p>
							<h5>
								<p>{{ dispute.email }}</p>
								{{ dispute.phone_number }}
							</h5>
						</div>

						<div class="col-sm-2 text-center">
							<p class="call-name pt-2">Status</p>
							<h5 :class="statusColor(dispute.status)">
								{{ titleCase(dispute.status) }}
							</h5>
						</div>
					</div>
				</div>

				<template v-if="dispute.proof_url">
					<div class="flex__end mb-3">
						<button
							class="btn btn-sm btn--primary px-4 "
							@click="download"
						>
							Download Proof
						</button>
					</div>
					<div class="card">
						<img :src="dispute.proof_url" alt="proof" /></div
				></template>
			</template>
		</template>
	</div>
</template>

<script>
	import UpdateDisputeStatusButton from "../../buttons/UpdateDisputeStatusButton/UpdateDisputeStatusButton";
	import EmptyWallet from "../../../common/EmptyWallet/EmptyWallet";
	import Spinner from "../../../../../utils/Spinner/Spinner";
	import FailedState from "../../../../../utils/ResponseState/FailedState";
	import {
		getWalletApikey,
		toCurrency,
		titleCase,
		statusColor,
	} from "../../../../../utils/helpers";

	export default {
		name: "PaymentDisputeDetails",
		data() {
			return {
				id: "",
				loading: false,
				errorMsg: "",
				dispute: {},
			};
		},
		components: {
			Spinner,
			FailedState,
			EmptyWallet,
			UpdateDisputeStatusButton,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
		},
		methods: {
			titleCase,
			toCurrency,
			statusColor,
			download() {
				window.open(this.dispute.proof_url);
			},
			async getPaymentDispute(id) {
				try {
					this.loading = true;
					this.errorMsg = "";
					const response = await this.$store.dispatch(
						"payment/getDispute",
						{
							apiKey: getWalletApikey(this.$store),
							id,
						}
					);
					if (response.status === 200) {
						this.loading = false;
						this.dispute = response.data.data;
					}
				} catch (error) {
					this.loading = false;
					this.errorMsg = error.message;
				}
			},
		},

		async mounted() {
			const id = this.$route.params.id;
			this.id = id;
			this.getPaymentDispute(id);
		},
	};
</script>
