<template>
	<div>
		<button
			v-if="status.createStatus || duplicate"
			class="btn btn--lg btn--primary py-3"
			@click="(e) => handleSubmit(e, 'create')"
			:disabled="loading || sLoading"
		>
			<Spinner v-if="loading" :color="'#fff'" />
			<span v-else>
				<span v-if="duplicate">Duplicate Invoice</span>
				<span v-else>Create Invoice</span>
			</span>
		</button>

		<button
			v-else
			class="btn btn--lg btn--primary py-3"
			@click="(e) => handleSubmit(e, 'create')"
			:disabled="loading || sLoading"
		>
			<Spinner v-if="loading" :color="'#fff'" />
			<span v-else>Update Invoice</span>
		</button>

		<div class="flex__between mt-4">
			<button
				v-if="status.previewStatus"
				class="btn btn--lg btn--primary--border py-3 mr-2"
				@click="(e) => handleSubmit(e, 'preview')"
				:disabled="loading || sLoading"
			>
				Preview Invoice
			</button>
			<button
				v-else
				class="btn btn--lg btn--primary--border py-3 mr-2"
				@click="() => changeStatus('previewStatus')"
				:disabled="loading || sLoading"
			>
				Edit Invoice
			</button>
			<button
				class="btn--lg btn--primary--border pointer py-3"
				@click="(e) => handleSubmit(e, 'save')"
				:disabled="loading || sLoading"
			>
				<Spinner v-if="sLoading" />
				<span v-else> Save Invoice</span>
			</button>
		</div>
	</div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";

export default {
	name: "InvoiceCreationActionButton",
	props: [
		"loading",
		"sLoading",
		"status",
		"changeStatus",
		"handleSubmit",
		"duplicate",
	],
	components: {
		Spinner,
	},
};
</script>
