<template>
	<form id="sender" @submit.prevent="handleMask" class="text-left">
		<CustomInput
			:name="'senderMask'"
			v-model="request.senderMask"
			:label="'Serder Mask ( Optional )'"
			:placeholder="'Enter a sender mask'"
			:error="formErrors.senderMask"
			:handleError="handleError"
		/>

		<FormButtons
			:handleCloseModal="closeModal"
			:closeBtnName="'Skip'"
			:btnName="'Validate'"
		/>
	</form>
</template>

<script>
	import { notifications } from "../../../../../../../public/assets/mixins/notifications";
	import CustomInput from "../../../../../../utils/CustomInput/CustomInput";
	import { formValidator } from "../../../../../../utils/FormValidator/FormValidator";
	import FormButtons from "../../../../../../utils/FormButtons/FormButtons";

	export default {
		name: "ValidateSenderMask",
		mixins: [notifications],
		data() {
			return {
				request: {
					senderMask: "",
				},
				formErrors: {},
			};
		},
		props: ["nextStep"],
		components: {
			CustomInput,
			FormButtons,
		},
		computed: {
			merchant() {
				return this.$store.state.account.profile.merchant;
			},
			clientId() {
				return this.$store.state.account.profile.api_customer.client_id;
			},
		},
		methods: {
			handleMask() {
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) this.validateMask();
				else this.formErrors = formErrors;
			},
			closeModal() {
				this.nextStep({});
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			async validateMask() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"wallets/validateSenderMask",
						{
							params: this.request,
							clientid: this.clientId,
						}
					);
					const { status } = response || {};
					if (status === 200) {
						this.loading = false;
						this.nextStep(this.request);
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
