<template>
	<!-- <div
		class="az-sidebar az-sidebar-sticky"
		:style="{ backgroundColor: appSettings.sidebar_color }"
	> -->
	<div class="az-sidebar az-sidebar-sticky">
		<div class="az-sidebar-header">
			<!-- <a href="#" class="az-logo" v-if="appSettings.logo_url">
				<img :src="appSettings.logo_url" alt style class="img-logo" />
			</a> -->
			<a href="#" class="az-logo">
				<img src="/assets/img/blusalt-logo-sb.svg" alt style />
			</a>
		</div>

		<div class="az-sidebar-body">
			<ul class="nav">
				<li class="nav-item" ref="dashNavElement">
					<router-link to="/dashboard" class="nav-link">
						<i class="la la-tachometer"></i>Dashboard
					</router-link>
				</li>

				<li class="nav-item" ref="servicesNavElement">
					<a href class="nav-link with-sub">
						<i class="la la-briefcase"></i>Verifications
					</a>
					<nav class="nav-sub">
						<router-link to="/services" class="nav-link">
							<i class="la la-qrcode"></i>Identity Services
						</router-link>
						<router-link to="/services/facial" class="nav-link">
							<i class="la la-user-plus"></i>Smart KYC
						</router-link>
						<router-link to="/services/aml" class="nav-link">
							<i class="fas fa-search-dollar"></i>Anti Money
							Laundering
						</router-link>
						<router-link
							to="/services/customer-info"
							class="nav-link"
						>
							<i class="la la-info"></i>Customer Information
						</router-link>
						<router-link
							to="/services/business-info"
							class="nav-link"
						>
							<i class="la la-building"></i>Business Verification
						</router-link>
						<router-link
							to="/services/api-docs/keys-and-webhook"
							class="nav-link"
						>
							<i class="fas fa-file-invoice"></i>API Documentation
						</router-link>
						<router-link to="/services/reports" class="nav-link">
							<i class="la la-line-chart"></i>Reports
						</router-link>
					</nav>
				</li>

				<li class="nav-item" ref="walletNavElement">
					<a href class="nav-link with-sub">
						<i class="la la-money"></i>Payments
					</a>
					<nav class="nav-sub">
						<router-link to="/wallets-overview" class="nav-link">
							<i class="la la-home"></i>Overview
						</router-link>
						<router-link
							to="/wallets/transactions"
							class="nav-link to-bottom"
						>
							<i class="la la-bar-chart"></i>Transactions
						</router-link>
						<router-link to="/wallet/payment" class="nav-link">
							<i class="las la-credit-card"></i>Payment
							Collections
						</router-link>
						<router-link
							to="/wallet/payment-links"
							class="nav-link"
						>
							<i class="la la-link"></i>Payment Link
						</router-link>
						<router-link
							to="/wallet/split-payments"
							class="nav-link"
						>
							<i class="la la-arrows-h"></i>Split Payments
						</router-link>
						<router-link to="/wallet/terminal" class="nav-link">
							<i class="la la-bar-chart"></i>Terminals
						</router-link>
						<router-link
							to="/wallet/reserved-accounts"
							class="nav-link"
						>
							<i class="la la-user-secret"></i>Reserved Accounts
						</router-link>
						<router-link to="/wallets" class="nav-link">
							<i class="la la-credit-card"></i>Wallets
						</router-link>
						<router-link to="/invoice" class="nav-link">
							<i class="fas fa-file-invoice"></i> Invoice
						</router-link>
						<router-link
							to="/wallet/subscriptions"
							class="nav-link"
						>
							<i class="la la-refresh"></i>Subscriptions
						</router-link>
						<router-link to="/wallet/remittance" class="nav-link">
							<i class="la la-bar-chart"></i>Remittance
						</router-link>
						<router-link to="/wallets/customers" class="nav-link">
							<i class="la la-users"></i>Customers
						</router-link>

						<AccessControl>
							<router-link
								to="/wallets/preferences"
								class="nav-link to-bottom"
							>
								<i class="la la-cog"></i>Preferences
							</router-link>
						</AccessControl>
					</nav>
				</li>

				<li class="nav-item" ref="fundNavElement">
					<a href class="nav-link with-sub">
						<i class="la la-exchange"></i>Funds Transfer
					</a>
					<nav class="nav-sub">
						<router-link
							v-if="!merchant"
							to="/request-funds-transfer"
							class="nav-link"
						>
							<i class="la la-money"></i>Activate Funds Transfer
						</router-link>
						<router-link
							v-if="merchant"
							to="/funds-transfer"
							class="nav-link"
						>
							<i class="la la-cc-mastercard"></i>Initiate Transfer
						</router-link>
						<router-link
							v-if="merchant"
							to="/funds-transfer-transactions"
							class="nav-link"
						>
							<i class="la la-qrcode"></i>Transactions
						</router-link>
						<router-link
							v-if="merchant"
							to="/preferences"
							class="nav-link"
						>
							<i class="la la-cog"></i>Preferences
						</router-link>
					</nav>
				</li>

				<li class="nav-item" ref="billsNavElement">
					<a href class="nav-link with-sub">
						<i class="la la-credit-card"></i>Bill Payments
					</a>
					<nav class="nav-sub">
						<router-link to="/services/bills" class="nav-link">
							<i class="la la-mobile"></i>Mobile & Utility
						</router-link>
					</nav>
				</li>

				<li class="nav-item" ref="msgNavElement">
					<router-link to="/messaging" class="nav-link">
						<i class="far fa-comment-dots"></i>Messaging
					</router-link>
				</li>

				<!-- <li class="nav-item" v-if="adminAccess" ref="billsNavElement">
          <router-link to="/services/bills" class="nav-link">
            <i class="la la-credit-card"></i>Bill Payments
          </router-link>
        </li> -->

				<!-- <li class="nav-item" v-if="adminAccess">
          <a href class="nav-link with-sub">
            <i class="fas fa-concierge-bell"></i>Bill Payments
          </a>
          <nav class="nav-sub">
            <router-link to="/services" class="nav-link">
              <i class="la la-qrcode"></i>Utility Bills
            </router-link>
            <router-link to="/services/aml" class="nav-link">
              <i class="fas fa-search-dollar"></i>Airtime Service
            </router-link>
            <router-link to="/services/customer-info" class="nav-link">
              <i class="la la-info"></i>DataPlan Service
            </router-link>
          </nav>
        </li> -->

				<!-- <li
					class="nav-item"
					v-if="adminAccess"
					ref="directDebitNavElement"
				>
					<a href class="nav-link with-sub">
						<i class="la la-industry"></i>Direct Debit
					</a>
					<nav class="nav-sub">
						<router-link to="/direct-debit" class="nav-link">
							<i class="la la-home"></i>Overview
						</router-link>
						<router-link
							to="/direct-debit/mandates"
							class="nav-link"
							v-if="userProfile.directDebit"
						>
							<i class="la la-mobile"></i>Direct Debit Mandates
						</router-link>
						<router-link
							to="/direct-debit/transactions"
							class="nav-link"
							v-if="userProfile.directDebit"
						>
							<i class="lla la-qrcode"></i>Direct Debit
							Transactions
						</router-link>
						<router-link
							to="/direct-debit/preferences"
							class="nav-link"
							v-if="userProfile.directDebit"
						>
							<i class="la la-cog"></i>Preferences
						</router-link>
					</nav>
				</li> -->

				<!-- <li
					class="nav-item"
					v-if="userRoleStorage === 'Primary Customer'"
				>
					<router-link to="/request-whitelabelling" class="nav-link">
						<i class="la la-user-secret"></i>Request White-labelling
					</router-link>
				</li>
				<li
					class="nav-item"
					v-if="userRoleStorage === 'Whitelabelled Customer'"
					ref="whitelabelNavElement"
				>
					<a href class="nav-link with-sub">
						<i class="la la-paint-brush"></i>White-labelling
					</a>
					<nav class="nav-sub">
						<router-link to="/whitelabel-analytics" class="nav-link"
							><i class="la la-line-chart"></i
							>Analytics</router-link
						>
						<router-link to="/whitelabel-customers" class="nav-link"
							><i class="la la-users"></i>Customer
							Listing</router-link
						>
						<router-link to="/whitelabel-pricing" class="nav-link">
							<i class="la la-tag"></i>Default Service
							Pricing</router-link
						>
						<router-link to="/whitelabel-settings" class="nav-link"
							><i class="la la-cogs"></i>Settings</router-link
						>
						<router-link to="/whitelabel-api-logs" class="nav-link"
							><i class="la la-paste"></i>Request
							logs</router-link
						>
						<router-link
							to="/whitelabel-transactions"
							class="nav-link"
							><i class="la la-bar-chart"></i
							>Transactions</router-link
						>
					
					</nav>
				</li> -->

				<li class="nav-item" ref="transactionsNavElement">
					<router-link to="/transactions" class="nav-link">
						<i class="la la-bar-chart"></i>Transactions
					</router-link>
				</li>

				<li class="nav-item" ref="applicationsNavElement">
					<router-link to="/applications" class="nav-link">
						<i class="la la-code-fork"></i>Applications
					</router-link>
				</li>

				<li class="nav-item" ref="auditNavElement">
					<router-link to="/audit" class="nav-link">
						<i class="la la-line-chart"></i>Audit Trail
					</router-link>
				</li>

				<li class="nav-item" ref="accountNavElement">
					<router-link to="/account/profile" class="nav-link">
						<i class="la la-user"></i>Account
					</router-link>
				</li>
				<li class="nav-item">
					<a href class="nav-link" @click.prevent="startTour">
						<i class="la la-play-circle"></i>Take A Tour
					</a>
				</li>

				<!-- End of Navigation -->
			</ul>

			<!-- nav -->
			<p class="nav-tip" v-if="false">
				Contact our integration team to learn more about our API and
				dashboard, or to schedule a demo:
				<a href="#">blusalt.net/contact</a>
			</p>
			<ul class="nav fixed" v-if="false">
				<li>
					<a href="#" target="_blank">
						API Documentation
						<img
							src="/assets/img/new-window-arrow.svg"
							alt="open-in-new-window"
						/>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { ACCOUNT_ROLES } from "@/constants/roles";
import AccessControl from "@/utils/AccessControl/AccessControl";
import DevOnly from "@/utils/AccessControl/DevOnly";

export default {
	data() {
		return {
			userRoleStorage: "",
			isUserAdminOrOwner: false,
			clientProfile: "",
			accountRole: "",
			viewServices: false,
			adminAccess: false,
			viewApplication: false,
		};
	},
	components: {
		AccessControl,
		DevOnly,
	},
	computed: {
		userProfile() {
			return this.$store.state.account.profile;
		},
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		userRole() {
			return this.$store.state.account.profile.roles[0].role_label;
		},
		isApproved() {
			return this.$store.state.fundstransfer.businessInfo.is_approved;
		},
	},
	props: {
		appSettings: {
			type: Object,
		},
	},
	methods: {
		viewPermissions() {
			if (
				this.accountRole == ACCOUNT_ROLES.ADMIN ||
				this.accountRole == ACCOUNT_ROLES.OWNER ||
				this.accountRole == ACCOUNT_ROLES.DEVELOPER
			)
				this.viewServices = true;

			if (
				this.accountRole == ACCOUNT_ROLES.ADMIN ||
				this.accountRole == ACCOUNT_ROLES.OWNER
			)
				this.adminAccess = true;

			if (
				this.accountRole == ACCOUNT_ROLES.ADMIN ||
				this.accountRole == ACCOUNT_ROLES.OWNER ||
				this.accountRole == ACCOUNT_ROLES.OPERATIONS ||
				this.accountRole == ACCOUNT_ROLES.DEVELOPER
			)
				this.viewApplication = true;
		},
		startTour() {
			this.$nextTick(() => {
				const tour = this.$shepherd({
					useModalOverlay: true,
					defaultStepOptions: {
						scrollTo: { behavior: "smooth", block: "center" },
					},
				});

				tour.addStep({
					title: "WELCOME TO BLUSALT AGGREGATED SERVICES PORTAL",
					text: `<p> This is an overview of Blusalt documentation that introduces you to the Blusalt application and services with the aim to help you understand and integrate easily into the platform </p>
          <br>`,
					buttons: [
						{
							text: "Next",
							action: tour.next,
						},

						{
							action: tour.cancel,
							text: "Close",
						},
					],
				});

				tour.addStep({
					attachTo: { element: "#userProfile", on: "bottom" },
					title: "Your Profile",
					text: `<p>Quick access to your profile information. You can add, edit and update your information from your profile page</p>
          <br>
          <p>You can also ‘Sign Out’ from this panel</p>
          <br>`,
					buttons: [
						{
							text: "Next",
							action: tour.next,
						},

						{
							action: tour.cancel,
							class: "btn-secondary",
							text: "Close",
						},
					],
				});

				tour.addStep({
					attachTo: { element: "#walletBalance", on: "bottom" },
					title: "Credit Balance",
					text: `<p>This shows available credit to carry out transactions, and access the different services on the platform.</p>
          <br>
          <p>On click of your balance, it will redirect to your wallet information, for other actions on the wallet Eg. Fund Wallet.</p>
          <br>`,
					buttons: [
						{
							text: "Next",
							action: tour.next,
						},

						{
							action: tour.cancel,
							class: "btn-secondary",
							text: "Close",
						},
					],
				});

				tour.addStep({
					attachTo: {
						element: this.$refs.dashNavElement,
						on: "right",
					},
					title: "Dashboard ",
					text: `<p>The analytics of your application, how many API calls you're making and more statistics</p>
          <br>`,
					buttons: [
						{
							text: "Next",
							action: tour.next,
						},

						{
							action: tour.cancel,
							class: "",
							text: "Close",
						},
					],
				});

				if (this.$refs.servicesNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.servicesNavElement,
							on: "right",
						},
						title: "Services ",
						text: `<p>This is a comprehensive collection of the services available on the platform divided into sections; Identity Services, Anti Money Laundering, Customer Information, Messaging. This contains an implementation view, and the API documentation for each service.</p>
          <br>
          <p>Identity Services -  A collection of services for identification in different countries and regions. Identification means includes; BVN, NIN, PVC, Drivers License, Facial Recognition, International Passport, Social Security Number.</p>
          <br>
          <p>Anti Money Laundering - A cross platform search services that gets the information of people on an the AML list</p>
          <br>
          <p>Customer Information  - This service is used to verify the mobile phone carriers of users</p>
          <br>
          <p>Messaging  - For sending SMS and OTP to your customers</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.billsNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.billsNavElement,
							on: "right",
						},
						title: "Bill Payments ",
						text: `<p>A collection of our bill payment services which includes Airtime, Data and Utility bills.</p>
          <br>
          <p>Mobile bills are to different providers in different countries and utility bills include electricity, TV and Internet.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.fundNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.fundNavElement,
							on: "right",
						},
						title: "Funds Transfer",
						text: `<p>From this Tab, you can access your fund transfer profile, Initiate a transfer, view your transactions, and get your preferences for funds transfer.</p>
          <br>
          <p>Transfers can be to a singular recipient or to a bulk recipient. Your transactions can be filtered, And in your preferences, you can get access to your API keys, webhook, and how to receive notifications on your transfers.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.directDebitNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.directDebitNavElement,
							on: "right",
						},
						title: "Direct Debit",
						text: `<p>Activate direct debit, the simplest and most convenient way for you to collect regular and occasional payments.</p>
          <br>
          <p>From this Tab, you can activate an instruction to authorises collection of payment from your customers.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.walletNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.walletNavElement,
							on: "right",
						},
						title: "Wallets",
						text: `<p>This tab contains wallet management information, transactions and how to integrate the wallet to other applications.</p>
          <br>
          <p>By default, each account has a wallet in the local currency of it’s resident country with an option to add a dollar wallet as a secondary wallet. This is made so to allow ease of local and international transactions on the platform.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.whitelabelNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.whitelabelNavElement,
							on: "right",
						},
						title: "White Labelling",
						text: `<p>In white-labelling tab, you manage your white label settings, view your service pricing, analytics of the API calls made by customers and their statistics across the different services available on the platform.</p>
          <br>
          <p>You can also view request logs, transaction history and adjust white-label settings.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.transactionsNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.transactionsNavElement,
							on: "right",
						},
						title: "Transactions",
						text: `<p>Transactions tab aggregates all the transactions by an account on the platform and divides it into two distinct tabs; Credit history and Debit history.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.applicationsNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.applicationsNavElement,
							on: "right",
						},
						title: "Applications",
						text: `<p>All services on the aggregated service portal need an application to run on. The applications tab gives you the access to create an application, manage applications and get access credentials to explore APIs and start testing out services.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.auditNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.auditNavElement,
							on: "right",
						},
						title: "Audit Trail",
						text: `<p>View the trail of actions performed by users in your organization across different services and APIs.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				if (this.$refs.accountNavElement) {
					tour.addStep({
						attachTo: {
							element: this.$refs.accountNavElement,
							on: "right",
						},
						title: "Account ",
						text: `<p>All other settings not found in other tabs are found in the account tab.</p>
          <br>
          <p>In the account tab, you can fund your different wallets, change profile information, add a team member and manage team members, edit your services, view the historical data of your API Requests on any of the Blusalt services registered against your account.</p>
          <br>`,
						buttons: [
							{
								text: "Next",
								action: tour.next,
							},

							{
								action: tour.cancel,
								class: "",
								text: "Close",
							},
						],
					});
				}

				tour.start();
			});
		},
		getClientProfile() {
			this.clientProfile =
				this.$store.getters["account/getClientProfile"];
			console.log("Client Profile", this.clientProfile);
		},
	},
	async mounted() {
		let userRoleStorage = localStorage.getItem("identifiedAs");
		this.accountRole = localStorage.getItem("accountRole");
		this.viewPermissions();
		this.userRoleStorage = userRoleStorage;
		this.getClientProfile();

		const userId =
			this.$store.state?.account?.profile?.api_customer?.user_id;
		const member = this.$store.state?.account?.profile?.team?.find(
			(m) => m.user_id === userId
		);
		this.isUserAdminOrOwner =
			member?.organization_role === 1 || member?.organization_role === 2;
	},
};
</script>
<style scoped>
@import "~shepherd.js/dist/css/shepherd.css";
.img-logo {
	height: -webkit-fill-available;
	object-fit: contain;
	width: 100%;
}
</style>
