<template>
	<div
		class="tab-pane active fade show"
		id="Subscription_Details"
		role="tabpanel"
		aria-labelledby="Subscription_Details-tab"
	>
		<div class="row mt-4">
			<div class="col-md-6 mb-4">
				<ul class="card sub_card h-100">
					<h5>Amount Details</h5>
					<li>
						<p>Amount</p>
						<p>
							{{
								subscription.metadata.plan
									? subscription.metadata.plan.currency
									: " "
							}}{{ toCurrency(subscription.amount, "") }}
						</p>
					</li>
					<li>
						<p>Setup Amount</p>
						<p>
							{{
								subscription.metadata.plan
									? subscription.metadata.plan.currency
									: " "
							}}{{ toCurrency(subscription.setup_amount, "") }}
						</p>
					</li>
					<li>
						<p>Coupon Amount</p>
						<p>
							{{
								subscription.metadata.plan
									? subscription.metadata.plan.currency
									: " "
							}}{{ toCurrency(subscription.couponAmount, " ") }}
						</p>
					</li>
					<li>
						<p>VAT</p>
						<p>{{ toCurrency(subscription.vat) }}</p>
					</li>
					<li>
						<p>Total Amount</p>
						<p>
							{{
								subscription.metadata.plan
									? subscription.metadata.plan.currency
									: " "
							}}
							{{ toCurrency(subscription.amountAfter, " ") }}
						</p>
					</li>
				</ul>
			</div>

			<div class="col-md-6 mb-4">
				<ul class="card sub_card h-100">
					<h5>Subscription</h5>
					<li>
						<p>Subscription ID</p>
						<p>{{ subscription.id }}</p>
					</li>
					<li v-show="title">
						<p>Trail Title</p>
						<p>
							{{ title }}
						</p>
					</li>
					<li>
						<p>Subscription Type</p>
						<p>
							{{
								titleCase(subscription.subscriptionType) ||
								"Not Available"
							}}
						</p>
					</li>
					<li v-show="desc">
						<p>Description</p>
						<p>
							{{ desc }}
						</p>
					</li>
					<li>
						<p>Status</p>
						<p>
							{{ subscription.active ? "Active" : "Not Active" }}
						</p>
					</li>
				</ul>
			</div>

			<div class="col-md-6 mb-4">
				<ul class="card sub_card h-100">
					<h5>Subscription Date Details</h5>
					<li>
						<p>Date Created</p>
						<p>
							{{
								timeDateFormat(subscription.createdAt).date ||
								"Not Available"
							}}
							{{ timeDateFormat(subscription.createdAt).time }}
						</p>
					</li>
					<li>
						<p>Start Date</p>
						<p>
							{{
								timeDateFormat(subscription.startDate).date ||
								"N/A"
							}}
							{{ timeDateFormat(subscription.startDate).time }}
						</p>
					</li>
					<li>
						<p>Renewal Date</p>
						<p>
							{{
								timeDateFormat(subscription.renewalDate).date ||
								"Not Available"
							}}
							{{ timeDateFormat(subscription.renewalDate).time }}
						</p>
					</li>
					<li>
						<p>End Date</p>
						<p>
							{{
								timeDateFormat(subscription.endDate).date ||
								"N/A"
							}}
							{{ timeDateFormat(subscription.endDate).time }}
						</p>
					</li>
				</ul>
			</div>

			<div class="col-md-6 mb-4">
				<ul class="card sub_card h-100">
					<h5>Plan Details</h5>
					<li>
						<p>Plan Name</p>
						<p>
							{{
								subscription.metadata.plan
									? subscription.metadata.plan.name
									: "Not Available"
							}}
						</p>
					</li>
					<li>
						<p>Plan Reference</p>
						<p>{{ subscription.planRef }}</p>
					</li>
					<li>
						<p>Interval</p>
						<p>
							{{
								subscription.metadata.plan
									? titleCase(
											subscription.metadata.plan.interval
									  )
									: "Not Available"
							}}
						</p>
					</li>
					<li>
						<p>Description</p>
						<p>
							{{
								subscription.metadata.plan
									? subscription.metadata.plan.description
									: "Not Available"
							}}
						</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {
	titleCase,
	toCurrency,
	statusColor,
	timeDateFormat,
} from "../../../../../../utils/helpers";

export default {
	name: "SubscriptionDetailsMain",
	props: ["subscription", "title", "desc"],

	methods: {
		titleCase,
		toCurrency,
		statusColor,
		timeDateFormat,
	},
};
</script>
