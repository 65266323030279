export const sampleResponse = {
	data: {
		error: false,
		status_code: 200,
		message: "User Info Retrieved",
		data: {
			valid: true,
			number: "2347065745963",
			local_format: "07065745963",
			international_format: "+2347065745963",
			country_prefix: "+234",
			country_code: "NG",
			country_name: "Nigeria (Federal Republic of)",
			location: "",
			carrier: "MTN Nigeria Communications Ltd",
			line_type: "mobile",
			mno: "mtn",
			updatedAt: 1605874568229,
		},
	},
};
