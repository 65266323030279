<template>
	<div>
		<img
			v-if="useIcon"
			@click="openUpdateLink"
			class="pointer mr-3"
			src="/assets/img/icons/edit.png"
			alt="Update Payment Link"
			width="25px"
		/>

		<button
			v-else
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openUpdateLink"
		>
			Update
		</button>

		<UpdatePaymentLinkModal
			v-if="showUpdateModal"
			:id="id"
			:payment="payment"
			:closeModal="closeModal"
			:paymentLinkRef="paymentLinkRef"
			:refresh="refresh"
		/>
	</div>
</template>

<script>
import UpdatePaymentLinkModal from "../../modals/UpdatePaymentLinkModal/UpdatePaymentLinkModal";

export default {
	name: "UpdatePaymentLinkButton",
	data() {
		return {
			showUpdateModal: false,
		};
	},
	props: ["payment", "paymentLinkRef", "refresh", "useIcon"],
	methods: {
		openUpdateLink() {
			this.showUpdateModal = true;
			$(`#${this.id}`).modal("show");
		},
		closeModal() {
			this.showUpdateModal = false;
			$(`#${this.id}`).modal("hide");
		},
	},
	components: {
		UpdatePaymentLinkModal,
	},
	computed: {
		id() {
			return (Math.random() + 1).toString(36).substring(7);
		},
	},
};
</script>
