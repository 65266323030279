<template>
	<div
		class="tab-pane fade"
		id="settings"
		role="tabpanel"
		aria-labelledby="settings-tab"
	>
		<form @submit.prevent="requestOtp" class="card p-4">
			<div class="SectionItem mb-4 flex__between pb-2">
				<h5 class="font-weight-bold mb-0">Account Settings</h5>
				<img
					v-if="disabled"
					@click="toggleDisable"
					class="pointer"
					src="/assets/img/icons/edit.png"
					alt="update FT Account"
					width="25px"
				/>
				<button
					v-else
					type="submit"
					class="btn btn--sm btn-primary px-5 py-0"
					:disabled="loading"
				>
					<Spinner v-if="loading" />
					<span v-else>Save Changes</span>
				</button>
			</div>
			<div class="row">
				<div class="col-sm-4">
					<CustomSelect
						:name="'settlement_type'"
						label="Settlement Type"
						:options="['bank', 'wallet']"
						v-model="request.settlement_type"
						:disabled="disabled"
					/>
				</div>

				<div class="col-sm-4">
					<CustomSelect
						:name="'bank_account_id'"
						label="Settlement Account"
						:getLabel="getLabel"
						:options="accountOptions"
						v-model="request.bank_account_id"
						:required="true"
						:disabled="disabled"
					/>
				</div>

				<div class="col-sm-4">
					<CurrencySelect
						:name="'default_currency'"
						v-model="request.default_currency"
						:disabled="disabled"
					/>
				</div>

				<div class="col-sm-4">
					<BankProviderSelect
						:name="'BankProvider'"
						v-model="BankProvider"
						:disabled="true"
					/>
				</div>

				<li class="col-sm-4 flex__between">
					<h5>Customer Pay Charges?</h5>
					<toggle-button
						v-model="request.customer_pays_charges"
						color="#006848"
						:value="request.customer_pays_charges"
						:sync="true"
						:disabled="disabled"
						:labels="{
							checked: 'ON',
							unchecked: 'OFF',
						}"
					/>
				</li>

				<li class="col-sm-4 flex__between">
					<h5>Refund Invalid Collections?</h5>
					<toggle-button
						v-model="request.refund_invalid_collections"
						color="#006848"
						:value="request.refund_invalid_collections"
						:sync="true"
						:disabled="disabled"
						:labels="{
							checked: 'ON',
							unchecked: 'OFF',
						}"
					/>
				</li>
			</div>
		</form>

		<div class="card p-4">
			<div class="SectionItem mb-3 flex__between pb-2">
				<h5 class="font-weight-bold mb-0">Update Logo</h5>
				<AddLogoButton
					:useIcon="true"
					:logo="logo"
					:setLogo="setLogo"
				/>
			</div>

			<div class="flex__between">
				<h5>This image will be used as logo in the checkout page</h5>

				<div v-show="logo">
					<img :src="logo" class="customer__logo" />
				</div>
			</div>
		</div>

		<div class="card p-4">
			<div class="SectionItem mb-3 flex__between pb-2">
				<h5 class="font-weight-bold mb-0">IP Whitelisting</h5>
				<img
					@click="openWhitelistingModal"
					class="pointer"
					src="/assets/img/icons/edit.png"
					alt="Update Whitelisted Address"
					width="25px"
				/>
			</div>
			<h3 v-if="ipLists.length === 0" class="text-center py-3">
				No IP Address Added Yet!
			</h3>
			<ol class="row parsley-list mb-0" v-else>
				<li
					class="my-2 col-md-3"
					v-for="(ipList, index) in ipLists"
					:key="index"
					style="font-size: 18px"
				>
					<div v-if="ipList" class="d-flex">
						{{ ipList }}
					</div>
				</li>
			</ol>
		</div>

		<UpdateWalletSettingsModal
			v-if="showUpdateModal"
			:id="'updateSetting'"
			:payload="payload"
			:closeModal="closeModal"
			:disableSetting="disableSetting"
		/>

		<UpdateWhitelistedIPModal
			v-if="showWhitelistedModal"
			:id="'whitelistedIp'"
			:whitelistedIP="whitelistedIP"
			:updateWhitelistedIP="updateWhitelistedIP"
			:closeModal="closeModal"
		/>
	</div>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import CustomInput from "@/utils/CustomInput/CustomInput";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import BankProviderSelect from "@/utils/Selects/BankProviderSelect/BankProviderSelect";
import UpdateWalletSettingsModal from "../modals/UpdateWalletSettingsModal/UpdateWalletSettingsModal";
import UpdateWhitelistedIPModal from "../modals/UpdateWhitelistedIPModal/UpdateWhitelistedIPModal";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import AddLogoButton from "../buttons/AddLogoButton/AddLogoButton";
import Spinner from "@/utils/Spinner/Spinner";
import { getWalletApikey, getResponseStatus } from "@/utils/helpers";

export default {
	mixins: [notifications],
	name: "WalletSettings",
	data() {
		return {
			request: {
				settlement_type: "",
				default_currency: "",
				bank_account_id: "",
				sender_mask: "",
				customer_pays_charges: false,
				refund_invalid_collections: true,
			},
			whitelistedIP: "",
			BankProvider: "",
			loading: false,
			disabled: true,
			showUpdateModal: false,
			showWhitelistedModal: false,
		};
	},

	props: ["Settings", "merchantBanks", "settlementWallets", "logo"],
	components: {
		CustomInput,
		CustomSelect,
		CurrencySelect,
		BankProviderSelect,
		Spinner,
		AddLogoButton,
		UpdateWalletSettingsModal,
		UpdateWhitelistedIPModal,
	},
	methods: {
		setLogo(logo) {
			this.logo = logo;
		},
		toggleDisable(e) {
			e.preventDefault();
			this.disabled = false;
		},
		disableSetting() {
			this.disabled = true;
		},
		getLabel: (val) => {
			return val.account_name
				? `${val.account_name} ( ${val.account_number} )`
				: null;
		},
		updateWhitelistedIP(value) {
			this.whitelistedIP = value;
		},
		async requestOtp() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"wallets/requestOtp",
					{
						apiKey: getWalletApikey(this.$store),
					}
				);
				const success = getResponseStatus(response);
				if (success) this.popUpdateModal();
				else this.showErrorLoginNotification(response.data.message);
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		popUpdateModal() {
			this.showUpdateModal = true;
			setTimeout(() => $("#updateSetting").modal("show"), 100);
		},
		closeModal() {
			this.showUpdateModal = false;
			this.showWhitelistedModal = false;
			$("#updateSetting, #whitelistedIp").modal("hide");
		},
		openWhitelistingModal() {
			this.showWhitelistedModal = true;
			setTimeout(() => $("#whitelistedIp").modal("show"), 100);
		},
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
		payload() {
			let data = Object.fromEntries(
				Object.entries(this.request).filter(([_, v]) => v != "")
			);
			const default_currency =
				this.request.default_currency.code ||
				this.request.default_currency;
			data = {
				...data,
				customer_pays_charges: this.request.customer_pays_charges,
				refund_invalid_collections:
					this.request.refund_invalid_collections,
				default_currency,
			};
			default_currency !== "NGN" && delete data.bank_account_id;

			return data;
		},
		accountOptions() {
			return this.request.default_currency.code === "NGN"
				? this.merchantBanks
				: this.settlementWallets;
		},
		ipLists() {
			return this.whitelistedIP ? this.whitelistedIP.split(",") : [];
		},
	},
	mounted() {
		const { BankAccountId, default_currency, BankAccount } =
			this.Settings || {};
		const { whitelistedIP } = this.$store.state.account.customerKeys || {};
		const bank_account_id =
			default_currency === "NGN"
				? {
						...BankAccount,
						id: BankAccountId ? parseInt(BankAccountId) : "",
				  }
				: this.settlementWallets[0] || {};

		this.BankProvider = this.Settings.BankProvider;
		this.whitelistedIP = whitelistedIP;

		this.request = {
			...this.request,
			settlement_type: this.Settings.settlement_type,
			default_currency: this.Settings.default_currency,
			bank_account_id,
			customer_pays_charges: this.Settings.customer_pays_charges,
			refund_invalid_collections:
				this.Settings.refund_invalid_collections,
		};
	},
	watch: {
		"request.default_currency"({ code }) {
			const { BankAccountId, default_currency, BankAccount } =
				this.Settings || {};
			const bank_account_id = {
				...BankAccount,
				id: BankAccountId ? parseInt(BankAccountId) : "",
			};
			const curCode = code || default_currency;
			this.request.bank_account_id =
				curCode === "NGN"
					? bank_account_id
					: this.settlementWallets[0] || {};
		},
	},
};
</script>
