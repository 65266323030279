<template>
	<div
		class="tab-pane active fade show pt-3"
		id="Online_Transactions"
		role="tabpanel"
		aria-labelledby="Online_Transactions-tab"
	>
		<div class="row">
			<div class="col-12 mt-3"></div>
			<div v-if="loading" class="col-12">
				<div class="flex__center my-5 py-5">
					<Spinner :size="'30px'" />
				</div>
			</div>

			<div v-else-if="errorMsg" class="col-12">
				<FailedState :errorMsg="errorMsg" :refetch="refetch" />
			</div>
			<div class="col-12" :class="tbClass">
				<div class="px-3">
					<FilterWrapper>
						<template slot="pageSelect"> </template>
						<template slot="filterForm">
							<TransactionsTableFilters
								:loading="loading"
								:rLoading="rLoading"
								:submitFilter="refetch"
								:clearFilter="clearFilter"
								:download="downloadAgentTrans"
							/>
						</template>
					</FilterWrapper>
				</div>
				<v-server-table
					ref="onlineTransRef"
					class="v-table"
					:columns="columns"
					:options="options"
					@loading="setLoading(true)"
					@loaded="setLoading(false)"
					@error="setError"
					@row-click="showData"
				>
					<template slot="charges" slot-scope="{ row }">
						<span>
							{{ toCurrency(row.charges, row.currency) }}
						</span>
					</template>
					<template slot="source" slot-scope="{ row }">
						<span>
							{{ titleCase(row.source) }}
						</span>
					</template>
					<template slot="amount" slot-scope="{ row }">
						<span>
							{{ toCurrency(row.amount, row.currency) }}
						</span>
					</template>
					<template slot="createdAt" slot-scope="{ row }">
						<span>
							{{
								timeDateFormat(row.createdAt).date +
								" " +
								timeDateFormat(row.createdAt).time
							}}
						</span>
					</template>
					<template slot="status" slot-scope="{ row }">
						<span :class="statusColor(row.status)">{{
							titleCase(row.status)
						}}</span>
					</template>
				</v-server-table>
			</div>
		</div>
		<TransactionModal
			v-if="showSideModal"
			:data="modalData"
			@close-modal="closeModal"
		/>
	</div>
</template>

<script>
import { componentData } from "../../../../../../../../public/assets/mixins/componentData";
import TransactionModal from "../../../../../../wallet/common/TransactionsTable/TransactionsTableModal.vue";
import Tabs from "../../../../../../../utils/Tabs/Tabs.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import TransactionsTableFilters from "../../../../Transactions/TransactionsTableFilters/TransactionsTableFilters.vue";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
	timeDateFormat,
	tableOptions,
	titleCase,
	toCurrency,
	getWalletApikey,
	statusColor,
} from "@/utils/helpers";

const tableHeadings = {
	reference: "Reference",
	source: "Payment Method",
	amount: "Amount",
	charges: "Charges",
	createdAt: "Created At",
	status: "Status",
};
export default {
	mixins: [componentData],
	name: "OnlineTransactions",
	data() {
		return {
			loading: false,
			rLoading: false,
			showSideModal: false,
			modalData: null,
			stats: null,
			errorMsg: "",
			size: 20,
			filters: { id: this.$route.params.id },
      		id: this.$route.params.id,
			...tableOptions(tableHeadings, 20),
		};
	},
	components: {
		TransactionsTableFilters,
		TransactionModal,
		FilterWrapper,
		Tabs,
		AccessControl,
		FailedState,
		Spinner,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	methods: {
		showData({ row }) {
			this.modalData = row;
			this.showSideModal = true;
		},
		closeModal(e) {
			this.showSideModal = false;
			this.modalData = null;
		},
		timeDateFormat,
		titleCase,
		toCurrency,
		statusColor,
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		setError(error) {
			this.errorMsg = error.message;
		},
		refetch(filters) {
			if (filters) this.filters = {...filters, id: this.id};
			this.getAgentTransactions();
			this.$refs.onlineTransRef.refresh();
		},
		clearFilter() {
			this.filters = {};
			this.$refs.onlineTransRef.refresh();
		},
		async getAgentTransactions() {
			this.options.requestFunction = async (data) => {
				const payload = {
					apiKey: getWalletApikey(this.$store),
					params: {
						...data,
						size: this.size,
						...this.filters,
					},
				};

				try {
					const resp = (
						await this.$store.dispatch(
							"pos/getAgentOnlineTransactions",
							payload
						)
					).data.data;
					return { ...resp, count: resp.total };
				} catch (error) {
					console.log("posError", error);
				}
			};
		},
		async downloadAgentTrans() {
			const download = (filterData) =>
				this.downloadReport(tableHeadings, filterData.data.data);
			this.downloadTableReport(
				"pos/getAgentOnlineTransactions",
				download
			);
		},
	},
	created() {
		this.getAgentTransactions();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
