<template>
  <div class="px-3">
    <div class="p-4">
      <div class="row table-like-body">
        <div class="col-4">MAIDEN NAME</div>
        <div class="col-4">EDUCATIONAL LEVEL</div>
        <div class="col-4">MARITAL STATUS</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.maiden_name || "N/A" }}</div>
        <div class="col-4">{{ details.education_level || "N/A" }}</div>
        <div class="col-4">{{ details.marital_status || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">TITLE</div>
        <div class="col-4">HEIGHT</div>
        <div class="col-4">RELIGION</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.title || "N/A" }}</div>
        <div class="col-4">{{ details.height || "N/A" }}</div>
        <div class="col-4">{{ details.religion || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">EMPLOYMENT STATUS</div>
        <div class="col-4">PROFESSION</div>
        <div class="col-4">NATIVE LANGUAGE</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.employment_status || "N/A" }}</div>
        <div class="col-4">{{ details.profession || "N/A" }}</div>
        <div class="col-4">{{ details.native_language || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">OTHER LANGUAGE</div>
        <div class="col-4">IDIGENE LGA OF ORIGIN</div>
        <div class="col-4">IDIGENE PLACE OF ORIGIN</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.other_language || "N/A" }}</div>
        <div class="col-4">{{ details.indigene_lga_of_origin || "N/A" }}</div>
        <div class="col-4">{{ details.indigene_place_of_origin || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">IDIGENE STATE OF ORIGIN</div>
        <div class="col-4">IDIGENE LGA OF BIRTH</div>
        <div class="col-4">IDIGENE STATE OF ORIGIN</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.indigene_state_of_origin || "N/A" }}</div>
        <div class="col-4">{{ details.indigene_lga_of_origin || "N/A" }}</div>
        <div class="col-4">{{ details.indigene_state_of_origin || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">LGA OF RESIDENCE</div>
        <div class="col-4">STATE OF RESIDENCE</div>
        <div class="col-4">RESIDENCE STATUS</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.lga_of_residence || "N/A" }}</div>
        <div class="col-4">{{ details.state_of_residence || "N/A" }}</div>
        <div class="col-4">{{ details.residence_status || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">NEXT OF KIN FIRST NAME</div>
        <div class="col-4">NEXT OF KIN LAST NAME</div>
        <div class="col-4">NEXT OF KIN ADDRESS</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.next_of_kin_first_name || "N/A" }}</div>
        <div class="col-4">{{ details.next_of_kin_last_name || "N/A" }}</div>
        <div class="col-4">{{ details.next_of_kin_address || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">NEXT OF KIN LGA</div>
        <div class="col-4">NEXT OF KIN TOWN</div>
        <div class="col-4">NEXT OF KIN STATE</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.next_of_kin_lga || "N/A" }}</div>
        <div class="col-4">{{ details.next_of_kin_town || "N/A" }}</div>
        <div class="col-4">{{ details.next_of_kin_state || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">IDIGENE COUNTRY OF BIRTH</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4">{{ details.indigene_country_of_birth || "N/A" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import "../style.css";

export default {
  name: "OtherInformation",
  mixins: [componentData],
  data() {
    return {
      details: null,
      ...this.tableOptions(tableHeadings, 20),
    };
  },
  computed: {
    details() {
      return this.$store.state.services.reportDetails;
    },
  },
  methods: {},
};
</script>

<style scoped>
.row {
  font-size: 15px;
}
.table-like-header {
  background: #ffffff;
}
.table-like-body {
  font-weight: 100;
}
</style>
