import remittance from "@/services/wallets/remittance";

export default {
  namespaced: true,
  actions: {
    async getTransactionHistory({}, payload) {
      try {
        return await remittance.getTransactionHistory(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getSenders({}, payload) {
      try {
        return await remittance.getSenders(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getSenderTransactionHistory({}, payload) {
      try {
        return await remittance.getSenderTransactionHistory(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getBeneficiaries({}, payload) {
      try {
        return await remittance.getBeneficiaries(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getCustomerBeneficiaries({}, payload) {
      try {
        return await remittance.getCustomerBeneficiaries(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getBeneficiaryTransaction({}, payload) {
      try {
        return await remittance.getBeneficiaryTransaction(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async addBeneficiary({}, payload) {
      return await remittance.addBeneficiary(payload);
    },
    async addCustomer({}, payload) {
      return await remittance.addCustomer(payload);
    },
    async createPayment({}, payload) {
      return await remittance.createPayment(payload);
    },
    async nameEnquiry({}, payload) {
      try {
        return await remittance.nameEnquiry(payload);
      } catch (error) {
        throw Error(error);
      }
    },
    async getChargeFee({}, payload) {
      try {
        return await remittance.getChargeFee(payload);
      } catch (error) {
        throw Error(error);
      }
    },
  },
};
