<template>
	<div class="mx-3">
		<button class="btn btn-sm btn--primary" @click="openAddAccountModal">
			Add Account
		</button>

		<AddMoreAccountModal
			:id="'addAccount'"
			:accountFile="accountFile"
			:closeModal="closeModal"
			:updateAccountList="updateAccountList"
		/>
	</div>
</template>

<script>
	import AddMoreAccountModal from "../../modals/AddMoreAccountModal/AddMoreAccountModal";

	export default {
		name: "AddMoreAccountButton",
		props: ["accountFile", "updateAccountList"],
		components: {
			AddMoreAccountModal,
		},
		methods: {
			openAddAccountModal() {
				$("#addAccount").modal("show");
			},
			closeModal() {
				$("#addAccount").modal("hide");
			},
		},
	};
</script>
