<template>
    <Modal
        :id="id"
        :title="`Delete Invoice ${invoice.invoice_reference}`"
        :toggle="handleCloseModal"
        :loading="loading"
    >
        <SuccessState
            v-if="successMsg"
            :message="'Invoice Deleted Successfully'"
            :onSuccess="handleRefetch"
        />
        <div v-else-if="loading" class="my-5">
            <Spinner :size="'20px'" />
        </div>

        <form v-else @submit.prevent="deleteInvoice">
            <h4 class="text-center my-4">
                Are you sure you want to delete invoice
                <b>{{ invoice.invoice_reference }}</b
                >?
            </h4>

            <FormButtons
                :btnName="'Delete'"
                :primary="false"
                :loading="loading"
                :handleCloseModal="handleCloseModal"
            />
        </form>
    </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../public/assets/mixins/filters";

const defaultValue = {
    loading: false,
    successMsg: false,
};

export default {
    mixins: [notifications, filters],
    name: "DeleteInvoiceModal",
    data() {
        return { ...defaultValue };
    },
    props: ["id", "invoice", "closeModal", "draftRef"],
    methods: {
        handleCloseModal() {
            Object.assign(this.$data, { ...defaultValue });
            this.closeModal();
        },
        handleRefetch() {
            this.handleCloseModal();
            location.reload();
        },
        handleError(msg) {
            this.loading = false;
            this.showErrorLoginNotification(msg);
        },
        async deleteInvoice() {
            try {
                this.loading = true;
                const response = await this.$store.dispatch(
                    "invoices/deleteInvoice",
                    {
                        apiKey: this.getWalletApikey(this.$store),
                        reference: this.invoice.invoice_reference,
                    }
                );
                const { status, data } = response || {};

                if (status === 200 && data.status) {
                    this.loading = false;
                    this.successMsg = true;
                } else this.handleError(data.message);
            } catch (error) {
                this.handleError(this.getError(error));
            }
        },
    },
};
</script>
