<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<template v-else>
			<div v-if="loading" class="flex__center mt-5 pt-5">
				<Spinner :size="'30px'" />
			</div>

			<FailedState
				v-else-if="errorMsg"
				:errorMsg="errorMsg"
				:refetch="() => getCustomer(routeParam.reference)"
			/>

			<SplitContent v-else :prevRoute="'/wallets/customers'">
				<template slot="sideview">
					<CustomerDetailSideView :customer="customer" />
				</template>

				<template slot="content">
					<CustomerDetialsContent :customer="customer" />
				</template>
			</SplitContent>
		</template>
	</div>
</template>

<script>
import CustomerDetialsContent from "./CustomerDetialsContent/CustomerDetialsContent";
import CustomerDetailSideView from "./CustomerDetailSideView/CustomerDetailSideView";
import EmptyWallet from "../../common/EmptyWallet/EmptyWallet";
import SplitContent from "../../../../utils/SplitContent/SplitContent";
import Spinner from "../../../../utils/Spinner/Spinner";
import FailedState from "../../../../utils/ResponseState/FailedState";
import { getWalletApikey } from "../../../../utils/helpers";

export default {
	name: "CustomerDetails",
	data() {
		return {
			routeParam: {},
			loading: false,
			errorMsg: "",
			customer: {},
		};
	},
	components: {
		CustomerDetialsContent,
		CustomerDetailSideView,
		SplitContent,
		EmptyWallet,
		Spinner,
		FailedState,
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		async getCustomer(reference) {
			try {
				this.loading = true;
				this.errorMsg = "";
				const response = await this.$store.dispatch(
					"customers/getCustomer",
					{
						apiKey: getWalletApikey(this.$store),
						reference,
					}
				);
				if (response.status === 200) {
					this.loading = false;
					this.customer = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = error.message;
			}
		},
	},

	async mounted() {
		const { reference, wallet_id } = this.$route.params;
		this.routeParam = { reference, wallet_id };
		this.getCustomer(reference);
	},
};
</script>
