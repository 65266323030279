<template>
	<div>
		<v-client-table
			class="v-table row-pointer items__table no-margin no-hover"
			:data="allItems"
			:columns="columns"
			:options="options"
		>
			<template slot="name" slot-scope="props">
				<ItemSelect
					v-if="props.index"
					:key="props.index"
					:item="items[props.index - 1].name"
					:action="getItems"
					:setRequest="(item) => setRequest(item, props.index - 1)"
					:itemsList="itemsList"
				/>
			</template>
			<template slot="amount" slot-scope="{ row }">
				<div class="text-center" style="padding: 10px 0">
					<span>{{ row.amount || 0 }}</span>
				</div>
			</template>
			<template slot="quantity" slot-scope="props">
				<CustomInput
					v-if="props.index"
					:name="'quantity'"
					:type="'number'"
					v-model="items[props.index - 1].quantity"
					:placeholder="'Quantity'"
					:min="1"
					:isNum="true"
				/>
			</template>

			<template slot="total" slot-scope="{ row }">
				<div class="text-center" style="padding: 10px 0">
					<span>{{ row.total || 0 }}</span>
				</div>
			</template>
			<template slot="action" slot-scope="props">
				<img
					v-if="props.index > 1"
					style="margin-top: 10px"
					src="/assets/img/icons/close-circle.svg"
					@click="() => removeItem(props.index - 1)"
					alt
				/>
			</template>
		</v-client-table>
		<p class="pointer font-weight-bold text--primary" @click="addItem">
			+ Add Line Item
		</p>

		<InvoiceTotalAnalysis
			:payload="payload"
			:updateRequest="updateRequest"
			:items="items"
		/>
	</div>
</template>

<script>
import { filters } from "../../../../../../public/assets/mixins/filters";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import InvoiceTotalAnalysis from "../../common/InvoiceTotalAnalysis/InvoiceTotalAnalysis";
import ItemSelect from "@/utils/Selects/ItemSelect/ItemSelect";
import { newInvoiceItemsHeadings, form } from "../../enums";

const initialForm = () => ({
	newform: {
		name: "",
		quantity: 1,
		amount: 0,
		total: 0,
	},
	items: [{ ...form }],
	itemsList: [],
});

export default {
	mixins: [filters, notifications],
	name: "ItemsTable",
	data() {
		return {
			...initialForm(),
			...this.tableOptions(
				newInvoiceItemsHeadings(
					this.payload.currency.code
						? this.payload.currency.code
						: this.payload.currency
				),
				5000,
				[],
				0
			),
		};
	},
	props: ["tax", "updateItem", "updateRequest", "payload"],
	components: {
		InvoiceTotalAnalysis,
		ItemSelect,
	},
	computed: {
		allItems() {
			const addedItems = this.items.map(({ name, quantity }) => ({
				name,
				amount: this.toCurrency(name.amount, " "),
				quantity,
				total: this.toCurrency(quantity * name.amount, " "),
			}));
			return addedItems;
		},
	},

	methods: {
		setRequest(value, index) {
			const newItems = [...this.items];
			newItems[index] = { ...form, name: value || "" };
			this.items = newItems;
		},

		async getItems() {
			try {
				const response = await this.$store.dispatch(
					"products/getProducts",
					{
						apiKey: this.getWalletApikey(this.$store),
						params: { limit: 100000000000000 },
					}
				);
				this.itemsList = response.data.data.data;
			} catch (error) {
				this.itemsList = [];
			}
		},
		addItem(e) {
			e.preventDefault();
			const payload = this.items[this.items.length - 1];

			const { isValid } = this.formValidator(payload);
			if (isValid) {
				this.items = [...this.items, { ...form }];
			} else this.showWarningNotification("Please Select an Item");
		},
		removeItem(index) {
			const newItems = [...this.items];
			newItems.splice(index, 1);
			this.items = newItems;
			this.updateItem(this.items);
		},
	},

	async mounted() {
		let currency = this.payload.currency;
		currency = currency.code ? currency.code : currency;
		this.items =
			this.payload.line_items.length > 0
				? this.payload.line_items
				: [{ ...form }];
		this.columns = Object.keys(newInvoiceItemsHeadings(currency)).map(
			(keyVal) => keyVal
		);
		this.getItems();
	},
	watch: {
		items: {
			handler(newItems) {
				this.updateItem(newItems);
			},
			deep: true,
		},
	},
};
</script>
