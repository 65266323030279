<template>
	<div
		class="tab-pane active fade"
		id="Sub_Account_Information"
		role="tabpanel"
		aria-labelledby="Sub_Account_Information-tab"
	>
		<TableWrapper
			:isEmpty="!subAccounts || subAccounts.length === 0"
			:emptyTitle="'No Sub Account Added'"
		>
			<template slot="content">
				<v-client-table
					class="v-table row-pointer mt-4"
					:columns="columns"
					:options="options"
					:data="subAccounts"
				>
					<template slot="value" slot-scope="{ row }">
						{{
							row.type === "flat"
								? toCurrency(row.value)
								: row.value
						}}
					</template>
				</v-client-table>
			</template>
		</TableWrapper>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "@/utils/TableWrapper/TableWrapper";
import { splitSubAccountHeading } from "../enums";

export default {
	mixins: [componentData],
	name: "SplitPaymentSubAccountInfo",
	props: ["subAccounts"],
	data() {
		return {
			...this.tableOptions(splitSubAccountHeading, 100),
		};
	},
	components: {
		TableWrapper,
	},
	mounted() {
		this.setColums(splitSubAccountHeading);
	},
};
</script>
