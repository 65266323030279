<template>
	<form @submit.prevent="handleSubmit">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-2 col-md-4 col-xl-2">
				<CustomInput
					:name="'userId'"
					:placeholder="'User ID'"
					v-model="filters.userId"
					:handleError="handleError"
				/>
			</div>
			<div class="col-sm-6 px-sm-2 col-md-4 col-xl-2">
				<CustomInput
					:name="'name'"
					:placeholder="' Name'"
					v-model="filters.name"
					:handleError="handleError"
				/>
			</div>
			<div class="col-sm-6 px-sm-2 col-md-4 col-xl-2">
				<CustomInput
					:name="'event'"
					:placeholder="'Event'"
					v-model="filters.event"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6 px-sm-2 col-md-4 col-xl-2">
				<CustomSelect
					:name="'category'"
					:placeholder="'Category'"
					:optionLabel="'name'"
					v-model="filters.category"
					:options="auditCategories"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>

				<div class="d-inline-block">
					<!-- <DownloadReport :downloadReport="download" :loading="rLoading" /> -->
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import { filters } from "../../../../public/assets/mixins/filters";
import { auditCategories } from "../enums";

const initialState = () => ({
	filters: {
		name: "",
		userId: "",
		event: "",
		category: null,
	},
	isFilter: false,
	qLoading: false,
});

export default {
	mixins: [filters],
	name: "AuditTrailFilter",
	data() {
		return {
			...initialState(),
			auditCategories,
		};
	},
	props: ["loading", "rLoading", "clearFilter", "submitFilter"],
	computed: {
		disabled() {
			return !Object.values(this.filters).some((val) => val);
		},
	},
	methods: {
		resetFilters() {
			Object.assign(this.$data, { ...initialState() });
			this.clearFilter();
			this.isFilter = false;
		},
		handleSubmit() {
			this.isFilter = true;
			if (this.filters.from)
				this.filters.from = this.timeRangeFormat(
					this.filters.from,
					true
				);
			if (this.filters.to)
				this.filters.to = this.timeRangeFormat(this.filters.to);
			this.submitFilter(this.filters);
		},
	},
	watch: {
		"filters.from": function () {
			this.filters.to = null;
		},
	},
};
</script>
