<template>
  <div
    class="tab-pane active fade show"
    id="Terminal_Information"
    role="tabpanel"
    aria-labelledby="Terminal_Information-tab"
  >
    <div v-if="loading" class="col-12">
      <div class="flex__center my-5 py-5">
        <Spinner :size="'30px'" />
      </div>
    </div>
    <div class="row mt-4" v-else>
      <div class="col-md-6 mb-4">
        <ul class="sub_card h-100">
          <h5 class="mt-4 pt-4">Virtual Terminal Information</h5>
          <li>
            <p>Terminal ID</p>
            <p>{{ titleCase(virtualTerminalData.deviceId) }}</p>
          </li>
          <li>
            <p>Terminal Name</p>
            <p>{{ titleCase(virtualTerminalData.deviceName) }}</p>
          </li>
          <li>
            <p>Device OS</p>
            <p>{{ virtualTerminalData.deviceOs }}</p>
          </li>
          <h5 class="mt-4 pt-4">Agent Information</h5>
          <li>
            <p>Status</p>
            <p
              :class="
                virtualTerminalData.agent.status === 'active'
                  ? 'text-success'
                  : 'text-danger'
              "
            >
              {{ titleCase(virtualTerminalData.agent.status) }}
            </p>
          </li>
          <li>
            <p>Agent's Name</p>
            <p>{{ virtualTerminalData.agent.accountName }}</p>
          </li>
          <li>
            <p>Agent's Email</p>
            <p>{{ virtualTerminalData.agent.email }}</p>
          </li>
          <li>
            <p>Agent's Phone Number</p>
            <p>{{ virtualTerminalData.agent.phoneNumber }}</p>
          </li>
          <li>
            <p>Agent Reference</p>
            <p>{{ virtualTerminalData.agent.reference }}</p>
          </li>
          <li>
            <p>Bank Name</p>
            <p>{{ virtualTerminalData.agent.bank_name }}</p>
          </li>
          <li>
            <p>Account Number</p>
            <p>{{ virtualTerminalData.agent.accountNumber }}</p>
          </li>
         
        </ul>
      </div>
      <div class="col-md-6 d-none">
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">N580,000,000</h2>
          <p class="call-name">Total Amount Procesed</p>
        </div>
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">53</h2>
          <p class="call-name">Number of Transactions</p>
        </div>
        <div class="dashboard-brief border py-3">
          <h2 class="call-count">0</h2>
          <p class="call-name">N78,829,890</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";
import {
  titleCase,
  toCurrency,
  statusColor,
  timeDateFormat,
  getWalletApikey,
} from "../../../../../../utils/helpers";

export default {
  name: "TerminalInformation",
  data() {
    return {
      loading: true,
      virtualTerminalData: null,
    };
  },
  components: {
    Spinner,
  },
  methods: {
    titleCase,
    toCurrency,
    statusColor,
    timeDateFormat,
    async getPosDetails() {
      const payload = {
        apiKey: getWalletApikey(this.$store),
        params: {
          // ...data,
          size: this.size,
          ...this.filters,
          id: this.$route.params.id,
        },
      };
      return await this.$store
        .dispatch("pos/getVirtualTerminalsDetails", payload)
        .then((response) => {
          this.virtualTerminalData = response.data.data;
          this.loading = false;
        });
    },
  },
  async mounted() {
    this.getPosDetails();
  },
};
</script>
