<template>
  <div class="row">
    <div class="col-12">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row d-flex justify-content-between">
            <div>
              <img src="/assets/img/blusalt-logo-dark.png" alt width="100px" />
            </div>
            <div>
              <p>{{ wallet.accountEmail }}</p>
            </div>
          </div>
          <div class="mt-5">
            <div v-if="fundStep == 1">
              <form @submit.prevent="selectPaymenMethod" class="px-5">
                <div class="row mt-5">
                  <div class="col-sm-12 pb-5">
                    <h3 class="text-center themed-text">
                      <strong>Fund your wallet</strong>
                    </h3>
                  </div>
                  <!-- Currency -->
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="input-item-label">Select Wallet</label>
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                      <v-select
                        class="v-select"
                        placeholder="Select currency"
                        v-model="selectedWallet"
                        @keydown.enter.native.prevent
                        :required="!selectedWallet"
                        :disabled="!baseWallet"
                        :options="[...baseWallet]"
                        label="currency"
                      ></v-select>
                    </div>
                  </div>
                  <!-- Amount -->
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="input-item-label">Enter Amount</label>
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                      <input
                        v-model="amount"
                        required
                        type="number"
                        min="50000"
                        class="form-control input-bordered"
                        placeholder="Enter amount to fund"
                      />
                      <span class="text-danger" title="This field is required"
                        >Minimum of {{ selectedWallet.currency }} 50,000</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="row justify-content-between px-3 py-5">
                      <button class="btn btn-outline" @click="onCloseButton">
                        <span>Cancel</span>
                      </button>
                      <button class="btn themed-button" type="submit">
                        <span class="text-white">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="fundStep == 2">
              <div>
                <div class="col-12 py-5 my-2 px-5" v-if="tabDisplay == 0">
                  <div>
                    <div class="col-sm-12 pb-4">
                      <h3 class="text-center themed-text">
                        <strong>Select method of payment</strong>
                      </h3>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn themed-outline btn-lg btn-block py-2"
                    @click="switchTab(1)"
                  >
                    <div class="row">
                      <div class="col-2">
                        <i class="la la-mobile la-2x pt-1"></i>
                      </div>
                      <div class="col-10">
                        <p class="text-left pt-2">Pay with USSD</p>
                      </div>
                    </div>
                  </button>
                  <br />
                  <button
                    type="button"
                    class="btn themed-outline btn-lg btn-block py-2"
                    @click="switchTab(2)"
                  >
                    <div class="row">
                      <div class="col-2">
                        <i class="la la-exchange la-2x pt-1"></i>
                      </div>
                      <div class="col-10">
                        <h5 class="text-left pt-2">Pay with Bank Transfer</h5>
                      </div>
                    </div>
                  </button>
                  <div class="text-center pt-5">
                    <p class="btn btn-default" @click="fundStep = 1">Cancel</p>
                  </div>
                </div>
                <div class="col-12 mt-3 px-5 mx-2" v-if="tabDisplay == 1">
                  <div>
                    <div class="col-sm-12 pb-4">
                      <h3 class="text-center themed-text">
                        <strong>Payment Details</strong>
                      </h3>
                    </div>
                  </div>
                  <p>Select Bank to Transfer from</p>
                  <button
                    type="button"
                    class="btn themed-outline btn-lg btn-block py-2"
                    v-for="bank in ussdBanks"
                    :key="bank.label"
                    @click="displayUSSD(bank)"
                  >
                    <p>
                      <span class="float-left">{{ bank.label }}</span>
                      <span
                        class="badge badge-pill themed-button text-white float-right mt-1"
                      >
                        * {{ bank.code }} #
                      </span>
                    </p>
                  </button>
                  <br />
                  <div class="text-center">
                    <p class="btn btn-default" @click="tabDisplay = 0">
                      Cancel
                    </p>
                  </div>
                </div>

                <div class="col-12 mt-3 px-5" v-if="tabDisplay == 2">
                  <div>
                    <div class="col-sm-12 pb-4">
                      <h3 class="text-center themed-text">
                        <strong>Payment Details</strong>
                      </h3>
                    </div>
                  </div>
                  <div class="col-12">
                    <label class="input-item-label text-center">
                      Kindly pay {{ selectedWallet.currency }}
                      {{ Number(amount).toLocaleString() }} to the account
                      details shown below using your mobile/internet banking app
                    </label>

                    <table class="table table-bordered pt-3">
                      <tbody>
                        <tr>
                          <td>Amount</td>
                          <td>
                            <strong>
                              {{ selectedWallet.currency }}
                              {{ Number(amount).toLocaleString() }}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Account Number</td>
                          <td>
                            <strong>{{ wallet.accountNumber }} </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td>
                            <strong>{{ wallet.bankName }} </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <vac :end-time="new Date().getTime() + 20 * 60000">
                      <p
                        class="text-right"
                        slot="process"
                        slot-scope="{ timeObj }"
                      >
                        {{ `Expires in : ${timeObj.m}:${timeObj.s}` }}
                      </p>
                      <p class="text-right" slot="finish">Expired!</p>
                    </vac>
                  </div>

                  <div class="col-12 mt-3">
                    <button
                      class="btn btn-lg themed-button btn-block"
                      type="submit"
                      @click="confirmPayment"
                    >
                      <span class="text-white">Waiting for payment</span>
                    </button>
                    <div class="text-center">
                      <p class="btn btn-default" @click="tabDisplay = 0">
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-3" v-if="tabDisplay == 3">
                  <div>
                    <div class="col-sm-12 pb-4">
                      <h3 class="text-center themed-text">
                        <strong>Payment Details</strong>
                      </h3>
                    </div>
                  </div>

                  <div>
                    <div class="col-sm-12 py-4">
                      <p class="text-center">
                        Dial the code below to complete your transaction
                      </p>
                    </div>
                  </div>
                  <div class="col-12">
                    <table class="table table-bordered pt-3">
                      <tbody>
                        <tr>
                          <td>{{ ussdBank.label }}</td>
                          <td class="pull-right px-3">
                            <strong class="badge themed-button text-white pa-3">
                              * {{ ussdBank.ussdCode }} #
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-12">
                    <div class="bg-light py-2">
                      <h2 class="text-center mt-4 pt-2">
                        <strong>
                          * {{ ussdBank.ussdCode }} * {{ amount }} *
                          {{ wallet.accountNumber }} #
                        </strong>
                      </h2>
                      <div class="row justify-content-center pb-4">
                        <p
                          class="text-center mt-4 btn themed-button btn-sm text-white"
                          v-clipboard="
                            `*${ussdBank.ussdCode}*${amount}*${wallet.accountNumber}#`
                          "
                          v-clipboard:success="clipboardSuccessHandler"
                        >
                          Copy <i class="la la-copy"></i>
                        </p>
                      </div>
                    </div>
                    <vac :end-time="new Date().getTime() + 20 * 60000">
                      <p
                        class="text-right"
                        slot="process"
                        slot-scope="{ timeObj }"
                      >
                        {{ `Expires in : ${timeObj.m}:${timeObj.s}` }}
                      </p>
                      <p class="text-right" slot="finish">Expired!</p>
                    </vac>
                  </div>

                  <div class="col-12 mt-3">
                    <button
                      class="btn btn-lg themed-button btn-block"
                      type="submit"
                      @click="confirmPayment"
                    >
                      <span class="text-white">Waiting for payment</span>
                    </button>
                    <div class="text-center">
                      <p class="btn btn-default" @click="tabDisplay = 1">
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="fundStep == 3">
              <div v-if="fundLoading">
                <div class="row my-5 py-5 justify-content-center">
                  <vue-loaders-ball-beat
                    color="#1d4486"
                    scale="1"
                  ></vue-loaders-ball-beat>
                </div>
              </div>
              <div v-else>
                <div class="row px-4">
                  <div class="col-sm-12 pb-4">
                    <h3 class="text-center themed-text">
                      <strong>Payment Confirmation</strong>
                    </h3>
                  </div>
                  <div class="col-12">
                    <h5 class="text-center py-3">
                      You paid
                      <strong
                        >{{ selectedWallet.currency }}
                        {{ Number(amount).toLocaleString() }}</strong
                      >
                      to the acount below
                    </h5>

                    <table class="table table-bordered pt-3">
                      <tbody>
                        <tr>
                          <td>Amount</td>
                          <td>
                            <strong>
                              {{ selectedWallet.currency }}
                              {{ Number(amount).toLocaleString() }}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Account Number</td>
                          <td>
                            <strong>{{ wallet.accountNumber }} </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td>
                            <strong>{{ wallet.bankName }} </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-12 my-5">
                    <button
                      class="btn btn-lg themed-button btn-block"
                      @click="completePayment"
                    >
                      <span class="text-white">Complete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="fundStep == 4">
              <div v-if="fundLoading">
                <div class="row my-5 justify-content-center">
                  <vue-loaders-ball-beat
                    color="#1d4486"
                    scale="1"
                  ></vue-loaders-ball-beat>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-12">
                    <div class="row mt-2 mb-5 justify-content-center">
                      <h3 class="text-center">
                        <i
                          class="la-2x themed-text"
                          :class="`la la-${alertIcon}`"
                        ></i>
                      </h3>
                      <br />
                    </div>
                  </div>
                  <div class="col-sm-12 pb-4">
                    <h3 class="text-center themed-text">
                      <strong>{{ alertTitle }}</strong>
                    </h3>
                    <p class="text-center">
                      {{ alertMessage }}
                    </p>
                  </div>

                  <div class="col-sm-12 pb-4 text-center">
                    <h4
                      class="text-center btn btn-default"
                      @click="onCloseButton"
                      v-if="paymentSuccess"
                    >
                      <strong>Close</strong>
                    </h4>
                    <h4
                      class="text-center btn btn-default"
                      v-else
                      @click="fundStep = 1"
                    >
                      <strong>Try Again</strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row justify-content-center py-5">
        <p class="text-center text-white">
          Powered by <strong>Blusalt</strong>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
export default {
  methods: {
    onCloseButton(event) {
      this.fundStep = 1;
      this.tabDisplay = 0;
      this.$emit("clicked", "close");
    },
    switchTab(tab) {
      this.tabDisplay = tab;
    },
    async confirmPayment() {
      this.fundLoading = true;
      this.checkPayment();
    },
    async checkPayment() {
      const updatedProfile = await this.$store.dispatch("account/getProfile");
      const updatedWallets = updatedProfile.customer_wallet;
      const updatedWallet = updatedWallets.find((wallet) => {
        return wallet.currency == this.selectedWallet.currency;
      });
      if (
        Number(this.selectedWallet.balance) + Number(this.amount) ==
        updatedWallet.balance
      ) {
        this.paymentSuccess = true;
        this.fundLoading = false;
        this.fundStep = 3;
      } else {
        console.log("I never see the money");
        this.paymentSuccess = false;
        this.fundLoading = false;
        this.completePayment();
        this.fundStep = 4;
      }
    },
    closeFundWallet() {
      this.tabDisplay = 0;
      this.amount = "";
      this.fundStep = 1;
      this.fundLoading = false;
      $("#blusaltFunding").modal("hide");
      if (this.alertMessage != "") {
        window.location.reload();
        this.alertMessage = "";
      }
    },
    selectPaymenMethod() {
      this.fundStep = 2;
    },
    completePayment() {
      if (this.paymentSuccess) {
        this.alertTitle = "Transaction Successful!";
        this.alertIcon = "check-circle";
        this.alertMessage = "Your wallet has been funded succesfully";
      } else {
        this.alertTitle = "Transaction Failed";
        this.alertIcon = "close";
        this.alertMessage = "We haven’t received any payment yet.";
      }
      this.fundStep = 4;
    },
    displayUSSD(bank) {
      this.ussdBank = bank;
      this.tabDisplay = 3;
      // this.bankUssdSelect = true;
    },
    expandWallets() {
      this.expandWallet = !this.expandWallet;
    },
    ussdSelector() {
      this.bankUssdSelect = false;
    },
    closeWalletFunding() {
      this.fundWallet = false;
      this.bankUssdSelect = false;
    },
    walletFunding() {
      $("#blusaltFunding").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
      this.startTimer(60 * 5);
    },
    getClientProfile() {
      this.clientProfile = this.$store.getters["account/getClientProfile"];
      this.currency = this.baseWallet.currency;
      const activeWallet = {
        currency: this.baseWallet.currency,
        balance: this.baseWallet.balance,
        accountName: this.baseWallet.bank.account_name.split("-")[1],
        accountNumber: this.baseWallet.bank.account_number,
        bankName: this.baseWallet.bank.bank_name,
        walletID: this.baseWallet.bank.wallet_id,
        accountEmail: this.clientProfile.email,
      };
      activeWallet.history = this.walletHistories.filter(
        (history) => history.currency == this.baseWallet.currency
      );
      this.wallet = activeWallet;
      console.log("Active wallet History", this.wallet.history);
    },
    async getCurrencies() {
      try {
        const currencies = await this.$store.dispatch("wallets/getCurrencies");
        this.currencies = currencies.data.map((item) => {
          return item.code;
        });
      } catch (error) {
        console.log("Currency error :", error);
      }
    },
    clipboardSuccessHandler({ value, event }) {
      console.log("Clipboard Value", value);
      this.showSuccessNotification("successfully copied to clipboard!");
    },
  },
  mixins: [notifications, modalMixin],
  data() {
    return {
      wallets: [],
      //wallet data
      clientProfile: "",
      expandWallet: false,
      fundWallet: false,
      walletTab: 1,
      wallet: {},
      transactionList: [],
      loading: true,
      transactions: [],
      columns: ["reference", "action", "createdAt", "amount"],
      options: {
        sortable: [],
        perPage: 15,
        pagination: {
          chunk: 5,
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search..",
          noResults: "No matching records",
        },
        headings: {
          reference: "Tx Reference",
          action: "Type",
          createdAt: "Payment Method",
          amount: "Amount",
        },
      },
      bankUssdSelect: false,
      ussdBanks: [
        {
          label: "GT Bank",
          code: "737",
          ussdCode: "737 * 2",
        },
        {
          label: "First Bank",
          code: "894",
          ussdCode: "894",
        },
        {
          label: "Zenith Bank",
          code: "966",
          ussdCode: "966",
        },
        {
          label: "Access Bank",
          code: "901",
          ussdCode: "901 * 2",
        },
        {
          label: "UBA Bank",
          code: "919",
          ussdCode: "919",
        },
        {
          label: "Polaris Bank",
          code: "833",
          ussdCode: "833",
        },
      ],
      ussdBank: {},
      selectedBank: {
        name: "",
        ussdCode: "",
      },
      customerWallets: this.$store.state.account.customerWallets,
      baseWallet: this.$store.state.account.baseWallet,
      selectedWallet: "",
      currencies: [],
      walletHistories: [],
      currency: "",
      amount: "",
      fundStep: 1,
      tabDisplay: 0,
      countDownTime: "",
      fundLoading: false,
      startDate: null,
      endDate: null,
      alertTitle: "",
      alertIcon: "",
      alertMessage: "",
      paymentSuccess: true,
    };
  },
  async mounted() {
    this.loading = true;

    this.getCurrencies();
    this.getClientProfile();
    this.loading = false;
  },
};
</script>
