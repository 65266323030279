<template>
	<div class="mr-3">
		<div class="dropdown az-profile-menu">
			<img
				type="button dropdown-toggle"
				data-toggle="dropdown"
				src="/assets/img/icons/ellipsis.svg"
				alt
				width="30px"
			/>

			<div class="dropdown-menu">
				<span v-if="invoice.status === 'issued'" class="dropdown-item">
					<a
						class="text-dark"
						target="_blank"
						:href="invoice.invoice_link"
						>View
					</a>
				</span>
				<span
					v-if="invoice.status === 'issued'"
					class="dropdown-item"
					@click="() => copy(invoice.invoice_link)"
				>
					Copy Link
				</span>
				<AccessControl>
					<span
						v-if="invoice.status === 'draft'"
						class="dropdown-item"
						@click="editInvoice"
					>
						Edit
					</span>
				</AccessControl>
				<AccessControl>
					<span class="dropdown-item" @click="duplicateInvoice">
						Duplicate
					</span>
				</AccessControl>
				<AccessControl>
					<span
						v-if="invoice.status === 'draft'"
						class="dropdown-item"
						@click="openIssueInvoiceModal"
					>
						Issue
					</span>
				</AccessControl>
				<AccessControl>
					<span
						class="dropdown-item text-danger"
						@click="openDeleteInvoiceModal"
					>
						Delete
					</span>
				</AccessControl>
			</div>
		</div>

		<IssueInvoiceModal
			:id="invoice.invoice_reference"
			:invoice="invoice"
			:closeModal="closeModal"
			:draftRef="draftRef"
		/>

		<DeleteInvoiceModal
			:id="`del${invoice.invoice_reference}`"
			:invoice="invoice"
			:closeModal="closeModal"
			:draftRef="draftRef"
		/>
	</div>
</template>

<script>
import AccessControl from "@/utils/AccessControl/AccessControl";
import IssueInvoiceModal from "../../modals/IssueInvoiceModal/IssueInvoiceModal";
import DeleteInvoiceModal from "../../modals/DeleteInvoiceModal/DeleteInvoiceModal";

export default {
	name: "DraftActionButton",
	props: ["draftRef", "invoice", "copy", "editInvoice", "duplicateInvoice"],
	components: {
		AccessControl,
		IssueInvoiceModal,
		DeleteInvoiceModal,
	},
	methods: {
		openIssueInvoiceModal() {
			$(`#${this.invoice.invoice_reference}`).modal("show");
		},
		openDeleteInvoiceModal() {
			$(`#del${this.invoice.invoice_reference}`).modal("show");
		},
		closeModal() {
			$(`#${this.invoice.invoice_reference}`).modal("hide");
			$(`#del${this.invoice.invoice_reference}`).modal("hide");
		},
	},
};
</script>
