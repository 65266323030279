<template>
	<div class="user-settings-section border-0">
		<div class="invoice-info mt-4 border-top-0">
			<p class="invoice-info-row preview-row ">
				<span>Card Scheme</span>
				<span>{{
					titleCase(result.cardScheme) || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Card Type</span>
				<span>{{
					titleCase(result.cardType) || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Card Country</span>
				<span>{{
					result.county.cardCountry || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Card Currency</span>
				<span>{{
					result.county.cardCurrency || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Country Latitutude</span>
				<span>{{
					result.county.countryLatitude || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Country Longitude</span>
				<span>{{
					result.county.countryLongitude ||
						"Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Bank Name</span>
				<span
					>{{
						result.cardIssuer.bankName ||
							"Information not available"
					}}
				</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Bank Url</span>
				<span>{{
					result.cardIssuer.bankURL || "Information not available"
				}}</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Bank Phone</span>
				<span>{{
					result.cardIssuer.bankPhone || "Information not available"
				}}</span>
			</p>
		</div>
	</div>
</template>

<script>
	import { titleCase } from "../../../../../utils/helpers";

	export default {
		name: "BINVerificationResult",
		props: ["result"],
		methods: {
			titleCase,
		},
	};
</script>
