import { apiClient } from "./api";

export default {
	userAccounts() {
		return apiClient.get(`Account/ListUserProfiles`);
	},
	getProfile() {
		return apiClient.get(`Authorize/Profile`);
	},
	editProfile(profileDetails) {
		return apiClient.put(`Authorize/EditProfile`, profileDetails);
	},
	editAccountServices(accountDetail) {
		let headers = { clientid: accountDetail.clientid };

		return apiClient.put(
			`Authorize/EditCustomerService`,
			accountDetail.payload,
			{ headers }
		);
	},
	passwordChange(credential) {
		return apiClient.post(`Authorize/ResetPassword`, credential);
	},
	getDashboardAnalytics(applicationDetails) {
		let headers = { clientid: applicationDetails.clientID };
		return apiClient.post(`Authorize/Dashboard`, {}, { headers });
	},
	generateMfaOtp() {
		return apiClient.post(`Authorize/GenerateMfaOtp`);
	},
  updateMfa(payload) {
		return apiClient.post(`Authorize/UpdateMfaStatus`, payload);
	},
	addTeamMember(requestData) {
		let headers = { clientid: requestData.clientID };
		return apiClient.post(`Authorize/AddTeamMember`, requestData.payload, {
			headers,
		});
	},

	// White labelling routes
	getSingleSecondaryCustomers(credential) {
		let headers = { clientid: credential };
		return apiClient.get(`Authorize/SingleSecondaryCustomers`, { headers });
	},
	getAppSetting() {
		return apiClient.get(`Authorize/AppSetting`);
	},
	getSecondaryCustomers() {
		return apiClient.get(`Authorize/SecondaryCustomers`);
	},
	SecondaryCustomerCreditLogs() {
		return apiClient.get(`/Authorize/Primary/SecondaryCustomerCreditLogs`);
	},
	actualSecondaryCustomerLog(requestHeaders) {
		let headers = { clientid: requestHeaders.clientID };
		return apiClient.get(
			`Authorize/WlCustomer/ActualSecondaryCustomerLog`,
			{ headers }
		);
	},
	customerRequestWhiteLabelling(requestHeaders) {
		let headers = { clientid: requestHeaders.clientid };
		return apiClient.get(`Authorize/InternalWLRegistration`, { headers });
	},
	SecondaryCustomerLoadCredit(requestData) {
		return apiClient.put(
			`Authorize/SecondaryCustomer/LoadCredit/${requestData.clientid}`,
			requestData.payload
		);
	},
	editSecondaryCustomerProfile(profileDetails) {
		return apiClient.put(
			`/Authorize/SecondaryCustomerEditProfile`,
			profileDetails
		);
	},
	primaryCustomerEditSecondaryCustomer(profileDetails) {
		console.log("testing", profileDetails);
		let headers = { clientid: profileDetails.user_clientid };
		return apiClient.put(
			`/Authorize/PrimaryCustomerEditProfile/${profileDetails.clientid}`,
			profileDetails.payload,
			{ headers }
		);
	},
	updateSecondaryCustomerPricing(requestData) {
		let headers = { clientid: requestData.clientid };
		return apiClient.patch(
			`Authorize/WlCustomer/DefaultServicesPrice/${requestData.serviceId}`,
			requestData.payload,
			{ headers }
		);
	},
	getAllWhiteLabelledServices() {
		return apiClient.get(`Authorize/WlCustomer/DefaultServicesPrices`);
	},
	getSecondaryCustomerServicePricing(requestHeaders) {
		let headers = { clientid: requestHeaders.clientID };
		return apiClient.get(`Authorize/SecondaryCustomer/ServicesPrices`, {
			headers,
		});
	},
	updateSecondaryCustomerServicePricing(requestData) {
		let headers = { clientid: requestData.clientid };
		return apiClient.patch(
			`Authorize/SecondaryCustomer/ServicesPrice/${requestData.serviceId}`,
			requestData.payload,
			{ headers }
		);
	},
	// Upload KYC
	uploadKYCData(requestData) {
		console.log("KYC Data", requestData);
		let headers = {
			clientid: requestData.clientID,
			"Content-Type": "multipart/form-data",
		};
		return apiClient.post(
			`Authorize/UploadKYCDocument`,
			requestData.payload,
			{ headers }
		);
	},
	// Get KYC Documents
	getKYCData(requestHeaders) {
		let headers = {
			clientid: requestHeaders.clientID,
		};
		return apiClient.get(`Authorize/GetKYCDocuments`, { headers });
	},
	// View KYC Document
	viewKYCDocument(requestData) {
		let headers = {
			clientid: requestData.clientID,
		};
		return apiClient.post(
			`Authorize/ViewKYCDocument/${requestData.documentId}`,
			{ headers }
		);
	},
  // Get Compliance
	getCompliance() {
		return apiClient.get(`Authorize/Compliance`);
	},
  addCompliance(payload) {
		return apiClient.post(`Authorize/Compliance`, payload);
	},
  deleteDirector(id) {
		return apiClient.delete(`Authorize/ComplianceDirector/${id}`);
	}
};
