<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openCreatePaymentCollectionModal"
		>
			Create Collection
		</button>
		<CreatePaymentCollectionModal
			:id="'createPaymentCollection'"
			:closeModal="closeModal"
			:collectionRef="collectionRef"
		/>
	</div>
</template>

<script>
	import CreatePaymentCollectionModal from "../../modals/CreatePaymentCollectionModal/CreatePaymentCollectionModal";

	export default {
		name: "CreatePaymentCollectionButton",
		props: ["collectionRef"],
		methods: {
			openCreatePaymentCollectionModal() {
				$("#createPaymentCollection").modal("show");
			},
			closeModal() {
				$("#createPaymentCollection").modal("hide");
			},
		},
		components: {
			CreatePaymentCollectionModal,
		},
	};
</script>
