<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="SectionItem no-b-b mb-3">
        <div class="row">
          <div class="col-12">
            <div class="SectionHeader">
              <span class="title">Application Billings</span>
              <div class="description">
                Overview of your billings on the portal
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="user-settings-section">
              <div class="flex-wrapper-space-between pt-5">
                <div class="payment-details--info">
                  <h2 class="payment-section-hdr">Total Credit Loaded</h2>
                  <div class="text-span">
                    This is the total number of credits loaded into the
                    application.
                  </div>
                </div>
                <div class="balance-container">
                  <h1 class="payment-balance">
                    {{
                      Number(
                        apiUser.api_customer.total_credit_loaded
                      ).toLocaleString()
                    }}
                  </h1>
                  <!-- <a href="#" class="btn btn-lg btn-primary">Add test credit</a> -->
                </div>
              </div>
              <hr />
              <div class="flex-wrapper-space-between">
                <div class="payment-details--info">
                  <h2 class="payment-section-hdr">Available Credit Balance</h2>
                  <div class="text-span">
                    This is the total number of credits available to be utilized
                    for any of the services registered against your account.
                  </div>
                </div>
                <div class="balance-container">
                  <h1 class="payment-balance">
                    {{
                      Number(
                        apiUser.api_customer.total_credit_remaining
                      ).toLocaleString()
                    }}
                  </h1>
                  <!-- <a href="#" class="btn btn-lg btn-primary">Add test credit</a> -->
                </div>
              </div>
              <hr />
              <div class="flex-wrapper-space-between">
                <div class="payment-details--info">
                  <h2 class="payment-section-hdr">
                    Available Credit for Applications
                  </h2>
                  <div class="text-span">
                    This is the total number of credits available to be utilized
                    for any of the applications created.
                  </div>
                </div>
                <div class="balance-container">
                  <h1 class="payment-balance">
                    {{
                      Number(
                        apiUser.api_customer.credit_for_apps_and_sec_customers
                      ).toLocaleString()
                    }}
                  </h1>
                  <!-- <a href="#" class="btn btn-lg btn-primary">Add test credit</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    apiUser: {
      type: Object,
    },
  },
  computed: {
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
};
</script>

<style></style>
