<template>
  <div
    class="tab-pane fade show pt-3"
    id="Cash_Registers"
    role="tabpanel"
    aria-labelledby="Cash_Registers-tab"
  >
    <div class="row">
      <div class="col-12 mt-3"></div>
      <div v-if="loading" class="col-12">
        <div class="flex__center my-5 py-5">
          <Spinner :size="'30px'" />
        </div>
      </div>

      <div v-else-if="errorMsg" class="col-12">
        <FailedState :errorMsg="errorMsg" :refetch="refetch" />
      </div>
      <div class="col-12" :class="tbClass">
        <div class="px-3">
          <FilterWrapper>
            <template slot="pageSelect"> </template>
            <template slot="filterForm">
              <TransactionsTableFilters
                :loading="loading"
                :rLoading="rLoading"
                :submitFilter="refetch"
                :clearFilter="clearFilter"
                :download="downloadPosTransactions"
              />
            </template>
          </FilterWrapper>
        </div>
        <v-server-table
          ref="registerRef"
          class="v-table"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="setLoading(false)"
          @error="setError"
          @row-click="showData"
        >
          <template slot="amount" slot-scope="{ row }">
            <span>
              {{ toCurrency(row.amount, row.currency) }}
            </span>
          </template>
          <template slot="created_at" slot-scope="{ row }">
            <span>
              {{
                timeDateFormat(row.created_at).date +
                " " +
                timeDateFormat(row.created_at).time
              }}
            </span>
          </template>
          <template slot="status" slot-scope="{ row }">
            <span :class="statusColor(row.status)">{{
              titleCase(row.status)
            }}</span>
          </template>
        </v-server-table>
      </div>
    </div>
    <CashRegisterModal
      v-if="showSideModal"
      :data="modalData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import CashRegisterModal from "../CashRegisters/CashRegisterModal.vue";
import Tabs from "../../../../../utils/Tabs/Tabs.vue";
import AccessControl from "@/utils/AccessControl/AccessControl";
import TransactionsTableFilters from "../TransactionsTableFilters/TransactionsTableFilters.vue";
import FilterWrapper from "@/utils/FilterWrapper/FilterWrapper";
import FailedState from "@/utils/ResponseState/FailedState";
import Spinner from "@/utils/Spinner/Spinner";
import {
  timeDateFormat,
  tableOptions,
  titleCase,
  toCurrency,
  getWalletApikey,
  statusColor,
} from "@/utils/helpers";
const tableHeadings = {
  reference: "Transaction Reference",
  amount: "Amount",
  customerName: "Customer Name",
  customerEmail: "Customer Email",
  created_at: "Created At",
  narration: "Narration",
};
export default {
  mixins: [componentData],
  name: "CashRegisters",
  data() {
    return {
      loading: false,
      rLoading: false,
      showSideModal: false,
      modalData: null,
      stats: null,
      errorMsg: "",
      size: 20,
      filters: {},
      ...tableOptions(tableHeadings, 20),
    };
  },
  components: {
    TransactionsTableFilters,
    CashRegisterModal,
    FilterWrapper,
    Tabs,
    AccessControl,
    FailedState,
    Spinner,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    statusColor,
    showData({ row }) {
      this.modalData = row;
      this.showSideModal = true;
    },
    closeModal(e) {
      this.showSideModal = false;
      this.modalData = null;
    },
    timeDateFormat,
    titleCase,
    toCurrency,
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      if (filters) this.filters = filters;
      this.fetchPosTransactions();
      this.$refs.registerRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.registerRef.refresh();
    },

    async fetchPosTransactions() {
      this.options.requestFunction = async (data) => {
        const payload = {
          apiKey: getWalletApikey(this.$store),
          params: {
            ...data,
            size: this.size,
            ...this.filters,
            id: this.$route.params.id,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch(
              "pos/getPosTransactionsCashRegister",
              payload
            )
          ).data.data;
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("posError", error);
        }
      };
    },
    async downloadPosTransactions() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data.data);
      this.downloadTableReport("pos/getPosTransactionsCashRegister", download);
    },
  },
  created() {
    this.fetchPosTransactions();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
