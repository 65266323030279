<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">Direct Debit Transactions</span>
            <div class="description">
              View all transactions from customers carried out on your direct
              debit account.
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <FilterBar
          @clicked="onFilterClick"
          :activityTypes="activityTypes"
        ></FilterBar>
        <div class="row">
          <div class="col-12">
            <v-client-table
              :data="tableData"
              :columns="columns"
              :options="options"
            >
              <template slot="createdAt" slot-scope="props">
                <div>
                  {{ props.row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
                </div>
              </template>
            </v-client-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "../UI/FilterBar";
import moment from "moment";

export default {
  components: {
    FilterBar,
  },
  data() {
    return {
      activityTypes: ["none", "create-mandate", "request", "validate-otp"],
      tableData: [],
      allTransactions: [],
      columns: ["mandateId", "action", "amount", "createdAt"],
      options: {
        sortable: [],
        perPage: 10,
        pagination: {
          chunk: 5,
        },
        headings: {
          mandateId: "Mandate ID",
          action: "Action",
          amount: "Amount",
          createdAt: "Created At",
        },
        perPageValues: [],
        filterByColumn: true,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
  methods: {
    onFilterClick(value) {
      let result = this.allTransactions;

      if (value.endTime) {
        result = result.filter(
          (transaction) => transaction.createdAt < value.endTime
        );
      }
      if (value.startTime) {
        result = result.filter(
          (transaction) => transaction.createdAt > value.startTime
        );
      }

      if (value.dateRange) {
        const startOfDay = moment()
          .startOf("day")
          .format("MMM D, YYYY, HH:mmA");
        const startOfWeek = moment()
          .startOf("week")
          .format("MMM D, YYYY, HH:mmA");
        const startOfMonth = moment()
          .startOf("month")
          .format("MMM D, YYYY, HH:mmA");

        if (value.dateRange == "none") return result;

        if (value.dateRange == "Today") {
          result = result.filter(
            (transaction) => startOfDay > moment(transaction.createdAt)
          );
        }

        if (value.dateRange == "This week") {
          result = result.filter(
            (transaction) => startOfWeek > moment(transaction.createdAt)
          );
        }

        if (value.dateRange == "This month") {
          result = result.filter(
            (transaction) => startOfMonth > moment(transaction.createdAt)
          );
        }
      }

      if (value.activityType) {
        if (value.activityType == "none") return result;
        result = result.filter(
          (transaction) => transaction.action == value.activityType
        );
      }
      this.tableData = result;
    },
    async getDDTransactions() {
      try {
        let merchantData = {
          clientID: this.user.api_customer.client_id,
        };
        const ddTransactions = await this.$store.dispatch(
          "directdebit/getDirectDebitTransactions",
          merchantData
        );
        this.allTransactions = ddTransactions.data;
        this.tableData = this.allTransactions;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.getDDTransactions();
  },
};
</script>
