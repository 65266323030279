<template>
	<div class="row">
		<div class="col-12 mt-3" v-if="!baseWallet">
			<DefaultContent
				:image="'empty-resources.png'"
				:title="'No Wallet Data Found'"
				:subtitle="'Contact admin to activate wallets.'"
			></DefaultContent>
		</div>
		<div class="col-lg-12" v-else>
			<div class="SectionItem no-b-b mb-3">
				<div class="row">
					<div class="col-12">
						<div class="SectionHeader">
							<span class="title">Wallet</span>
							<div class="description">
								Wallet funding, history and manage wallet
								activities
							</div>
						</div>
					</div>
					<div class="col-12 mt-5">
						<div class="user-details--wrapper">
							<div class="row">
								<div
									class="col-sm-1"
									v-if="!expandWallet"
									@click="expandWallets"
								>
									<h2 class="themed-text">
										<i class="la la-arrow-down"></i>
									</h2>
								</div>
								<div
									class="col-sm-1"
									v-else
									@click="expandWallets"
								>
									<h2 class="themed-text">
										<i class="la la-close"></i>
									</h2>
								</div>
								<div class="col-sm-8">
									<h3 class="themed-text">
										{{ wallet.currency }}
										{{
											Number(
												wallet.balance
											).toLocaleString()
										}}
									</h3>
									<h6>
										CURRENT {{ wallet.currency }} WALLET
										BALANCE
									</h6>
								</div>
								<div class="col-sm-3" v-if="!fundWallet">
									<button
										type="button"
										class="btn themed-button pull-right"
										v-if="!expandWallet"
										@click="popModal"
									>
										Fund Wallet
									</button>
									<button
										type="button"
										class="btn btn-tertiary pull-right"
										v-else
									>
										Selected Wallet
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div v-if="!expandWallet">
							<div class="user-details--wrapper">
								<h5 class="card-title">WALLET INFO</h5>
								<br />
								<p>Account Name</p>
								<h5>
									{{ wallet.accountName }}
								</h5>
								<hr />
								<p>Account Number</p>
								<h5>{{ wallet.accountNumber }}</h5>
								<hr />
								<p>Bank Name</p>
								<h5>{{ wallet.bankName }}</h5>
							</div>

							<div class="user-details--wrapper">
								<h5 class="card-title">
									WALLET FUNDING HISTORY
								</h5>
								<br />
								<p>History of funds through wallet</p>
								<div v-if="wallet.history.length > 0">
									<v-client-table
										:data="wallet.history"
										:columns="columns"
										:options="options"
									>
										<template
											slot="createdAt"
											slot-scope="props"
										>
											<div>
												{{
													props.row.createdAt
														| moment(
															" MM-DD-YYYY h:mm a"
														)
												}}
											</div>
										</template>

										<template
											slot="amount"
											slot-scope="props"
										>
											<div>
												{{
													Number(
														props.row.amount
													).toLocaleString()
												}}
											</div>
										</template>
									</v-client-table>
								</div>
								<div v-else>
									<div class="row justify-content-center">
										<img
											src="/assets/img/noDataFound.png"
											alt
											width="500px"
										/>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<div class="user-details--wrapper">
								<div
									v-for="currentWallet in user.customer_wallet"
									:key="currentWallet.wallet_id"
								>
									<div
										class="wallet-balance-card"
										v-if="
											currentWallet.currency !==
												wallet.currency
										"
									>
										<div class="row">
											<div class="col-sm-1"></div>
											<div class="col-sm-8">
												<h3 class="themed-text">
													{{ currentWallet.currency }}
													{{
														Number(
															currentWallet.balance
														).toLocaleString()
													}}
												</h3>
												<h6>
													CURRENT
													{{
														currentWallet.currency
													}}
													WALLET BALANCE
												</h6>
											</div>
											<div class="col-sm-3">
												<button
													type="button"
													class="btn btn-primary pull-right"
													@click="
														walletTabswitch(
															currentWallet
														)
													"
												>
													Select Wallet
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal 13-->
		<div
			class="modal fade"
			id="busaltWalletModal"
			tabindex="-1"
			role="dialog"
			aria-labelledby="busaltWalletModalTitle"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog-centered" role="document">
				<WalletFunding
					@clicked="closeModal"
					:walletLists="[...baseWallet]"
				></WalletFunding>
			</div>
		</div>
		<!-- modal end  -->
	</div>
</template>

<script>
	import { notifications } from "../../../public/assets/mixins/notifications";
	import WalletInfo from "@/components/wallet/WalletInfo";
	import WalletFunding from "@/components/wallet/WalletFunding";
	export default {
		mixins: [notifications],
		components: {
			WalletInfo,
			WalletFunding,
		},
		computed: {
			user() {
				return this.$store.state.account.profile;
			},
		},
		methods: {
			popModal() {
				$("#busaltWalletModal").modal({
					backdrop: "static",
					keyboard: false,
					show: true,
				});
			},
			closeModal() {
				$("#busaltWalletModal").modal("hide");
			},
			walletTabswitch(wallet) {
				// console.log("Selected Wallet", wallet);
				// const activeWallet = {
				//   currency: wallet.currency,
				//   balance: wallet.balance,
				//   accountName: wallet.bank.account_name.split("-")[1],
				//   accountNumber: wallet.bank.account_number,
				//   bankName: wallet.bank.bank_name,
				//   walletID: wallet.bank.wallet_id,
				//   accountEmail: this.clientProfile.email,
				// };
				// this.wallet = activeWallet;
				// this.expandWallet = !this.expandWallet;

				this.showErrorRegistrationNotification(
					"Wallet currently not available"
				);
			},
			expandWallets() {
				this.expandWallet = !this.expandWallet;
			},
			ussdSelector() {
				this.bankUssdSelect = false;
			},
			closeWalletFunding() {
				this.fundWallet = false;
				this.bankUssdSelect = false;
			},
			getClientProfile() {
				this.clientProfile = this.$store.getters[
					"account/getClientProfile"
				];
				this.currency = this.baseWallet.currency;
				const activeWallet = {
					currency: this.baseWallet.currency,
					balance: this.baseWallet.balance,
					accountName: this.baseWallet.account_name,
					accountNumber: this.baseWallet.bank.account_number,
					bankName: this.baseWallet.bank.bank_name,
					walletID: this.baseWallet.bank.wallet_id,
					accountEmail: this.clientProfile.email,
				};
				activeWallet.history = this.walletHistories.filter(
					(history) => history.currency == this.baseWallet.currency
				);
				this.wallet = activeWallet;
			},
			async getCurrencies() {
				try {
					const currencies = await this.$store.dispatch(
						"wallets/getCurrencies"
					);
					this.currencies = currencies.data.map((item) => {
						return item.code;
					});
				} catch (error) {
					console.log("Currency error :", error);
				}
			},
			async getWalletHistory() {
				try {
					this.loading = true;
					const historyResponse = await this.$store.dispatch(
						"wallets/getWalletHistory"
					);
					this.walletHistories = historyResponse.results;
					this.wallet.history = historyResponse.results;
					this.loading = false;
				} catch (error) {
					console.log("Get wallet history :", error);
				}
			},
		},
		data() {
			return {
				wallets: [],
				clientProfile: "",
				expandWallet: false,
				fundWallet: false,
				wallet: {},
				transactionList: [],
				loading: true,
				transactions: [],
				columns: ["reference", "action", "createdAt", "amount"],
				merchantWallets: [
					"none",
					"create-mandate",
					"request",
					"validate-otp",
				],
				options: {
					sortable: [],
					perPage: 10,
					pagination: {
						chunk: 5,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search..",
						noResults: "No matching records",
					},
					headings: {
						reference: "Tx Reference",
						action: "Type",
						createdAt: "Payment Method",
						amount: "Amount",
					},
				},
				bankUssdSelect: false,
				selectedBank: {
					name: "",
					ussdCode: "",
				},
				customerWallets: this.$store.state.account.customerWallets,
				baseWallet: this.$store.state.account.baseWallet,
				selectedWallet: "",
				currencies: [],
				walletHistories: [],
				currency: "",
				amount: "",
				countDownTime: "",
				startDate: null,
				endDate: null,
			};
		},
		async mounted() {
			this.getCurrencies();
			this.getClientProfile();
			this.getWalletHistory();
		},
	};
</script>

<style scoped>
	.light-grey-bg {
		background-color: #ededed !important;
	}
	.input-group-text {
		cursor: pointer;
	}
	.api__keys {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.wallet-balance-card {
		width: 100%;
	}
	.themed-text {
		color: #1d4486 !important;
	}
	.wallet-info-card {
		padding-left: 0 !important;
	}
	.wallet-history-card {
		padding-right: 0 !important;
	}
	.nav-pills .nav-link.active {
		background-color: #ededed !important;
		color: #1d4486 !important;
	}
	.no-padding-btn {
		padding: 0 !important;
		margin: 0 !important;
	}
	.nav-link {
		padding: 10px 5px !important;
		margin: 0 !important;
	}
</style>
