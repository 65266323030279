<template>
	<div
		class="tab-pane fade show active"
		id="Wallet"
		role="tabpanel"
		aria-labelledby="Wallet-tab"
	>
		<div class="card p-3">
			<div class="flex__between pb-3 border-bottom">
				<div class="d-flex">
					<div class="wallet__switch active">
						<h4>{{ wallet.currency }}</h4>
					</div>
				</div>
				<AccessControl>
					<div class="d-flex">
						<DepositFundButton
							:app="'wallet'"
							:setDefault="true"
							:walletId="wallet.wallet_id"
							:refetch="refetch"
						/>
						<RequestFundButton :walletId="wallet.wallet_id" /></div
				></AccessControl>
			</div>

			<div class="row pb-3">
				<div class="col-sm-5 text-center border-right">
					<p class="call-name pt-4 py-3">
						Your Available Balance
					</p>
					<h4 class="call-count">
						{{ wallet.balance ? toCurrency(wallet.balance) : 0 }}
					</h4>
				</div>

				<div class="col-sm-7">
					<p class="call-name py-4">Account Details</p>
					<div class="d-flex">
						<h4 class="m-0">{{ wallet.bank_name }}</h4>
						<h4 class="mx-5 my-0">
							{{ wallet.account_number || "None" }}
						</h4>
						<img
							v-if="wallet.account_number"
							src="/assets/img/icons/copy.svg"
							class="pointer"
							alt=""
							@click="copy(wallet.account_number)"
						/>
					</div>
				</div>
			</div>
		</div>
		<TransactionsTable
			:title="'Wallet Transactions'"
			:params="tableParam"
			:perPage="15"
		/>
	</div>
</template>

<script>
	import { notifications } from "../../../../../../../public/assets/mixins/notifications";
	import DepositFundButton from "../../../../WalletOverview/buttons/DepositFundButton/DepositFundButton";
	import RequestFundButton from "../../../../WalletOverview/buttons/RequestFundButton/RequestFundButton";
	import TransactionsTable from "../../../../common/TransactionsTable/TransactionsTable";
	import AccessControl from "@/utils/AccessControl/AccessControl";
	import { toCurrency, copyToClipboard } from "@/utils/helpers";

	export default {
		mixins: [notifications],
		name: "WalletTab",
		data() {
			return {
				tableParam: { wallet: this.$route.params.reference },
			};
		},
		props: ["wallet", "refetch"],
		components: {
			DepositFundButton,
			RequestFundButton,
			TransactionsTable,
			AccessControl,
		},
		methods: {
			toCurrency,
			copy(value) {
				const copied = copyToClipboard(value);
				copied &&
					this.showSuccessNotification("Account Number Copied!");
			},
		},
	};
</script>
