<template>
    <Modal
        :id="id"
        :title="`Delete ${item.name}`"
        :toggle="handleCloseModal"
        :loading="loading"
    >
        <SuccessState
            v-if="successMsg"
            :message="'Item Deleted Successfully'"
            :onSuccess="handleRefetch"
        />
        <div v-else-if="loading" class="my-5">
            <Spinner :size="'20px'" />
        </div>

        <form v-else @submit.prevent="deleteItem">
            <h4 class="text-center my-4">
                Are you sure you want to delete <b>{{ item.name }}</b
                >?
            </h4>

            <FormButtons
                :btnName="'Delete'"
                :primary="false"
                :loading="loading"
                :handleCloseModal="handleCloseModal"
            />
        </form>
    </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../public/assets/mixins/filters";

const defaultValue = () => ({
    loading: false,
    successMsg: false,
});

export default {
    mixins: [notifications, filters],
    name: "DeleteItemModal",
    data() {
        return { ...defaultValue() };
    },
    props: ["id", "item", "closeModal"],
    methods: {
        handleCloseModal() {
            Object.assign(this.$data, { ...defaultValue() });
            this.closeModal();
        },
        handleRefetch() {
            this.handleCloseModal();
            location.reload();
        },
        setError(msg) {
            this.loading = false;
            this.showErrorLoginNotification(msg);
        },
        async deleteItem() {
            try {
                this.loading = true;
                const response = await this.$store.dispatch(
                    "products/deleteProduct",
                    {
                        apiKey: this.getWalletApikey(this.$store),
                        reference: this.item.reference,
                    }
                );

                const { status, data } = response || {};

                if (status === 200 && (data.status || data.success)) {
                    this.loading = false;
                    this.successMsg = true;
                } else this.setError(data.message);
            } catch (error) {
                this.setError(this.getError(error));
            }
        },
    },
};
</script>
