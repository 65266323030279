<template>
	<Modal :id="id" :title="'Payments Activation'" :toggle="closeModal">
		<ValidateSenderMask v-if="step === 1" :nextStep="nextStep" />

		<template v-else>
			<SuccessState
				v-if="successMsg"
				:message="'Wallet Activated Successfully'"
				:onSuccess="handleRefetch"
			/>

			<form
				id="activate"
				v-else
				@submit.prevent="handleSubmit"
				class="text-left"
			>
				<BankProviderSelect
					:name="'preferred_bank'"
					:error="formErrors.preferred_bank"
					:handleError="handleError"
					v-model="request.preferred_bank"
				/>

				<FormButtons
					:loading="loading"
					:handleCloseModal="prevStep"
					:closeBtnName="'Back'"
				/>
			</form>
		</template>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../utils/Modal/Modal";
	import ValidateSenderMask from "./ValidateSenderMask/ValidateSenderMask";
	import BankProviderSelect from "../../../../../utils/Selects/BankProviderSelect/BankProviderSelect";
	import SuccessState from "../../../../../utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
	import FormButtons from "../../../../../utils/FormButtons/FormButtons";

	export default {
		mixins: [notifications],
		name: "ActivateWalletModal",
		data() {
			return {
				request: {
					preferred_bank: "",
				},
				step: 1,
				formErrors: {},
				successMsg: false,
				loading: false,
			};
		},
		props: ["id", "closeModal"],
		components: {
			Modal,
			ValidateSenderMask,
			BankProviderSelect,
			FormButtons,
			SuccessState,
		},
		methods: {
			nextStep(data = {}) {
				this.request = { ...this.request, ...data };
				this.step += 1;
			},
			prevStep() {
				this.request = { preferred_bank: this.request.preferred_bank };
				this.step -= 1;
			},
			handleRefetch() {
				this.closeModal();
				location.reload();
				this.$router.push("/wallets-overview");
			},
			handleSubmit() {
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) this.activateWallet();
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			async activateWallet() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"wallets/activateWallet",
						{
							...this.request,
							preferred_bank: this.request.preferred_bank.id,
						}
					);
					const { status } = response || {};
					if (status === 201 || status === 200) {
						this.loading = false;
						this.successMsg = true;
					}
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
