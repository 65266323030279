<template>
	<div
		class="tab-pane fade"
		id="Business_Information"
		role="tabpanel"
		aria-labelledby="Business_Information-tab"
	>
		<div class="card overflow-hidden mt-3">
			<div v-if="customer.business" class="row py-3">
				<div class="col-md-3 text-center border-right ">
					<p class="call-name pt-2">Business Name</p>
					<h4>
						{{ customer.business_details.name || "---------" }}
					</h4>
				</div>

				<div class="col-md-3 text-center border-right ">
					<p class="call-name pt-2">RC Number</p>
					<h4>
						{{ customer.business_details.rc_number || "---------" }}
					</h4>
				</div>

				<div class="col-md-3 text-center border-right ">
					<p class="call-name pt-2">TIN</p>
					<h4>
						{{ customer.business_details.tin || "---------" }}
					</h4>
				</div>

				<div class="col-md-3 text-center border-right ">
					<p class="call-name pt-2">Address</p>
					<span>
						{{ customer.business_details.address || "---------" }}
					</span>
				</div>
			</div>

			<div v-else class="flex__center py-5">
				<h5 class="text-danger">You are not a business customer</h5>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "BusinessInformation",
		props: ["customer"],
	};
</script>
