<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">BVN Request form</h2>
                <h5 class="user-profile-email sub">
                  Verify BVN identities of various users.
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyBVN">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      BVN Number
                      <span class="text-danger" title="This field is required"
                        >*</span
                      >
                    </label>
                    <input
                      type="text"
                      v-model="bvn"
                      @blur="$v.bvn.$touch()"
                      class="form-control input-bordered"
                      placeholder="Enter BVN number"
                      @keypress="onlyNumbers"
                    />
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.bvn.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.bvn) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- phone numbeer -->
                <div class="col-md-6">
                  <CustomPhoneInput
                    :onlyCountries="['NG']"
                    label="Phone Number"
                    v-model="phonenumber"
                    @blur="$v.phonenumber.$touch()"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phonenumber.$error && formVuelidator($v.phonenumber)
                    "
                  />
                </div>

                <!-- Application details -->
                <div class="col-md-12">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- API Version details -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label">
                    Select an API Version
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an API version"
                    v-model="apiVersion"
                    @close="$v.apiVersion.$touch()"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul
                    class="parsley-errors-list filled"
                    v-show="$v.apiVersion.$error"
                  >
                    <li class="parsley-required">
                      API version field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          @blur="$v.call_id.$touch()"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Verifying BVN</span>
                    <span v-else>Verify BVN</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Response Page -->
        <div
          class="user-settings-section"
          v-if="typeof verificationResult == 'object'"
        >
          <div class="user-settings-section" v-if="hasNewResponse">
            <div class="user-settings-section-header">
              <div class="user-settings-section-header-text">
                <h1>BVN Request Response</h1>
                <span class="text-span"
                  >Preview of the information gotten from bvn request made</span
                >
              </div>
              <a
                href="#"
                class="btn btn-outline-danger"
                @click="hasNewResponse = false"
              >
                <i class="la la-close"></i>Close Panel
              </a>
            </div>
            <div class="preview-content" v-if="apiVersion == 'v1'">
              <p class="invoice-info-row preview-row">
                <span>Fullname</span>
                <span>{{ verificationResult.full_name }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Firstname</span>
                <span>{{ verificationResult.first_name }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Middlename</span>
                <span>
                  {{ verificationResult.middle_name || "Not Available" }}
                </span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Lastname</span>
                <span>{{ verificationResult.last_name }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>BVN</span>
                <span>{{ verificationResult.bvn_number }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Date of Birth</span>
                <span>{{ verificationResult.formatted_date_of_birth }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Phone number</span>
                <span>{{ verificationResult.phone_number }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Request Reference</span>
                <span>{{ verificationResult.request_reference }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Verification Status</span>
                <span>{{ verificationResult.verification_status }}</span>
              </p>

              <div class="gaps-1-5x"></div>
            </div>
            <div class="preview-content" v-else>
              <p class="invoice-info-row preview-row">
                <span>Fullname</span>
                <span>{{ verificationResult.personal_info.full_name }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Firstname</span>
                <span>{{ verificationResult.personal_info.first_name }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Middlename</span>
                <span>
                  {{
                    verificationResult.personal_info.middle_name ||
                    "Not Available"
                  }}
                </span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Lastname</span>
                <span>{{ verificationResult.personal_info.last_name }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Email:</span>
                <span>{{
                  verificationResult.personal_info.email ||
                  "Information not available"
                }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>BVN</span>
                <span>{{ verificationResult.bvn_number }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Reg Date</span>
                <span>{{
                  verificationResult.formatted_registration_date
                }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Date of Birth</span>
                <span>{{
                  verificationResult.personal_info.formatted_date_of_birth
                }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>LGA</span>
                <span>{{
                  verificationResult.personal_info.lga_of_origin
                }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>State of origin</span>
                <span>{{
                  verificationResult.personal_info.state_of_origin
                }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Nationality</span>
                <span>{{ verificationResult.personal_info.nationality }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Marital Status</span>
                <span>{{
                  verificationResult.personal_info.marital_status
                }}</span>
              </p>
              <!-- <p class="invoice-info-row preview-row">
              <span>Address</span>
              <span>{{ verificationResult.residential_info.residential_address }}</span>
            </p> -->
              <p class="invoice-info-row preview-row">
                <span>Phone number</span>
                <span>{{ verificationResult.personal_info.phone_number }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Request Reference</span>
                <span>{{ verificationResult.request_reference }}</span>
              </p>
              <p class="invoice-info-row preview-row">
                <span>Verification Status</span>
                <span>{{ verificationResult.verification_status }}</span>
              </p>

              <div class="gaps-1-5x"></div>
            </div>
          </div>
        </div>

        <div class="user-settings-section" v-else>
          <p class="invoice-info-row preview-row">
            <span>Sorry, you have entered an invalid BVN </span>
          </p>
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}IdentityVerification/BVN`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
          <!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
          <!-- "dob": "{{dob}}" -->
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "phone_number": "{{phonenumber.formatted ? phonenumber.formatted.replace(/\s/g, "") : "phonenumber"}}",
        "bvn_number": "{{bvn ? bvn : "bvn_number"}}",
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
          "status_code": 200,
          "status": "Success",
          "message": "BVN Verification Completed Successfully",
          "results": {
            "request_reference": "xxqq1122eddg",
            "bvn_number": "01234567890",
            "name_on_card": "CIROMA CHUKWUMA ADEKUNLE",
            "enrolment_branch": "Lagos",
            "enrolment_bank": "Blusalt",
            "formatted_registration_date": null,
            "level_of_account": null,
            "nin": "01234567890",
            "watchlisted": null,
            "verification_status": "VERIFIED",
            "service_type": "BVN Verification without Image",
            "personal_info": {
              "first_name": "CIOMA",
              "middle_name": "",
              "last_name": "ADEKUNLE",
              "full_name": "CIROMA CHUKWUMA ADEKUNLE",
              "email": "chioma@dekunle.com",
              "gender": "female",
              "phone_number": "08012345678",
              "phone_number_2": null,
              "date_of_birth": "01-Oct-1960",
              "formatted_date_of_birth": "February 2, 1987",
              "lga_of_origin": null,
              "state_of_origin": null,
              "nationality": null,
              "marital_status": null
            },
            "residential_info": {
              "state_of_residence": null,
              "lga_of_residence": null,
              "residential_address": null
            }
          }
        }`}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import {
  required,
  minLength,
  numeric,
  maxLength,
} from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    phonenumber: {
      isPhoneValid: isPhoneValid("phonenumber"),
    },
    bvn: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11),
    },
    apiVersion: { required },
    app: { required },
  },
  components: {
    CustomPhoneInput,
  },
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "",
      call_id: "",
      clientID: "",
      bvn: "",
      apikey: "",
      appname: "",
      phonenumber: "",
      firstname: "",
      lastname: "",
      hasNewResponse: false,
      dob: "",
      logData: [],
      apiResponse: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  computed: {
    API() {
      if (this.apiVersion == "v2" || this.apiVersion == "") {
        return this.$store.state.appUrlV2;
      } else {
        return process.env.VUE_APP_BASE_URL;
      }
    },
  },
  methods: {
    formVuelidator,
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("BVN")) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },

    getRequestData() {
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          bvn_number: this.bvn,
          phone_number: this.phonenumber.formatted.replace(/\s/g, ""),
          apiVersion: this.apiVersion,
          currency_type: this.currencyType.currency,
          // first_name: this.firstname,
          // last_name: this.lastname,
          // dob: this.dob,
        },
        query: this.call_id,
      };

      return verificationData;
    },
    async verifyBVN() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;

      try {
        let apiResponse = await this.$store.dispatch(
          "services/verifyBVN",
          verificationData
        );
        console.log("[api Response from bvn ] ->", apiResponse);
        this.apiResponse = apiResponse;
        this.showSuccessNotification(apiResponse.message);
        this.verificationResult = apiResponse.results;
        this.hasNewResponse = true;
        this.isSubmitting = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isSubmitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;

    console.log("Customer Wallets", this.$store.state.account.customerWallets);
  },
};
</script>
