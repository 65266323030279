<template>
	<form @submit.prevent="handleSubmit">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<CustomInput
					:name="'couponCode'"
					v-model="filters.couponCode"
					:placeholder="'Coupon Code'"
				/>
			</div>

			<div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
				<DatePicker
					:name="'from'"
					v-model="filters.from"
					:placeholder="'Start Date'"
					:disabledDates="{ from: new Date() }"
				/>
			</div>

			<div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
				<DatePicker
					:name="'to'"
					v-model="filters.to"
					:disabledDates="{ from: new Date(), to: filters.from }"
					:placeholder="'End Date'"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>

				<div class="d-inline-block">
					<DownloadReport
						:downloadReport="download"
						:loading="rLoading"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import DatePicker from "../../../../../utils/DatePicker/DatePicker";
	import CustomInput from "../../../../../utils/CustomInput/CustomInput";
	import Spinner from "../../../../../utils/Spinner/Spinner";
	import DownloadReport from "../../../../../utils/DownloadReport/DownloadReport";
	import { timeRangeFormat } from "../../../../../utils/helpers";

	const initialState = () => ({
		filters: {
			couponCode: "",
			from: null,
			to: null,
		},
		isFilter: false,
		qLoading: false,
	});

	export default {
		name: "CouponTableFilters",
		data() {
			return { ...initialState() };
		},
		props: [
			"loading",
			"rLoading",
			"clearFilter",
			"submitFilter",
			"download",
		],
		components: {
			DatePicker,
			CustomInput,
			Spinner,
			DownloadReport,
		},
		computed: {
			disabled() {
				return !Object.values(this.filters).some((val) => val);
			},
		},
		methods: {
			resetFilters() {
				Object.assign(this.$data, { ...initialState() });
				this.clearFilter();
				this.isFilter = false;
			},
			handleSubmit() {
				this.isFilter = true;
				if (this.filters.from)
					this.filters.from = timeRangeFormat(
						this.filters.from,
						true
					);
				if (this.filters.to)
					this.filters.to = timeRangeFormat(this.filters.to);
				this.submitFilter(this.filters);
			},
		},
		watch: {
			"filters.from": function() {
				this.filters.to = null;
			},
		},
	};
</script>
