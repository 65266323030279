<template>
	<div class="col-sm-6 text-center mb-3">
		<div class="card h-100 px-3 pt-3">
			<ContentWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="getTransactions"
			>
				<template slot="content">
					<div class="flex__between border-bottom">
						<p>
							Total Transactions ({{ range }}) -
							{{ transactions.total || 0 }}
						</p>
						<p
							class="pointer text-primary"
							@click="$router.push('/wallets/transactions')"
						>
							See All Transactions
						</p>
					</div>

					<div class="row flex__center pt-3">
						<div
							class="col-sm-6 col-12 w-100 border-right small-form-group"
						>
							<div :style="{ width: '160px' }">
								<CustomSelect
									:placeholder="'Select Range'"
									v-model="range"
									:options="rangeOptions"
								/>
							</div>
							<h4 class="call-count mt-5 mb-3">
								{{ transactions.total || 0 }}
							</h4>
							<p class="call-name py-2">Transactions Initiated</p>
						</div>

						<TransactionsChart :transactions="transactions" />
					</div>
				</template>
			</ContentWrapper>
		</div>
	</div>
</template>

<script>
import {
	notifications,
	componentData,
} from "../../../../../public/assets/mixins";
import TransactionsChart from "./TransactionsChart";
import { getDateRange } from "@/utils/helpers";

const rangeOptions = ["Today", "This Week", "This Month", "This Year"];
const defaultFilter = {
	from: getDateRange(rangeOptions[0]),
	to: getDateRange(),
};

export default {
	mixins: [notifications, componentData],
	name: "TotalTransactions",
	data() {
		return {
			range: "Today",
			rangeOptions,
			transactions: {},
			filters: defaultFilter,
		};
	},
	props: ["apiKey", "currency"],
	components: {
		TransactionsChart,
	},
	methods: {
		async getTransactions() {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"transactions/getAllTransactions",
					{
						apiKey: this.getWalletApikey(this.$store),
						params: this.filters,
					}
				);
				const { status, data } = response || {};
				if (status === 200) {
					this.loading = false;
					this.transactions = data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = this.getError(error);
			}
		},
	},
	watch: {
		range: function (newRange) {
			this.filters = {
				...defaultFilter,
        currency: this.currency,
				from: getDateRange(newRange),
			};
			this.getTransactions();
		},
    currency() {
			this.filters = {
				...this.filters,
        currency: this.currency,
      }
			this.getTransactions();
    }
	},
	async mounted() {
		this.getTransactions();
	},
};
</script>
