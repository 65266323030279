<template>
	<div>
		<CustomerInfoModal
			:id="'customerInfo'"
			:closeModal="closeModal"
			:customer="customer"
		/>

		<AddressWarningModal
			:id="'addressWarning'"
			:closeModal="closeModal"
			:continue="handleCreateInvoice"
		/>

		<SuccessModal
			v-if="duplicate"
			:id="'invoiceCreationSuccess'"
			:title="`Invoice Duplicated`"
			:message="`Invoice Duplicated Successfully
				`"
			:onSuccess="backToInvoice"
		/>
		<SuccessModal
			v-else
			:id="'invoiceCreationSuccess'"
			:title="`${status ? 'New Invoice Created' : 'Invoice Updated'}`"
			:message="`${
				status
					? 'Invoice Created Successfully'
					: 'Invoice Updated Successfully'
			}`"
			:onSuccess="backToInvoice"
		/>
	</div>
</template>

<script>
import CustomerInfoModal from "../CustomerInfoModal/CustomerInfoModal";
import AddressWarningModal from "../AddressWarningModal/AddressWarningModal";
import SuccessModal from "@/utils/Modal/SuccessModal/SuccessModal";

export default {
	name: "InvoiceActionModal",
	props: [
		"status",
		"customer",
		"closeModal",
		"backToInvoice",
		"handleCreateInvoice",
		"duplicate",
	],
	components: {
		SuccessModal,
		AddressWarningModal,
		CustomerInfoModal,
	},
};
</script>
