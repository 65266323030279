<template>
	<div class="p-4">
		<div v-if="loading" class="flex__center mt-5 pt-5">
			<Spinner :size="'30px'" />
		</div>

		<FailedState
			v-else-if="errorMsg"
			:errorMsg="errorMsg"
			:refetch="() => getPlan(reference)"
		/>

		<template v-else>
			<div class="SectionItem no-b-b mb-4 flex__beteen flex-wrap">
				<div class="d-flex align-items-center">
					<img
						src="/assets/img/icons/arrow-left.svg"
						width="30"
						alt=""
						class="pointer"
						@click="$router.push('/wallet/subscriptions/plans')"
					/>
					<h4 class="font-weight-bold ml-3 mb-0">
						{{ details.plan.name }}
					</h4>
				</div>
				<div class="d-flex">
					<SubscribeButton
						:plan="details ? details.plan : {}"
						:refetch="() => getPlan(reference)"
					/>
					<UpdatePlanButton
						v-if="details.plan.type !== 'services'"
						:plan="{ ...(newPlan.plan || {}), tax: newPlan.tax }"
						:refetch="() => getPlan(reference)"
					/>
					<!-- <DeletePlanButton :reference="reference" /> -->
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-md-6 mb-4">
					<ul class="card sub_card h-100">
						<h5>Plan Details</h5>
						<li>
							<p>Plan ID</p>
							<p>
								{{ details.plan.id }}
							</p>
						</li>
						<li>
							<p>Amount</p>
							<p>
								{{ details.plan.currency }}
								{{ details.plan.amount }}
							</p>
						</li>
						<li>
							<p>Setup Amount</p>
							<p>
								{{ details.plan.currency }}
								{{ details.plan.setup_amount }}
							</p>
						</li>
						<li>
							<p>Interval</p>
							<p>
								{{
									titleCase(details.plan.interval) ||
									"Not Available"
								}}
							</p>
						</li>
						<li>
							<p>VAT</p>
							<p>
								{{
									details.plan.TaxId
										? `${newPlan.tax.name} ( ${newPlan.tax.rate} )`
										: "None"
								}}
							</p>
						</li>

						<li>
							<p>Status</p>
							<p>
								{{ details.plan.status }}
							</p>
						</li>
						<li>
							<p>Description</p>
							<p>
								{{
									details.plan.description || "Not Available"
								}}
							</p>
						</li>
					</ul>
				</div>

				<div class="col-md-6 mb-4">
					<ul class="card sub_card h-100">
						<h5>Subscription Details</h5>
						<li>
							<p>Total Amount Subscribed</p>
							<p>
								{{ details.plan.currency }}
								{{ details.totalAmount }}
							</p>
						</li>
						<li>
							<p>Total Subscription</p>
							<p>
								{{ details.totalSubscription || 0 }}
							</p>
						</li>
						<li>
							<p>Total Subscribers</p>
							<p>
								{{ details.totalSubscribers || 0 }}
							</p>
						</li>

						<li>
							<p>Subscription Type</p>
							<p>
								{{ titleCase(details.plan.subscription_type) }}
							</p>
						</li>
					</ul>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins";
import DeletePlanButton from "../../buttons/DeletePlanButton/DeletePlanButton";
import UpdatePlanButton from "../../buttons/UpdatePlanButton/UpdatePlanButton";
import SubscribeButton from "../../buttons/SubscribeButton/SubscribeButton";
import PlanSubscriber from "./PlanSubscriber/PlanSubscriber";

export default {
	mixins: [componentData],
	name: "PlanDetails",
	data() {
		return {
			reference: "",
			loading: false,
			errorMsg: "",
			details: {},
			taxes: [],
		};
	},
	components: {
		DeletePlanButton,
		UpdatePlanButton,
		SubscribeButton,
		PlanSubscriber,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
		newPlan() {
			const tax = this.taxes.find(
				(val) => val.id === this.details.plan.TaxId
			);
			return { ...this.details, tax };
		},
	},
	methods: {
		async getPlan(reference) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch("plans/getPlan", {
					apiKey: this.user.merchant.api_key,
					reference,
				});

				if (response.status === 200) {
					this.loading = false;
					this.details = response.data.data;
				}
			} catch (error) {
				this.loading = false;
				this.errorMsg = this.getError(error);
			}
		},
		async getTax() {
			try {
				const response = await this.$store.dispatch("tax/getAllTaxes", {
					apiKey: this.user.merchant.api_key,
				});
				if (response.status === 200) {
					this.taxes = response.data.data.data;
				}
			} catch (error) {
				this.taxes = [];
			}
		},
	},

	async mounted() {
		const reference = this.$route.params.reference;
		this.reference = reference;
		this.getPlan(reference);
		this.getTax();
	},
};
</script>
