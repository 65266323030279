<template>
	<div class="form-group">
		<label class="input-item-label">{{ label }}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<div class="input-wrapper">
			<input
				:id="id"
				:type="showPassword ? 'text' : 'password'"
				:value="value"
				v-on:input="$emit('input', $event.target.value)"
				class="form-control input-bordered w-100"
				:placeholder="placeholder"
				:disabled="disabled"
			/>

			<em
				class="fas fa-eye-slash fs-24  pointer pr-3 pl-2"
				@click="togglePassword"
			/>
		</div>
		<span v-show="error" class="text-danger">{{ error }}</span>
	</div>
</template>

<script>
	export default {
		name: "CustomPassword",
		data() {
			return {
				showPassword: false,
			};
		},
		props: [
			"id",
			"value",
			"name",
			"type",
			"min",
			"placeholder",
			"required",
			"label",
			"error",
			"disabled",
			"handleError",
			"icon",
			"iconClick",
		],
		methods: {
			togglePassword() {
				this.showPassword = !this.showPassword;
			},
		},
		watch: {
			value() {
				this.handleError(this.name);
			},
		},
	};
</script>
