<template>
	<div class="row">
		<transition mode="out-in" name="fade">
			<div class="col-12">
				<div v-if="!hasNewResponse">
					<div class="user-details-left-header">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									TAX Identification Number Verifaction
								</h2>
								<h5 class="user-profile-email sub">
									Verifies company details using TIN, CAC, or
									Phone Number
								</h5>
							</div>
						</div>
						<div class="content">
							<span
								class="badge badge-warning"
								style="font-size: 14px; font-weight: 500"
								v-if="
									hasService &&
									userRole !== 'Secondary Customer'
								"
								>(Each API call costs &#8358;{{
									`${servicePrice}`
								}})</span
							>
							<span
								v-if="!hasService"
								class="badge badge-danger"
								style="
									font-size: 14px;
									margin-top: 5px;
									font-weight: 500;
								"
								>You don't have access to this service</span
							>
						</div>
					</div>
					<div class="contact-details--card">
						<form @submit.prevent="verifyTIN">
							<div class="row mt-4">
								<div class="col-md-6">
									<label class="input-item-label">
										Select Criteria
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Criteria"
										v-model="criteria"
										@keydown.enter.native.prevent
										:options="criterias"
										label="criteria"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.criteria.$error"
									>
										<li class="parsley-required">
											Criteria is required.
										</li>
									</ul>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label">
											Enter
											{{ this.showLabelText(criteria) }}
											<span
												class="text-danger"
												title="This field is required"
												>*</span
											>
										</label>
										<input
											type="text"
											v-model="criteriaValue"
											class="form-control input-bordered"
											:placeholder="criteriaFormat"
										/>
										<ul
											class="parsley-errors-list filled"
											v-if="$v.criteriaValue.$error"
										>
											<li
												v-if="
													!$v.criteriaValue.required
												"
												class="parsley-required"
											>
												{{ showLabelText(criteria) }} is
												required.
											</li>
											<li
												v-if="
													!$v.criteriaValue
														.valueFormat
												"
												class="parsley-required"
											>
												{{ showLabelText(criteria) }}
												format is incorrect.
											</li>
										</ul>
									</div>
								</div>

								<!-- Application details -->
								<div class="col-md-12">
									<label class="input-item-label">
										Select an Application to be billed for
										the request
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an application"
										v-model="app"
										@keydown.enter.native.prevent
										:required="!app"
										:disabled="!application"
										:options="application"
										label="app_name"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.app.$error"
									>
										<li class="parsley-required">
											Application field is required.
										</li>
									</ul>
								</div>

								<!-- API Version details -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select an API Version
										<span
											class="text-danger"
											title="This field is required"
											>*</span
										>
									</label>
									<v-select
										class="v-select"
										placeholder="Select an API version"
										v-model="apiVersion"
										@keydown.enter.native.prevent
										:required="!apiVersion"
										:disabled="!versions"
										:options="versions"
									></v-select>
									<ul
										class="parsley-errors-list filled"
										v-show="$v.apiVersion.$error"
									>
										<li class="parsley-required">
											API version field is required.
										</li>
									</ul>
								</div>

								<!-- Currency Type  -->
								<div class="col-md-6 mt-4">
									<label class="input-item-label">
										Select Currency Type
									</label>
									<v-select
										class="v-select"
										placeholder="Select an Currency Type"
										v-model="currencyType"
										@keydown.enter.native.prevent
										:disabled="!customerWallets"
										:options="customerWallets"
										label="currency"
									></v-select>
								</div>

								<!-- Call ID -->
								<div class="col-md-12" v-if="hasBundleBilling">
									<div class="gaps-2x"></div>
									<div class="row">
										<div class="col-10">
											<div class="form-group">
												<label class="input-item-label"
													>Bundle Call ID</label
												>
												<input
													type="text"
													v-model="call_id"
													class="form-control input-bordered"
													placeholder="Enter your bundle call ID"
												/>
											</div>
										</div>
										<div class="col-2 mt-1">
											<div class="form-group mt-4">
												<button
													@click.prevent="
														initializeApp(app)
													"
													class="btn btn-block btn-lg btn-info mt-1"
												>
													Rengerate call ID
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="gaps-2x"></div>
									<button
										class="btn btn-lg btn-primary mg-b-20"
										type="submit"
										:disabled="
											isSubmitting == true || !hasService
										"
									>
										<span v-if="isSubmitting"
											>...Verifying</span
										>
										<span v-else>Verify </span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<!-- Response Page -->
				<div
					class="user-settings-section"
					v-if="typeof verificationResult == 'object'"
				>
					<div class="user-settings-section" v-if="hasNewResponse">
						<div class="user-settings-section-header">
							<div class="user-settings-section-header-text">
								<h1>TIN Verification Response</h1>
								<span class="text-span"
									>Preview of the TIN Verification</span
								>
							</div>
							<a
								href="#"
								class="btn btn-outline-danger"
								@click="hasNewResponse = false"
							>
								<i class="la la-close"></i>Close Panel
							</a>
						</div>
						<div class="preview-content">
							<p class="invoice-info-row preview-row">
								<span>Company Name</span>
								<span>{{
									verificationResult.Name || "Not Available"
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Company Email</span>
								<span>{{
									verificationResult.Email || "Not Available"
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>RC Number</span>
								<span>{{
									verificationResult["RC/BN"] ||
									"Not Available"
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Company TIN</span>
								<span>{{
									verificationResult.TIN || "Not Available"
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Company Phone</span>
								<span>{{
									verificationResult.Phone || "Not Available"
								}}</span>
							</p>
							<p class="invoice-info-row preview-row">
								<span>Tax Office</span>
								<span>{{
									verificationResult["Tax Office"] ||
									"Not Available"
								}}</span>
							</p>

							<div class="gaps-1-5x"></div>
						</div>
					</div>
				</div>
				<!-- End of transition -->

				<hr />
				<div class="row">
					<div class="col-12">
						<div class="user-details-info">
							<div class="user-details--top-info">
								<h2 class="user-profile-name">
									API Request Format
								</h2>
								<h5 class="user-profile-email sub">
									Representation of how the API call can be
									made with the appropriate data neccesary
								</h5>
							</div>
						</div>
					</div>

					<div class="col-12">
						<pre
							class="code code-html"
						><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}BusinessInformation/TIN`
      </code></pre>
					</div>
					<div class="col-12">
						<pre
							class="code code-html"
						><label style="top:42%;">Headers</label><code>
          {
            'clientid': '{{clientID}}',
            'appname': '{{ app ? app.app_name : 'app_name'  }}',
            'apikey': '{{ app ? app.api_key : 'app_key'  }}',
          }
      </code></pre>
					</div>
					<div class="col-12">
						<!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
						<!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
						<!-- "dob": "{{dob}}" -->
						<pre
							class="code code-html"
						><label style="top:42%;">Body</label><code>
        {
          "criteria": "{{criteria || "criteria"}}",
          "criteriaValue": "{{criteriaValue || "TIN|CAC|Phone"}}",
        }
      </code></pre>
					</div>
					<div class="col-12">
						<pre
							class="code code-html"
						><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
          {
            "Email": "business@blusalt.net",
            "JTB TIN": "1011933456",
            "Name": "BLUSALT FINANCIAL SERVICES LIMITED",
            "Phone": "08099999999",
            "RC/BN": "RC1234567",
            "TIN": "00000000-0001",
            "Tax Office": "MSTO ILUPEJU I"
          }
        }`}}
      </code></pre>
					</div>
				</div>
			</div>
		</transition>
		<ApplicationInitializationModal
			:app="app"
			:initializedAppResult="initializedAppResult"
		></ApplicationInitializationModal>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";

const valueFormat = (value, vm) => {
	if (vm.criteria === "TIN") return /^\d{8}-\d{4}$/i.test(value);
	else if (vm.criteria === "CAC") return /^RC\d{7}$/i.test(value);
	else if (vm.criteria === "Phone")
		return Number.isInteger(+value) && value.length === 11;
	else return true;
};

export default {
	name: "CACService",
	props: {
		application: {
			type: Array,
		},
		services: {
			type: Array,
		},
	},
	mixins: [notifications, modalMixin],
	validations: {
		criteriaValue: {
			required,
			valueFormat,
		},
		criteria: { required },
		apiVersion: { required },
		app: { required },
	},
	data() {
		return {
			initializedAppResult: {},
			versions: ["v1", "v2"],
			criterias: ["TIN", "CAC", "Phone"],
			criteria: "",
			criteriaValue: "",
			app: "",
			apiVersion: "v2",
			call_id: "",
			clientID: "",
			rcNumber: "",
			type: "",
			types: [
				{
					label: "Business Name",
					key: "bn",
				},
				{
					label: "Limited Company",
					key: "co",
				},
				{
					label: "Incorporated Trustee",
					key: "it",
				},
			],
			apikey: "",
			appname: "",
			hasNewResponse: false,
			logData: [],
			apiResponse: "",
			customerWallets: this.$store.state.account.customerWallets,
			currencyType: "NGN",
			hasService: false,
			servicePrice: "",
			isSubmitting: false,
			verificationResult: [],
		};
	},
	watch: {
		hasNewResponse(newValue, oldValue) {
			if (oldValue == true && newValue == false) {
				this.apiResponse = "";
			}
		},
	},
	computed: {
		criteriaFormat() {
			if (this.criteria === "TIN") {
				return "00000000-0000";
			} else if (this.criteria === "CAC") {
				return "RC1234567";
			}

			return "Enter Criteria Value";
		},
		API() {
			if (this.apiVersion == "v2" || this.apiVersion == "") {
				return this.$store.state.appUrlV2;
			} else {
				return process.env.VUE_APP_BASE_URL;
			}
		},
	},
	methods: {
		computeService() {
			if (this.services) {
				this.services.filter((service) => {
					if (service.service_label.includes("TIN Verification")) {
						this.hasService = true;
						this.servicePrice = service.pivot.price_per_call;
					}
				});
			}
		},
		showLabelText(criteria) {
			switch (criteria) {
				case "TIN":
					return "TIN";
				case "CAC":
					return "CAC";
				case "Phone":
					return "Phone";
				default:
					return "Criteria value";
			}
		},
		getRequestData() {
			if (!this.hasBundleBilling) {
				this.call_id = "";
			}
			let verificationData = {
				clientID: this.clientID,
				appname: this.app.app_name,
				apikey: this.app.api_key,
				payload: {
					criteria: this.criteria,
					criteriaValue: this.criteriaValue,
					apiVersion: this.apiVersion,
					currency_type: this.currencyType.currency,
				},
				query: this.call_id,
			};
			return verificationData;
		},
		async verifyTIN() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
			let verificationData = this.getRequestData();
			this.isSubmitting = true;
			try {
				let apiResponse = await this.$store.dispatch(
					"services/verifyTIN",
					verificationData
				);
				console.log("[api Response from bvn ] ->", apiResponse);
				this.apiResponse = apiResponse;
				this.showSuccessNotification(apiResponse.message);
				this.verificationResult = apiResponse;
				console.log(this.verificationResult);
				this.hasNewResponse = true;
				this.isSubmitting = false;
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isSubmitting = false;
				console.log("error :", error);
				this.showErrorLoginNotification(error.message);
			}
		},
	},
	mounted() {
		this.computeService();
		this.clientID = this.$store.state.account.clientID;
		console.log(
			"Customer Wallets",
			this.$store.state.account.customerWallets
		);
	},
};
</script>
