<template>
    <Modal
        :id="id"
        :title="'Create Reserved Account'"
        :toggle="handleCloseModal"
    >
        <SuccessState
            v-if="successMsg"
            :message="'Reserved Account Created Successfully'"
            :onSuccess="handleRefetch"
        />

        <form v-else @submit.prevent="handleSubmit" class="row h-100">
            <div class="col-sm-6">
                <CustomInput
                    :name="'first_name'"
                    v-model="request.customer.first_name"
                    :label="'First Name'"
                    :placeholder="'Enter First Name'"
                    :error="formErrors.first_name"
                    :handleError="handleError"
                    :required="true"
                />
            </div>

            <div class="col-sm-6">
                <CustomInput
                    :name="'last_name'"
                    v-model="request.customer.last_name"
                    :label="'Last Name'"
                    :placeholder="'Enter Last Name'"
                    :error="formErrors.last_name"
                    :handleError="handleError"
                    :required="true"
                />
            </div>

            <div class="col-sm-6">
                <CustomInput
                    :name="'email'"
                    v-model="request.customer.email"
                    :label="'Email'"
                    :placeholder="'Enter Email'"
                    :error="formErrors.email"
                    :handleError="handleError"
                    :required="true"
                />
            </div>

			<div class="col-sm-6">
				<CustomPhoneInput
					:name="'mobile_no'"
					v-model="request.customer.mobile_no"
					@blur="$v.request.customer.mobile_no.$touch()"
					:label="'Mobile Number'"
					:placeholder="'Enter Mobile Number'"
					:error="
						formErrors.mobile_no ||
						($v.request.customer.mobile_no.$error &&
							formVuelidator($v.request.customer.mobile_no))
					"
					:handleError="handleError"
				/>
			</div>

            <div class="form-group col-12">
                <label class="input-item-label">Account Type</label>
                <div class="d-flex">
                    <label class="rdiobox mr-4">
                        <input
                            :name="'accountType'"
                            type="radio"
                            :value="false"
                            v-model="accountType"
                        />
                        <span class="fs-15">Individual</span>
                    </label>

                    <label class="rdiobox">
                        <input
                            :name="'accountType'"
                            type="radio"
                            :value="true"
                            v-model="accountType"
                        />
                        <span class="fs-15">Business </span>
                    </label>
                </div>
            </div>

            <div v-show="accountType" class="col-sm-6">
                <CustomInput
                    :name="'name'"
                    v-model="request.customer.name"
                    :label="'Account Name'"
                    :placeholder="'Enter Account Name'"
                    :error="formErrors.name"
                    :handleError="handleError"
                    :required="true"
                />
            </div>

			<div class="col-sm-6">
				<CurrencySelect
					:name="'currency'"
					:handleError="handleError"
					:error="formErrors.currency"
					v-model="request.currency"
					:ngnOnly="true"
				/>
			</div>

			<div v-show="!accountType" class="col-sm-6">
				<CustomInput
					:name="'bvn'"
					v-model="request.customer.bvn"
					:label="'BVN ( Optional )'"
					:placeholder="'Enter your bvn'"
					:maxlength="11"
					:minlength="11"
					:isNum="true"
				/>
			</div>

            <div v-show="accountType" class="col-sm-6">
                <CustomInput
                    :name="'rc_number'"
                    v-model="request.customer.rc_number"
                    :label="'CAC Registration Number'"
                    :placeholder="'Enter RC Number'"
                    :error="formErrors.rc_number"
                    :handleError="handleError"
                    :required="true"
                />
            </div>

            <div v-show="accountType" class="col-sm-6">
                <CustomInput
                    :name="'address'"
                    v-model="request.customer.address"
                    :label="'Business Address'"
                    :placeholder="'Enter Business Address'"
                    :error="formErrors.address"
                    :handleError="handleError"
                    :required="true"
                />
            </div>

            <div class="col-12">
                <FormButtons
                    :loading="loading"
                    :handleCloseModal="handleCloseModal"
                />
            </div>
        </form>
    </Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "@/utils/Modal/Modal";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import CustomInput from "@/utils/CustomInput/CustomInput";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import {
	formVuelidator,
	isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";
import FormButtons from "@/utils/FormButtons/FormButtons";
import { getError, getWalletApikey, getResponseStatus } from "@/utils/helpers";
import { required } from "vuelidate/lib/validators";

const initialState = () => ({
	request: {
		currency: "",
		customer: {
			first_name: "",
			last_name: "",
			email: "",
			mobile_no: "",
			bvn: "",
			name: "",
			rc_number: "",
			address: "",
		},
	},
	accountType: false,
	formErrors: {},
	loading: false,
	successMsg: false,
});

export default {
	mixins: [notifications],
	name: "CreateReservedAccountModal",
	data() {
		return { ...initialState() };
	},
	validations: {
		request: {
			customer: {
				mobile_no: {
					required,
					isPhoneValid: isPhoneValid("request.customer.mobile_no"),
				},
			},
		},
	},
	props: ["id", "closeModal", "accountRef"],
	components: {
		Modal,
		CurrencySelect,
		CustomInput,
		FormButtons,
		SuccessState,
		CustomPhoneInput,
	},
	methods: {
		formVuelidator,
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
		},
		handleRefetch() {
			this.handleCloseModal();
			location.reload();
		},
		handleSubmit() {
			const { currency, customer } = this.request;
			let payload = { ...customer, currency };

			if (!this.accountType) {
				payload = { ...customer, currency };
				delete payload.rc_number;
				delete payload.name;
				delete payload.address;
			}
			delete payload.bvn;

			this.$v.$touch();
			const { formErrors, isValid } = formValidator(payload);
			if (isValid && !this.$v.$invalid) this.createAccount();
			else this.formErrors = formErrors;
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		async createAccount() {
			try {
				this.loading = true;

				let payload = {};
				const { currency, customer } = this.request;

				if (this.accountType) {
					payload = {
						business: {
							...customer,
							mobile_no: `${customer.mobile_no.number}`,
						},
						currency: currency.code,
					};
					delete payload.business.bvn;
				} else {
					payload = {
						customer: {
							...customer,
							mobile_no: `${customer.mobile_no.number}`,
						},
						currency: currency.code,
					};
					delete payload.customer.rc_number;
					delete payload.customer.name;
					delete payload.customer.address;
					!customer.bvn && delete payload.customer.bvn;
				}

				const response = await this.$store.dispatch(
					"reservedAccounts/createReservedAccount",
					{
						apiKey: getWalletApikey(this.$store),
						request: payload,
					}
				);

				const success = getResponseStatus(response);
				if (success) this.successMsg = true;
				else this.showErrorLoginNotification(response.data.message);

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(getError(error));
			}
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
};
</script>
