<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <!-- Mobile Carrier Service -->
                <h2 class="user-profile-name">Betting Service</h2>
                <h5 class="user-profile-email sub">
                  Top up your betting account
                </h5>
              </div>
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="topBetting">
              <div class="row mt-4">
                <!-- Application details -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select an Application
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>
                <!-- Betting providers -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select a provider
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="provider"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!providers"
                    :options="providers"
                    label="label"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row mt-4">
                <!-- user id -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">User ID</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <input
                      type="number"
                      v-model="customerId"
                      required
                      class="form-control input-bordered"
                      placeholder="Enter User ID"
                      maxlength="11"
                      @keydown="blockInvalidChar"
                      @keypress="validateNumberInput"
                    />
                  </div>
                </div>
                <!-- amount -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Amount</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <input
                      @keydown="blockInvalidChar"
				              step="any"
                      type="number"
                      v-model="amount"
                      required
                      class="form-control input-bordered"
                      placeholder="Enter Amount"
                      maxlength="11"
                    />
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <div class="gaps-2x"></div>

                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting"
                  >
                    <span v-if="isSubmitting">...Loading</span>
                    <span v-else>Top Up Billing Account</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Data Top Up Response</h1>
              <span class="text-span"
                >Preview of the information gotten from the data top<br
              /></span>
              <br />
              <div class="row mt-4">
                <!-- Message -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Message</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.message }}
                    </h5>
                  </div>
                </div>
                <!-- Recipient -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Recipient</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.recipient }}
                    </h5>
                  </div>
                </div>
                <!-- Operator -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Operator</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.operator }}
                    </h5>
                  </div>
                </div>
                <!-- Amount -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Amount</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.destination_currency_code }}
                      {{ this.apiResponse.delivered_top_up_amount }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <!-- <div v-for="value in computedObj">{{value}}</div> -->
        </div>
        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}/BillPayment/BettingTopup`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
       {
            "customerId": "34382",
            "bettingProvider": "BetKing",
            "serviceId": "B11T",
            "amount": 100,
            "name": "James Blunt"
        }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
            "status code": 200,
            "message": "Success,
            "data": {
                "statusCode": "00",
                "statusDescription": "Successful",
                "status": true,
                "result": {
                    "message": "Transaction Successful"
                    "name": "James Blunt",
                    "customerId": "34382",
                    "amount": "100.00",
                    "transId": "45871671022087',
                    "date": "2022-12-14 12:49:25",
                    "type": "BetKing",
                }

            }
        }`}}
      </code></pre>
        </div>
      </div>
    </div>

    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, minLength } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import services from "@/services/services";
import { blockInvalidChar, getError } from "@/utils/helpers";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "v2",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      hasDataPlans: false,
      hasNewResponse: false,
      logData: [],
      apiResponse: "",
      // services
      hasService: false,
      servicePrice: "",
      customerId: "",
      provider: "",
      amount: "",
      isSubmitting: false,
      providers: [],
    };
  },
  validations: {
    apiVersion: { required },
    app: { required },
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
    app() {
      this.getServices();
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    blockInvalidChar,
    validateNumberInput(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Mobile")) {
            console.log("YEAH 'MOBILE' FOUND >>>>", service.service_label);
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    async topBetting() {
      this.isSubmitting = true;
      let requestData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          customerId: this.customerId,
          amount: this.amount,
          bettingProvider: this.provider.label,
          serviceId: this.provider.value,
          name: this.name || this.customerId,
        },
        query: "",
      };

      const { data } = await services.validateBettingProvider({
        customerId: this.customerId,
        bettingProvider: this.provider.label,
      });
      if (data.message === "Success") {

        try {
          const result = await services.topUpBettingAccount({
            ...requestData,
            payload: {
              ...requestData.payload,
              name: data.data.result.name || data.data.result.username || this.customerId,
            },
          });
          this.isSubmitting = false;
          if (result.data.message !== "Success") {
            this.showErrorLoginNotification(
              result.data.message || "Unable to fund betting account"
            );
          } else {
            this.customerId = "";
            this.amount = "";
            this.showSuccessNotification(result.data.message);
          }
        } catch (error) {
          this.isSubmitting = false;
          this.showErrorLoginNotification(getError(error.message));
        }
      } else {
        this.isSubmitting = false;
        this.showErrorLoginNotification("Unable to verify User ID");
      }
    },
    async fetchBettingProviders() {
      try {
        let { data } = await services.getBettingProviders();
        const bettingProvider = data.data.result;
        const parsedArray = Object.keys(bettingProvider).map((key) => ({
          value: key,
          label: bettingProvider[key],
        }));
        this.providers = parsedArray;
      } catch (error) {
        console.log("Providers error :", error);
      }
    },
  },
  mounted() {
    this.fetchBettingProviders();
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
