<template>
  <div id="loadApplicationCredit" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-loadApplicationCredit"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Load Application Credit</h5>
          <p>Top up credit for your application.</p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="creditApplication">
            <!-- CLient ID -->
            <div class="form-group">
              <label class="input-item-label">Application Name</label>
              <input
                type="text"
                disabled
                class="form-control input-bordered"
                v-model="modalData.app_name"
              />
            </div>

            <div class="form-group">
              <label class="input-item-label">App Credit</label>
              <input type="text" class="form-control input-bordered" v-model.number="appCredit" />
              <ul class="parsley-errors-list filled" v-show="$v.appCredit.$error">
                <li class="parsley-required">Application credit are required.</li>
              </ul>
            </div>

            <div
              class="message-info"
            >Your application credit cannot exceed {{toCurrency(allowableCredit)}}.</div>

            <div class="gaps-2x"></div>
            <button type="submit" class="btn btn-lg btn-primary" :disabled="isProcessing == true">
              <span v-if="isProcessing">...crediting application</span>
              <span v-else>Credit application</span>
            </button>
            <button type="button" class="btn btn-lg btn-light ml-2" data-dismiss="modal">Close</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress"
import { required } from 'vuelidate/lib/validators';
import { notifications } from '../../../public/assets/mixins/notifications';
import { toCurrency } from "@/utils/helpers"

export default {
  mixins: [notifications],
  props: {
    modalData: {
      type: Object
    },
    clientid: {
      type: String
    },
    allowableCredit: { type: Number }
  },
  validations: {
    appCredit: { required }
  },
  data() {
    return {
      isProcessing: false,
      appCredit: '',
    }
  },
  methods: {
    toCurrency,
    formatEditApplicationData() {
      this.isProcessing = true
      let dataToBeSubmitted = {}

      dataToBeSubmitted.appname = this.modalData.app_name
      dataToBeSubmitted.apikey = this.modalData.api_key
      dataToBeSubmitted.clientid = this.clientid
      dataToBeSubmitted.payload = { "app_credit": this.appCredit }

      return dataToBeSubmitted
    },
    closeModalSuccess() {
      let closeButton = document.getElementById('close-loadApplicationCredit')
      closeButton.click()

      this.$emit('refetchApplication')

    },
    async creditApplication() {
      try {
        let requestPayload = this.formatEditApplicationData()
        let application = await this.$store.dispatch('application/creditApplication', requestPayload)
        console.log('[] ->', application);
        this.showSuccessNotification(application.message)
        let self = this
        setTimeout(() => {
          self.isProcessing = false
          self.closeModalSuccess()
        }, 2500);
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false
        console.log('[error] ->', error);
        this.showErrorLoginNotification(error.message)
      }
    }
  }
}
</script>


<style scoped>
.message-info {
  font-size: 14px;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: #e9f5fa;
  border: 1px solid #9cd1e9;
  color: #323a40;
  margin-bottom: 36px;
  padding-left: 80px;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.message-info::before {
  position: absolute;
  /* background: url(../img/icon-info.svg); */
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  left: 30px;
  top: 50%;
  color: #bfdef4;
  font-size: 46px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
</style>