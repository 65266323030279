<template>
	<Modal
		:id="id"
		:title="'Change Password'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Password Changed Successfully'"
			:onSuccess="handleCloseModal"
		/>

		<form v-else @submit.prevent="handleSubmit">
			<CustomPassword
				:name="'old_password'"
				v-model="request.old_password"
				:label="'Current Password'"
				:placeholder="'Enter Current Password'"
				:error="formErrors.old_password"
				:handleError="handleError"
				:required="true"
			/>

			<CustomPassword
				:name="'new_password'"
				v-model="request.new_password"
				:label="'New Password'"
				:placeholder="'Enter New Password'"
				:error="formErrors.new_password"
				:handleError="handleError"
				:required="true"
			/>

			<CustomPassword
				:name="'confirm_new_password'"
				v-model="request.confirm_new_password"
				:label="'Confirm Password'"
				:placeholder="'Confirm Password'"
				:error="formErrors.confirm_new_password"
				:handleError="handleError"
				:required="true"
			/>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../utils/Modal/Modal";
	import FormButtons from "../../../../utils/FormButtons/FormButtons";
	import CustomPassword from "../../../../utils/CustomInput/CustomPassword/CustomPassword";
	import SuccessState from "../../../../utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../utils/FormValidator/FormValidator";
	import Spinner from "../../../../utils/Spinner/Spinner";

	const initialState = () => ({
		request: {
			old_password: "",
			new_password: "",
			confirm_new_password: "",
		},
		loading: false,
		successMsg: false,
		formErrors: {},
	});

	export default {
		mixins: [notifications],
		name: "ChangePasswordModal",
		data() {
			return { ...initialState() };
		},
		props: ["id", "closeModal"],
		components: {
			Modal,
			CustomPassword,
			SuccessState,
			Spinner,
			FormButtons,
		},

		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleSubmit(e) {
				e.preventDefault();
				const { new_password, confirm_new_password } = this.request;
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) {
					if (new_password === confirm_new_password) {
						this.changePassword();
					} else
						this.formErrors.confirm_new_password =
							"Password doesnt match";
				} else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},

			async changePassword() {
				const credentials = { ...this.request };
				try {
					await this.$store
						.dispatch("account/changePassword", credentials)
						.then(() => {
							this.loading = false;
							this.successMsg = true;
						});
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
