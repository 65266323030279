<template>
  <div :class="`status__pill text-center${smallWidth}${bgColor} ${cls}`">
    {{ titleCase(renderStatus(status)) }}
  </div>
</template>

<script>
import { titleCase } from "../helpers";

export default {
  props: {
    cls: { type: String, default: "" },
    status: [Boolean, String],
    success: String,
    warning: String,
    danger: String,
    finished: String,
  },
  methods: {
    titleCase,
    renderStatus(status) {
      if (typeof status === "boolean") {
        return status ? "SUCCESS" : "FAILED";
      }

      if (this.bgColor === " pill--success") {
        return this.success || status;
      }

      if (this.bgColor === " pill--warning") {
        return this.warning || status;
      }

      if (this.bgColor === " pill--danger") {
        return this.danger || status;
      }

      if (this.bgColor === " pill--finished") {
        return this.finished || status;
      }

      if (this.bgColor === " pill--disabled") {
        return status;
      }
    },
    statusBgColor(status) {
      const key = typeof status === "boolean" ? status : titleCase(status);
      switch (key) {
        case true:
        case titleCase("PAID"):
        case titleCase("READY"):
        case titleCase("ACTIVE"):
        case titleCase("CREDIT"):
        case titleCase("SUCCESS"):
        case titleCase("SETTLED"):
        case titleCase("ASSIGNED"):
        case titleCase("RESOLVED"):
        case titleCase("VERIFIED"):
        case titleCase("APPROVED"):
        case titleCase("ACCEPTED"):
        case titleCase("COMPLETED"):
        case titleCase("SUCCESSFUL"):
        case titleCase("ENABLED"):
        case titleCase("ACTIVATED"):
          return " pill--success";

        case titleCase("DRAFT"):
        case titleCase("PENDING"):
        case titleCase("TRANSFER"):
        case titleCase("AWAITING"):
        case titleCase("INVITED"):
        case titleCase("PROCESSING"):
        case titleCase("NOT_ACTIVE"):
          return " pill--warning";

        case titleCase("CLOSED"):
        case titleCase("CHALLENGED"):
          return " pill--finished";

        default:
        case false:
        case titleCase("DEBIT"):
        case titleCase("FAILED"):
        case titleCase("DANGER"):
        case titleCase("INACTIVE"):
        case titleCase("REJECTED"):
        case titleCase("UNAUTHENTICATED"):
        case titleCase("ESCALATED"):
          return " pill--danger";

        case titleCase("SAVE"):
        case titleCase("SAVED"):
        case titleCase("DEFAULT"):
          return " pill--disabled";
      }
    },
  },
  computed: {
    bgColor() {
      return this.statusBgColor(this.status);
    },
    smallWidth() {
      return titleCase(this.status) === titleCase("success") ||
        this.status === titleCase("pending") ||
        this.status === titleCase("failed")
        ? " min-w-80"
        : "";
    },
    value() {
      return this.titleCase(this.renderStatus(this.status));
    },
  },
};
</script>
