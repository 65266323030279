<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openMFAModal"
    >
      {{ user.mfa_active ? "Disable" : "Enable" }}
    </button>

    <ToggleMultiFactorAuthModal
      :id="'toggleMfa'"
      :closeModal="closeModal"
      :refresh="refresh"
      :user="user"
    />
  </div>
</template>

<script>
import ToggleMultiFactorAuthModal from "../../modals/ToggleMultiFactorAuthModal/ToggleMultiFactorAuthModal";

export default {
  name: "ToggleMFAButton",
  props: ["refresh", "user"],
  methods: {
    openMFAModal() {
      $("#toggleMfa").modal("show");
    },
    closeModal() {
      $("#toggleMfa").modal("hide");
    },
  },
  components: {
    ToggleMultiFactorAuthModal,
  },
};
</script>
