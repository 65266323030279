<template>
  <div>
    <InitiatePaymentCollection></InitiatePaymentCollection>
    <div class="col-lg-12">
      <div class="SectionItem">
        <div class="SectionHeader">
          <span class="title">Payment Collection</span>
          <div class="description">
            Payment collection dashboard for all your payments processed
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-2">
        <a
            href="#"
            class="btn btn-md btn-primary mb-3"
            data-toggle="modal"
            data-effect="effect-scale"
            data-backdrop="static"
            data-target="#initiatePaymentCollectionModal"
        >
          <i class="la la-plus"></i>Initiate Payment Collection
        </a>
<!--        <p class="text-right">-->
<!--          <button-->
<!--            type="button"-->
<!--            class="btn"-->
<!--            :class="user.directDebit ? 'themed-button' : 'btn-secondary'"-->
<!--            @click="popWithdrawModal"-->
<!--          >-->
<!--            Withdraw-->
<!--          </button>-->
<!--        </p>-->
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="card-body dashboard-flex dashboard-briefs">
          <div class="dashboard-brief">
            <h2 class="call-count">
              {{ "0" }}
            </h2>
            <p class="call-name">Available Balance</p>
          </div>
          <div class="dashboard-brief">
            <h2 class="call-count">
              {{ "0" }}
            </h2>
            <p class="call-name">Pending Balance</p>
          </div>
          <div class="dashboard-brief">
            <h2 class="call-count">
              {{ "0" }}
            </h2>
            <p class="call-name">Total Transactions</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <hr />
      <div class="col-12">
        <div class="SectionItem">
          <div class="SectionHeader">
            <span class="title">Collection History</span>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="row">
          <select class="form-select" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div> -->
      <div class="col-12">
        <v-client-table :data="tableData" :columns="columns" :options="options">
          <template slot="createdAt" slot-scope="props">
            <div>
              {{ props.row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
            </div>
          </template>
        </v-client-table>
      </div>
    </div>

    <!-- Withdraw Modal-->
    <div
      class="modal fade"
      id="withdrawModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="withdrawModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row d-flex justify-content-between">
              <div>
                <img
                  src="/assets/img/blusalt-logo-dark.png"
                  alt
                  width="100px"
                />
              </div>
              <div>
                <p>{{ user.email }}</p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <h4 class="text-center themed-text">
                  <strong>Withdraw from collection</strong>
                </h4>
              </div>
              <div class="col-12">
                <form @submit.prevent="" class="px-5">
                  <div v-if="withdrawStep == 1">
                    <div class="row mt-5">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <!-- Amount -->
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="input-item-label"
                                >Enter Amount</label
                              >
                              <span
                                class="text-danger"
                                title="This field is required"
                                >*</span
                              >
                              <input
                                required
                                type="number"
                                min="50000"
                                v-model="amount"
                                class="form-control input-bordered"
                                placeholder="Enter amount to fund"
                              />
                            </div>
                          </div>
                          <!-- Narration -->
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="input-item-label">Narration</label>
                              <span
                                class="text-danger"
                                title="This field is required"
                                >*</span
                              >
                              <input
                                required
                                type="text"
                                v-model="narration"
                                class="form-control input-bordered"
                                placeholder="Enter narration"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="row justify-content-between px-3 py-5">
                          <button class="btn btn-outline" data-dismiss="modal">
                            <span>Cancel</span>
                          </button>
                          <button
                            class="btn themed-button"
                            @click="withdrawStep = 2"
                          >
                            <span>Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="withdrawStep == 2">
                    <div class="row mt-5">
                      <div class="col-12">
                        <p>Withdraw NGN 3,000 to the account below:</p>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <!-- Wallet -->
                          <!-- <div class="col-sm-12">
                            <div class="form-group">
                              <label class="input-item-label"
                                >Select Bank</label
                              >
                              <span
                                class="text-danger"
                                title="This field is required"
                                >*</span
                              >
                              <v-select
                                class="v-select"
                                placeholder="Select bank"
                                @keydown.enter.native.prevent
                                :options="bankList"
                                v-model="selectedBank"
                                label="name"
                              ></v-select>
                            </div>
                          </div> -->
                          <!-- Account Number -->
                          <!-- <div class="col-sm-12">
                            <div class="form-group">
                              <label class="input-item-label"
                                >Enter Account Number</label
                              >
                              <span
                                class="text-danger"
                                title="This field is required"
                                >*</span
                              >
                              <input
                                required
                                type="text"
                                maxlength="10"
                                class="form-control input-bordered"
                                placeholder="Enter account number"
                                v-model="accountNumber"
                              />
                            </div>
                          </div> -->
                          <!-- Account Name-->
                          <!-- <div class="col-sm-12">
                            <div class="form-group">
                              <label class="input-item-label"
                                >Account Name</label
                              >
                              <input
                                :value="accountName"
                                type="text"
                                readonly
                                required
                                class="form-control input-bordered"
                                placeholder="Select bank and enter account number to verify"
                              />
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-sm-12 user-account-info">
                        <div class="row py-3">
                          <div class="col-12">
                            <h6><strong>Bank Name</strong></h6>
                            <h5>BANK OF INSPECTION</h5>
                          </div>
                          <div class="col-12">
                            <h6><strong>Account Number</strong></h6>
                            <h5>00990099009</h5>
                          </div>
                          <div class="col-12">
                            <h6><strong>Account Name</strong></h6>
                            <h5>DEMO NAME</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="row justify-content-between px-3 py-5">
                          <button
                            class="btn btn-outline"
                            @click="withdrawStep = 1"
                          >
                            <span>Back</span>
                          </button>
                          <!-- <button
                            class="btn themed-button"
                            @click="verifyAccountNumber()"
                          >
                            <template v-if="verifiedAccountName">
                              <span v-if="isSubmitting">..loading</span>
                              <span v-else>Withdraw</span>
                            </template>
                            <template v-else>
                              <span v-if="isSubmitting">..loading</span>
                              <span>Verify Account</span>
                            </template>
                          </button> -->
                          <button class="btn themed-button" @click="toOTP()">
                            <span v-if="isSubmitting">..loading</span>
                            <span v-else>Continue</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Withdraw modal end  -->
    <!-- OTP Modal-->
    <div
      class="modal fade"
      id="otpModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otpModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row d-flex justify-content-between">
              <div>
                <img
                  src="/assets/img/blusalt-logo-dark.png"
                  alt
                  width="100px"
                />
              </div>
              <div>
                <p>{{ user.email }}</p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <h4 class="text-center themed-text">
                  <strong>Enter OTP</strong>
                </h4>
              </div>
              <div class="col-12">
                <div class="row justify-content-center my-2 py-5">
                  <OTPInput />
                </div>
              </div>
              <div class="col-12 pb-3">
                <div class="row justify-content-center">
                  <button class="btn themed-button">
                    <span v-if="isSubmitting">..loading</span>
                    <span v-else>Complete Transaction</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- OTP modal end  -->
  </div>
</template>
<script>
import { notifications } from "../../../public/assets/mixins/notifications";
import OTPInput from "../otp/OTPInput";
import InitiatePaymentCollection from "@/components/wallet/InitiatePaymentCollection";
export default {
  mixins: [notifications],
  components: {
    InitiatePaymentCollection,
    OTPInput,
  },
  data() {
    return {
      withdrawStep: 1,
      loading: false,
      isSubmitting: false,
      verifiedAccountName: false,
      loading: false,
      requestStep: 1,
      withdrawAmount: "",
      amount: "",
      accountName: "",
      bankList: [],
      columns: [
        "Reference",
        "Currency",
        "Amount",
        "Customer",
        "Channel",
        "Paid On",
      ],
      options: {
        sortable: [],
        perPage: 10,
        pagination: {
          chunk: 5,
        },
        headings: {
          reference: "Reference",
          currency: "Currency",
          amount: "Amount",
          customer: "Customer",
          transaction_type: "Transaction Type",
          createdAt: "Paid On",
        },
        perPageValues: [],
        filterByColumn: true,
      },
      tableData: [],
      narration: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.account.profile;
    },
  },
  methods: {
    async getBankList() {
      try {
        const bankList = await this.$store.dispatch(
          "fundstransfer/getBankList"
        );
        this.bankList = bankList.data.result;
        this.isLoading = false;
      } catch (error) {}
    },
    async verifyAccountNumber() {
      if (!this.accountNumber) {
        this.showErrorLoginNotification("Fill required fields");
        return;
      }
      if (this.verifiedAccountName) {
        this.addBank();
        return;
      }
      Nprogress.start();
      this.submitting = true;
      const data = {
        account_number: this.accountNumber,
        bank_code: this.selectedBank.code,
        apiKey: this.public_key,
      };
      try {
        const verifiedAccount = await this.$store.dispatch(
          "wallets/resolveBankName",
          data
        );
        console.log("verifiedAccount", verifiedAccount);
        this.showSuccessNotification(verifiedAccount.data.message);
        this.accountName = verifiedAccount.data.account_name.toUpperCase();
        this.isLoading = false;
        this.submitting = false;
        this.verifiedAccountName = true;
        Nprogress.done();
      } catch (error) {
        console.log("verifiedAccount error", error);
        this.submitting = false;
        this.accountNumber = "";
        Nprogress.done();
      }
    },
    popWithdrawModal() {
      $("#withdrawModal").modal("show");
    },
    popOTPModal() {
      $("#otpModal").modal("show");
    },
    closeModal() {
      $("#withdrawModal").modal("hide");
      $("#otpModal").modal("hide");
    },
    toOTP() {
      this.closeModal();
      this.popOTPModal();
    },
  },
  async mounted() {
    this.getBankList();
  },
};
</script>
<style scoped>
.charts {
  padding: 20px;
  /* background-color: #cccbcb;
  border: 1px solid black; */
}
.user-account-info {
  background-color: #ededed;
  height: 100%;
}
.charts svg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.graphs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard-flex {
  padding: 0;
  /* background: #081b2a; */
  border: 1px solid #cccbcb;
  border-radius: 5px;
  color: black;
}
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 120px;
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
}

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  color: #2e6091;
  padding-top: 8px;
  margin-bottom: 2px;
}
.call-name {
  font-size: 13px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
