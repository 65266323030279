<template>
	<div>
		<CustomBuiltSelect
			:label="'Customer'"
			:btnName="'Customer'"
			:placeholder="'Select Customer'"
			:value="value"
			:error="error"
			:loading="loading"
			:selected="selected"
			:getLabel="getLabel"
			:required="required"
			:options="filteredCustomers"
			:handleChange="handleChange"
			:handleSelect="handleSelect"
			:createFooter="openAddNewCustomerModal"
		/>

		<AddCustomerModal
			:id="'addCustomer'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import { getWalletApikey } from "@/utils/helpers";
import AddCustomerModal from "@/components/wallet/Customers/modals/AddCustomerModal/AddCustomerModal";
import CustomBuiltSelect from "../CustomBuiltSelect/CustomBuiltSelect";

export default {
	name: "CustomerSelect",
	data() {
		return {
			filteredCustomers: [],
			customers: [],
			selected: this.customer || null,
			value: "",
			loading: false,
		};
	},
	components: {
		CustomBuiltSelect,
		AddCustomerModal,
	},
	props: ["required", "error", "action", "customer", "setRequest"],
	methods: {
		async refetch(customer) {
			this.action(customer);
			this.getCustomers();
		},
		handleChange({ target: { value } }) {
			if (value) {
				this.value = value;
				let data = [...this.customers].filter(
					(customer) =>
						`${customer.first_name} ${customer.last_name}`
							.toLowerCase()
							.includes(value.toLowerCase()) ||
						customer.email.includes(value.toLowerCase())
				);
				this.filteredCustomers = data;
			} else {
				this.value = "";
				this.filteredCustomers = this.customers;
			}
		},
		getLabel(customer) {
			const { first_name, last_name, email } = customer || {};
			return first_name ? `${first_name} ${last_name} (${email})` : "";
		},
		handleSelect(option) {
			this.selected = option;
			this.value = this.getLabel(option);
			this.setRequest("customer", option);
		},
		openAddNewCustomerModal() {
			$("#addCustomer").modal("show");
		},
		closeModal() {
			$("#addCustomer").modal("hide");
		},

		async getCustomers() {
			this.loading = true;

			try {
				const response = await this.$store.dispatch(
					"customers/getAllCustomers",
					{
						apiKey: getWalletApikey(this.$store),
						params: { limit: 100000000000000 },
					}
				);
				this.customers = response.data.data.data;
				this.filteredCustomers = response.data.data.data;
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.customers = [];
			}
		},
		setCustomer(v) {
			this.selected = v;
			this.value = this.getLabel(v);
		},
	},
	mounted() {
		this.setCustomer(this.customer);
		this.getCustomers();
	},
	watch: {
		customer(option) {
			this.setCustomer(option);
		},
	},
};
</script>
