<template>
	<div>
		<img
			@click="openEditItem"
			class="pointer mr-4"
			src="/assets/img/icons/edit.png"
			alt="update item"
			width="25px"
		/>

		<CreateItemsModal
			:id="`item${item.reference}`"
			:closeModal="closeModal"
			:item="item"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import CreateItemsModal from "../../modals/CreateItemsModal/CreateItemsModal";

export default {
	name: "UpdateItemButton",
	props: ["item", "refetch"],
	methods: {
		openEditItem() {
			$(`#item${this.item.reference}`).modal("show");
		},
		closeModal() {
			$(`#item${this.item.reference}`).modal("hide");
		},
	},
	components: {
		CreateItemsModal,
	},
};
</script>
