<template>
	<form @submit.prevent="handleSubmit">
		<div class="row mb-2">
			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<CustomSelect
					:name="'status'"
					v-model="filters.status"
					:options="disputeStatus"
					:optionLabel="'name'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<CustomInput
					:name="'transaction_id'"
					v-model="filters.transaction_id"
					:placeholder="'Transaction ID'"
				/>
			</div>

			<div class="col-sm-6 px-sm-3 col-md-4 px-ms-3 col-xl-3">
				<CustomInput
					:name="'email'"
					v-model="filters.email"
					:placeholder="'email'"
				/>
			</div>

			<div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
				<button
					type="submit"
					:disabled="disabled || loading"
					class="btn btn-primary mx-3"
				>
					Filter
				</button>

				<button
					v-show="isFilter"
					type="button"
					class="btn btn-outline"
					@click="resetFilters"
				>
					Clear
				</button>

				<div class="d-inline-block">
					<DownloadReport
						:downloadReport="download"
						:loading="rLoading"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import { filters } from "../../../../../../public/assets/mixins/filters";
	import { disputeStatus } from "../../enums"

	const initialState = () => ({
		filters: {
			status: disputeStatus[1],
			transaction_id: "",
			email: "",
		},
		isFilter: false,
	});

	export default {
		mixins: [filters],
		name: "PaymentDisputeFilters",
		data() {
			return { ...initialState(), disputeStatus };
		},
		props: ["loading", "rLoading", "clearFilter", "submitFilter", "download"],
		computed: {
			disabled() {
				return !Object.values(this.filters).some((val) => val);
			},
		},
		methods: {
			resetFilters() {
				Object.assign(this.$data, { ...initialState() });
				this.clearFilter();
				this.isFilter = false;
			},
			handleSubmit() {
				this.isFilter = true;
				this.submitFilter(this.filters);
			},
		},
	};
</script>
