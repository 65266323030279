<template>
	<div>
		<button
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openDisputeUpdate"
		>
			Update Status
		</button>

		<UpdateDisputeStatusModal
			:id="dispute.id"
			:dispute="dispute"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
	import UpdateDisputeStatusModal from "../../modals/UpdateDisputeStatusModal/UpdateDisputeStatusModal";

	export default {
		name: "UpdateDisputeStatusButton",
		props: ["dispute", "refetch"],
		methods: {
			openDisputeUpdate() {
				$(`#${this.dispute.id}`).modal("show");
			},
			closeModal() {
				$(`#${this.dispute.id}`).modal("hide");
			},
		},
		components: {
			UpdateDisputeStatusModal,
		},
	};
</script>
