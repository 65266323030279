<template>
	<div class="border-top">
		<div class="row mt-4" v-if="pageData">
			<div class="col-md-7 mb-4 border-right">
				<ul class="sub_card">
					<h5 class="mb-3">Personal Information</h5>
					<li>
						<p>Page Title</p>
						<p>{{ titleCase(pageData.title) }}</p>
					</li>
					<li>
						<p>Page Description</p>
						<p>
							<ViewMore
								:content="titleCase(pageData.description)"
								:title="titleCase(pageData.title)"
							/>
						</p>
					</li>
					<li>
						<p>Page URL</p>
						<div class="d-flex">
							<a
								class="text-break mr-2"
								:href="pageData.link"
								target="_blank"
								rel="noopener noreferrer"
								>{{ pageData.link }}</a
							>
							<button
								class="btn btn--xm py-0 btn-primary-outline"
								@click="copy(pageData.link)"
							>
								Copy
							</button>
						</div>
					</li>
					<li>
						<p>Amount</p>
						<p>
							{{
								toCurrency(
									pageData.amount,
									pageData.Currency.code
								)
							}}
						</p>
					</li>
					<li>
						<p>Page Type</p>
						<p>{{ titleCase(pageData.page_type) }}</p>
					</li>
					<li>
						<p>Status</p>
						<p :class="statusColor(pageData.status)">
							{{ titleCase(pageData.status) }}
						</p>
					</li>
					<li>
						<p>Created At</p>
						<p>{{ timeDateFormat(pageData.createdAt).date }}</p>
					</li>
					<li>
						<p>Expired At</p>
						<p>{{ timeDateFormat(pageData.expiry_date).date }}</p>
					</li>
				</ul>
				<div v-if="pageData.image_urls !== null" class="d-flex">
					<div
						class="mx-2"
						v-for="(value, key, index) in pageData.image_urls"
						:key="index"
					>
						<img
							:src="value"
							:alt="value"
							class="img-fluid"
							height="200"
						/>
					</div>
				</div>
			</div>
			<div class="col-md-5 mb-4">
				<div class="dashboard-brief flex__start border px-3 py-3">
					<div>
						<p class="call-name m-0">Net Amount</p>
						<h2 class="call-count">
							{{
								toCurrency(
									pageData.net_amount,
									pageData.Currency.code
								) || "0"
							}}
						</h2>
					</div>
				</div>
				<div class="dashboard-brief border px-3 py-3 mt-4">
					<div>
						<p class="call-name m-0">Total Tax</p>
						<h2 class="call-count">
							{{
								toCurrency(
									pageData.total_tax,
									pageData.Currency.code
								) || "0"
							}}
						</h2>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<Spinner />
		</div>
	</div>
</template>

<script>
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../../../public/assets/mixins/componentData";
import {
	titleCase,
	toCurrency,
	statusColor,
	timeDateFormat,
	getWalletApikey,
} from "@/utils/helpers";
import Spinner from "@/utils/Spinner/Spinner";
import ViewMore from "@/utils/ViewMore/ViewMore";

export default {
	mixins: [notifications, componentData],
	name: "PaymentInformation",
	components: {
		Spinner,
		ViewMore,
	},
	data() {
		return {
			pageData: null,
		};
	},
	methods: {
		titleCase,
		toCurrency,
		statusColor,
		timeDateFormat,
		getWalletApikey,
		async getInformation() {
			const payload = {
				apiKey: getWalletApikey(this.$store),
				reference: this.$route.params.reference,
			};
			try {
				const resp = await this.$store.dispatch(
					"payment/getOnePaymentPage",
					payload
				);
				this.pageData = resp.data.data;
			} catch (error) {
				console.log("error", error);
			}
		},
		async copy(data) {
			const copied = this.copyToClipboard(data);
			copied && this.showSuccessNotification("Link Copied!");
		},
	},
	created() {
		this.getInformation();
	},
};
</script>

<style scoped>
.dashboard-brief {
	align-items: flex-start;
	padding: 40px 30px !important;
}
ul.sub_card li {
	border: none !important;
	justify-content: flex-start;
}
ul.sub_card li p {
	margin: 0 !important;
	padding: 0 !important;
	font-size: 16px;
}
ul.sub_card li p:first-child {
	width: 300px;
	min-width: 300px;
}
.text-break {
	word-break: break-all;
}
.img-fluid {
	max-height: 200px;
}
.call-count {
	font-size: 30px;
}
.col-md-7 {
	min-height: 75vh;
}
</style>
