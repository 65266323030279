<template>
	<div>
		<EmptyWallet v-if="!merchant" />

		<div v-else class="p-md-4 p-3">
			<div class="flex__between">
				<div class="SectionItem no-b-b mb-3">
					<p class="title">Terminal</p>
				</div>
			</div>

			<div class="content__slider">
				<TabsV2
					:tabNames="tabNames"
					:tabId="'terminalTab'"
					:tabLink="true"
				/>
			</div>

			<transition mode="out-in" name="slide">
				<router-view />
			</transition>
		</div>
	</div>
</template>

<script>
import SplitContent from "../../../utils/SplitContent/SplitContent";
import EmptyWallet from "../common/EmptyWallet/EmptyWallet";
import TabsV2 from "../../../utils/Tabs/TabsV2";

const tabNames = [
	{ tabName: "Overview", value: "overview", link: "/wallet/terminal" },
	{
		tabName: "Transactions",
		value: "transactions",
		link: "/wallet/terminal/transactions",
	},
	{
		tabName: "Virtual Terminal",
		value: "virtual_terminal",
		link: "/wallet/terminal/virtual",
	},
	{
		tabName: "POS Terminal",
		value: "pos_terminal",
		link: "/wallet/terminal/pos",
	},
	{
		tabName: "Sales Agents",
		link: "/wallet/terminal/agents",
	},
];

export default {
	name: "Terminal",
	components: {
		TabsV2,
		SplitContent,
		EmptyWallet,
	},
	data() {
		return {
			tabNames,
		};
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
};
</script>
