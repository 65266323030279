export const paymentStatus = [
	{
		name: "Failed",
		value: "failed",
	},
	{
		name: "Pending",
		value: "pending",
	},
	{
		name: "Successful",
		value: "successful",
	},
];

export const disputeStatus = [
	{
		name: "Abandoned",
		value: "abandoned",
	},
	{
		name: "Pending",
		value: "pending",
	},
	{
		name: "Resolved",
		value: "resolved",
	},
];

export const tableHeadings = {
  title: "Page Name",
  reference: "Reference",
  page_type: "Page Type",
  amount: "Amount",
  status: "Status",
  createdAt: "Date Created",
  action: "",
};

export const payoutTableHeadings = {
	createdAt: "Payout Date",
	BankAccountId: "Recipient",
	amount: "Original Amount",
	charges: "Settled Ammount",
};

export const disputeTableHeadings = {
	createdAt: "Date",
	transaction_id: "Transaction ID",
	amount: "Amount",
	first_name: "First Name",
	last_name: "Last Name",
	email: "Email",
	status: "Status",
	proof_url: "",
};

export const paymentCollectionCsvRows = {
  reference: "Payment Reference",
  title: "Title",
  description: "Description",
  page_type: "Type",
  amount: "Amount",
  status: "Transaction Status",
  createdAt: "Date Created",
};

export const refundTableHeadings = {
	original_transaction_reference: "Payment Reference",
	amount: "Amount",
	charges: "Charges",
	reference: "Refund Reference",
	createdAt: "Date",
	status: "Status",
};
