<template>
  <div class="p-4">
    <!-- <EmptyWallet v-if="!merchant" /> -->

    <template>
      <div class="flex__between">
        <div class="SectionItem no-b-b mb-4 justify-content-start">
          <img
            src="/assets/img/icons/arrow-left.svg"
            width="30"
            alt=""
            class="pointer"
            @click="$router.push('/wallet/remittance/beneficiaries')"
          />
          <p class="title ml-3 mb-0">Beneficiary Details</p>
        </div>
      </div>

      <template>
        <div class="content__slider">
          <Tabs
            :tabNames="['Beneficiary_Information', 'Transaction_History']"
            :tabId="'subTab'"
          />
        </div>

        <div class="tab-content" id="subTabContent">
          <BeneficiaryInformation />
          <TransactionHistory />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Tabs from "../../../../../utils/Tabs/Tabs";
import EmptyWallet from "../../../common/EmptyWallet/EmptyWallet";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import BeneficiaryInformation from "./BeneficiaryInformation/BeneficiaryInformation";
import Spinner from "../../../../../utils/Spinner/Spinner";
import FailedState from "../../../../../utils/ResponseState/FailedState";
import "../../Sender/SenderDetails/SubscriptionDetails.css";

export default {
  name: "BeneficiaryDetails",
  data() {
    return {
      id: "",
      loading: false,
      errorMsg: "",
      subscription: {},
      senderData,
    };
  },
  components: {
    Spinner,
    FailedState,
    EmptyWallet,
    Tabs,
    BeneficiaryInformation,
    TransactionHistory,
  },
  computed: {
    merchant() {
      return this.$store.state.account.profile.merchant;
    },
  },
  methods: {},

  async mounted() {
    const id = this.$route.params.id;
    this.id = id;
    this.getSubsciption(id);
  },
};
</script>
