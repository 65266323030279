<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openActivateWalletModal"
		>
			Activate Payments
		</button>
		<ActivateWalletModal :id="'activateWallet'" :closeModal="closeModal" />
	</div>
</template>

<script>
	import ActivateWalletModal from "../../modals/ActivateWalletModal/ActivateWalletModal";

	export default {
		name: "ActivateWalletButton",
		props: ["walletRef"],
		methods: {
			openActivateWalletModal() {
				$("#activateWallet").modal("show");
			},
			closeModal() {
				$("#activateWallet").modal("hide");
			},
		},
		components: {
			ActivateWalletModal,
		},
	};
</script>
