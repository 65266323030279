<template>
	<div class="limit_wrap">
		<CustomSelect
			name="pageLimit"
			:options="[10, 25, 50, 100, 200, 500, 1000]"
			v-model="pageLimit"
			:clear="false"
		/>
	</div>
</template>

<script>
import CustomSelect from "../../CustomSelect/CustomSelect";

export default {
	name: "PerPageSelect",
	data() {
		return {
			pageLimit: this.limit,
		};
	},
	props: {
		handleChange: Function,
		limit: {
			type: Number,
			defautlt: 25,
		},
	},
	components: {
		CustomSelect,
	},
	watch: {
		pageLimit(limit) {
			this.handleChange({ limit });
		},
	},
};
</script>

<style scoped>
.limit_wrap {
	width: 130px;
}
</style>
