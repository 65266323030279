<template>
	<div class="p-3">
		<template>
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<AuditTrailFilter
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('serverTableRef')"
						/>
					</template>
				</FilterWrapper>
			</div>

			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<template slot="empty"> </template>
				<template slot="content">
					<v-server-table
						ref="serverTableRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@error="setError"
					>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{
									timeDateFormat(row.createdAt).date +
									" " +
									timeDateFormat(row.createdAt).time
								}}
							</span>
						</template>
					</v-server-table>
				</template>
			</TableWrapper>
		</template>
	</div>
</template>

<script>
import AuditTrailFilter from "./filters/AuditTrailFilter.vue";
import { componentData } from "../../../public/assets/mixins/componentData";
import { notifications } from "../../../public/assets/mixins/notifications";
import { tableHeadings } from "./enums";
import { getError } from '@/utils/helpers';

export default {
	mixins: [notifications, componentData],
	name: "PaymentCollection",
	data() {
		return {
			emptyTitle: "No Audit Trail",
			...this.tableOptions(tableHeadings, 20),
		};
	},
	components: {
		AuditTrailFilter,
	},
	methods: {
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.category) {
				newFilters = {
					...newFilters,
					"category[0]": newFilters.category.value,
				};
			}
			if (newFilters.event) {
				newFilters = { ...newFilters, "event[0]": newFilters.event };
			}

			this.handleRefetch(
				newFilters,
				this.fetchAuditTrails,
				"serverTableRef"
			);
		},
		async fetchAuditTrails() {
			this.options.requestFunction = async (data) => {
				const params = {
					size: data.limit,
					page: data.page,
					...this.filters,
				};
				
        try {
          const response = await this.$store.dispatch(
					"logs/getAuditTrail",
					{
						params,
						clientid: this.$store.state.account.clientID,
					}
				);

        this.loading = false;
				const newData = response.result.logs;
				const total = response.result.total;
				return { count: total, total: total, data: newData };
        } catch(e) {
          this.loading = false;
          this.errorMsg = getError(e)
        }
			};
		},
	},
	created() {
		this.fetchAuditTrails();
	},
	mounted() {
		this.setColums(tableHeadings);
	},
};
</script>
