<template>
  <div class="az-content-body">
    <Loader v-if="isProcessing == true"></Loader>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="SectionItem">
          <div class="SectionHeader">
            <span class="title">Dashboard</span>
            <div class="description">
              View the analytics of your application, how many api calls you're
              making and more statistics
            </div>
          </div>
        </div>
      </div>

      <!-- row -->
      <template v-if="profile.is_active">
        <div class="col-12">
          <div class="card-body dashboard-flex dashboard-briefs">
            <div class="dashboard-brief">
              <h2 class="call-count">{{ dashboardData.totalCalls || "0" }}</h2>
              <p class="call-name">Total calls</p>
            </div>
            <div class="dashboard-brief">
              <h2 class="call-count">
                {{ dashboardData.totalSuccessfulCalls || "0" }}
              </h2>
              <p class="call-name">successful calls</p>
            </div>
            <div class="dashboard-brief">
              <h2 class="call-count">
                {{ dashboardData.totalFailedCalls || "0" }}
              </h2>
              <p class="call-name">Failed calls</p>
            </div>
            <div class="dashboard-brief">
              <h2 class="call-count">
                {{ dashboardData.totalExpense || "0" }}
              </h2>
              <p class="call-name">Total Credit Consumed</p>
            </div>
          </div>
        </div>
        <div class="gaps-2x"></div>
        <div class="gaps-2x"></div>
        <div class="gaps-2x"></div>

        <div class="col-lg-4 mg-t-20">
          <div class="card card-dashboard-eight bg-white">
            <h6 class="card-title">Indentity Expense</h6>
            <span class="d-block mg-b-20"
              >View how much you've spent on identity services</span
            >

            <div class="list-group">
              <div
                class="list-group-item"
                v-for="(expense, i) in dashboardExpense"
                :key="i"
              >
                <div>{{ Object.keys(expense)[0] }}</div>
                <span>&#8358;{{ Object.values(expense)[0] }}</span>
              </div>
            </div>
            <!-- list-group -->
          </div>
          <!-- card -->
        </div>

        <div class="col-lg-4 mg-t-20">
          <div class="card card-dashboard-eight bg-white">
            <h6 class="card-title">Indentity Expense Contd.</h6>
            <span class="d-block mg-b-20"
              >View how much you've spent on identity services</span
            >

            <div class="list-group">
              <div class="list-group">
                <div
                  class="list-group-item"
                  v-for="(expense, i) in dashboardSecondExpense"
                  :key="i"
                >
                  <div>{{ Object.keys(expense)[0] }}</div>
                  <span>&#8358;{{ Object.values(expense)[0] }}</span>
                </div>
              </div>
            </div>
            <!-- list-group -->
          </div>
        </div>

        <div class="col-lg-4 mg-t-20">
          <div class="card card-dashboard-eight bg-white">
            <h6 class="card-title">Verification Services</h6>
            <span class="d-block mg-b-20"
              >View how much you've spent on verifications</span
            >

            <div class="list-group">
              <div class="list-group">
                <div
                  class="list-group-item"
                  v-for="(expense, i) in dashboardThirdExpense"
                  :key="i"
                >
                  <div>{{ Object.keys(expense)[0] }}</div>
                  <span>&#8358;{{ Object.values(expense)[0] }}</span>
                </div>
              </div>
            </div>
            <!-- list-group -->
          </div>
        </div>

        <div class="col-md-12 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <IdentitySuccessCallChart
              :series="dashboardAnalytics"
            ></IdentitySuccessCallChart>
          </div>
        </div>
        <!-- <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <IndentityServiceChart :series="dashboardFirstIdentity"></IndentityServiceChart>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <SecondIndentityServiceChart :series="dashboardSecondIdentity"></SecondIndentityServiceChart>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <IdentityFailedCallChart :series="dashboardFirstFailedCalls"></IdentityFailedCallChart>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <VerificationServiceChart :series="dashboardVerification"></VerificationServiceChart>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <VerificationSuccessChart :series="dashboardSecondSuccessfulCalls"></VerificationSuccessChart>
          </div>
        </div>-->
      </template>
      <DefaultContent
        v-else
        :image="'empty-resources.png'"
        :title="`Seems you have not verified your accout.`"
        :subtitle="
          'Verify your account to start testing out the different services. Contact the Admin to verify your account'
        "
      ></DefaultContent>
    </div>
  </div>
</template>

<script>
import IdentitySuccessCallChart from "@/components/dashboard/IdentitySuccessCallChart";

// import SecondIndentityServiceChart from '@/components/dashboard/SecondIndentityServiceChart'
// import IndentityServiceChart from '@/components/dashboard/IndentityServiceChart'
// import VerificationServiceChart from '@/components/dashboard/VerificationServiceChart'
// import VerificationSuccessChart from '@/components/dashboard/VerificationSuccessChart'
// import IdentityFailedCallChart from '@/components/dashboard/IdentityFailedCallChart'
export default {
  components: {
    IdentitySuccessCallChart,

    // IndentityServiceChart,
    // SecondIndentityServiceChart,
    // VerificationSuccessChart,
    // VerificationServiceChart,
    // IdentityFailedCallChart,
  },
  data() {
    return {
      values: [10, 5, 5, 5],
      names: [
        "Address Calls",
        "BVN Calls",
        "License Calls",
        "NIN Calls",
        "NIP Calls",
        "PVC Calls",
      ],
      isProcessing: true,
      dashboardData: {},

      dashboardAnalytics: [],

      // dashboardFirstIdentity: [],
      // dashboardSecondIdentity: [],
      // dashboardVerification: [],

      // dashboardExpense: [],
      // dashboardSecondExpense: [],
      // dashboardThirdExpense: [],

      // dashboardFirstSuccessfulCalls: [],
      // dashboardSecondSuccessfulCalls: [],
      // dashboardFirstFailedCalls: [],
      // dashboardSecondFailedCalls: [],
    };
  },
  methods: {
    dataFormat: function(a, b) {
      if (b) return b + "%";
      return a;
    },
    async getAnalytics() {
      let clientID =
        this.$store.state.account.clientID || localStorage.getItem("local-id");
      let header = { clientID };
      try {
        let dashboardData = await this.$store.dispatch(
          "account/getDashboardAnalytics",
          header
        );
        this.dashboardData = dashboardData.result;

        // Expenses
        this.dashboardExpense = [
          { BVN: this.dashboardData.totalBvnExpense },
          { NIN: this.dashboardData.totalNinExpense },
          { "BVN with Image": this.dashboardData.totaliBvnExpense },
          { "Permanent voters card": this.dashboardData.totalPvcExpense },
        ];
        this.dashboardSecondExpense = [
          { "Drivers License": this.dashboardData.totalLicenceExpense },
          { "Nigerian Passport": this.dashboardData.totalNipExpense },
          { "Address Verification": this.dashboardData.totalAddressExpense },
          { "Liveness Check": this.dashboardData.totalLivenessExpense },
        ];
        this.dashboardThirdExpense = [
          {
            "Physical Indetity Verification": this.dashboardData
              .totalPivExpense,
          },
          {
            "Facial Comparison": this.dashboardData
              .totalFacialComparisonExpense,
          },
          { "Number to Bank": this.dashboardData.totalNum2BankExpense },
          {
            "Bank account Verification": this.dashboardData
              .totalBankAccountExpense,
          },
        ];

        this.dashboardAnalytics = [
          // {
          //   name: 'TotalCalls',
          //   data: [
          //     this.dashboardData.totalBvnCalls,
          //     this.dashboardData.totalNinCalls,
          //     this.dashboardData.totaliBVNCalls,
          //     this.dashboardData.totalPvcCalls,
          //     this.dashboardData.totalNipCalls,
          //     this.dashboardData.totalLicenceCalls,
          //     this.dashboardData.totalAddressCalls,
          //     this.dashboardData.totalPivCalls,
          //     this.dashboardData.totalLivenessCheckCalls,
          //     this.dashboardData.totalFacialComparisonCalls,
          //     this.dashboardData.totalNum2BankCalls,
          //     this.dashboardData.totalBankAccountCalls,
          //   ]
          // },
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.totalSuccessfulBvnCalls,
              this.dashboardData.totalSuccessfulNinCalls,
              this.dashboardData.totalSuccessfuliBVNCalls,
              this.dashboardData.totalSuccessfulPvcCalls,
              this.dashboardData.totalSuccessfulNipCalls,
              this.dashboardData.totalSuccessfulLicenceCalls,
              this.dashboardData.totalSuccessfulAddressCalls,
              this.dashboardData.totalSuccessfulPivCalls,
              this.dashboardData.totalSuccessfulLivenessCheckCalls,
              this.dashboardData.totalSuccessfulFacialComparisonCalls,
              this.dashboardData.totalSuccessfulNum2BankCalls,
              this.dashboardData.totalSuccessfulBankAccountCalls,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.totalFailedBvnCalls,
              this.dashboardData.totalFailedNinCalls,
              this.dashboardData.totalFailediBVNCalls,
              this.dashboardData.totalFailedPvcCalls,
              this.dashboardData.totalFailedNipCalls,
              this.dashboardData.totalFailedLicenceCalls,
              this.dashboardData.totalFailedAddressCalls,
              this.dashboardData.totalFailedPivCalls,
              this.dashboardData.totalFailedLivenessCheckCalls,
              this.dashboardData.totalFailedFacialComparisonCalls,
              this.dashboardData.totalFailedNum2BankCalls,
              this.dashboardData.totalFailedBankAccountCalls,
            ],
          },
        ];

        this.isProcessing = false;

        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  computed: {
    profile() {
      return this.$store.state.account.profile.api_customer;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  mounted() {
    this.getAnalytics();
  },
};
</script>

<style scoped>
.charts {
  padding: 20px;
  /* background-color: #cccbcb;
  border: 1px solid black; */
}

.charts svg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.graphs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard-flex {
  padding: 0;
  /* background: #081b2a; */
  border: 1px solid #cccbcb;
  border-radius: 5px;
  color: black;
}
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 120px;
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
}

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 42px;
  font-weight: 600;
  line-height: 1;
  color: #2e6091;
  padding-top: 8px;
  margin-bottom: 2px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
