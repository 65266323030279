<template>
  <div class="row">
    <div class="col-12">
      <v-server-table
        ref="serverTableRef"
        name="question-table"
        class="v-table"
        :columns="columns"
        :options="options"
      >
        <template slot="beforeFilter">
          <div class="row mr-2">
            <div class="col-12">
              <button
                class="btn btn-lg btn-secondary btn-block text-right"
                type="submit"
                @click="exportHistory"
              >
                <span>Export/Download as csv</span>
              </button>
            </div>
          </div>
        </template>
        <template slot="request_time" slot-scope="props">
          <div>{{ props.row.request_time | moment(" MM-DD-YYYY h:mm a") }}</div>
        </template>
        <template slot="totalamount">
          <!--          <div>&#8358;{{debitHistory.totalAmountDebited.total_amount_debited}}</div>-->
        </template>
        <template slot="price_per_call" slot-scope="props">
          <div>&#8358;{{ props.row.price_per_call }}</div>
        </template>
        <template slot="id" slot-scope="props">
          <router-link
            :to="`/transactions/debit/${props.row.request_reference}`"
            class="btn btn-dark btn-sm"
          >
            <i class="typcn typcn-eye-outline mr-1"></i>View Details
          </router-link>
        </template>
      </v-server-table>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="filterModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="filterModaltitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Export debit history
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
export default {
  data() {
    return {
      clientID: "",
      startDate: null,
      endDate: null,
      allFields: ["Request Reference", "Application Used", "Client ID"],
      fields: ["Request Reference", "Application Used", "Client ID"],
      columns: [
        "request_reference",
        "client_id",
        "app.app_name",
        "request_time",
        "price_per_call",
        "id",
      ],
      options: {
        sortable: [],
        perPage: 10,
        pagination: {
          chunk: 5,
        },
        headings: {
          id: "Actions",
          request_reference: "Request Reference",
          client_id: "Client ID",
          "app.app_name": "Application Used",
          price_per_call: "Amount Debited",
          // totalamount: "Total Amount Debited",
          request_time: "Request Date",
        },
        filterByColumn: true,
        perPageValues: [],
      },
    };
  },
  methods: {
    exportBtn() {
      $("#filterModal").modal();
    },
    async exportHistory() {
      $("#filterModal").modal("hide");
      const historyRequest = this.exportRequestData();
      try {
        let apiResponse = await this.$store.dispatch(
          "logs/exportDebitHistory",
          historyRequest
        );
        console.log("Api Response from History Export ->", apiResponse);

        let blob = new Blob([apiResponse], { type: "text/csv" }),
          url = window.URL.createObjectURL(blob);

        window.open(url);

        this.showSuccessNotification("Export successful");
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.showErrorLoginNotification(error.message);
      }
    },
    exportRequestData() {
      let verificationData = {
        clientID: this.clientID,
        query: "",
      };
      return verificationData;
    },
  },
  created() {
    this.options.requestFunction = async (data) => {
      let clientID =
        this.$store.state.account.clientID || localStorage.getItem("local-ID");
      let verificationData = { clientID, ...data };
      const apiResponse = await this.$store.dispatch(
        "logs/CustomerDebitHistory",
        verificationData
      );

      const rr = apiResponse.result.debitLog;
      rr.count = rr.total;
      return rr;
    };
  },
  mounted() {
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>

<style>
</style>
