<template>
  <section>
    <div class="mb-3 border-bottom">
      <div class="d-flex align-items-center justify-content-start mb-2">
        <img
          v-if="successMsg"
          src="/assets/img/icons/arrow-left.svg"
          width="30"
          alt=""
          class="pointer mr-3"
          @click="goBack"
        />
        <p class="user-profile-name m-0">
          Phone Number Verification (Standard)
        </p>
      </div>

      <div class="flex__between pb-3">
        <h5 class="user-profile-email sub m-0">
          Verify Phone Number of your users.
        </h5>
        <PricesAndAccess
          :serviceLabel="'Phone Number Verification (Standard)'"
          :getServiceStatus="getServiceStatus"
        />
      </div>
    </div>

    <form v-if="!successMsg" @submit.prevent="handleSubmit" class="row pt-3">
      <div class="col-12">
        <CustomPhoneInput
          label="Phone Number"
          v-model="request.phone_number"
          @blur="$v.request.phone_number.$touch()"
          placeholder="Enter your phone number"
          :error="
            formErrors.phone_number ||
            ($v.request.phone_number.$error &&
              formVuelidator($v.request.phone_number))
          "
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 mt-3">
        <ActiveAppSelect
          :name="'app'"
          :handleError="handleError"
          :error="formErrors.app"
          v-model="request.app"
        />
      </div>
      <div class="col-sm-6 mt-3">
        <CurrencySelect
          :name="'currency_type'"
          :handleError="handleError"
          :error="formErrors.currency_type"
          v-model="request.currency_type"
        />
      </div>

      <div v-if="hasService" class="col-sm-6 mt-4">
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100"
          :disabled="loading"
        >
          <Spinner v-if="loading" />
          <span v-else>Verify</span>
        </button>
      </div>
    </form>

    <PhoneNumberVerificationStandardResult
      v-else
      :result="apiResponse.data.data"
    />

    <ResponsePreview>
      <template slot="endpoint">
        POST : '{{ API }}customerinformation/verifyMobileCarrierStandard'
      </template>

      <template slot="header">
        {{
          `{
						"clientid": ${clientID},
						"appname": ${request.app ? request.app.app_name : "app_name"},
						"apikey": ${request.app ? request.app.api_key : "app_key"}
					}`
        }}
      </template>

      <template slot="body">
        {{
          `{
						"country_code": ${
              request.phone_number.formatted
                ? request.phone_number.countryCallingCode
                : "country_code"
            },
            "phone_number": ${
              request.phone_number.formatted
                ? `${request.phone_number.formatted.replace(/\s/g, "")}`
                : "phonenumber"
            }
						"currency_type": ${
              request.currency_type ? request.currency_type : "currency_type"
            }
					}`
        }}
      </template>

      <template slot="sample">
        {{ apiResponse ? apiResponse : sampleResponse }}
      </template>
    </ResponsePreview>
  </section>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import CustomSelect from "@/utils/CustomSelect/CustomSelect";
import CustomInput from "@/utils/CustomInput/CustomInput";
import Spinner from "@/utils/Spinner/Spinner";
import ActiveAppSelect from "@/utils/Selects/ActiveAppSelect/ActiveAppSelect";
import CurrencySelect from "@/utils/Selects/CurrencySelect/CurrencySelect";
import { formValidator } from "@/utils/FormValidator/FormValidator";
import PricesAndAccess from "@/utils/PricesAndAccess/PricesAndAccess";
import { newCountryFormat } from "@/assets/js/countryDailcodes";
import PhoneNumberVerificationStandardResult from "./PhoneNumberVerificationStandardResult/PhoneNumberVerificationStandardResult";
import ResponsePreview from "@/utils/ResponsePreview/ResponsePreview";
import { sampleResponse } from "./PhoneNumberVerificationStandardSample";
import { required } from "vuelidate/lib/validators";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

const initialState = () => ({
  request: {
    phone_number: "",
    app: "",
    currency_type: "NGN",
  },
  loading: false,
  successMsg: false,
  formErrors: {},
  apiResponse: "",
  sampleResponse,
});

export default {
  mixins: [notifications],
  name: "PhoneNumberVerificationStandard",
  data() {
    return { hasService: false, ...initialState(), newCountryFormat };
  },
  validations: {
    request: {
      phone_number: {
        required,
        isPhoneValid: isPhoneValid("request.phone_number"),
      },
    },
  },
  components: {
    CustomPhoneInput,
    CustomSelect,
    CustomInput,
    CurrencySelect,
    ActiveAppSelect,
    Spinner,
    PricesAndAccess,
    PhoneNumberVerificationStandardResult,
    ResponsePreview,
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
    clientID() {
      return this.$store.state.account.clientID;
    },
  },
  methods: {
    formVuelidator,
    goBack() {
      Object.assign(this.$data, { ...initialState() });
    },
    getServiceStatus(hasService) {
      this.hasService = hasService;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$v.$touch();
      const { formErrors, isValid } = formValidator(this.request);

      if (isValid && !this.$v.$invalid) this.verifiyInformation();
      else this.formErrors = formErrors;
    },
    async verifiyInformation() {
      try {
        this.loading = true;

        const headers = {
          clientid: this.$store.state.account.clientID,
          appname: this.request.app.app_name,
          apikey: this.request.app.api_key,
        };
        const { phone_number, currency_type } = this.request || {};

        const response = await this.$store.dispatch(
          "services/phoneNumberVerifStandard",
          {
            headers,
            payload: {
              phone_number: phone_number.formatted.replace(/\s/g, ""),
              country_code: phone_number.countryCallingCode,
              currency_type: currency_type.code
                ? currency_type.code
                : currency_type,
            },
          }
        );

        if (response.status === 200) {
          this.apiResponse = response;
          this.loading = false;
          this.successMsg = true;
          this.showSuccessNotification("Phone Number Verified Successfully!");
        }
      } catch (error) {
        this.loading = false;
        typeof error.message === "string"
          ? this.showErrorLoginNotification(error.message)
          : this.showErrorLoginNotification("Error Validation Phone Number");
      }
    },
  },
};
</script>
