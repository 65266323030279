<template>
  <div class="az-content-box">
    <div class="az-content-left">
      <div class="az-services-menu">
        <!-- AML Service-->
        <div
          id="accordion"
          class="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          <div class="card-header panel-heading" role="tab" id="headingSix">
            <a
              data-toggle="collapse"
              href="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              <label
                class="az-content-label az-content-label-sm"
                style="cursor: pointer"
                >Payment Collection</label
              >
            </a>
          </div>
          <div
            id="collapseSix"
            data-parent="#accordion"
            class="collapse show"
            role="tabpanel"
            aria-labelledby="headingSix"
          >
            <nav class="nav az-nav-column mg-b-20" role>
              <a
                data-toggle="tab"
                @click="tabswitch(1)"
                href
                :class="{ active: tab == 1 }"
                class="nav-link"
                >Collection Overview</a
              >
              <a
                data-toggle="tab"
                @click="tabswitch(2)"
                href
                :class="{ active: tab == 2 }"
                class="nav-link"
                >Payout</a
              >
            </nav>
          </div>
        </div>
        <!-- End Aml ---->
      </div>
    </div>

    <loader v-if="isProcessing == true"></loader>

    <div class="az-content-body tab-content" v-else>
      <transition
        mode="out-in"
        name="fade"
        v-if="profile.is_active && application.length > 0"
      >
        <PaymentCollection v-if="tab == 1"></PaymentCollection>
        <Payout v-if="tab == 2"></Payout>
      </transition>
      <DefaultContent
        v-if="!profile.is_active"
        :image="'empty-resources.png'"
        :title="`Seems you've not verified your account.`"
        :subtitle="'Verify your account to start testing out the different services. Contact the Admin to verify your account'"
      ></DefaultContent>
      <DefaultContent
        v-if="profile.is_active && application.length == 0"
        :image="'empty-resources.png'"
        :title="'No Application Created'"
        :subtitle="'You currently do not have any active application, Please create one start testing services'"
      >
        <router-link class="btn btn-md btn-primary" to="/applications">
          <i class="la la-plus"></i> Create Application
        </router-link>
      </DefaultContent>
    </div>
  </div>
</template>

<script>
import PaymentCollection from "./PaymentCollection";
import Payout from "./Payout";

export default {
  components: {
    PaymentCollection,
    Payout,
  },
  computed: {
    profile() {
      return this.$store.state.account.profile.api_customer;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  data() {
    return {
      tab: 1,
      apps: {},
      application: [],
      isProcessing: true,
    };
  },
  methods: {
    tabswitch(tab) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (tab) {
        this.tab = tab;
        this.$router.replace({ query: { tab: this.tab } });
      } else {
        this.tab = 1;
      }
    },

    async fetchApps() {
      try {
        const app = await this.$store.dispatch("application/getApplications");
        this.apps = app;
        let applications = app.applications;
        let filteredApplication = [];
        if (applications.length > 0) {
          filteredApplication = applications.filter(
            (app) => app.is_active == 1
          );
        }
        // console.log('[filtered] ->', filteredApplication);
        this.application = filteredApplication;

        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  async mounted() {
    if (this.$route.query.tab) {
      let tab = this.$route.query.tab;
      this.tabswitch(tab);
    }
    this.fetchApps();
  },
};
</script>
<style scoped>
.accordion .card-header a {
  padding: 14px 0px !important;
}
.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
  color: #212229;
  background-color: transparent !important;
}
.accordion .card-header a.collapsed {
  background-color: transparent !important;
}
.panel-heading a:before {
  content: "\002B";
  float: right;
  transition: all 0.5s;
}
.panel-heading a.collapsed:before {
  content: "\2212" !important;
  float: right;
  transition: all 0.5s;
}
</style>