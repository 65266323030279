<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <h2 class="user-profile-name">BVN Upload Bulk Request form</h2>
                <h5 class="user-profile-email sub">
                  Verify BVN identities of various users in Bulk.
                </h5>
              </div>
            </div>
            <div class="content">
              <span
                class="badge badge-warning"
                style="font-size: 14px; font-weight: 500"
                v-if="hasService && userRole !== 'Secondary Customer'"
                >(Each API call costs &#8358;{{ `${servicePrice}` }})</span
              >
              <span
                v-if="!hasService"
                class="badge badge-danger"
                style="font-size: 14px; margin-top: 5px; font-weight: 500"
                >You don't have access to this service</span
              >
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="verifyBVNBulk">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <CustomUpload
                      :name="'bvn'"
                      :label="'BVN File'"
                      :required="true"
                      :validFileTypes="['.csv']"
                      :handleChange="handleUpload"
                      :fileName="mediaData && mediaData.name"
                      :error="uploadError && 'File size must be less than 5MB'"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">
                      Use the template below </label
                    ><br />
                    <a href="../../assets/samplebulkuploadfile.csv" download
                      >Download this template</a
                    >
                  </div>
                </div>

                <!-- Verification Type -->
                <div class="col-md-6">
                  <label class="input-item-label">
                    Select BVN Verification type
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select verification type"
                    v-model="verificationType"
                    @keydown.enter.native.prevent
                    :options="verificationTypes"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6">
                  <label class="input-item-label"> Select Currency Type </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an Currency Type"
                    v-model="currencyType"
                    @keydown.enter.native.prevent
                    :disabled="!customerWallets"
                    :options="customerWallets"
                    label="currency"
                  ></v-select>
                </div>

                <!-- Currency Type  -->
                <div class="col-md-6 mt-4">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>

                <!-- API Version details -->
                <!-- <div class="col-md-12 mt-4">
                  <label class="input-item-label">Select an API Version</label>
                  <v-select
                    class="v-select"
                    placeholder="Select an API version"
                    v-model="apiVersion"
                    @keydown.enter.native.prevent
                    :required="!apiVersion"
                    :disabled="!versions"
                    :options="versions"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.apiVersion.$error">
                    <li class="parsley-required">API version field is required.</li>
                  </ul>
                </div>-->

                <!-- Call ID -->
                <div class="col-md-12" v-if="hasBundleBilling">
                  <div class="gaps-2x"></div>
                  <div class="row">
                    <div class="col-10">
                      <div class="form-group">
                        <label class="input-item-label">Bundle Call ID</label>
                        <input
                          type="text"
                          v-model="call_id"
                          class="form-control input-bordered"
                          placeholder="Enter your bundle call ID"
                        />
                      </div>
                    </div>
                    <div class="col-2 mt-1">
                      <div class="form-group mt-4">
                        <button
                          @click.prevent="initializeApp(app)"
                          class="btn btn-block btn-lg btn-info mt-1"
                        >
                          Rengerate call ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting == true || !hasService"
                  >
                    <span v-if="isSubmitting">...Validating request</span>
                    <span v-else>Verify BVNs</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>BVN Bulk Upload Request Response</h1>
              <span class="text-span"
                >Preview of the information gotten from bulk bvn request made<br
              /></span>
              <span class="text-span"
                ><b>Note:</b> Only 5 items are displayed, check your mail for
                the complete Response</span
              >
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <!-- <div v-for="value in computedObj">{{value}}</div> -->
          <div
            class="preview-content"
            v-for="item in verificationResult.results.slice(0, 5)"
            :key="item"
          >
            <!-- <div class="invoice-info-row preview-row">
              <div class="bvn-image">
                <img
                  width="200px"
                  height="200px"
                  :src="item.results.personal_info.image_url"
                  :alt="`image for ${item.results.personal_info.full_name}`"
                />
              </div>
            </div> -->
            <p class="invoice-info-row preview-row">
              <span>Fullname</span>
              <span>{{ item.personal_info.full_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Firstname</span>
              <span>{{ item.personal_info.first_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Lastname</span>
              <span>{{ item.personal_info.last_name }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Email:</span>
              <span>{{
                item.personal_info.email || "Information not available"
              }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>BVN</span>
              <span>{{ item.bvn_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Reg Date</span>
              <span>{{ item.formatted_registration_date }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Date of Birth</span>
              <span>{{ item.personal_info.formatted_date_of_birth }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>LGA</span>
              <span>{{ item.personal_info.lga_of_origin }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>State of origin</span>
              <span>{{ item.personal_info.state_of_origin }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Nationality</span>
              <span>{{ item.personal_info.nationality }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Marital Status</span>
              <span>{{ item.personal_info.marital_status }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Phone number</span>
              <span>{{ item.personal_info.phone_number }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Request Reference</span>
              <span>{{ item.request_reference }}</span>
            </p>
            <p class="invoice-info-row preview-row">
              <span>Verification Status</span>
              <span>{{ item.verification_status }}</span>
            </p>

            <div class="gaps-1-5x"></div>
          </div>
        </div>

        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}IdentityVerification/BVN-Upload`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <!-- "first_name": "{{firstname ? firstname : "firstname"}}", -->
          <!-- "last_name": "{{ lastname ? lastname : "lastname"}}", -->
          <!-- "dob": "{{dob}}" -->
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
      {
        "bvn_upload": "{{"bvn_upload"}}"
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
           {
    "status_code": 200,
    "message": "BVN Verification Completed Successfully",
    "results": [
        {
            "request_reference": "RbEyPV721424655",
            "bvn_number": "11222222222",
            "name_on_card": null,
            "enrolment_branch": null,
            "enrolment_bank": null,
            "formatted_registration_date": null,
            "level_of_account": null,
            "nin": null,
            "watchlisted": null,
            "verification_status": "VERIFIED",
            "service_type": "BVN Verification without Image",
            "personal_info": {
                "first_name": "CIROMA ",
                "middle_name": "CHUKWUMA",
                "last_name": "ADEKUNLE",
                "full_name": "CIROMA CHUKWUMA ADEKUNLE",
                "email": null,
                "gender": null,
                "phone_number": "08012345678",
                "phone_number_2": null,
                "date_of_birth": "1965-01-19",
                "formatted_date_of_birth": "January 19, 1965",
                "lga_of_origin": null,
                "state_of_origin": null,
                "nationality": null,
                "marital_status": null
            },
            "residential_info": {
                "state_of_residence": null,
                "lga_of_residence": null,
                "residential_address": null
            }
        },
        {
            "request_reference": "VnMdIg721424805",
            "bvn_number": "223333333333",
            "name_on_card": null,
            "enrolment_branch": null,
            "enrolment_bank": null,
            "formatted_registration_date": null,
            "level_of_account": null,
            "nin": null,
            "watchlisted": null,
            "verification_status": "VERIFIED",
            "service_type": "BVN Verification without Image",
            "personal_info": {
                "first_name": "CHIOMA",
                "middle_name": "APATA",
                "last_name": "YARO",
                "full_name": "CHIOMA APATA YARO",
                "email": null,
                "gender": null,
                "phone_number": "08012345888",
                "phone_number_2": null,
                "date_of_birth": "1967-10-02",
                "formatted_date_of_birth": "October 2, 1967",
                "lga_of_origin": null,
                "state_of_origin": null,
                "nationality": null,
                "marital_status": null
            },
            "residential_info": {
                "state_of_residence": null,
                "lga_of_residence": null,
                "residential_address": null
                }
            }
        ]
    }
    `}}
      </code></pre>
        </div>
      </div>
    </div>
    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>

    <!-- Modal -->
    <div
      class="modal fade"
      id="sucessModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sucessModaltitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="sucessModalLongTitle">
              <i class="la la-exclamation-circle"></i> Success
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Your Bulk BVN request has been recieved and is being processed. A
              comprehensive report will sent to your mail shortly.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import {
  required,
  minLength,
  alphaNum,
  sameAs,
} from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import csvFile from "../../../public/assets/samplebulkuploadfile.csv";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    app: { required },
    // apiVersion: { required },
  },
  data() {
    return {
      initializedAppResult: {},
      app: "",
      versions: ["v1", "v2"],
      verificationTypes: ["BVN with Image", "BVN without Image"],
      verificationType: "BVN without Image",
      apiVersion: "",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      phonenumber: "",
      image_1: "",
      firstname: "",
      lastname: "",
      uploadError: "",
      hasNewResponse: false,
      dob: "",
      logData: [],
      apiResponse: "",
      customerWallets: this.$store.state.account.customerWallets,
      currencyType: "",
      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
    };
  },
  watch: {
    hasNewResponse(newValue, oldValue) {
      console.log("New-Value", newValue);
      console.log("Old-Value", oldValue);
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
  },
  components: {
    CustomUpload,
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Bulk")) {
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    async handleUpload({ target: { files } }) {
      this.mediaData = null;

      const file = files[0];
      if (file) {
        const dataSize = 1024 * 1024;
        if (file.size / dataSize < 5) {
          this.mediaData = file;
          this.uploadError = false;
        } else {
          this.uploadError = true;
        }
      }
    },
    getRequestData() {
      console.log("In Get-Request-Block");
      if (!this.hasBundleBilling) {
        this.call_id = "";
      }

      let bvnData = new FormData();
      bvnData.append("bvn_upload", this.mediaData);

      let verificationData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        query: this.call_id,
        payload: {
          currency_type: this.currencyType.currency,
        },
      };
      console.log("VerificationData", verificationData);

      return { verificationData, bvnData };
    },
    async verifyBVNBulk() {
      console.log("In Verify-Bulk-BVN...");
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("[invalid fields] ->");
        return;
      }
      if (this.uploadError) return;
      let verificationData = this.getRequestData();
      this.isSubmitting = true;
      console.log("[Ver-Data] ->", verificationData);

      if (this.verificationType === "BVN without Image") {
        try {
          let apiResponse = await this.$store.dispatch(
            "services/verifyBVNBulk",
            verificationData
          );
          console.log("[api Response from Bulk-Bvn ] ->", apiResponse.results);
          this.apiResponse = apiResponse;

          $("#sucessModal").modal();
          this.isSubmitting = false;
          this.showSuccessNotification(apiResponse.message);
          Nprogress.done();
        } catch (error) {
          Nprogress.done();
          this.isSubmitting = false;
          console.log("error :", error);
          this.showErrorLoginNotification(error.message);
        }
      } else {
        try {
          let apiResponse = await this.$store.dispatch(
            "services/verifyBVNBulkWithImage",
            verificationData
          );
          console.log("[api Response from Bulk-Bvn ] ->", apiResponse.results);
          this.apiResponse = apiResponse;

          $("#sucessModal").modal();
          this.isSubmitting = false;
          this.showSuccessNotification(apiResponse.message);
          Nprogress.done();
        } catch (error) {
          Nprogress.done();
          this.isSubmitting = false;
          console.log("error :", error);
          this.showErrorLoginNotification(error.message);
        }
      }
    },
  },
  mounted() {
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
    console.log("Stored-ClinetID", this.clientID);
  },
};
</script>
