<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openModal"
    >
      Edit
    </button>

    <EditDocumentsModal
      :id="'updateDocuments'"
      :closeModal="closeModal"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import EditDocumentsModal from "../modals/EditDocumentsModal.vue";

export default {
  data() {
    return {};
  },
  methods: {
    openModal() {
      $("#updateDocuments").modal("show");
    },
    closeModal() {
      $("#updateDocuments").modal("hide");
    },
  },
  components: {
    EditDocumentsModal,
  },
};
</script>
