<template>
	<Modal
		:id="id"
		:title="coupon ? 'Update Coupon' : 'Create New Coupon'"
		:toggle="handleCloseModal"
	>
		<SuccessState
			v-if="successMsg"
			:message="
				coupon
					? 'Coupon Updated Successfully'
					: 'Coupon Created Successfully'
			"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row h-100">
			<div class="col-sm-6">
				<PlanSelect
					:name="'forPlans'"
					:error="
						formErrors.forPlans ||
						($v.request.forPlans.$error &&
							formVuelidator($v.request.forPlans))
					"
					:handleError="handleError"
					v-model="request.forPlans"
					@close="$v.request.forPlans.$touch()"
					:multiple="true"
					:setPlans="setPlans"
					:cls="'h-auto'"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					:name="'couponCode'"
					v-model="request.couponCode"
					@blur="$v.request.couponCode.$touch()"
					:label="'Coupon Code'"
					:placeholder="'Enter Coupon Code'"
					:required="true"
					:error="
						formErrors.couponCode ||
						($v.request.couponCode.$error &&
							formVuelidator($v.request.couponCode))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-12">
				<label class="input-item-label d-block">Usage Type</label>
				<div class="form-group d-flex pt-2">
					<div class="radio">
						<label>
							<input
								type="radio"
								name="limited"
								v-model="request.limited"
								:value="true"
								class="mr-2"
							/>Limited</label
						>
					</div>
					<div class="radio ml-5">
						<label
							><input
								type="radio"
								name="limited"
								:value="false"
								class="mr-2"
								v-model="request.limited"
							/>Unlimited
						</label>
					</div>
				</div>
				<ul
					class="parsley-errors-list filled"
					v-show="$v.request.limited.$error"
				>
					<li class="parsley-required">
						{{ formVuelidator($v.request.limited) }}
					</li>
				</ul>
			</div>

			<div class="col-sm-6">
				<DatePicker
					:name="'startDate'"
					:label="'Start Date'"
					v-model="request.startDate"
					@blur="$v.request.startDate.$touch()"
					:placeholder="'Select Start Date'"
					:disabledDates="yesterDay"
					:required="true"
					:error="
						formErrors.startDate ||
						($v.request.startDate.$error &&
							formVuelidator($v.request.startDate))
					"
					:handleError="handleError"
				/>
			</div>

			<template v-if="request.limited && !coupon">
				<div class="col-sm-6">
					<DatePicker
						:name="'endDate'"
						:label="'End Date'"
						v-model="request.endDate"
						@blur="$v.request.endDate.$touch()"
						:disabledDates="{ to: request.startDate }"
						:placeholder="'Select End Date'"
						:required="true"
						:error="
							formErrors.endDate ||
							($v.request.endDate.$error &&
								formVuelidator($v.request.endDate))
						"
						:handleError="handleError"
					/>
				</div>

				<div class="col-sm-6">
					<CustomInput
						:type="Number"
						:name="'numberAvailable'"
						v-model="request.numberAvailable"
						@blur="$v.request.numberAvailable.$touch()"
						:label="'Max Number of Redemption'"
						:placeholder="'Max Number of Redemption'"
						:required="true"
						:error="
							formErrors.numberAvailable ||
							($v.request.numberAvailable.$error &&
								formVuelidator($v.request.numberAvailable))
						"
						:handleError="handleError"
						:isNum="true"
					/>
				</div>
			</template>

			<div class="col-sm-6">
				<CustomSelect
					name="discountType"
					label="Discount Type"
					placeholder="Select Sub Account"
					:options="['flat', 'percentage']"
					v-model="request.discountType"
					@close="$v.request.discountType.$touch()"
					:required="true"
					:error="
						formErrors.discountType ||
						($v.request.discountType.$error &&
							formVuelidator($v.request.discountType))
					"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6">
				<CustomInput
					type="number"
					:max="
						request.discountType === 'percentage'
							? 100
							: 99999999999999999999999
					"
					:name="'discountValue'"
					v-model="request.discountValue"
					@blur="$v.request.discountValue.$touch()"
					:label="amountLabel"
					:placeholder="'Enter Amount'"
					:required="true"
					:error="
						formErrors.discountValue ||
						($v.request.discountValue.$error &&
							formVuelidator($v.request.discountValue))
					"
					:handleError="handleError"
					:disabled="!request.discountType"
          :isNum="true"
				/>
			</div>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import CustomSelect from "../../../../../utils/CustomSelect/CustomSelect";
import PlanSelect from "../../../../../utils/Selects/PlanSelect/PlanSelect";
import DatePicker from "../../../../../utils/DatePicker/DatePicker";
import CustomInput from "../../../../../utils/CustomInput/CustomInput";
import Modal from "../../../../../utils/Modal/Modal";
import { formValidator } from "../../../../../utils/FormValidator/FormValidator";
import Spinner from "../../../../../utils/Spinner/Spinner";
import FormButtons from "../../../../../utils/FormButtons/FormButtons";
import SuccessState from "../../../../../utils/ResponseState/SuccessState";
import { getWalletApikey } from "../../../../../utils/helpers";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import { numeric, required, requiredIf } from "vuelidate/lib/validators";

const initialState = () => ({
	request: {
		forPlans: [],
		couponCode: "",
		limited: true,
		numberAvailable: "",
		startDate: "",
		endDate: "",
		expiration: true,
		discountType: "",
		discountValue: "",
	},
	loading: false,
	successMsg: false,
	formErrors: {},
});

export default {
	name: "CreateCouponModal",
	mixins: [notifications],
	data() {
		return { ...initialState(), allPlans: [], couponData: {} };
	},
	validations: {
		request: {
			forPlans: {
				required,
			},
			couponCode: {
				required,
			},
			limited: {
				required,
			},
			numberAvailable: {
				required: requiredIf(function () {
					return this.request.limited;
				}),
				numeric,
			},
			startDate: {
				required,
			},
			endDate: {
				required: requiredIf(function () {
					return this.request.limited;
				}),
			},
			expiration: {
				required,
			},
			discountType: {
				required,
			},
			discountValue: {
				required,
			},
		},
	},
	props: ["id", "closeModal", "couponRef", "coupon", "refetch"],
	components: {
		CustomInput,
		CustomSelect,
		Modal,
		Spinner,
		FormButtons,
		SuccessState,
		PlanSelect,
		DatePicker,
	},
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
		amountLabel() {
			return this.request.discountType === "flat"
				? "Amount"
				: "Amount In Percent";
		},
		yesterDay() {
			return {
				to: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
			};
		},
		isMultiple() {
			const isAll = this.request.forPlans.find(
				(plan) => plan.reference === "allPlans"
			);
			return isAll ? false : true;
		},
	},
	methods: {
		formVuelidator,
		handleSubmit(e) {
			e.preventDefault();
			this.$v.$touch();
			const payload = { ...this.request };
			if (!this.request.limited) {
				delete payload.endDate;
				delete payload.numberAvailable;
			}
			if (this.coupon) {
				delete payload.createdAt;
				delete payload.endDate;
			}

			const { formErrors, isValid } = formValidator(payload);
			if (isValid && !this.$v.$invalid)
				this.coupon
					? this.updateCoupon(payload)
					: this.createCoupon(payload);
			else this.formErrors = formErrors;
		},
		handleRefetch() {
			this.handleCloseModal();
			this.couponRef
				? this.couponRef.refresh()
				: this.refetch(this.couponData);
			this.setCouponData();
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
			this.setCouponData();
		},
		getPlanRefs() {
			const plan = this.request.forPlans;
			const isAll = plan.find(
				({ reference }) => reference === "allPlans"
			);
			const plans = isAll ? this.allPlans : plan;
			return plans.map((plan) => plan.reference || plan);
		},
		setPlans(plans) {
			this.allPlans = plans;
		},
		async createCoupon(payload) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"coupons/createCoupon",
					{
						apiKey: getWalletApikey(this.$store),
						request: {
							...payload,
							forPlans: this.getPlanRefs(),
						},
					}
				);
				const { status, data } = response || {};

				if (status === 200) {
					this.loading = false;
					this.successMsg = true;
					this.couponData = data.data;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		async updateCoupon(payload) {
			try {
				this.loading = true;
				const response = await this.$store.dispatch(
					"coupons/updateCoupon",
					{
						apiKey: getWalletApikey(this.$store),
						request: {
							...payload,
							forPlans: this.getPlanRefs(),
						},
					}
				);

				if (response.status === 200) {
					this.loading = false;
					this.successMsg = true;
				}
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
		setCouponData() {
			if (this.coupon) {
				const newCoupon = { ...this.coupon };
				delete newCoupon.count;
				delete newCoupon.updatedAt;
				this.request = newCoupon;
			}
		},
	},
	watch: {
		"request.startDate": function () {
			this.request.endDate = "";
		},
		"request.limited": function (val) {
			this.request.expiration = val ? true : false;
		},
	},
	mounted() {
		this.setCouponData();
	},
};
</script>
