import { titleCase } from "../../../../../../utils/helpers";

export const PayBillStatus = (successMsg, service_type) => {
	if (service_type === "cable_tv")
		return `<h5>
			Bill Payment Successful
			<h5>
				Transaction Number: <b>${successMsg.transactionNo}</b>
			</h5>
			<h5>
				Transaction Reference: <b>${successMsg.transactionRef}</b>
			</h5>
		</h5>`;
	else if (service_type === "electricity")
		return `<h5>
			Bill Payment Successful
			<h5>
				Transaction ID: <b>${successMsg.tranxId}</b>
			</h5>
			${successMsg.token ? `<h5>
				Bill Token: <b>${successMsg.token}</b>
			</h5>`: ''}
      <h5>
				Transaction Status: <b>${titleCase(successMsg.status)}</b>
			</h5>
		</h5>`;
	else if (service_type === "internet")
		return `<h5>
			Bill Payment Successful
			<h5>
				Transaction Reference: <b>${successMsg.tranxReference ||
					successMsg.detail.tranxReference}</b>
			</h5>
		</h5>`;
	else return "Bill Payment Successful";
};
