<template>
	<div class="card pt-3">
		<div class="row">
			<div class="col-12">
				<v-server-table
					ref="subRef"
					class="v-table"
					:columns="columns"
					:options="options"
				>
					<template slot="index" slot-scope="props">
						{{ props.index }}
					</template>
					<template slot="amountAfter" slot-scope="{ row }">
						{{ row.metadata.plan ? row.metadata.plan.currency : " "
						}}{{ toCurrency(row.amountAfter, " ") }}
					</template>
					<template slot="subscriptionType" slot-scope="{ row }">
						{{ titleCase(row.subscriptionType) }}
					</template>
					<template slot="startDate" slot-scope="{ row }">
						{{ timeDateFormat(row.startDate).date }}
					</template>
					<template slot="renewalDate" slot-scope="{ row }">
						{{ timeDateFormat(row.renewalDate).date || "None" }}
					</template>
					<template slot="active" slot-scope="{ row }">
						<span :class="getStatus(row.active).style">
							{{ getStatus(row.active).value }}</span
						>
					</template>
				</v-server-table>
			</div>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins";

const tableHeadings = {
	index: "S/N",
	planRef: "Plan",
	subscriptionType: "Subscription Type",
	amountAfter: "Amount",
	startDate: "Date Created",
	renewalDate: "Renewal Date",
	active: "Status",
};

export default {
	mixins: [componentData],
	name: "SubscriptionTable",
	props: {
		dataParams: function () {
			return Object;
		},
		perPage: {
			type: Number,
			default: 20,
		},
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
	},
	methods: {
		getStatus(status) {
			return {
				value: status ? "Active" : "Not-Active",
				style: status ? "text-success" : "text-danger",
			};
		},
	},
	data() {
		return { ...this.tableOptions(tableHeadings, this.perPage) };
	},
	created() {
		this.options.requestFunction = async (data) => {
			return (
				await this.$store.dispatch(
					"subscriptions/getAllSubscriptions",
					{
						apiKey: this.merchant.api_key,
						params: {
							...data,
							...this.dataParams,
						},
					}
				)
			).data.data;
		};
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
