<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div v-if="!hasNewResponse">
          <div class="user-details-left-header">
            <div class="user-details-info">
              <div class="user-details--top-info">
                <!-- Mobile Carrier Service -->
                <h2 class="user-profile-name">DataPlan Service</h2>
                <h5 class="user-profile-email sub">
                  Recharge data across different networks
                </h5>
              </div>
            </div>
          </div>
          <div class="contact-details--card">
            <form @submit.prevent="topData">
              <div class="row mt-4">
                <!-- Application details -->
                <div class="col-md-12">
                  <label class="input-item-label">
                    Select an Application to be billed for the request
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                  </label>
                  <v-select
                    class="v-select"
                    placeholder="Select an application"
                    v-model="app"
                    @close="$v.app.$touch()"
                    @keydown.enter.native.prevent
                    :required="!app"
                    :disabled="!application"
                    :options="application"
                    label="app_name"
                  ></v-select>
                  <ul class="parsley-errors-list filled" v-show="$v.app.$error">
                    <li class="parsley-required">
                      Application field is required.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row mt-4">
                <!-- Country Code -->
                <div class="col-12 col-md-6">
                  <CustomPhoneInput
                    :name="'phone'"
                    v-model="phoneNumber"
                    @blur="$v.phoneNumber.$touch()"
                    :label="'Phone Number'"
                    placeholder="Enter your phone number"
                    :error="
                      $v.phoneNumber.$error && formVuelidator($v.phoneNumber)
                    "
                  />
                </div>

                <!-- Currency -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="input-item-label">Currency</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <v-select
                      class="v-select"
                      placeholder="Select currency"
                      v-model="currency"
                      @close="$v.currency.$touch()"
                      @keydown.enter.native.prevent
                      :required="!currency"
                      :disabled="!currencies"
                      :options="currencies"
                    ></v-select>
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.currency.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.currency) }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Data Plan -->
                <div class="col-md-6" v-if="hasDataPlans">
                  <div class="form-group">
                    <label class="input-item-label">Data Plan</label>
                    <span class="text-danger" title="This field is required"
                      >*</span
                    >
                    <v-select
                      class="v-select"
                      placeholder="Select DataPlan"
                      v-model="dataPlan"
                      @close="$v.dataPlan.$touch()"
                      @keydown.enter.native.prevent
                      :required="!dataPlan"
                      :disabled="!dataPlans"
                      :options="dataPlans"
                      label="plan"
                    ></v-select>
                    <ul
                      class="parsley-errors-list filled"
                      v-show="$v.dataPlan.$error"
                    >
                      <li class="parsley-required">
                        {{ formVuelidator($v.dataPlan) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting"
                    v-if="!hasDataPlans"
                  >
                    <span v-if="isSubmitting">...Loading</span>
                    <span v-else>Get Data Plans</span>
                  </button>
                  <button
                    class="btn btn-lg btn-primary mg-b-20"
                    type="submit"
                    :disabled="isSubmitting"
                    v-if="hasDataPlans"
                  >
                    <span v-if="isSubmitting">...Loading</span>
                    <span v-else>Top Up Data</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- Response Page -->
        <div class="user-settings-section" v-if="hasNewResponse">
          <div class="user-settings-section-header">
            <div class="user-settings-section-header-text">
              <h1>Data Top Up Response</h1>
              <span class="text-span"
                >Preview of the information gotten from the data top<br
              /></span>
              <br />
              <div class="row mt-4">
                <!-- Message -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Message</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.message }}
                    </h5>
                  </div>
                </div>
                <!-- Recipient -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Recipient</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.recipient }}
                    </h5>
                  </div>
                </div>
                <!-- Operator -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Operator</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.operator }}
                    </h5>
                  </div>
                </div>
                <!-- Operator -->
                <!-- <div class="col-md-4">
                  <div class="form-group">
                    <h5>Operator</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.operator_name }}
                    </h5>
                  </div>
                </div> -->
                <!-- Amount -->
                <div class="col-md-4">
                  <div class="form-group">
                    <h5>Amount</h5>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <h5 class="input-item-label">
                      {{ this.apiResponse.destination_currency_code }}
                      {{ this.apiResponse.delivered_top_up_amount }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="#"
              class="btn btn-outline-danger"
              @click="hasNewResponse = false"
            >
              <i class="la la-close"></i>Close Panel
            </a>
          </div>
          <!-- <div v-for="value in computedObj">{{value}}</div> -->
        </div>
        <!-- End of transition -->
      </transition>

      <hr />
      <div class="row">
        <div class="col-12">
          <div class="user-details-info">
            <div class="user-details--top-info">
              <h2 class="user-profile-name">API Request Format</h2>
              <h5 class="user-profile-email sub">
                Representation of how the API call can be made with the
                appropriate data neccesary
              </h5>
            </div>
          </div>
        </div>

        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:35%;">End-Point</label><code>
        'POST' : `{{API}}/BillPayment/DataTopup`
      </code></pre>
        </div>
        <div class="col-12">
          <pre
            class="code code-html"
          ><label style="top:42%;">Headers</label><code>
      {
        'clientid': '{{clientID}}',
        'appname': '{{ app ? app.app_name : 'app_name'  }}',
        'apikey': '{{ app ? app.api_key : 'app_key'  }}',
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label style="top:42%;">Body</label><code>
       {
        'country_code': '{{phoneNumber.formatted ? phoneNumber.countryCallingCode : 'country_code'}}',
        'phone_number': '{{ phoneNumber.formatted ? phoneNumber.nationalNumber.replace(/\s/g, "") : 'phone_number'  }}',
        'currency_type': '{{ currency ? currency : 'currency_type'  }}',
        'data_plan': '{{ dataPlan ? dataPlan : 'data_plan'  }}'
      }
      </code></pre>
        </div>
        <div class="col-12">
          <pre class="code code-html"><label>Sample Response</label><code>
            {{apiResponse ? apiResponse :`
        {
  "status_code": 200,
  "message": "Airtime top up successful",
  "data": {
    "status": "success",
    "message": "Transaction Successfull",
    "reference": "jQWYJbFosY3QVB59wv"
  }
}`}}
      </code></pre>
        </div>
      </div>
    </div>

    <ApplicationInitializationModal
      :app="app"
      :initializedAppResult="initializedAppResult"
    ></ApplicationInitializationModal>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, requiredIf } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";
import {
  formVuelidator,
  isPhoneValid,
} from "@/utils/FormValidator/FormVuelidator";
import CustomPhoneInput from "@/utils/CustomInput/CustomPhoneInput/CustomPhoneInput";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  data() {
    return {
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "v2",
      call_id: "",
      clientID: "",
      apikey: "",
      appname: "",
      hasDataPlans: false,
      hasNewResponse: false,
      logData: [],
      apiResponse: "",
      // services
      hasService: false,
      servicePrice: "",

      isSubmitting: false,
      verificationResult: [],
      //data service
      dataPlans: "",
      dataPlan: "",
      phoneNumber: "",
      currencies: [],
      currency: "",
    };
  },
  validations: {
    apiVersion: { required },
    app: { required },
    dataPlan: {
      required: requiredIf(function () {
        return this.hasDataPlans;
      }),
    },
    currency: { required },
    phoneNumber: {
      required,
      isPhoneValid: isPhoneValid("phoneNumber"),
    },
  },
  components: { CustomPhoneInput },
  watch: {
    phoneNumber() {
      this.hasDataPlans = false;
      this.dataPlans = [];
    },
    hasNewResponse(newValue, oldValue) {
      if (oldValue == true && newValue == false) {
        this.apiResponse = "";
      }
    },
    app() {
      this.getServices();
    },
  },
  computed: {
    API() {
      return this.$store.state.appUrlV2;
    },
  },
  methods: {
    formVuelidator,
    computeService() {
      if (this.services) {
        this.services.filter((service) => {
          if (service.service_label.includes("Mobile")) {
            console.log("YEAH 'MOBILE' FOUND >>>>", service.service_label);
            this.hasService = true;
            this.servicePrice = service.pivot.price_per_call;
          }
        });
      }
    },
    topDataRequest() {
      let requestData = {
        clientID: this.clientID,
        appname: this.app.app_name,
        apikey: this.app.api_key,
        payload: {
          phone_number: this.phoneNumber.nationalNumber.replace(/\s/g, ""),
          country_code: this.phoneNumber.countryCallingCode,
          currency_type: this.currency,
          data_plan: this.dataPlan,
          dataAmount: this.dataAmount,
        },
        query: "",
      };
      return requestData;
    },
    async topData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      if (!this.dataPlan) {
        const userData = this.topDataRequest();
        try {
          this.isSubmitting = true;
          let apiResponse = await this.$store.dispatch(
            "services/getDataPlans",
            userData
          );
          console.log("Api Response from Data Plan Service ->", apiResponse);
          this.dataPlans = apiResponse.bundles;
          if (!apiResponse.bundles.length || apiResponse.bundles.length < 1) {
            this.showErrorLoginNotification("No Data Plan found");
          } else {
            this.showSuccessNotification(apiResponse.message);
            this.hasDataPlans = true;
          }
          Nprogress.done();
          this.isSubmitting = false;
        } catch (error) {
          Nprogress.done();
          this.isSubmitting = false;
          console.log("Error :", error);
          this.showErrorLoginNotification(error.message);
        }
      } else {
        const dataDetails = this.topDataRequest();
        try {
          this.isSubmitting = true;
          let apiResponse = await this.$store.dispatch(
            "services/topData",
            dataDetails
          );
          console.log("Api Response from Data top up Service ->", apiResponse);
          this.showSuccessNotification(apiResponse.message);
          this.apiResponse = apiResponse;
          this.hasNewResponse = true;
          Nprogress.done();
          this.isSubmitting = false;
        } catch (error) {
          Nprogress.done();
          this.isSubmitting = false;
          console.log("Data top up error :", error);
          this.showErrorLoginNotification(error.message);
        }
      }
    },
    async getCurrencies() {
      try {
        const currencies = await this.$store.dispatch("wallets/getCurrencies");
        this.currencies = currencies.data.map((item) => {
          return item.code;
        });
      } catch (error) {
        console.log("Currency error :", error);
      }
    },
  },
  mounted() {
    this.getCurrencies();
    this.computeService();
    this.clientID = this.$store.state.account.clientID;
  },
};
</script>
