<template>
	<div style="z-index: 9999" class="custom-modal start-0 livesness__check">
		<div class="close" @click="close">
			<img src="/assets/img/close.svg" alt="close.svg" />
		</div>
		<div key="1" v-if="step == 1" class="modal-content bg-white">
			<p class="breadcrumb">Liveness check</p>
			<div class="welcome-screen">
				<p class="title">Liveness Check</p>
				<div>
					<img
						style="margin-bottom: 13.75px"
						src="/assets/img/welcome-icon.svg"
						alt="welcome-icon"
					/>
					<p class="subtitle text-center">
						Face forward and make sure your
						<br />
						eyes are well visible
					</p>
				</div>
				<button @click="handleStart" class="btn custom-btn">
					Continue
				</button>
			</div>
			<div class="text-center">
				<img
					class="logo"
					src="/assets/img/blusalt-liveness-logo.svg"
					alt="welcome-icon"
				/>
			</div>
		</div>
		<div
			key="2"
			v-show="step == 2"
			class="no-padding modal-content bg-white position-relative"
		>
			<p
				:style="{
					color: loading ? 'white' : null,
					paddingInline: '28px',
					paddingBlock: '20px',
				}"
				class="breadcrumb position-absolute"
			>
				Liveness check
			</p>
			<video muted ref="webcam" id="video">
				Video stream not available.
			</video>
			<div class="position-absolute custom-overlay">
				<transition v-if="!loading" mode="out-in" name="fade">
					<p :key="countdown" class="countdown">
						{{ countdown <= 0 ? null : countdown }}
					</p>
				</transition>
				<Spinner color="white" v-else />
			</div>
			<p class="position-absolute text-center bottom-text">
				Position your head within the circle
			</p>
		</div>
		<div
			key="3"
			style="height: 553px"
			v-if="step == 3"
			class="modal-content bg-white"
		>
			<p class="breadcrumb">Liveness check</p>
			<div class="between-column">
				<div class="welcome-screen">
					<p class="title">Liveness Done!</p>
					<div class="between">
						<button
							@click="handleStart"
							class="btn custom-btn invert"
						>
							Redo
						</button>
						<button @click="confirm" class="btn custom-btn">
							Confirm
						</button>
					</div>
				</div>
				<div class="text-center">
					<img
						class="logo"
						src="/assets/img/blusalt-liveness-logo.svg"
						alt="welcome-icon"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Spinner from "@/utils/Spinner/Spinner";
import nprogress from "nprogress";
import "./LinenessModal.css";

export default {
	name: "LivenessModal",
	data() {
		return {
			step: 1,
			blobs: [],
			file: null,
			countdown: 5,
			loading: true,
			recording: false,
			cameraStream: null,
			mediaRecorder: null,
		};
	},
	components: {
		Spinner,
		nprogress,
	},
	methods: {
		handleStart(e) {
			this.step = 2;
			this.blobs = [];
			this.file = null;
			this.countdown = 5;
			this.loading = true;

			this.startCamera();
		},
		async startCamera() {
			const video = this.$refs.webcam;
			video.src = "";

			this.cameraStream = await navigator.mediaDevices
				.getUserMedia({ video: true, audio: true })
				.then((stream) => {
					this.loading = false;
					setTimeout(() => {
						this.countdown--;
					}, 1200);
					return stream;
				});

			video.srcObject = this.cameraStream;
			video.play();

			(this.mediaRecorder = new MediaRecorder(this.cameraStream)),
				{
					videoBitsPerSecond: 2009000,
				};

			this.mediaRecorder.addEventListener("dataavailable", (e) => {
				this.blobs.push(e.data);
			});

			this.mediaRecorder.addEventListener("stop", async () => {
				const blob = new Blob(this.blobs, {
					type: "video/mp4",
				});

				this.file = new File([blob], "liveness.mp4", {
					type: "video/mp4",
				});
			});
		},
		stopStream() {
			this.cameraStream
				? this.cameraStream.getTracks().forEach(function (track) {
						track.stop();
				  })
				: null;

			this.recording ? this.mediaRecorder.stop() : null;
		},
		confirm() {
			nprogress.configure({
				parent: "body",
			});
			this.$emit("confirm", this.file);
		},
		close() {
			nprogress.configure({
				parent: "body",
			});
			this.stopStream();
			this.$emit("close");
		},
	},
	watch: {
		countdown: {
			handler(value) {
				if (value > 0 && this.loading == false) {
					setTimeout(() => {
						this.countdown--;
					}, 1000);
				} else if (value == 0 && this.loading == false) {
					this.recording = true;
					this.mediaRecorder.start();
					nprogress.configure({
						parent: ".modal-content",
						easing: "ease",
						speed: 3000,
					});
					nprogress.set(0.3);

					setTimeout(() => {
						nprogress.done();
						setTimeout(() => {
							this.step = 3;
							this.stopStream();
							this.recording = false;
						}, 1000);
					}, 2000);
				}
			},
		},
	},
};
</script>
