<template>
	<Modal :id="id" :toggle="handleClose">
		<SuccessState
			v-if="successMsg"
			:message="'Chargeback Challenged Successfully'"
			:onSuccess="handleRefetch"
		/>
		<form v-else @submit.prevent="handleSubmit" class="h-100">
			<h2 class="heading">Challenge Chargeback Dispute?</h2>
			<p class="sub-heading my-4">
				Upload evidence to challenge this chargeback dispute, your
				feedback will be shared with the bank/customer and the dispute
				will be closed
			</p>

			<div class="form-group">
				<CustomUpload
					:name="'evidence'"
					:validFileTypes="['.png', '.jpg', '.jpeg']"
					:handleChange="handleUpload"
					:fileName="evidence && evidence.name"
					uploadText="Upload Prove to Challenge chargeback"
					:error="
						(uploadError && 'File size must be less than 5MB') ||
						(!$v.evidence.required &&
							$v.evidence.$dirty &&
							'Evidence image is required')
					"
				/>
			</div>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleClose"
				closeBtnName="Cancel"
				btnName="Challenge Chargeback"
			/>
		</form>
	</Modal>
</template>

<script>
import Modal from "@/utils/Modal/Modal";
import { getWalletApikey } from "@/utils/helpers";
import { required } from "vuelidate/lib/validators";
import FormButtons from "@/utils/FormButtons/FormButtons";
import CustomUpload from "@/utils/CustomUpload/CustomUpload";
import SuccessState from "@/utils/ResponseState/SuccessState";
import { notifications } from "../../../../../../../public/assets/mixins/notifications";

export default {
	props: ["id", "chargeback", "closeModal", "refetch"],
	mixins: [notifications],
	data() {
		return {
			loading: false,
			successMsg: false,
			evidence: null,
			uploadError: false,
		};
	},
	validations: {
		evidence: { required },
	},
	components: {
		Modal,
		FormButtons,
		SuccessState,
		CustomUpload,
	},
	methods: {
		async handleUpload({ target: { files } }) {
			this.evidence = null;

			const dataSize = 1024 * 1024;

			const file = files[0];

			if (file.size / dataSize < 5) {
				this.evidence = file;
				this.uploadError = false;
			} else {
				this.uploadError = true;
			}
		},
		handleRefetch() {
			this.handleClose();
			this.refetch();
		},
		handleClose() {
			this.closeModal();
			this.successMsg = false;
			this.loading = false;
			this.evidence = null;
			this.uploadError = null;
		},
		async handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid || this.uploadError) return;

			try {
				this.loading = true;

				const payload = new FormData();

				payload.append("status", "CHALLENGED");
				payload.append("evidence", this.evidence);

				const response = await this.$store.dispatch(
					"wallets/updateChargeBack",
					{
						apiKey: getWalletApikey(this.$store),
						reference: this.chargeback.reference,
						payload,
					}
				);

				if (
					(response.status === 200 || response.status === 201) &&
					response.data.status === true
				) {
					this.successMsg = true;
				} else this.showErrorLoginNotification(response.data.message);

				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.showErrorLoginNotification(error.message);
			}
		},
	},
};
</script>

<style scoped>
.heading {
	color: #071b2b;
	font-size: 22px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
}

.sub-heading {
	color: #505050;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
}
</style>
