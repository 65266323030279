<template>
	<div
		class="tab-pane fade active show"
		id="Card_detials"
		role="tabpanel"
		aria-labelledby="Card_detials-tab"
		>
		<div class="card p-3">
			<div class="row align-items-center">
				<div class="col-12 flex__between pb-2 border-bottom">
					<div class="wallet__switch active">
						<h4>
							NGN
						</h4>
					</div>
					<p>Active</p>
				</div>
				<div class="col-sm-5 text-center p-4 border-right">
					<DebitCard />
				</div>

				<div class="col-sm-7">
					<div class="row align-items-center">
						<div class="col-4">
							<p class="call-name">Card Type</p>
							<p class="m-0">Physical</p>
						</div>

						<div class="col-4">
							<div class="d-flex">
								<h5 class="mt-1 mr-3">Freeze Card</h5>
								<toggle-button
									v-model="freezeCard"
									color="#006848"
									:value="true"
									:sync="true"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DebitCard from "../../../../../utils/DebitCard/DebitCard";

	export default {
		name: "CardDetails",
		data() {
			return {
				freezeCard: false,
			};
		},
		components: {
			DebitCard,
		},
	};
</script>
