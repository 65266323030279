import directdebit from '@/services/directdebit'
import { apiClient } from '@/services/api'

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2

export default {
  namespaced: true,
  actions: {
    async activateDirectDebit({ dispatch }, activationData) {
      try {
        let { data } = await directdebit.activateDirectDebit(activationData)
        dispatch('account/getProfile', null, { root: true })
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },
    async getDirectDebitMerchantProfile(merchantData) {
      try {
        let { data } = await directdebit.getDirectDebitMerchantProfile(merchantData)
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },
    async getDirectDebitWalletTransactions(merchantData) {
      try {
        let { data } = await directdebit.getDirectDebitWalletTransactions(merchantData)
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },
    async getDirectDebitTransactions(merchantData) {
      try {
        let { data } = await directdebit.getDirectDebitTransactions(merchantData)
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },
    async getDirectDebitMandates(merchantData) {
      try {
        let { data } = await directdebit.getDirectDebitMandates(merchantData)
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },
    async getDirectDebitCustomers(merchantData) {
      try {
        let { data } = await directdebit.getDirectDebitCustomers(merchantData)
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },
    async withdrawFunds(merchantData) {
      try {
        let { data } = await directdebit.withdrawFunds(merchantData)
        return data
      } catch (error) {
        if (!error.response) {
          throw Error('Network Error, Please check your network and retry')
        } else {
          throw error.response.data
        }
      }
    },

  },
  getters: {},
}
