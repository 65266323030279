<template>
	<div class="user-settings-section border-0">
		<div class="invoice-info border-0">
			<div class="invoice-info-row preview-row">
      <div class="user-prof">
			  <img :src="userImage" alt="" class="rounded" />
		  </div>
      <div class="user-prof">
			  <img :src="originalImage" alt="" class="rounded" />
		  </div>
    </div>
    </div>
		<div class="invoice-info mt-4">
			<p class="invoice-info-row preview-row">
				<span>Procedure Validation</span>
				<span>
					{{
						result.liveness.procedure_validation ||
						"Information not available"
					}}
				</span>
			</p>
			<p class="invoice-info-row preview-row">
				<span>Face Genuineness</span>
				<span>
					{{
						result.liveness.face_genuineness ||
						"Information not available"
					}}
				</span>
			</p>
      <p class="invoice-info-row preview-row">
        <span>Image Quality</span>
        <span>
          {{
            result.face_attributes.image_quality.value || "Information not available"
          }}
        </span>
      </p>
      <p class="invoice-info-row preview-row">
        <span>Glasses</span>
        <span>
          {{
          result.face_attributes.glass || "Information not available"
          }}
        </span>
      </p>
       <p class="invoice-info-row preview-row">
				<span>Reference ID</span>
				<span>
					{{ result.request_id || "Information not available" }}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "LivenessReport",
	props: ["result"],
	computed: {
		userImage() {
			return this.result.image_best
				? `data:image/png;base64, ${this.result.image_best}`
				: "/assets/img/avatar1.svg";
		},
    originalImage() {
			return this.result.original_image
				? `${this.result.original_image}`
				: "/assets/img/avatar1.svg";
		},
	},
};
</script>

<style scoped>
.user-settings-section-head p {
	font-size: 15px;
	padding: 3px 0;
}

.invoice-info {
	border-top: 1px dotted #ced4da;
	border-bottom: 1px dotted #ced4da;
}

.user-prof img {
	width: 300px;
	height: auto;
	object-fit: cover;
}

.user-prof {
	text-align: right;
	width: auto;
	height: auto;
	background: none !important;
	overflow: hidden;
}
</style>
