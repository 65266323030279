<template>
	<div class="row">
		<!-- Transfer section -->
		<div class="col-lg-12 col-md-12">
			<div>
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
							<label class="input-item-label"
								>Select Transfer Type</label
							>
							<v-select
								:options="transfer_type"
								label="type_label"
								v-model="beneficiary_type"
								class="v-select"
								placeholder="Select Transfer Type"
							></v-select>
						</div>
					</div>
					<template v-if="transferTab === 'selectBeneficiary'">
						<div class="col-12">
							<div class="form-group">
								<label class="input-item-label"
									>Select Beneficiary</label
								>
								<v-select
									:options="beneficiaryList"
									:get-option-label="getLabel"
									v-model="beneficiary_details"
									class="v-select"
									placeholder="Select Beneficiary"
								>
									<template
										v-slot:no-options="{
											search,
											searching,
										}"
									>
										<template v-if="searching">
											No results found for
											<em>{{ search }}</em
											>.
										</template>
										<em style="opacity: 0.5" v-else
											>You currently have no
											beneficiaries.</em
										>
									</template>
								</v-select>
								<ul
									class="parsley-errors-list filled"
									v-show="$v.beneficiary_details.$error"
								>
									<li class="parsley-required">
										{{
											formVuelidator(
												$v.beneficiary_details
											)
										}}
									</li>
								</ul>
							</div>
						</div>
					</template>
					<template v-if="transferTab === 'newTransfer'">
						<div class="col-12">
							<div class="row">
								<!-- account no field -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>Select your Bank</label
										>
										<v-select
											:options="bankList"
											label="name"
											v-model="bank_details"
											@close="$v.bank_details.$touch()"
											class="v-select"
											placeholder="Select your bank"
											:disabled="accountValidated"
										></v-select>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.bank_details.$error"
										>
											<li class="parsley-required">
												{{
													formVuelidator(
														$v.bank_details
													)
												}}
											</li>
										</ul>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label class="input-item-label"
											>Account Number</label
										>
										<input
											type="number"
											@keypress="blockInvalidChar"
											v-model="account_number"
											@blur="$v.account_number.$touch()"
											class="form-control input-bordered"
											placeholder="Enter Account Number"
											:disabled="accountValidated"
										/>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.account_number.$error"
										>
											<li class="parsley-required">
												{{
													formVuelidator(
														$v.account_number
													)
												}}
											</li>
										</ul>
										<div
											v-if="verifying"
											class="d-flex mt-2 justify-content-end text-danger"
										>
											<span class="pr-2"
												>Verifying Account...</span
											>
											<Spinner
												:variant="'clip'"
												:size="'16px'"
												:color="'red'"
											/>
										</div>
										<div
											v-else-if="verifyError"
											class="text-right text-danger"
										>
											Error Validating Account
										</div>
									</div>
								</div>

								<!-- beneficiary name field -->
								<div class="col-12">
									<div class="form-group">
										<label class="input-item-label"
											>Beneficiary Name</label
										>
										<input
											type="text"
											:value="beneficiary_name"
											@blur="$v.beneficiary_name.$touch()"
											class="form-control input-bordered"
											placeholder="Enter Beneficiary Name"
											disabled
										/>
										<ul
											class="parsley-errors-list filled"
											v-show="$v.beneficiary_name.$error"
										>
											<li class="parsley-required">
												{{
													formVuelidator(
														$v.beneficiary_name
													)
												}}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Amount</label>
							<input
								type="number"
								@keypress="blockInvalidChar"
								v-model="amount"
								@blur="$v.amount.$touch()"
								class="form-control input-bordered"
								placeholder="Enter Amount"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.amount.$error"
							>
								<li class="parsley-required">
									{{ formVuelidator($v.amount) }}
								</li>
							</ul>
						</div>
					</div>
					<!-- description field -->
					<div class="col-md-6">
						<div class="form-group">
							<label class="input-item-label">Narration</label>
							<input
								type="text"
								v-model="description"
								@blur="$v.description.$touch()"
								class="form-control input-bordered"
								placeholder="Enter Narration"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.description.$error"
							>
								<li class="parsley-required">
									{{ formVuelidator($v.description) }}
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-6" v-if="transferTab === 'newTransfer'">
						<div
							class="form-group d-flex justify-content-between mt-2"
						>
							<label class="input-item-label"
								>Save Beneficiary?</label
							>
							<div class="d-flex justify-content-between">
								<div
									class="custom-control custom-checkbox mr-2"
								>
									<input
										type="checkbox"
										class="custom-control-input"
										id="customCheck1"
										@input="beneficiarySaved"
										v-model="trueCheckbox"
									/>
									<label
										class="custom-control-label"
										for="customCheck1"
										>Yes</label
									>
								</div>
								<div class="custom-control custom-checkbox">
									<input
										type="checkbox"
										class="custom-control-input"
										id="customCheck2"
										@input="beneficiaryNotSaved"
										v-model="falseCheckbox"
									/>
									<label
										class="custom-control-label"
										for="customCheck2"
										>No</label
									>
								</div>
							</div>
						</div>
						<ul
							class="parsley-errors-list filled"
							v-show="$v.save_beneficiary.$error"
						>
							<li class="parsley-required">
								{{ formVuelidator($v.save_beneficiary) }}
							</li>
						</ul>
					</div>
					<div class="col-md-6 mt-3">
						<button
							class="btn btn--lg btn-primary mg-b-20"
							type="submit"
							@click.prevent="handleSubmit"
							:disabled="submitting == true"
						>
							<span v-if="submitting == true">Submitting...</span>
							<span v-else>Submit</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<FundTransferOtp
			v-if="showModal"
			id="toggleTransferOTP"
			:closeModal="closeModal"
			@handleOtp="performTransaction($event)"
		/>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import {
	required,
	minLength,
	numeric,
	maxLength,
	requiredIf,
} from "vuelidate/lib/validators";
import { formVuelidator } from "@/utils/FormValidator/FormVuelidator";
import FundTransferOtp from "./modals/FundTransferOTP/FundTransferOTP.vue";
import { notifications } from "../../../public/assets/mixins/notifications";
import { getWalletApikey, getWalletPublickey } from "@/utils/helpers";
import { decrypt, encrypt } from "./helpers";
import { blockInvalidChar, getResponseStatus, getError } from "@/utils/helpers";
import Spinner from "@/utils/Spinner/Spinner";

const initialState = {
	transferTab: "selectBeneficiary",
	verifyError: "",
	verifying: "",
	accountValidated: false,
	verifyAccount: [],
	submitting: false,
	accountVerification: false,
	beneficiary_type: { id: 1, type_label: "Transfer to Saved Beneficiary" },
	beneficiary_details: "",
	bank_details: "",
	account_number: "",
	save_beneficiary: false,
	amount: "",
	beneficiary_name: "",
	falseCheckbox: true,
	trueCheckbox: false,
	description: "",
	transfer_code: "",
	showModal: false,
	transfer_type: [
		{ id: 1, type_label: "Transfer to Saved Beneficiary" },
		{ id: 2, type_label: "Transfer to New Beneficiary" },
	],
};

export default {
	data() {
		return {
			...initialState,
		};
	},
	mixins: [notifications],
	computed: {
		resolveBank() {
			return `${this.bank_details.code}|${this.account_number}`;
		},
	},
	components: {
		FundTransferOtp,
		Spinner,
	},
	validations: {
		description: {
			required,
		},
		beneficiary_details: {
			required: requiredIf(function () {
				return this.transferTab == "selectBeneficiary";
			}),
		},
		save_beneficiary: {
			required: requiredIf(function () {
				return (
					this.transferTab == "newTransfer" &&
					this.accountVerification == true
				);
			}),
		},
		beneficiary_name: {
			required: requiredIf(function () {
				return (
					this.transferTab == "newTransfer" &&
					this.accountVerification == true
				);
			}),
		},
		bank_details: {
			required: requiredIf(function () {
				return this.transferTab == "newTransfer";
			}),
		},
		account_number: {
			required: requiredIf(function () {
				return this.transferTab == "newTransfer";
			}),
			numeric,
			minLength: minLength(10),
			maxLength: maxLength(10),
		},
		amount: {
			required,
			numeric,
		},
	},
	props: {
		settings: {
			type: Object,
		},
		bankList: {
			type: Array,
		},
		beneficiaryList: {
			type: Array,
		},
		fetchAllBeneficiaries: {
			type: Function,
		},
	},
	methods: {
		getResponseStatus,
		getError,
		blockInvalidChar,
		formVuelidator,
		openModal() {
			this.showModal = true;
			setTimeout(() => $("#toggleTransferOTP").modal("show"), 200);
		},
		closeModal() {
			$("#toggleTransferOTP").modal("hide");
			setTimeout(() => (this.showModal = false), 200);
		},
		getLabel: (val) => {
			return `${val.account_name} ( ${val.bank.name} )`;
		},
		beneficiarySaved() {
			this.falseCheckbox = false;
			this.save_beneficiary = true;
		},
		beneficiaryNotSaved() {
			this.trueCheckbox = false;
			this.save_beneficiary = false;
		},
		back() {
			this.accountValidated = false;
			this.accountVerification = false;
		},
		async verifyAccountNumber() {
			try {
				this.verifying = true;
				this.verifyError = false;
				const response = await this.$store.dispatch(
					"wallets/resolveBankName",
					{
						account_number: this.account_number,
						bank_code: this.bank_details.code,
						apiKey: getWalletApikey(this.$store),
					}
				);

				if (response.status === 200) {
					this.verifyAccount = response.data.data;
					this.beneficiary_name =
						this.verifyAccount.account_name.toUpperCase();
					this.accountVerification = true;
					this.accountValidated = true;
					this.verifying = false;
				}
			} catch (error) {
				this.verifying = false;
				this.beneficiary_name = "";
				this.verifyError = true;
				Nprogress.done();
			}
		},

		async handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) return;
      this.openModal();
		},
		randomString() {
			var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ";
			var string_length = 12;
			var randomstring = "";
			for (var i = 0; i < string_length; i++) {
				var randNum = Math.floor(Math.random() * chars.length);
				randomstring += chars.substring(randNum, randNum + 1);
			}
			return randomstring;
		},
		async performTransaction(otp) {
			Nprogress.start();
			this.submitting = true;

			const headers = {
				clientid:
					this.$store.state.account.clientID ||
					localStorage.getItem("local-ID"),
				publickey: getWalletPublickey(this.$store),
			};

			let payload = {
				amount: this.amount,
				source: "wallet",
				reference_code: this.randomString(),
				narration: this.description,
        otp,
			};

			if (this.transferTab != "selectBeneficiary") {
				payload = {
					...payload,
					account_number: this.account_number,
					bank_code: this.bank_details.code,
					metadata: {
						name: this.beneficiary_name,
						payment: this.description,
					},
					save_beneficiary: this.save_beneficiary,
				};
			} else {
				payload = {
					...payload,
					account_number: this.beneficiary_details.account_number,
					bank_code: this.beneficiary_details.bank.code,
					metadata: {
						name: this.beneficiary_details.account_name,
						payment: this.description,
					},
					save_beneficiary: false,
				};
			}

			try {
				const response = await this.$store.dispatch(
					"fundstransfer/initiateSingleTransfer",
					{
						headers,
						payload: encrypt(
							getWalletApikey(this.$store),
							JSON.stringify(payload)
						),
					}
				);

				const { status, data } = response || {};

				const result = decrypt(
					data.base64Iv,
					getWalletApikey(this.$store),
					data.data
				);
      

				if (
					(status === 200 || status === 201) &&
					result.status === "success"
				) {
					this.$swal({
						title: `Transaction Proccessing`,
						text: result.message,
						icon: "success",
						button: "Ok",
					}).then(() => {
						this.save_beneficiary && this.fetchAllBeneficiaries();
						Object.assign(this.$data, { ...initialState });
						this.$nextTick(() => {
							this.$v.$reset();
						});
					});
				} else this.showErrorLoginNotification(result.message);

				this.submitting = false;
			} catch (error) {
				if (error?.response?.data?.base64Iv) {
					const { message } = decrypt(
						error?.response?.data?.base64Iv,
						getWalletApikey(this.$store),
						error?.response?.data?.data
					);
					this.showErrorLoginNotification(message);
				} else this.showErrorLoginNotification(getError(error));
				this.submitting = false;
				Nprogress.done();
			}
		},
	},
	watch: {
		resolveBank() {
			if (
				this.bank_details &&
				String(this.account_number).length === 10
			) {
				this.beneficiary_name = "";
				this.verifyAccountNumber();
			}
		},
		beneficiary_type() {
			this.$nextTick(() => {
				this.$v.$reset();
			});
			this.transferTab = "";
			if (
				this.beneficiary_type.type_label ===
				"Transfer to Saved Beneficiary"
			) {
				this.transferTab = "selectBeneficiary";
			} else {
				this.transferTab = "newTransfer";
			}
		},
	},
};
</script>

<style>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
	display: none !important;
}
.v-select.vs__dropdown-toggle {
	padding: 2px 15px !important;
}
.v-select.v-select.vs--single.vs--searchable .v-select.vs__dropdown-toggle {
	padding: 2px 15px !important;
}
.input__field {
	border-color: #ff6868;
}
</style>
