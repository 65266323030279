export const tableHeadings = {
	first_name: "First Name",
	last_name: "Last Name",
	reference: "Reference",
	business: "Customer Type",
	email: "Email",
	phone: "Phone",
	createdAt: "Date Created",
};

export const customerCsvRows = {
	reference: "Payment Reference",
	id: "Customer ID",
	first_name: "First Name",
	last_name: "Last Name",
	email: "Email",
	phone: "Phone Number",
	business: "Business Customer",
	country: "Country",
	state: "State",
	address: "Address",
	createdAt: "Date Created",
};
